import { useMutation } from '@tanstack/react-query';

import { AddManualActivityCommandRequest, AddManualActivityCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

type RequestObject = Omit<AddManualActivityCommandRequest.AsObject, 'businessProcessDomainId'> ;
const MESSAGE_NAME = 'wilqo.api.mortgage.AddManualActivityCommandRequest';

export const useAddActivityManually = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation<AddManualActivityCommandResponse.AsObject, Error, RequestObject, any>((params: RequestObject) => {
    const request = new AddManualActivityCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setConditionId(params.conditionId);
    request.setDealId(params.dealId);
    request.setLoanId(params.loanId);
    request.setTemplateIdsList(params.templateIdsList);
    return sendMessage<AddManualActivityCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      AddManualActivityCommandResponse,
    );
  });
};
