import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { AddDesignPanelCommandRequest, PanelSequenceDesignCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.AddDesignPanelCommandRequest';

const useAddDesignerPanel = () => {
  const { commandResponse } = useBackend();
  const { designerPanel: { entityId, panelSequenceDesignId } } = useActivityConfiguratorContext();
  const bpdId = useBPDId();

  const AddDesignerPanelCommand = async () => {
    const request = new AddDesignPanelCommandRequest();
    request.setPanelSequenceDesignId(panelSequenceDesignId);
    request.setActivityDesignId(entityId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new PanelSequenceDesignCommandResponse();
    PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };
  return useMutation(AddDesignerPanelCommand);
};

export { useAddDesignerPanel };
