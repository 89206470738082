import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { DocumentCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { DocType, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useUpdateDocumentCategory } from '@/API/Queries/mortgage/useUpdateDocumentCategory';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getAssignedBadgeProps } from '@/Utils/Helpers/getAssignedBadgeProps';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';

import type { DocumentCategoryModalView } from './DocumentCategoryModal';

type TableItem = {
  id: string;
  label: string;
  documentTypeName: string;
  lastEdited: string;
  badge?: IBadgeProps;
  isAssigned: ReactNode;
  isConsumerFacing: string;
  docTypeId: string | undefined;
};

interface UpdateDocumentCategoryProps {
  documentCategory?: DocumentCategory.AsObject;
  refetch: () => void;
  setView: (view: DocumentCategoryModalView) => void;
  setTypeId: (typeId?: string) => void;
  isLoading?: boolean;
}

const getLegacyId = (docType: DocType.AsObject) => `${String(docType.docType)}-${docType.documentTypeName}`;

const UpdateDocumentCategory = ({
  documentCategory,
  isLoading,
  refetch,
  setTypeId,
  setView,
}: UpdateDocumentCategoryProps) => {
  const { isLoading: isSaving, mutate: updateDocumentCategory } = useUpdateDocumentCategory();
  const [selectedTypes, setSelectedTypes] = useState<TableItem[]>([]);
  const [search, setSearch] = useState('');

  const columns = useMemo((): Array<Column<any>> => [{
    header: 'Document Type',
    id: 'documentTypeName',
    key: {
      text: 'documentTypeName',
      type: 'text',
    },
  }, {
    header: 'Mismo Mapping',
    id: 'label',
    key: {
      children: 'label',
      type: 'generic',
    },
  }, {
    header: 'Last Edited',
    id: 'lastEdited',
    key: {
      text: 'lastEdited',
      type: 'text',
    },
  }, {
    header: 'Assign',
    id: 'badge',
    key: {
      badge: 'badge',
      type: 'badge',
    },
  },
  {
    header: 'Consumer Facing ',
    id: 'isConsumerFacing',
    key: {
      text: 'isConsumerFacing',
      type: 'text',
    },
  },
  ], []);

  const getTableItem = useCallback((docType: DocType.AsObject, isAssigned?: boolean): TableItem => {
    const lastEditedDate = getDateFromTimestamp(documentCategory?.lastEditedDate);
    return {
      badge: isAssigned ? getAssignedBadgeProps() : undefined,
      docTypeId: docType.id,
      documentTypeName: docType?.documentTypeName || '--',
      id: getLegacyId(docType),
      isAssigned,
      isConsumerFacing: docType.isConsumerFacing ? 'Yes' : 'No',
      label: ConvertEnum(DocumentEnum, docType.docType),
      lastEdited: lastEditedDate !== null ? lastEditedDate.toLocaleString() : '',
    };
  }, [documentCategory?.lastEditedDate]);

  const data = useMemo(() => {
    const enumProperties = Object.keys(DocumentEnum);
    enumProperties.shift();
    let tableData = documentCategory?.docTypesList?.map((docType) => getTableItem(docType, true)) || [];

    if (search) {
      tableData = tableData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
    }

    return tableData.filter((item) => item.isAssigned);
  }, [documentCategory?.docTypesList, getTableItem, search]);

  const handleUnassign = useCallback((selectedTypes: TableItem[]) => {
    if (documentCategory?.typesList) {
      // If document type id is available, use that. Otherwise fall back to old matching strategy
      const docTypes = documentCategory.docTypesList
        .filter((type) => !selectedTypes.some((selectedType) => (type.id != null && type.id.length > 0 ? selectedType.docTypeId === type.id : selectedType.id === getLegacyId(type))));

      const newDocTypes = docTypes.map((docType) => {
        const newDocType = new DocType();
        newDocType.setDocType(docType.docType);
        newDocType.setDocumentTypeName(docType.documentTypeName);

        if (docType.id != null) {
          newDocType.setId(docType.id);
        }

        return newDocType;
      });

      // Get distinct types
      const newTypes = Array.from(new Set(docTypes.map((type) => Number(type.docType))));

      updateDocumentCategory({
        category: documentCategory?.categoryType || 0,
        docTypes: newDocTypes,
        id: documentCategory?.id || '',
        name: documentCategory?.name || '',
        types: newTypes,
      }, {
        onSuccess: () => {
          refetch();
          setSearch('');
        },
      });
    }
  }, [documentCategory?.typesList, documentCategory?.docTypesList, documentCategory?.categoryType, documentCategory?.id, documentCategory?.name, updateDocumentCategory, refetch]);

  const handleSingleItemClick = useCallback((item: TableItem) => {
    handleUnassign([item]);
  }, [handleUnassign]);

  const handleUpdateCategory = (data: any, onClose: () => void) => {
    const docTypes = documentCategory?.docTypesList.map((docType) => {
      const newDocType = new DocType();
      newDocType.setDocType(docType.docType);
      newDocType.setDocumentTypeName(docType.documentTypeName);
      newDocType.setIsConsumerFacing(docType.isConsumerFacing);

      if (docType.id != null) {
        newDocType.setId(docType.id);
      }

      return newDocType;
    }) || [];

    updateDocumentCategory({
      category: documentCategory?.categoryType || 0,
      docTypes,
      id: documentCategory?.id || '',
      name: data?.name?.value || documentCategory?.name,
      types: documentCategory?.typesList || [],
    }, {
      onSuccess: () => {
        refetch();
        setSearch('');
        onClose();
      },
    });
  };

  const handleEditType = (item: TableItem) => {
    setView('type');
    setTypeId(item.id);
  };

  const handleCreateType = () => {
    setView('type');
    setTypeId(undefined);
  };

  return (
    <DialogLayout>
      <DialogHeader title={documentCategory?.name || ''} />

      <DialogContent>
        <div className="w-full h-full p-4 sm:p-12 flex flex-col gap-y-10 overflow-auto">
          <Typography
            className="text-on-surface-inactive"
            variant="body1"
          >
            Edit category details, assign or unassign types to the category.
          </Typography>
          <CardListItem
            headerProps={{ title: 'Document Category' }}
            isEditable
            isLoading={isLoading}
            isSaving={isSaving}
            listItems={[
              {
                caption: documentCategory?.name || '--',
                label: 'Category Name',
                panelElement: getPanelElement({
                  headerText: 'Category Name',
                  id: 'name',
                  type: 'text',
                }),
                value: {
                  value: documentCategory?.name || '',
                },
              },
            ]}
            onSubmit={handleUpdateCategory}
            variant="border"
          />

          <div className="border border-on-surface-stroke p-4 w-full rounded-sm">
            <Table
              cardProps={{
                headerProps: {
                  actions: [{ label: 'Create new', onClick: handleCreateType }],
                  contextualHeaderProps: {
                    buttons: [
                      { label: 'Unassign', onClick: () => handleUnassign(selectedTypes) },
                    ],
                    caption: `${selectedTypes.length} selected`,
                  },
                  searchProps: { onChange: setSearch, value: search },
                  showContextual: selectedTypes.length > 0,
                  title: 'Assigned Document Types',
                },
              }}
              columns={columns}
              data={data}
              hoverActions={[
                { label: (item) => (item.isAssigned ? 'Unassign' : 'Assign'), onClick: (item) => handleSingleItemClick(item) },
                { label: 'Edit', onClick: handleEditType },
              ]}
              isLoading={isLoading}
              setRowSelection={setSelectedTypes}
              width="stretch"
            />
          </div>
        </div>
      </DialogContent>
    </DialogLayout>
  );
};

export { UpdateDocumentCategory };
