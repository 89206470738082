import clsx from 'clsx';

import type { TypographyVariant } from './Typography';
import { Typography } from './Typography';

type AvatarSizes = '2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs';
export interface IAvatarProps {
  text?: string;
  image?: string;
  size?: AvatarSizes;
}

const sizeDisplayMap: Record<AvatarSizes, { typographyVariant: TypographyVariant; classes: string }> = {
  '2xl': { classes: 'h-44 w-44', typographyVariant: 'display4' },
  lg: { classes: 'h-12 w-12', typographyVariant: 'display6' },
  md: { classes: 'h-10 w-10', typographyVariant: 'display6' },
  sm: { classes: 'h-6 w-6', typographyVariant: 'display6' },
  xl: { classes: 'h-32 w-32', typographyVariant: 'display6' },
  xs: { classes: 'h-7 w-7', typographyVariant: 'body1Bold' },
};

const Avatar = (props: IAvatarProps) => {
  const { image, size = 'md', text } = props;

  const generateAvatarText = () => {
    if (!text) return null;
    const variant = sizeDisplayMap[size].typographyVariant;
    const splitText = text.split(' ');
    if (splitText.length === 1) {
      return <Typography variant={variant}>{splitText[0].charAt(0)}</Typography>;
    }
    return (
      <>
        <Typography variant={variant}>{splitText[0].charAt(0)}</Typography>
        <Typography variant={variant}>{splitText[1].charAt(0)}</Typography>
      </>
    );
  };

  const generateAvatarImage = () => {
    if (!image) return null;
    return (
      <img
        alt="avatar"
        className={clsx('rounded-full', sizeDisplayMap[size].classes)}
        src={image}
      />
    );
  };

  return (
    <div
      className={clsx(
        'select-none cursor-pointer flex items-center justify-center text-on-surface-inactive bg-surface-variant active:text-on-surface-active active:bg-on-surface-states-pressed rounded-full',
        sizeDisplayMap[size].classes,
      )}
      data-testid="circular-icon-letters"
    >
      {text && !image && generateAvatarText()}
      {image && generateAvatarImage()}
    </div>
  );
};

export { Avatar };
