/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useState } from 'react';

import type { FeeParametrizedRuleGroupSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import { Icon } from '@/Components/Atoms/Icon';
import { Menu } from '@/Components/Atoms/Menu';
import { Switch } from '@/Components/Atoms/Switch';
import { Typography } from '@/Components/Atoms/Typography';
import { TriggerWarning } from '@/Routes/Pages/admin/feeTemplate/FeeTemplateConfig/TriggerWarning';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { Rule } from './Rule';
import { useTriggerContext } from './TriggerContext';

interface RuleGroupProps {
  collapsed?: boolean;
  onClickGroup: () => void;
  children?: React.ReactNode;
  group: FeeParametrizedRuleGroupSummary.AsObject;
  index: number;
  addDefaultAutomatically?: boolean;
}

const RuleGroup = (props: RuleGroupProps) => {
  const { addDefaultAutomatically, children, collapsed = false, group, index, onClickGroup } = props;
  const { windowType } = useWindow();
  const { removeGroup, toggleGroupCondition } = useTriggerContext();

  const [currentRuleId, setCurrentRuleId] = useState('');
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const renderRuleGroupHeader = () => (
    <div
      className="items-center flex flex-row justify-between p-4"
      onClick={onClickGroup}
    >
      <span className="font-semibold text-on-surface-active">
        {`Rule Group ${index + 1}`}
      </span>
      <Menu
        options={[{
          icon: 'Delete',
          label: 'Remove',
          onClick: () => setShowWarning(true),
        }]}
        triggerItem={<Icon icon="MoreVert" variant="interactive" />}
      />
    </div>
  );

  const renderRuleGroupContent = () => (
    <>
      <div
        className={clsx(
          {
            'p-0': windowType === 'mobile',
            'py-2 px-0': windowType !== 'mobile',
          },
        )}
        onClick={onClickGroup}
        onKeyDown={onClickGroup}
        role="button"
        tabIndex={-1}
      >
        {
          group.rulesList.map((rule, index) => (
            <div className={clsx({ 'mt-4': index !== 0 })}>
              <Rule
                key={rule.id}
                addDefaultAutomatically={addDefaultAutomatically}
                collapsed={windowType !== 'mobile' ? false : currentRuleId !== rule.id}
                onClickRule={() => setCurrentRuleId(rule.id)}
                rule={rule}
                ruleGroupId={group.id}
              />
            </div>
          ))
        }
      </div>
      <div className="items-center flex flex-row p-4 pt-10 sm:py-2 sm:px-4">
        <Switch
          displayOnly
          elevation
          onSelect={() => toggleGroupCondition(group.id)}
          optionsLabels={[{
            disabled: false,
            name: 'AND',
          }, {
            disabled: false,
            name: 'OR',
          }]}
          selectedItem={group.applyAndBetweenRules ? 'AND' : 'OR'}
        />
        <Typography className="text-on-surface-inactive ml-[10px]" variant="body2">
          Between rules
        </Typography>
      </div>
      {children && (
        <div className="p-4">
          {children}
        </div>
      )}
    </>
  );

  const renderRuleGroupWarning = () => (
    <TriggerWarning handlePublish={() => removeGroup(group.id)} innerText={'You\'re about to remove this rule group, are you sure?'} onClose={() => setShowWarning(false)} title="You will remove a Rule Group" />
  );
  return (
    <div
      className={clsx(
        'bg-surface rounded-sm shadow-one',
        {
          'border-l-[6px] border-l-primary-on-surface': collapsed,
          'mt-4': index > 0,
        },
      )}
      onClick={onClickGroup}
      onKeyDown={onClickGroup}
      role="button"
      tabIndex={-1}
    >
      {renderRuleGroupHeader()}
      {!collapsed && renderRuleGroupContent()}
      {showWarning && renderRuleGroupWarning()}
    </div>
  );
};

export { RuleGroup };
