import { Content, Description, Dialog as RDialog, Overlay, Portal } from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useWindow } from '@/Utils/Helpers/useWindow';

export type DialogVariant = 'content' | 'full';
export type DialogProps =
  React.ComponentPropsWithoutRef<typeof Content>
  & React.ComponentPropsWithoutRef<typeof RDialog>
  & { ariaDesc?: string; renderWithin?: boolean; variant?: DialogVariant; overlayClassName?: string };

export const Dialog = forwardRef<
React.ElementRef<typeof Content>,
DialogProps
>(({ ariaDesc, className, forceMount, onOpenChange, overlayClassName, renderWithin = true, variant = 'full', ...rest }, ref) => {
  const { windowType } = useWindow();

  const [re, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <RDialog
        defaultOpen={rest.defaultOpen}
        modal
        onOpenChange={onOpenChange}
        open={rest.open}
      >

        <Portal container={renderWithin ? re : undefined} forceMount={forceMount}>
          <Overlay className={clsx('fixed inset-0 pointer-events-auto bg-black/50 data-[state=open]:animate-dialogOverlayShow z-50', overlayClassName, {
            hidden: windowType === 'mobile',
          })}
          />

          <Content
            ref={ref}
            id={variant === 'full' ? 'page-dialog' : 'alert'}
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
            {...rest}
            className={twMerge(clsx(
              'z-50 !fixed bg-surface overflow-hidden data-[state=open]:animate-dialogContentShow',
              {
                '!w-auto': variant === 'full',
                'inset-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-fit h-fit': variant === 'content' && windowType !== 'mobile',
                'top-0 left-0 right-0 bottom-0': windowType === 'mobile',
                'top-6 bottom-6 left-6 right-6': windowType !== 'mobile' && variant === 'full',
              },
            ), className)}
            {...(!ariaDesc && { 'aria-describedby': undefined })}
          >
            {rest.children}
          </Content>

          <VisuallyHidden>
            <Description>{ariaDesc}</Description>
          </VisuallyHidden>
        </Portal>
      </RDialog>
      { renderWithin && <div ref={(ref) => setRef(ref)} style={{ position: 'absolute' }} />}
    </>
  );
});
