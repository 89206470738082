import clsx from 'clsx';

import { Button } from './Button';
import { DynamicIcon } from './DynamicIcon';
import { Typography } from './Typography';

export interface IErrorMessageProps {
  title: string;
  subtitle: string;
  onClick?: () => void;
  buttonLabel?: string;
  className?: string;
  icon?: string;
}

const ErrorMessage = (props: IErrorMessageProps) => {
  const {
    buttonLabel = 'Try Again',
    className,
    icon = 'highlight_off',
    onClick,
    subtitle,
    title,
  } = props;

  return (
    <div
      className={
        clsx(
          'flex items-center flex-col justify-center mt-[50%] tablet:mt-0 tablet:translate-y-full w-full',
          className,
        )
      }
    >
      <DynamicIcon
        className="mb-4 w-14 h-14 text-on-surface-inactive"
        icon={icon}
      />
      <Typography className="my-4 text-center" variant="display6">
        {title}
      </Typography>
      <span
        className="block text-on-surface-inactive font-one text-sm mb-3 text-center"
      >
        {subtitle}
      </span>
      <Button
        className="max-h-9 max-w-[120px] min-h-[36px] min-w-[120px] p-0 uppercase"
        label={buttonLabel}
        onClick={onClick}
        variant="secondary"
      />
    </div>
  );
};

export { ErrorMessage };
