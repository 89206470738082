import { useState } from 'react';

import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useIsOfficerLicensed } from '@/API/Queries/user/useIsOfficerLicensed';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import { AutoComplete } from '../AutoComplete';
import { UnlicensedStateDialog } from './UnlicensedStateDialog';

interface StateSelectProps {
  panelElement: PanelElement.AsObject;
  className?: string;
}

export const StateSelect = (props: StateSelectProps) => {
  const { className, panelElement } = props;
  const {
    disabled,
    headerText: placeholder,
    id,
    optionsList: options,
    path: panelElementPath,
    requirement,
    validatorsList,
  } = panelElement;

  const { mutate: isLicensed } = useIsOfficerLicensed();
  const [unlicensedStateOpen, setUnlicensedStateOpen] = useState(false);
  const [value, setValue, { disabled: conditionallyDisabled, errorMessage, hidden }] = useDynamicFormRegister(
    id,
    {
      fromDynamic: (v) => options.find((option) => option.id === toString(v)) || null,
      toDynamic: (v) => fromString(v?.id || ''),
    },
    { requirement },
  );

  const validateState = Boolean(validatorsList.find((validator) => validator.type === 'validate_state'));

  const handleOnChange = (option: PanelElementOption.AsObject) => {
    if (option) {
      const { id: state } = option;
      const path = option.path || panelElementPath;
      setValue(option, option.headerText, path);

      if (!validateState) {
        return;
      }

      isLicensed(state, {
        onSuccess: (response) => {
          const licensed = !!response?.value;
          setUnlicensedStateOpen(!licensed);
        },
      });
    }
  };

  if (hidden) return null;
  return (
    <>
      <AutoComplete
        className={className}
        disabled={disabled || conditionallyDisabled}
        displayOnly
        errorMessage={errorMessage}
        onSelect={handleOnChange}
        optionKey="headerText"
        options={options}
        placeholder={placeholder}
        required={requirement?.required}
        value={value?.headerText}
      />
      <UnlicensedStateDialog onClickDismiss={() => setUnlicensedStateOpen(false)} open={unlicensedStateOpen} />
    </>
  );
};
