import { useCreateUser } from '@/API/Queries/user/useCreateUser';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanel, getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface CreateUserProps {
  closeModal: () => void;
}

const maxLength = 'max-length';

const CreateUser = (props: CreateUserProps) => {
  const { closeModal } = props;
  const { showSnackBar } = useShared();
  const { isLoading: saving, mutate: createUser } = useCreateUser();

  const handleSubmitSuccess = () => {
    closeModal();
    showSnackBar({ message: 'User created', open: true });
  };

  const onSubmit = (data: DynamicDataElementValues) => {
    const user = {
      email: data.email?.value,
      firstName: data.firstName?.value,
      lastName: data.lastName?.value,
      phoneNumber: data.phoneNumber?.value,
      skillsetGroupIds: [],
    };
    createUser(user, { onSuccess: handleSubmitSuccess });
  };

  return (
    <FullPageStepper
      close={closeModal}
      handleSubmit={onSubmit}
      headerAction={{
        label: 'Discard',
        leftIcon: 'Delete',
        onClick: closeModal,
        variant: 'tertiary',
      }}
      openModal
      panels={[
        getPanel({
          headerText: 'User Information',
          id: '1',
          panelElementsList: [
            getPanelElement({
              headerText: 'First Name',
              id: 'firstName',
              requirement: { errorMessage: 'First Name is required', required: true },
              type: 'text',
              validatorsList: [
                {
                  errorMessage: 'First name may include only letters, apostrophes, and spaces.',
                  type: 'regex',
                  value: "^[a-zA-Z_ ']*$",
                },
                {
                  errorMessage: 'First name should not exceed 20 characters',
                  type: maxLength,
                  value: '20',
                },
              ],
            }),
            getPanelElement({
              headerText: 'Last Name',
              id: 'lastName',
              requirement: { errorMessage: 'Last Name is required', required: true },
              type: 'text',
              validatorsList: [
                {
                  errorMessage: 'Last name may incldue only letters, apostrophes, and spaces.',
                  type: 'regex',
                  value: "^[a-zA-Z_ ']*$",
                },
                {
                  errorMessage: 'Last name should not exceed 30 characters',
                  type: maxLength,
                  value: '30',
                },
              ],
            }),
            getPanelElement({
              headerText: 'Email',
              id: 'email',
              type: 'text',
              validatorsList: [
                {
                  errorMessage: 'You must enter a valid email address to proceed (##@##.##)',
                  type: 'regex',
                  value: "^[a-zA-Z0-9\\._%+!$&*=^|~#%'`?{}\\-]+@([a-zA-Z0-9\\-]+\\.){1,}([a-z]{2,16})$",
                },
              ],
            }),
            getPanelElement({
              headerText: 'Phone Number',
              id: 'phoneNumber',
              mask: { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'phone' },
              type: 'text',
              validatorsList: [
                {
                  errorMessage: 'You must enter a valid phone number to proceed (###-###-####)',
                  type: 'regex',
                  value: '^\\d{10}$',
                },
              ],
            }),
          ],
        }),
      ]}
      saving={saving}
     title="Create new User"
    />
  );
};

export { CreateUser };
