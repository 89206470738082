import { useParams } from 'react-router';

import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { LicenseAuthorityEnum, LicenseScopeEnum } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { useUpdateUser } from '@/API/Queries/user/useUpdateUser';
import { useUpsertUserLicense } from '@/API/Queries/user/useUpsertUserLicense';
import { useUser } from '@/API/Queries/user/useUser';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { LicensesTable } from '@/Components/Features/LicenseTable/LicensesTable';
import { getTimestampFromDate } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const UserLicense = () => {
  const { id = '' } = useParams();

  const { data: user, isLoading, refetch } = useUser(id);
  const { mutate: upsertUserLicense } = useUpsertUserLicense();
  const { mutate: updateUser } = useUpdateUser();
  const { showSnackBar } = useShared();

  const handleUpsertLicenseSuccess = (selectedLicense?: License.AsObject) => {
    if (selectedLicense) {
      showSnackBar({ message: `${selectedLicense.licenseLegalName} updated`, open: true });
    } else {
      showSnackBar({ message: 'License created', open: true });
    }
  };

  const handleLicenseSubmit = (formData: DynamicDataElementValues, selectedLicense?: License.AsObject) => {
    const { enabled, expirationDate, licenseId, licenseName, regulator, startDate, status } = formData;
    const updateObj = {
      license: {
        enabled: enabled.value.id === 'yes',
        expirationDate: getTimestampFromDate(expirationDate.value).toObject(),
        id: selectedLicense?.id || '',
        issueDate: { nanos: 0, seconds: 0 },
        licenseAuthorityType: LicenseAuthorityEnum.LICENSE_AUTHORITY_ENUM_NMLS,
        licenseDescription: '',
        licenseId: licenseId.value,
        licenseLegalName: licenseName.value,
        licenseScope: regulator.value.id || '',
        licenseScopeType: LicenseScopeEnum.LICENSE_SCOPE_ENUM_STATE,
        licenseStatusType: status.value.id,
        startDate: getTimestampFromDate(startDate.value).toObject(),
      },
      userId: id,
    };
    upsertUserLicense(updateObj, { onSuccess: () => handleUpsertLicenseSuccess(selectedLicense) });
  };

  const handleSubmitNMLS = (values: DynamicDataElementValues, close: () => void) => {
    if (user) {
      updateUser({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        nationalLicensingId: values.license.value,
        phoneNumber: user.phoneNumber,
        profileImageUrl: user.profileImageUrl,
        skillsetGroupIds: user.skillsetGroupList.map((e) => e.id),
        userId: user.id,
      }, {
        onSettled: close,
        onSuccess: () => {
          refetch();
        },
      });
    }
  };

  return (
    <LicensesTable
      handleSubmit={handleLicenseSubmit}
      handleSubmitNMLS={handleSubmitNMLS}
      isLoading={isLoading}
      licenseNumber={user?.nationalLicensingId || ''}
      licenses={user?.licensesList || []}
      title="User"
    />
  );
};

export { UserLicense };
