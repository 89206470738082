interface IconProps {
  className?: string;
}

const DataUsage = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 96 960 960"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M481 976q-82 0-155-31.5T198.5 859q-54.5-54-86-127T81 577q0-158 106.5-272T452 179v102q-115 11-192 95.5T183 577q0 124 87 210.5T481 874q72 0 136-32.5T726 750l88 51q-58 83-145.5 129T481 976Zm362-229-88-49q12-31 18-61.5t6-60.5q0-116-76.5-201T512 280V178q157 11 263 124.5T881 572q0 45-9.5 88.5T843 747Z"
    />
  </svg>
);

export default DataUsage;
