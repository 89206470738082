/* eslint-disable react/button-has-type */
import clsx from 'clsx';

import { LoanPageActionDisplayVariant } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { useNavigate } from '@/Routes/NavigationContext';
import { cn } from '@/Utils/Helpers/uiBuilders';

import { Icon } from './Icon';
import type { TypographyVariant } from './Typography';
import { Typography } from './Typography';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
export type ButtonHeightType = 'default' | 'tall';
export type ButtonElevation = 'eight' | 'four' | 'nine' | 'one' | 'six' | 'sixteen' | 'three' | 'twelve' | 'twentyFour' | 'two' | 'xs';
export type ButtonType = 'button' | 'reset' | 'submit';

export const ButtonVariants: Record<LoanPageActionDisplayVariant, ButtonVariant> = {
  [LoanPageActionDisplayVariant.LOAN_PAGE_ACTION_DISPLAY_VARIANT_PRIMARY]: 'primary',
  [LoanPageActionDisplayVariant.LOAN_PAGE_ACTION_DISPLAY_VARIANT_SECONDARY]: 'secondary',
  [LoanPageActionDisplayVariant.LOAN_PAGE_ACTION_DISPLAY_VARIANT_TERTIARY]: 'tertiary',
};

export interface IButtonProps {
  id?: string;
  name?: string;
  additionalData?: any;
  danger?: boolean;
  label: string;
  variant?: ButtonVariant;
  type?: ButtonType;
  heightType?: ButtonHeightType;
  linkTo?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  noCaps?: boolean;
  elevation?: ButtonElevation;
  externalLink?: boolean;
  iconClassName?: string;
  tabIndex?: number;
  onBlurHandler?: () => void;
  textVariant?: TypographyVariant;
}

const getElevation = (elevation?: ButtonElevation): string => {
  const elevationMap = {
    eight: 'shadow-eight',
    four: 'shadow-four',
    nine: 'shadow-nine',
    one: 'shadow-one',
    six: 'shadow-six',
    sixteen: 'shadow-sixteen',
    three: 'shadow-three',
    twelve: 'shadow-twelve',
    twentyFour: 'shadow-twentyFour',
    two: 'shadow-two',
    xs: 'shadow-xs',
  };
  return elevationMap[elevation || 'two'];
};

const Button = (props: IButtonProps) => {
  const {
    additionalData,
    className,
    danger = false,
    disabled = false,
    elevation,
    externalLink = false,
    heightType = 'default',
    iconClassName,
    id,
    isLoading = false,
    label,
    leftIcon,
    linkTo,
    noCaps = false,
    onBlurHandler,
    onClick,
    rightIcon,
    tabIndex,
    textVariant = 'button',
    type = 'button',
    variant = 'primary',
  } = props;

  const navigate = useNavigate();
  const hasIcon = Boolean(leftIcon) || Boolean(rightIcon);

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'button') event.preventDefault();
    if (linkTo) {
      if (externalLink) {
        window.location.replace(linkTo);
      } else {
        navigate(linkTo);
      }
    } else if (onClick && !isLoading) onClick(event);
  };

  return (
    <button
      className={
        cn(
          'rounded cursor-pointer font-one text-sm font-semibold not-italic w-fit tracking-[1.25px] disabled:cursor-not-allowed',
          'leading-4 outline-none overflow-hidden py-1.5 text-center no-underline uppercase disabled:shadow-none disabled:text-on-surface-disabled min-w-fit',
          {
            '!w-28 !h-12 !rounded-[100px]': heightType === 'tall',
            'flex flex-row items-center justiy-center': hasIcon,
            'flex items-center justify-center shadow-none pointer-none': isLoading,
            'focus:animate-borderGrow focus:!border-danger-on-surface bg-danger-on-surface-states-enabled text-danger-on-surface focus:bg-danger-on-surface-states-focus hover:bg-danger-on-surface-states-pressed': danger && ['secondary', 'tertiary'].includes(variant),
            'focus:animate-borderGrow focus:!border-on-danger-active bg-on-danger-states-enabled text-on-danger-active focus:bg-on-danger-states-focus hover:bg-on-danger-states-focus active:bg-on-danger-states-pressed': danger && variant === 'primary',
            'focus:animate-borderGrow focus:!border-on-primary-states-focus': ['tertiary', 'primary'].includes(variant),
            'focus:animate-borderGrow focus:!border-on-primary-states-focus border border-on-surface-stroke': variant === 'secondary',
            'focus:animate-borderGrow focus:!border-on-surface-stroke bg-primary text-on-primary-active [&_svg]:text-on-primary-active focus:bg-on-primary-states-focus hover:bg-on-primary-states-hover active:bg-on-primary-states-pressed disabled:bg-on-primary-states-disabled disabled:text-on-surface-disabled': variant === 'primary' && !danger,
            'focus:animate-borderGrow focus:!border-primary-on-surface bg-transparent text-primary-on-surface [&_svg]:text-primary-on-surface focus:bg-primary-on-surface-states-focus hover:bg-primary-on-surface-states-hover hover:text-primary-on-surface hover:disabled:bg-transparent active:bg-primary-on-surface-states-pressed': ['secondary', 'tertiary'].includes(variant) && !danger,
            'h-9': heightType === 'default',
            'normal-case tracking-[0.25px]': noCaps,
            'order-last': type === 'submit',
            'pointer-events-none': disabled,
            'px-2': variant === 'tertiary',
            'px-4': variant !== 'tertiary',
            'text-on-surface-disabled [&_svg]:text-on-surface-disabled': disabled,
            [`transition-shadow ${getElevation(elevation)}`]: variant === 'primary' && !disabled,
            'text-primary-on-surface [&_svg]:!text-primary-on-surface': ['secondary', 'tertiary'].includes(variant) && !disabled && !danger,
          },
          className,
        )
      }
      data-additionalinfo={additionalData}
      data-component="button"
      data-testid={`button-${id}`}
      disabled={disabled || false}
      id={id}
      name={props.name}
      onBlur={() => {
        if (onBlurHandler) onBlurHandler();
      }}
      onClick={onButtonClick}
      tabIndex={tabIndex || undefined}
      type={type}
    >
      <div className={cn('flex items-center flex-row content-center justify-center w-full')}>
        {leftIcon && (
          <Icon className={clsx('mr-2', iconClassName)} icon={leftIcon} variant="default" />
        )}
        {isLoading ? <Icon className={clsx('animate-spin h-4')} icon="Loop" /> : <Typography className="whitespace-nowrap" variant={textVariant}>{label}</Typography>}
        {rightIcon && (
          <Icon className={clsx('ml-2', iconClassName)} icon={rightIcon} />
        )}
      </div>
    </button>
  );
};

export { Button };
