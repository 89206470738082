import type { SwitchProps as ComponentProps } from './Switch.component';
import Component from './Switch.component';
import type { SwitchProps as DDEProps } from './Switch.dde';
import DDE from './Switch.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
  disabled?: boolean;
}

type SwitchProps = ExtendedComponentProps | ExtendedDDEProps;

const Switch = (props: SwitchProps) => {
  const { displayOnly } = props;
  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { Switch };
