// source: Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb = require('../../Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AssociationSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Badge', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.BehaviorType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CustomRenderType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldFilters', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.GroupTypeCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HeaderAction', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HyperLink', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HyperLinkType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ParentField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TextField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TextTemplate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TriggerType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.HeaderAction.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HeaderAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HeaderAction.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HeaderAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HyperLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HyperLink.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HyperLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TextTemplate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TextTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TextTemplate.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TextTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TextField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TextField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TextField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TextField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldFilters.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldFilters.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ParentField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ParentField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ParentField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.AssociationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.AssociationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Badge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Badge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Badge.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Badge';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    validationOperator: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement;
  return proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator} */ (reader.readEnum());
      msg.setValidationOperator(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValidationOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator = {
  VALIDATION_OPERATOR_UNKNOWN: 0,
  VALIDATION_OPERATOR_EQUALS: 1,
  VALIDATION_OPERATOR_NOT_EQUALS: 2,
  VALIDATION_OPERATOR_LESS_THAN: 3,
  VALIDATION_OPERATOR_GREATER_THAN: 4,
  VALIDATION_OPERATOR_IN: 5,
  VALIDATION_OPERATOR_NOT_IN: 6,
  VALIDATION_OPERATOR_HAS_VALUE: 7
};

/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ValidationOperator validation_operator = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.getValidationOperator = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.ValidationOperator} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.setValidationOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourcePanelElement: (f = msg.getSourcePanelElement()) && proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.toObject(includeInstance, f),
    targetElementsList: jspb.Message.toObjectList(msg.getTargetElementsList(),
    proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.toObject, includeInstance),
    actionType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sourcePanelElementsList: jspb.Message.toObjectList(msg.getSourcePanelElementsList(),
    proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation;
  return proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinaryFromReader);
      msg.setSourcePanelElement(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.deserializeBinaryFromReader);
      msg.addTargetElements(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinaryFromReader);
      msg.addSourcePanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourcePanelElement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.serializeBinaryToWriter
    );
  }
  f = message.getTargetElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.serializeBinaryToWriter
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSourcePanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SourcePanelElement source_panel_element = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.getSourcePanelElement = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.setSourcePanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.clearSourcePanelElement = function() {
  return this.setSourcePanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.hasSourcePanelElement = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ShowFieldValidationTarget target_elements = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget>}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.getTargetElementsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.setTargetElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.addTargetElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.clearTargetElementsList = function() {
  return this.setTargetElementsList([]);
};


/**
 * optional ShowFieldValidationActionType action_type = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.getActionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated SourcePanelElement source_panel_elements = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement>}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.getSourcePanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.setSourcePanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.addSourcePanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation.prototype.clearSourcePanelElementsList = function() {
  return this.setSourcePanelElementsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    widgetsIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    panelElementsIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    headerActionsList: jspb.Message.toObjectList(msg.getHeaderActionsList(),
    proto.wilqo.api.mortgage_dynamic_data.HeaderAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget;
  return proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addWidgetsIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPanelElementsIds(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.HeaderAction;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.HeaderAction.deserializeBinaryFromReader);
      msg.addHeaderActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWidgetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getPanelElementsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getHeaderActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.HeaderAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string widgets_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.getWidgetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.setWidgetsIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.addWidgetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.clearWidgetsIdsList = function() {
  return this.setWidgetsIdsList([]);
};


/**
 * repeated string panel_elements_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.getPanelElementsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.setPanelElementsIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.addPanelElementsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.clearPanelElementsIdsList = function() {
  return this.setPanelElementsIdsList([]);
};


/**
 * repeated HeaderAction header_actions = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HeaderAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.getHeaderActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HeaderAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.HeaderAction, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HeaderAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.setHeaderActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.addHeaderActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.HeaderAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationTarget.prototype.clearHeaderActionsList = function() {
  return this.setHeaderActionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HeaderAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerActionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionSubtypeIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    hideParent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HeaderAction;
  return proto.wilqo.api.mortgage_dynamic_data.HeaderAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderActionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addActionSubtypeIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideParent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HeaderAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderActionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionSubtypeIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getHideParent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string header_action_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.getHeaderActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.setHeaderActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string action_subtype_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.getActionSubtypeIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.setActionSubtypeIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.addActionSubtypeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.clearActionSubtypeIdsList = function() {
  return this.setActionSubtypeIdsList([]);
};


/**
 * optional bool hide_parent = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.getHideParent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HeaderAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HeaderAction.prototype.setHideParent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelElementId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    behaviorType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    triggerType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    toggleConsent: (f = msg.getToggleConsent()) && proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior;
  return proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.BehaviorType} */ (reader.readEnum());
      msg.setBehaviorType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.TriggerType} */ (reader.readEnum());
      msg.setTriggerType(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.deserializeBinaryFromReader);
      msg.setToggleConsent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBehaviorType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTriggerType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getToggleConsent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_element_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BehaviorType behavior_type = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.BehaviorType}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.getBehaviorType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.BehaviorType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.BehaviorType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.setBehaviorType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional TriggerType trigger_type = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TriggerType}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.getTriggerType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.TriggerType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TriggerType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.setTriggerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ToggleConsentSettings toggle_consent = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.getToggleConsent = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.setToggleConsent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.clearToggleConsent = function() {
  return this.setToggleConsent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior.prototype.hasToggleConsent = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkboxPanelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    methodTypePanelElementId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientIpPanelElementId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    consentProviderPanelElementId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings;
  return proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckboxPanelElementId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodTypePanelElementId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientIpPanelElementId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsentProviderPanelElementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckboxPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethodTypePanelElementId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientIpPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConsentProviderPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string checkbox_panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.getCheckboxPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.setCheckboxPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string method_type_panel_element_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.getMethodTypePanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.setMethodTypePanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_ip_panel_element_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.getClientIpPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.setClientIpPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string consent_provider_panel_element_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.getConsentProviderPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ToggleConsentSettings.prototype.setConsentProviderPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourcePanelElement: (f = msg.getSourcePanelElement()) && proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.toObject(includeInstance, f),
    targetElementsList: jspb.Message.toObjectList(msg.getTargetElementsList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides;
  return proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.deserializeBinaryFromReader);
      msg.setSourcePanelElement(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.deserializeBinaryFromReader);
      msg.addTargetElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourcePanelElement();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement.serializeBinaryToWriter
    );
  }
  f = message.getTargetElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SourcePanelElement source_panel_element = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.getSourcePanelElement = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SourcePanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.setSourcePanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.clearSourcePanelElement = function() {
  return this.setSourcePanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.hasSourcePanelElement = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FieldPanelElementOverridesTarget target_elements = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.getTargetElementsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.setTargetElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.addTargetElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides.prototype.clearTargetElementsList = function() {
  return this.setTargetElementsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementsIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    overridesMap: (f = msg.getOverridesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget;
  return proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPanelElementsIds(value);
      break;
    case 2:
      var value = msg.getOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated string panel_elements_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.getPanelElementsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.setPanelElementsIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.addPanelElementsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.clearPanelElementsIdsList = function() {
  return this.setPanelElementsIdsList([]);
};


/**
 * map<string, string> overrides = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.getOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverridesTarget.prototype.clearOverridesMap = function() {
  this.getOverridesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HyperLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    url: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hyperlinkTarget: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HyperLink;
  return proto.wilqo.api.mortgage_dynamic_data.HyperLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget} */ (reader.readEnum());
      msg.setHyperlinkTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HyperLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHyperlinkTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional HyperLinkType type = 5;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkType}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string url = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setUrl = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.clearUrl = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional HyperLinkTarget hyperlink_target = 8;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.getHyperlinkTarget = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLink.prototype.setHyperlinkTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TextTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textFieldsList: jspb.Message.toObjectList(msg.getTextFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.TextField.toObject, includeInstance),
    missingFieldReplacementValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TextTemplate;
  return proto.wilqo.api.mortgage_dynamic_data.TextTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TextField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TextField.deserializeBinaryFromReader);
      msg.addTextFields(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMissingFieldReplacementValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TextTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TextField.serializeBinaryToWriter
    );
  }
  f = message.getMissingFieldReplacementValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TextField text_fields = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TextField>}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.getTextFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TextField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TextField, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TextField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.setTextFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.addTextFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.TextField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.clearTextFieldsList = function() {
  return this.setTextFieldsList([]);
};


/**
 * optional string missing_field_replacement_value = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.getMissingFieldReplacementValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextTemplate} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TextTemplate.prototype.setMissingFieldReplacementValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TextField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldValue: (f = msg.getFieldValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TextField;
  return proto.wilqo.api.mortgage_dynamic_data.TextField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TextField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TextField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.RenderableValue field_value = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.getFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.setFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TextField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.clearFieldValue = function() {
  return this.setFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TextField.prototype.hasFieldValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentFieldsList: jspb.Message.toObjectList(msg.getParentFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.ParentField.toObject, includeInstance),
    dependencyInfoList: jspb.Message.toObjectList(msg.getDependencyInfoList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldFilters;
  return proto.wilqo.api.mortgage_dynamic_data.FieldFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ParentField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ParentField.deserializeBinaryFromReader);
      msg.addParentFields(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.deserializeBinaryFromReader);
      msg.addDependencyInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ParentField.serializeBinaryToWriter
    );
  }
  f = message.getDependencyInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    childFieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentFieldIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo;
  return proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChildFieldId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addParentFieldIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChildFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string child_field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.getChildFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.setChildFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string parent_field_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.getParentFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.setParentFieldIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.addParentFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo.prototype.clearParentFieldIdsList = function() {
  return this.setParentFieldIdsList([]);
};


/**
 * repeated ParentField parent_fields = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ParentField>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.getParentFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ParentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ParentField, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ParentField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.setParentFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ParentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.addParentFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.ParentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.clearParentFieldsList = function() {
  return this.setParentFieldsList([]);
};


/**
 * repeated DependencyInfo dependency_info = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.getDependencyInfoList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.setDependencyInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.addDependencyInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldFilters.DependencyInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldFilters} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldFilters.prototype.clearDependencyInfoList = function() {
  return this.setDependencyInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ParentField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ParentField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ParentField;
  return proto.wilqo.api.mortgage_dynamic_data.ParentField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ParentField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ParentField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ParentField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ParentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ParentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ParentField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.GroupTypeCase = {
  GROUP_TYPE_NOT_SET: 0,
  GOOGLE_ADDRESS: 1
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.GroupTypeCase}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.getGroupTypeCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.GroupTypeCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    googleAddress: (f = msg.getGoogleAddress()) && proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.deserializeBinaryFromReader);
      msg.setGoogleAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGoogleAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.serializeBinaryToWriter
    );
  }
};


/**
 * optional GoogleAddressLookup google_address = 1;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.getGoogleAddress = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.setGoogleAddress = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.clearGoogleAddress = function() {
  return this.setGoogleAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.prototype.hasGoogleAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressLineFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressAdditionalLineFieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cityFieldId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stateFieldId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stateCodeFieldId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zipCodeFieldId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryFieldId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    countyNameFieldId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    countyCodeFieldId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup;
  return proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineFieldId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityFieldId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateFieldId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCodeFieldId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCodeFieldId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryFieldId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyNameFieldId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyCodeFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressLineFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressAdditionalLineFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCityFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStateFieldId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStateCodeFieldId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZipCodeFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryFieldId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountyNameFieldId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountyCodeFieldId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string address_line_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getAddressLineFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setAddressLineFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_additional_line_field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getAddressAdditionalLineFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setAddressAdditionalLineFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city_field_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getCityFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setCityFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state_field_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getStateFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setStateFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state_code_field_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getStateCodeFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setStateCodeFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zip_code_field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getZipCodeFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setZipCodeFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_field_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getCountryFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setCountryFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string county_name_field_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getCountyNameFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setCountyNameFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string county_code_field_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.getCountyCodeFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GoogleAddressLookup.prototype.setCountyCodeFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.toObject, includeInstance),
    selectionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.AssociationSettings;
  return proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType} */ (reader.readEnum());
      msg.setSelectionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.serializeBinaryToWriter
    );
  }
  f = message.getSelectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType = {
  SELECTION_TYPE_UNKNOWN: 0,
  SELECTION_TYPE_SINGLE: 1,
  SELECTION_TYPE_MULTIPLE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    label: (f = msg.getLabel()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem;
  return proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue value = 1;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue label = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.getLabel = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AssociationItem items = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.AssociationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SelectionType selection_type = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType}
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.getSelectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.SelectionType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AssociationSettings.prototype.setSelectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Badge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Badge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    variant: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Badge}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Badge;
  return proto.wilqo.api.mortgage_dynamic_data.Badge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Badge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Badge}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Badge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Badge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue value = 1;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Badge} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Badge} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.UxDisplayStatusEnum variant = 2;
 * @return {!proto.wilqo.shared.models.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.getVariant = function() {
  return /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Badge} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Badge.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.BehaviorType = {
  BEHAVIOR_TYPE_UNKNOWN: 0,
  BEHAVIOR_TYPE_TOGGLE_CONSENT: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.TriggerType = {
  TRIGGER_TYPE_UNKNOWN: 0,
  TRIGGER_TYPE_ON_BLUR: 1,
  TRIGGER_TYPE_ON_CHANGE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState = {
  WIDGET_COLLAPSED_STATE_UNKNOWN: 0,
  WIDGET_COLLAPSED_STATE_EXPANDED: 1,
  WIDGET_COLLAPSED_STATE_COLLAPSED: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.CustomRenderType = {
  CUSTOM_RENDER_TYPE_NONE: 0,
  CUSTOM_RENDER_TYPE_HMDA: 1,
  CUSTOM_RENDER_TYPE_URLA_FORM: 2,
  CUSTOM_RENDER_TYPE_ACTIVITY_TABLE_FORM: 3,
  CUSTOM_RENDER_TYPE_CONSOLIDATED_INCOME_FORM: 4,
  CUSTOM_RENDER_TYPE_CENTRALIZED_ADDRESS_TABLE: 5,
  CUSTOM_RENDER_TYPE_FORM_INTERVIEW: 6,
  CUSTOM_RENDER_TYPE_REO_ADDRESS_TABLE: 7,
  CUSTOM_RENDER_TYPE_FORM_CARDLESS: 8,
  CUSTOM_RENDER_TYPE_TABLE_FORM_CARD: 9,
  CUSTOM_RENDER_TYPE_REO_RENTAL_INCOME_TABLE: 10,
  CUSTOM_RENDER_TYPE_SUBJECT_PROPERTY_INFO: 11,
  CUSTOM_RENDER_TYPE_ADD_WILQO_PARTY_BORROWER: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLinkType = {
  HYPER_LINK_TYPE_UNKNOWN: 0,
  HYPER_LINK_TYPE_LINK: 1,
  HYPER_LINK_TYPE_COLLAPSIBLE: 2,
  HYPER_LINK_TYPE_MODAL: 3,
  HYPER_LINK_TYPE_PDF: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.HyperLinkTarget = {
  HYPER_LINK_TARGET_UNKNOWN: 0,
  HYPER_LINK_TARGET_SELF: 1,
  HYPER_LINK_TARGET_BLANK: 2,
  HYPER_LINK_TARGET_MODAL: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidationActionType = {
  SHOW_FIELD_VALIDATION_ACTION_TYPE_UNKNOWN: 0,
  SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN: 1,
  SHOW_FIELD_VALIDATION_ACTION_TYPE_DISABLED: 2,
  SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN_IGNORE_ON_SAVE: 3
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
