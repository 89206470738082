import type { DefaultValueConfig } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/DefaultValueConfig_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicLoanPage } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { useDynamicPageWithDefaults } from '@/API/Queries/mortgageDynamicData/useDynamicPageWithDefaults';

export const useDialogData = (
  dealId: string,
  pageId: string,
  scopeToken: string,
  tabId: string,
  withoutData = false,
  virtualId?: string,
  dynamicValue?: DynamicDataElement.AsObject,
  widgetType?: DefaultValueConfig.WidgetType,
) => {
  const isPageWithDefault = Boolean(widgetType);
  const pageWithDefaultsData = useDynamicPageWithDefaults(dealId, pageId, scopeToken, tabId, withoutData, dynamicValue, virtualId, widgetType, isPageWithDefault);
  const pageData = useDynamicLoanPage(dealId, pageId, scopeToken, tabId, withoutData, { cacheTime: 0, enabled: !isPageWithDefault });

  if (isPageWithDefault) return pageWithDefaultsData;
  return pageData;
};
