import dayjs from 'dayjs';
import type { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { useParams } from 'react-router';

import { ProcessType } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { useGetProcessesForDeal } from '@/API/Queries/mortgageIntegrations/processStatus/useGetProcessesForDeal';
import { AppBar } from '@/Components/Atoms/AppBar';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { LayoutWithColumns } from '@/Components/Layouts/WithColumn';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { useLayout } from '@/Utils/Hooks/useLayout/useLayout';

import { ProcessInformationTable } from './ProcessInformationTable';

const ProcessInformation = () => {
  const { dealId = '' } = useParams();
  const withColumnsProps = useLayout();

  const convertTimestamp = (timestamp?: Timestamp.AsObject) => {
    if (timestamp && timestamp?.seconds) {
      return dayjs(getDateFromTimestamp(timestamp)).format('MM/DD/YYYY hh:mm:ss');
    }
    return 'MM/DD/YYYY';
  };

  // TODO: this needs to be a multiselect
  const { data, error, isLoading } = useGetProcessesForDeal({
    dealId,
    processTypesList: [
      ProcessType.PROCESS_TYPE_CREDIT,
      ProcessType.PROCESS_TYPE_LODESTAR,
      ProcessType.PROCESS_TYPE_ASURITY,
      ProcessType.PROCESS_TYPE_MERS_REGISTRATION,
      ProcessType.PROCESS_TYPE_FRAUD,
      ProcessType.PROCESS_TYPE_COMPLIANCE,
      ProcessType.PROCESS_TYPE_DOCUMENT_GENERATION,
      ProcessType.PROCESS_TYPE_MORTGAGE_INSURANCE_QUOTES_SEARCH,
      ProcessType.PROCESS_TYPE_DOC_MAGIC_DOCUMENTS,
      ProcessType.PROCESS_TYPE_DOCUTECH_DOCUMENTS,
      ProcessType.PROCESS_TYPE_PRODUCT_PRICE_SEARCH,
      ProcessType.PROCESS_TYPE_PRODUCT_PRICE_DETAILS,
      ProcessType.PROCESS_TYPE_DOC_MAGIC_PLAN_CODE_LIST,
      ProcessType.PROCESS_TYPE_PRODUCT_PRICE_APPLY_SELECTION,
      ProcessType.PROCESS_TYPE_PRODUCT_PRICE_RATE_LOCK,
      ProcessType.PROCESS_TYPE_FLOOD,
      ProcessType.PROCESS_TYPE_DOC_MAGIC_AUDIT,
      ProcessType.PROCESS_TYPE_WEBHOOK_EVENT,
    ],
  });

  const renderRows = () => data?.processesList.map(
    (row) => (
      <tr key={`row-${row.instanceId}`}>
        <td>{row.instanceId}</td>
        <td>{ConvertEnum(ProcessType, row.processType)}</td>
        <td>{convertTimestamp(row.startedOn)}</td>
        <td>{convertTimestamp(row.lastUpdated)}</td>
        <td><ProcessInformationTable processId={row.instanceId} /></td>
      </tr>
    ),
  );

  const renderTable = () => (data?.processesList
    ? (
      <table>
        <thead>
          <tr>
            <th>Instance Identifier</th>
            <th>Process Type</th>
            <th>Started on</th>
            <th>Last Updated on</th>
            <th>Info</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    )
    : <div>No processes to display</div>);

  const renderContent = () => {
    if (error) {
      return (
        <div>
          Some error
        </div>
      );
    }
    if (isLoading) {
      return <Skeleton height="50px" rows={10} variant="rect" />;
    }
    return renderTable();
  };

  return (
    <LayoutWithColumns
      {...withColumnsProps}
      content={renderContent()}
      header={{
        appBar: <AppBar hideMenu />,
        renderDrawer: false,
      }}
      justifyContent="center"
      layoutsList={[8]}
      pageTitle="Process Status"
      renderFooter
    />
  );
};

export { ProcessInformation };
