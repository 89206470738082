import { useMemo, useState } from 'react';

import type { DataFieldTemplateSummary } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb';
import { useDataFieldTemplateForTriggers } from '@/API/Queries/activities/useDataFieldTemplateForTriggers';
import type { SearchDropdownGroup, SearchDropdownOption } from '@/Components/Atoms/SearchDropdown';
import { SearchDropdown } from '@/Components/Atoms/SearchDropdown';
import type { TextFieldVariant } from '@/Components/Atoms/TextField';

import { useTriggerContext } from './TriggerContext';

interface SearchFieldProps {
  ruleId: string;
  ruleGroupId: string;
  removeRule: () => void;
  field?: DataFieldTemplateSummary.AsObject;
  variant?: TextFieldVariant;
}

const SearchFieldComponent = (props: SearchFieldProps) => {
  const { field, removeRule, ruleGroupId, ruleId, variant = 'default' } = props;

  const { updateRuleField } = useTriggerContext();
  const [searchText, setSearchText] = useState('');
  const { data: fieldGroups = [] } = useDataFieldTemplateForTriggers(searchText);

  const setFieldRule = (field?: DataFieldTemplateSummary.AsObject) => {
    if (field) {
      updateRuleField(ruleGroupId, ruleId, field);
    }
  };

  const handleFieldClick = (item?: SearchDropdownOption, groupId?: string) => {
    if (item) {
      const field = fieldGroups
        .find((group) => group.id === groupId)?.dataFieldsList
        .find((field) => field.id === item.id);
      if (field) {
        setFieldRule(field);
      }
    } else {
      removeRule();
    }
  };

  const options = useMemo((): Array<SearchDropdownGroup> => fieldGroups.map((group) => ({
    id: group.id,
    name: group.displayName,
    options: group.dataFieldsList?.map((field) => ({
      id: field.id,
      name: field.name,
    })),
  })), [fieldGroups]);

  return (
    <SearchDropdown
      onChangeInputValue={setSearchText}
      onSelectItem={(item, groupId) => handleFieldClick(item, groupId)}
      optionsGroup={options}
      selectedItem={field?.name}
      variant={variant}
    />
  );
};

const SearchField = SearchFieldComponent;

export { SearchField };
