import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { cn } from '@/Utils/Helpers/uiBuilders';

import { DynamicIcon } from './DynamicIcon';
import { Tooltip } from './Tooltip';

export type IconVariantType = 'default' | 'fixed-background' | 'interactive';
export type IconSizeType = 'lg' | 'md' | 'sm' | 'xl' | 'xs';

export interface IconProps {
  icon: string;
  alert?: boolean;
  variant?: IconVariantType;
  size?: IconSizeType;
  selected?: boolean;
  border?: boolean;
  className?: string;
  asButton?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  animation?: string;
  tooltipMessage?: string;
}

const Icon = forwardRef((props: IconProps, ref: any) => {
  const {
    alert = false,
    animation = '',
    asButton = false,
    border = false,
    className,
    disabled = false,
    icon,
    onClick,
    selected = false,
    size = 'md',
    tooltipMessage = '',
    variant: propVariant,
    ...rest
  } = props;

  const variant = useMemo(() => {
    if (propVariant) return propVariant;
    if (onClick) return 'interactive';
    return 'default';
  }, [propVariant, onClick]);

  const iconClasses = cn('rounded-full flex items-center justify-center', {
    'bg-on-surface-stroke h-10 w-10': variant === 'fixed-background',
    'bg-primary-on-surface-states-activated hover:bg-primary-on-surface-states-hover active:bg-primary-on-surface-states-pressed': variant === 'interactive' && selected,
    'border border-on-surface-stroke': border,
    'cursor-default': variant !== 'interactive',
    'cursor-pointer': variant === 'interactive',
    'h-10 w-10': variant === 'interactive',
    'h-16 w-16': size === 'lg',
    'h-32 w-32': size === 'xl',
    'hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed active:text-on-surface-active': variant === 'interactive' && !selected,
    'text-on-surface-inactive': !selected,
    'text-primary-on-surface': selected,
  }, animation, className);

  const renderIcon = () => (
    <div
      className={iconClasses}
    >
      <DynamicIcon
        className={cn({
          'h-10 w-10': size === 'lg',
          'h-3 w-3': size === 'xs',
          'h-6 w-6': size === 'md',
          'h-[18px] w--[18px]': size === 'sm',
        })}
        icon={icon}
      />
      {alert && <div className="absolute right-2 top-2 w-2 h-2 border border-surface bg-danger-on-surface rounded-full" />}
    </div>
  );

  if (onClick || asButton) {
    const handleIconClick = (e: any) => {
      if (!disabled && onClick) onClick(e);
    };

    return (
      <button
        ref={ref}
        className={twMerge('focus:bg-primary-on-surface-states-activated rounded-full h-10 w-10', className)}
        onClick={handleIconClick}
        tabIndex={-1}
        type="button"
        {...rest}
      >
        {renderIcon()}
      </button>
    );
  }

  if (tooltipMessage !== '') {
    return (
      <div
        className={iconClasses}
      >
        <Tooltip message={tooltipMessage}>
          <DynamicIcon
            className={clsx({
              'h-10 w-10': size === 'lg',
              'h-3 w-3': size === 'xs',
              'h-6 w-6': size === 'md',
              'h-[18px] w--[18px]': size === 'sm',
            })}
            icon={icon}
          />
        </Tooltip>
        {alert && (
          <div
            className="absolute right-2 top-2 w-2 h-2 border border-surface bg-danger-on-surface rounded-full"
          />
        )}
      </div>

    );
  }

  return renderIcon();
});

export { Icon };
