import { useCallback, useEffect, useRef, useState } from 'react';

import { DeleteItemCommandRequest, DeleteItemCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { useCollectionItemDelete } from '@/API/Queries/mortgageDynamicData/useCollectionItemDelete';
import { Button } from '@/Components/Atoms/Button';
import { DialogRoot } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { renderValue } from '@/Utils/Helpers/renderableHelper';

interface Props {
  close: VoidFunction;
  handleDeleteSuccess: (version: number) => void;
  dealId: string;
  scopeToken: string;
  handleDeleteError?: VoidFunction;
}

interface Result {
  showOption?: boolean;
  status: DeleteItemCommandResponse.DeleteStatus;
  associations?: Array<DeleteItemCommandResponse.AssociatedItemResponse.AsObject>;
  message: string;
}

const StatusTypeRelation: Record<DeleteItemCommandResponse.DeleteStatus, DeleteItemCommandRequest.DeleteType> = {
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_HAS_ASSOCIATIONS]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_ITEM_AND_ASSOCIATIONS,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_NO_ASSOCIATIONS]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_ITEM_ONLY,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_UNKNOWN]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ERROR]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_DELETED]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_AND_ASSOCIATIONS_DELETED]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN,
  [DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_CANNOT_BE_DELETED]: DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN,
};

export const DeleteItemCollection = (props: Props) => {
  const { close, dealId, handleDeleteError, handleDeleteSuccess, scopeToken } = props;

  const hasRun = useRef(false);
  const [associationResponse, setAssociationResponse] = useState<Result | undefined>();

  const { version } = useDynamicContext();
  const { isLoading: isDeleting, mutate: deleteFromTable } = useCollectionItemDelete();

  const handleRemoveItem = useCallback((type = DeleteItemCommandRequest.DeleteType.DELETE_TYPE_UNKNOWN) => {
    if (scopeToken) {
      deleteFromTable({
        dealId,
        dealVersion: version,
        scopeToken,
        type,
      },
      {
        onError: () => {
          if (handleDeleteError) handleDeleteError();
        },
        onSuccess: (x: DeleteItemCommandResponse.AsObject) => {
          if (x.status === DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_AND_ASSOCIATIONS_DELETED
            || x.status === DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_DELETED) {
            handleDeleteSuccess(x.dealVersion);
          } else {
            setAssociationResponse({
              associations: x.associatedItemsData?.itemsList || [],
              message: x.displayPrompt,
              showOption: x.addAssociatedItemConfirmationOption,
              status: x.status,
            });
          }
        },
      });
    }
  }, [dealId, deleteFromTable, handleDeleteError, handleDeleteSuccess, scopeToken, version]);

  useEffect(() => {
    if (!hasRun.current && scopeToken) {
      handleRemoveItem();
      hasRun.current = true;
    }
  }, [handleRemoveItem, scopeToken]);

  if (!associationResponse) return null;

  return (
    <DialogRoot.Root onOpenChange={props.close} open={Boolean(props.scopeToken)} variant="content">
      <DialogRoot.Layout>
        <DialogRoot.Header
          title={associationResponse.status !== DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_CANNOT_BE_DELETED
            ? 'Delete Confirmation' : 'Unable to Delete'}
        />
        <DialogRoot.Content>
          <div className="flex flex-col gap-4 p-4">
            <Typography variant="body2">{associationResponse?.message}</Typography>
            {associationResponse?.associations?.map((item) => (
              <div key={item.id}>
                {item.namePartsList.map((name) => {
                  const value = renderValue(name);
                  return <Typography key={value} variant="body2">{value}</Typography>;
                })}
              </div>
            ))}
          </div>
        </DialogRoot.Content>
        <DialogRoot.Footer borderTop>
          <Button
            label={associationResponse.status !== DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_CANNOT_BE_DELETED
              ? 'Cancel' : 'Close'}
            onClick={close}
            variant="tertiary"
          />
          {
            associationResponse.status !== DeleteItemCommandResponse.DeleteStatus.DELETE_STATUS_ITEM_CANNOT_BE_DELETED
            && (
            <Button
              danger
              isLoading={isDeleting}
              label="Delete"
              onClick={() => handleRemoveItem(StatusTypeRelation[associationResponse.status || 0])}
            />
            )
          }
        </DialogRoot.Footer>
      </DialogRoot.Layout>
    </DialogRoot.Root>

  );
};
