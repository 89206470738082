import { useParams } from 'react-router';

import { useGetURLAEligibleLoans } from '@/API/Queries/mortgageDynamicData/useGetURLAEligibleLoans';
import { useSearchParams } from '@/Routes/NavigationContext';

export const useDynamicLoanInfo = () => {
  const { loanId: loanIdFromParams = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { scopeToken: scopeTokenFromParams = '' } = Object.fromEntries([...searchParams]);
  const { data: { loanId: loanIdFromService, scopeToken: scopeTokenFromService } = {} } = useGetURLAEligibleLoans(!loanIdFromParams);

  return {
    loanId: loanIdFromParams || loanIdFromService || '',
    scopeToken: scopeTokenFromParams || scopeTokenFromService || '',
  };
};
