import { ContextualNavTabEnum } from '@/Routes/Pages/admin/activityTemplates/configurator/content/content';

import { useAsset } from './useAsset';
import { useSingleDataFieldTemplate } from './useSingleDataFieldTemplate';

const useDataFieldTemplate = (type: ContextualNavTabEnum, id: string) => {
  const { data: single } = useSingleDataFieldTemplate(id, type === ContextualNavTabEnum.FIELDS);
  const { data: asset } = useAsset(id, type === ContextualNavTabEnum.ASSETS);

  return single || asset;
};

export { useDataFieldTemplate };
