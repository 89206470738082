import { useMemo } from 'react';

import { useDealSummaryConfigurations } from '@/API/Queries/brand/useDealSummaryConfigurations';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';

interface TableDataItem {
  id: string;
  title: string;
  version: string;
}

const LoanPageConfiguration = () => {
  const {
    data: dealSummaryConfiguration,
    isLoading,
  } = useDealSummaryConfigurations();

  const columns = useMemo((): Array<Column<TableDataItem>> => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'title',
        type: 'text',
      },
    },
    {
      header: 'Version',
      id: 'version',
      key: {
        text: 'version',
        type: 'text',
      },
    },
  ], []);

  const data = useMemo(() => dealSummaryConfiguration?.map((config) => ({
    id: config.id,
    title: config.businessProcessDomainId,
  })), [dealSummaryConfiguration]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'All Loan Page Configurations' } }}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
    />
  );
};

export { LoanPageConfiguration };
