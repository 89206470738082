import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpsertLoanComplianceTestSummaryCommandRequest, UpsertLoanComplianceTestSummaryCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { ComplianceTestSummary } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { getTimestampFromDate } from '@/Utils/Helpers/getDateFromTimestamp';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest';

interface UpsertLoanComplianceTestSummaryProps {
  dealId: string;
  loanEntityId: string;
  testSummary: {
    complianceReportFirstOrderedDate?: string;
    complianceReportLastOrderedDate?: string;
    complianceReportFirstReceivedDate?: string;
    complianceReportLastReceivedDate?: string;
    fraudReportFirstOrderedDate?: string;
    fraudReportLastOrderedDate?: string;
    fraudReportFirstReceivedDate?: string;
    fraudReportLastReceivedDate?: string;
  };
}

const useUpsertLoanComplianceTestSummary = () => {
  const { commandResponse } = useBackend();

  const UpdateLoanProgressStatusCommand = async (updateObject: UpsertLoanComplianceTestSummaryProps) => {
    const { dealId, loanEntityId, testSummary } = updateObject;
    const request = new UpsertLoanComplianceTestSummaryCommandRequest();
    request.setDealId(dealId);
    request.setLoanEntityId(loanEntityId);

    const complianceTestSummary = new ComplianceTestSummary();

    if (testSummary?.complianceReportFirstOrderedDate) {
      complianceTestSummary.setComplianceReportFirstOrderedDate(getTimestampFromDate(testSummary.complianceReportFirstOrderedDate));
    }

    if (testSummary?.complianceReportLastOrderedDate) {
      complianceTestSummary.setComplianceReportLastOrderedDate(getTimestampFromDate(testSummary.complianceReportLastOrderedDate));
    }

    if (testSummary?.complianceReportFirstReceivedDate) {
      complianceTestSummary.setComplianceReportFirstReceivedDate(getTimestampFromDate(testSummary.complianceReportFirstReceivedDate));
    }

    if (testSummary?.complianceReportLastReceivedDate) {
      complianceTestSummary.setComplianceReportLastReceivedDate(getTimestampFromDate(testSummary.complianceReportLastReceivedDate));
    }

    if (testSummary?.fraudReportFirstOrderedDate) {
      complianceTestSummary.setFraudReportFirstOrderedDate(getTimestampFromDate(testSummary.fraudReportFirstOrderedDate));
    }

    if (testSummary?.fraudReportLastOrderedDate) {
      complianceTestSummary.setFraudReportLastOrderedDate(getTimestampFromDate(testSummary.fraudReportLastOrderedDate));
    }

    if (testSummary?.fraudReportFirstReceivedDate) {
      complianceTestSummary.setFraudReportFirstReceivedDate(getTimestampFromDate(testSummary.fraudReportFirstReceivedDate));
    }

    if (testSummary?.fraudReportLastReceivedDate) {
      complianceTestSummary.setFraudReportLastReceivedDate(getTimestampFromDate(testSummary.fraudReportLastReceivedDate));
    }

    request.setTestSummary(complianceTestSummary);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertLoanComplianceTestSummaryCommandResponse();
    UpsertLoanComplianceTestSummaryCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpdateLoanProgressStatusCommand);
};

export { useUpsertLoanComplianceTestSummary };
