import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GenerateLoanOfficerQuestionnaireUrlCommandRequest, GenerateLoanOfficerQuestionnaireUrlCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const GENERATE_LOAN_OFFICER_QUESTIONAIRE_URL_MESSAGE = 'wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest';

export function useGenerateLoanOfficerQuestionnaireUrl() {
  const { commandResponse } = useBackend();
  const { secureAuthValues } = useAuth();
  const { institutionId } = secureAuthValues;

  const update = async (userId: string) => {
    const request = new GenerateLoanOfficerQuestionnaireUrlCommandRequest();
    request.setInstitutionId(institutionId);
    request.setUserId(userId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: GENERATE_LOAN_OFFICER_QUESTIONAIRE_URL_MESSAGE,
    });

    const response = new GenerateLoanOfficerQuestionnaireUrlCommandResponse();
    GenerateLoanOfficerQuestionnaireUrlCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(GENERATE_LOAN_OFFICER_QUESTIONAIRE_URL_MESSAGE, responseObj.error, responseObj);
  };
  return useMutation(update);
}
