import { useState } from 'react';
import { useParams } from 'react-router';

import type { Organization } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { useOrganization } from '@/API/Queries/user/useOrganization';
import { useUpdateOrganization } from '@/API/Queries/user/useUpdateOrganization';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { AddressCardListItem } from '@/Components/Features/AddressCardListItem/AddressCardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { IGeoCodeData } from '@/Utils/Helpers/geoCodeConversion';
import { getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const OrganizationOverview = () => {
  const { orgId = '' } = useParams();

  const [isPolling, setIsPolling] = useState(false);
  const { data: organization, isLoading } = useOrganization(orgId, isPolling);
  const { isLoading: isSaving, mutate } = useUpdateOrganization();
  const { showSnackBar } = useShared();

  const handleMutation = (updatedOrganization: Organization.AsObject, close: () => void) => {
    mutate(updatedOrganization, {
      onError: () => {
        showSnackBar({ message: 'Something wrong happened, try again later.', open: true });
      },
      onSuccess: () => {
        close();
        setIsPolling(true);
        showSnackBar({ message: 'Organization successfully updated', open: true });
      },
    });
  };

  const handleUpdateDetails = (formData: DynamicDataElementValues, close: () => void) => {
    if (organization) {
      const updatedOrganization: Organization.AsObject = {
        ...organization,
        displayName: formData.displayName.value,
        externalId: formData.externalId.value,
        fullName: formData?.fullName?.value,
      };
      handleMutation(updatedOrganization, close);
    }
  };

  const handleUpdateOrgAddress = (values: IGeoCodeData, close: () => void) => {
    if (organization) {
      const updatedOrganization: Organization.AsObject = {
        ...organization,
        addressAdditionalLineText: values.additionalLineText,
        addressLineText: values.street,
        cityName: values.city,
        plusFourZipCode: values.plusFourZip || '',
        postalCode: values.zip,
        stateCode: values.state_abbr,
        stateName: values.state,
      };
      handleMutation(updatedOrganization, close);
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <CardListItem
        headerProps={{ title: 'Organization Details' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: organization?.displayName || '',
            label: 'Organization name',
            panelElement: getPanelElement({ headerText: 'Organization Internal Name', id: 'displayName', requirement: getPanelElementRequiment('Required'), type: 'text' }),
            value: { value: organization?.displayName },
          },
          {
            caption: organization?.externalId || '',
            label: 'Organization Id',
            panelElement: getPanelElement({ headerText: 'Organization Id', id: 'externalId', requirement: getPanelElementRequiment('Required'), type: 'text' }),
            value: { value: organization?.externalId || '' },
          },
          {
            caption: organization?.fullName || '',
            label: 'Organization legal name',
            panelElement: getPanelElement({ headerText: 'Organizaton Legal Name', id: 'fullName', requirement: getPanelElementRequiment('Required'), type: 'text' }),
            value: { value: organization?.fullName || '' },
          },
        ]}
        onSubmit={handleUpdateDetails}
      />
      <AddressCardListItem
        isLoading={isLoading}
        onSubmit={handleUpdateOrgAddress}
        progress={{
          additionalLineText: organization?.addressAdditionalLineText || '',
          city: organization?.cityName || '',
          country: 'US',
          county: '',
          plusFourZip: organization?.plusFourZipCode,
          state: organization?.stateName || '',
          state_abbr: organization?.stateCode || '',
          street: organization?.addressLineText || '',
          zip: organization?.postalCode || '',
        }}
        title="Organization Address"
      />
    </div>

  );
};

export { OrganizationOverview };
