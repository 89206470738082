interface IconProps {
  className?: string;
}

const NoItems = ({ className }: IconProps) => (
  <svg className={className} fill="none" height="128" viewBox="0 0 144 128" width="144" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.8833 9.96083C64.8833 9.96083 59.473 37.8327 28.363 38.9339C-2.74698 40.0351 -11.2601 84.9079 20.3215 93.2426C51.9031 101.577 36.3448 123.148 57.1322 127.517C80.7585 132.483 81.0517 97.5291 97.4808 96.9672C133.741 95.7272 152.505 79.1209 139.391 65.6515C116.373 42.0077 104.514 45.819 105.319 26.9242C106.124 8.02941 72.0617 -12.8085 64.8833 9.96083Z" fill="#E0E0E0" />
    <path d="M83.5997 20.6433L29.5462 31.7989C27.8241 32.1543 26.9578 35.0096 27.6114 38.1764L40.4137 100.209C41.0672 103.376 42.9931 105.655 44.7153 105.299L98.7687 94.1439C100.491 93.7885 101.357 90.9332 100.704 87.7664L87.9013 25.7337C87.2477 22.567 85.3218 20.2879 83.5997 20.6433Z" fill="#1C4C6A" />
    <path d="M80.0579 28.1144L71.5525 29.8697C70.1408 30.1611 69.2325 31.5417 69.5239 32.9534L71.2792 41.4588C71.5706 42.8705 72.9512 43.7787 74.3629 43.4874L82.8683 41.732C84.2801 41.4407 85.1883 40.0601 84.8969 38.6484L83.1416 30.143C82.8502 28.7313 81.4696 27.823 80.0579 28.1144Z" fill="white" />
    <path d="M92.0715 74.4311L43.6782 84.4185C43.5195 84.4513 43.4066 84.5541 43.426 84.6482L44.2943 88.8552C44.3137 88.9493 44.4581 88.9991 44.6168 88.9663L93.0101 78.9789C93.1688 78.9462 93.2817 78.8433 93.2623 78.7492L92.3941 74.5422C92.3747 74.4481 92.2302 74.3983 92.0715 74.4311Z" fill="#E0F1EA" />
    <path d="M90.3919 65.1621L41.9985 75.1495C41.8398 75.1822 41.7269 75.2851 41.7464 75.3792L42.6146 79.5862C42.634 79.6803 42.7784 79.73 42.9371 79.6973L91.3304 69.7099C91.4891 69.6771 91.6021 69.5743 91.5826 69.4802L90.7144 65.2732C90.695 65.179 90.5506 65.1293 90.3919 65.1621Z" fill="#E0F1EA" />
    <path d="M94.5905 83.8163L46.0264 93.839C45.9149 93.862 45.8402 93.9569 45.8596 94.0511L46.7279 98.258C46.7473 98.3522 46.8535 98.4098 46.965 98.3868L95.5291 88.3642C95.6407 88.3411 95.7153 88.2462 95.6959 88.1521L94.8277 83.9451C94.8082 83.851 94.7021 83.7933 94.5905 83.8163Z" fill="#E0F1EA" />
    <path d="M88.612 55.8232L40.0479 65.8458C39.9364 65.8688 39.8617 65.9638 39.8811 66.0579L40.7494 70.2649C40.7688 70.359 40.875 70.4166 40.9865 70.3936L89.5506 60.371C89.6621 60.348 89.7368 60.253 89.7174 60.1589L88.8492 55.9519C88.8297 55.8578 88.7236 55.8002 88.612 55.8232Z" fill="#E0F1EA" />
    <path d="M86.7487 46.5014L38.1846 56.524C38.0731 56.547 37.9984 56.642 38.0178 56.7361L38.8861 60.9431C38.9055 61.0372 39.0117 61.0949 39.1232 61.0718L87.6873 51.0492C87.7989 51.0262 87.8735 50.9312 87.8541 50.8371L86.9859 46.6301C86.9664 46.536 86.8603 46.4784 86.7487 46.5014Z" fill="#E0F1EA" />
  </svg>

);

export default NoItems;
