// source: Wilqo_API_Users_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_API_Users_Models_pb = require('./Wilqo_API_Users_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Users_Models_pb);
var Wilqo_API_Users_InstitutionModels_pb = require('./Wilqo_API_Users_InstitutionModels_pb.js');
goog.object.extend(proto, Wilqo_API_Users_InstitutionModels_pb);
goog.exportSymbol('proto.wilqo.api.users.ExportConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.ExportConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetInstitutionByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetInstitutionByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLeadSourceConfigQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLeadSourceConfigQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseCheckQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseCheckQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseInformationListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseInformationListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseInformationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLicenseInformationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLoanOfficerListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLoanOfficerListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLoanOfficerQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetLoanOfficerQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationListByUserQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationListByUserQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetOrganizationListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetByIdsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetByIdsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserByIdBackendQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserByIdBackendQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserLeadSourcesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserLeadSourcesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserListByInstitutionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserListByInstitutionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserLoanPermissionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserLoanPermissionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUsersDisplayDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUsersDisplayDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SearchUsersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SearchUsersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetUserByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetUserByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUserListByInstitutionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.displayName = 'proto.wilqo.api.users.GetUserListByInstitutionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUserListByInstitutionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.displayName = 'proto.wilqo.api.users.GetUserListByInstitutionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserListQueryRequest.displayName = 'proto.wilqo.api.users.GetUserListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUserListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUserListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserListQueryResponse.displayName = 'proto.wilqo.api.users.GetUserListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetListQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsetListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillsetListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetListQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsetListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.displayName = 'proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.displayName = 'proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.displayName = 'proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.displayName = 'proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsetGroupListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsetGroupListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillSetByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillSetByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetByIdsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.displayName = 'proto.wilqo.api.users.GetSkillSetByIdsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetSkillSetByIdsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.displayName = 'proto.wilqo.api.users.GetSkillSetByIdsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserByIdBackendQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserByIdBackendQueryRequest.displayName = 'proto.wilqo.api.users.GetUserByIdBackendQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserByIdBackendQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserByIdBackendQueryResponse.displayName = 'proto.wilqo.api.users.GetUserByIdBackendQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetInstitutionByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetInstitutionByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetInstitutionByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetInstitutionByIdQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetInstitutionByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetInstitutionByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetInstitutionByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetOrganizationByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetOrganizationByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationListQueryRequest.displayName = 'proto.wilqo.api.users.GetOrganizationListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetOrganizationListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationListQueryResponse.displayName = 'proto.wilqo.api.users.GetOrganizationListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationListByUserQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.displayName = 'proto.wilqo.api.users.GetOrganizationListByUserQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetOrganizationListByUserQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.displayName = 'proto.wilqo.api.users.GetOrganizationListByUserQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.displayName = 'proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.displayName = 'proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.displayName = 'proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.displayName = 'proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseInformationListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseInformationListQueryRequest.displayName = 'proto.wilqo.api.users.GetLicenseInformationListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetLicenseInformationListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseInformationListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseInformationListQueryResponse.displayName = 'proto.wilqo.api.users.GetLicenseInformationListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseInformationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseInformationQueryRequest.displayName = 'proto.wilqo.api.users.GetLicenseInformationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseInformationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseInformationQueryResponse.displayName = 'proto.wilqo.api.users.GetLicenseInformationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.displayName = 'proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.displayName = 'proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLoanOfficerListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLoanOfficerListQueryRequest.displayName = 'proto.wilqo.api.users.GetLoanOfficerListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetLoanOfficerListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetLoanOfficerListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLoanOfficerListQueryResponse.displayName = 'proto.wilqo.api.users.GetLoanOfficerListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLoanOfficerQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLoanOfficerQueryRequest.displayName = 'proto.wilqo.api.users.GetLoanOfficerQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLoanOfficerQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLoanOfficerQueryResponse.displayName = 'proto.wilqo.api.users.GetLoanOfficerQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseCheckQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseCheckQueryRequest.displayName = 'proto.wilqo.api.users.GetLicenseCheckQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLicenseCheckQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLicenseCheckQueryResponse.displayName = 'proto.wilqo.api.users.GetLicenseCheckQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.displayName = 'proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.displayName = 'proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLeadSourceConfigQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.displayName = 'proto.wilqo.api.users.GetLeadSourceConfigQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetLeadSourceConfigQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.displayName = 'proto.wilqo.api.users.GetLeadSourceConfigQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserLeadSourcesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.displayName = 'proto.wilqo.api.users.GetUserLeadSourcesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUserLeadSourcesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.displayName = 'proto.wilqo.api.users.GetUserLeadSourcesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.displayName = 'proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.displayName = 'proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ExportConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ExportConfigurationQueryRequest.displayName = 'proto.wilqo.api.users.ExportConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ExportConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ExportConfigurationQueryResponse.displayName = 'proto.wilqo.api.users.ExportConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserLoanPermissionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.displayName = 'proto.wilqo.api.users.GetUserLoanPermissionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserLoanPermissionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.displayName = 'proto.wilqo.api.users.GetUserLoanPermissionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SearchUsersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SearchUsersQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SearchUsersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SearchUsersQueryRequest.displayName = 'proto.wilqo.api.users.SearchUsersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SearchUsersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SearchUsersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SearchUsersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SearchUsersQueryResponse.displayName = 'proto.wilqo.api.users.SearchUsersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.displayName = 'proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.displayName = 'proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.displayName = 'proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.displayName = 'proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUsersDisplayDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.displayName = 'proto.wilqo.api.users.GetUsersDisplayDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.GetUsersDisplayDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.displayName = 'proto.wilqo.api.users.GetUsersDisplayDataQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserByIdQueryRequest;
  return proto.wilqo.api.users.GetUserByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.clearBusinessProcessDomainId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.hasBusinessProcessDomainId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserByIdQueryResponse;
  return proto.wilqo.api.users.GetUserByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserByIdQueryResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserListByInstitutionQueryRequest;
  return proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPartyRoleTypes(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyRoleTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.clearSearchTerm = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.hasSearchTerm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AdminLoanPartyRoleTypesEnum party_role_types = 2;
 * @return {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.getPartyRoleTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.setPartyRoleTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.addPartyRoleTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.clearPartyRoleTypesList = function() {
  return this.setPartyRoleTypesList([]);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserListByInstitutionQueryResponse;
  return proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUserListByInstitutionQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserListByInstitutionQueryResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserListQueryRequest}
 */
proto.wilqo.api.users.GetUserListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserListQueryRequest;
  return proto.wilqo.api.users.GetUserListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserListQueryRequest}
 */
proto.wilqo.api.users.GetUserListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserListQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserListQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUserListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse}
 */
proto.wilqo.api.users.GetUserListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserListQueryResponse;
  return proto.wilqo.api.users.GetUserListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse}
 */
proto.wilqo.api.users.GetUserListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserListQueryResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUserListQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserListQueryResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest;
  return proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    Wilqo_API_Users_Models_pb.Skill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse;
  return proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.Skill;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.Skill.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Skill skills = 3;
 * @return {!Array<!proto.wilqo.api.users.Skill>}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.getSkillsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.Skill, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.Skill>} value
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.setSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.Skill}
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.Skill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsForUserByIdQueryResponse.prototype.clearSkillsList = function() {
  return this.setSkillsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest;
  return proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillSetsIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse;
  return proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillSetsIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string skill_sets_id = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.getSkillSetsIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.setSkillSetsIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.addSkillSetsId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetsIdsForUserByIdQueryResponse.prototype.clearSkillSetsIdList = function() {
  return this.setSkillSetsIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillSetsIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    institutionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest;
  return proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetsId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillSetsIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string skill_sets_id = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.getSkillSetsIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.setSkillSetsIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.addSkillSetsId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.clearSkillSetsIdList = function() {
  return this.setSkillSetsIdList([]);
};


/**
 * optional string institution_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    hasSkillset: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    match: (f = msg.getMatch()) && Wilqo_API_Users_Models_pb.SkillSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse;
  return proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSkillset(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSet;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSet.deserializeBinaryFromReader);
      msg.setMatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getHasSkillset();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMatch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_skillset = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.getHasSkillset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.setHasSkillset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional SkillSet match = 4;
 * @return {?proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.getMatch = function() {
  return /** @type{?proto.wilqo.api.users.SkillSet} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillSet, 4));
};


/**
 * @param {?proto.wilqo.api.users.SkillSet|undefined} value
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.setMatch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.clearMatch = function() {
  return this.setMatch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetMatchingSkillsetForUserByIdQueryResponse.prototype.hasMatch = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetListQueryRequest;
  return proto.wilqo.api.users.GetSkillsetListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search_term = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetListQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsetsList: jspb.Message.toObjectList(msg.getSkillsetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetListQueryResponse;
  return proto.wilqo.api.users.GetSkillsetListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addSkillsets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SkillSetSummary skillsets = 3;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.getSkillsetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.setSkillsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.addSkillsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsetListQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetListQueryResponse.prototype.clearSkillsetsList = function() {
  return this.setSkillsetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest;
  return proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skill_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.getSkillId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedSkillsetsList: jspb.Message.toObjectList(msg.getAssignedSkillsetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillsetsList: jspb.Message.toObjectList(msg.getAvailableSkillsetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse;
  return proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillsets(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillsets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SkillSetSummary assigned_skillsets = 3;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.getAssignedSkillsetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.setAssignedSkillsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.addAssignedSkillsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.clearAssignedSkillsetsList = function() {
  return this.setAssignedSkillsetsList([]);
};


/**
 * repeated SkillSetSummary available_skillsets = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.getAvailableSkillsetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.setAvailableSkillsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.addAvailableSkillsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetsContainingSkillQueryResponse.prototype.clearAvailableSkillsetsList = function() {
  return this.setAvailableSkillsetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest;
  return proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skill_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.getSkillId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    userCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse;
  return proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUserCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 user_count = 2;
 * @return {number}
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.getUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserCountMatchingSkillQueryResponse.prototype.setUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    institutionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest;
  return proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPageCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skill_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.getSkillId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 offset = 3;
 * @return {number}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 page_count = 4;
 * @return {number}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.getPageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.setPageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string institution_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse;
  return proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} returns this
*/
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse} returns this
 */
proto.wilqo.api.users.GetUsersMatchingSkillQueryResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupListQueryRequest;
  return proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsetGroupsList: jspb.Message.toObjectList(msg.getSkillsetGroupsList(),
    Wilqo_API_Users_Models_pb.SkillsetGroupSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupListQueryResponse;
  return proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillsetGroupSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillsetGroupSummary.deserializeBinaryFromReader);
      msg.addSkillsetGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillsetGroupSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SkillsetGroupSummary skillset_groups = 3;
 * @return {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.getSkillsetGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillsetGroupSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.setSkillsetGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.addSkillsetGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.SkillsetGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupListQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupListQueryResponse.prototype.clearSkillsetGroupsList = function() {
  return this.setSkillsetGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest;
  return proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_group_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.getSkillsetGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.setSkillsetGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsetGroup: (f = msg.getSkillsetGroup()) && Wilqo_API_Users_Models_pb.SkillsetGroupSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse;
  return proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillsetGroupSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillsetGroupSummary.deserializeBinaryFromReader);
      msg.setSkillsetGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillsetGroupSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SkillsetGroupSummary skillset_group = 3;
 * @return {?proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.getSkillsetGroup = function() {
  return /** @type{?proto.wilqo.api.users.SkillsetGroupSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillsetGroupSummary, 3));
};


/**
 * @param {?proto.wilqo.api.users.SkillsetGroupSummary|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.setSkillsetGroup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.clearSkillsetGroup = function() {
  return this.setSkillsetGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetGroupByIdQueryResponse.prototype.hasSkillsetGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest;
  return proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search_term = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsetGroupsList: jspb.Message.toObjectList(msg.getSkillsetGroupsList(),
    Wilqo_API_Users_Models_pb.SkillsetGroupSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse;
  return proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.SkillsetGroupSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillsetGroupSummary.deserializeBinaryFromReader);
      msg.addSkillsetGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.SkillsetGroupSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SkillsetGroupSummary skillset_groups = 2;
 * @return {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.getSkillsetGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillsetGroupSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.setSkillsetGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.addSkillsetGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.SkillsetGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillsetGroupsBySearchQueryResponse.prototype.clearSkillsetGroupsList = function() {
  return this.setSkillsetGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillSetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetByIdQueryRequest;
  return proto.wilqo.api.users.GetSkillSetByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillSetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skill_set_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.getSkillSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.setSkillSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillSet: (f = msg.getSkillSet()) && Wilqo_API_Users_Models_pb.SkillSetSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetByIdQueryResponse;
  return proto.wilqo.api.users.GetSkillSetByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.setSkillSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillSet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SkillSetSummary skill_set = 3;
 * @return {?proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.getSkillSet = function() {
  return /** @type{?proto.wilqo.api.users.SkillSetSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 3));
};


/**
 * @param {?proto.wilqo.api.users.SkillSetSummary|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.setSkillSet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.clearSkillSet = function() {
  return this.setSkillSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillSetByIdQueryResponse.prototype.hasSkillSet = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skillSetIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetByIdsQueryRequest;
  return proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillSetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string skill_set_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.getSkillSetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.setSkillSetIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.addSkillSetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryRequest} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryRequest.prototype.clearSkillSetIdsList = function() {
  return this.setSkillSetIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillSetsList: jspb.Message.toObjectList(msg.getSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetSkillSetByIdsQueryResponse;
  return proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SkillSetSummary skill_sets = 2;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.getSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} returns this
*/
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.addSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetSkillSetByIdsQueryResponse} returns this
 */
proto.wilqo.api.users.GetSkillSetByIdsQueryResponse.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserByIdBackendQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserByIdBackendQueryRequest;
  return proto.wilqo.api.users.GetUserByIdBackendQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserByIdBackendQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserByIdBackendQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserByIdBackendQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserByIdBackendQueryResponse;
  return proto.wilqo.api.users.GetUserByIdBackendQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserByIdBackendQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserByIdBackendQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserByIdBackendQueryResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetInstitutionByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetInstitutionByIdQueryRequest;
  return proto.wilqo.api.users.GetInstitutionByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetInstitutionByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetInstitutionByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetInstitutionByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    institution: (f = msg.getInstitution()) && Wilqo_API_Users_InstitutionModels_pb.Institution.toObject(includeInstance, f),
    businessProcessDomainsList: jspb.Message.toObjectList(msg.getBusinessProcessDomainsList(),
    Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetInstitutionByIdQueryResponse;
  return proto.wilqo.api.users.GetInstitutionByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.Institution;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.Institution.deserializeBinaryFromReader);
      msg.setInstitution(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.deserializeBinaryFromReader);
      msg.addBusinessProcessDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetInstitutionByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getInstitution();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.Institution.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Institution institution = 3;
 * @return {?proto.wilqo.api.users.Institution}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.getInstitution = function() {
  return /** @type{?proto.wilqo.api.users.Institution} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.Institution, 3));
};


/**
 * @param {?proto.wilqo.api.users.Institution|undefined} value
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.setInstitution = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.clearInstitution = function() {
  return this.setInstitution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.hasInstitution = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated BusinessProcessDomain business_process_domains = 4;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomain>}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.getBusinessProcessDomainsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomain>} value
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.setBusinessProcessDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.addBusinessProcessDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.BusinessProcessDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetInstitutionByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetInstitutionByIdQueryResponse.prototype.clearBusinessProcessDomainsList = function() {
  return this.setBusinessProcessDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    showNationalLicense: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationByIdQueryRequest;
  return proto.wilqo.api.users.GetOrganizationByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowNationalLicense(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShowNationalLicense();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool show_national_license = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.getShowNationalLicense = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.setShowNationalLicense = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && Wilqo_API_Users_InstitutionModels_pb.Organization.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationByIdQueryResponse;
  return proto.wilqo.api.users.GetOrganizationByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.Organization;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.Organization.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Organization organization = 3;
 * @return {?proto.wilqo.api.users.Organization}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.getOrganization = function() {
  return /** @type{?proto.wilqo.api.users.Organization} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.Organization, 3));
};


/**
 * @param {?proto.wilqo.api.users.Organization|undefined} value
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetOrganizationByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetOrganizationByIdQueryResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationListQueryRequest;
  return proto.wilqo.api.users.GetOrganizationListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationListQueryResponse;
  return proto.wilqo.api.users.GetOrganizationListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OrganizationSummary organizations = 3;
 * @return {!Array<!proto.wilqo.api.users.OrganizationSummary>}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.OrganizationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.OrganizationSummary>} value
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.OrganizationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.OrganizationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetOrganizationListQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationListQueryResponse.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationListByUserQueryRequest;
  return proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryRequest} returns this
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetOrganizationListByUserQueryResponse;
  return proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OrganizationSummary organizations = 2;
 * @return {!Array<!proto.wilqo.api.users.OrganizationSummary>}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.OrganizationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.OrganizationSummary>} value
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} returns this
*/
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.OrganizationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.OrganizationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetOrganizationListByUserQueryResponse} returns this
 */
proto.wilqo.api.users.GetOrganizationListByUserQueryResponse.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest;
  return proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessDomain: (f = msg.getBusinessProcessDomain()) && Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse;
  return proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.deserializeBinaryFromReader);
      msg.setBusinessProcessDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomain();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessDomain business_process_domain = 3;
 * @return {?proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.getBusinessProcessDomain = function() {
  return /** @type{?proto.wilqo.api.users.BusinessProcessDomain} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomain, 3));
};


/**
 * @param {?proto.wilqo.api.users.BusinessProcessDomain|undefined} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} returns this
*/
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.setBusinessProcessDomain = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.clearBusinessProcessDomain = function() {
  return this.setBusinessProcessDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetBusinessProcessDomainByIdQueryResponse.prototype.hasBusinessProcessDomain = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest;
  return proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessDomainsList: jspb.Message.toObjectList(msg.getBusinessProcessDomainsList(),
    Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse;
  return proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.deserializeBinaryFromReader);
      msg.addBusinessProcessDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} returns this
*/
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessProcessDomainSummary business_process_domains = 3;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomainSummary>}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.getBusinessProcessDomainsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomainSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomainSummary>} value
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} returns this
*/
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.setBusinessProcessDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomainSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary}
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.addBusinessProcessDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.BusinessProcessDomainSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse} returns this
 */
proto.wilqo.api.users.GetBusinessProcessDomainListQueryResponse.prototype.clearBusinessProcessDomainsList = function() {
  return this.setBusinessProcessDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseInformationListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseInformationListQueryRequest;
  return proto.wilqo.api.users.GetLicenseInformationListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseInformationListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseInformationListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    licenseInformationListList: jspb.Message.toObjectList(msg.getLicenseInformationListList(),
    Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseInformationListQueryResponse;
  return proto.wilqo.api.users.GetLicenseInformationListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.LicenseInformation;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.deserializeBinaryFromReader);
      msg.addLicenseInformationList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseInformationListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInformationListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LicenseInformation license_information_list = 2;
 * @return {!Array<!proto.wilqo.api.users.LicenseInformation>}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.getLicenseInformationListList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LicenseInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.LicenseInformation, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LicenseInformation>} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.setLicenseInformationListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.LicenseInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.addLicenseInformationList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.LicenseInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetLicenseInformationListQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseInformationListQueryResponse.prototype.clearLicenseInformationListList = function() {
  return this.setLicenseInformationListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseInformationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseInformationQueryRequest;
  return proto.wilqo.api.users.GetLicenseInformationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseInformationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseInformationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    licenseInformation: (f = msg.getLicenseInformation()) && Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseInformationQueryResponse;
  return proto.wilqo.api.users.GetLicenseInformationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.LicenseInformation;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.deserializeBinaryFromReader);
      msg.setLicenseInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseInformationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInformation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicenseInformation license_information = 2;
 * @return {?proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.getLicenseInformation = function() {
  return /** @type{?proto.wilqo.api.users.LicenseInformation} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.LicenseInformation, 2));
};


/**
 * @param {?proto.wilqo.api.users.LicenseInformation|undefined} value
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.setLicenseInformation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLicenseInformationQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.clearLicenseInformation = function() {
  return this.setLicenseInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseInformationQueryResponse.prototype.hasLicenseInformation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest;
  return proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} returns this
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} returns this
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest} returns this
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    licensed: (f = msg.getLicensed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse;
  return proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setLicensed(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicensed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue licensed = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.getLicensed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} returns this
*/
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.setLicensed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} returns this
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.clearLicensed = function() {
  return this.setLicensed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.hasLicensed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} returns this
*/
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse} returns this
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.IsLoanOfficerLicensedInStatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLoanOfficerListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyRoleType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLoanOfficerListQueryRequest;
  return proto.wilqo.api.users.GetLoanOfficerListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} */ (reader.readEnum());
      msg.setPartyRoleType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLoanOfficerListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyRoleType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional AdminLoanPartyRoleTypesEnum party_role_type = 1;
 * @return {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.getPartyRoleType = function() {
  return /** @type {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.setPartyRoleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLoanOfficerListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanOfficersList: jspb.Message.toObjectList(msg.getLoanOfficersList(),
    Wilqo_API_Users_Models_pb.LoanOfficer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLoanOfficerListQueryResponse;
  return proto.wilqo.api.users.GetLoanOfficerListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.LoanOfficer;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LoanOfficer.deserializeBinaryFromReader);
      msg.addLoanOfficers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLoanOfficerListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanOfficersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.LoanOfficer.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} returns this
*/
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} returns this
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LoanOfficer loan_officers = 3;
 * @return {!Array<!proto.wilqo.api.users.LoanOfficer>}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.getLoanOfficersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LoanOfficer>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.LoanOfficer, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LoanOfficer>} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} returns this
*/
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.setLoanOfficersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.LoanOfficer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LoanOfficer}
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.addLoanOfficers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.LoanOfficer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetLoanOfficerListQueryResponse} returns this
 */
proto.wilqo.api.users.GetLoanOfficerListQueryResponse.prototype.clearLoanOfficersList = function() {
  return this.setLoanOfficersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLoanOfficerQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanOfficerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryRequest}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLoanOfficerQueryRequest;
  return proto.wilqo.api.users.GetLoanOfficerQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryRequest}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLoanOfficerQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanOfficerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loan_officer_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.getLoanOfficerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryRequest} returns this
 */
proto.wilqo.api.users.GetLoanOfficerQueryRequest.prototype.setLoanOfficerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLoanOfficerQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanOfficer: (f = msg.getLoanOfficer()) && Wilqo_API_Users_Models_pb.LoanOfficer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLoanOfficerQueryResponse;
  return proto.wilqo.api.users.GetLoanOfficerQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LoanOfficer;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LoanOfficer.deserializeBinaryFromReader);
      msg.setLoanOfficer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLoanOfficerQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanOfficer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LoanOfficer.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} returns this
*/
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} returns this
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LoanOfficer loan_officer = 2;
 * @return {?proto.wilqo.api.users.LoanOfficer}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.getLoanOfficer = function() {
  return /** @type{?proto.wilqo.api.users.LoanOfficer} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LoanOfficer, 2));
};


/**
 * @param {?proto.wilqo.api.users.LoanOfficer|undefined} value
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} returns this
*/
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.setLoanOfficer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLoanOfficerQueryResponse} returns this
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.clearLoanOfficer = function() {
  return this.setLoanOfficer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLoanOfficerQueryResponse.prototype.hasLoanOfficer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseCheckQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stateCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanOriginatorUserId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseCheckQueryRequest;
  return proto.wilqo.api.users.GetLicenseCheckQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOriginatorUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseCheckQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanOriginatorUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string state_code = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loan_originator_user_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.getLoanOriginatorUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.setLoanOriginatorUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string institution_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryRequest} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLicenseCheckQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    orgSuccessLicenseCheck: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    validationMessage: (f = msg.getValidationMessage()) && Wilqo_API_Users_Models_pb.LicenseValidationMessages.toObject(includeInstance, f),
    userSuccessLicenseCheck: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLicenseCheckQueryResponse;
  return proto.wilqo.api.users.GetLicenseCheckQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrgSuccessLicenseCheck(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.LicenseValidationMessages;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LicenseValidationMessages.deserializeBinaryFromReader);
      msg.setValidationMessage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserSuccessLicenseCheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLicenseCheckQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOrgSuccessLicenseCheck();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getValidationMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.LicenseValidationMessages.serializeBinaryToWriter
    );
  }
  f = message.getUserSuccessLicenseCheck();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool org_success_license_check = 2;
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.getOrgSuccessLicenseCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.setOrgSuccessLicenseCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional LicenseValidationMessages validation_message = 3;
 * @return {?proto.wilqo.api.users.LicenseValidationMessages}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.getValidationMessage = function() {
  return /** @type{?proto.wilqo.api.users.LicenseValidationMessages} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LicenseValidationMessages, 3));
};


/**
 * @param {?proto.wilqo.api.users.LicenseValidationMessages|undefined} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
*/
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.setValidationMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.clearValidationMessage = function() {
  return this.setValidationMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.hasValidationMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool user_success_license_check = 4;
 * @return {boolean}
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.getUserSuccessLicenseCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetLicenseCheckQueryResponse} returns this
 */
proto.wilqo.api.users.GetLicenseCheckQueryResponse.prototype.setUserSuccessLicenseCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest;
  return proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest} returns this
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    leadSourcesList: jspb.Message.toObjectList(msg.getLeadSourcesList(),
    Wilqo_API_Users_Models_pb.LeadSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse;
  return proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LeadSource;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LeadSource.deserializeBinaryFromReader);
      msg.addLeadSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLeadSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LeadSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} returns this
*/
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} returns this
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LeadSource lead_sources = 2;
 * @return {!Array<!proto.wilqo.api.users.LeadSource>}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.getLeadSourcesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LeadSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.LeadSource, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LeadSource>} value
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} returns this
*/
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.setLeadSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.LeadSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.addLeadSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.LeadSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse} returns this
 */
proto.wilqo.api.users.GetLeadSourcesConfigQueryResponse.prototype.clearLeadSourcesList = function() {
  return this.setLeadSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLeadSourceConfigQueryRequest;
  return proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest} returns this
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryRequest} returns this
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    leadSource: (f = msg.getLeadSource()) && Wilqo_API_Users_Models_pb.LeadSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetLeadSourceConfigQueryResponse;
  return proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LeadSource;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LeadSource.deserializeBinaryFromReader);
      msg.setLeadSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLeadSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LeadSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} returns this
*/
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} returns this
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LeadSource lead_source = 2;
 * @return {?proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.getLeadSource = function() {
  return /** @type{?proto.wilqo.api.users.LeadSource} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LeadSource, 2));
};


/**
 * @param {?proto.wilqo.api.users.LeadSource|undefined} value
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} returns this
*/
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.setLeadSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetLeadSourceConfigQueryResponse} returns this
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.clearLeadSource = function() {
  return this.setLeadSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetLeadSourceConfigQueryResponse.prototype.hasLeadSource = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserLeadSourcesQueryRequest;
  return proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    leadSourcesList: jspb.Message.toObjectList(msg.getLeadSourcesList(),
    Wilqo_API_Users_Models_pb.LeadSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserLeadSourcesQueryResponse;
  return proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LeadSource;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LeadSource.deserializeBinaryFromReader);
      msg.addLeadSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLeadSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LeadSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LeadSource lead_sources = 2;
 * @return {!Array<!proto.wilqo.api.users.LeadSource>}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.getLeadSourcesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LeadSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.LeadSource, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LeadSource>} value
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.setLeadSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.LeadSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.addLeadSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.LeadSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUserLeadSourcesQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserLeadSourcesQueryResponse.prototype.clearLeadSourcesList = function() {
  return this.setLeadSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inviteId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest;
  return proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInviteId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string invite_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.getInviteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.setInviteId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isValid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    validationMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse;
  return proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationMessage(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValidationMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool is_valid = 1;
 * @return {boolean}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.getIsValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.setIsValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string validation_message = 2;
 * @return {string}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.getValidationMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.setValidationMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 3;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 3));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} returns this
*/
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse} returns this
 */
proto.wilqo.api.users.VerifyBorrowerInviteUrlQueryResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ExportConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ExportConfigurationQueryRequest;
  return proto.wilqo.api.users.ExportConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ExportConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryRequest} returns this
 */
proto.wilqo.api.users.ExportConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ExportConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    zipFile: msg.getZipFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ExportConfigurationQueryResponse;
  return proto.wilqo.api.users.ExportConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZipFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ExportConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ExportConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getZipFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryResponse} returns this
*/
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes zip_file = 2;
 * @return {string}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.getZipFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes zip_file = 2;
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {string}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.getZipFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZipFile()));
};


/**
 * optional bytes zip_file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.getZipFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZipFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.users.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.users.ExportConfigurationQueryResponse.prototype.setZipFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserLoanPermissionQueryRequest;
  return proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanPermission: (f = msg.getLoanPermission()) && Wilqo_API_Users_Models_pb.LoanPermission.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserLoanPermissionQueryResponse;
  return proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LoanPermission;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LoanPermission.deserializeBinaryFromReader);
      msg.setLoanPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanPermission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LoanPermission.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LoanPermission loan_permission = 2;
 * @return {?proto.wilqo.api.users.LoanPermission}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.getLoanPermission = function() {
  return /** @type{?proto.wilqo.api.users.LoanPermission} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LoanPermission, 2));
};


/**
 * @param {?proto.wilqo.api.users.LoanPermission|undefined} value
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.setLoanPermission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserLoanPermissionQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.clearLoanPermission = function() {
  return this.setLoanPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserLoanPermissionQueryResponse.prototype.hasLoanPermission = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SearchUsersQueryRequest.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SearchUsersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SearchUsersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SearchUsersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchTerms: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    skillsetGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    partyRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    loadAssignedToParentUserIndicator: (f = msg.getLoadAssignedToParentUserIndicator()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    parentUserId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    page: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 9, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SearchUsersQueryRequest;
  return proto.wilqo.api.users.SearchUsersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SearchUsersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerms(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetGroupIds(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPartyRoleTypes(values[i]);
      }
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setLoadAssignedToParentUserIndicator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentUserId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SearchUsersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SearchUsersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SearchUsersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchTerms();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSkillsetGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPartyRoleTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getLoadAssignedToParentUserIndicator();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getParentUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search_terms = 2;
 * @return {string}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getSearchTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setSearchTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string organization_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated string skillset_group_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getSkillsetGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setSkillsetGroupIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.addSkillsetGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.clearSkillsetGroupIdsList = function() {
  return this.setSkillsetGroupIdsList([]);
};


/**
 * repeated AdminLoanPartyRoleTypesEnum party_role_types = 5;
 * @return {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getPartyRoleTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setPartyRoleTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.addPartyRoleTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.clearPartyRoleTypesList = function() {
  return this.setPartyRoleTypesList([]);
};


/**
 * optional google.protobuf.BoolValue load_assigned_to_parent_user_indicator = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getLoadAssignedToParentUserIndicator = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
*/
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setLoadAssignedToParentUserIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.clearLoadAssignedToParentUserIndicator = function() {
  return this.setLoadAssignedToParentUserIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.hasLoadAssignedToParentUserIndicator = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string parent_user_id = 7;
 * @return {string}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getParentUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setParentUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 page = 8;
 * @return {number}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 page_size = 9;
 * @return {number}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string business_process_domain_id = 10;
 * @return {string}
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryRequest} returns this
 */
proto.wilqo.api.users.SearchUsersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SearchUsersQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SearchUsersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SearchUsersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SearchUsersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    Wilqo_API_Users_Models_pb.UserSummary.toObject, includeInstance),
    currentPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentPageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalUsersCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SearchUsersQueryResponse;
  return proto.wilqo.api.users.SearchUsersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SearchUsersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.UserSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.UserSummary.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPageSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalUsersCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SearchUsersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SearchUsersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SearchUsersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.UserSummary.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCurrentPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalUsersCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
*/
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserSummary users = 2;
 * @return {!Array<!proto.wilqo.api.users.UserSummary>}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.UserSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.UserSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserSummary>} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
*/
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.UserSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserSummary}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.UserSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional int32 current_page = 3;
 * @return {number}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 current_page_size = 4;
 * @return {number}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.getCurrentPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.setCurrentPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total_users_count = 5;
 * @return {number}
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.getTotalUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SearchUsersQueryResponse} returns this
 */
proto.wilqo.api.users.SearchUsersQueryResponse.prototype.setTotalUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest;
  return proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    userNotificationPreferences: (f = msg.getUserNotificationPreferences()) && Wilqo_API_Users_Models_pb.UserNotificationPreference.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse;
  return proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.UserNotificationPreference;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.UserNotificationPreference.deserializeBinaryFromReader);
      msg.setUserNotificationPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUserNotificationPreferences();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.UserNotificationPreference.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserNotificationPreference user_notification_preferences = 2;
 * @return {?proto.wilqo.api.users.UserNotificationPreference}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.getUserNotificationPreferences = function() {
  return /** @type{?proto.wilqo.api.users.UserNotificationPreference} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.UserNotificationPreference, 2));
};


/**
 * @param {?proto.wilqo.api.users.UserNotificationPreference|undefined} value
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.setUserNotificationPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.clearUserNotificationPreferences = function() {
  return this.setUserNotificationPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserNotificationPreferencesQueryResponse.prototype.hasUserNotificationPreferences = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notificationType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notificationMethod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest;
  return proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.users.UserNotificationType} */ (reader.readEnum());
      msg.setNotificationType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.users.NotificationMethod} */ (reader.readEnum());
      msg.setNotificationMethod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNotificationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserNotificationType notification_type = 3;
 * @return {!proto.wilqo.api.users.UserNotificationType}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.getNotificationType = function() {
  return /** @type {!proto.wilqo.api.users.UserNotificationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.users.UserNotificationType} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.setNotificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional NotificationMethod notification_method = 4;
 * @return {!proto.wilqo.api.users.NotificationMethod}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.getNotificationMethod = function() {
  return /** @type {!proto.wilqo.api.users.NotificationMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.users.NotificationMethod} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.setNotificationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notificationTypeAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    notificationMethodDefined: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    userNotificationDestination: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse;
  return proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotificationTypeAllowed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotificationMethodDefined(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserNotificationDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationTypeAllowed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNotificationMethodDefined();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUserNotificationDestination();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
*/
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool notification_type_allowed = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.getNotificationTypeAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.setNotificationTypeAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool notification_method_defined = 4;
 * @return {boolean}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.getNotificationMethodDefined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.setNotificationMethodDefined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string user_notification_destination = 5;
 * @return {string}
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.getUserNotificationDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse} returns this
 */
proto.wilqo.api.users.GetUserNotificationDestinationQueryResponse.prototype.setUserNotificationDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUsersDisplayDataQueryRequest;
  return proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string user_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryRequest} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    Wilqo_API_Users_Models_pb.UserSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GetUsersDisplayDataQueryResponse;
  return proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.UserSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.UserSummary.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.UserSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} returns this
*/
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserSummary users = 2;
 * @return {!Array<!proto.wilqo.api.users.UserSummary>}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.UserSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.UserSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserSummary>} value
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} returns this
*/
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.UserSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserSummary}
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.UserSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.GetUsersDisplayDataQueryResponse} returns this
 */
proto.wilqo.api.users.GetUsersDisplayDataQueryResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


goog.object.extend(exports, proto.wilqo.api.users);
