// source: Wilqo_API_Mortgage_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('./Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('./Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_API_Mortgage_Models_pb = require('./Wilqo_API_Mortgage_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Models_pb);
var Wilqo_API_Mortgage_Integrations_Models_pb = require('./Wilqo_API_Mortgage_Integrations_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Integrations_Models_pb);
var Wilqo_API_Mortgage_DynamicData_Models_pb = require('./Wilqo_API_Mortgage_DynamicData_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Models_pb);
var Wilqo_API_Mortgage_DocGen_Models_pb = require('./Wilqo.API.Mortgage.DocGen/Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DocGen_Models_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('./Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddDealDocumentCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddDealDocumentCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddLoanConditionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddLoanConditionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddManualActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddManualActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealCommentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteFeeCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteFeeCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeletePartyCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeletePartyCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteThreadCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeDealCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeDealCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeSandboxCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InitializeSandboxCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NotificationSpecificCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PushDealJsonCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PushDealJsonCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PutSampleDealCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PutSampleDealCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDealCacheCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDealCacheCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetSandboxCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResetSandboxCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SaveThreadCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertFeeCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertFeeCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.displayName = 'proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PutSampleDealCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PutSampleDealCommandRequest.displayName = 'proto.wilqo.api.mortgage.PutSampleDealCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PutSampleDealCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PutSampleDealCommandResponse.displayName = 'proto.wilqo.api.mortgage.PutSampleDealCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDealCacheCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.displayName = 'proto.wilqo.api.mortgage.ResetDealCacheCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDealCacheCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.displayName = 'proto.wilqo.api.mortgage.ResetDealCacheCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.displayName = 'proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.displayName = 'proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.displayName = 'proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.displayName = 'proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeletePartyCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeletePartyCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeletePartyCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeletePartyCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeletePartyCommandResponse.displayName = 'proto.wilqo.api.mortgage.DeletePartyCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddDealDocumentCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddDealDocumentCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddDealDocumentCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddDealDocumentCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.displayName = 'proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.displayName = 'proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.displayName = 'proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.displayName = 'proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.displayName = 'proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.displayName = 'proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.displayName = 'proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.displayName = 'proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.displayName = 'proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.displayName = 'proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetSandboxCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetSandboxCommandRequest.displayName = 'proto.wilqo.api.mortgage.ResetSandboxCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResetSandboxCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResetSandboxCommandResponse.displayName = 'proto.wilqo.api.mortgage.ResetSandboxCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeSandboxCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.displayName = 'proto.wilqo.api.mortgage.InitializeSandboxCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeSandboxCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.displayName = 'proto.wilqo.api.mortgage.InitializeSandboxCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertFeeCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertFeeCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertFeeCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertFeeCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertFeeCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertFeeCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteFeeCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteFeeCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeleteFeeCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteFeeCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteFeeCommandResponse.displayName = 'proto.wilqo.api.mortgage.DeleteFeeCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.displayName = 'proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.displayName = 'proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.displayName = 'proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.displayName = 'proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddLoanConditionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddLoanConditionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddLoanConditionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddLoanConditionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddManualActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddManualActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddManualActivityCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddManualActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddManualActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddManualActivityCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddManualActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.displayName = 'proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.displayName = 'proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PushDealJsonCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PushDealJsonCommandRequest.displayName = 'proto.wilqo.api.mortgage.PushDealJsonCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PushDealJsonCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PushDealJsonCommandResponse.displayName = 'proto.wilqo.api.mortgage.PushDealJsonCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.displayName = 'proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.displayName = 'proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.displayName = 'proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.displayName = 'proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.displayName = 'proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.displayName = 'proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.displayName = 'proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.displayName = 'proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.displayName = 'proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.displayName = 'proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.InitializeDealCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeDealCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeDealCommandRequest.displayName = 'proto.wilqo.api.mortgage.InitializeDealCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeDealCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeDealCommandResponse.displayName = 'proto.wilqo.api.mortgage.InitializeDealCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SaveThreadCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SaveThreadCommandRequest.displayName = 'proto.wilqo.api.mortgage.SaveThreadCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteThreadCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteThreadCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeleteThreadCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.displayName = 'proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.displayName = 'proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealCommentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealCommentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.DealCommentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.displayName = 'proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.displayName = 'proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.displayName = 'proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.displayName = 'proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.displayName = 'proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.displayName = 'proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.displayName = 'proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.displayName = 'proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.displayName = 'proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeLoanSummaryCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest;
  return proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse;
  return proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DeleteLoanSummarySubscriptionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: (f = msg.getBusinessProcessDomainId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue business_process_domain_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.clearBusinessProcessDomainId = function() {
  return this.setBusinessProcessDomainId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandRequest.prototype.hasBusinessProcessDomainId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeProgressItemsCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeProgressItemsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanEntityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    testSuite: (f = msg.getTestSuite()) && Wilqo_API_Mortgage_Models_pb.ComplianceTestSuite.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest;
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanEntityId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.ComplianceTestSuite;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ComplianceTestSuite.deserializeBinaryFromReader);
      msg.setTestSuite(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTestSuite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.ComplianceTestSuite.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.getLoanEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.setLoanEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ComplianceTestSuite test_suite = 3;
 * @return {?proto.wilqo.api.mortgage.ComplianceTestSuite}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.getTestSuite = function() {
  return /** @type{?proto.wilqo.api.mortgage.ComplianceTestSuite} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.ComplianceTestSuite, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.ComplianceTestSuite|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.setTestSuite = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.clearTestSuite = function() {
  return this.setTestSuite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.hasTestSuite = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse;
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSuiteCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanEntityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    testSummary: (f = msg.getTestSummary()) && Wilqo_API_Mortgage_Models_pb.ComplianceTestSummary.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest;
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanEntityId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.ComplianceTestSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ComplianceTestSummary.deserializeBinaryFromReader);
      msg.setTestSummary(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTestSummary();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.ComplianceTestSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.getLoanEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.setLoanEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ComplianceTestSummary test_summary = 3;
 * @return {?proto.wilqo.api.mortgage.ComplianceTestSummary}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.getTestSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.ComplianceTestSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.ComplianceTestSummary, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.ComplianceTestSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.setTestSummary = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.clearTestSummary = function() {
  return this.setTestSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.hasTestSummary = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse;
  return proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertLoanComplianceTestSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PutSampleDealCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileIdentifier: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PutSampleDealCommandRequest;
  return proto.wilqo.api.mortgage.PutSampleDealCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum} */ (reader.readEnum());
      msg.setProfileIdentifier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PutSampleDealCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileIdentifier();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional SampleDealProfileIdentifierEnum profile_identifier = 2;
 * @return {!proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.getProfileIdentifier = function() {
  return /** @type {!proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum} value
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.setProfileIdentifier = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.PutSampleDealCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PutSampleDealCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PutSampleDealCommandResponse;
  return proto.wilqo.api.mortgage.PutSampleDealCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PutSampleDealCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse} returns this
*/
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PutSampleDealCommandResponse} returns this
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PutSampleDealCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    progressItemsList: jspb.Message.toObjectList(msg.getProgressItemsList(),
    Wilqo_API_Mortgage_Models_pb.ProgressItem.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest;
  return proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ProgressItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ProgressItem.deserializeBinaryFromReader);
      msg.addProgressItems(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgressItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ProgressItem.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ProgressItem progress_items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.ProgressItem>}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.getProgressItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ProgressItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ProgressItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ProgressItem>} value
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.setProgressItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ProgressItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ProgressItem}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.addProgressItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.ProgressItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.clearProgressItemsList = function() {
  return this.setProgressItemsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse;
  return proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertProgressItemsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusValue: (f = msg.getStatusValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest;
  return proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} */ (reader.readEnum());
      msg.setStatusType(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setStatusValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatusValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identifier = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProgressItemStatusEnum status_type = 3;
 * @return {!proto.wilqo.api.mortgage.ProgressItemStatusEnum}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.getStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.setStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement status_value = 5;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.getStatusValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.setStatusValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.clearStatusValue = function() {
  return this.setStatusValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.hasStatusValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse;
  return proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateProgressItemCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDealCacheCommandRequest;
  return proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDealCacheCommandResponse;
  return proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse} returns this
*/
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ResetDealCacheCommandResponse} returns this
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ResetDealCacheCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest;
  return proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse;
  return proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse} returns this
*/
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ResetDealXRefDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest;
  return proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse;
  return proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse} returns this
*/
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ResetDocumentCategoryDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeletePartyCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandRequest}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeletePartyCommandRequest;
  return proto.wilqo.api.mortgage.DeletePartyCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandRequest}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeletePartyCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeletePartyCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeletePartyCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandResponse}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeletePartyCommandResponse;
  return proto.wilqo.api.mortgage.DeletePartyCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandResponse}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeletePartyCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeletePartyCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DeletePartyCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DeletePartyCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeTridSummaryCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeTridSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    progressItemTemplateIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    documentStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentCategory: jspb.Message.getFieldWithDefault(msg, 9, 0),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 10, 0),
    documentCategoryId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    visibleToConsumerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    documentTypeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddDealDocumentCommandRequest;
  return proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemTemplateIdentifier(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setDocumentCategory(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentCategoryId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleToConsumerIndicator(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getProgressItemTemplateIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getDocumentCategoryId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getVisibleToConsumerIndicator();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDocumentTypeId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_url = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DocumentEnum document_type = 4;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string progress_item_template_identifier = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getProgressItemTemplateIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setProgressItemTemplateIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DocumentStatusEnum document_status = 6;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp expiration_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp effective_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DocumentCategoryEnum document_category = 9;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 10;
 * @return {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setValidPeriod = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.clearValidPeriod = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.hasValidPeriod = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string document_category_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool visible_to_consumer_indicator = 12;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getVisibleToConsumerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setVisibleToConsumerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string document_type_id = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getDocumentTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setDocumentTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string business_process_domain_id = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated string borrower_ids = 15;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandRequest.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddDealDocumentCommandResponse;
  return proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentCommandResponse.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest;
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document;
      reader.readMessage(value,proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    documentStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentCategory: jspb.Message.getFieldWithDefault(msg, 7, 0),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 8, 0),
    documentCategoryId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    visibleToConsumerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    documentTypeId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    progressItemTemplateIdentifier: jspb.Message.getFieldWithDefault(msg, 12, ""),
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document;
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setDocumentCategory(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentCategoryId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleToConsumerIndicator(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemTemplateIdentifier(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDocumentCategoryId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVisibleToConsumerIndicator();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDocumentTypeId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProgressItemTemplateIdentifier();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
};


/**
 * optional string document_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_url = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocumentEnum document_type = 3;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DocumentStatusEnum document_status = 4;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp expiration_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp effective_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DocumentCategoryEnum document_category = 7;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 8;
 * @return {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setValidPeriod = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.clearValidPeriod = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.hasValidPeriod = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string document_category_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool visible_to_consumer_indicator = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getVisibleToConsumerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setVisibleToConsumerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string document_type_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getDocumentTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setDocumentTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string progress_item_template_identifier = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getProgressItemTemplateIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setProgressItemTemplateIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string borrower_ids = 13;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Document documents = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document>}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document>} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandRequest.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse;
  return proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string document_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.getDocumentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.setDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.addDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddDealDocumentsCommandResponse.prototype.clearDocumentIdsList = function() {
  return this.setDocumentIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    progressItemTemplateIdentifier: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentCategory: jspb.Message.getFieldWithDefault(msg, 10, 0),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 11, 0),
    documentCategoryId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    visibleToConsumerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    documentTypeId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest;
  return proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemTemplateIdentifier(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setDocumentCategory(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentCategoryId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleToConsumerIndicator(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProgressItemTemplateIdentifier();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDocumentCategoryId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVisibleToConsumerIndicator();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDocumentTypeId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string document_url = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DocumentEnum document_type = 5;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string progress_item_template_identifier = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getProgressItemTemplateIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setProgressItemTemplateIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional DocumentStatusEnum document_status = 7;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp expiration_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp effective_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DocumentCategoryEnum document_category = 10;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 11;
 * @return {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setValidPeriod = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.clearValidPeriod = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.hasValidPeriod = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string document_category_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool visible_to_consumer_indicator = 13;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getVisibleToConsumerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setVisibleToConsumerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string document_type_id = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getDocumentTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setDocumentTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string business_process_domain_id = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated string borrower_ids = 16;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandRequest.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse;
  return proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    documentStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest;
  return proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentIds(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string document_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.getDocumentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.setDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.addDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.clearDocumentIdsList = function() {
  return this.setDocumentIdsList([]);
};


/**
 * optional DocumentStatusEnum document_status = 4;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.getDocumentStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandRequest.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse;
  return proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDealDocumentsStatusCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest;
  return proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string document_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.getDocumentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.setDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.addDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandRequest.prototype.clearDocumentIdsList = function() {
  return this.setDocumentIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse;
  return proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DeleteDealDocumentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partyRole: jspb.Message.getFieldWithDefault(msg, 4, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest;
  return proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (reader.readEnum());
      msg.setPartyRole(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartyRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PartyRoleEnum party_role = 4;
 * @return {!proto.wilqo.api.mortgage.PartyRoleEnum}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getPartyRole = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartyRoleEnum} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setPartyRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 8;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandRequest.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse;
  return proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddUserAsPartyCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.repeatedFields_ = [16,17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    partyRole: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sourceType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    partyAddress: (f = msg.getPartyAddress()) && proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.toObject(includeInstance, f),
    individual: (f = msg.getIndividual()) && proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.toObject(includeInstance, f),
    legalEntity: (f = msg.getLegalEntity()) && proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.toObject(includeInstance, f),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    agentLicense: jspb.Message.getFieldWithDefault(msg, 10, ""),
    companyLicense: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contactLicense: jspb.Message.getFieldWithDefault(msg, 12, ""),
    contactStateLicenseNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    companyStateLicenseNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    assetOwnersList: jspb.Message.toObjectList(msg.getAssetOwnersList(),
    Wilqo_API_Mortgage_Models_pb.AssetOwner.toObject, includeInstance),
    liabilityOwnersList: jspb.Message.toObjectList(msg.getLiabilityOwnersList(),
    Wilqo_API_Mortgage_Models_pb.LiabilityOwner.toObject, includeInstance),
    residencesList: jspb.Message.toObjectList(msg.getResidencesList(),
    Wilqo_API_Mortgage_Models_pb.ResidenceOwner.toObject, includeInstance),
    realEstateOwnedsList: jspb.Message.toObjectList(msg.getRealEstateOwnedsList(),
    Wilqo_API_Mortgage_Models_pb.RealEstateOwned.toObject, includeInstance),
    addressId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    residenceAddress: (f = msg.getResidenceAddress()) && proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.toObject(includeInstance, f),
    borrowerType: jspb.Message.getFieldWithDefault(msg, 24, ""),
    borrowerOnTitleType: jspb.Message.getFieldWithDefault(msg, 25, ""),
    dangerousOverwritePartyDataFlag: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    dealId: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest;
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (reader.readEnum());
      msg.setPartyRole(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.PartySourceTypeEnum} */ (reader.readEnum());
      msg.setSourceType(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress;
      reader.readMessage(value,proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.deserializeBinaryFromReader);
      msg.setPartyAddress(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.deserializeBinaryFromReader);
      msg.setIndividual(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.deserializeBinaryFromReader);
      msg.setLegalEntity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentLicense(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyLicense(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactLicense(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactStateLicenseNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyStateLicenseNumber(value);
      break;
    case 16:
      var value = new Wilqo_API_Mortgage_Models_pb.AssetOwner;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.AssetOwner.deserializeBinaryFromReader);
      msg.addAssetOwners(value);
      break;
    case 17:
      var value = new Wilqo_API_Mortgage_Models_pb.LiabilityOwner;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LiabilityOwner.deserializeBinaryFromReader);
      msg.addLiabilityOwners(value);
      break;
    case 18:
      var value = new Wilqo_API_Mortgage_Models_pb.ResidenceOwner;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ResidenceOwner.deserializeBinaryFromReader);
      msg.addResidences(value);
      break;
    case 19:
      var value = new Wilqo_API_Mortgage_Models_pb.RealEstateOwned;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.RealEstateOwned.deserializeBinaryFromReader);
      msg.addRealEstateOwneds(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressId(value);
      break;
    case 21:
      var value = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress;
      reader.readMessage(value,proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.deserializeBinaryFromReader);
      msg.setResidenceAddress(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerOnTitleType(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDangerousOverwritePartyDataFlag(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPartyRole();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPartyAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.serializeBinaryToWriter
    );
  }
  f = message.getIndividual();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.serializeBinaryToWriter
    );
  }
  f = message.getLegalEntity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.serializeBinaryToWriter
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAgentLicense();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompanyLicense();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContactLicense();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getContactStateLicenseNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCompanyStateLicenseNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAssetOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      Wilqo_API_Mortgage_Models_pb.AssetOwner.serializeBinaryToWriter
    );
  }
  f = message.getLiabilityOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      Wilqo_API_Mortgage_Models_pb.LiabilityOwner.serializeBinaryToWriter
    );
  }
  f = message.getResidencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      Wilqo_API_Mortgage_Models_pb.ResidenceOwner.serializeBinaryToWriter
    );
  }
  f = message.getRealEstateOwnedsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      Wilqo_API_Mortgage_Models_pb.RealEstateOwned.serializeBinaryToWriter
    );
  }
  f = message.getAddressId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getResidenceAddress();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.serializeBinaryToWriter
    );
  }
  f = message.getBorrowerType();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBorrowerOnTitleType();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDangerousOverwritePartyDataFlag();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    suffixName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    homePhone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    workPhone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mobilePhone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    personalEmail: jspb.Message.getFieldWithDefault(msg, 9, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userVersionNumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    useLatestUserData: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo;
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuffixName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomePhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkPhone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilePhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonalEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserVersionNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseLatestUserData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSuffixName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHomePhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWorkPhone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMobilePhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPersonalEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserVersionNumber();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUseLatestUserData();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middle_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string full_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string suffix_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getSuffixName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setSuffixName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string home_phone = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getHomePhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setHomePhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string work_phone = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getWorkPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setWorkPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mobile_phone = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getMobilePhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setMobilePhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string personal_email = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getPersonalEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setPersonalEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string user_id = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 user_version_number = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getUserVersionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setUserVersionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string profile_image_url = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool use_latest_user_data = 13;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.getUseLatestUserData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo.prototype.setUseLatestUserData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyLicense: jspb.Message.getFieldWithDefault(msg, 2, ""),
    primaryContactFullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    primaryContactPhone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    primaryContactEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    secondaryContactFullName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    secondaryContactEmail: jspb.Message.getFieldWithDefault(msg, 7, ""),
    secondaryContactPhone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    businessFaxNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    useLatestOrganizationData: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo;
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyLicense(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryContactFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryContactPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryContactEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryContactFullName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryContactEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryContactPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessFaxNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseLatestOrganizationData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyLicense();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrimaryContactFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrimaryContactPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrimaryContactEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSecondaryContactFullName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSecondaryContactEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSecondaryContactPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBusinessFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUseLatestOrganizationData();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string company_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string company_license = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getCompanyLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setCompanyLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string primary_contact_full_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getPrimaryContactFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setPrimaryContactFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string primary_contact_phone = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getPrimaryContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setPrimaryContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string primary_contact_email = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getPrimaryContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setPrimaryContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string secondary_contact_full_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getSecondaryContactFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setSecondaryContactFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string secondary_contact_email = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getSecondaryContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setSecondaryContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string secondary_contact_phone = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getSecondaryContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setSecondaryContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string business_fax_number = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getBusinessFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setBusinessFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string organization_id = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool use_latest_organization_data = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.getUseLatestOrganizationData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo.prototype.setUseLatestOrganizationData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressAdditionalLine: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressUnitIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress;
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLine(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressUnitIdentifier(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressAdditionalLine();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressUnitIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_additional_line = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getAddressAdditionalLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setAddressAdditionalLine = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_unit_identifier = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getAddressUnitIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setAddressUnitIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string zip = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state_code = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string party_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 3;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PartyRoleEnum party_role = 4;
 * @return {!proto.wilqo.api.mortgage.PartyRoleEnum}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getPartyRole = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartyRoleEnum} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setPartyRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PartySourceTypeEnum source_type = 5;
 * @return {!proto.wilqo.api.mortgage.PartySourceTypeEnum}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartySourceTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartySourceTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PartyAddress party_address = 6;
 * @return {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getPartyAddress = function() {
  return /** @type{?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setPartyAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearPartyAddress = function() {
  return this.setPartyAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.hasPartyAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IndividualPartyInfo individual = 7;
 * @return {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getIndividual = function() {
  return /** @type{?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo, 7));
};


/**
 * @param {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.IndividualPartyInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setIndividual = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearIndividual = function() {
  return this.setIndividual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.hasIndividual = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LegalEntityPartyInfo legal_entity = 8;
 * @return {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getLegalEntity = function() {
  return /** @type{?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.LegalEntityPartyInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setLegalEntity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearLegalEntity = function() {
  return this.setLegalEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.hasLegalEntity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string national_licensing_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string agent_license = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getAgentLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setAgentLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string company_license = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getCompanyLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setCompanyLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string contact_license = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getContactLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setContactLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string contact_state_license_number = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getContactStateLicenseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setContactStateLicenseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string company_state_license_number = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getCompanyStateLicenseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setCompanyStateLicenseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated AssetOwner asset_owners = 16;
 * @return {!Array<!proto.wilqo.api.mortgage.AssetOwner>}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getAssetOwnersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AssetOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.AssetOwner, 16));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AssetOwner>} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setAssetOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AssetOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AssetOwner}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.addAssetOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.wilqo.api.mortgage.AssetOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearAssetOwnersList = function() {
  return this.setAssetOwnersList([]);
};


/**
 * repeated LiabilityOwner liability_owners = 17;
 * @return {!Array<!proto.wilqo.api.mortgage.LiabilityOwner>}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getLiabilityOwnersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LiabilityOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LiabilityOwner, 17));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LiabilityOwner>} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setLiabilityOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LiabilityOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LiabilityOwner}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.addLiabilityOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.wilqo.api.mortgage.LiabilityOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearLiabilityOwnersList = function() {
  return this.setLiabilityOwnersList([]);
};


/**
 * repeated ResidenceOwner residences = 18;
 * @return {!Array<!proto.wilqo.api.mortgage.ResidenceOwner>}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getResidencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ResidenceOwner>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ResidenceOwner, 18));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ResidenceOwner>} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setResidencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ResidenceOwner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ResidenceOwner}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.addResidences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.wilqo.api.mortgage.ResidenceOwner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearResidencesList = function() {
  return this.setResidencesList([]);
};


/**
 * repeated RealEstateOwned real_estate_owneds = 19;
 * @return {!Array<!proto.wilqo.api.mortgage.RealEstateOwned>}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getRealEstateOwnedsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.RealEstateOwned>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.RealEstateOwned, 19));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.RealEstateOwned>} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setRealEstateOwnedsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.RealEstateOwned=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.RealEstateOwned}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.addRealEstateOwneds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.wilqo.api.mortgage.RealEstateOwned, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearRealEstateOwnedsList = function() {
  return this.setRealEstateOwnedsList([]);
};


/**
 * optional string address_id = 20;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional PartyAddress residence_address = 21;
 * @return {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getResidenceAddress = function() {
  return /** @type{?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress, 21));
};


/**
 * @param {?proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.PartyAddress|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setResidenceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.clearResidenceAddress = function() {
  return this.setResidenceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.hasResidenceAddress = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string borrower_type = 24;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getBorrowerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setBorrowerType = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string borrower_on_title_type = 25;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getBorrowerOnTitleType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setBorrowerOnTitleType = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool dangerous_overwrite_party_data_flag = 22;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getDangerousOverwritePartyDataFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setDangerousOverwritePartyDataFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string deal_id = 23;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse;
  return proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdatePartyCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    categoryType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    docTypesList: jspb.Message.toObjectList(msg.getDocTypesList(),
    Wilqo_API_Mortgage_Models_pb.DocType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest;
  return proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 5:
      var value = new Wilqo_API_Mortgage_Models_pb.DocType;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocType.deserializeBinaryFromReader);
      msg.addDocTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Mortgage_Models_pb.DocType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DocumentEnum types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentEnum>}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DocumentCategoryEnum category_type = 4;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated DocType doc_types = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.DocType>}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.getDocTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocType>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocType, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocType>} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
*/
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.setDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocType}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.addDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.DocType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandRequest.prototype.clearDocTypesList = function() {
  return this.setDocTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentCategory: (f = msg.getDocumentCategory()) && Wilqo_API_Mortgage_Models_pb.DocumentCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse;
  return proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentCategory.deserializeBinaryFromReader);
      msg.setDocumentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocumentCategory document_category = 2;
 * @return {?proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.getDocumentCategory = function() {
  return /** @type{?proto.wilqo.api.mortgage.DocumentCategory} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentCategory, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DocumentCategory|undefined} value
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.clearDocumentCategory = function() {
  return this.setDocumentCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreateDocumentCategoryCommandResponse.prototype.hasDocumentCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    typesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    docTypesList: jspb.Message.toObjectList(msg.getDocTypesList(),
    Wilqo_API_Mortgage_Models_pb.DocType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest;
  return proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_Models_pb.DocType;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocType.deserializeBinaryFromReader);
      msg.addDocTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Wilqo_API_Mortgage_Models_pb.DocType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocumentCategoryEnum category_type = 3;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated DocumentEnum types = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentEnum>}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated DocType doc_types = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.DocType>}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.getDocTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocType>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocType, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocType>} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.setDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocType}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.addDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.DocType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandRequest.prototype.clearDocTypesList = function() {
  return this.setDocTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentCategory: (f = msg.getDocumentCategory()) && Wilqo_API_Mortgage_Models_pb.DocumentCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse;
  return proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentCategory.deserializeBinaryFromReader);
      msg.setDocumentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocumentCategory document_category = 2;
 * @return {?proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.getDocumentCategory = function() {
  return /** @type{?proto.wilqo.api.mortgage.DocumentCategory} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentCategory, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DocumentCategory|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.clearDocumentCategory = function() {
  return this.setDocumentCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateDocumentCategoryCommandResponse.prototype.hasDocumentCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loanId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectedAutomatedUnderwritingId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest;
  return proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectedAutomatedUnderwritingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectedAutomatedUnderwritingId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string loan_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string selected_automated_underwriting_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.getSelectedAutomatedUnderwritingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.setSelectedAutomatedUnderwritingId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse;
  return proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddOrUpdateAutomatedUnderwritingSelectionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string process_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeToProcessUpdatesCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string process_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeFromProcessUpdatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeIntegrationCompletionCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeIntegrationCompletionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priority: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dueOrderDate: (f = msg.getDueOrderDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    processId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest;
  return proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum} */ (reader.readEnum());
      msg.setPriority(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueOrderDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDueOrderDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AppraisalManualTriggerPriorityEnum priority = 3;
 * @return {!proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.getPriority = function() {
  return /** @type {!proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.setPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp due_order_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.getDueOrderDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
*/
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.setDueOrderDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.clearDueOrderDate = function() {
  return this.setDueOrderDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.hasDueOrderDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string process_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.clearProcessId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandRequest.prototype.hasProcessId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse;
  return proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAppraisalBPCommandResponse.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    providerOptionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    loanProspectorTrackId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    desktopUnderwriterTrackId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    desktopOriginatorTrackId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    submissionType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lenderId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lenderName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    loanProcessingStage: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest;
  return proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegrationVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderOptions(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProspectorTrackId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopUnderwriterTrackId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopOriginatorTrackId(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} */ (reader.readEnum());
      msg.setSubmissionType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} */ (reader.readEnum());
      msg.setLoanProcessingStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProviderOptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getLoanProspectorTrackId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDesktopUnderwriterTrackId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDesktopOriginatorTrackId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubmissionType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getLenderId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLenderName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLoanProcessingStage();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string client_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional IntegrationVendor vendor = 5;
 * @return {!proto.wilqo.api.mortgage.IntegrationVendor}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegrationVendor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegrationVendor} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated AusProvider provider_options = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.AusProvider>}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getProviderOptionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusProvider>} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setProviderOptionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusProvider} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.addProviderOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.clearProviderOptionsList = function() {
  return this.setProviderOptionsList([]);
};


/**
 * optional string loan_prospector_track_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getLoanProspectorTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setLoanProspectorTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string desktop_underwriter_track_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getDesktopUnderwriterTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setDesktopUnderwriterTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string desktop_originator_track_id = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getDesktopOriginatorTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setDesktopOriginatorTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional DesktopOriginatorSubmissionType submission_type = 11;
 * @return {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getSubmissionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setSubmissionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string lender_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getLenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setLenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lender_name = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getLenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setLenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string process_id = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.clearProcessId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.hasProcessId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional LoanProductAdvisorLoanProcessingStageEnum loan_processing_stage = 15;
 * @return {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.getLoanProcessingStage = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandRequest.prototype.setLoanProcessingStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse;
  return proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualAutomatedUnderwritingBPCommandResponse.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    borrowerIntentToProceed: (f = msg.getBorrowerIntentToProceed()) && Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceed.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest;
  return proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceed;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceed.deserializeBinaryFromReader);
      msg.setBorrowerIntentToProceed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBorrowerIntentToProceed();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceed.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BorrowerIntentToProceed borrower_intent_to_proceed = 4;
 * @return {?proto.wilqo.api.mortgage.BorrowerIntentToProceed}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.getBorrowerIntentToProceed = function() {
  return /** @type{?proto.wilqo.api.mortgage.BorrowerIntentToProceed} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceed, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.BorrowerIntentToProceed|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.setBorrowerIntentToProceed = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.clearBorrowerIntentToProceed = function() {
  return this.setBorrowerIntentToProceed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest.prototype.hasBorrowerIntentToProceed = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse;
  return proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetSandboxCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetSandboxCommandRequest;
  return proto.wilqo.api.mortgage.ResetSandboxCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetSandboxCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.ResetSandboxCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResetSandboxCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResetSandboxCommandResponse;
  return proto.wilqo.api.mortgage.ResetSandboxCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResetSandboxCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse} returns this
*/
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ResetSandboxCommandResponse} returns this
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ResetSandboxCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeSandboxCommandRequest;
  return proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeSandboxCommandResponse;
  return proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse} returns this
*/
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.InitializeSandboxCommandResponse} returns this
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.InitializeSandboxCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertFeeCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    feeName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    feePaymentPaidByBorrowerTotalAmount: (f = msg.getFeePaymentPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaymentPaidBySellerTotalAmount: (f = msg.getFeePaymentPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaymentPaidByLenderTotalAmount: (f = msg.getFeePaymentPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaymentPaidByOtherTotalAmount: (f = msg.getFeePaymentPaidByOtherTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaymentTotal: (f = msg.getFeePaymentTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    categoryType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertFeeCommandRequest;
  return proto.wilqo.api.mortgage.UpsertFeeCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeName(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeePaymentPaidByBorrowerTotalAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeePaymentPaidBySellerTotalAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeePaymentPaidByLenderTotalAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeePaymentPaidByOtherTotalAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeePaymentTotal(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertFeeCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFeeName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFeePaymentPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaymentPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaymentPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaymentPaidByOtherTotalAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaymentTotal();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fee_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fee_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_payment_paid_by_borrower_total_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeePaymentPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeePaymentPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.clearFeePaymentPaidByBorrowerTotalAmount = function() {
  return this.setFeePaymentPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.hasFeePaymentPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_payment_paid_by_seller_total_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeePaymentPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeePaymentPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.clearFeePaymentPaidBySellerTotalAmount = function() {
  return this.setFeePaymentPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.hasFeePaymentPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_payment_paid_by_lender_total_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeePaymentPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeePaymentPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.clearFeePaymentPaidByLenderTotalAmount = function() {
  return this.setFeePaymentPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.hasFeePaymentPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_payment_paid_by_other_total_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeePaymentPaidByOtherTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeePaymentPaidByOtherTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.clearFeePaymentPaidByOtherTotalAmount = function() {
  return this.setFeePaymentPaidByOtherTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.hasFeePaymentPaidByOtherTotalAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_payment_total = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getFeePaymentTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setFeePaymentTotal = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.clearFeePaymentTotal = function() {
  return this.setFeePaymentTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.hasFeePaymentTotal = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LoanFeeCategoryTypeEnum category_type = 10;
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string business_process_domain_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertFeeCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanFee: (f = msg.getLoanFee()) && Wilqo_API_Mortgage_Models_pb.LoanFee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertFeeCommandResponse;
  return proto.wilqo.api.mortgage.UpsertFeeCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanFee;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanFee.deserializeBinaryFromReader);
      msg.setLoanFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertFeeCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanFee();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanFee.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LoanFee loan_fee = 2;
 * @return {?proto.wilqo.api.mortgage.LoanFee}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.getLoanFee = function() {
  return /** @type{?proto.wilqo.api.mortgage.LoanFee} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanFee, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.LoanFee|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.setLoanFee = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertFeeCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.clearLoanFee = function() {
  return this.setLoanFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertFeeCommandResponse.prototype.hasLoanFee = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteFeeCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteFeeCommandRequest;
  return proto.wilqo.api.mortgage.DeleteFeeCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteFeeCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fee_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteFeeCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteFeeCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteFeeCommandResponse;
  return proto.wilqo.api.mortgage.DeleteFeeCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteFeeCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DeleteFeeCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DeleteFeeCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subTextLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest;
  return proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubTextLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubTextLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identifier = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_text = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_text_label = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.getSubTextLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.setSubTextLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse;
  return proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateProgressItemSubTextCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    paidByBorrowerTotalFeeAmount: (f = msg.getPaidByBorrowerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalFeeAmount: (f = msg.getPaidBySellerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalFeeAmount: (f = msg.getPaidByLenderTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalFeeAmount: (f = msg.getPaidByOtherTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalFeeAmount: (f = msg.getTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalAmount: (f = msg.getPaidByOtherTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    aprTotalAmount: (f = msg.getAprTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeSectionTotalsList: jspb.Message.toObjectList(msg.getFeeSectionTotalsList(),
    Wilqo_API_Mortgage_Models_pb.FeeSectionTotal.toObject, includeInstance),
    feesTotalSummaryList: jspb.Message.toObjectList(msg.getFeesTotalSummaryList(),
    Wilqo_API_Mortgage_Models_pb.FeesTotalSummary.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest;
  return proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalFeeAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalFeeAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalFeeAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalFeeAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalFeeAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalAmount(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAprTotalAmount(value);
      break;
    case 14:
      var value = new Wilqo_API_Mortgage_Models_pb.FeeSectionTotal;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeeSectionTotal.deserializeBinaryFromReader);
      msg.addFeeSectionTotals(value);
      break;
    case 15:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesTotalSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesTotalSummary.deserializeBinaryFromReader);
      msg.addFeesTotalSummary(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPaidByBorrowerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalAmount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAprTotalAmount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeSectionTotalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      Wilqo_API_Mortgage_Models_pb.FeeSectionTotal.serializeBinaryToWriter
    );
  }
  f = message.getFeesTotalSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesTotalSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fee_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_fee_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByBorrowerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByBorrowerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByBorrowerTotalFeeAmount = function() {
  return this.setPaidByBorrowerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByBorrowerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_fee_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidBySellerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidBySellerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidBySellerTotalFeeAmount = function() {
  return this.setPaidBySellerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidBySellerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_fee_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByLenderTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByLenderTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByLenderTotalFeeAmount = function() {
  return this.setPaidByLenderTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByLenderTotalFeeAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_fee_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByOtherTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByOtherTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByOtherTotalFeeAmount = function() {
  return this.setPaidByOtherTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByOtherTotalFeeAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_fee_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearTotalFeeAmount = function() {
  return this.setTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasTotalFeeAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_amount = 12;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getPaidByOtherTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 12));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setPaidByOtherTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearPaidByOtherTotalAmount = function() {
  return this.setPaidByOtherTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasPaidByOtherTotalAmount = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr_total_amount = 13;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getAprTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 13));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setAprTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearAprTotalAmount = function() {
  return this.setAprTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.hasAprTotalAmount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated FeeSectionTotal fee_section_totals = 14;
 * @return {!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getFeeSectionTotalsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeeSectionTotal, 14));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setFeeSectionTotalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSectionTotal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.addFeeSectionTotals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.api.mortgage.FeeSectionTotal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearFeeSectionTotalsList = function() {
  return this.setFeeSectionTotalsList([]);
};


/**
 * repeated FeesTotalSummary fees_total_summary = 15;
 * @return {!Array<!proto.wilqo.api.mortgage.FeesTotalSummary>}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getFeesTotalSummaryList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeesTotalSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesTotalSummary, 15));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeesTotalSummary>} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setFeesTotalSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesTotalSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.addFeesTotalSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.api.mortgage.FeesTotalSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.clearFeesTotalSummaryList = function() {
  return this.setFeesTotalSummaryList([]);
};


/**
 * optional string business_process_domain_id = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse;
  return proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SendCalculatedFeeTotalsToSubscriptionsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: (f = msg.getBusinessProcessDomainId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    clientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest;
  return proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue business_process_domain_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
*/
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.clearBusinessProcessDomainId = function() {
  return this.setBusinessProcessDomainId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.hasBusinessProcessDomainId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string client_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string process_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.clearProcessId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandRequest.prototype.hasProcessId = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse;
  return proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualFraudBPCommandResponse.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    borrowerChosenProviderIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    paymentDate: (f = msg.getPaymentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    providerSelectedFromLenderListIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest;
  return proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerChosenProviderIndicator(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProviderSelectedFromLenderListIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fee_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.clearPartyId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.hasPartyId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool borrower_chosen_provider_indicator = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getBorrowerChosenProviderIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setBorrowerChosenProviderIndicator = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.clearBorrowerChosenProviderIndicator = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.hasBorrowerChosenProviderIndicator = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp payment_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool provider_selected_from_lender_list_indicator = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.getProviderSelectedFromLenderListIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.setProviderSelectedFromLenderListIndicator = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.clearProviderSelectedFromLenderListIndicator = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandRequest.prototype.hasProviderSelectedFromLenderListIndicator = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse;
  return proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateIndividualFeePayeeDetailsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeDOTSideSheetCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loan_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeDOTSideSheetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conditionIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddLoanConditionCommandRequest;
  return proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addConditionIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConditionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string condition_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.getConditionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.setConditionIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.addConditionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.clearConditionIdsList = function() {
  return this.setConditionIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddLoanConditionCommandResponse;
  return proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddLoanConditionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddLoanConditionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conditionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest;
  return proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string condition_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string business_process_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.getBusinessProcessIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.setBusinessProcessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.addBusinessProcessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.clearBusinessProcessIdsList = function() {
  return this.setBusinessProcessIdsList([]);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse;
  return proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddBusinessProcessToConditionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddManualActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conditionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    templateIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddManualActivityCommandRequest;
  return proto.wilqo.api.mortgage.AddManualActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTemplateIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddManualActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string condition_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string loan_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string template_ids = 6;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.getTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.setTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.addTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandRequest.prototype.clearTemplateIdsList = function() {
  return this.setTemplateIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddManualActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddManualActivityCommandResponse;
  return proto.wilqo.api.mortgage.AddManualActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddManualActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddManualActivityCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddManualActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authPullSoftCredit: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    consentPrivacyPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    consentReceiveDocumentsElectronically: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    consentReceiveEmailAndText: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    consentElectronicSignature: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    consentTermsAndConditions: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ignorePartyEmailValidation: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    partyId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest;
  return proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthPullSoftCredit(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsentPrivacyPolicy(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsentReceiveDocumentsElectronically(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsentReceiveEmailAndText(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsentElectronicSignature(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConsentTermsAndConditions(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnorePartyEmailValidation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthPullSoftCredit();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConsentPrivacyPolicy();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getConsentReceiveDocumentsElectronically();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getConsentReceiveEmailAndText();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getConsentElectronicSignature();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getConsentTermsAndConditions();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIgnorePartyEmailValidation();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional bool auth_pull_soft_credit = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getAuthPullSoftCredit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setAuthPullSoftCredit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool consent_privacy_policy = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getConsentPrivacyPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setConsentPrivacyPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool consent_receive_documents_electronically = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getConsentReceiveDocumentsElectronically = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setConsentReceiveDocumentsElectronically = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool consent_receive_email_and_text = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getConsentReceiveEmailAndText = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setConsentReceiveEmailAndText = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool consent_electronic_signature = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getConsentElectronicSignature = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setConsentElectronicSignature = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool consent_terms_and_conditions = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getConsentTermsAndConditions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setConsentTermsAndConditions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ignore_party_email_validation = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getIgnorePartyEmailValidation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setIgnorePartyEmailValidation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string party_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string business_process_domain_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse;
  return proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateConsumerConsentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    wilqoUserId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest;
  return proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWilqoUserId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWilqoUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string wilqo_user_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.getWilqoUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.setWilqoUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse;
  return proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AssignWilqoUserIdToPartyCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PushDealJsonCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealJson: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PushDealJsonCommandRequest;
  return proto.wilqo.api.mortgage.PushDealJsonCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealJson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PushDealJsonCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_json = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.getDealJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.setDealJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.PushDealJsonCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PushDealJsonCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PushDealJsonCommandResponse;
  return proto.wilqo.api.mortgage.PushDealJsonCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PushDealJsonCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse} returns this
*/
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PushDealJsonCommandResponse} returns this
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PushDealJsonCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest;
  return proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string process_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.clearProcessId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandRequest.prototype.hasProcessId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lendersList: jspb.Message.toObjectList(msg.getLendersList(),
    Wilqo_API_Mortgage_Models_pb.LenderOption.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse;
  return proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.LenderOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LenderOption.deserializeBinaryFromReader);
      msg.addLenders(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLendersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.LenderOption.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated LenderOption lenders = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.LenderOption>}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.getLendersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LenderOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LenderOption, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LenderOption>} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.setLendersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LenderOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LenderOption}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.addLenders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.LenderOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.clearLendersList = function() {
  return this.setLendersList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string template_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualGetLendersCommandResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    wilqoUserIdentifier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wilqoUserVersionNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest;
  return proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWilqoUserIdentifier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWilqoUserVersionNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWilqoUserIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWilqoUserVersionNumber();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string wilqo_user_identifier = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.getWilqoUserIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.setWilqoUserIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 wilqo_user_version_number = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.getWilqoUserVersionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.setWilqoUserVersionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse;
  return proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertUserInPartiesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NotificationSpecificCase = {
  NOTIFICATION_SPECIFIC_NOT_SET: 0,
  NEW_LOAN: 5,
  NEW_BORROWER: 6
};

/**
 * @return {proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NotificationSpecificCase}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getNotificationSpecificCase = function() {
  return /** @type {proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NotificationSpecificCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notificationMethod: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dealId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    newLoan: (f = msg.getNewLoan()) && proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.toObject(includeInstance, f),
    newBorrower: (f = msg.getNewBorrower()) && proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest;
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.MortgageNotificationMethod} */ (reader.readEnum());
      msg.setNotificationMethod(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs;
      reader.readMessage(value,proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.deserializeBinaryFromReader);
      msg.setNewLoan(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs;
      reader.readMessage(value,proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.deserializeBinaryFromReader);
      msg.setNewBorrower(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotificationMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNewLoan();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.serializeBinaryToWriter
    );
  }
  f = message.getNewBorrower();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    loanId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs;
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string loan_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerPartyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs;
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerPartyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string borrower_party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.prototype.getBorrowerPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs.prototype.setBorrowerPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MortgageNotificationMethod notification_method = 3;
 * @return {!proto.wilqo.api.mortgage.MortgageNotificationMethod}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getNotificationMethod = function() {
  return /** @type {!proto.wilqo.api.mortgage.MortgageNotificationMethod} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MortgageNotificationMethod} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setNotificationMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string deal_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NewLoanArgs new_loan = 5;
 * @return {?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getNewLoan = function() {
  return /** @type{?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewLoanArgs|undefined} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
*/
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setNewLoan = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.clearNewLoan = function() {
  return this.setNewLoan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.hasNewLoan = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NewBorrowerArgs new_borrower = 6;
 * @return {?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.getNewBorrower = function() {
  return /** @type{?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.NewBorrowerArgs|undefined} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
*/
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.setNewBorrower = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.clearNewBorrower = function() {
  return this.setNewBorrower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandRequest.prototype.hasNewBorrower = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse;
  return proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse} returns this
*/
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.NotifyLenderPartiesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.repeatedFields_ = [3,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feeCategoriesList: jspb.Message.toObjectList(msg.getFeeCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.toObject, includeInstance),
    escrowCategoriesList: jspb.Message.toObjectList(msg.getEscrowCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.toObject, includeInstance),
    prepaidCategoriesList: jspb.Message.toObjectList(msg.getPrepaidCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest;
  return proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.deserializeBinaryFromReader);
      msg.addFeeCategories(value);
      break;
    case 6:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.deserializeBinaryFromReader);
      msg.addEscrowCategories(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.deserializeBinaryFromReader);
      msg.addPrepaidCategories(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeeCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getEscrowCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LoanFeeCategory fee_categories = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getFeeCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanFeeCategory, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setFeeCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.addFeeCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.clearFeeCategoriesList = function() {
  return this.setFeeCategoriesList([]);
};


/**
 * repeated LoanEscrowFeeCategory escrow_categories = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getEscrowCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setEscrowCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.addEscrowCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.LoanEscrowFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.clearEscrowCategoriesList = function() {
  return this.setEscrowCategoriesList([]);
};


/**
 * repeated LoanPrepaidFeeCategory prepaid_categories = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getPrepaidCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setPrepaidCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.addPrepaidCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.LoanPrepaidFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.clearPrepaidCategoriesList = function() {
  return this.setPrepaidCategoriesList([]);
};


/**
 * optional string business_process_domain_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse;
  return proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateLoanFeesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest;
  return proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse;
  return proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribePreQualSummaryCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribePreQualSummaryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressInfo: (f = msg.getAddressInfo()) && Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest;
  return proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.deserializeBinaryFromReader);
      msg.setAddressInfo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.getAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CentralizedAddressLoanPageData address_info = 3;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.getAddressInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData|undefined} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} returns this
*/
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.setAddressInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.clearAddressInfo = function() {
  return this.setAddressInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.hasAddressInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse;
  return proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SaveCentralizedAddressLoanPageDataCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    trackId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    documentTypesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    processId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest;
  return proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_docgen.DocumentType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDocumentTypes(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDocumentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string document_type = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string track_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated wilqo.api.mortgage_docgen.DocumentType document_types = 8;
 * @return {!Array<!proto.wilqo.api.mortgage_docgen.DocumentType>}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getDocumentTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_docgen.DocumentType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_docgen.DocumentType>} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setDocumentTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.addDocumentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.clearDocumentTypesList = function() {
  return this.setDocumentTypesList([]);
};


/**
 * optional string process_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.clearProcessId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest.prototype.hasProcessId = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trackId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse;
  return proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
*/
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse} returns this
 */
proto.wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeDealCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loUserId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loOrgId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    propertyStateName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leadSourceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    referralSourceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Mortgage_DynamicData_Models_pb.DynamicDataItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeDealCommandRequest;
  return proto.wilqo.api.mortgage.InitializeDealCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoOrgId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyStateName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadSourceId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferralSourceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 9:
      var value = new Wilqo_API_Mortgage_DynamicData_Models_pb.DynamicDataItem;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Models_pb.DynamicDataItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeDealCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoOrgId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPropertyStateName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeadSourceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReferralSourceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_API_Mortgage_DynamicData_Models_pb.DynamicDataItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lo_user_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getLoUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setLoUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lo_org_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getLoOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setLoOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string property_state_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getPropertyStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setPropertyStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lead_source_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getLeadSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setLeadSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string referral_source_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getReferralSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setReferralSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string page_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated DynamicDataItem items = 9;
 * @return {!Array<!proto.wilqo.api.mortgage.DynamicDataItem>}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DynamicDataItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Models_pb.DynamicDataItem, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DynamicDataItem>} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
*/
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DynamicDataItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem}
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.api.mortgage.DynamicDataItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeDealCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeDealCommandResponse;
  return proto.wilqo.api.mortgage.InitializeDealCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeDealCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} returns this
*/
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 deal_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.InitializeDealCommandResponse} returns this
 */
proto.wilqo.api.mortgage.InitializeDealCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    feesComparisonSection: (f = msg.getFeesComparisonSection()) && Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.toObject(includeInstance, f),
    totalLenderCure: (f = msg.getTotalLenderCure()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest;
  return proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesComparisonSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.deserializeBinaryFromReader);
      msg.setFeesComparisonSection(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalLenderCure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFeesComparisonSection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.serializeBinaryToWriter
    );
  }
  f = message.getTotalLenderCure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeesComparisonSection fees_comparison_section = 3;
 * @return {?proto.wilqo.api.mortgage.FeesComparisonSection}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.getFeesComparisonSection = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeesComparisonSection} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesComparisonSection, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeesComparisonSection|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.setFeesComparisonSection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.clearFeesComparisonSection = function() {
  return this.setFeesComparisonSection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.hasFeesComparisonSection = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_lender_cure = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.getTotalLenderCure = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.setTotalLenderCure = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.clearTotalLenderCure = function() {
  return this.setTotalLenderCure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandRequest.prototype.hasTotalLenderCure = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse;
  return proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesComparisonCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    section: (f = msg.getSection()) && Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest;
  return proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.deserializeBinaryFromReader);
      msg.setSection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeesAdjustmentsCreditsSection section = 3;
 * @return {?proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.getSection = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.setSection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.clearSection = function() {
  return this.setSection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest.prototype.hasSection = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse;
  return proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    section: (f = msg.getSection()) && Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest;
  return proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.deserializeBinaryFromReader);
      msg.setSection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeesOriginationDiscountSection section = 3;
 * @return {?proto.wilqo.api.mortgage.FeesOriginationDiscountSection}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.getSection = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeesOriginationDiscountSection} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeesOriginationDiscountSection|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.setSection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.clearSection = function() {
  return this.setSection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest.prototype.hasSection = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse;
  return proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SaveThreadCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threadId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    richContent: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SaveThreadCommandRequest;
  return proto.wilqo.api.mortgage.SaveThreadCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SaveThreadCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRichContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thread_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string rich_content = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.getRichContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommandRequest.prototype.setRichContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteThreadCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threadId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteThreadCommandRequest;
  return proto.wilqo.api.mortgage.DeleteThreadCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteThreadCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thread_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommandRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threadId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    threadCommentId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    richContent: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest;
  return proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadCommentId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThreadCommentId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRichContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thread_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thread_comment_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getThreadCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setThreadCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string rich_content = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.getRichContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SaveThreadCommentCommandRequest.prototype.setRichContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    threadId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    threadCommentId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest;
  return proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadCommentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThreadCommentId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thread_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thread_comment_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.getThreadCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest} returns this
 */
proto.wilqo.api.mortgage.DeleteThreadCommentCommandRequest.prototype.setThreadCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealCommentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comments: (f = msg.getComments()) && Wilqo_API_Mortgage_Models_pb.DealComments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealCommentsCommandResponse;
  return proto.wilqo.api.mortgage.DealCommentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.DealComments;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DealComments.deserializeBinaryFromReader);
      msg.setComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealCommentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.DealComments.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DealComments comments = 3;
 * @return {?proto.wilqo.api.mortgage.DealComments}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.getComments = function() {
  return /** @type{?proto.wilqo.api.mortgage.DealComments} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DealComments, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.DealComments|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.setComments = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealCommentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.clearComments = function() {
  return this.setComments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealCommentsCommandResponse.prototype.hasComments = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest;
  return proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse;
  return proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.InitializeManagedSettingsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentCategoriesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest;
  return proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDocumentCategories(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DocumentCategoryEnum document_categories = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.getDocumentCategoriesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.setDocumentCategoriesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.addDocumentCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandRequest.prototype.clearDocumentCategoriesList = function() {
  return this.setDocumentCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentsSummary: (f = msg.getDocumentsSummary()) && Wilqo_API_Mortgage_Models_pb.DocumentSummarySubscriptionMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse;
  return proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentSummarySubscriptionMessage;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentSummarySubscriptionMessage.deserializeBinaryFromReader);
      msg.setDocumentsSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentsSummary();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentSummarySubscriptionMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocumentSummarySubscriptionMessage documents_summary = 3;
 * @return {?proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.getDocumentsSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentSummarySubscriptionMessage, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage|undefined} value
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.setDocumentsSummary = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.clearDocumentsSummary = function() {
  return this.setDocumentsSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.SubscribeDocumentsCommandResponse.prototype.hasDocumentsSummary = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest;
  return proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse;
  return proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UnsubscribeDocumentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    partyDataList: jspb.Message.toObjectList(msg.getPartyDataList(),
    Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest;
  return proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.deserializeBinaryFromReader);
      msg.addPartyData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPartyDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 4;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated wilqo.shared.models.DynamicDataWriteItem party_data = 5;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.getPartyDataList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
*/
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.setPartyDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.addPartyData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.DynamicDataWriteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandRequest.prototype.clearPartyDataList = function() {
  return this.setPartyDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse;
  return proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 deal_version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} returns this
*/
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AddWilqoPartyMemberCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: (f = msg.getCategory()) && Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest;
  return proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WilqoPartyCategory category = 2;
 * @return {?proto.wilqo.api.mortgage.WilqoPartyCategory}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.getCategory = function() {
  return /** @type{?proto.wilqo.api.mortgage.WilqoPartyCategory} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.WilqoPartyCategory|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} returns this
*/
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest} returns this
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse;
  return proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} returns this
*/
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse} returns this
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertWilqoPartyCategoryCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage);
