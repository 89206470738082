import type { FieldGroupingSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { fromString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

import { useDynamicForm } from './DynamicFormContext';

type FieldGroupingTypes = keyof FieldGroupingSettings.AsObject;

export const useFieldGrouping = () => {
  const { fieldGrouping, resetProgress, values } = useDynamicForm();

  const onValueChange = (value: any, type: FieldGroupingTypes) => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (type) {
      case 'googleAddress': {
        if (value) {
          const { geocode } = value;
          const getValue = (s: string, id: string) => ({
            dynamic: fromString(s),
            fieldConfig: values[id]?.fieldConfig,
            value: s,
          });
          const getPanelElement = (id: string, value: string, displayText: string) => ({
            fieldConfig: values[id]?.fieldConfig,
            value: getPanelElementOption({
              headerText: displayText,
              id: value,
              value: fromString(value).toObject(),
            }),
          });

          const progressValues = {
            [fieldGrouping?.googleAddress?.cityFieldId ?? '']: getValue(geocode?.city, fieldGrouping?.googleAddress?.cityFieldId ?? ''),
            [fieldGrouping?.googleAddress?.countryFieldId ?? '']: getValue(geocode?.country, fieldGrouping?.googleAddress?.countryFieldId ?? ''),
            [fieldGrouping?.googleAddress?.countyNameFieldId ?? '']: getValue(geocode?.county, fieldGrouping?.googleAddress?.countyNameFieldId ?? ''),
            [fieldGrouping?.googleAddress?.zipCodeFieldId ?? '']: getValue(geocode?.zip, fieldGrouping?.googleAddress?.zipCodeFieldId ?? ''),
            [fieldGrouping?.googleAddress?.stateCodeFieldId ?? '']: getPanelElement(fieldGrouping?.googleAddress?.stateCodeFieldId ?? '', geocode?.state_abbr, geocode?.state),
            [fieldGrouping?.googleAddress?.stateFieldId ?? '']: fieldGrouping?.googleAddress?.stateFieldId === 'internationalStateName'
              ? getValue(geocode?.state, fieldGrouping?.googleAddress?.stateFieldId ?? '')
              : {
                fieldConfig: values[fieldGrouping?.googleAddress?.stateFieldId ?? '']?.fieldConfig,
                value: getPanelElementOption({
                  headerText: geocode?.state,
                  id: geocode?.state_abbr,
                  value: fromString(geocode?.state).toObject(),
                }),
              },
          };

          resetProgress({
            values: Object.keys(progressValues).reduce((acc, key) => {
              if (!key) {
                return acc;
              }
              return { ...acc, [key]: progressValues[key] };
            }, {}),
          });
        }
        break;
      }
      default: break;
    }
  };

  return { onValueChange };
};
