import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import useEvent from 'react-use-event-hook';

import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { Checkbox } from '@/Components/Atoms/Checkbox';
import { Input } from '@/Components/Atoms/Input';
import { ListItem } from '@/Components/Atoms/ListItem';
import { RadioGroup } from '@/Components/Atoms/RadioGroup';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { ExtendedConverter, HandleChangeType } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { useBlocker } from '@/Routes/NavigationContext';
import { manageList } from '@/Utils/Helpers/arrayHelpers';
import { ddeAreEqual, DynamicDataElementMap, fromList, fromNumber, toList, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import type { SaveConfig } from '../useSaveWidget';
import { ExpandStatusTypeEnum, useWidgetContext } from '../WidgetContext';

const ethnicityId = 'PZAv699yFun-dv_UlVKUR';
const hispanicOrLatinoId = '3n4yIpPlFtEdknEtO9hK3';
const raceId = 'r9iaKAra_AowY7JHvURCf';
const designationTypeId = 'm6vpk-f8yxs7BGH8lyHNO';
const sexId = 'Y8EOQ2bjtzjGXQ8oRk4zC';
const asianHeaderText = ['Asian Indian', 'Chinese', 'Filipino', 'Japanese', 'Korean', 'Vietnamese', 'Other Asian'];
const pacificHeaderText = ['Native Hawaiian', 'Guamanian Or Chamorro', 'Samoan', 'Other Pacific Islander'];

interface HMDAOption extends PanelElementOption.AsObject {
  options?: Array<PanelElement.AsObject | PanelElementOption.AsObject>;
}

const consumerMessage = 'I do not wish to provide this information';
const lenderMessage = 'Applicant did not wish to provide information';

export const HMDA = (props: LoanPageWidget.AsObject & { allowPrompt?: boolean }) => {
  const {
    addEditingWidget,
    context,
    editingWidgetIds,
    expandStatus,
    pageId = '',
    removeEditingWidget,
    savingWidgetIds,
    scopeToken = '',
  } = useWidgetContext();
  const { generateDialogKeywordLinks } = useShared();
  const { isConsumer } = useAuth();

  const isEditing = useMemo(() => editingWidgetIds.some((w) => w === props.id), [editingWidgetIds, props.id]);
  const isSaving = useMemo(() => savingWidgetIds.some((w) => w === props.id), [savingWidgetIds, props.id]);
  const { loanId } = useDynamicLoanInfo();
  const { resetProgress, values } = useDynamicForm();

  useEffect(() => {
    if (context === 'dialog' && !isEditing) {
      addEditingWidget(props.id);
    }
  }, [context, isEditing, props.id, addEditingWidget]);

  const sexOptions = useMemo((): Array<PanelElementOption.AsObject> => {
    const sexField = props.form?.fieldsList.find((field) => field.id === sexId);
    const [notProvided, female, male] = sexField?.panelElement?.optionsList.filter((e) => e?.headerText !== '--') || [];
    if (isConsumer) notProvided.headerText = consumerMessage;
    else notProvided.headerText = lenderMessage;
    return [female, male, notProvided];
  }, [props, isConsumer]);

  const ethnicityOptions = useMemo((): PanelElementOption.AsObject[] => {
    const ethinicityField = props.form?.fieldsList.find((field) => field.id === ethnicityId);
    const [hl, notProvided, notH] = ethinicityField?.panelElement?.optionsList.filter((option) => option?.headerText !== '--' && option?.headerText !== 'Not Applicable' && option?.headerText !== 'Other') || [];
    if (isConsumer) notProvided.headerText = consumerMessage;
    else notProvided.headerText = lenderMessage;
    return [hl, notH, notProvided];
  },
  [props, isConsumer]);

  const hispanicOptions = useMemo((): Array<PanelElement.AsObject | PanelElementOption.AsObject> => {
    const hispanicOrLatinoField = props.form?.fieldsList.find((field) => field.id === hispanicOrLatinoId);
    const subOptions = hispanicOrLatinoField?.panelElement?.optionsList.filter((e) => e?.headerText !== '--');
    const otherPE = getPanelElement({ headerText: 'Other Hispanic or Latino', id: '8Vv5XR6jeGtqY_Z6Cc2kt', summaryText: 'For example: Argentinean, Colombian, and so on', type: 'text' });
    const [cuban, mexican, other, pr, otherText] = subOptions?.concat(otherPE) || [];
    return [mexican, pr, cuban, other, otherText];
  }, [props]);

  const raceOptions = useMemo((): Array<PanelElementOption.AsObject> => {
    const raceField = props.form?.fieldsList.find((field) => field.id === raceId);
    const [americanIndian, asian, black, notProvided, nativeHawaiian, white] = raceField?.panelElement?.optionsList.filter((option) => option?.headerText !== '--' && option?.headerText !== 'Not Applicable') || [];
    if (isConsumer) notProvided.headerText = consumerMessage;
    else notProvided.headerText = lenderMessage;
    return [americanIndian, asian, black, nativeHawaiian, white, notProvided];
  }, [props, isConsumer]);

  const { americanIndianOptions, asianOptions, pacificOptions } = useMemo(() => {
    const designationTypes = props.form?.fieldsList.find((field) => field.id === designationTypeId);
    const asianOptions = designationTypes?.panelElement?.optionsList.filter((a) => asianHeaderText.some((b) => a?.headerText === b)) || [];
    const asianOtherPE = getPanelElement({ headerText: 'Other Asian', id: 'OwdGRQmtbrODBWEPl9ALM', summaryText: 'For example: Hmong, Laotian, Thai, and so on', type: 'text' });
    const [indian, chinese, filipino, japanese, korean, other, viet, otherText] = asianOptions.concat(asianOtherPE);
    const pacificOptions = designationTypes?.panelElement?.optionsList.filter((a) => pacificHeaderText.some((b) => a?.headerText === b)) || [];
    const pacificOtherPE = getPanelElement({ headerText: 'Other Pacific Islander', id: '4JBvWroAPxeepEB6P-mek', summaryText: 'For example: Fijian, Tongan, and so on', type: 'text' });
    const [guan, hawaii, otherPacific, samoan, otherTextPacific] = pacificOptions.concat(pacificOtherPE);
    const americanIndianPE = getPanelElement({ headerText: 'Principal Tribe', id: 'GU_XYYUWkAaxpiz5ffQfh', summaryText: 'Type name of enrolled or principal tribe', type: 'text' });
    return {
      americanIndianOptions: [americanIndianPE],
      asianOptions: [indian, chinese, filipino, japanese, korean, viet, other, otherText],
      pacificOptions: [hawaii, guan, samoan, otherPacific, otherTextPacific],
    };
  }, [props]);

  const getConverter = useCallback((options: HMDAOption[]): ExtendedConverter<Array<PanelElementOption.AsObject>> => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fromDynamic: (dynamicList: DynamicDataElement) => {
      if (dynamicList.getDataType() === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) return [];
      return fromList(dynamicList).reduce((selectedOptions: Array<PanelElementOption.AsObject>, currentDDE) => {
        const selectedOptionFromDynamic = options.find((option) => (option.value ? ddeAreEqual(DynamicDataElementMap(option.value), currentDDE) : false));
        if (selectedOptionFromDynamic) {
          return selectedOptions.concat(selectedOptionFromDynamic);
        }
        return selectedOptions;
      }, []);
    },
    toDynamic: (v = []) => toList(v.map((e) => (e.value ? DynamicDataElementMap(e?.value) : fromNumber(0)))),
  }), []);

  const [sex = [], setSex] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(sexId, getConverter(sexOptions));
  const [ethnicity = [], setEthnicity] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(ethnicityId, getConverter(ethnicityOptions));
  const [hispanicOrLatino = [], setHispanicOrLatino] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(hispanicOrLatinoId, getConverter(hispanicOptions));
  const [races = [], setRaces] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(raceId, getConverter(raceOptions));
  const [designationTypes = [], setDesignationTypes] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(designationTypeId, getConverter(asianOptions.concat(pacificOptions)));

  useEffect(() => {
    const values = props.form?.fieldsList.reduce((progress: DynamicDataElementValues, field) => {
      let fieldDynamicValue: DynamicDataElement | undefined = field.value?.value ? DynamicDataElementMap(field.value?.value) : undefined;
      if (field.value?.value && field.panelElement?.type === 'select' && field.value.value.dataType === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
        fieldDynamicValue = toList([]);
      }
      const value = field.panelElement?.type === 'text' && fieldDynamicValue ? toString(fieldDynamicValue) : undefined;
      return {
        ...progress,
        [field.id]: { dynamic: fieldDynamicValue, value },
      };
    }, {});
    if (values) resetProgress({ values });
  }, [resetProgress, props.form, isEditing]);

  const disableActions = useMemo(() => context === 'page' && editingWidgetIds.length > 0 && !isEditing, [context, editingWidgetIds, isEditing]);

  const actions = useMemo((): CardButton[] => {
    if (context === 'dialog') return [];
    if (isEditing) {
      const additionalData: SaveConfig = {
        loanId,
        pageId,
        scopeToken,
        type: 'form',
        widgetId: props.id,
        widgets: [props],
      };
      return [
        { label: 'Cancel', onClick: () => removeEditingWidget(props.id), variant: 'tertiary' },
        { additionalData: JSON.stringify(additionalData), isLoading: isSaving, label: 'Save', type: 'submit' },
      ];
    }
    return [
      { disabled: disableActions, label: 'Edit', onClick: () => addEditingWidget(props.id), variant: 'tertiary' },
    ];
  }, [context, isEditing, disableActions, loanId, pageId, scopeToken, props, isSaving, removeEditingWidget, addEditingWidget]);

  const renderSubOptions = (options: PanelElementOption.AsObject[], selectedOptions: PanelElementOption.AsObject[], setOptions: HandleChangeType<PanelElementOption.AsObject[]>) => (
    <div className="pl-8 flex flex-col gap-4">
      {
        options.map((subOption) => {
          if (subOption.type === 'text') {
            return (
              <div className={clsx({ 'pl-8': subOption?.headerText !== 'Principal Tribe' })}>
                <Input displayOnly={false} panelElement={subOption as PanelElement.AsObject} />
              </div>
            );
          }
          return (
            <Checkbox
              displayOnly
              label={subOption?.headerText}
              onClick={() => setOptions(manageList(selectedOptions, subOption, 'id'), '')}
              value={selectedOptions.some((item) => item.id === subOption.id)}
            />
          );
        })
      }
    </div>
  );

  const visualObservationPE = useMemo(() => {
    const obsSex = props.form?.fieldsList.find((field) => field.id === 'DXGAn9q3kpXcvba0akP5I')?.panelElement;
    const obsEthnicity = props.form?.fieldsList.find((field) => field.id === '2z2InEira3fmkeKxGLxSP')?.panelElement;
    const obsRace = props.form?.fieldsList.find((field) => field.id === 'N1iN0zngYzIcf8ilp0m89')?.panelElement;
    return { obsEthnicity, obsRace, obsSex };
  }, [props.form]);

  const renderEditMode = () => (
    <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-4">
      <div>
        <Typography variant="body1Bold">Sex:</Typography>
        <Typography className="mt-1" variant="caption">Select all that apply</Typography>
        <div className="flex flex-col gap-4 pt-4">
          {
            sexOptions.map((option) => (
              <Checkbox
                displayOnly
                label={option?.headerText}
                onClick={() => setSex(manageList(sex, option, 'id'), '')}
                value={sex.some((item: PanelElementOption.AsObject) => item.id === option.id)}
              />
            ))
          }
          {!isConsumer && (
            <RadioGroup
              displayOnly={false}
              panelElement={getPanelElement({
                headerText: 'Was the sex collected on the basis of visual observation or surname?',
                id: 'DXGAn9q3kpXcvba0akP5I',
                optionsList: visualObservationPE.obsSex?.optionsList,
                type: 'radio',
              })}
            />
          )}
        </div>
      </div>
      <div>
        <Typography variant="body1Bold">Ethnicity:</Typography>
        <Typography className="mt-1" variant="caption">Select all that apply</Typography>
        <div className="flex flex-col gap-4 pt-4">
          {
            ethnicityOptions?.map((option) => (
              <>
                <Checkbox
                  displayOnly
                  label={option?.headerText}
                  onClick={() => setEthnicity(manageList(ethnicity, option, 'id'), '')}
                  value={ethnicity.some((item: PanelElementOption.AsObject) => item.id === option.id)}
                />
                {option?.headerText === 'Hispanic Or Latino' && renderSubOptions(hispanicOptions, hispanicOrLatino, setHispanicOrLatino)}
              </>
            ))
          }
          {!isConsumer && (
            <RadioGroup
              displayOnly={false}
              panelElement={getPanelElement({
                headerText: 'Was the ethnicity collected on the basis of visual observation or surname?',
                id: '2z2InEira3fmkeKxGLxSP',
                optionsList: visualObservationPE.obsEthnicity?.optionsList,
                type: 'radio',
              })}
            />
          )}
        </div>
      </div>
      <div>
        <Typography variant="body1Bold">Race:</Typography>
        <Typography className="mt-1" variant="caption">Select all that apply</Typography>
        <div className="flex flex-col gap-4 pt-4">
          {
            raceOptions?.map((option) => (
              <>
                <Checkbox
                  displayOnly
                  label={option?.headerText}
                  onClick={() => setRaces(manageList(races, option, 'id'), '')}
                  value={races.some((item: PanelElementOption.AsObject) => item.id === option.id)}
                />
                {option?.headerText === 'American Indian Or Alaska Native' && renderSubOptions(americanIndianOptions, designationTypes, setDesignationTypes)}
                {option?.headerText === 'Asian' && renderSubOptions(asianOptions, designationTypes, setDesignationTypes)}
                {option?.headerText === 'Native Hawaiian Or Other Pacific Islander' && renderSubOptions(pacificOptions, designationTypes, setDesignationTypes)}
              </>
            ))
          }
          {!isConsumer && (
            <RadioGroup
              displayOnly={false}
              panelElement={getPanelElement({
                headerText: 'Was the race collected on the basis of visual observation or surname?',
                id: 'N1iN0zngYzIcf8ilp0m89',
                optionsList: visualObservationPE.obsRace?.optionsList,
                type: 'radio',
              })}
            />
          )}
        </div>
      </div>
    </div>
  );

  const selectedSexDisplayValue = useMemo(() => values[sexId]?.value?.map((e: any) => e?.headerText).toString() || '--', [values]);
  const selectedEthnicityValue = useMemo(() => {
    const selectedEthnicities = values[ethnicityId]?.value?.map((e: any) => e?.headerText) || [];
    const selectedHispanic = values[hispanicOrLatinoId]?.value?.map((e: any) => e?.headerText) || [];
    const otherHispanic = [values['8Vv5XR6jeGtqY_Z6Cc2kt']?.value];
    return [...selectedEthnicities, ...selectedHispanic, ...otherHispanic].toString() || '--';
  }, [values]);

  const seledctedRaceValue = useMemo(() => {
    const selectedRaces = values[raceId]?.value?.map((e: any) => e?.headerText) || [];
    const selectedDesignations = values[designationTypeId]?.value?.map((e: any) => e?.headerText) || [];
    const otherDescriptions = [values.GU_XYYUWkAaxpiz5ffQfh?.value, values.OwdGRQmtbrODBWEPl9ALM?.value, values['4JBvWroAPxeepEB6P-mek']?.value];
    return [...selectedRaces, ...selectedDesignations, ...otherDescriptions].filter((e) => e).toString() || '--';
  }, [values]);

  const handleDiscardAnywayClick = (_: any, callback?: any) => {
    removeEditingWidget(props.id);
    if (callback) callback();
  };

  const handleSaveBeforeLeaving = useEvent((_: any, callback?: any) => {
    document.getElementById('hdma-submit-button')?.click();
    callback();
  });

  useBlocker(context === 'page' && isEditing && Boolean(props.allowPrompt), {
    actions: [
      { danger: true, label: 'Discard', onClick: handleDiscardAnywayClick, variant: 'tertiary' },
      { label: 'Save', onClick: handleSaveBeforeLeaving, variant: 'tertiary' },
    ],
    children: (
      <Typography variant="body2">
        To continue navigation, you must select Discard or Save.
      </Typography>
    ),
    id: props.id,
    onClickDismiss: () => removeEditingWidget(props.id),
    title: 'Selection Needed',
  });

  const renderReadMode = () => (
    <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3 w-full">
      <ListItem
        label={selectedSexDisplayValue}
        overline="Sex"
      />
      <ListItem
        label={selectedEthnicityValue}
        overline="Ethnicity"
      />
      <ListItem
        label={seledctedRaceValue}
        overline="Race"
      />
    </div>
  );

  const renderContent = () => {
    if (isEditing) return renderEditMode();
    return renderReadMode();
  };

  return (
    <Card
      defaultOpen
      headerProps={{
        actions,
        open: expandStatus !== ExpandStatusTypeEnum.COLLAPSE_ALL || context === 'dialog',
        subtitle: generateDialogKeywordLinks(props.form?.helpText || '', props.form?.hyperLinksList || []),
        title: 'Demographic',
      }}
      variant="border"
    >
      <div className="p-4 flex flex-row flex-wrap gap-4">
        {renderContent()}
        <button
          data-additionalinfo={JSON.stringify({
            loanId,
            pageId,
            scopeToken,
            type: 'form',
            widgetId: props.id,
            widgets: [props],
          })}
          hidden
          id="hdma-submit-button"
          type="submit"
        >
          submit
        </button>
      </div>
    </Card>
  );
};
