import type { LenderCondition } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { ConditionSatisfactionResponsiblePartyEnum, ConditionSatisfactionTimeframeEnum, ConditionTemplateCategoryEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { CardListItemSubmitHandler } from '@/Components/Atoms/Card/CardListItem';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { ConvertEnum, getEnumPanelElementOptionList } from '@/Utils/Helpers/enumHelpers';
import { getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';

interface ConditionTemplateProps {
  isEdit?: boolean;
  condition?: LenderCondition.AsObject;
  handleSubmit?: CardListItemSubmitHandler;
  isLoading?: boolean;
  handleIsEditing?: (bool: boolean) => void;
  isSaving?: boolean;
}

const categoryPE = getPanelElement({
  headerText: 'Condition Category',
  id: 'category',
  optionsList: getEnumPanelElementOptionList(ConditionTemplateCategoryEnum),
  requirement: getPanelElementRequiment(),
  type: 'select',
});
const timeframePE = getPanelElement({
  headerText: 'Satisfaction Timeframe',
  id: 'satisfactionTimeframe',
  optionsList: getEnumPanelElementOptionList(ConditionSatisfactionTimeframeEnum),
  requirement: getPanelElementRequiment(),
  type: 'select',
});
const partyPE = getPanelElement({
  headerText: 'Satisfaction Responsible Party',
  id: 'satisfactionResponsibleParty',
  optionsList: getEnumPanelElementOptionList(ConditionSatisfactionResponsiblePartyEnum),
  requirement: getPanelElementRequiment(),
  type: 'select',
});

export const ConditionTemplate = (props: ConditionTemplateProps) => (
  <CardListItem
    allowPrompt
    displayOnly={!props.isEdit}
    editByDefault={!props.isEdit}
    handleIsEditing={props.handleIsEditing}
    headerProps={{ title: 'Condition Detail' }}
    hideActions={!props.isEdit}
    isLoading={props.isLoading}
    isSaving={props.isSaving}
    listItems={[
      {
        label: props.condition ? ConvertEnum(ConditionTemplateCategoryEnum, props.condition.category) : '',
        overline: 'Category',
        panelElement: categoryPE,
        value: { value: categoryPE.optionsList.find((opt) => Number(opt.id) === props.condition?.category) },
      },
      {
        label: props.condition?.internalName || '',
        overline: 'Internal Name',
        panelElement: getPanelElement({
          headerText: 'Internal Name',
          id: 'internalName',
          requirement: getPanelElementRequiment(),
          type: 'text',
        }),
        value: { value: props.condition?.internalName },
      },
      {
        label: props.condition?.title || '',
        overline: 'Title',
        panelElement: getPanelElement({
          headerText: 'Title',
          id: 'title',
          requirement: getPanelElementRequiment(),
          type: 'text',
        }),
        value: { value: props.condition?.title },
      },
      {
        label: props.condition?.conditionDescription || '',
        overline: 'Description',
        panelElement: getPanelElement({
          headerText: 'Condition Description',
          id: 'description',
          requirement: getPanelElementRequiment(),
          type: 'text',
        }),
        value: { value: props.condition?.conditionDescription },
      },
      {
        label: props.condition ? ConvertEnum(ConditionSatisfactionTimeframeEnum, props.condition?.conditionSatisfactionTimeframeType) : '',
        overline: 'Satisfaction Timeframe',
        panelElement: timeframePE,
        value: { value: timeframePE.optionsList.find((opt) => Number(opt.id) === props.condition?.conditionSatisfactionTimeframeType) },
      },
      {
        label: props.condition ? ConvertEnum(ConditionSatisfactionResponsiblePartyEnum, props.condition?.conditionSatisfactionResponsiblePartyType) : '',
        overline: 'Satisfaction Resposible Party',
        panelElement: partyPE,
        value: { value: partyPE.optionsList.find((opt) => Number(opt.id) === props.condition?.conditionSatisfactionResponsiblePartyType) },
      },
    ]}
    onSubmit={props.handleSubmit}
    variant="border"
  />
);
