import type { FeesAdjustmentsCreditsSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { DisplayType } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useGetFeesAdjustmentsCredits } from '@/API/Queries/mortgage/useGetFeesAdjustmentsCredits';
import type { UseUpdateFeesAdjustmentsCreditsDTO } from '@/API/Queries/mortgage/useUpdateFeesAdjustmentsCredits';
import { useUpdateFeesAdjustmentsCredits } from '@/API/Queries/mortgage/useUpdateFeesAdjustmentsCredits';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import {
  blockedInputs,
  EarnestMoneyEnumType,
  earnestMoneyOptions,
  InterestRateEnumType,
  interestRateToggleOptions,
  LenderCreditEnumType,
  lenderCreditToggleOptions,
  ProrationsEnumType,
  prorationsOptions,
  toggleInputs,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/adjustmentsCreditsHelper';
import { getPanelElement, getPanelElementMask } from '@/Utils/Helpers/getPanelElement';
import { capitalizeFirstAndLowerRest } from '@/Utils/Helpers/normalizeString';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const toggles = [
  'LenderCreditAmountUsedForCalculationsType',
  'SellerCreditAmountUsedForCalculationsType',
  'ProrationAmountUsedForCalculationsType',
  'EarnestMoneyAmountUsedForCalculationsType',
  'SellerCreditAmountUsedForCalculationsType',
];

const AdjustmentsCreditsDetails = () => {
  const { data, isLoading, refetch } = useGetFeesAdjustmentsCredits();
  const { isLoading: isSaving, mutate: updateFeesAdjustmentsCredits } = useUpdateFeesAdjustmentsCredits();
  const { showSnackBar } = useShared();
  const { loanId } = useDynamicLoanInfo();

  if (isLoading) return <div />;

  const getEnumValue = (id: string, value: string): number | string => {
    const enumMappings: Record<string, Record<string, number>> = {
      EarnestMoneyAmountUsedForCalculationsType: {
        Contract: EarnestMoneyEnumType.CONTRACT,
        Verified: EarnestMoneyEnumType.VERIFIED,
      },
      LenderCreditAmountUsedForCalculationsType: {
        Applied: LenderCreditEnumType.APPLIED,
        Disclosed: LenderCreditEnumType.DISCLOSED,
      },
      ProrationAmountUsedForCalculationsType: {
        Applied: ProrationsEnumType.APPLIED,
        Estimated: ProrationsEnumType.ESTIMATED,
      },
      SellerCreditAmountUsedForCalculationsType: {
        Applied: InterestRateEnumType.APPLIED,
        Contract: InterestRateEnumType.CONTRACT,
      },
    };

    return enumMappings[id]?.[value] ?? value;
  };

  const getDropdownOptions = (id: string) => {
    switch (id) {
      case 'EarnestMoneyAmountUsedForCalculationsType':
        return earnestMoneyOptions;
      case 'ProrationAmountUsedForCalculationsType':
        return prorationsOptions;
      case 'LenderCreditAmountUsedForCalculationsType':
        return lenderCreditToggleOptions;
      case 'SellerCreditAmountUsedForCalculationsType':
        return interestRateToggleOptions;
      default:
        return [];
    }
  };

  const getDropdownCaption = (id: string, value: string) => {
    const enumMap = {
      EarnestMoneyAmountUsedForCalculationsType: EarnestMoneyEnumType,
      LenderCreditAmountUsedForCalculationsType: LenderCreditEnumType,
      ProrationAmountUsedForCalculationsType: ProrationsEnumType,
      SellerCreditAmountUsedForCalculationsType: InterestRateEnumType,
    };

    const enumType = enumMap[id as keyof typeof enumMap];
    if (enumType) {
      return capitalizeFirstAndLowerRest(enumType[Number(value)]);
    }
    return '';
  };
  const convertToPanelElement = (listItem: Array<{ id: string; caption: string; order: number; value: string; type: DisplayType; isEditable: boolean }>) => listItem
    .sort((a, b) => a.order - b.order)
    .map((item) => {
      const { caption, id, isEditable, type, value } = item;
      const isDropdown = toggleInputs.includes(id);
      const isToggle = toggles.includes(id);

      if (isDropdown) {
        const options = getDropdownOptions(id);
        const dropdownCaption = getDropdownCaption(id, value);

        return {
          caption: dropdownCaption,
          label: caption,
          panelElement: getPanelElement({
            disabled: blockedInputs.includes(id) || !isEditable,
            headerText: caption,
            id,
            optionsList: options,
            type: isToggle ? 'switch' : 'select',
          }),
          value: {
            value: isToggle ? getDropdownCaption(id, value) : value,
          },
        };
      }

      const getMaskType = () => {
        switch (type) {
          case DisplayType.DISPLAY_TYPE_CURRENCY:
            return getPanelElementMask({ type: 'currency' });
          case DisplayType.DISPLAY_TYPE_PERCENTAGE:
            return getPanelElementMask({ type: 'percentage' });
          default:
            return undefined;
        }
      };
      const cleanedValue = value?.replace(/[%$]/g, '');

      return {
        caption: value,
        label: caption,
        panelElement: getPanelElement({
          disabled: blockedInputs.includes(id),
          headerText: caption,
          id,
          mask: getMaskType(),
          type: 'number',
        }),
        value: { value: cleanedValue },
      };
    });

  const handleUpdateAdjustmentsCredits = (section: FeesAdjustmentsCreditsSection.AsObject) => (
    formData: DynamicDataElementValues,
    close: () => void,
  ) => {
    const { cardListList, order, sectionName, sectionType } = section;

    const updatedCardListValues = cardListList.map((list) => ({
      ...list,
      value: String(getEnumValue(list.id, formData[list.id]?.value)),
    }));

    const updatedTemplate: UseUpdateFeesAdjustmentsCreditsDTO = {
      dealId: loanId,
      feesAdjustmentsCreditsSection: {
        cardListList: updatedCardListValues,
        order,
        sectionName,
        sectionType,
      },
    };

    updateFeesAdjustmentsCredits(updatedTemplate, {
      onError: () => {
        showSnackBar({ message: 'Something went wrong, please try again later.', open: true });
      },
      onSuccess: () => {
        close();
        refetch();
        showSnackBar({ message: 'Fee successfully updated', open: true });
      },
    });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {
        data?.sectionsList
          .sort((a, b) => a.order - b.order)
          .map((section) => (
            <CardListItem
              key={section.sectionName}
              headerProps={{ title: section.sectionName }}
              isEditable
              isLoading={isLoading}
              isSaving={isSaving}
              listItems={convertToPanelElement(section.cardListList)}
              numberOfColumns={1}
              onSubmit={handleUpdateAdjustmentsCredits(section)}
              variant="border"
              widgetId={section.sectionName}
            />
          ))
      }
    </div>
  );
};

export default AdjustmentsCreditsDetails;
