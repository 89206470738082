import clsx from 'clsx';
import type { MouseEvent } from 'react';
import { useRef } from 'react';
import type { Row } from 'react-table';

import { Button } from '@/Components/Atoms/Button';
import { Icon } from '@/Components/Atoms/Icon';
import { Menu } from '@/Components/Atoms/Menu';

export interface IHoverActions<T> {
  label: string;
  children?: IHoverActions<T>[];
  onClick: (item: T) => void;
  isLoading?: boolean;
  icon?: string;
}

export interface IHoverActionsProps<T> {
  data: Array<any>;
  hoverActions: Array<IHoverActions<T>>;
  row: Row<any>;
  hide: boolean;
}

export const HoverActions = <T extends Record<string, unknown>>(props: IHoverActionsProps<T>) => {
  const { data, hide, hoverActions, row } = props;
  const [firstHoverAction, secondHoverAction, ...restHoverActions] = hoverActions;

  const containerRef = useRef<HTMLTableCellElement>(null);
  const getDataId = (rowId: string) => data[parseInt(rowId, 10)] as T;

  const handleClickButton = (event: MouseEvent<HTMLButtonElement>, click: any) => {
    event.stopPropagation();
    click();
  };

  const renderAction = (action: IHoverActions<T>) => {
    if (action.icon) {
      return (
        <Icon
          icon={action.icon}
          onClick={(event) => handleClickButton(event, () => action.onClick(getDataId(row.id)))}
        />
      );
    }
    return (
      <Button
        isLoading={action.isLoading}
        label={action.label}
        onClick={(event) => handleClickButton(event, () => action.onClick(getDataId(row.id)))}
        type="button"
        variant="tertiary"
      />
    );
  };

  return (
    <div
      ref={containerRef}
      className={
        clsx(
          'flex items-center flex-row',
          '[&_svg]:text-primary-on-surface',
          { 'bg-on-surface-states-hover': !hide, 'bg-transparent': hide },
        )
      }
    >
      <div
        className={clsx('flex items-center flex-row', { 'opacity-0': hide })}
      >
        {firstHoverAction && renderAction(firstHoverAction)}
        {secondHoverAction && renderAction(secondHoverAction)}
        {restHoverActions.length > 0 && (
          <Menu
            options={restHoverActions.map((action) => ({ label: action.label, onClick: () => action.onClick(getDataId(row.id)) }))}
            triggerItem={(
              <Button
                label="More"
                rightIcon="ArrowDropDown"
                variant="tertiary"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
