interface IconProps {
  className?: string;
}

const CreditScore = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M4 8h16V6H4ZM2 6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v6H4v6h4.1v2H4q-.825 0-1.412-.587Q2 18.825 2 18Zm12.95 16-4.25-4.25 1.4-1.4 2.85 2.8 5.65-5.65 1.4 1.45ZM4 6v12-4.5 2.825V6Z" />
  </svg>
);

export default CreditScore;
