import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { useDynamicLoanPage } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import type { DynamicDataElementValues, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { DynamicPage } from '@/Components/Widgets/DynamicPage';
import { useSearchParams } from '@/Routes/NavigationContext';

import { useLoanNavigation } from './LoanNavigationContext';
import { useSaveLoanPage } from './useSaveLoanPage';

export const DynamicLoanPages = () => {
  const { backToLoanOverview, createPageNavigation } = useLoanNavigation();

  const { loanId = '' } = useParams();
  const { setVersion } = useDynamicContext();
  const [searchParams] = useSearchParams();
  const { pageId, scopeToken, tabId } = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const { data, isLoading, refetch } = useDynamicLoanPage(loanId, pageId, scopeToken, tabId);
  // const contextValues = useWidgetContextValues();
  const { contextValues, savePage } = useSaveLoanPage(refetch, data?.page?.saveStrategy);

  // when page data loads:
  // - sets context deal version
  // - create page configuration for header chips
  useEffect(() => {
    if (data?.page) {
      setVersion(data.dealVersion);
      if (data?.page?.header?.navigationChipTitle !== 'Loan Overview') {
        createPageNavigation([
          {
            label: data?.page?.header?.navigationChipTitle || '',
            onClickClose: backToLoanOverview,
            selected: true,
          },
        ]);
      }
    }
  }, [backToLoanOverview, createPageNavigation, data, setVersion]);

  const handleUpdate = useCallback((values: DynamicDataElementValues, { additionalFormInfo, callback, event, path: saveConfigStr }: SubmitValues) => {
    savePage(values, saveConfigStr, callback, event, additionalFormInfo);
  }, [savePage]);

  return (
    <DynamicFormProvider
      dealId={loanId}
      onSubmit={handleUpdate}
      page={data?.page}
      scopeToken={scopeToken}
    >
      <DynamicPage
        context="page"
        isLoading={isLoading}
        page={data?.page}
        pageId={pageId}
        scopeToken={scopeToken}
        tabId={tabId}
        {...contextValues}
      />
    </DynamicFormProvider>
  );
};
