import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { DeleteFeeCommandRequest, DeleteFeeCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

interface UseDeleteFeeDTO {
  feeId: string;
  dealId: string;
  loanId: string;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.DeleteFeeCommandRequest';

export const useDeleteFee = () => {
  const { commandResponse } = useBackend();

  const deleteFee = async (dto: UseDeleteFeeDTO) => {
    const request = new DeleteFeeCommandRequest();

    request.setDealId(dto.dealId);
    request.setLoanId(dto.loanId);
    request.setFeeId(dto.feeId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new DeleteFeeCommandResponse();
    DeleteFeeCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error);
  };

  return useMutation(deleteFee);
};
