interface IconProps {
  className?: string;
}

const Number6 = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M13.4941 7.38086H13.7402V8.72852H13.6406C13.1953 8.72852 12.7988 8.79492 12.4512 8.92773C12.1035 9.06055 11.8086 9.25 11.5664 9.49609C11.3281 9.73828 11.1445 10.0312 11.0156 10.375C10.8906 10.7188 10.8281 11.1035 10.8281 11.5293V12.9473C10.8281 13.252 10.8574 13.5215 10.916 13.7559C10.9785 13.9863 11.0664 14.1797 11.1797 14.3359C11.2969 14.4883 11.4336 14.6035 11.5898 14.6816C11.7461 14.7559 11.9219 14.793 12.1172 14.793C12.3008 14.793 12.4668 14.7539 12.6152 14.6758C12.7676 14.5977 12.8965 14.4883 13.002 14.3477C13.1113 14.2031 13.1934 14.0352 13.248 13.8438C13.3066 13.6484 13.3359 13.4355 13.3359 13.2051C13.3359 12.9746 13.3066 12.7617 13.248 12.5664C13.1934 12.3711 13.1113 12.2031 13.002 12.0625C12.8926 11.918 12.7598 11.8066 12.6035 11.7285C12.4473 11.6504 12.2715 11.6113 12.0762 11.6113C11.8105 11.6113 11.5762 11.6738 11.373 11.7988C11.1738 11.9199 11.0176 12.0762 10.9043 12.2676C10.791 12.459 10.7305 12.6602 10.7227 12.8711L10.2129 12.543C10.2246 12.2422 10.2871 11.957 10.4004 11.6875C10.5176 11.418 10.6777 11.1797 10.8809 10.9727C11.0879 10.7617 11.3359 10.5977 11.625 10.4805C11.9141 10.3594 12.2402 10.2988 12.6035 10.2988C13.002 10.2988 13.3516 10.375 13.6523 10.5273C13.957 10.6797 14.2109 10.8887 14.4141 11.1543C14.6172 11.4199 14.7695 11.7266 14.8711 12.0742C14.9727 12.4219 15.0234 12.793 15.0234 13.1875C15.0234 13.6016 14.9551 13.9863 14.8184 14.3418C14.6855 14.6973 14.4922 15.0078 14.2383 15.2734C13.9883 15.5391 13.6875 15.7461 13.3359 15.8945C12.9844 16.043 12.5898 16.1172 12.1523 16.1172C11.6992 16.1172 11.2871 16.0352 10.916 15.8711C10.5488 15.7031 10.2324 15.4688 9.9668 15.168C9.70117 14.8672 9.49609 14.5098 9.35156 14.0957C9.21094 13.6816 9.14062 13.2285 9.14062 12.7363V12.0801C9.14062 11.3926 9.24023 10.7637 9.43945 10.1934C9.64258 9.61914 9.93359 9.12305 10.3125 8.70508C10.6914 8.2832 11.1484 7.95703 11.6836 7.72656C12.2188 7.49609 12.8223 7.38086 13.4941 7.38086Z" />
  </svg>
);

export default Number6;
