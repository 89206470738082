import { useCreateActivityTemplate } from '@/API/Queries/activities/useCreateActivityTemplate';
import { useUpdateActivityDesign } from '@/API/Queries/activities/useUpdateActivityDesign';
import { useProgressItemTemplates } from '@/API/Queries/mortgageConfig/useProgressItemTemplates';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues, FormProgress } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useNavigate } from '@/Routes/NavigationContext';
import { getPanel, getPanelElement, getPanelElementOption, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import type { ISnackBar } from '@/Utils/Hooks/useShared/useShared';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { useActivityConfiguratorContext } from '../configurator/activityConfiguratorContext';

interface ActivityTemplateCreateProps {
  closeModal: () => void;
  progress?: FormProgress;
  isEdit?: boolean;
  showActivity?: (form: any) => void;
  open?: boolean;
}

export const ActivityTemplateCreator = (props: ActivityTemplateCreateProps) => {
  const { closeModal, isEdit = false, open, progress, showActivity } = props;

  const navigate = useNavigate();
  const { designerPanel, updateDesignerPanel } = useActivityConfiguratorContext();
  const { isLoading: isCreating, mutate: createActivityTemplate } = useCreateActivityTemplate();
  const { isLoading: isUpdating, mutate: updateActivityDesign } = useUpdateActivityDesign();
  const { data: progressItems } = useProgressItemTemplates();
  const { showSnackBar } = useShared();

  const handleUpdateSuccess = (updateObj: any) => {
    closeModal();
    showSnackBar({ message: `${designerPanel.internalName} updated`, open: true });
    updateDesignerPanel(updateObj);
  };

  const handleError = (error: any) => {
    showSnackBar({
      message: error.message || 'Something went wrong, try again later',
      open: true,
    });
  };

  const handleCreateSuccess = (response?: { id: string }) => {
    closeModal();
    if (response) navigate(`/admin/activity/${response.id}/configurator/content`);
  };

  const handleCreateError = (error: any, form: DynamicDataElementValues) => {
    const options: ISnackBar = { message: error.message, open: true };
    if (error.message === 'Activity Template you are trying to add already exists') {
      options.buttonLabel = 'View';
      options.onClick = () => showActivity && showActivity(form);
    }
    showSnackBar(options);
  };

  const handleSubmit = (form: DynamicDataElementValues) => {
    const params = {
      allowConcurrence: form?.allowConcurrence?.value || false,
      allowMultipleInstances: form?.allowMultipleInstances?.value || false,
      externalName: form.externalName.value,
      internalName: form.internalName.value,
      progressType: {
        consumerDescription: form.progressItem.value.summaryText,
        identifier: form.progressItem.value.id,
        lenderDescription: '',
        name: form.progressItem.value.headerText,
        screenOrder: form.progressItem.value.order,
      },
    };
    if (isEdit) {
      updateActivityDesign(params, { onError: handleError, onSuccess: () => handleUpdateSuccess(params) });
    } else {
      createActivityTemplate(params, {
        onError: (error) => handleCreateError(error, form),
        onSuccess: handleCreateSuccess,
      });
    }
  };

  return (
    <FullPageStepper
      close={closeModal}
      handleSubmit={handleSubmit}
      openModal={Boolean(open)}
      panels={[
        getPanel({
          headerText: 'Activity name',
          id: 'activity-name',
          panelElementsList: [
            getPanelElement({
              headerText: 'External name',
              id: 'externalName',
              order: 1,
              requirement: getPanelElementRequiment('External name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Internal name',
              id: 'internalName',
              order: 2,
              requirement: getPanelElementRequiment('Internal name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'What progress type is this activity associated to?',
              id: 'progressItem',
              optionsList: progressItems?.map((progress) => getPanelElementOption({
                headerText: progress.name,
                id: progress.identifier,
                order: progress.screenOrder,
                summaryText: progress.consumerDescription,
              })),
              order: 3,
              requirement: getPanelElementRequiment('Progress Type is required'),
              type: 'select',
            }),
            getPanelElement({
              headerText: 'Can be manually triggered',
              id: 'allowConcurrence',
              order: 4,
              type: 'checkbox',
            }),
            getPanelElement({
              headerText: 'Can exist more than once on a loan',
              id: 'allowMultipleInstances',
              order: 5,
              type: 'checkbox',
            }),
          ],
          summaryText: 'What would you like to name this activity',
        }),
      ]}
      progress={progress}
      saving={isCreating || isUpdating}
      title={isEdit ? `Update ${progress?.values.internalName.value}` : 'Create new Activity Template'}
    />
  );
};
