import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import type { LeadSource, ReferralSource } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { DOMAIN_LEAD_SOURCES, useLeadSources } from '@/API/Queries/user/useLeadSources';
import { useUpsertLeadSource } from '@/API/Queries/user/useUpsertLeadSource';
import { Button } from '@/Components/Atoms/Button';
import Input from '@/Components/Atoms/Input/Input.component';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Toggle } from '@/Components/Atoms/Toggle';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface LeadSourceItem extends LeadSource.AsObject {
  referralSource: string;
}

const LeadAndReferralSourcesList = () => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useShared();
  const { isLoading: saving, mutate: upsertLeadSource } = useUpsertLeadSource();
  const { data: leadSources, isLoading } = useLeadSources();

  const [leadSource, setLeadSource] = useState<LeadSource.AsObject>({ active: true, id: '', name: '', referralSourcesList: [] });
  const [openModal, setOpenModal] = useState<'create' | 'edit' | 'none'>('none');
  const [newReferralSource, setNewReferralSource] = useState<string>('');

  const onClose = useCallback(() => {
    setLeadSource({ active: true, id: '', name: '', referralSourcesList: [] });
    setOpenModal('none');
  }, []);

  const handleUpdateLeadSource = useCallback((leadSource: LeadSource.AsObject) => {
    upsertLeadSource({ ...leadSource, active: !leadSource.active }, {
      onError() {
        showSnackBar({ message: 'Something went wrong', open: true });
      },
      onSuccess() {
        queryClient.invalidateQueries([DOMAIN_LEAD_SOURCES]);
      },
    });
  }, [queryClient, showSnackBar, upsertLeadSource]);

  const handleEditLeadSource = useCallback((leadSource: LeadSource.AsObject) => {
    setLeadSource(leadSource);
    setOpenModal('edit');
  }, []);

  const columns = useMemo((): Array<Column<LeadSourceItem>> => [{
    header: '',
    id: 'toggle',
    key: {
      children: 'toggle',
      type: 'generic',
    },
  }, {
    header: 'Lead Source',
    id: 'name',
    key: {
      text: 'name',
      type: 'text',
    },
  }, {
    header: 'Referal Sources',
    id: 'referralSource',
    key: {
      text: 'referralSource',
      type: 'text',
    },
  }], []);

  const data = useMemo(() => leadSources?.map((leadSource) => {
    const referralSources = leadSource.referralSourcesList;
    let referralSource = referralSources.length > 0 ? referralSources[0].name : '';

    if (referralSources.length > 1) {
      referralSource += ` + ${referralSources.length - 1}`;
    }

    return {
      active: leadSource.active,
      id: leadSource.id,
      name: leadSource.name,
      referralSource,
      referralSourcesList: leadSource.referralSourcesList,
      toggle: (
        <Toggle onClick={() => handleUpdateLeadSource(leadSource)} value={leadSource.active} />
      ),
    };
  }), [handleUpdateLeadSource, leadSources]);

  const handleSubmit = useCallback(() => {
    upsertLeadSource(leadSource, {
      onError() {
        showSnackBar({ message: 'Something went wrong', open: true });
      },
      onSuccess() {
        const message = openModal === 'edit' ? 'Lead source edited' : 'Lead source created';
        showSnackBar({ message, open: true });
        queryClient.invalidateQueries([DOMAIN_LEAD_SOURCES]);
        onClose();
      },
    });
  }, [leadSource, onClose, openModal, queryClient, showSnackBar, upsertLeadSource]);

  const handleAddReferralSource = useCallback(() => {
    setLeadSource((prev) => ({
      ...prev,
      referralSourcesList: [
        ...prev.referralSourcesList,
        { active: true, id: '', name: newReferralSource },
      ],
    }));
    setNewReferralSource('');
  }, [newReferralSource]);

  const handleToggleReferralSourceState = useCallback((identifier: string) => {
    setLeadSource((prev) => ({
      ...prev,
      referralSourcesList: prev.referralSourcesList.map((referralSource) => {
        if (referralSource.name === identifier || referralSource.id === identifier) {
          return {
            ...referralSource,
            active: !referralSource.active,
          };
        }

        return referralSource;
      }),
    }));
  }, []);

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: () => setOpenModal('create') }],
            title: 'All Lead & Referral Sources',
          },
        }}
        columns={columns}
        data={data || []}
        hoverActions={[
          {
            label: 'Edit',
            onClick: handleEditLeadSource,
          },
        ]}
        isLoading={isLoading}
      />

      <Dialog onOpenChange={onClose} open={openModal !== 'none'}>
        <DialogLayout>
          <DialogHeader title={openModal === 'edit' ? 'Edit Lead Source' : 'Create Lead Source'} />

          <DialogContent>
            <div className="flex flex-1 flex-col overflow-auto">
              <div className="h-full self-center p-10 tablet:pt-6 max-w-[508px] w-full">
                <Input
                  label="Lead source name"
                  onChange={(value) => setLeadSource((prev) => ({ ...prev, name: value }))}
                  value={leadSource.name}
                />

                <Typography className="my-11" variant="body1">Add the referral source below.</Typography>

                <Input
                  label="Referral source name"
                  onChange={setNewReferralSource}
                  value={newReferralSource}
                />

                <Button
                  disabled={!newReferralSource}
                  label="referral"
                  leftIcon="Add"
                  onClick={handleAddReferralSource}
                  type="button"
                  variant="tertiary"
                />

                {leadSource.referralSourcesList.map((referralSource: ReferralSource.AsObject) => (
                  <div key={referralSource.id} className="flex justify-between border-b border-on-surface-stroke py-4">
                    <Typography variant="body1">{referralSource.name}</Typography>
                    <Toggle
                      onClick={() => handleToggleReferralSourceState(openModal === 'create' ? referralSource.name : referralSource.id)}
                      value={referralSource.active}
                    />
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>

          <DialogFooter borderTop>
            <Button
              disabled={!leadSource.name || !leadSource.referralSourcesList.length}
              isLoading={saving}
              label="save"
              onClick={handleSubmit}
              type="button"
            />
          </DialogFooter>
        </DialogLayout>
      </Dialog>
    </>
  );
};

export { LeadAndReferralSourcesList };
