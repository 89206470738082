import { type HTMLProps } from 'react';

import { cn } from '@/Utils/Helpers/uiBuilders';

export interface IDrawerContent extends HTMLProps<HTMLDivElement> {}

export const DrawerContent = ({
  children,
  className,
  ...rest
}: IDrawerContent) => (
  <div className={cn('overflow-auto flex-1 pb-12', className)} {...rest}>
    {children}
  </div>
);
