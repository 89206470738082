import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import type {
  IntegratedDisclosureSectionEnum,
  LoanEscrowFee,
  LoanFee,
  LoanFeeCategoryTypeEnum,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useUpdateFees } from '@/API/Queries/mortgage/useUpdateFees';
import { Button } from '@/Components/Atoms/Button';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Tabs } from '@/Components/Atoms/Tab';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import Escrows from './Escrows';
import Fees from './Fees';
import Prepaids from './Prepaids';

export interface FeeEditTotalsProps {
  onEditFee: (
    fee: LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject,
    categoryType: LoanFeeCategoryTypeEnum,
    FeeSectionType?: IntegratedDisclosureSectionEnum
  ) => void;
  onUpdateFees: () => void;
  handleClose: () => void;
}

const ManageFeesTable = (props: FeeEditTotalsProps) => {
  const { handleClose, onEditFee, onUpdateFees } = props;
  const { loanId = '' } = useParams();

  const [selectedTab, setSelectedTab] = useState<'loanFees' | 'prepaidsAndEscrows'>('loanFees');

  const { showSnackBar } = useShared();
  const { mutate: updateFees } = useUpdateFees();
  const {
    anyErrors,
    isDirty,
    loanEscrowFees,
    loanFees,
    loanPrepaidFees,
    overrideErrors,
    showModalTabs,
    totalMet,
  } = useFeesContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const disabled = isDirty || totalMet.exceeded || anyErrors || Object.keys(overrideErrors).length !== 0;

  const handleUpdateFee = useCallback(() => {
    if (loanFees && loanEscrowFees && loanPrepaidFees) {
      updateFees({
        dealId: loanId,
        escrowCategory: loanEscrowFees,
        feeCategories: loanFees,
        loanId: '',
        prepaidCategory: loanPrepaidFees,
      }, {
        onError() {
          showSnackBar({ message: 'Something went wrong', open: true });
          setIsLoading(false);
        },
        onSuccess() {
          showSnackBar({ message: 'Fees updated successfully', open: true });
          setIsLoading(false);
          onUpdateFees();
        },
      });
    }
  }, [loanPrepaidFees, loanFees, loanEscrowFees, loanId, onUpdateFees, showSnackBar, updateFees]);

  return (
    <DialogLayout>
      <DialogHeader
        icon="Clear"
        iconAction={() => handleClose()}
        title="All Loan Fees"
      />
      <DialogContent>

        {showModalTabs ? (
          <>
            <Tabs
              className="mb-6"
              stretch
              tabsData={[
                { active: selectedTab === 'loanFees', label: 'Loan Fees', onClick: () => setSelectedTab('loanFees') },
                { active: selectedTab === 'prepaidsAndEscrows', label: 'Prepaids & Escrows', onClick: () => setSelectedTab('prepaidsAndEscrows') },
              ]}
            />

            {selectedTab === 'loanFees' && (
              <div className="p-4 tablet:p-8 desktop:p-6 desktop:py-4 gap-6 overflow-y-auto">
                <Fees onEditFee={onEditFee} />
              </div>
            )}
            {selectedTab === 'prepaidsAndEscrows' && (
              <div className="p-4 tablet:p-8 desktop:p-6 desktop:py-4 gap-6 overflow-y-auto grid gap-y-6">
                <Escrows onEditFee={onEditFee} />
                <Prepaids onEditFee={onEditFee} />
              </div>
            )}
          </>
        ) : (
          <div className="p-4 tablet:p-8 desktop:p-6 desktop:py-4 gap-6 overflow-y-auto">
            <Fees onEditFee={onEditFee} />
            <Escrows onEditFee={onEditFee} />
            <Prepaids onEditFee={onEditFee} />
          </div>
        )}
      </DialogContent>
      <DialogFooter borderTop>
        <Button
          disabled={disabled}
          isLoading={isLoading}
          label="update fees"
          onClick={() => handleUpdateFee()}
          variant="primary"
        />
      </DialogFooter>
    </DialogLayout>
  );
};

export default ManageFeesTable;
