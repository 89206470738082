/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
import { capitalize, kebabCase } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import type { Navigation } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { useLoanHomePageConfiguration } from '@/API/Queries/brand/useLoanHomePageConfiguration';
import type { IChipProps } from '@/Components/Atoms/Chip';
import { useNavigate } from '@/Routes/NavigationContext';

interface LoanNavigationContextState {
  navigationChips: IChipProps[];
  backToLoanOverview: () => void;
  createPageNavigation: (navigationList: Array<IChipProps>) => void;
}

const initialState: LoanNavigationContextState = {
  backToLoanOverview: () => {},
  createPageNavigation: () => {},
  navigationChips: [],
};

const LoanNavigationContext = createContext(initialState);
LoanNavigationContext.displayName = 'LoanNavigationContext';

interface LoanNavigationProviderProps { children: React.ReactNode }

const pathRegex = /loan\/[^/]+\/([^/]+)(?:\/([^/]+))?/gi;

const getNormalizedName = (str: string) => kebabCase(str).split('-').map((word) => capitalize(word)).join(' ');

const getCurrentRouteName = (pathname: string): string | undefined => {
  pathRegex.lastIndex = 0;
  const pathData = pathRegex.exec(pathname);
  if (pathData) {
    if (['progress', 'integration-history'].includes(pathData[1])) {
      return getNormalizedName(pathData[2]);
    }
    if (pathData[1] === 'summary' && pathData[2]) {
      return getNormalizedName(pathData[2]);
    }
    if (pathData[1] !== 'summary') {
      return getNormalizedName(pathData[1]);
    }
  }

  return undefined;
};

const isCurrentRoute = (search: string, navigation?: Navigation.AsObject) => {
  if (navigation) {
    const { dynamicPageId, scopeToken, tabId } = navigation;
    if (!dynamicPageId || !scopeToken) return false;
    return search.includes(dynamicPageId) && search.includes(tabId) && search.includes(scopeToken);
  }
  return false;
};

const LoanNavigationProvider = (props: LoanNavigationProviderProps) => {
  const { children } = props;

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [navigationChips, setNavigationChips] = useState<IChipProps[]>([]);

  const { data: loanPageConfig } = useLoanHomePageConfiguration();

  const close = useCallback(() => {
    navigate({
      pathname: 'dynamic-page',
      search: createSearchParams({
        pageId: loanPageConfig?.loanOverviewNavigation?.dynamicPageId || '',
        scopeToken: loanPageConfig?.loanOverviewNavigation?.scopeToken || '',
        tabId: loanPageConfig?.loanOverviewNavigation?.tabId || '',
      }).toString(),
    }, undefined);
  }, [loanPageConfig]);

  const onClick = useCallback((navigation: Navigation.AsObject) => {
    if (navigation.dynamicPageId) {
      navigate({
        pathname: 'dynamic-page',
        search: createSearchParams({
          pageId: navigation.dynamicPageId,
          scopeToken: navigation.scopeToken,
          tabId: navigation.tabId,
        }).toString(),
      });
    } else {
      navigate(navigation.linkTo, undefined);
    }
  }, []);

  const defaultPageNavigation = useMemo(() => loanPageConfig?.navigationList.map((navigation): IChipProps => {
    const isSelected = isCurrentRoute(search, navigation);

    return {
      icon: navigation.icon,
      label: navigation.label,
      onClick: () => onClick(navigation),
      onClickClose: isSelected ? close : undefined,
      selected: isSelected,
    };
  }) || [], [close, loanPageConfig, search, onClick]);

  const createPageNavigation = useCallback((navigationList: Array<IChipProps>) => {
    setNavigationChips(navigationList);
  }, []);

  const backToLoanOverview = useCallback(() => {
    navigate({
      pathname: 'dynamic-page',
      search: createSearchParams({
        pageId: loanPageConfig?.loanOverviewNavigation?.dynamicPageId || '',
        scopeToken: loanPageConfig?.loanOverviewNavigation?.scopeToken || '',
        tabId: loanPageConfig?.loanOverviewNavigation?.tabId || '',
      }).toString(),
    });

    const chips = defaultPageNavigation && defaultPageNavigation.map((navigation) => ({ ...navigation, onClickClose: undefined, selected: false }));
    setNavigationChips(chips);
  }, [defaultPageNavigation, loanPageConfig?.loanOverviewNavigation?.dynamicPageId, loanPageConfig?.loanOverviewNavigation?.scopeToken, loanPageConfig?.loanOverviewNavigation?.tabId]);

  useEffect(() => {
    if (navigationChips.length === 0) {
      setNavigationChips(defaultPageNavigation);
    }
  }, [defaultPageNavigation, navigationChips.length]);

  useEffect(() => {
    if (!pathname.includes('dynamic-page')) {
      const label = getCurrentRouteName(pathname);
      if (label) {
        setNavigationChips([
          { label, onClickClose: backToLoanOverview, selected: true },
        ]);
      }
    }
  }, [pathname, backToLoanOverview]);

  return (
    <LoanNavigationContext.Provider value={{ backToLoanOverview, createPageNavigation, navigationChips }}>
      {children}
    </LoanNavigationContext.Provider>
  );
};

const useLoanNavigation = () => useContext(LoanNavigationContext);
export { LoanNavigationProvider, useLoanNavigation };
