import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type AnyProps = Record<string, any>;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export function mergeReactProps(parentProps: AnyProps, childProps: AnyProps) {
  const overrideProps = { ...childProps };

  for (const propName in childProps) {
    const parentPropValue = parentProps[propName];
    const childPropValue = childProps[propName];

    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      if (childPropValue && parentPropValue) {
        overrideProps[propName] = (...args: unknown[]) => {
          childPropValue?.(...args);
          parentPropValue?.(...args);
        };
      } else if (parentPropValue) {
        overrideProps[propName] = parentPropValue;
      }
    } else if (propName === 'style') {
      overrideProps[propName] = { ...parentPropValue, ...childPropValue };
    } else if (propName === 'className') {
      overrideProps[propName] = [parentPropValue, childPropValue]
        .filter(Boolean)
        .join(' ');
    }
  }

  return { ...parentProps, ...overrideProps };
}

function setRef<TInstance>(ref: React.Ref<TInstance>, instance: TInstance) {
  if (ref instanceof Function) {
    ref(instance);
  } else if (ref != null) {
    (ref as React.MutableRefObject<TInstance>).current = instance;
  }
}

export function combinedRef<TInstance>(refs: React.Ref<TInstance>[]) {
  return (instance: TInstance | null) => refs.forEach((ref) => setRef(ref, instance));
}

export const Slot = React.forwardRef<HTMLElement, any>((props, forwardedRef) => {
  const { children, ...slotProps } = props;

  if (!React.isValidElement(children)) return null;

  return React.cloneElement(children, {
    ...mergeReactProps(slotProps, children.props as HTMLElement),
    ref: combinedRef([forwardedRef, (children as any).ref]),
  } as any);
});
