import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

export enum EarnestMoneyEnumType {
  CONTRACT = 1,
  VERIFIED = 2,
}

export enum ProrationsEnumType {
  ESTIMATED = 1,
  APPLIED = 2,
}

export enum LenderCreditEnumType {
  DISCLOSED = 1,
  APPLIED = 2,
}
export enum InterestRateEnumType {
  CONTRACT = 1,
  APPLIED = 2,
}

export const blockedInputs = [
  'LenderCreditsItemized',
  'SellerCreditsApplied',
  'TotalVerifiedEarnestMoneyAmount',
  'TotalEarnestMoneyAmount',
  'TotalAppliedProrationAmount',
  'TotalUnverifiedEarnestMoneyAmount',
  'TotalProrationAmount',
  'TotalAppliedSellerCreditAmount',
  'TotalAppliedLenderCreditAmount',
  'TotalAppliedItemizedSellerCreditAmount',
  'TotalAppliedItemizedLenderCreditAmount',
  'CurrentTotalSellerCreditToSalesContractCreditVarianceAmount',
  'CurrentTotalLenderCreditToDisclosedLenderCreditVarianceAmount',
];

export const toggleInputs = [
  'EarnestMoneyAmountUsedForCalculationsType',
  'ProrationAmountUsedForCalculationsType',
  'LenderCreditAmountUsedForCalculationsType',
  'SellerCreditAmountUsedForCalculationsType',
];

export const prorationsOptions = [
  getPanelElementOption({
    headerText: 'Estimated',
    id: String(ProrationsEnumType.ESTIMATED),
  }),
  getPanelElementOption({
    headerText: 'Applied',
    id: String(ProrationsEnumType.APPLIED),
  }),
];

export const earnestMoneyOptions = [
  getPanelElementOption({
    headerText: 'Contract',
    id: String(EarnestMoneyEnumType.CONTRACT),
  }),
  getPanelElementOption({
    headerText: 'Verified',
    id: String(EarnestMoneyEnumType.VERIFIED),
  }),
];

export const lenderCreditToggleOptions = [
  getPanelElementOption({
    headerText: 'Disclosed',
    id: String(LenderCreditEnumType.DISCLOSED),
  }),
  getPanelElementOption({
    headerText: 'Applied',
    id: String(LenderCreditEnumType.APPLIED),
  }),
];

export const interestRateToggleOptions = [
  getPanelElementOption({
    headerText: 'Contract',
    id: String(InterestRateEnumType.CONTRACT),
  }),
  getPanelElementOption({
    headerText: 'Applied',
    id: String(InterestRateEnumType.APPLIED),
  }),
];
