import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';

import { type BusinessProcessTemplateDesignSummary, BusinessProcessTypeEnum } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb';
import { useBusinessProcessTemplates } from '@/API/Queries/businessProcess/useBusinessProcessTemplates';
import { Alert } from '@/Components/Atoms/Alert';
import { Button } from '@/Components/Atoms/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { type Column, Table } from '@/Components/Features/table';
import { useTableConfig } from '@/Components/Features/table/useTableConfig';

export interface Props {
  onClose: VoidFunction;
  onClickFinish: (selectedActivities: BusinessProcessTemplateDesignSummary.AsObject[]) => void;
  open: boolean;
  isLoading: boolean;
  conditionName?: string;
}

export const AddActivity = (props: Props) => {
  const { conditionName, isLoading, onClose } = props;

  const [selected, setSelected] = useState<BusinessProcessTemplateDesignSummary.AsObject[]>([]);
  const tableConfig = useTableConfig();

  const { data, isLoading: isLoadingBusinessTemplate } = useBusinessProcessTemplates({
    filterTemplatesAllowingConcurrentInstances: true,
    filterTemplatesAllowingMultipleInstances: true,
    pageConfig: {
      currentPage: tableConfig.page,
      pageLength: tableConfig.pagePerPage,
      sortAscending: false,
      sortField: '',
    },
    progressItemIdentifier: '',
    searchTerm: tableConfig.search,
    showArchived: false,
    templateTypesList: [BusinessProcessTypeEnum.BUSINESS_PROCESS_TYPE_ENUM_ACTIVITY],
  });

  const columns = useMemo((): Column<BusinessProcessTemplateDesignSummary.AsObject>[] => [
    {
      header: 'Activity Name',
      id: 'title',
      key: { text: 'title', type: 'text' },
    },
    {
      header: 'Internal Name',
      id: 'internalName',
      key: { text: 'internalName', type: 'text' },
    },
    {
      header: 'Skillsets',
      id: 'skillset',
      key: { text: 'skillSetsList', type: 'text' },
    },
  ], []);

  const renderSelectedCount = () => {
    let message: ReactNode = 'You must select at least one activity';
    const fashion = selected.length === 0 ? 'danger' : 'success';

    if (selected.length === 1) {
      message = (
        <span>
          <strong>{`${selected.length} activity `}</strong>
          selected
        </span>
      );
    }

    if (selected.length > 1) {
      message = (
        <span>
          <strong>{`${selected.length} activities `}</strong>
          selected
        </span>
      );
    }

    return (
      <Alert fashion={fashion}>
        {message}
      </Alert>
    );
  };

  const renderActivityTable = () => (
    <Table
      cardProps={{
        headerProps: { searchProps: { onChange: tableConfig.setSearch, value: tableConfig.search }, title: 'Activities' },
      }}
      columns={columns}
      currentPage={tableConfig.page}
      data={data?.itemsList || []}
      handleNewPage={tableConfig.setPage}
      handleNewPageSize={tableConfig.setPagePerPage}
      hasOwnPagination
      isLoading={isLoadingBusinessTemplate}
      numberOfPages={Math.ceil((data?.pageDetails?.totalCount || 0) / tableConfig.pagePerPage)}
      setRowSelection={setSelected}
      totalItems={data?.pageDetails?.totalCount}
      width="stretch"
    />
  );

  return (
    <Dialog
      onOpenChange={onClose}
      open={props.open}
    >
      <DialogLayout>
        <DialogHeader title={conditionName} />

        <DialogContent>
          <div className="flex-grow p-4 h-full">
            {renderSelectedCount()}
            {renderActivityTable()}
          </div>
        </DialogContent>

        <DialogFooter borderTop span>
          <Button
            disabled={false}
            label="cancel"
            onClick={onClose}
            variant="tertiary"
          />
          <Button
            disabled={selected.length === 0 || isLoading}
            isLoading={isLoading}
            label="add activities"
            onClick={() => props.onClickFinish(selected)}
            variant="primary"
          />
        </DialogFooter>
      </DialogLayout>
    </Dialog>
  );
};
