import { useEffect, useRef } from 'react';

export const useUnmount = (cb: (updatedDeps: Array<any>) => void, deps: Array<any>) => {
  const depsRef = useRef(deps);

  useEffect(() => {
    depsRef.current = deps;
  }, [deps]);

  useEffect(() => () => {
    cb(depsRef.current);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
