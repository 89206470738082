// source: Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb = require('../Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb.js');
goog.object.extend(proto, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_PaginationModels_pb = require('../Wilqo.Shared.Models/PaginationModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_PaginationModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
goog.exportSymbol('proto.wilqo.api.business_process.ExportConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.ExportConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetValidationChecklistQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.GetValidationChecklistQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetValidationChecklistQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.displayName = 'proto.wilqo.api.business_process.GetValidationChecklistQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.GetValidationChecklistQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.displayName = 'proto.wilqo.api.business_process.GetValidationChecklistQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.ExportConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.ExportConfigurationQueryRequest.displayName = 'proto.wilqo.api.business_process.ExportConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.ExportConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.ExportConfigurationQueryResponse.displayName = 'proto.wilqo.api.business_process.ExportConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.displayName = 'proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BusinessProcessTypeEnum type = 3;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessProcessTemplateDesignSummary items = 2;
 * @return {!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplatesQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessTemplateDesign: (f = msg.getBusinessProcessTemplateDesign()) && Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.deserializeBinaryFromReader);
      msg.setBusinessProcessTemplateDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateDesign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessTemplateDesign business_process_template_design = 2;
 * @return {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.getBusinessProcessTemplateDesign = function() {
  return /** @type{?proto.wilqo.api.business_process.BusinessProcessTemplateDesign} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign, 2));
};


/**
 * @param {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.setBusinessProcessTemplateDesign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.clearBusinessProcessTemplateDesign = function() {
  return this.setBusinessProcessTemplateDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryResponse.prototype.hasBusinessProcessTemplateDesign = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetValidationChecklistQueryRequest;
  return proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    validationsList: jspb.Message.toObjectList(msg.getValidationsList(),
    Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessValidationChecklistSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetValidationChecklistQueryResponse;
  return proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessValidationChecklistSummary;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessValidationChecklistSummary.deserializeBinaryFromReader);
      msg.addValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessValidationChecklistSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessProcessValidationChecklistSummary validations = 2;
 * @return {!Array<!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary>}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.getValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessValidationChecklistSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary>} value
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.setValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary}
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.addValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.GetValidationChecklistQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetValidationChecklistQueryResponse.prototype.clearValidationsList = function() {
  return this.setValidationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessesList: jspb.Message.toObjectList(msg.getBusinessProcessesList(),
    Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.ExecutedBusinessProcess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.ExecutedBusinessProcess;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.ExecutedBusinessProcess.deserializeBinaryFromReader);
      msg.addBusinessProcesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.ExecutedBusinessProcess.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ExecutedBusinessProcess business_processes = 2;
 * @return {!Array<!proto.wilqo.api.business_process.ExecutedBusinessProcess>}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.getBusinessProcessesList = function() {
  return /** @type{!Array<!proto.wilqo.api.business_process.ExecutedBusinessProcess>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.ExecutedBusinessProcess, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.ExecutedBusinessProcess>} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.setBusinessProcessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.business_process.ExecutedBusinessProcess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess}
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.addBusinessProcesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.business_process.ExecutedBusinessProcess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessesByDealIdQueryResponse.prototype.clearBusinessProcessesList = function() {
  return this.setBusinessProcessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.ExportConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.ExportConfigurationQueryRequest;
  return proto.wilqo.api.business_process.ExportConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.ExportConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryRequest} returns this
 */
proto.wilqo.api.business_process.ExportConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.ExportConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    zipFile: msg.getZipFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.ExportConfigurationQueryResponse;
  return proto.wilqo.api.business_process.ExportConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZipFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.ExportConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getZipFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} returns this
*/
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes zip_file = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.getZipFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes zip_file = 2;
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {string}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.getZipFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZipFile()));
};


/**
 * optional bytes zip_file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.getZipFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZipFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.business_process.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.business_process.ExportConfigurationQueryResponse.prototype.setZipFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templateTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    showArchived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pageConfig: (f = msg.getPageConfig()) && Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.toObject(includeInstance, f),
    filterTemplatesAllowingConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    filterTemplatesAllowingMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    progressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.business_process.BusinessProcessTypeEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTemplateTypes(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowArchived(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig;
      reader.readMessage(value,Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.deserializeBinaryFromReader);
      msg.setPageConfig(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilterTemplatesAllowingConcurrentInstances(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilterTemplatesAllowingMultipleInstances(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplateTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getShowArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPageConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.serializeBinaryToWriter
    );
  }
  f = message.getFilterTemplatesAllowingConcurrentInstances();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFilterTemplatesAllowingMultipleInstances();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search_term = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated BusinessProcessTypeEnum template_types = 4;
 * @return {!Array<!proto.wilqo.api.business_process.BusinessProcessTypeEnum>}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getTemplateTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.business_process.BusinessProcessTypeEnum>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.BusinessProcessTypeEnum>} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setTemplateTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.addTemplateTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.clearTemplateTypesList = function() {
  return this.setTemplateTypesList([]);
};


/**
 * optional bool show_archived = 5;
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getShowArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setShowArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional wilqo.shared.models.PaginationConfig page_config = 6;
 * @return {?proto.wilqo.shared.models.PaginationConfig}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getPageConfig = function() {
  return /** @type{?proto.wilqo.shared.models.PaginationConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PaginationConfig|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setPageConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.clearPageConfig = function() {
  return this.setPageConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.hasPageConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool filter_templates_allowing_concurrent_instances = 7;
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getFilterTemplatesAllowingConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setFilterTemplatesAllowingConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool filter_templates_allowing_multiple_instances = 8;
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getFilterTemplatesAllowingMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setFilterTemplatesAllowingMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string progress_item_identifier = 9;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.getProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest.prototype.setProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.toObject, includeInstance),
    pageDetails: (f = msg.getPageDetails()) && Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails;
      reader.readMessage(value,Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.deserializeBinaryFromReader);
      msg.setPageDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.serializeBinaryToWriter
    );
  }
  f = message.getPageDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessProcessTemplateDesignSummary items = 2;
 * @return {!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary>} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional wilqo.shared.models.PaginationDetails page_details = 3;
 * @return {?proto.wilqo.shared.models.PaginationDetails}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.getPageDetails = function() {
  return /** @type{?proto.wilqo.shared.models.PaginationDetails} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PaginationDetails|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.setPageDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.clearPageDetails = function() {
  return this.setPageDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryResponse.prototype.hasPageDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateDesignId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.getTemplateDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryRequest.prototype.setTemplateDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    templateDesign: (f = msg.getTemplateDesign()) && Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.deserializeBinaryFromReader);
      msg.setTemplateDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplateDesign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessTemplateDesignSummary template_design = 2;
 * @return {?proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.getTemplateDesign = function() {
  return /** @type{?proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesignSummary, 2));
};


/**
 * @param {?proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.setTemplateDesign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.clearTemplateDesign = function() {
  return this.setTemplateDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignQueryResponse.prototype.hasTemplateDesign = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateDesignId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.getTemplateDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest.prototype.setTemplateDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse;
  return proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 2;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} returns this
*/
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse} returns this
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.business_process);
