import { useMutation } from '@tanstack/react-query';

import { UpdateBusinessProcessTemplateTriggerCommandRequest, UpdateBusinessProcessTemplateTriggerCommandResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { convertTriggerSummaryToInstance } from '../mortgageConfig/helpers';
import { useWilqoMessage } from '../useWilqoMessage';

interface Params {
  id: string;
  trigger: RuleAggregatorSummary.AsObject;
}
const MESSAGE_NAME = 'wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest';

export const useUpdateBusinessProcessTemplateTrigger = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (params: Params) => {
    const request = new UpdateBusinessProcessTemplateTriggerCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setBusinessProcessTemplateId(params.id);
    request.setTriggerRules(convertTriggerSummaryToInstance(params.trigger));
    return sendMessage<UpdateBusinessProcessTemplateTriggerCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      UpdateBusinessProcessTemplateTriggerCommandResponse,
    );
  });
};
