import { useQueries } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { IntegratedDisclosureSectionEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { GetAvailableFeeQueryRequest, GetAvailableFeeQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

interface UseGetAvailableFeesProps {
  dealId: string;
  loanId: string;
  queries: Array<{
    sectionName: IntegratedDisclosureSectionEnum;
  }>;
}

export const AVAILABLE_FEES_MESSAGE_NAME = 'wilqo.api.mortgage.GetAvailableFeeQueryRequest';

const useGetAvailableFee = (props: UseGetAvailableFeesProps) => {
  const { dealId, loanId, queries } = props;
  const { commandResponse } = useBackend();

  return useQueries({
    queries: queries.map(({ sectionName }) => ({
      queryFn: async () => {
        const request = new GetAvailableFeeQueryRequest();
        request.setDealId(dealId);
        request.setLoanId(loanId);
        request.setSectionName(sectionName);

        const responseMsg = await commandResponse({
          msg: request,
          msgName: AVAILABLE_FEES_MESSAGE_NAME,
        });

        const response = new GetAvailableFeeQueryResponse();
        GetAvailableFeeQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
        const responseObj = response.toObject();
        return endpointResponseMiddleware(AVAILABLE_FEES_MESSAGE_NAME, responseObj.error, responseObj.loanAvailableFeesInfoList);
      },
      queryKey: [AVAILABLE_FEES_MESSAGE_NAME, dealId, loanId, sectionName],
    })),
  });
};

export { useGetAvailableFee };
