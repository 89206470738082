/* eslint-disable linebreak-style */
import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateUserOrganizationsCommandRequest, UpdateUserOrganizationsCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.users.UpdateUserOrganizationsCommandRequest';

interface RequestParams {
  userId: string;
  orgIds: Array<string>;
}

const useUserOrgAssociation = () => {
  const { commandResponse } = useBackend();
  const userOrgAssociation = async (requestData: RequestParams) => {
    const { orgIds, userId } = requestData;
    const request = new UpdateUserOrganizationsCommandRequest();
    request.setUserId(userId);
    request.setOrganizationIdsList(orgIds);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new UpdateUserOrganizationsCommandResponse();
    UpdateUserOrganizationsCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.user);
  };

  return useMutation(userOrgAssociation);
};

export { useUserOrgAssociation };
