import { useQuery } from '@tanstack/react-query';

import { GetIntegrationRunRequestDataQueryRequest, GetIntegrationRunRequestDataQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetIntegrationRunRequestDataQueryRequest';

const useIntegrationRunRequest = (trackId: string) => {
  const sendMessage = useWilqoMessage();

  return useQuery([MESSAGE_NAME, trackId], async () => {
    const request = new GetIntegrationRunRequestDataQueryRequest();
    request.setTrackId(trackId);
    const response = await sendMessage<GetIntegrationRunRequestDataQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      }, GetIntegrationRunRequestDataQueryResponse,
    );

    return {
      requestObject: response.requestObject,
      requestType: response.requestType,
    };
  }, {
    enabled: Boolean(trackId),
  });
};
export { useIntegrationRunRequest };
