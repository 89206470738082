import { useMemo } from 'react';

import type { LoanPage } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Skeleton } from '@/Components/Atoms/Skeleton';

import { BannerList } from './content/Banner/BannerList';
import { Header } from './content/Header';
import type { Props as ContextProps } from './content/WidgetContext';
import { WidgetProvider } from './content/WidgetContext';
import { WidgetRenderer } from './WidgetRenderer';

interface Props extends Omit<ContextProps, 'children'> {
  isLoading?: boolean;
  className?: string;
  headerClassName?: string;
  widgetClassName?: string;
  page?: LoanPage.AsObject;
  widgets?: LoanPageWidget.AsObject[];
  handleCloseModal?: VoidFunction;
}

export const DynamicPage = (props: Props) => {
  const { className, handleCloseModal, isLoading, page, widgets, ...contextProps } = props;

  const pageWidgets = useMemo(() => page?.widgetsList || widgets || [], [page, widgets]);

  if (isLoading) {
    return (
      <div className="p-8">
        <Skeleton delay="5" height="300px" rows={2} variant="rect" />
      </div>
    );
  }

  if (page?.widgetsList.length === 0) {
    return (
      <ContingencyMessage
        action={{
          label: 'Reload',
          onClick: () => window.location.reload(),
        }}
        icon="Error"
        subtitle="Sorry, we could not load this page"
        title="Something went wrong"
      />
    );
  }

  return (
    <section className="flex-1">
      {page?.bannerList && <BannerList banners={page.bannerList} />}
      <WidgetProvider {...contextProps}>
        <div className="flex flex-col">
          {page?.header && (
            <div className="p-4 tablet:p-8 desktop:p-12 desktop:px-6">
              <Header
                header={page.header}
                onCloseDialogAction={handleCloseModal}
              />
            </div>
          )}
          {pageWidgets && (
            <WidgetRenderer
              allowPrompt
              className={props.widgetClassName}
              widgetList={pageWidgets}
            />
          )}
        </div>
      </WidgetProvider>
    </section>
  );
};
