import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { COMPLIANCE_PAGE_MESSAGE, useLoanCompliancePage } from '@/API/Queries/mortgage/useLoanCompliancePage';
import { useUpsertLoanComplianceTestSummary } from '@/API/Queries/mortgage/useUpsertLoanComplianceTestSummary';
import { Button } from '@/Components/Atoms/Button';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Tabs } from '@/Components/Atoms/Tab';
import { DynamicDataElementMap, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getDateFromWilqoTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { convertStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { IntegrationHistory } from '../../IntegrationHistory';
import { ComplianceModal } from './ComplianceModal';

const ComplianceDetails = () => {
  const { loanId = '' } = useParams();
  const { data: loan, isLoading: isLoadingLoan } = useLoan();
  const { isMobile } = useWindow();
  const [selectedTab, setSelectedTab] = useState('details');
  const [showModal, setShowModal] = useState(false);
  const { showSnackBar } = useShared();
  const { data: compliancePageData, isLoading: compliancePageLoading } = useLoanCompliancePage(loanId);
  const { isLoading: isSaving, mutate } = useUpsertLoanComplianceTestSummary();
  const renderIntegrationHistory = () => <IntegrationHistory hideBreadcrumbs sideSheetName="compliance" />;
  const queryClient = useQueryClient();
  const getDynamicString = (v?: any) => (v ? toString(DynamicDataElementMap(v)) : '');
  const getParamFromObject = (o: any, str: string): any => {
    const data = Object.entries(o).find(([key]) => !!key.includes(str));
    return (data || [])[1];
  };

  const handleSaveTestSummary = (loanEntityId: string) => (data: any, close: () => void) => {
    mutate({
      dealId: loan?.dealId || '',
      loanEntityId: loanEntityId || '',
      testSummary: {
        complianceReportFirstOrderedDate: getParamFromObject(data, compliancePageData?.complianceReportContentPanelList[0]?.firstOrderedDate?.id || '')?.value,
        complianceReportFirstReceivedDate: getParamFromObject(data, compliancePageData?.complianceReportContentPanelList[0]?.firstReceivedDate?.id || '')?.value,
        complianceReportLastOrderedDate: getParamFromObject(data, compliancePageData?.complianceReportContentPanelList[0]?.lastOrderedDate?.id || '')?.value,
        complianceReportLastReceivedDate: getParamFromObject(data, compliancePageData?.complianceReportContentPanelList[0]?.lastReceivedDate?.id || '')?.value,
      },
    }, {
      onError: () => {
        showSnackBar({ message: 'There was an error updating the compliance date', open: true });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([COMPLIANCE_PAGE_MESSAGE, loan?.dealId]);
        showSnackBar({ message: 'Compliance date updated', open: true });
        close();
      },
    });
  };

  if (compliancePageLoading || isLoadingLoan) {
    return <Skeleton height="70px" margin="20px 0" rows={6} variant="rect" width="100%" />;
  }
  const renderDetails = () => (
    <ContingencyMessage
      icon="NoItems"
      subtitle="No compliance found"
      title="No results, yet"
      variant="image"
    />
  );

  return (
    <div>
      <div className="flex flex-row items-center justify-between px-4 py-6 tablet:px-8 desktop:px-6">
        <span className="font-four font-bold text-4xl">Compliance Details</span>
        <Button
          label="RUN TEST"
          onClick={() => setShowModal(true)}
          variant="secondary"
        />
      </div>
      <div className="flex flex-wrap gap-6 px-4 py-6 tablet:px-8 desktop:px-6">
        {compliancePageData?.cardListList.map((infoCard) => (
          <InfoCard
            key={infoCard.id}
            caption={infoCard.caption}
            isMobile={isMobile}
            items={infoCard.verifiedListList}
            type={infoCard.type}
            value={infoCard.value}
            variant={convertStatusEnum(infoCard.infoCardType)}
          />
        ))}
      </div>

      <div className="flex flex-wrap justify-start gap-6 px-4 py-6 tablet:px-8 desktop:px-6">
        {compliancePageData?.complianceReportContentPanelList?.map((compliancePanel) => (
          <CardListItem
            key={compliancePanel?.panelId}
            headerProps={{
              title: 'Compliance Report',
            }}
            isEditable
            isSaving={isSaving}
            listItems={[{
              caption: !compliancePanel?.firstOrderedDate ? '--/--/--' : getDynamicString(compliancePanel.firstOrderedDate?.value) || '--',
              label: 'First Ordered Date',
              panelElement: compliancePanel.firstOrderedDate,
              value: { value: getDateFromWilqoTimestamp(compliancePanel?.firstOrderedDate?.value) },
            }, {
              caption: !compliancePanel?.lastOrderedDate ? '--/--/--' : getDynamicString(compliancePanel.lastOrderedDate?.value) || '--',
              label: 'Last Ordered Date',
              panelElement: compliancePanel.lastOrderedDate,
              value: { value: getDateFromWilqoTimestamp(compliancePanel?.lastOrderedDate?.value) },
            },
            {
              caption: !compliancePanel?.firstReceivedDate ? '--/--/--' : getDynamicString(compliancePanel.firstReceivedDate?.value) || '--',
              label: 'First Received Date',
              panelElement: compliancePanel.firstReceivedDate,
              value: { value: getDateFromWilqoTimestamp(compliancePanel?.firstReceivedDate?.value) },
            }, {
              caption: !compliancePanel?.lastReceivedDate ? '--/--/--' : getDynamicString(compliancePanel.lastReceivedDate?.value) || '--',
              label: 'Last Received Date',
              panelElement: compliancePanel.lastReceivedDate,
              value: { value: getDateFromWilqoTimestamp(compliancePanel?.lastReceivedDate?.value) },
            }]}
            onSubmit={handleSaveTestSummary(compliancePageData?.loanEntityId)}
            variant="border"
          />
        ))}
      </div>
      <Tabs
        stretch
        tabsData={[
          { active: selectedTab === 'details', label: 'Details', onClick: () => setSelectedTab('details') },
          { active: selectedTab === 'integration', label: 'Integrations', onClick: () => setSelectedTab('integration') },
        ]}
      />
      {selectedTab === 'details' ? renderDetails() : renderIntegrationHistory()}
      <ComplianceModal onClose={() => setShowModal(false)} openModal={showModal} />
    </div>
  );
};

export { ComplianceDetails };
