import { Icon } from './Icon';

interface ISectionDividerProps {
  title: string;
  icon: string;
}

const SectionDivider = (props: ISectionDividerProps) => {
  const { icon, title } = props;

  return (
    <div
      className="flex items-center border-b-2 border-solid border-on-surface-stroke h-12 mb-6 mt-12 px-2"
    >
      <Icon className="cursor-default" icon={icon} />
      <span
        className="text-on-surface-active font-semibold tracking-[1.5px] ml-2 uppercase"
      >
        {title}
      </span>
    </div>
  );
};

export { SectionDivider };
