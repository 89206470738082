import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { EditPanelDetailsCommandRequest, PanelSequenceDesignCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.EditPanelDetailsCommandRequest';

interface UpdateObject {
  panelId: string;
  headerText: string;
  summaryText: string;
  modalText: string;
}

const useEditDesignerPanel = () => {
  const { commandResponse } = useBackend();
  const {
    designerPanel: { entityId, panelSequenceDesignId },
  } = useActivityConfiguratorContext();
  const bpdId = useBPDId();

  const EditDesignerPanelCommand = async (updateObject: UpdateObject) => {
    const { headerText, modalText, panelId, summaryText } = updateObject;
    const request = new EditPanelDetailsCommandRequest();
    request.setPanelSequenceDesignId(panelSequenceDesignId);
    request.setPanelId(panelId);
    request.setHeaderText(headerText);
    request.setSummaryText(summaryText);
    request.setShowNextModalConfirmationText(modalText);
    request.setActivityDesignId(entityId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PanelSequenceDesignCommandResponse();
    PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(EditDesignerPanelCommand);
};

export { useEditDesignerPanel };
