import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';
import { UpdateFeesComparisonCommandRequest, UpdateFeesComparisonCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { FeesComparisonSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toFeesComparisonSectionFromObject } from '../helpers/feesComparisonHelper';
import { toProtoDecimalFromObject } from '../helpers/helper';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface UseUpdateFeesComparisonDTO {
  dealId: string;
  feesComparisonSection?: FeesComparisonSection.AsObject;
  totalLenderCure?: ProtoDecimal.AsObject;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.UpdateFeesComparisonCommandRequest';

const useUpdateFeesComparison = () => {
  const { commandResponse } = useBackend();

  const updateFeesComparison = async (updateObject: UseUpdateFeesComparisonDTO) => {
    const request = new UpdateFeesComparisonCommandRequest();
    request.setDealId(updateObject.dealId);
    if (updateObject.feesComparisonSection) request.setFeesComparisonSection(toFeesComparisonSectionFromObject(updateObject.feesComparisonSection));
    if (updateObject.totalLenderCure) request.setTotalLenderCure(toProtoDecimalFromObject(updateObject.totalLenderCure));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateFeesComparisonCommandResponse();
    UpdateFeesComparisonCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(updateFeesComparison);
};

export { useUpdateFeesComparison };
