import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useGenerateLoanOfficerQuestionnaireUrl } from '@/API/Queries/mortgage/useGenerateLoanOfficerQuestionnaireUrl';
import { useUpdateUser } from '@/API/Queries/user/useUpdateUser';
import { useUser } from '@/API/Queries/user/useUser';
import { Avatar } from '@/Components/Atoms/Avatar';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { formatPhone } from '@/Utils/Helpers/normalizeString';

const emailPanelElement = getPanelElement({
  headerText: 'Email',
  id: 'email',
  type: 'text',
  validatorsList: [
    {
      errorMessage:
        'You must enter a valid email address to proceed (##@##.##)',
      type: 'regex',
      value:
        "^[a-zA-Z0-9\\._%+!$&*=^|~#%'`?{}\\-]+@([a-zA-Z0-9\\-]+\\.){1,}([a-z]{2,16})$",
    },
  ],
});
const firstNamePanelElement = getPanelElement({
  headerText: 'First Name',
  id: 'firstName',
  requirement: { errorMessage: 'First Name is required', required: true },
  type: 'text',
  validatorsList: [
    {
      errorMessage:
        'First name may include only letters, apostrophes, and spaces.',
      type: 'regex',
      value: "^[a-zA-Z_ ']*$",
    },
    {
      errorMessage: 'First name should not exceed 20 characters',
      type: 'max-length',
      value: '20',
    },
  ],
});
const lastNamePanelElement = getPanelElement({
  headerText: 'Last Name',
  id: 'lastName',
  requirement: { errorMessage: 'Last Name is required', required: true },
  type: 'text',
  validatorsList: [
    {
      errorMessage:
        'Last name may incldue only letters, apostrophes, and spaces.',
      type: 'regex',
      value: "^[a-zA-Z_ ']*$",
    },
    {
      errorMessage: 'Last name should not exceed 30 characters',
      type: 'max-length',
      value: '30',
    },
  ],
});
const phoneNumberPanelElement = getPanelElement({
  headerText: 'Phone Number',
  id: 'phoneNumber',
  mask: {
    allowEmptyFormatting: false,
    customFormat: '',
    customMask: '',
    type: 'phone',
  },
  summaryText:
    "By providing your phone number, you're consenting to receive text messages from Wilqo. You can unsubscribe at any time by texting STOP",
  type: 'text',
  validatorsList: [
    {
      errorMessage: 'You must enter a valid phone number to proceed (###-###-####)',
      type: 'regex',
      value: '^\\d{10}$',
    },
  ],

});

const profileImageUrlPanelElement = getPanelElement({
  headerText: 'Profile Image Url',
  id: 'profileImageUrl',
  type: 'text',
});

const urlPanelElement = getPanelElement({
  headerText: 'Url',
  id: 'url',
  type: 'text',
});

const UserOverview = () => {
  const { id = '' } = useParams();
  const { data: user, isLoading, refetch } = useUser(id);
  const { isLoading: isSaving, mutate: updateUser } = useUpdateUser();
  const { data: linkData, mutate: generateLink } = useGenerateLoanOfficerQuestionnaireUrl();

  useEffect(() => {
    if (user?.id) {
      generateLink(user?.id);
    }
  }, [generateLink, user?.id]);

  const handleSubmitUpdate = (
    formData: DynamicDataElementValues,
    close: () => void,
  ) => {
    const { email, firstName, lastName, phoneNumber, profileImageUrl } = formData;
    updateUser(
      {
        email: email?.value || user?.email,
        firstName: firstName?.value || user?.firstName,
        lastName: lastName?.value || user?.lastName,
        nationalLicensingId: '',
        phoneNumber: phoneNumber?.value || user?.phoneNumber,
        profileImageUrl: profileImageUrl?.value || user?.profileImageUrl,
        skillsetGroupIds:
          user?.skillsetGroupList.map((skillsetGroup) => skillsetGroup.id)
          || [],
        userId: id,
      },
      {
        onSuccess: () => {
          refetch();
          close();
        },
      },
    );
  };

  const handleGenerateCustomApplyLink = () => {
    generateLink(user?.id || '');
  };

  return (
    <div className="flex flex-col gap-6 lg:flex-row">
      <div className="flex justify-center">
        <Avatar size="2xl" text={`${user?.firstName} ${user?.lastName}`} />
      </div>
      <div className="flex flex-col gap-y-2 w-full">
        <CardListItem
          headerProps={{ title: 'User details' }}
          isEditable
          isLoading={isLoading}
          isSaving={isSaving}
          listItems={[
            {
              caption: user?.firstName,
              label: 'First name',
              leadingIconProps: {
                icon: 'Person',
              },
              panelElement: firstNamePanelElement,
              value: { value: user?.firstName },
            },
            {
              caption: user?.lastName,
              label: 'Last name',
              panelElement: lastNamePanelElement,
              value: { value: user?.lastName },
            },
          ]}
          onSubmit={handleSubmitUpdate}
          variant="border"
        />
        <CardListItem
          headerProps={{ title: 'Contact Info' }}
          isEditable
          isLoading={isLoading}
          isSaving={isSaving}
          listItems={[
            {
              caption: user?.email,
              label: 'Email',
              leadingIconProps: {
                icon: 'Email',
              },
              panelElement: emailPanelElement,
              value: { value: user?.email },
            },
            {
              caption: formatPhone(user?.phoneNumber),
              label: 'Phone number',
              leadingIconProps: {
                icon: 'Phone',
              },
              panelElement: phoneNumberPanelElement,
              value: { value: user?.phoneNumber },
            },
            {
              caption: user?.profileImageUrl,
              label: 'Profile Image Url',
              leadingIconProps: {
                icon: 'Link',
              },
              panelElement: profileImageUrlPanelElement,
              value: { value: user?.profileImageUrl },
            },
          ]}
          onSubmit={handleSubmitUpdate}
          variant="border"
        />
        <CardListItem
          headerProps={{
            actions: [
              { label: 'Create', onClick: handleGenerateCustomApplyLink },
            ],
            title: 'Custom Apply Link',
          }}
          listItems={[
            {
              caption: linkData?.loanOfficerQuestionnaireUrl,
              label: 'Url',
              leadingIconProps: {
                icon: 'Link',
              },
              panelElement: urlPanelElement,
              value: { value: linkData?.loanOfficerQuestionnaireUrl },
            },
          ]}
          variant="border"
        />
      </div>
    </div>
  );
};

export { UserOverview };
