import clsx from 'clsx';
import { useEffect } from 'react';

import { usePageConfiguration } from '@/API/Queries/brand/usePageConfiguration';
import { AnimatedContainer } from '@/Components/Atoms/AnimatedContainer';
import { Footer } from '@/Components/Atoms/Footer';
import { NavDrawer } from '@/Components/Atoms/NavDrawer';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export interface IWithColumnProps {
  header: {
    appBar: React.ReactNode;
    renderDrawer?: boolean;
  };
  renderFooter?: boolean;
  content: React.ReactNode;
  sideContent?: React.ReactNode;
  layoutsList: Array<number>;
  pageTitle?: string;
  pageSubTitle?: string;
  justifyContent?: 'center' | 'end' | 'start';
  className?: string;
}

const LayoutWithColumns = (props: IWithColumnProps) => {
  const {
    className,
    content,
    header: { appBar, renderDrawer = false },
    layoutsList,
    pageSubTitle,
    pageTitle,
    renderFooter = false,
    sideContent,
  } = props;
  const multipleColumns = layoutsList.length >= 2;

  const {
    data: pageConfiguration,
    isLoading: isLoadingPageConfiguration,
  } = usePageConfiguration();
  const { drawer: isDrawerOpen, toggleDrawer } = useShared();

  useEffect(() => {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';

    return () => {
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, []);

  const renderContent = () => (
    <div className={clsx('px-4 py-8 desktop:p-12', className)}>
      {
        (pageTitle || pageSubTitle)
        && (
          <div>
            {pageTitle && (
              <div className={clsx('font-four text-3xl leading-9 font-semibold text-on-surface-solid', {
                'mb-4': pageSubTitle !== '',
              })}
              >
                {pageTitle}
              </div>
            )}
            {pageSubTitle && <span className="text-on-surface-inactive">{pageSubTitle}</span>}
          </div>
        )
      }
      {content}
    </div>
  );

  return (
    <AnimatedContainer>
      <div className="absolute h-full w-screen top-0 left-0">
        <div className="flex flex-col h-full">
          {appBar}
          <div className={clsx('flex overflow-y-auto h-[calc(100vh-72px)]', {
            'flex-col': !renderDrawer,
          })}
          >
            {renderDrawer && (
              <NavDrawer
                isLoading={isLoadingPageConfiguration}
                items={pageConfiguration?.navigationList || []}
                open={isDrawerOpen}
                toggleDrawer={toggleDrawer}
              />
            )}
            <div className="w-full">
              <div>
                {
                  multipleColumns ? (
                    <div className="grid grid-flow-col gap-4">
                      {renderContent()}
                      <div className="col-span-2">{sideContent}</div>
                    </div>
                  ) : renderContent()
                }
              </div>
            </div>
          </div>
          {renderFooter && <Footer />}
        </div>
      </div>
    </AnimatedContainer>
  );
};

export { LayoutWithColumns };
