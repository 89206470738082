import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useParams } from 'react-router';

import { GetFeesAdjustmentsCreditsQueryRequest, GetFeesAdjustmentsCreditsQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest';

const useGetFeesAdjustmentsCredits = () => {
  const { commandResponse } = useBackend();
  const { loanId = '' } = useParams();

  return useQuery([MESSAGE_NAME, loanId], async () => {
    const request = new GetFeesAdjustmentsCreditsQueryRequest();
    request.setDealId(loanId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetFeesAdjustmentsCreditsQueryResponse();
    GetFeesAdjustmentsCreditsQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();

    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      {
        ...responseObj,
      },
    );
  });
};

export { useGetFeesAdjustmentsCredits };
