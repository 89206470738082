import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import Component from './DateMonthYear.component';

export interface DateMonthYearProps {
  panelElement: PanelElement.AsObject;
  min?: number;
  max?: number;
}

const DateMonthYear = (props: DateMonthYearProps) => {
  const {
    max,
    min,
    panelElement: {
      disabled,
      id,
      path,
      requirement,
    },
  } = props;

  const [value, setValue, { errorMessage, hidden, ref }] = useDynamicFormRegister(
    id,
    {
      fromDynamic: (v) => toString(v),
      toDynamic: (v) => fromString(v),
    },
    { requirement },
  );

  const handleOnChange = (value: string) => {
    setValue(value, value, path);
  };

  if (hidden) return null;

  return (
    <Component
      ref={ref}
      disabled={disabled}
      errorMessage={errorMessage}
      fullWidth
      max={max}
      min={min}
      onChange={handleOnChange}
      value={value}
    />
  );
};

export default DateMonthYear;
