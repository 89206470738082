import { AddressAutoComplete } from '@/Components/Atoms/AddressAutoComplete';
import { AutoComplete } from '@/Components/Atoms/AutoComplete';
import { Button } from '@/Components/Atoms/Button';
import { Checkbox } from '@/Components/Atoms/Checkbox';
import { CheckboxSelect } from '@/Components/Atoms/CheckboxSelect';
import { ChipGroupDDE } from '@/Components/Atoms/ChipGroup/ChipGroup.dde';
import { DateMonthYear } from '@/Components/Atoms/DateMonthYear';
import { Divider } from '@/Components/Atoms/Divider';
import { DocumentView } from '@/Components/Atoms/DocumentView';
import { FileUploader } from '@/Components/Atoms/FileUploader';
import { Icon } from '@/Components/Atoms/Icon';
import { Input } from '@/Components/Atoms/Input';
import { convertPanelElementMask, TextInput } from '@/Components/Atoms/Input/Input.dde';
import { MultiSelect } from '@/Components/Atoms/MultiSelect';
import { RadioGroup } from '@/Components/Atoms/RadioGroup';
import { SectionDivider } from '@/Components/Atoms/SectionDivider';
import type { SelectionSurfaceVariant } from '@/Components/Atoms/SelectionSurface';
import { StateSelect } from '@/Components/Atoms/StateSelect/StateSelect';
import { Switch } from '@/Components/Atoms/Switch';
import { TextGenerate } from '@/Components/Atoms/TextGenerate';
import { TextModal } from '@/Components/Atoms/TextModal';
import { Tile } from '@/Components/Atoms/Tile';
import { Typography } from '@/Components/Atoms/Typography';
import type { PanelElementExtended } from '@/Utils/Helpers/getPanelElement';

import { HiddenElement } from './HiddenElement';

interface PanelElementRendererProps extends PanelElementExtended {
  displayOnly?: boolean;
  showAsQuestion?: boolean;
  onChange?: (value?: any) => void;
  onDropdownApply?: (closeDropdown: VoidFunction) => void;
  value?: any;
  hidden?: boolean;
  trigger?: 'chip' | 'text-field';
}

const PanelElementRenderer = (props: PanelElementRendererProps) => {
  const { displayOnly = false, showAsQuestion = false, onChange = () => { }, value, variant, orientation, hidden = false, onDropdownApply } = props;

  const renderDisplayOnly = () => {
    switch (props.type) {
      case 'auto-complete-postal-code':
      case 'auto-complete-address':
      case 'auto-complete-address-international':
        return (
          <AddressAutoComplete
            displayOnly
            onSelect={onChange}
            placeholder={props.headerText}
            value={value}
          />
        );
      case 'generate':
      case 'text':
      case 'number':
      case 'date':
      case 'text-area':
        return (
          <Input
            displayOnly
            label={props.headerText}
            mask={convertPanelElementMask(props.mask)}
            onChange={props.onChange}
            value={value}
            variant={props.type === 'text-area' ? 'text-area' : props.variant}
          />
        );
      case 'auto-complete':
      case 'select':
        return (
          <AutoComplete
            displayOnly
            noAutoFocus={props.order === 1}
            onSelect={onChange}
            options={props.optionsList}
            placeholder={props.headerText}
            value={value}
            variant={props.variant}
          />
        );
      case 'state-select':
        return (
          <StateSelect panelElement={props} />
        );
      case 'checkbox':
        return (
          <Checkbox
            displayOnly
            label={props.enrichedHeaderText || props.headerText}
            onClick={onChange}
            value={value}
          />
        );
      case 'file-uploader':
        return (
          <FileUploader
            displayOnly
            onSelect={onChange}
          />
        );
      case 'multi-select':
        return (
          <MultiSelect
            {...props}
            displayOnly
            onApply={onDropdownApply}
            onClear={() => onChange(undefined)}
            onSelect={onChange}
            onSelectAll={() => onChange(props.optionsList)}
            selected={value}
          />
        );
      case 'radioGroup':
      case 'radio':
        return (
          <RadioGroup
            displayOnly
            label={props.headerText}
            onSelect={onChange}
            optionLabelKey="headerText"
            options={props.optionsList}
            orientation={orientation}
            value={value}
            valueLabelKey="id"
            variant={variant as SelectionSurfaceVariant}
          />
        );
      case 'switch':
        return (
          <Switch
            displayOnly
            onSelect={onChange}
            optionsLabels={[{
              disabled: false,
              name: props.headerText,
            },
            {
              disabled: false,
              name: props.summaryText,
            }]}
            selectedItem={value}
          />
        );
      case 'button':
      case 'buttonGroup':
        return (
          <Tile
            disabled={props.disabled}
            displayOnly={false}
            headerText={props.headerText}
            id={props.id}
            options={props.optionsList}
            path={props.path}
            thumbnail={props.thumbnail}
          />
        );
      case 'button-link':
        return (
          <Button
            externalLink={Boolean(props.externalLink?.value)}
            label={props.headerText}
            linkTo={props.externalLink?.value || props.path}
            variant="primary"
          />
        );
      case 'modal':
        return (
          <TextModal
            text={props.summaryText}
            title={props.headerText}
          />
        );
      case 'document-view':
        return (
          <DocumentView
            path={props.externalLink?.value}
            title={props.headerText}
          />
        );
      case 'divider':
        return (
          <Divider />
        );
      case 'section-divider':
        return (
          <SectionDivider
            icon={props.summaryText}
            title={props.headerText}
          />
        );
      case 'message-title':
        return (
          <>
            <Typography variant="display6">
              {props.headerText}
            </Typography>
            <span className="text-onSurfaceActive">{props.summaryText}</span>
          </>
        );
      case 'message-icon':
        return <Icon icon={props.headerText} size="lg" variant="fixed-background" />;
      case 'link':
        return (
          <a
            className="text-hyperlinkUnclicked"
            href={props.externalLink?.value || ''}
            rel="noreferrer"
            target="_blank"
          >
            {props.headerText}
          </a>
        );
      case 'month-year':
        return (
          <DateMonthYear
            displayOnly
            onChange={props.onChange}
            value={value}
          />
        );
      default:
        return null;
    }
  };

  const renderDDEElement = () => {
    if (hidden) return <HiddenElement {...props} />;
    switch (props.type) {
      case 'auto-complete-postal-code':
      case 'auto-complete-address':
      case 'auto-complete-address-international':
        return <AddressAutoComplete displayOnly={false} panelElement={props} />;
      case 'text':
      case 'date':
      case 'number':
      case 'text-area':
        return (
          <TextInput
            panelElement={props}
            showAsQuestion={showAsQuestion}
            variant={variant}
          />
        );
      case 'generate':
        return <TextGenerate panelElement={props} />;
      case 'auto-complete':
      case 'select':
        return (
          <AutoComplete
            displayOnly={false}
            panelElement={props}
            showAsQuestion={showAsQuestion}
            variant={variant}
          />
        );
      case 'state-select':
        return (
          <StateSelect panelElement={props} />
        );
      case 'checkbox':
        return (
          <Checkbox displayOnly={false} panelElement={props} variant={props.variant} />
        );
      case 'file-uploader':
        return <FileUploader displayOnly={false} panelElement={props} />;
      case 'multi-select':
        return (
          <MultiSelect displayOnly={false} panelElement={props} trigger={props.trigger} variant={variant} />
        );
      case 'radioGroup':
      case 'radio':
        return (
          <RadioGroup displayOnly={false} panelElement={props} />
        );
      case 'switch':
        return <Switch displayOnly={false} panelElement={props} />;
      case 'button':
      case 'buttonGroup':
        return (
          <Tile
            disabled={props.disabled}
            displayOnly={false}
            headerText={props.headerText}
            id={props.id}
            options={props.optionsList}
            path={props.path}
            thumbnail={props.thumbnail}
          />
        );
      case 'button-link':
        return (
          <Button
            externalLink={Boolean(props.externalLink?.value)}
            label={props.headerText}
            linkTo={props.externalLink?.value || props.path}
            variant="primary"
          />
        );
      case 'modal':
        return (
          <TextModal
            text={props.summaryText}
            title={props.headerText}
          />
        );
      case 'document-view':
        return (
          <DocumentView
            path={props.externalLink?.value}
            title={props.headerText}
          />
        );
      case 'divider':
        return (
          <Divider />
        );
      case 'chip-list':
        return <ChipGroupDDE panelElement={props} />;
      case 'section-divider':
        return (
          <SectionDivider
            icon={props.summaryText}
            title={props.headerText}
          />
        );
      case 'message-title':
        return (
          <>
            <Typography variant="display6">
              {props.headerText}
            </Typography>
            <span className="text-onSurfaceActive">{props.summaryText}</span>
          </>
        );
      case 'message-icon':
        return <Icon icon={props.headerText} size="lg" variant="fixed-background" />;
      case 'link':
        return (
          <a
            className="text-hyperlinkUnclicked"
            href={props.externalLink?.value || ''}
            rel="noreferrer"
            target="_blank"
          >
            {props.headerText}
          </a>
        );
      case 'month-year':
        return (
          <DateMonthYear
            displayOnly={false}
            panelElement={props}
          />
        );
      case 'checkbox-select':
        return (
          <CheckboxSelect
            displayOnly={false}
            {...props as PanelElementExtended}

          />
        );
      default:
        return null;
    }
  };

  const renderPanelElement = () => {
    if (displayOnly) return renderDisplayOnly();
    return renderDDEElement();
  };

  return renderPanelElement();
};

export { PanelElementRenderer };
