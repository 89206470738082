const separateString = (source: string, cuttingPoint: number, separator: string): string[] => {
  const sourceLength: number = source.length;
  const separatorLength: number = separator.length;
  const firstPart: string = source.substring(0, cuttingPoint);
  const secondPart: string = source.substring(cuttingPoint + separatorLength, sourceLength);

  return [firstPart, secondPart];
};

const halves = (source: string, separator: string): string[] => {
  const cuttingPoint: number = source.indexOf(separator);
  const separationFunction: (source: string, cuttingPoint: number, separator: string) => string[] = separateString;

  if (cuttingPoint < 0) { return [source]; }

  const [firstPart, secondPart] = separationFunction(source, cuttingPoint, separator);

  let halves: string[] = [];

  if (typeof firstPart === 'string' && firstPart.length > 0) { halves = [firstPart]; }
  if (Buffer.isBuffer(firstPart) && firstPart.length > 0) { halves = [firstPart]; }
  halves = [...halves, separator];
  if (typeof secondPart === 'string' && secondPart.length > 0) { halves = [...halves, secondPart]; }
  if (Buffer.isBuffer(secondPart) && secondPart.length > 0) { halves = [...halves, secondPart]; }

  return halves;
};

const tokenize = (source: string, match: string): string[] => {
  const tokens: string[] = halves(source, match);

  if (tokens.length <= 1) { return tokens; }
  if (tokens.length <= 2) { return [tokens[0], ...tokenize(tokens[1], match)]; }

  return [tokens[0], tokens[1], ...tokenize(tokens[2], match)];
};

const singleTokenization = (tokens: string[], match: string): string[] => tokens.flatMap((token) => tokenize(token, match));

const multiTokenization = (tokens: string[], matches: string[]): string[] => {
  if (matches.length <= 0) { return tokens; }

  const newTokens: string[] = singleTokenization(tokens, matches[0]);

  return multiTokenization(newTokens, matches.slice(1));
};

export default (source: string, ...matches: string[]): string[] => multiTokenization([source], matches);
