// source: Wilqo_API_Mortgage_DynamicData_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('./Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage.DealPageElement', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealReadData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DynamicDataItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DynamicDataItemField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DynamicDataWrite', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldGroupSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldLibraryDefinition', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldLibraryParameter', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldLibraryScope', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldLibraryUpdate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldLibraryUpdateValue', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FieldTemplateSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FlexlistSubsectionData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ScopeData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.URLAProgressItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.VirtualFieldData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DynamicDataWrite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DynamicDataWrite.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DynamicDataWrite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DynamicDataWrite.displayName = 'proto.wilqo.api.mortgage.DynamicDataWrite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealPageElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealPageElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealPageElement.displayName = 'proto.wilqo.api.mortgage.DealPageElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldLibraryScope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldLibraryScope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldLibraryScope.displayName = 'proto.wilqo.api.mortgage.FieldLibraryScope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FieldLibraryUpdate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldLibraryUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldLibraryUpdate.displayName = 'proto.wilqo.api.mortgage.FieldLibraryUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldLibraryUpdateValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldLibraryUpdateValue.displayName = 'proto.wilqo.api.mortgage.FieldLibraryUpdateValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FieldLibraryDefinition.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldLibraryDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldLibraryDefinition.displayName = 'proto.wilqo.api.mortgage.FieldLibraryDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldLibraryParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldLibraryParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldLibraryParameter.displayName = 'proto.wilqo.api.mortgage.FieldLibraryParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldGroupSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FieldGroupSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldGroupSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldGroupSummary.displayName = 'proto.wilqo.api.mortgage.FieldGroupSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FieldTemplateSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FieldTemplateSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FieldTemplateSummary.displayName = 'proto.wilqo.api.mortgage.FieldTemplateSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealReadData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealReadData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealReadData.displayName = 'proto.wilqo.api.mortgage.DealReadData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FlexlistSubsectionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FlexlistSubsectionData.displayName = 'proto.wilqo.api.mortgage.FlexlistSubsectionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.URLAProgressItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.URLAProgressItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.URLAProgressItem.displayName = 'proto.wilqo.api.mortgage.URLAProgressItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.VirtualFieldData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.VirtualFieldData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.VirtualFieldData.displayName = 'proto.wilqo.api.mortgage.VirtualFieldData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ScopeData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ScopeData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ScopeData.displayName = 'proto.wilqo.api.mortgage.ScopeData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DynamicDataItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DynamicDataItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DynamicDataItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DynamicDataItem.displayName = 'proto.wilqo.api.mortgage.DynamicDataItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DynamicDataItemField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DynamicDataItemField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DynamicDataItemField.displayName = 'proto.wilqo.api.mortgage.DynamicDataItemField';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DynamicDataWrite.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DynamicDataWrite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DynamicDataWrite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataWrite.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    controlType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeSelectorsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isNewItem: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fieldId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DynamicDataWrite;
  return proto.wilqo.api.mortgage.DynamicDataWrite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DynamicDataWrite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopeSelectors(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNewItem(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DynamicDataWrite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DynamicDataWrite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataWrite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getControlType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeSelectorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getIsNewItem();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 1;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
*/
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string control_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getControlType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setControlType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string scope_selectors = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getScopeSelectorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setScopeSelectorsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.addScopeSelectors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.clearScopeSelectorsList = function() {
  return this.setScopeSelectorsList([]);
};


/**
 * optional bool is_new_item = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getIsNewItem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setIsNewItem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage.DynamicDataWrite.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealPageElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealPageElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealPageElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldTemplate: (f = msg.getFieldTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f),
    fieldValue: (f = msg.getFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealPageElement}
 */
proto.wilqo.api.mortgage.DealPageElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealPageElement;
  return proto.wilqo.api.mortgage.DealPageElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealPageElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealPageElement}
 */
proto.wilqo.api.mortgage.DealPageElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setFieldTemplate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealPageElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealPageElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealPageElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFieldValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_library_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealPageElement} returns this
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplate field_template = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.getFieldTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealPageElement} returns this
*/
proto.wilqo.api.mortgage.DealPageElement.prototype.setFieldTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealPageElement} returns this
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.clearFieldTemplate = function() {
  return this.setFieldTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.hasFieldTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement field_value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.getFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealPageElement} returns this
*/
proto.wilqo.api.mortgage.DealPageElement.prototype.setFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealPageElement} returns this
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.clearFieldValue = function() {
  return this.setFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealPageElement.prototype.hasFieldValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldLibraryScope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldLibraryScope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryScope.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNew: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scope: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryScope}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldLibraryScope;
  return proto.wilqo.api.mortgage.FieldLibraryScope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryScope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryScope}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNew(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldLibraryScope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryScope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryScope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNew();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_new = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.getIsNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryScope} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.setIsNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryScope} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryScope.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldLibraryUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeList: jspb.Message.toObjectList(msg.getScopeList(),
    proto.wilqo.api.mortgage.FieldLibraryScope.toObject, includeInstance),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.wilqo.api.mortgage.FieldLibraryUpdateValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldLibraryUpdate;
  return proto.wilqo.api.mortgage.FieldLibraryUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.FieldLibraryScope;
      reader.readMessage(value,proto.wilqo.api.mortgage.FieldLibraryScope.deserializeBinaryFromReader);
      msg.addScope(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.FieldLibraryUpdateValue;
      reader.readMessage(value,proto.wilqo.api.mortgage.FieldLibraryUpdateValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldLibraryUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.FieldLibraryScope.serializeBinaryToWriter
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.FieldLibraryUpdateValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldLibraryScope scope = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.FieldLibraryScope>}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.getScopeList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FieldLibraryScope>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FieldLibraryScope, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FieldLibraryScope>} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate} returns this
*/
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.setScopeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FieldLibraryScope=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FieldLibraryScope}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.addScope = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.FieldLibraryScope, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.clearScopeList = function() {
  return this.setScopeList([]);
};


/**
 * repeated FieldLibraryUpdateValue values = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.FieldLibraryUpdateValue>}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FieldLibraryUpdateValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FieldLibraryUpdateValue, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FieldLibraryUpdateValue>} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate} returns this
*/
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.FieldLibraryUpdateValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdate} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryUpdate.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldLibraryUpdateValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldLibraryUpdateValue;
  return proto.wilqo.api.mortgage.FieldLibraryUpdateValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldLibraryUpdateValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_library_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} returns this
*/
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryUpdateValue} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FieldLibraryUpdateValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldLibraryDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldLibraryDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    path: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.wilqo.api.mortgage.FieldLibraryParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldLibraryDefinition;
  return proto.wilqo.api.mortgage.FieldLibraryDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.FieldLibraryParameter;
      reader.readMessage(value,proto.wilqo.api.mortgage.FieldLibraryParameter.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldLibraryDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.FieldLibraryParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FieldLibraryParameter parameters = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.FieldLibraryParameter>}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FieldLibraryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FieldLibraryParameter, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FieldLibraryParameter>} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition} returns this
*/
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FieldLibraryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.FieldLibraryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryDefinition.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldLibraryParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldLibraryParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    length: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldLibraryParameter;
  return proto.wilqo.api.mortgage.FieldLibraryParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldLibraryParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldLibraryParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_position = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.getStartPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.setStartPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 length = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage.FieldLibraryParameter.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FieldGroupSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldGroupSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldGroupSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldGroupSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage.FieldTemplateSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldGroupSummary;
  return proto.wilqo.api.mortgage.FieldGroupSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldGroupSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.FieldTemplateSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.FieldTemplateSummary.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldGroupSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldGroupSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldGroupSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.FieldTemplateSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FieldTemplateSummary fields = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.FieldTemplateSummary>}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FieldTemplateSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FieldTemplateSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FieldTemplateSummary>} value
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary} returns this
*/
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FieldTemplateSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.FieldTemplateSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage.FieldGroupSummary.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FieldTemplateSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FieldTemplateSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scopeId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FieldTemplateSummary;
  return proto.wilqo.api.mortgage.FieldTemplateSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FieldTemplateSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FieldTemplateSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FieldTemplateSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getScopeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_element_type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.getPanelElementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.setPanelElementType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 4;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string scope_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.getScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage.FieldTemplateSummary.prototype.setScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealReadData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealReadData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealReadData.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    template: (f = msg.getTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealReadData}
 */
proto.wilqo.api.mortgage.DealReadData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealReadData;
  return proto.wilqo.api.mortgage.DealReadData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealReadData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealReadData}
 */
proto.wilqo.api.mortgage.DealReadData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealReadData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealReadData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealReadData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealReadData} returns this
 */
proto.wilqo.api.mortgage.DealReadData.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplate template = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealReadData} returns this
*/
proto.wilqo.api.mortgage.DealReadData.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealReadData} returns this
 */
proto.wilqo.api.mortgage.DealReadData.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealReadData} returns this
*/
proto.wilqo.api.mortgage.DealReadData.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealReadData} returns this
 */
proto.wilqo.api.mortgage.DealReadData.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealReadData.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FlexlistSubsectionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FlexlistSubsectionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    subsection: (f = msg.getSubsection()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.toObject(includeInstance, f),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.wilqo.shared.models.DynamicDataElement.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FlexlistSubsectionData}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FlexlistSubsectionData;
  return proto.wilqo.api.mortgage.FlexlistSubsectionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FlexlistSubsectionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FlexlistSubsectionData}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.deserializeBinaryFromReader);
      msg.setSubsection(value);
      break;
    case 2:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader, "", new proto.wilqo.shared.models.DynamicDataElement());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FlexlistSubsectionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FlexlistSubsectionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubsection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter);
  }
};


/**
 * optional wilqo.shared.models.DesignerPanelSequence subsection = 1;
 * @return {?proto.wilqo.shared.models.DesignerPanelSequence}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.getSubsection = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelSequence|undefined} value
 * @return {!proto.wilqo.api.mortgage.FlexlistSubsectionData} returns this
*/
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.setSubsection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FlexlistSubsectionData} returns this
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.clearSubsection = function() {
  return this.setSubsection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.hasSubsection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, wilqo.shared.models.DynamicDataElement> data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.shared.models.DynamicDataElement>}
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.shared.models.DynamicDataElement>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.wilqo.shared.models.DynamicDataElement));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage.FlexlistSubsectionData} returns this
 */
proto.wilqo.api.mortgage.FlexlistSubsectionData.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.URLAProgressItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.URLAProgressItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.URLAProgressItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.URLAProgressItem}
 */
proto.wilqo.api.mortgage.URLAProgressItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.URLAProgressItem;
  return proto.wilqo.api.mortgage.URLAProgressItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.URLAProgressItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.URLAProgressItem}
 */
proto.wilqo.api.mortgage.URLAProgressItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.URLAProgressItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.URLAProgressItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.URLAProgressItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.URLAProgressItem} returns this
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.URLAProgressItem} returns this
*/
proto.wilqo.api.mortgage.URLAProgressItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.URLAProgressItem} returns this
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.URLAProgressItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.VirtualFieldData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.VirtualFieldData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.VirtualFieldData.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logicalScopeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    template: (f = msg.getTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData}
 */
proto.wilqo.api.mortgage.VirtualFieldData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.VirtualFieldData;
  return proto.wilqo.api.mortgage.VirtualFieldData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.VirtualFieldData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData}
 */
proto.wilqo.api.mortgage.VirtualFieldData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalScopeId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.VirtualFieldData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.VirtualFieldData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.VirtualFieldData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogicalScopeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData} returns this
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData} returns this
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logical_scope_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.getLogicalScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData} returns this
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.setLogicalScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplate template = 4;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData} returns this
*/
proto.wilqo.api.mortgage.VirtualFieldData.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.VirtualFieldData} returns this
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.VirtualFieldData.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ScopeData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ScopeData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ScopeData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prefix: jspb.Message.getFieldWithDefault(msg, 3, ""),
    depth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requiresSelector: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hierarchy: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ScopeData}
 */
proto.wilqo.api.mortgage.ScopeData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ScopeData;
  return proto.wilqo.api.mortgage.ScopeData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ScopeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ScopeData}
 */
proto.wilqo.api.mortgage.ScopeData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefix(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresSelector(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHierarchy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ScopeData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ScopeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ScopeData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrefix();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRequiresSelector();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHierarchy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string prefix = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 depth = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool requires_selector = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getRequiresSelector = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setRequiresSelector = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string hierarchy = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ScopeData.prototype.getHierarchy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ScopeData} returns this
 */
proto.wilqo.api.mortgage.ScopeData.prototype.setHierarchy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DynamicDataItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DynamicDataItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DynamicDataItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage.DynamicDataItemField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem}
 */
proto.wilqo.api.mortgage.DynamicDataItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DynamicDataItem;
  return proto.wilqo.api.mortgage.DynamicDataItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DynamicDataItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem}
 */
proto.wilqo.api.mortgage.DynamicDataItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.DynamicDataItemField;
      reader.readMessage(value,proto.wilqo.api.mortgage.DynamicDataItemField.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DynamicDataItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DynamicDataItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.DynamicDataItemField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem} returns this
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DynamicDataItemField items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DynamicDataItemField>}
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DynamicDataItemField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DynamicDataItemField, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DynamicDataItemField>} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem} returns this
*/
proto.wilqo.api.mortgage.DynamicDataItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DynamicDataItemField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField}
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.DynamicDataItemField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItem} returns this
 */
proto.wilqo.api.mortgage.DynamicDataItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DynamicDataItemField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DynamicDataItemField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataItemField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DynamicDataItemField;
  return proto.wilqo.api.mortgage.DynamicDataItemField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DynamicDataItemField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DynamicDataItemField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DynamicDataItemField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DynamicDataItemField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField} returns this
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField} returns this
*/
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DynamicDataItemField} returns this
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DynamicDataItemField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage);
