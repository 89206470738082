import clsx from 'clsx';

import { useAdaptiveDesign } from '@/Utils/Hooks/useAdaptiveDesign/useAdaptiveDesign';

import { Icon } from './Icon';
import { Menu } from './Menu';
import { ProgressBar } from './ProgressBar';
import { Skeleton } from './Skeleton';
import { Typography } from './Typography';

interface IContextualAppBarProps {
  icon: string;
  onClickIcon: () => void;
  title?: string;
  loading?: boolean;
  children?: React.ReactNode;
  dropdownOptions?: Array<any> | React.ReactNode;
  stepsNumber?: number;
  currentStep?: number;
  mobileDirection?: 'column' | 'row';
}

const ContextualAppBar = (props: IContextualAppBarProps) => {
  const { children, currentStep, dropdownOptions, icon, loading = true, mobileDirection = 'row', onClickIcon, stepsNumber, title } = props;

  const renderChildren = () => {
    if (loading) return null;
    return children;
  };

  const mobileBarCname = clsx(
    'sticky top-0 w-full z-[2] max-h-32 bg-surface border-b border-on-surface-stroke',
    {
      'flex items-center justify-between': !stepsNumber && !currentStep && !dropdownOptions,
    },
  );

  const renderMobileAppBar = () => (
    <div className={mobileBarCname}>
      <div
        className={
          clsx('flex justify-between desktop:p-4 w-full', {
            'flex-col md:flex-row items-start md:items-center': mobileDirection === 'column',
            'flex-row items-center': mobileDirection === 'row',
          })
        }
      >
        <div className={clsx('flex items-center w-full p-2 desktop:p-4')}>
          <Icon className="mr-4 cursor-pointer" icon={icon} onClick={onClickIcon} variant="interactive" />
          {
            loading ? <Skeleton height="24px" variant="rect" width="127px" />
              : (
                <Typography variant="display6">
                  {title}
                </Typography>
              )
          }
        </div>
        {renderChildren()}
      </div>

      {
        (dropdownOptions && !(stepsNumber || currentStep)) && (
          <div className="flex items-center justify-start p-4 pt-0">
            <Menu
              options={dropdownOptions}
              triggerItem={<Icon className="!text-on-surface-inactive" icon="Menu" />}
            />
          </div>
        )
      }
      {
        (dropdownOptions && stepsNumber && currentStep) && (
          <div className="flex items-center justify-center p-4 pt-0">
            <Menu
              options={dropdownOptions}
              triggerItem={<Icon className="!text-on-surface-inactive" icon="Menu" />}
            />
            <div className="ml-3 w-full">
              <ProgressBar currentStep={currentStep} stepsNumber={stepsNumber} variant="light" />
            </div>
          </div>
        )
      }
    </div>
  );

  const renderDesktopAppBar = () => (
    <div
      className="flex items-center justify-between h-[72px] min-h-[72px]
      sticky top-0 w-full z-[2] bg-surface border-b border-on-surface-stroke
      py-0 px-6"
      id="desktopAppBar"
    >
      <div className="flex items-center justify-between p3">
        <Icon className="icon mr-4 cursor-pointer" icon={icon} onClick={onClickIcon} variant="interactive" />
        {
          loading ? <Skeleton height="24px" variant="rect" width="127px" />
            : (
              <Typography variant="display6">
                {title}
              </Typography>
            )
        }
      </div>
      {renderChildren()}
    </div>
  );

  const AdaptiveComponent = useAdaptiveDesign({
    Desktop: renderDesktopAppBar,
    Mobile: renderMobileAppBar,
    Tablet: renderDesktopAppBar,
  });

  return <AdaptiveComponent />;
};

export { ContextualAppBar };
