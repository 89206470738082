import { Route, Routes } from 'react-router';

import { LoanListPageDesktop } from '@/Routes/Pages/LoanListPage/Desktop';
import { LoanListPageMobile } from '@/Routes/Pages/LoanListPage/Mobile';
import Login from '@/Routes/Pages/Login';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { PrivateHome, PrivateRouteWrapper } from './privateRoute';

const MobileRoutes = () => (
  <Routes>
    <Route element={<Login />} path="/" />
    <Route element={<LoanListPageMobile />} path="loan-list" />
  </Routes>
);

const DesktopRoutes = () => (
  <Routes>
    <Route element={<Login />} path="/" />
    <Route element={<PrivateRouteWrapper><PrivateHome /></PrivateRouteWrapper>} path="home" />
    <Route element={<LoanListPageDesktop />} path="loan-list" />
  </Routes>
);

export const NewRoutes = () => {
  const { isDesktop } = useWindow();

  if (isDesktop) return <DesktopRoutes />;
  return <MobileRoutes />;
};
