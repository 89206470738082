// source: Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/TableCircuitComponent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableIteratorId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    columnsList: jspb.Message.toObjectList(msg.getColumnsList(),
    proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.toObject, includeInstance),
    rowDataList: jspb.Message.toObjectList(msg.getRowDataList(),
    proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.toObject, includeInstance),
    addNew: (f = msg.getAddNew()) && proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.toObject(includeInstance, f),
    helperText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableIteratorId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.deserializeBinaryFromReader);
      msg.addColumns(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.deserializeBinaryFromReader);
      msg.addRowData(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.deserializeBinaryFromReader);
      msg.setAddNew(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelperText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableIteratorId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.serializeBinaryToWriter
    );
  }
  f = message.getRowDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.serializeBinaryToWriter
    );
  }
  f = message.getAddNew();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.serializeBinaryToWriter
    );
  }
  f = message.getHelperText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_iterator_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getTableIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setTableIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TableCircuitComponentColumn columns = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getColumnsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.addColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * repeated TableCircuitComponentRowData row_data = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getRowDataList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setRowDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.addRowData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.clearRowDataList = function() {
  return this.setRowDataList([]);
};


/**
 * optional TableCircuitComponentAddNew add_new = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getAddNew = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setAddNew = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.clearAddNew = function() {
  return this.setAddNew(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.hasAddNew = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string helper_text = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.getHelperText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent.prototype.setHelperText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.toObject = function(includeInstance, msg) {
  var f, obj = {
    addRoute: (f = msg.getAddRoute()) && proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.toObject(includeInstance, f),
    uniqueIdentifierVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    writeItemsList: jspb.Message.toObjectList(msg.getWriteItemsList(),
    Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.deserializeBinaryFromReader);
      msg.setAddRoute(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifierVirtualFieldId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.deserializeBinaryFromReader);
      msg.addWriteItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddRoute();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.serializeBinaryToWriter
    );
  }
  f = message.getUniqueIdentifierVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWriteItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional TableCircuitComponentRoute add_route = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.getAddRoute = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.setAddRoute = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.clearAddRoute = function() {
  return this.setAddRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.hasAddRoute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string unique_identifier_virtual_field_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.getUniqueIdentifierVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.setUniqueIdentifierVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated wilqo.shared.models.DynamicDataWriteItem write_items = 4;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.getWriteItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.setWriteItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.addWriteItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.DynamicDataWriteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentAddNew.prototype.clearWriteItemsList = function() {
  return this.setWriteItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasKebab: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasKebab(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasKebab();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_kebab = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.getHasKebab = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentColumn.prototype.setHasKebab = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.toObject = function(includeInstance, msg) {
  var f, obj = {
    circuitId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCircuitId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCircuitId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string circuit_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.prototype.getCircuitId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.prototype.setCircuitId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.toObject = function(includeInstance, msg) {
  var f, obj = {
    editEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    deleteEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    editRoute: (f = msg.getEditRoute()) && proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteEnabled(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.deserializeBinaryFromReader);
      msg.setEditRoute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDeleteEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEditRoute();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool edit_enabled = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.getEditEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.setEditEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool delete_enabled = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.getDeleteEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.setDeleteEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional TableCircuitComponentRoute edit_route = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.getEditRoute = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRoute|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.setEditRoute = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.clearEditRoute = function() {
  return this.setEditRoute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.prototype.hasEditRoute = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineOneValuesList: jspb.Message.toObjectList(msg.getLineOneValuesList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    lineOneDelimiter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lineTwoValuesList: jspb.Message.toObjectList(msg.getLineTwoValuesList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    lineTwoDelimiter: jspb.Message.getFieldWithDefault(msg, 4, ""),
    kebab: (f = msg.getKebab()) && proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addLineOneValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineOneDelimiter(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addLineTwoValues(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineTwoDelimiter(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.deserializeBinaryFromReader);
      msg.setKebab(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineOneValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getLineOneDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLineTwoValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getLineTwoDelimiter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKebab();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab.serializeBinaryToWriter
    );
  }
};


/**
 * repeated wilqo.shared.models.RenderableValue line_one_values = 1;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.getLineOneValuesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.setLineOneValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.addLineOneValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.clearLineOneValuesList = function() {
  return this.setLineOneValuesList([]);
};


/**
 * optional string line_one_delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.getLineOneDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.setLineOneDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.RenderableValue line_two_values = 3;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.getLineTwoValuesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.setLineTwoValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.addLineTwoValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.clearLineTwoValuesList = function() {
  return this.setLineTwoValuesList([]);
};


/**
 * optional string line_two_delimiter = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.getLineTwoDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.setLineTwoDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TableCircuitComponentKebab kebab = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.getKebab = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentKebab|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.setKebab = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.clearKebab = function() {
  return this.setKebab(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.prototype.hasKebab = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellDataMap: (f = msg.getCellDataMap()) ? f.toObject(includeInstance, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.toObject) : [],
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData;
  return proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getCellDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.deserializeBinaryFromReader, "", new proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData());
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData.serializeBinaryToWriter);
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * map<string, TableCircuitComponentCellData> cell_data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.getCellDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentCellData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.clearCellDataMap = function() {
  this.getCellDataMap().clear();
  return this;
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponentRowData.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
