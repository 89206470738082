import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import type { WilqoError } from '@/API/Models/Wilqo.Shared.Messaging/WilqoMessaging_pb';

import { CustomValue } from '../Models/Wilqo.Shared.Mortgage.Integration/Pricing/Models_pb';

const logger = console;

export type HookParameters<D = Record<string, any>> = {
  dataTransferObject: D;
  options?: UseQueryOptions<any, Error, any>;
};

export interface PageResults<T> {
  list: T[];
  totalItems?: number;
  totalPages?: number;
}

export type PageHook<TData> = (search: string, page: number, size: number, filters: any) => UseQueryResult<PageResults<TData>>;

export const endpointResponseMiddleware = <T extends Record<any, any>>
  (endpointName: string, error?: WilqoError.AsObject, data?: T) => {
  if (error && error.error) {
    const message = error.errorMessage || 'Unknown error';
    logger.error(message);
    throw new Error(message);
  }
  return data;
};

export const pingLatencyFromTtl = (ttl: number): number => Math.max((ttl - 3) * 1000, 1000);

export function expBackoff(attempt: number, interval: number, exp: number): number {
  return interval * (exp ** attempt) - interval;
}

export type ConnectionStatusType = 'connected' | 'disconnected' | 'none' | 'reconnecting';
export interface Message {
  status: ConnectionStatusType;
  timeToReconnect?: number;
}
export type MessageHandler = (message: Message) => void;
export type ReconnectCallback = (newCount: number) => void;
export const reconnectSubscriptionHandler = (retryCount: number, messageHandler: MessageHandler, reconnect: ReconnectCallback) => {
  messageHandler({ status: 'disconnected' });
  const newCount = retryCount + 1;
  if (retryCount === 0) {
    reconnect(newCount);
  } else {
    const backoffTiming = expBackoff(retryCount, 1000, 1.5);
    setTimeout(() => {
      reconnect(newCount);
      messageHandler({ status: 'reconnecting', timeToReconnect: backoffTiming });
    }, backoffTiming);
  }
};

export const createCustomValue = (name: string, value: string) => {
  const customValue = new CustomValue();
  customValue.setName(name);
  customValue.setValue(JSON.stringify(value));
  return customValue;
};
