import { useMemo } from 'react';

import { useLoanOverviewConfigurations } from '@/API/Queries/brand/useLoanOverviewConfigurations';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';

interface TableDataItem {
  id: string;
  title: string;
  published: string;
}

const LoanOverviewConfiguration = () => {
  const {
    data: sidesheetConfigurations,
    isLoading,
  } = useLoanOverviewConfigurations();

  const columns = useMemo((): Array<Column<TableDataItem>> => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'title',
        type: 'text',
      },
    },
  ], []);

  const data = useMemo(() => sidesheetConfigurations?.map((sidesheet) => ({
    id: sidesheet.id,
    title: sidesheet.title,
  })), [sidesheetConfigurations]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'All Loan Overviews' } }}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
    />
  );
};

export { LoanOverviewConfiguration };
