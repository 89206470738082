import { useParams } from 'react-router';

import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { LicenseAuthorityEnum, LicenseScopeEnum } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { useOrganization } from '@/API/Queries/user/useOrganization';
import { useUpdateOrganization } from '@/API/Queries/user/useUpdateOrganization';
import { useUpsertOrgLicense } from '@/API/Queries/user/useUpsertOrgLicense';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { LicensesTable } from '@/Components/Features/LicenseTable/LicensesTable';
import { getTimestampFromDate } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const OrganizationLicense = () => {
  const { orgId = '' } = useParams();
  const { data: organization, isLoading, refetch } = useOrganization(orgId);
  const { mutate: upsertOrgLicense } = useUpsertOrgLicense();
  const { mutate: updateOrganization } = useUpdateOrganization();
  const { showSnackBar } = useShared();

  const handleUpsertLicenseSuccess = (selectedLicense?: License.AsObject) => {
    refetch();
    if (selectedLicense) {
      showSnackBar({ message: `${selectedLicense.licenseLegalName} updated`, open: true });
    } else {
      showSnackBar({ message: 'License created', open: true });
    }
  };

  const handleLicenseSubmit = (formData: DynamicDataElementValues, selectedLicense?: License.AsObject) => {
    const { enabled, expirationDate, licenseId, licenseName, regulator, startDate, status } = formData;
    const updateObj = {
      license: {
        enabled: enabled.value.id === 'yes',
        expirationDate: getTimestampFromDate(expirationDate.value).toObject(),
        id: selectedLicense?.id || '',
        issueDate: { nanos: 0, seconds: 0 },
        licenseAuthorityType: LicenseAuthorityEnum.LICENSE_AUTHORITY_ENUM_NMLS,
        licenseDescription: '',
        licenseId: licenseId.value,
        licenseLegalName: licenseName.value,
        licenseScope: regulator.value.id || '',
        licenseScopeType: LicenseScopeEnum.LICENSE_SCOPE_ENUM_STATE,
        licenseStatusType: status.value.id,
        startDate: getTimestampFromDate(startDate.value).toObject(),
      },
      orgId,
    };
    upsertOrgLicense(updateObj, {
      onError: () => showSnackBar({ message: 'Something went wrong, try again later', open: true }),
      onSuccess: () => handleUpsertLicenseSuccess(selectedLicense),
    });
  };

  const handleNMLSSubmit = (formData: any, close: () => void) => {
    if (organization) {
      updateOrganization({ ...organization, nationalLicensingId: formData.license.value }, {
        onSuccess: () => {
          showSnackBar({ message: 'License updated', open: true });
          close();
        },
      });
    }
  };

  return (
    <LicensesTable
      handleSubmit={handleLicenseSubmit}
      handleSubmitNMLS={handleNMLSSubmit}
      isLoading={isLoading}
      licenseNumber={organization?.nationalLicensingId || ''}
      licenses={organization?.licensesList || []}
      title="Organization"
    />
  );
};

export { OrganizationLicense };
