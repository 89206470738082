import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';

import { useMenuContext } from './MenuContext';
import { type MenuItemProps, MenuItemComponent } from './MenuItem';

export const MenuSubItem = ({ children, ...props }: Omit<MenuItemProps, 'sub'>) => {
  const { setOpenChildren } = useMenuContext();

  const handleClick = (event: Event) => {
    event.preventDefault();
    setOpenChildren({ children, title: props.label });
  };

  return (
    <DropdownMenuItem onSelect={handleClick}>
      <MenuItemComponent sub {...props} />
    </DropdownMenuItem>
  );
};
