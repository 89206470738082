import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SectionAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb';
import type { FormField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { TogglableWidgetsContainerSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { DealWriteCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { SaveFieldItem, TogglableWidgetPageSaveCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useTogglableWidgetSave } from '@/API/Queries/mortgageDynamicData/useTogglableWidgetSave';
import { type IButtonProps, Button } from '@/Components/Atoms/Button';
import { Card } from '@/Components/Atoms/Card/Card';
import { Chip } from '@/Components/Atoms/Chip';
import { Icon } from '@/Components/Atoms/Icon';
import { ListItem } from '@/Components/Atoms/ListItem';
import { Masonry } from '@/Components/Atoms/Masonry';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import type { DynamicDataElementValues, DynamicFormContextState, FormProgress, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { ButtonGroup } from '@/Components/Features/PanelElement/ButtonGroup';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useWindow } from '@/Utils/Helpers/useWindow';
import type { FormPanelElement } from '@/Utils/Helpers/widgetHelpers';
import { mapFormFieldToPanelElement } from '@/Utils/Helpers/widgetHelpers';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { useWidgetContext } from '../WidgetContext';
import type { DynamicDialogProps } from '.';

export const Togglable = (props: DynamicDialogProps) => {
  const [openWidgetIds, setOpenWidgetIds] = useState<string[]>([]);
  const [isSavingNew, setIsSavingNew] = useState(false);
  const saveNewRef = useRef(false);

  const [isEditing, setIsEditing] = useState(props.isNew || props.editMode);
  const { loanId } = useDynamicLoanInfo();
  const { setVersion, version } = useDynamicContext();
  const { showSnackBar } = useShared();
  const { reloadMainPage } = useWidgetContext();
  const { isMobile } = useWindow();

  const { isLoading: isSaving, mutate: saveToggable } = useTogglableWidgetSave();

  const togglableWidget = useMemo(() => props.page?.widgetsList[0].togglableWidget, [props]);
  const chipList = useMemo(() => togglableWidget?.widgetAdditionChipsList.filter((chip) => !openWidgetIds.includes(chip.targetWidgetId)), [openWidgetIds, togglableWidget]);

  const createProgress = useCallback(() => ({
    values: togglableWidget?.itemsList.reduce((progress, item) => ({
      ...progress,
      ...item.widget?.form?.fieldsList.reduce((acc, field) => ({
        ...acc,
        [field.id]: {
          dynamic: field.value?.value && field.value?.value?.dataType !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL ? DynamicDataElementMap(field.value?.value) : undefined,
        },
      }), {}),
    }), {}) || {},
  }), [togglableWidget]);

  const progress = useMemo((): FormProgress | undefined => createProgress(), [createProgress]);

  const onClickChip = (chip: TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.AsObject) => {
    setOpenWidgetIds(openWidgetIds.concat(chip.targetWidgetId));
  };

  const handleDiscardClick = useCallback(() => {
    setIsEditing(false);
    props.onClose();
  }, [props]);

  const handleSubmitError = (error: any) => showSnackBar(error.message || 'Something went wrong');

  const handleSubmitSuccess = (response: DealWriteCommandResponse.AsObject) => {
    if (response.error?.error) {
      handleSubmitError({ message: response.error.errorMessage });
    } else {
      if (!saveNewRef.current) {
        props.onClose();
      }
      showSnackBar('Loan Updated');
      setVersion(response.dealVersion);
      reloadMainPage();
    }
    saveNewRef.current = false;
    setIsSavingNew(false);
  };

  const handleSubmit = (values: DynamicDataElementValues, { callback, hiddenWidgetIds }: SubmitValues) => {
    saveToggable(
      {
        dealId: loanId,
        dealVersion: version,
        itemsList: props.page?.widgetsList[0].togglableWidget?.itemsList.filter((item) => openWidgetIds.includes(item.id) && item?.widget?.id !== undefined && !hiddenWidgetIds?.includes(item.widget.id)).map((openWidget) => {
          const itemSettings = props.page?.widgetsList[0].togglableWidget?.itemsList.find((i) => i.id === openWidget.id);
          const item = new TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem();
          item.setCreateNew(Boolean(props.isNew || !itemSettings?.active));
          const saveItemsList: Array<SaveFieldItem> = [];
          itemSettings?.widget?.form?.fieldsList.forEach((field) => {
            const fieldValue = values[field.id];
            if (field.hidden
              && field.value?.value
              && field.value?.value?.dataType !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
              const saveItem = new SaveFieldItem();
              saveItem.setFieldId(field.id);
              saveItem.setValue(DynamicDataElementMap(field.value.value));
              saveItem.setConsolidatedItemScopeToken(field.consolidatedItemScopeToken);
              saveItemsList.push(saveItem);
              return;
            }
            if (fieldValue && fieldValue.dynamic) {
              const saveItem = new SaveFieldItem();
              saveItem.setFieldId(field.id);
              saveItem.setValue(fieldValue.dynamic);
              saveItem.setConsolidatedItemScopeToken(field.consolidatedItemScopeToken);
              saveItemsList.push(saveItem);
            }
          });
          item.setItemsList(saveItemsList);
          item.setWidgetId(itemSettings?.id || '');
          item.setWidgetScopeToken(itemSettings?.scopeToken || '');
          return item;
        }) ?? [],
        pageId: props.page?.id || '',
      },
      {
        onError: handleSubmitError,
        onSuccess: (res) => {
          callback?.();
          handleSubmitSuccess(res);
        },
      },
    );
  };

  const getButtonActions = useCallback(({ clearForm, hasError, imperativeSubmit, resetProgress }: DynamicFormContextState): Array<IButtonProps> => {
    if (!isEditing) {
      return [
        {
          label: 'Edit',
          onClick: () => {
            setIsEditing(true);
            resetProgress(createProgress());
          },
        },
      ];
    }

    const editingStateButtons: Record<number, IButtonProps> = {
      [SectionAction.SECTION_ACTION_CANCEL]: {
        label: 'Discard',
        onClick: handleDiscardClick,
        variant: 'tertiary',
      },
      [SectionAction.SECTION_ACTION_SAVE_AND_NEW_ITEM]: {
        disabled: hasError,
        isLoading: isSavingNew && isSaving,
        label: 'Save & new item',
        onClick: () => {
          imperativeSubmit(clearForm);
          setIsSavingNew(true);
          saveNewRef.current = true;
        },
        variant: 'secondary',
      },
      [SectionAction.SECTION_ACTION_SAVE]: {
        disabled: hasError,
        isLoading: isSaving && !isSavingNew,
        label: 'Save',
        type: 'submit',
      },
    };
    const actions = props.actions || [SectionAction.SECTION_ACTION_CANCEL, SectionAction.SECTION_ACTION_SAVE];
    return actions
      .map((actionEnum) => editingStateButtons[actionEnum])
      .filter((button) => button !== undefined);
  }, [props, handleDiscardClick, isSavingNew, isSaving, isEditing, createProgress]);

  // adds default open widget to list of open widget ids
  const hasOpenDefault = useRef(false);
  useEffect(() => {
    if (!hasOpenDefault.current && togglableWidget && isEditing) {
      const defaultOpen = togglableWidget.itemsList.filter((e) => e.active).map((e) => e.id || '');
      setOpenWidgetIds(defaultOpen);
      hasOpenDefault.current = true;
    }
  }, [togglableWidget, isEditing]);

  const renderPanelElementList = (fieldsList: FormField.AsObject[], values: DynamicFormContextState) => {
    const panelElements = fieldsList.reduce((acc: FormPanelElement[], field) => {
      const panelElement = mapFormFieldToPanelElement(field, undefined, field.id === values.fieldGrouping?.googleAddress?.addressLineFieldId);
      if (panelElement && field.panelElement && field.panelElement.type === 'multi-select' && field.panelElement.headerText === 'Borrowers') {
        panelElement.type = 'chip-list';
      }
      if (panelElement) return [...acc, panelElement];
      return acc;
    }, []);
    const borrowerChip = panelElements.find((pe) => pe.type === 'chip-list');
    const pe = panelElements.filter((pe) => pe.type !== 'chip-list');
    return (
      <div className="flex flex-col gap-6">
        {borrowerChip && (
          <PanelElementRenderer {...borrowerChip} />
        )}
        {pe.map((panelElement) => (
          <PanelElementRenderer key={panelElement.id} {...panelElement} hidden={panelElement.hidden} />
        ))}
      </div>
    );
  };

  const renderEditMode = (values: DynamicFormContextState) => (
    <Masonry maxNumberOfColumns={3}>
      {togglableWidget?.itemsList.filter((item) => item?.widget?.id !== undefined && !values.hiddenWidgetIds.includes(item.widget.id)).map((widget) => (
        <div className={clsx({ hidden: !openWidgetIds.includes(widget.id) })}>
          <Card
            defaultOpen
            disableAccordion
            headerProps={{
              kebabActions: widget.active ? undefined : {
                options: [{ label: 'Remove', onClick: () => setOpenWidgetIds(openWidgetIds.filter((e) => e !== widget.id)) }],
                triggerItem: <Icon icon="MoreVert" />,
              },
              title: widget.widget?.form?.label || '',
            }}
            variant="border"
          >
            <div className="p-6 flex flex-col gap-6">
              {renderPanelElementList(widget.widget?.form?.fieldsList || [], values)}
            </div>
          </Card>
        </div>
      ))}
      {chipList && chipList.length > 0 && (
        <Card
          defaultOpen
          disableAccordion
          headerProps={{ title: 'Additional Info' }}
          variant="border"
        >
          <div className="p-6 flex flex-row flex-wrap gap-4">
            {chipList?.map((chip) => (
              <Chip
                icon="Add"
                label={chip.label}
                onClick={() => onClickChip(chip)}
              />
            ))}
          </div>
        </Card>
      )}
    </Masonry>
  );

  const renderReadMode = (values: DynamicFormContextState) => (
    <Masonry maxNumberOfColumns={3}>
      {
        togglableWidget?.itemsList.filter((item) => item?.widget?.id !== undefined && !values.hiddenWidgetIds.includes(item.widget.id)).map((item) => (!item.active ? null : (
          <Card
            defaultOpen
            disableAccordion
            headerProps={{ title: item.title }}
            variant="border"
          >
            {item.widget?.form?.fieldsList.map((field) => (values.hiddenFieldIds.includes(field.id) || field.hidden ? null : (
              <ListItem label={renderValue(field.value, field.id, field.panelElement)} overline={field.leadingText} />
            )))}
          </Card>
        )))
      }
    </Masonry>
  );

  const renderWidget = (values: DynamicFormContextState) => {
    if (isEditing) return renderEditMode(values);
    return renderReadMode(values);
  };

  return (
    <Dialog onOpenChange={props.onClose} open={props.isOpen} renderWithin={false}>
      <DialogLayout asChild>
        <DynamicFormProvider
          fieldGrouping={props.page?.widgetsList[0].fieldGrouping}
          onSubmit={handleSubmit}
          page={props.page}
          progress={progress}
        >
          {(values) => (
            <>
              <DialogHeader
                actions={!isMobile && (
                  <ButtonGroup
                    buttons={getButtonActions(values)}
                  />
                )}
                title={props.page?.header?.title}
              />
              <DialogContent className="p-4 tablet:p-8 desktop:p-12">
                {renderWidget(values)}
              </DialogContent>
              {isMobile && (
                <DialogFooter borderTop span>
                  {
                    getButtonActions(values).map((btn) => <Button key={btn.label} {...btn} />)
                  }
                </DialogFooter>
              )}
            </>
          )}
        </DynamicFormProvider>
      </DialogLayout>
    </Dialog>
  );
};
