import { IntegrationTypeLocalEnum } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useRunFraud } from '@/API/Queries/mortgageIntegrations/fraud/useRunFraud';
import { Dialog } from '@/Components/Atoms/RadixDialog';
import { RequiredFields } from '@/Components/Features/DynamicLoanPage/RequiredFields';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface FraudRequiredFieldsModalProps {
  onClose: () => void;
  open: boolean;
}

const FraudRequiredFieldsModal = ({ onClose, open }: FraudRequiredFieldsModalProps) => {
  const { showSnackBar } = useShared();

  const { data: loan } = useLoan();
  const { callRunFraud, isLoading } = useRunFraud();

  const handleOnClose = () => {
    onClose();
  };

  const handleOnIsValid = () => {
    callRunFraud(loan?.dealId || '').then(() => {
      showSnackBar({ message: 'Manual fraud triggered successfully', open: true });
      onClose();
    }).catch((error) => {
      showSnackBar({ message: error?.message || 'Something went wrong, please try again later', open: true });
    });
  };

  return (
    <Dialog onOpenChange={handleOnClose} open={open}>
      <RequiredFields
        dealId={loan?.dealId || ''}
        integrationType={IntegrationTypeLocalEnum.INTEGRATION_TYPE_LOCAL_ENUM_FRAUD}
        isRunningIntegration={isLoading}
        onIsValid={handleOnIsValid}
      />
    </Dialog>
  );
};

export { FraudRequiredFieldsModal };
