import clsx from 'clsx';

import type { LoanEscrowFee, LoanPrepaidFee } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { EscrowItemEnum, FeeSourceEnum, LoanFeeCategoryTypeEnum, PrepaidItemEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { Column } from '@/API/Queries/mortgage/useLoanFees';
import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { ESCROWS_PREPAIDS_COLUMNS } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import FeeOverrideControls from '@/Routes/Pages/loan/PurposeBuilt/Fees/Widgets/FeeOverrideControls';
import { pluralizeDays, pluralizeMonths } from '@/Utils/Helpers/dateHelpers';
import { convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';

const EscrowPrepaidDetails = () => {
  const { loanEscrowFees, loanPrepaidFees } = useFeesContext();

  const contingencyMessage = () => (
    <div className="py-4">
      <ContingencyMessage
        icon="NoItems"
        subtitle="No details found"
        title="No results, yet"
        variant="image"
      />
    </div>
  );

  const tableHeaderRow = (
    <tr>
      {ESCROWS_PREPAIDS_COLUMNS.map((column, index) => (
        <th
          key={column.id}
          className={
            clsx(
              'text-[10px] tracking-[1.5px] font-normal text-on-surface-inactive px-4 py-3 uppercase',
              {
                'border-r border-r-on-surface-stroke': index - 1 !== ESCROWS_PREPAIDS_COLUMNS.length,
                'text-left w-[450px]': index === 0,
                'text-right': index !== 0,
                'text-right w-[120px]': column.id === 'dataSource',
              },
            )
          }
        >
          {column.header}
        </th>
      ))}
    </tr>
  );

  const escrowAmountColumn = (column: Column, fee: LoanEscrowFee.AsObject): JSX.Element => {
    const aggregateAdjustment = fee.escrowPayment?.escrowType === EscrowItemEnum.ESCROW_ITEM_ENUM_AGGREGATE_ADJUSTMENT;

    const renderAmount = () => {
      if (aggregateAdjustment) {
        return '--';
      }
      if (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe) {
        return convertProtoToCurrency(fee.manualOverrideTotalAmount || 0);
      }

      return convertProtoToCurrency(fee.amount || 0);
    };

    const renderTimeFrame = () => {
      if (aggregateAdjustment || (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe)) {
        return '--';
      }
      if (fee.manualOverrideIndicator && fee.manualOverrideTimeframe) {
        return pluralizeMonths(fee.manualOverrideTimeframe);
      }

      return pluralizeMonths(fee.timeframe || 0);
    };

    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className={clsx('text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[160px] align-top',
          { 'border-r border-r-on-surface-stroke': true,
            'text-right': true,
          })}
      >
        {renderAmount()}
        <Typography
          className="text-on-surface-inactive"
          variant="caption"
        >
          {renderTimeFrame()}
        </Typography>
      </td>
    );
  };

  const prepaidAmountColumn = (column: Column, fee: LoanPrepaidFee.AsObject): JSX.Element => {
    const useDays = fee?.prepaidPayment?.prepaidType === PrepaidItemEnum.PREPAID_ITEM_ENUM_PREPAID_INTEREST;
    const amountSuffix = useDays ? ' / day' : ' / mo';

    const renderAmount = () => {
      if (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe) {
        return convertProtoToCurrency(fee.manualOverrideTotalAmount || 0);
      }

      return `${convertProtoToCurrency(fee.amount || 0)}${amountSuffix}`;
    };

    const renderTimeFrame = () => {
      if (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe) {
        return '--';
      }
      if (fee.manualOverrideIndicator && fee.manualOverrideTimeframe) {
        return useDays ? pluralizeDays(fee.manualOverrideTimeframe) : pluralizeMonths(fee.manualOverrideTimeframe);
      }

      return useDays ? pluralizeDays(fee.timeframe || 0) : pluralizeMonths(fee.timeframe || 0);
    };

    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className={clsx('text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
          { 'border-r border-r-on-surface-stroke': true, 'text-right': true })}
      >
        {renderAmount()}
        <Typography
          className="text-on-surface-inactive"
          variant="caption"
        >
          {renderTimeFrame()}
        </Typography>
      </td>
    );
  };

  const borrowerPaidColumn = (column: Column, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject): JSX.Element => {
    let value;
    if ('prepaidPayment' in fee) {
      const AMOUNT = fee.prepaidPayment?.paidByBorrowerOutsideOfClosingAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (POC)` : null;
    }
    if ('escrowPayment' in fee) {
      const AMOUNT = fee.escrowPayment?.paidByBorrowerOutsideOfClosingAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (POC)` : null;
    }
    const borrowerPaidAmount = fee.borrowerPaid ? convertProtoToCurrency(fee.borrowerPaid) : numberFormatter.format(0);
    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
        id={`table-row-cell-${fee.name}`}
      >
        <div className="flex flex-col items-end">
          <span className="text-sm text-on-surface-active font-normal">{borrowerPaidAmount}</span>
          <span className="font-normal block text-sm leading-captionHeight tracking-captionSpacing break-words text-on-surface-inactive">
            {value}
          </span>
        </div>
      </td>
    );
  };

  const sellerPaidColumn = (column: Column, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject): JSX.Element => {
    let value;
    if ('prepaidPayment' in fee) {
      const AMOUNT = fee.prepaidPayment?.paidBySellerCreditAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (credit)` : null;
    }
    if ('escrowPayment' in fee) {
      const AMOUNT = fee.escrowPayment?.paidBySellerCreditAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (credit)` : null;
    }
    const sellerPaidAmount = fee.sellerPaid ? convertProtoToCurrency(fee.sellerPaid) : numberFormatter.format(0);

    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className={clsx('text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
          { 'border-r border-r-on-surface-stroke': true, 'text-right': true })}
      >
        {sellerPaidAmount}
        <Typography className="text-on-surface-inactive" variant="caption">
          {value}
        </Typography>
      </td>
    );
  };

  const lenderPaidColumn = (column: Column, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject): JSX.Element => {
    let value;
    if ('prepaidPayment' in fee) {
      const AMOUNT = fee.prepaidPayment?.paidByLenderCreditAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (credit)` : null;
    }
    if ('escrowPayment' in fee) {
      const AMOUNT = fee.escrowPayment?.paidByLenderCreditAmount;
      value = AMOUNT && ConvertProtoDecimalAsObjectToNumber(AMOUNT) > 0 ? `${convertProtoToCurrency(AMOUNT)} (credit)` : null;
    }
    const lenderPaidAmount = fee.lenderPaid ? convertProtoToCurrency(fee.lenderPaid) : numberFormatter.format(0);

    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className={
          clsx('text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
            { 'border-r border-r-on-surface-stroke': true, 'text-right': true })
        }
      >
        {lenderPaidAmount}
        <Typography className="text-on-surface-inactive" variant="caption">
          {value}
        </Typography>
      </td>
    );
  };

  const generateSourceIcon = (source: FeeSourceEnum, sourceMessage: string) => {
    switch (source) {
      case FeeSourceEnum.FEE_SOURCE_ENUM_MANUAL:
        return (
          <div className="relative">
            <Tooltip message={sourceMessage}>
              <Icon icon="Person" />
            </Tooltip>
          </div>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_INTEGRATION:
        return (
          <Tooltip message={sourceMessage}>
            <Icon icon="CloudSync" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_TRIGGERED:
        return (
          <Tooltip message={sourceMessage}>
            <Icon icon="Bolt" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_CALCULATED:
        return (
          <Tooltip message={sourceMessage}>
            <Icon icon="Calculate" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_MANUAL_OVERRIDE:
        return (
          <Tooltip message={sourceMessage}>
            <Icon icon="PersonEdit" />
          </Tooltip>
        );
      default:
        return '--';
    }
  };

  const totalColumn = (column: Column, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject): JSX.Element => {
    const renderTotal = () => {
      if (fee.manualOverrideIndicator && (!fee.manualOverrideTimeframe && !fee.manualOverrideTotalAmount)) {
        return String(convertProtoToCurrency(fee.manualOverrideTotalAmount));
      }

      if (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe) {
        return String(convertProtoToCurrency(fee.manualOverrideTotalAmount));
      }

      if (fee.manualOverrideIndicator && fee.manualOverrideTimeframe) {
        return String(convertProtoToCurrency(fee.total));
      }

      return String(convertProtoToCurrency(fee.calculatedTotalAmount));
    };

    return (
      <td
        key={`${column.id}-${fee.feeId}`}
        className="px-6 py-4 text-right border-r border-r-on-surface-stroke border-l border-l-on-surface-stroke w-[150px] align-top"
      >
        <div className="flex items-center justify-end">
          <span className="text-sm text-on-surface-active font-normal">
            {renderTotal()}
          </span>
        </div>
      </td>
    );
  };

  const dataSourceColumn = (column: Column, fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject): JSX.Element => {
    let value;
    if ('prepaidPayment' in fee) {
      value = fee.prepaidPayment?.prepaidSource;
    }
    if ('escrowPayment' in fee) {
      value = fee.escrowPayment?.escrowSource;
    }

    return (
      <td
        key={`${column.id}-${fee.sourceMessage}`}
        className="px-6 py-4 align-top"
      >
        <div className="flex items-center justify-center">
          {generateSourceIcon(value ?? 0, fee.sourceMessage)}
        </div>
      </td>
    );
  };

  const escrowsTable = (): JSX.Element => (
    <div className="relative overflow-x-auto">
      <table className="w-full table-fixed">
        <thead className="border-b border-b-on-surface-stroke">
          {tableHeaderRow}
        </thead>
        <tbody>
          {loanEscrowFees[0].feesList.map((fee: LoanEscrowFee.AsObject) => (
            <tr
              key={`table-row-${fee.feeId}`}
              className="transition duration-150 ease-in-out hover:bg-on-surface-states-hover border-b border-b-on-surface-stroke"
              id={`table-row-${fee.feeId}`}
            >
              {ESCROWS_PREPAIDS_COLUMNS.map((column) => {
                switch (column.id) {
                  case 'name': {
                    return (
                      <td
                        key={`table-row-cell-${column.id}`}
                        className="px-6 py-4 w-[450px] align-top border-r border-r-on-surface-stroke"
                      >
                        <FeeOverrideControls
                          categoryType={LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_ESCROW}
                          fee={fee}
                          id={`override-${fee.feeId}`}
                        />
                      </td>
                    );
                  }
                  case 'amount': {
                    return escrowAmountColumn(column, fee);
                  }
                  case 'borrowerPaid': {
                    return borrowerPaidColumn(column, fee);
                  }
                  case 'sellerPaid': {
                    return sellerPaidColumn(column, fee);
                  }
                  case 'lenderPaid': {
                    return lenderPaidColumn(column, fee);
                  }
                  case 'total': {
                    return totalColumn(column, fee);
                  }
                  case 'dataSource': {
                    return dataSourceColumn(column, fee);
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const escrowsCard = (): JSX.Element => {
    const hasEscrows = loanEscrowFees[0].feesList.length > 0;
    const escrowsDataTable = hasEscrows ? escrowsTable() : contingencyMessage();

    return (
      <Card
        key={loanEscrowFees[0].category}
        headerProps={{
          caption: 'Total',
          captionStrong: convertProtoToCurrency(loanEscrowFees[0].sumTotal),
          title: loanEscrowFees[0].category || '',
        }}
        variant="border"
      >
        {escrowsDataTable}
      </Card>
    );
  };

  const prepaidsTable = (): JSX.Element => (
    <div className="relative overflow-x-auto">
      <table className="w-full table-fixed">
        <thead className="border-b border-b-on-surface-stroke">
          {tableHeaderRow}
        </thead>
        <tbody>
          {loanPrepaidFees[0].feesList.map((fee: LoanPrepaidFee.AsObject) => (
            <tr
              key={`table-row-${fee.feeId}`}
              className="transition duration-150 ease-in-out hover:bg-on-surface-states-hover border-b border-b-on-surface-stroke"
              id={`table-row-${fee.feeId}`}
            >
              {ESCROWS_PREPAIDS_COLUMNS.map((column) => {
                switch (column.id) {
                  case 'name': {
                    return (
                      <td
                        key={`table-row-cell-${column.id}`}
                        className="px-6 py-4 w-[450px] align-top border-r border-r-on-surface-stroke"
                      >
                        <FeeOverrideControls
                          categoryType={LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID}
                          fee={fee}
                          id={`override-${fee.feeId}`}
                        />
                      </td>
                    );
                  }
                  case 'amount': {
                    return prepaidAmountColumn(column, fee);
                  }
                  case 'borrowerPaid': {
                    return borrowerPaidColumn(column, fee);
                  }
                  case 'sellerPaid': {
                    return sellerPaidColumn(column, fee);
                  }
                  case 'lenderPaid': {
                    return lenderPaidColumn(column, fee);
                  }
                  case 'total': {
                    return totalColumn(column, fee);
                  }
                  case 'dataSource': {
                    return dataSourceColumn(column, fee);
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const prepaidsCard = (): JSX.Element => {
    const hasPrepaids = loanPrepaidFees[0].feesList.length > 0;
    const prepaidsDataTable = hasPrepaids ? prepaidsTable() : contingencyMessage();

    return (
      <Card
        key={loanPrepaidFees[0].category}
        headerProps={{
          caption: 'Total',
          captionStrong: convertProtoToCurrency(loanPrepaidFees[0].sumTotal),
          title: loanPrepaidFees[0].category || '',
        }}
        variant="border"
      >
        {prepaidsDataTable}
      </Card>
    );
  };

  return (
    <div className="grid gap-y-6">
      {escrowsCard()}
      {prepaidsCard()}
    </div>
  );
};

export default EscrowPrepaidDetails;
