import type { LoanPageBanner } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Banner_pb';
import { LoanPageBannerType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Banner_pb';
import { TRIDBanner } from '@/Components/Features/TRIDBanner';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import { BannerMessage } from '../BannerMessage';

export type BannerRendererProps = { banner: LoanPageBanner.AsObject; defaultOpen?: boolean; displayVertical?: boolean };

export const BannerRenderer = (props: BannerRendererProps) => {
  const { banner, defaultOpen = false, displayVertical } = props;
  const { loanId } = useDynamicLoanInfo();

  switch (banner?.type) {
    case LoanPageBannerType.LOAN_PAGE_BANNER_TYPE_TRID:
      return <TRIDBanner defaultOpen={defaultOpen} displayVertical={displayVertical} loanId={loanId} />;
    case LoanPageBannerType.LOAN_PAGE_BANNER_TYPE_MESSAGE:
      return <BannerMessage message={banner.message} />;
    default:
      return null;
  }
};
