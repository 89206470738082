import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useEffect, useRef } from 'react';

import { GetOrganizationByIdQueryRequest, GetOrganizationByIdQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.users.GetOrganizationByIdQueryRequest';

const useOrganization = (id: string, refetchIntervalInBackground = false) => {
  const { commandResponse } = useBackend();

  const count = useRef(0);

  useEffect(() => {
    if (!refetchIntervalInBackground) {
      count.current = 0;
    }
  }, [refetchIntervalInBackground]);

  return useQuery([MESSAGE_NAME, id], async () => {
    count.current += 1;
    const request = new GetOrganizationByIdQueryRequest();
    request.setOrganizationId(id);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetOrganizationByIdQueryResponse();
    GetOrganizationByIdQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.organization);
  }, {
    refetchInterval: () => (count.current < 10 && refetchIntervalInBackground ? (count.current + 1) * 1000 : false),
    refetchIntervalInBackground: true,
  });
};
export { useOrganization };
