import clsx from 'clsx';
import { useEffect, useMemo } from 'react';

import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElement, DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useAsyncFieldOverride, useFieldOverride } from '@/Components/Features/dynamicForm/useFieldOverride';
import { ddeAreEqual, DynamicDataElementMap, fromPanelElementOption, fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

import type { TextFieldVariant } from '../TextField';
import { Typography } from '../Typography';
import Component from './AutoComplete.component';

export interface IAutoCompleteProps {
  panelElement: PanelElement.AsObject;
  showAsQuestion?: boolean;
  className?: string;
  variant?: TextFieldVariant;
}

const AutoComplete = (props: IAutoCompleteProps) => {
  const {
    className,
    panelElement: {
      disabled,
      headerText: placeholder,
      id,
      optionsList: options,
      order,
      path: panelElementPath,
      requirement,
      summaryText: helperText,
      type,
    },
    showAsQuestion,
    variant,
  } = props;

  const override = useFieldOverride(id);
  const asyncOverride = useAsyncFieldOverride(id);
  const optionsList = useMemo(() => {
    if (override && override?.optionsFilter) {
      return options.filter((opt) => opt?.value && override.optionsFilter!.some((x) => x.value === opt.value!.value));
    }
    if (asyncOverride) {
      return asyncOverride.optionsList;
    }
    return options;
  }, [override, options, asyncOverride]);

  const [value, setValue, { disabled: conditionallyDisabled, errorMessage, focused, hidden, ref }] = useDynamicFormRegister<PanelElementOption.AsObject | null>(
    id || 'id',
    {
      fromDynamic: (v) => {
        if (type === 'select') {
          return optionsList.find((option) => {
            const type = v.getDataType();
            if (type === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_ENUMERATION && option.value) {
              return ddeAreEqual(v, DynamicDataElementMap(option.value));
            }

            let optionFound = option.id === toString(v);

            if (!optionFound && option.value) {
              const val = new DynamicDataElement();
              val.setDataType(option.value?.dataType);
              val.setValue(option.value?.value);
              optionFound = toString(val) === toString(v);
            }

            return optionFound;
          }) || null;
        }
        return optionsList.find((option) => option.id === toString(v)) || null;
      },
      toDynamic: (selectedOption) => {
        if (type === 'select' && !selectedOption) {
          if (optionsList.length > 0 && optionsList[0].headerText === '--') return fromPanelElementOption(optionsList[0]);
          return fromString('');
        }
        if (type === 'select') return fromPanelElementOption(selectedOption);

        if (!selectedOption) return fromString(selectedOption);

        return fromString(selectedOption?.id || '');
      },
    },
    { order, panelElementType: type, requirement },
  );
  // auto-select option from async config call
  useEffect(() => {
    if (override && override.value) {
      if (override.value.dataType === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
        setValue(null, '');
      } else {
        const selectedOption = optionsList.find((e) => (e.value && override.value ? ddeAreEqual(DynamicDataElementMap(e.value), DynamicDataElementMap(override.value)) : false));
        if (selectedOption) setValue(selectedOption, selectedOption.headerText);
      }
    }
    if (asyncOverride && asyncOverride.value) {
      if (asyncOverride.value.dataType === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
        setValue(null, '');
      } else {
        const selectedOption = optionsList.find((e) => (e.value && asyncOverride.value ? ddeAreEqual(DynamicDataElementMap(e.value), DynamicDataElementMap(asyncOverride.value)) : false));
        if (selectedOption) setValue(selectedOption, selectedOption.headerText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [override, optionsList, asyncOverride]);

  const handleOnChange = (option?: PanelElementOption.AsObject) => {
    if (option) {
      const path = option.path || panelElementPath;
      setValue(option, option.headerText, path);
    } else {
      setValue(null, '', '');
    }
  };

  const handleAutoCompleteOnChange = (inputValue?: string) => {
    if (inputValue) {
      const foundOption = optionsList.find((option) => option.headerText === inputValue);
      const inputValueAsPanelElementOption = foundOption ?? getPanelElementOption({
        headerText: inputValue,
        id: 'inputValueAsPanelElementOption',
        value: fromString(inputValue).toObject(),
      });

      setValue(inputValueAsPanelElementOption, inputValue, '');
    } else {
      setValue(null, '', '');
    }
  };

  if (hidden) return null;
  return (
    <div className="flex justify-between gap-x-3 w-full">
      {showAsQuestion && (
        <Typography className="w-full text-on-surface-inactive" variant="body1">
          {placeholder}
        </Typography>
      )}
      <div className={clsx('w-full', { 'max-w-xs': showAsQuestion })}>
        <Component
          ref={ref}
          autoComplete="off"
          className={className}
          disabled={disabled || conditionallyDisabled || optionsList.length === 0}
          errorMessage={errorMessage}
          focused={focused}
          helperText={helperText}
          noAutoFocus={order === 1}
          onChangeInputValue={handleAutoCompleteOnChange}
          onSelect={handleOnChange}
          options={optionsList}
          placeholder={placeholder}
          required={requirement?.required}
          value={value?.headerText}
          variant={variant}
        />
      </div>
    </div>
  );
};

export default AutoComplete;
