import clsx from 'clsx';

import type { IChipProps } from '../Chip';
import { Chip } from '../Chip';

export interface ChipGroupItem extends IChipProps {
  [key: string]: any;
}

export interface ChipGroupProps {
  chips: Array<ChipGroupItem>;
  selected?: Array<number | string>;
  selectedKey?: keyof IChipProps;
  onSelectItem: (item: IChipProps, selected: boolean) => void;
  className?: string;
}

const ChipGroup = (props: ChipGroupProps) => {
  const { chips, className = '', onSelectItem, selected, selectedKey = 'id' } = props;

  return (
    <div className={clsx('flex flex-row max-w-full gap-2 overflow-x-auto', className)}>
      {
        chips.map((chip, index) => {
          const isSelected = chip.selected || selected?.some((item) => {
            if (typeof item === 'string') return item === chip[selectedKey];
            return item === index;
          }) || false;
          return (
            <Chip
              key={chip.label}
              {...chip}
              onClick={() => onSelectItem(chip, isSelected)}
              selected={isSelected}
            />
          );
        })
      }
    </div>
  );
};

export { ChipGroup };
