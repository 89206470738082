import { useCallback, useState } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DocType, DocumentCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useDocumentCategory } from '@/API/Queries/mortgage/useDocumentCategory';
import { Dialog } from '@/Components/Atoms/RadixDialog';

import { CreateDocumentCategory } from './CreateDocumentCategory';
import { DocumentCategoryCreateType } from './DocumentCategoryCreateType';
import { DocumentCategoryEditType } from './DocumentCategoryEditType';
import { UpdateDocumentCategory } from './UpdateDocumentCategory';

interface DocumentCategoryModalProps {
  documentCategoryId?: string;
  onClose: () => void;
}

export type DocumentCategoryModalView = 'create' | 'edit' | 'newType' | 'type';

const DocumentCategoryModal = ({
  documentCategoryId,
  onClose,
}: DocumentCategoryModalProps) => {
  const { data: documentCategory, isLoading, refetch } = useDocumentCategory(documentCategoryId || '');

  const [view, setView] = useState<DocumentCategoryModalView>(documentCategoryId ? 'edit' : 'create');
  const [typeId, setTypeId] = useState<string | undefined>('');
  const [docType, setDocType] = useState<DocType.AsObject>();
  const [assign, setAssign] = useState<boolean>();
  const [docTypes, setDocTypes] = useState<DocType.AsObject[]>([]);
  const [category, setCategory] = useState<PanelElementOption.AsObject>();
  const [createDocumentCategory, setDocumentCategory] = useState<Partial<DocumentCategory.AsObject>>({
    docTypesList: [],
    name: '',
    typesList: [],
  });

  const handleClose = useCallback((docType?: DocType.AsObject, category?: PanelElementOption.AsObject, assign?: boolean) => {
    if (['edit', 'create'].includes(view)) {
      onClose();
    } else {
      if (docType) {
        setDocTypes([...docTypes, docType]);
      }
      setView(documentCategoryId ? 'edit' : 'create');
      setCategory(category);
      setDocType(docType);
      setAssign(assign);
    }
  }, [docTypes, documentCategoryId, onClose, view]);

  const renderContent = () => {
    switch (view) {
      case 'create':
        return (
          <CreateDocumentCategory
            assign={assign}
            category={category}
            docType={docType}
            docTypes={docTypes}
            documentCategory={createDocumentCategory}
            onBack={handleClose}
            refetch={refetch}
            setDocType={setDocType}
            setDocTypes={setDocTypes}
            setDocumentCategory={setDocumentCategory}
            setView={setView}
          />
        );
      case 'edit':
        return (
          <UpdateDocumentCategory
            documentCategory={documentCategory}
            isLoading={isLoading}
            refetch={refetch}
            setTypeId={setTypeId}
            setView={setView}
          />
        );
      case 'type':
        return (
          <DocumentCategoryEditType
            categoryId={documentCategoryId || ''}
            onClose={handleClose}
            refetch={refetch}
            typeId={typeId}
          />
        );
      case 'newType':
        return (
          <DocumentCategoryCreateType
            category={category}
            onClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      onOpenChange={onClose}
      open
    >
      {renderContent()}
    </Dialog>
  );
};

export { DocumentCategoryModal };
