import type { CardListItemField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem_pb';
import type { FormField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { DealWriteCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { FullPageSaveItem, SaveFieldItem, TogglableWidgetPageSaveCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicPageSave } from '@/API/Queries/mortgageDynamicData/useDynamicPageSave';
import { usePutFormWidgetData } from '@/API/Queries/mortgageDynamicData/usePutFormWidgetData';
import { usePutSectionWidgetData } from '@/API/Queries/mortgageDynamicData/usePutSectionWidgetData';
import { useSaveSubmitForm } from '@/API/Queries/mortgageDynamicData/useSaveSubmitForm';
import { useTogglableWidgetSave } from '@/API/Queries/mortgageDynamicData/useTogglableWidgetSave';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWidgetContext } from './WidgetContext';

export type SaveConfigType = 'cardListItem' | 'editableTable' | 'form' | 'getQualified' | 'page' | 'togglable';
export interface SaveConfig {
  pageId: string;
  scopeToken: string;
  widgetId?: string;
  loanId: string;
  saveAndNew?: boolean;
  widgets?: Array<LoanPageWidget.AsObject>;
  isNew?: boolean;
  isLast?: boolean;
  type: SaveConfigType;
  hiddenFieldIds?: Array<string>;
  conditionalWidgetSaving?: boolean;
  togglableConfig?: Array<{ scopeToken: string; id: string; createNew: boolean; fields: FormField.AsObject[] }>;
}

interface Options {
  onError?: (error: any) => void;
  onSuccess: (response: DealWriteCommandResponse.AsObject) => void;
}

interface WidgetData {
  values: DynamicDataElementValues;
  id?: string;
  scopeToken?: string;
  widgetProps: LoanPageWidget.AsObject;
}

export const getWidgetDataFormValues = (widgets: LoanPageWidget.AsObject[], values: DynamicDataElementValues, virtualFields: boolean): WidgetData[] => widgets.map((widget) => {
  const reduceValues = (acc: any, field: CardListItemField.AsObject | FormField.AsObject) => {
    const fieldId = field.id.split('field:').pop();
    const id = (virtualFields ? field.panelElement?.virtualFieldIdentifier?.value : fieldId) || '';

    if (!field.panelElement) return acc;
    return {
      ...acc,
      [id]: values[id],
    };
  };

  const processedValues = {
    ...widget.cardListItem?.fieldsList.reduce(reduceValues, {}) || {},
    ...widget.form?.fieldsList.reduce(reduceValues, {}) || {},
    ...widget.collectionForm?.panelElementsList.reduce((acc, panelElement) => {
      const id = (virtualFields ? panelElement?.virtualFieldIdentifier?.value : panelElement.id) || '';

      if (!panelElement) return acc;
      return {
        ...acc,
        [id]: values[id],
      };
    }, {}) || {},
  };

  // const { hiddenFieldIds } = getConditionalFieldIds(widget.cardListItem?.showFieldValidationsList || widget.form?.showFieldValidationsList || [], processedValues);

  return {
    id: widget.id,
    scopeToken: widget.cardListItem?.scopeToken || '',
    values: processedValues,
    widgetProps: widget,
  };
});

export const useSaveWidget = () => {
  const bpdId = useBPDId();
  const { version } = useDynamicContext();

  const { mutate: saveCardListItem } = usePutSectionWidgetData();
  const { mutateAsync: savePage } = useDynamicPageSave();
  const { mutate: saveForm } = usePutFormWidgetData();
  const { mutate: saveTogglable } = useTogglableWidgetSave();
  const { mutate: saveSubmitForm } = useSaveSubmitForm();
  const { addSavingWidget, removeSavingWidget, setSavingWidgetIds } = useWidgetContext();

  const sendCardListItem = (values: DynamicDataElementValues, pageConfig: SaveConfig, options: Options) => {
    const itemsToSave = new Array<SaveFieldItem>();
    const { hiddenFieldIds = [] } = pageConfig;
    Object.keys(values).forEach((fieldId) => {
      if (
        pageConfig?.widgets && pageConfig?.widgets[0].cardListItem?.fieldsList.some((field) => field.id === fieldId) // filter fields only on the current submit
        && !hiddenFieldIds.includes(fieldId) // filter hidden fields
        && values[fieldId] !== null && typeof values[fieldId]?.dynamic !== 'undefined' // filter null items
      ) {
        const fsr = new SaveFieldItem();
        fsr.setFieldId(fieldId || '');
        fsr.setValue(values[fieldId].dynamic);
        itemsToSave.push(fsr);
      }
    });
    addSavingWidget(pageConfig.widgetId || '');

    return saveCardListItem(
      {
        businessProcessDomainId: bpdId,
        dealId: pageConfig.loanId,
        dealVersion: version || 0,
        itemsList: itemsToSave,
        pageId: pageConfig.pageId,
        scopeToken: pageConfig.scopeToken,
        widgetId: pageConfig.widgetId || '',
      },
      {
        onError: options.onError,
        onSuccess: (response) => {
          if (options) options.onSuccess(response);
          removeSavingWidget(pageConfig.widgetId || '');
        },
      },
    );
  };

  const handleSavePage = (values: DynamicDataElementValues, saveConfig: SaveConfig, options: Options) => {
    const { isNew, pageId, scopeToken, widgets = [], loanId } = saveConfig;
    const widgetData = getWidgetDataFormValues(widgets || [], values, false);
    setSavingWidgetIds(widgets.map((w) => w.id));
    const itemsList: FullPageSaveItem[] = widgetData.map((data) => {
      const item = new FullPageSaveItem();
      item.setWidgetId(data.id || '');
      item.setWidgetScopeToken(data.scopeToken || scopeToken);
      const saveItems = Object.keys(data.values)
        .filter((fieldId) => (data.values[fieldId] && data.values[fieldId].dynamic ? data.values[fieldId].dynamic.getDataType() !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL : false))
        .map((fieldId) => {
          const saveItem = new SaveFieldItem();
          if (fieldId.includes('@')) {
            saveItem.setFieldId(fieldId.split('@')[1]);
          } else {
            saveItem.setFieldId(fieldId);
          }
          saveItem.setValue(data.values[fieldId].dynamic);
          return saveItem;
        });
      item.setItemsList(saveItems);
      return item;
    });
    return savePage({
      createNew: isNew || false,
      dealId: loanId,
      dealVersion: version,
      itemsList,
      pageId,
    }, { onError: options.onError, onSuccess: options.onSuccess });
  };

  const handleSaveForm = async (values: DynamicDataElementValues, saveConfig: SaveConfig, options: Options) => {
    const itemsToSave = new Array<SaveFieldItem>();
    const { hiddenFieldIds = [] } = saveConfig;
    Object.keys(values).forEach((fieldId) => {
      if (!hiddenFieldIds.includes(fieldId) && typeof values[fieldId]?.dynamic !== 'undefined') {
        const fsr = new SaveFieldItem();
        fsr.setFieldId(fieldId);
        fsr.setValue(values[fieldId].dynamic);
        itemsToSave.push(fsr);
      }
    });
    return saveForm({
      businessProcessDomainId: bpdId,
      dealId: saveConfig.loanId,
      dealVersion: version || 0,
      itemsList: itemsToSave,
      pageId: saveConfig.pageId,
      scopeToken: saveConfig.scopeToken,
      widgetId: saveConfig.widgetId || '',
    }, { onError: options.onError, onSuccess: options.onSuccess });
  };

  const handleTogglableWidget = (values: DynamicDataElementValues, saveConfig: SaveConfig, options: Options) => {
    const items: Array<TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem> = [];
    saveConfig.togglableConfig?.forEach((togglableConfigItem) => {
      const item = new TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem();
      item.setCreateNew(togglableConfigItem.createNew);
      const saveItemsList: Array<SaveFieldItem> = [];
      togglableConfigItem.fields.forEach((field) => {
        const fieldValue = values[field.id];
        if (fieldValue && fieldValue.dynamic) {
          const saveItem = new SaveFieldItem();
          saveItem.setFieldId(field.id);
          saveItem.setValue(fieldValue.dynamic);
          saveItem.setConsolidatedItemScopeToken(field.consolidatedItemScopeToken);
          saveItemsList.push(saveItem);
        }
      });
      item.setItemsList(saveItemsList);
      item.setWidgetId(togglableConfigItem.id);
      item.setWidgetScopeToken(togglableConfigItem.scopeToken);
      items.push(item);
    });
    return saveTogglable(
      {
        dealId: saveConfig.loanId,
        dealVersion: version,
        itemsList: items,
        pageId: saveConfig.pageId,
      },
      { onError: options.onError, onSuccess: options.onSuccess },
    );
  };

  const handleSubmitGetQualified = (values: DynamicDataElementValues, saveConfig: SaveConfig, options: Options) => {
    const itemsToSave = new Array<SaveFieldItem>();
    const { hiddenFieldIds = [] } = saveConfig;
    Object.keys(values).forEach((fieldId) => {
      if (!hiddenFieldIds.includes(fieldId) && typeof values[fieldId]?.dynamic !== 'undefined') {
        const fsr = new SaveFieldItem();
        fsr.setFieldId(fieldId);
        fsr.setValue(values[fieldId].dynamic);
        itemsToSave.push(fsr);
      }
    });

    return saveSubmitForm({
      bpdId,
      dealId: saveConfig.loanId,
      dealVersion: version,
      itemsList: itemsToSave,
      pageId: saveConfig.pageId,
      scopeToken: saveConfig.scopeToken,
      widgetId: saveConfig.widgetId || '',
    },
    { onError: options.onError, onSuccess: options.onSuccess });
  };

  const handleEditableTable = (values: DynamicDataElementValues, saveConfig: SaveConfig, options: Options) => {
    const { isNew, loanId, pageId, widgetId: id = '' } = saveConfig;
    const widgetKey = Object.keys(values).find((key) => {
      const [, , widgetId] = key.split('@');
      if (widgetId === id) {
        const value = values[key].dynamic;
        return Boolean(value);
      }
      return false;
    });
    const item = new FullPageSaveItem();
    if (widgetKey) {
      item.setWidgetId(id);
      const itemsList = Object.keys(values).reduce((prev: Array<SaveFieldItem>, key) => {
        const [isEditable, , widgetId, , fieldId] = key.split('@');
        if (isEditable === 'editable' && widgetId === id && values[key].dynamic) {
          const item = new SaveFieldItem();
          item.setFieldId(fieldId);
          item.setValue(values[key].dynamic);
          return [...prev, item];
        }
        return prev;
      }, []);
      const currentScopeToken = widgetKey?.split('@')[3];
      item.setWidgetScopeToken(currentScopeToken || '');
      item.setItemsList(itemsList);
    }
    return savePage({
      createNew: isNew || false,
      dealId: loanId,
      dealVersion: version,
      itemsList: [item],
      pageId,
    }, { onError: options.onError, onSuccess: options.onSuccess });
  };

  return (
    values: DynamicDataElementValues,
    saveConfig: SaveConfig,
    options: Options,
  ) => {
    if (saveConfig.type === 'cardListItem') return sendCardListItem(values, saveConfig, options);
    if (saveConfig.type === 'page') return handleSavePage(values, saveConfig, options);
    if (saveConfig.type === 'form') return handleSaveForm(values, saveConfig, options);
    if (saveConfig.type === 'togglable') return handleTogglableWidget(values, saveConfig, options);
    if (saveConfig.type === 'getQualified') return handleSubmitGetQualified(values, saveConfig, options);
    if (saveConfig.type === 'editableTable') return handleEditableTable(values, saveConfig, options);
    return () => { };
  };
};
