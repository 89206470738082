import { useMemo, useState } from 'react';

import { DocumentCategoryEnum, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useDocumentCategories } from '@/API/Queries/mortgage/useDocumentCategories';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

import { DocumentCategoryModal } from './DocumentCategoryModal';

export interface TableItem {
  id: string;
  category: string;
  name: string;
  lastEdited: string;
}

const DocumentCategoriesList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');
  const [documentCategoryId, setDocumentCategoryId] = useState<string | undefined>();

  const { data: documentCategories = [], isLoading, refetch } = useDocumentCategories();

  const columns = useMemo((): Array<Column<TableItem>> => [
    {
      header: 'Document Category',
      id: 'name',
      key: {
        text: 'name',
        type: 'text',
      },
    },
    {
      header: 'Last Edited',
      id: 'lastEdited',
      key: {
        text: 'lastEdited',
        type: 'text',
      },
    },
    {
      header: 'Document Types',
      id: 'list',
      key: {
        children: 'list',
        type: 'generic',
      },
    },
  ], []);

  const data = useMemo(() => {
    let data = documentCategories.map((document) => {
      const { docTypesList } = document;
      let list = docTypesList.length > 0 ? ConvertEnum(DocumentEnum, docTypesList[0].docType) : '--';

      if (docTypesList.length === 2) {
        list += ` & ${ConvertEnum(DocumentEnum, docTypesList[1].docType)}`;
      }

      if (docTypesList.length > 2) {
        list += ` + ${docTypesList.length - 1}`;
      }

      const lastEditedDate = getDateFromTimestamp(document.lastEditedDate);

      return {
        category: ConvertEnum(DocumentCategoryEnum, document.categoryType),
        id: document.id,
        lastEdited: lastEditedDate !== null ? lastEditedDate.toLocaleString() : '',
        list,
        name: document.name,
      };
    });

    if (search) {
      data = data.filter((document) => document.name.toLowerCase().includes(search.toLowerCase()) || document.category.toLowerCase().includes(search.toLowerCase()));
    }

    return data;
  }, [documentCategories, search]);

  const onCloseModal = () => {
    setOpenModal(false);
    refetch();
  };

  const handleClickEdit = (document: TableItem) => {
    setDocumentCategoryId(document.id);
    setOpenModal(true);
  };

  const handleClickCreate = () => {
    setDocumentCategoryId(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: handleClickCreate }],
            searchProps: { onChange: setSearch, value: search },
            title: 'All Categories',
          },
        }}
        columns={columns}
        data={data}
        hoverActions={[
          {
            label: 'Edit',
            onClick: handleClickEdit,
          },
        ]}
        isLoading={isLoading}
        width="stretch"
      />
      {openModal && <DocumentCategoryModal documentCategoryId={documentCategoryId} onClose={onCloseModal} />}
    </>

  );
};

export { DocumentCategoriesList };
