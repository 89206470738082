import clsx from 'clsx';
import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useEditDesignerPanel } from '@/API/Queries/activities/useEditDesignerPanel';
import { Icon } from '@/Components/Atoms/Icon';
import { InlineInputTextEditor } from '@/Components/Atoms/InlineInputTextEditor';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

import { useActivityConfiguratorContext } from '../../activityConfiguratorContext';

interface PanelDropAreaProps {
  panelIndex: number;
  panel: Panel.AsObject;
  onClickPanelElement: (panelIndex: number, panelElementIndex: number) => void;
  handleDeleteModal: (panelElementIndex: number) => void;
}

const PanelDropArea = (props: PanelDropAreaProps) => {
  const { handleDeleteModal, onClickPanelElement, panel, panelIndex } = props;
  const {
    designerPanel: { runtime },
    selectedPanel,
    selectedPanelElement,
    setSelectedPanel,
  } = useActivityConfiguratorContext();
  const { mutate: editDesignerPanel } = useEditDesignerPanel();

  const [panelTitle, setPanelTitle] = useState(panel.headerText || 'Add page title');
  const [panelDescription, setPanelDescription] = useState(panel.summaryText || 'Description (optional)');

  const handleSaveDesignerPanel = () => {
    editDesignerPanel({
      headerText: panelTitle,
      modalText: '',
      panelId: panel.id,
      summaryText: panelDescription,
    });
  };

  return (
    <div className={clsx(
      'border border-transparent', {
        'border border-primary': panelIndex === selectedPanel,
      },
    )}
    >
      <button className="p-10 relative w-full" onClick={() => setSelectedPanel(panelIndex)} type="button">
        <div className="mb-4">
          <InlineInputTextEditor
            onBlur={handleSaveDesignerPanel}
            onChange={({ target: { value } }) => setPanelTitle(value)}
            placeholder="Click to edit"
            textVariant="font-four font-semibold text-2xl leading-9"
            value={panelTitle}
          />
        </div>

        <InlineInputTextEditor
          onBlur={handleSaveDesignerPanel}
          onChange={({ target: { value } }) => setPanelDescription(value)}
          placeholder="Click to edit"
          textVariant="text-on-surface-active text-sm"
          value={panelDescription}
        />
        {
          runtime[panelIndex].showNextModalConfirmationText
          && (
            <Tooltip className="absolute top-0 right-0 m-4" message="Help modal text">
              <Icon icon="Help" />
            </Tooltip>
          )
        }
      </button>
      <Droppable droppableId={panel.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef.bind(this)}
            className="p-10"
          >
            <div className={
              clsx(
                'flex items-center justify-center flex-wrap min-h-[8px] p-10',
                {
                  'bg-primary-on-surface-states-pressed border-dashed border-2 border-primary rounded': snapshot.isDraggingOver,
                },
              )
            }
            >
              <DynamicFormProvider onSubmit={() => {}}>
                {
                  runtime[panelIndex].panelElementsList.map(
                    (panelElement, componentIndex) => (
                      <Draggable
                        key={panelElement.id}
                        draggableId={panelElement.id}
                        index={componentIndex}
                      >
                        {(provided) => (
                          <div className="relative w-full mb-6 flex flex-row items-center">
                            <div
                              {...provided.dragHandleProps}
                              className={
                              clsx(
                                {
                                  'opacity-0': snapshot.isDraggingOver.toString() === 'true',
                                },
                              )
                            }
                            >
                              <Icon
                                className="mr-1"
                                icon="DragIndicator"
                                variant="interactive"
                              />
                            </div>

                            <button
                              ref={provided.innerRef.bind(this)}
                              className={clsx(
                                'bg-white border-2 border-transparent shadow-two cursor-pointer p-3 relative w-full',
                                {
                                  'border-2 border-primary': componentIndex === selectedPanelElement && panelIndex === selectedPanel,
                                },
                              )}
                              type="button"
                              {...provided.draggableProps}
                              onClick={() => onClickPanelElement(panelIndex, componentIndex)}
                            >
                              <PanelElementRenderer variant="default" {...panelElement} disabled />
                            </button>
                            <Icon
                              className="ml-1"
                              icon="Delete"
                              onClick={() => handleDeleteModal(componentIndex)}
                              variant="interactive"
                            />
                          </div>
                        )}
                      </Draggable>
                    ),
                  )
              }
              </DynamicFormProvider>

            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};

export { PanelDropArea };
