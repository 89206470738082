import clsx from 'clsx';
import type { FC } from 'react';

import * as Icons from '@/Components/Atoms/Icons';

const DynamicIcon: FC<{ icon: string; className?: string }> = ({ className, icon }) => {
  const { ...icons }: any = Icons;

  const iconCname = clsx(
    className,
  );

  if (icon === '' || !icons.hasOwnProperty(icon)) return null;

  const Icon: React.ElementType = icons[icon];

  return (
    <Icon className={iconCname} />
  );
};

export { DynamicIcon };
