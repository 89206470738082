import { useParams } from 'react-router';

import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useLenderConditionTrigger } from '@/API/Queries/mortgageConfig/useLenderConditionTrigger';
import { useUpdateLenderConditionTriggers } from '@/API/Queries/mortgageConfig/useUpdateLenderConditionTriggers';
import { Trigger } from '@/Components/Features/trigger/Trigger';

const ConditionTrigger = () => {
  const { id = '' } = useParams();
  const { data, isLoading, refetch } = useLenderConditionTrigger(id);
  const { mutate } = useUpdateLenderConditionTriggers();

  const onUpdateTrigger = (trigger: RuleAggregatorSummary.AsObject) => {
    const updateObject = {
      conditionId: id,
      trigger,
    };
    mutate(updateObject, { onSuccess: () => refetch });
  };

  return (
    <Trigger
      addDefaultAutomatically={false}
      isLoading={isLoading}
      onUpdateTrigger={onUpdateTrigger}
      title="This condition gets triggered if the following data is collected"
      trigger={data}
    />
  );
};

export { ConditionTrigger };
