// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../../../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FormField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FormField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FormField.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FormField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FormField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FormField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.repeatedFields_ = [3,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.FormField.toObject, includeInstance),
    helpText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    renderType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hyperLinksList: jspb.Message.toObjectList(msg.getHyperLinksList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.toObject, includeInstance),
    actionsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    numberOfColumns: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FormField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FormField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (reader.readEnum());
      msg.setRenderType(value);
      break;
    case 9:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.deserializeBinaryFromReader);
      msg.addHyperLinks(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActions(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FormField.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRenderType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getHyperLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getNumberOfColumns();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FormField fields = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormField>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FormField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FormField, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.FormField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string help_text = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional CustomRenderType render_type = 6;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getRenderType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setRenderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated HyperLink hyper_links = 9;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getHyperLinksList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setHyperLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.addHyperLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.api.mortgage_dynamic_data.HyperLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.clearHyperLinksList = function() {
  return this.setHyperLinksList([]);
};


/**
 * repeated SectionAction actions = 10;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getActionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setActionsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionAction} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.addActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional int32 number_of_columns = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.getNumberOfColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.prototype.setNumberOfColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FormField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    helpText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    consolidatedItemScopeToken: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hyperLinksList: jspb.Message.toObjectList(msg.getHyperLinksList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.toObject, includeInstance),
    hasChild: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FormField;
  return proto.wilqo.api.mortgage_dynamic_data.FormField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsolidatedItemScopeToken(value);
      break;
    case 9:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.deserializeBinaryFromReader);
      msg.addHyperLinks(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FormField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getConsolidatedItemScopeToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHyperLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.serializeBinaryToWriter
    );
  }
  f = message.getHasChild();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string leading_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string help_text = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 4;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool hidden = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool read_only = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string consolidated_item_scope_token = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getConsolidatedItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setConsolidatedItemScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated HyperLink hyper_links = 9;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getHyperLinksList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setHyperLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.addHyperLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.api.mortgage_dynamic_data.HyperLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.clearHyperLinksList = function() {
  return this.setHyperLinksList([]);
};


/**
 * optional bool has_child = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.getHasChild = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormField.prototype.setHasChild = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iteratorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    formsSettingsList: jspb.Message.toObjectList(msg.getFormsSettingsList(),
    proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIteratorId(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.deserializeBinaryFromReader);
      msg.addFormsSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIteratorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFormsSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iterator_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.getIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.setIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FormGroupWidgetSettings forms_settings = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.getFormsSettingsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.setFormsSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.addFormsSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings.prototype.clearFormsSettingsList = function() {
  return this.setFormsSettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupTitleLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupTitleValue: (f = msg.getGroupTitleValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    groupTitlePanelElement: (f = msg.getGroupTitlePanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    form: (f = msg.getForm()) && proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.toObject(includeInstance, f),
    groupVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupTitleLabel(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setGroupTitleValue(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setGroupTitlePanelElement(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupVirtualFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupTitleLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupTitleValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getGroupTitlePanelElement();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getGroupVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_title_label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getGroupTitleLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setGroupTitleLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue group_title_value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getGroupTitleValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setGroupTitleValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.clearGroupTitleValue = function() {
  return this.setGroupTitleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.hasGroupTitleValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.PanelElement group_title_panel_element = 6;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getGroupTitlePanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setGroupTitlePanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.clearGroupTitlePanelElement = function() {
  return this.setGroupTitlePanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.hasGroupTitlePanelElement = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FormWidgetSettings form = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.hasForm = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string group_virtual_field_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.getGroupVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormGroupWidgetSettings.prototype.setGroupVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
