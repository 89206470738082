import { useMemo, useState } from 'react';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { IndividualFeePayment, LoanFee, LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  FeeCalculationTypeEnum,
  FeeEnum,
  FeePercentBasisEnum,
  FeeSourceEnum,
  FeeToleranceCategoryType,
  IntegratedDisclosureSectionEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { convertUxEnum } from '@/Utils/Helpers/getUxStatus';
import { convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { createListItems, handleAdditionalInfoSubmit } from '../../../helpers/individualFeesHelper';
import { FeePaidBy } from './FeePaidBy';
import { FeePaidTo } from './FeePaidTo';

interface IndividualFeeEditProps {
  clearFee: VoidFunction;
  fee: LoanFee.AsObject;
  categoryType: LoanFeeCategoryTypeEnum;
  isChipHidden?: (inputString: string) => boolean;
  feeSectionType: IntegratedDisclosureSectionEnum;
}

const additionalInfo = [
  { enumType: FeeCalculationTypeEnum, id: 'feeCalculationType', label: 'Fee Calculation Type' },
  { enumType: FeePercentBasisEnum, id: 'feePercentBasisType', label: 'Fee Percent Basis Type' },
  { enumType: IntegratedDisclosureSectionEnum, id: 'integratedDisclosureSectionType', label: 'Integrated Disclosure Section', type: 'select' },
  { enumType: FeeToleranceCategoryType, id: 'feeToleranceCategoryType', label: 'Default Fee Tolerance Category', type: 'select' },
  { enumType: FeeEnum, id: 'feeType', label: 'Fee Type', type: 'select' },
  { field: 'regulationZPointsAndFeesIndicator', id: 'regulationZPointsAndFees', label: 'Regulation Z Points and Fees', type: 'switch' },
  { field: 'feeSpecifiedFixedAmount', id: 'feeSpecifiedFixedAmount', label: 'Fee Specified Fixed Amount' },
  { field: 'feeTotalPercent', id: 'feeTotalPercent', label: 'Fee Total Percent' },
  { enumType: FeeSourceEnum, id: 'feeSource', label: 'Fee Source Type' }];

const enabledFields = ['feeType', 'feeToleranceCategoryType', 'regulationZPointsAndFees', 'integratedDisclosureSectionType'];

const IndividualFeeEdit = (props: IndividualFeeEditProps) => {
  const { categoryType, clearFee, fee, feeSectionType } = props;
  const { feePermissions, totalMet } = useFeesContext();
  const { feePayment: currentFeePayment, total } = fee;
  const emptyValue = numberFormatter.format(0);
  const { isMobile } = useWindow();
  const [errorState, setErrorState] = useState<string[]>([]);
  const [onEditAdditionalInfo, setOnEditAdditionalInfo] = useState(false);
  const [feePayment, setFeePayment] = useState<IndividualFeePayment.AsObject | undefined>(currentFeePayment);
  const [defaultTolerance, setDefaultTolerance] = useState<boolean>(feePayment?.providerSelectedFromLenderListIndicator || false);

  const addError = (error: string) => {
    setErrorState((prevErrors) => [...prevErrors, error]);
  };
  const removeError = (error: string) => {
    setErrorState((prevErrors) => prevErrors.filter((e) => e !== error));
  };

  const listItems = useMemo(() => createListItems(
    feePayment, additionalInfo, enabledFields,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [feePayment, defaultTolerance]);

  const updateDefaultTolerance = (flag: boolean): void => {
    if (feePayment && feeSectionType === IntegratedDisclosureSectionEnum.INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CAN_SHOP_FOR) {
      if (flag) {
        feePayment.feeToleranceCategoryType = FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT;
      } else {
        feePayment.feeToleranceCategoryType = feePayment.defaultFeeToleranceCategoryType || FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT;
      }
      setDefaultTolerance(flag);
    }
  };

  return (
    <DialogLayout>
      <DialogHeader
        icon={totalMet.exceeded || errorState.length > 0 ? 'Error' : 'ArrowBack'}
        iconAction={totalMet.exceeded || errorState.length > 0 ? () => null : clearFee}
        title={fee.name}
      />
      <DialogContent>
        <div className="flex flex-col gap-6 h-full w-full p-12 overflow-auto">
          <div className="w-min">
            <InfoCard
              caption={totalMet.exceeded || errorState.length > 0 ? totalMet.message : 'TOTAL FEE AMOUNT'}
              isMobile={isMobile}
              value={convertProtoToCurrency(total) || emptyValue}
              variant={convertUxEnum(totalMet.exceeded || errorState.length > 0 ? UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER : UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW)}
            />
          </div>
          <FeePaidBy
            addError={addError}
            categoryType={categoryType}
            errorState={errorState}
            fee={fee}
            feePayment={feePayment}
            isChipHidden={props.isChipHidden}
            removeError={removeError}
            total={ConvertProtoDecimalAsObjectToNumber(total)}
          />
          <FeePaidTo
            feePayment={feePayment}
            feeSectionType={feeSectionType}
            isAdditionalInformationEditing={onEditAdditionalInfo}
            updateDefaultTolerance={updateDefaultTolerance}
          />
          <CardListItem
            headerProps={{ title: 'Additional info' }}
            hideActions={!feePermissions?.editFeeDetailValue}
            isEditable
            isLoading={false}
            listItems={listItems}
            onEditing={setOnEditAdditionalInfo}
            onSubmit={handleAdditionalInfoSubmit(enabledFields, additionalInfo, feePayment, setFeePayment)}
            revertEmphasis
            variant="border"
          />
        </div>
      </DialogContent>
    </DialogLayout>
  );
};

export default IndividualFeeEdit;
