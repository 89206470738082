import clsx from 'clsx';
import { useEffect, useRef } from 'react';

import { CustomRenderType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { AggregatedTable } from '@/Components/Widgets/content/AggregatedTable';
import { IntegrationHistory } from '@/Routes/Pages/loan/IntegrationHistory';
import { ChangeHistoryDetails } from '@/Routes/Pages/loan/PurposeBuilt/ChangeHistoryDetails';

import { FusionCircuitComponent } from '../Circuits/Content/FusionCircuitComponent';
import { CardListItemWidget } from './content/CardListItemWidget';
import { EditableTable } from './content/EditableTable';
import { FormFlowWidget } from './content/FormFlowWidget';
import { FormWidget } from './content/FormWidget';
import { GroupedTable } from './content/GroupedTable';
import { HMDA } from './content/HMDA/HMDA';
import { InfoCardWidget } from './content/InfoCardWidget';
import { MessageCopy } from './content/MessageCopy';
import { MessageWidget } from './content/MessageWidget';
import { MosaicContainer } from './content/MosaicContainer';
import { ActivityTableCollection } from './content/NestedCollection/ActivityTableCollection';
import { NestedCollection } from './content/NestedCollection/NestedCollection';
import { TableCollection } from './content/TableForm/TableCollection';
import { TabsWidget } from './content/TabWidget';

interface Props {
  widgetList: Array<LoanPageWidget.AsObject>;
  allowPrompt?: boolean;
  className?: string;
}

export const WidgetRenderer = (props: Props) => {
  const { hiddenWidgetIds, setFieldGrouping } = useDynamicForm();

  const hasLoadFieldGroupings = useRef(false);
  useEffect(() => {
    const allFieldGroupings = props.widgetList.reduce((prev, widget) => {
      if (widget.fieldGrouping) return { ...prev, ...widget.fieldGrouping };
      return prev;
    }, {});
    if (!hasLoadFieldGroupings.current && Object.keys(allFieldGroupings).length > 0) {
      setFieldGrouping(allFieldGroupings);
      hasLoadFieldGroupings.current = true;
    }
  }, [props, setFieldGrouping]);

  const renderWidget = (widget: LoanPageWidget.AsObject, allowPrompt?: boolean) => {
    if (widget.messagePanel) return <MessageWidget {...widget.messagePanel} />;
    if (widget.editableTable) return <EditableTable {...widget.editableTable} id={widget.id} />;
    if (widget.aggregatedTable) return <AggregatedTable {...widget} id={widget.id} />;
    if (widget.groupedTableForm) return <GroupedTable key={widget.id} {...widget} groupedTableForm={widget.groupedTableForm} />;
    if (widget.tableForm && widget.tableForm.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_ACTIVITY_TABLE_FORM) return <ActivityTableCollection key={widget.id} {...widget.tableForm} />;
    if (widget.tableForm) return <TableCollection key={widget.id} validationMessages={widget.validationMessagesList} {...widget.tableForm} contingency={widget.contingency} />;
    if (widget.cardListItem) return <CardListItemWidget key={widget.id} allowPrompt={allowPrompt} {...widget} />;
    if (widget.form && widget.form.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_FORM_INTERVIEW) return <FormFlowWidget key={widget.id} {...widget} />;
    if (widget.form && widget.form.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_HMDA) return <HMDA allowPrompt={allowPrompt} {...widget} />;
    if (widget.form) return <FormWidget key={widget.id} allowPrompt={allowPrompt} {...widget} />;
    if (widget.relatedNestingContent) return <NestedCollection key={widget.id} nestedContentList={widget.relatedNestingContent.nestedContentList} />;
    if (widget.changeHistory) return <ChangeHistoryDetails key={widget.id} />;
    if (widget.integrationsHistory) return <IntegrationHistory key={widget.id} hideBreadcrumbs options={widget.integrationsHistory.statusField?.panelElement?.optionsList} progressId={widget.integrationsHistory.progressItemTemplateId} />;
    if (widget.tabs) return <TabsWidget key={widget.id} allowPrompt={allowPrompt} className={props.className} tabsWidgetSettings={widget.tabs} />;
    if (widget.mosaicWidgetContainer) return <MosaicContainer key={widget.id} {...widget.mosaicWidgetContainer} />;
    if (widget.infoCard) return <InfoCardWidget key={widget.id} {...widget.infoCard} />;
    if (widget.dynamicText) return <MessageCopy key={widget.id} {...widget.dynamicText} />;
    if (widget.fusionWidget) {
      return (
        <FusionCircuitComponent
          key={widget.id}
          fusionWidget={widget.fusionWidget}
          isLender
        />
      );
    }
    return null;
  };

  return (
    <div className={clsx('pb-6 flex flex-col gap-y-4 tablet:gap-y-6 w-full')}>
      {props.widgetList
        .filter((widget) => !hiddenWidgetIds.includes(widget.id))
        .map((widget) => (
          <div
            key={`widget-${widget.id}`}
            className={clsx('flex flex-col max-w-full', {
              'gap-6 px-4 tablet:px-8 desktop:px-6': !widget.tabs,
            })}
            id={`widget-${widget.id}`}
          >
            {renderWidget(widget, props.allowPrompt)}
          </div>
        ))}
    </div>
  );
};
