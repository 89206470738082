import { useState } from 'react';

import { Dialog } from './Dialog';
import { Icon } from './Icon';

interface ITextModalProps {
  title: string;
  text: string;
}

const TextModal = (props: ITextModalProps) => {
  const { text, title } = props;
  const [openModal, setOpenModal] = useState(false);

  const renderModal = () => openModal && (
    <Dialog
      actions={[
        { label: 'Got it', onClick: () => setOpenModal(false), type: 'button' },
      ]}
      onClickDismiss={() => setOpenModal(false)}
      title="Detailed information"
    >
      <span className="text-on-surface-active">{text}</span>
    </Dialog>
  );

  return (
    <>
      <span
        className="items-center text-on-surface-inactive cursor-pointer flex my-[10px] mx-0"
        onClick={() => setOpenModal(true)}
        onKeyDown={() => setOpenModal(true)}
        role="button"
        tabIndex={0}
      >
        {title}
        <Icon className="text-on-surface-inactive text-[22px] ml-[10px]" icon="Help" />
      </span>
      {renderModal()}
    </>
  );
};

export { TextModal };
