import type { FeesOriginationDiscountSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { DisplayType } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useGetFeesOriginationDiscount } from '@/API/Queries/mortgage/useGetFeesOriginationDiscount';
import type { UseUpdateFeesOriginationDiscountDTO } from '@/API/Queries/mortgage/useUpdateFeesOriginationDiscount';
import { useUpdateFeesOriginationDiscount } from '@/API/Queries/mortgage/useUpdateFeesOriginationDiscount';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement, getPanelElementMask } from '@/Utils/Helpers/getPanelElement';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const OriginationDiscountDetails = () => {
  const { data, isLoading, refetch } = useGetFeesOriginationDiscount();
  const { isLoading: isSaving, mutate: updateFeesAdjustmentsCredits } = useUpdateFeesOriginationDiscount();
  const { showSnackBar } = useShared();
  const { loanId } = useDynamicLoanInfo();

  if (isLoading) return <div />;

  const convertToPanelElement = (listItem: Array<{
    id: string;
    caption: string;
    order: number;
    value: string;
    type: DisplayType;
  }>) => listItem
    .sort((a, b) => a.order - b.order)
    .map((list) => {
      const value = list.value ? list.value.replace(/[%$]/g, '') : undefined;
      const blockedInputs = ['LenderCreditsItemized', 'SellerCreditsApplied'];

      const getMaskType = () => {
        if (list.type === DisplayType.DISPLAY_TYPE_CURRENCY) {
          return getPanelElementMask({ type: 'currency' });
        }
        if (list.type === DisplayType.DISPLAY_TYPE_PERCENTAGE) {
          return getPanelElementMask({ type: 'percentage' });
        }
        return undefined;
      };

      return (
        {
          caption: list.value,
          label: list.caption,
          panelElement: getPanelElement({
            disabled: blockedInputs.includes(list.id),
            headerText: list.caption,
            id: list.id,
            mask: getMaskType(),
            type: 'number',
          }),
          value: { value },
        }
      );
    });

  const handleUpdateAdjustmentsCredits = (section: FeesOriginationDiscountSection.AsObject) => (formData: DynamicDataElementValues, close: () => void) => {
    const { cardListList, order, sectionName, sectionType } = section;

    const updatedCardListValues = cardListList.map((list) => ({
      ...list,
      value: formData[list.id]?.value,
    }));

    const updatedTemplate: UseUpdateFeesOriginationDiscountDTO = {
      dealId: loanId,
      feesOriginationDiscountSection: {
        cardListList: updatedCardListValues,
        order,
        sectionName,
        sectionType,
      },
    };

    updateFeesAdjustmentsCredits(updatedTemplate, {
      onError: () => {
        showSnackBar({ message: 'Something went wrong, please try again later.', open: true });
      },
      onSuccess: () => {
        close();
        refetch();
        showSnackBar({ message: 'Fee successfully updated', open: true });
      },
    });
  };

  return (
    <div className="grid gap-y-6">
      {
        data?.sectionsList
          .sort((a, b) => a.order - b.order)
          .map((section) => (
            <CardListItem
              key={section.sectionName}
              headerProps={{ title: section.sectionName }}
              isEditable
              isLoading={isLoading}
              isSaving={isSaving}
              listItems={convertToPanelElement(section.cardListList)}
              onSubmit={handleUpdateAdjustmentsCredits(section)}
              variant="border"
              widgetId={section.sectionName}
            />
          ))
      }
    </div>
  );
};

export default OriginationDiscountDetails;
