import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { Button } from '@/Components/Atoms/Button';
import { Card } from '@/Components/Atoms/Card/Card';
import { DropdownMenu } from '@/Components/Atoms/DropdownMenu';
import { Icon } from '@/Components/Atoms/Icon';
import { ListItem } from '@/Components/Atoms/ListItem/ListItem';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { renderValue } from '@/Utils/Helpers/renderableHelper';

import type { Props } from '.';

export const MobileEditableTable = (props: Props) => {
  const [editIndexRow, setEditIndexRow] = useState<number | undefined>();
  const [addNewRow, setAddNewRow] = useState<boolean>(false);

  const { imperativeSubmit, resetProgress, values: formValues } = useDynamicForm();

  const optimisticRowUpdate = useCallback(() => {
    const resetEdit = () => {
      setEditIndexRow(undefined);
    };
    const resetAdd = () => {
      setAddNewRow(false);
    };
    imperativeSubmit(addNewRow ? resetAdd : resetEdit);
  }, [imperativeSubmit, addNewRow]);

  const handleEdit = (rowIndex: number) => {
    const values = props.rowsList[rowIndex].rowDataMap.reduce((values, cell) => ({
      ...values,
      [`editable@edit@${props.id}@${props.rowsList[rowIndex].rowScopeToken}@${cell[1].columnId}`]: {
        dynamic: cell[1].editableData?.value ? DynamicDataElementMap(cell[1].editableData.value) : undefined,
      },
    }), {} as DynamicDataElementValues);
    const oldValues = Object.keys(formValues).reduce((acc, key) => {
      if (key.match(/^[^@]+@[^@]+@[^@]+@[^@]+@[^@]+$/)) {
        return acc;
      }
      return { ...acc, [key]: formValues[key] };
    }, {} as DynamicDataElementValues);
    resetProgress({ values: { ...values, ...oldValues } }, true);
    setEditIndexRow(rowIndex);
  };

  return (
    <Card
      defaultOpen
      disableAccordion
      headerProps={{
        title: props.title,
      }}
      variant="border"
    >
      <div
        className={clsx('w-full flex flex-col py-2', {
          'px-0': !(editIndexRow !== undefined || addNewRow),
          'px-4': editIndexRow !== undefined || addNewRow,
        })}
      >
        {
          editIndexRow !== undefined || addNewRow ? (
            <div className="flex flex-col w-full gap-4">
              {
                    props.columnsList.map((col) => (
                      col.panelElement && !col.hidden
                        ? (
                          <PanelElementRenderer
                            key={col.panelElement?.id}
                            {...col.panelElement}
                            id={addNewRow
                              ? `editable@new@${props.id}@${props.newItemScopeToken}@${col.columnId}`
                              : `editable@edit@${props.id}@${props.rowsList[editIndexRow || 0]?.rowScopeToken}@${col.columnId}`}
                          />
                        ) : null))
                  }
              <div className="flex flex-row py-2">
                <Button
                  label="Cancel"
                  onClick={() => {
                    setAddNewRow(false);
                    setEditIndexRow(undefined);
                  }}
                  variant="tertiary"
                />
                <Button
                  label="Save"
                  onClick={optimisticRowUpdate}
                  variant="primary"
                />
              </div>
            </div>
          ) : (
            <>
              {
                props.rowsList.length === 0 && (
                  <Typography className="mx-4 text-on-surface-inactive" variant="body2">
                    No Items yet
                  </Typography>
                )
              }
              {
                props.rowsList.map((row, index) => (
                  <ListItem.Root key={row.rowScopeToken}>
                    <ListItem.Content>
                      <Typography className="text-on-surface-inactive" variant="overline">{renderValue(row.rowDataMap?.[1][1]?.displayData)}</Typography>
                      <Typography variant="body2">{renderValue(row.rowDataMap?.[0][1]?.displayData)}</Typography>
                      <Typography className="text-on-surface-inactive" variant="caption">{renderValue(row.rowDataMap?.[2][1]?.displayData)}</Typography>
                    </ListItem.Content>
                    <ListItem.Railing>
                      <DropdownMenu.Root modal>
                        <DropdownMenu.Trigger>
                          <Icon icon="MoreVert" />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                          <DropdownMenu.Item
                            label="Edit"
                            onClick={() => handleEdit(index)}
                          />
                          <DropdownMenu.Item label="Delete" onClick={() => props.handleDelete(row.rowScopeToken)} />
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </ListItem.Railing>
                  </ListItem.Root>
                ))
              }
            </>
          )
        }

      </div>
      { !(editIndexRow !== undefined || addNewRow) && (
        <div className="p-2 border-b border-b-on-surface-stroke">
          <Button
            label={props.newItemLabel || 'Add new'}
            onClick={() => setAddNewRow(true)}
            variant="tertiary"
          />
        </div>
      )}
    </Card>
  );
};
