import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetSkillsetListQueryRequest, GetSkillsetListQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.GetSkillsetListQueryRequest';

const useSkillsets = (search?: string, enabled = false) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, search], async () => {
    const request = new GetSkillsetListQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setSearchTerm(search || '');
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetSkillsetListQueryResponse();
    GetSkillsetListQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, {
      list: responseObj.skillsetsList,
      totalItems: 0,
      totalPages: 0,
    });
  }, { enabled });
};
export { useSkillsets };
