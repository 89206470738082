// source: Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/CircuitComponent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb = require('../../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb = require('../../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/TableCircuitComponent_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb = require('../../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/EditableTableCircuitComponent_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/SubmitForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Fusion_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb);
var Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb = require('../../../Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitComponent', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.ComponentCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CircuitComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CircuitComponent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.repeatedFields_ = [3,4,12];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_ = [[2,6,7,8,13,14]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.ComponentCase = {
  COMPONENT_NOT_SET: 0,
  FORM: 2,
  TABLE: 6,
  SUBMIT_FORM_WIDGET: 7,
  FORM_WIDGET: 8,
  EDITABLE_TABLE: 13,
  FUSION_WIDGET: 14
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.ComponentCase}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getComponentCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.ComponentCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    overrideNextButtonText: jspb.Message.getFieldWithDefault(msg, 15, ""),
    overrideAddNewButtonText: jspb.Message.getFieldWithDefault(msg, 16, ""),
    form: (f = msg.getForm()) && Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb.FormCircuitComponent.toObject(includeInstance, f),
    table: (f = msg.getTable()) && Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb.TableCircuitComponent.toObject(includeInstance, f),
    submitFormWidget: (f = msg.getSubmitFormWidget()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.toObject(includeInstance, f),
    formWidget: (f = msg.getFormWidget()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.toObject(includeInstance, f),
    editableTable: (f = msg.getEditableTable()) && Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb.EditableTableCircuitComponent.toObject(includeInstance, f),
    fusionWidget: (f = msg.getFusionWidget()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.toObject(includeInstance, f),
    validityRuleKeysList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    showFieldValidationsList: jspb.Message.toObjectList(msg.getShowFieldValidationsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.ShowFieldValidation.toObject, includeInstance),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fieldGrouping: (f = msg.getFieldGrouping()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.toObject(includeInstance, f),
    validationMessagesList: jspb.Message.toObjectList(msg.getValidationMessagesList(),
    Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CircuitComponent;
  return proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideNextButtonText(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverrideAddNewButtonText(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb.FormCircuitComponent;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb.FormCircuitComponent.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 6:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb.TableCircuitComponent;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb.TableCircuitComponent.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.deserializeBinaryFromReader);
      msg.setSubmitFormWidget(value);
      break;
    case 8:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.deserializeBinaryFromReader);
      msg.setFormWidget(value);
      break;
    case 13:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb.EditableTableCircuitComponent;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb.EditableTableCircuitComponent.deserializeBinaryFromReader);
      msg.setEditableTable(value);
      break;
    case 14:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.deserializeBinaryFromReader);
      msg.setFusionWidget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addValidityRuleKeys(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.ShowFieldValidation;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.ShowFieldValidation.deserializeBinaryFromReader);
      msg.addShowFieldValidations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 11:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.deserializeBinaryFromReader);
      msg.setFieldGrouping(value);
      break;
    case 12:
      var value = new Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.deserializeBinaryFromReader);
      msg.addValidationMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOverrideNextButtonText();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOverrideAddNewButtonText();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb.FormCircuitComponent.serializeBinaryToWriter
    );
  }
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb.TableCircuitComponent.serializeBinaryToWriter
    );
  }
  f = message.getSubmitFormWidget();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getFormWidget();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getEditableTable();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb.EditableTableCircuitComponent.serializeBinaryToWriter
    );
  }
  f = message.getFusionWidget();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getValidityRuleKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getShowFieldValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.ShowFieldValidation.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFieldGrouping();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.serializeBinaryToWriter
    );
  }
  f = message.getValidationMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string override_next_button_text = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getOverrideNextButtonText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setOverrideNextButtonText = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string override_add_new_button_text = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getOverrideAddNewButtonText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setOverrideAddNewButtonText = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional FormCircuitComponent form = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_FormCircuitComponent_pb.FormCircuitComponent, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasForm = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TableCircuitComponent table = 6;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_TableCircuitComponent_pb.TableCircuitComponent, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCircuitComponent|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasTable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SubmitFormWidgetSettings submit_form_widget = 7;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getSubmitFormWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings, 7));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setSubmitFormWidget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearSubmitFormWidget = function() {
  return this.setSubmitFormWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasSubmitFormWidget = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FormWidgetSettings form_widget = 8;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getFormWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setFormWidget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearFormWidget = function() {
  return this.setFormWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasFormWidget = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EditableTableCircuitComponent editable_table = 13;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.EditableTableCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getEditableTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.EditableTableCircuitComponent} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_EditableTableCircuitComponent_pb.EditableTableCircuitComponent, 13));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.EditableTableCircuitComponent|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setEditableTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearEditableTable = function() {
  return this.setEditableTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasEditableTable = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional FusionWidgetSettings fusion_widget = 14;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getFusionWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings, 14));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setFusionWidget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearFusionWidget = function() {
  return this.setFusionWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasFusionWidget = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated string validity_rule_keys = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getValidityRuleKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setValidityRuleKeysList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.addValidityRuleKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearValidityRuleKeysList = function() {
  return this.setValidityRuleKeysList([]);
};


/**
 * repeated ShowFieldValidation show_field_validations = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getShowFieldValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.ShowFieldValidation, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setShowFieldValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.addShowFieldValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.ShowFieldValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearShowFieldValidationsList = function() {
  return this.setShowFieldValidationsList([]);
};


/**
 * optional string scope_token = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional FieldGroupingSettings field_grouping = 11;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getFieldGrouping = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings, 11));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setFieldGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearFieldGrouping = function() {
  return this.setFieldGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.hasFieldGrouping = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated ValidationMessage validation_messages = 12;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.getValidationMessagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.setValidationMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.addValidationMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.mortgage_dynamic_data.ValidationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitComponent.prototype.clearValidationMessagesList = function() {
  return this.setValidationMessagesList([]);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
