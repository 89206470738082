import { useMemo, useState } from 'react';

import { useOrganizations } from '@/API/Queries/user/useOrganizations';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useNavigate } from '@/Routes/NavigationContext';

import { CreateOrganization } from './createOrganization';

interface TableDataItem {
  id: string;
  name: string;
  address: string;
  licensedStates: string;
  licenseNumber: string;
}

const OrganizationsList = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const { refetch } = useOrganizations();

  const handleOpenOrg = (data: TableDataItem) => {
    navigate(`/organization/${data.id}/overview`);
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
    refetch();
  };

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'displayName'],
    ['Address', 'fullAddress'],
  ], []);

  return (
    <>
      <TableBuilder
        cardProps={{
          headerProps: {
            actions: [{
              label: 'Create new',
              onClick: () => setOpenModal(true),
            }],
            title: 'All Organizations',
          },
        }}
        columns={columns}
        dataType={TableBuilderDataTypeEnum.ORGANIZATIONS}
        onClickRow={handleOpenOrg}
        rowActionType="button"
      />
      { openModal && <CreateOrganization onClose={handleOnCloseModal} />}
    </>
  );
};

export { OrganizationsList };
