import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetAdminLoanFeeTemplateQueryRequest, GetAdminLoanFeeTemplateQueryResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Queries_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.mortgage_config.GetAdminLoanFeeTemplateQueryRequest';

const useLoanFeeTemplate = (id: string) => {
  const { commandResponse } = useBackend();
  const auth = useAuth();
  const bpId = auth.secureAuthValues.authorizedBusinessProcessDomainIds[0];

  return useQuery([MESSAGE_NAME, bpId, id], async () => {
    const request = new GetAdminLoanFeeTemplateQueryRequest();
    request.setBusinessProcessDomainId(bpId);
    request.setId(id);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new GetAdminLoanFeeTemplateQueryResponse();
    GetAdminLoanFeeTemplateQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.loanFeeTemplate?.loanFeeTemplate);
  });
};

export { useLoanFeeTemplate };
