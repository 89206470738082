import { useMutation } from '@tanstack/react-query';

const useIdToken = () => useMutation(async (tokenId: string) => {
  const exchangeTokenRequestBody = new FormData();
  exchangeTokenRequestBody.append('token', tokenId);
  await fetch('/mortgage/api/token/idtoken', {
    body: exchangeTokenRequestBody,
    method: 'POST',
  });
});

export { useIdToken };
