import * as proto from 'google-protobuf';

import type { WilqoMessage } from './useBackend';
import { useBackend } from './useBackend';

const useWilqoMessage = () => {
  const { commandResponse } = useBackend();

  return async <T>(message: WilqoMessage, ResponseType: any): Promise<T> => {
    try {
      const responseMsg = await commandResponse(message);
      const response = new ResponseType();
      ResponseType.deserializeBinaryFromReader(response, new proto.BinaryReader(responseMsg.getValue()));
      const responseObj = response.toObject();
      const { error } = responseObj;
      if (error && error.error) {
        throw new Error(error.errorMessage);
      }
      return responseObj;
    } catch (e: any) {
      throw new Error(e);
    }
  };
};

export type OmitBPD<T> = Omit<T, 'businessProcessDomainId' | 'institutionId'>;

export { useWilqoMessage };
