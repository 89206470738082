// source: Wilqo.API.MortgageConfig/ProgressItemTemplate/Wilqo_API_MortgageConfig_ProgressItemTemplateQueries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb = require('../../Wilqo.API.MortgageConfig/ProgressItemTemplate/Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.displayName = 'proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.displayName = 'proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.displayName = 'proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.displayName = 'proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest;
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    progressItemTemplatesList: jspb.Message.toObjectList(msg.getProgressItemTemplatesList(),
    Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse;
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate;
      reader.readMessage(value,Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.deserializeBinaryFromReader);
      msg.addProgressItemTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProgressItemTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProgressItemTemplate progress_item_templates = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_config.ProgressItemTemplate>}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.getProgressItemTemplatesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.ProgressItemTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.ProgressItemTemplate>} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.setProgressItemTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ProgressItemTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.ProgressItemTemplate}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.addProgressItemTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_config.ProgressItemTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplatesQueryResponse.prototype.clearProgressItemTemplatesList = function() {
  return this.setProgressItemTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    identifier: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest;
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string identifier = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    progressItemTemplate: (f = msg.getProgressItemTemplate()) && Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse;
  return proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate;
      reader.readMessage(value,Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.deserializeBinaryFromReader);
      msg.setProgressItemTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProgressItemTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} returns this
*/
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProgressItemTemplate progress_item_template = 2;
 * @return {?proto.wilqo.api.mortgage_config.ProgressItemTemplate}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.getProgressItemTemplate = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.ProgressItemTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_ProgressItemTemplate_Wilqo_API_MortgageConfig_ProgressItemTemplate_pb.ProgressItemTemplate, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.ProgressItemTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} returns this
*/
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.setProgressItemTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse} returns this
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.clearProgressItemTemplate = function() {
  return this.setProgressItemTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.GetProgressItemTemplateQueryResponse.prototype.hasProgressItemTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage_config);
