import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { User } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useUserOrgAssociation } from '@/API/Queries/user/useUserOrgAssociation';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';

const OrganizationUsers = () => {
  const { orgId = '' } = useParams();

  const isAssigned = useCallback((user?: User.AsObject) => user?.organizationIdsList?.some((userOrgId) => userOrgId === orgId), [orgId]);
  const [selectedUsers, setSelectedUsers] = useState<Array<User.AsObject>>([]);
  const { mutate: updateUserOrg } = useUserOrgAssociation();

  const renderBadge = useCallback((user: User.AsObject) => {
    if (isAssigned(user)) {
      return <Badge label="Assigned" variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS} />;
    }
    return null;
  }, [isAssigned]);

  const handleClick = useCallback((user: User.AsObject, refetch: any) => {
    let orgIds = user.organizationIdsList;
    if (isAssigned(user)) {
      orgIds = orgIds.filter((id) => orgId !== id);
    } else {
      orgIds = [...orgIds, orgId];
    }
    updateUserOrg({
      orgIds,
      userId: user.id,
    }, {
      onSuccess: refetch,
    });
  }, [isAssigned, orgId, updateUserOrg]);

  const handleBulkAssignment = (assign: boolean, close: any, refetch?: any) => {
    selectedUsers.forEach((user) => {
      let orgIds = user.organizationIdsList;
      if (!assign) {
        orgIds = orgIds.filter((id) => orgId !== id);
      } else {
        orgIds = [...orgIds, orgId];
      }
      updateUserOrg({
        orgIds,
        userId: user.id,
      }, {
        onSuccess: refetch,
      });
    });
  };

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'displayName'],
    ['Assignment', { type: 'any', value: renderBadge }],
  ], [renderBadge]);

  const hoverActions = useMemo(() => [
    { label: (user: any) => (isAssigned(user) ? 'Unassign' : 'Assign'), onClick: handleClick },
  ], [isAssigned, handleClick]);

  const orderBy = useMemo(() => 'Assignment', []);

  return (
    <TableBuilder
      allowSearch
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Assign', onClick: (close, refetch) => handleBulkAssignment(true, close, refetch) },
              { label: 'Unassign', onClick: (close, refetch) => handleBulkAssignment(false, close, refetch) },
            ],
            caption: `${selectedUsers.length} user(s) selected`,
          },
          showContextual: selectedUsers.length > 0,
          title: 'Users',
        },
      }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.USERS}
      hoverActions={hoverActions}
      orderBy={orderBy}
      setRowSelection={setSelectedUsers}
    />
  );
};

export { OrganizationUsers };
