import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useLocation } from 'react-router';

import { useAuthSettings } from '@/API/Queries/tenancy/useAuthSettings';
import { useNavigate } from '@/Routes/NavigationContext';

import { AuthProvider } from './AppAuthContext';

interface Auth0Props {
  children: React.ReactNode;
}

const AppAuthProvider = (props: Auth0Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.hash.replace(/^#/, ''));
  const { data: authSettings } = useAuthSettings();

  const onRedirectCallback = (appState?: AppState) => {
    if (appState && (appState.runId || appState.invitationId)) {
      navigate('consumerCallback', { state: { invitationId: appState.invitationId, runId: appState.runId } });
    } else if (appState && appState.returnTo) {
      navigate(appState.returnTo.replace('/mortgage', ''), { replace: true });
    } else {
      navigate('home');
    }
  };

  if (!authSettings) return null;

  return (
    <Auth0Provider
      audience="https://frontend.wilqo.com"
      clientId={authSettings?.clientId || ''}
      clientType="web"
      connection={params.get('auth_connection') ?? ''}
      domain={authSettings?.domain || ''}
      onRedirectCallback={onRedirectCallback}
      redirectUri={`${window.location.protocol}//${window.location.host}/mortgage/login`}
    >
      <AuthProvider>
        {props.children}
      </AuthProvider>
    </Auth0Provider>
  );
};

export default AppAuthProvider;
