import { useMutation } from '@tanstack/react-query';

import { UpdateBusinessProcessTemplateCommandRequest } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import { UpdateProgressItemCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

interface ParamsObject {
  title: string;
  id: string;
  internalName: string;
  progressItemId: string;
}

const MESSAGE_NAME = 'wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest';

export const useUpdateBusinessProcessTemplate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (params: ParamsObject) => {
    const { id, internalName, progressItemId, title } = params;
    const request = new UpdateBusinessProcessTemplateCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setBusinessProcessTemplateId(id);
    request.setTitle(title);
    request.setInternalName(internalName);
    request.setLoanProgressItemIdentifier(progressItemId);
    return sendMessage<UpdateProgressItemCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      UpdateProgressItemCommandResponse,
    );
  });
};
