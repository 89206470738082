import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEvent } from 'react-use-event-hook';

import type { ShowFieldValidation } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { PanelElement, PanelElement as PanelElementType } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElementValue, DynamicDataElementValues, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider, useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { useWidgetContext } from '@/Components/Widgets/content/WidgetContext';
import { useBlocker } from '@/Routes/NavigationContext';

import { Dialog } from '../Dialog';
import type { ListItemProps } from '../ListItem';
import { ListItem } from '../ListItem';
import { Typography } from '../Typography';
import type { CardHeaderType, CardVariant } from './Card';
import { Card } from './Card';
import type { CardButton } from './CardHeader';

export interface PanelElementListItem extends ListItemProps {
  panelElement?: PanelElementType.AsObject;
  hidden?: boolean;
  value?: DynamicDataElementValue;
  id?: string;
}

export type CardListItemSubmitHandler = (values: DynamicDataElementValues, close: () => void, widgetId?: string, callback?: any) => void;

export interface CardListItemProps {
  isEditable?: boolean;
  listItems: PanelElementListItem[];
  onSubmit?: CardListItemSubmitHandler;
  headerProps: CardHeaderType;
  variant?: CardVariant;
  isLoading?: boolean;
  isSaving?: boolean;
  revertEmphasis?: boolean;
  defaultOpen?: boolean;
  editByDefault?: boolean;
  widgetId?: string;
  handleIsEditing?: (bool: boolean) => void;
  conditionalFields?: Array<ShowFieldValidation.AsObject>;
  hideActions?: boolean;
  disableActions?: boolean;
  displayOnly?: boolean;
  additionalSubmitInfo?: string;
  allowPrompt?: boolean;
  numberOfColumns?: number;
  disableAccordion?: boolean;
  onEditing?: (flag: boolean) => void;
  hideRead?: boolean;
}

interface InternalCardListItemProps extends CardListItemProps {
  isEditing: boolean;
  setIsEditing: (b: boolean) => void;
}

const CardListItemComponent = (props: InternalCardListItemProps) => {
  const {
    additionalSubmitInfo,
    allowPrompt,
    defaultOpen = true,
    disableAccordion = false,
    disableActions = false,
    handleIsEditing,
    headerProps,
    hideActions,
    hideRead = false,
    isEditing,
    isLoading = false,
    isSaving = false,
    listItems,
    numberOfColumns = 3,
    onEditing,
    revertEmphasis = false,
    setIsEditing,
    variant = 'borderless',
  } = props;

  const [showDialog, setShowDialog] = useState(false);

  const { hasError, imperativeSubmit, resetProgress } = useDynamicForm();

  const { removeEditingWidget } = useWidgetContext();

  const handleSetIsEditing = useCallback((bool: boolean) => {
    if (onEditing) onEditing(bool);
    setIsEditing(bool);
    if (handleIsEditing) handleIsEditing(bool);
  }, [handleIsEditing, onEditing, setIsEditing]);

  const handleDiscardAnywayClick = (_: any, callback?: any) => {
    setShowDialog(false);
    setIsEditing(false);
    handleSetIsEditing(false);
    if (callback) callback();
  };

  const handleSaveBeforeLeaving = useEvent((_: any, callback?: any) => {
    setShowDialog(false);
    imperativeSubmit(() => {
      callback();
      handleSetIsEditing(false);
      removeEditingWidget(props.widgetId ?? '');
    });
  });

  useBlocker(isEditing && Boolean(allowPrompt), {
    actions: [
      { danger: true, label: 'Discard', onClick: handleDiscardAnywayClick, variant: 'tertiary' },
      { label: 'Save', onClick: handleSaveBeforeLeaving, variant: 'tertiary' },
    ],
    children: (
      <Typography variant="body2">
        To continue navigation, you must select Discard or Save.
      </Typography>
    ),
    id: headerProps.title,
    onClickDismiss: () => setShowDialog(false),
    title: 'Selection Needed',
  });

  useHotkeys(
    'mod+s',
    (event) => {
      imperativeSubmit();
      event.preventDefault();
    },
    { enableOnFormTags: true, enabled: isEditing },
  );

  const reloadData = useCallback(() => {
    const values = listItems.reduce((progress, item) => ({
      ...progress,
      [item?.panelElement?.id || 'id']: item.value,
    }), {});
    resetProgress({ values });
  }, [resetProgress, listItems]);

  useEffect(() => {
    if (isEditing) {
      reloadData();
    }
  }, [isEditing]);

  useHotkeys(
    'esc',
    () => {
      if (props.isEditable && isEditing) setShowDialog(true);
    },
  );

  const handleEditClick = useCallback(() => {
    handleSetIsEditing(true);
    reloadData();
  }, [handleSetIsEditing, reloadData]);

  const getActions = useCallback((hasError: boolean): CardButton[] => {
    if (hideActions) return [];
    if (isEditing) {
      return [
        { label: 'Discard', onClick: () => handleSetIsEditing(false), variant: 'tertiary' },
        { additionalData: additionalSubmitInfo, disabled: hasError, isLoading: isSaving, label: 'Save', type: 'submit', variant: 'primary' },
      ];
    }

    return [
      { disabled: disableActions, label: 'Edit', onClick: handleEditClick, variant: 'tertiary' },
    ];
  }, [isEditing, isSaving, disableActions, handleSetIsEditing, hideActions, additionalSubmitInfo, handleEditClick]);

  const renderPanels = () => {
    if (isEditing && listItems.length > 0) {
      const [firstItem, ...rest] = listItems;
      const panelElementListItem = firstItem.panelElement?.type === 'chip-list' ? rest : listItems;
      const panelElements: Array<PanelElement.AsObject & { hidden?: boolean } | undefined> = panelElementListItem.map((e, index) => (e.panelElement ? { ...e.panelElement, hidden: e.hidden, order: index + 1 } : undefined));
      return (
        <>
          {firstItem.panelElement && firstItem.panelElement?.type === 'chip-list' && (
            <div className={clsx({ 'col-span-1 tablet:col-span-2 desktop:col-span-3': props.numberOfColumns !== 1 })}>
              <PanelElementRenderer {...firstItem.panelElement} />
            </div>
          )}
          {panelElements.map((panelElement) => (panelElement ? (
            <PanelElementRenderer key={panelElement.id} hidden={panelElement.hidden} {...panelElement} />
          ) : <div />))}
        </>
      );
    }
    if (hideRead) return null;

    return listItems.filter((p) => !p.hidden).map((listItem) => (
      <div key={listItem.panelElement?.id || `${listItem?.id}-${listItem.caption}-${listItem.label}`}>
        <ListItem disableInteractions revertEmphasis={revertEmphasis} {...listItem} isLoadingCaption={isLoading} />
      </div>
    ));
  };

  return (
    <>
      <Card
        defaultOpen={defaultOpen}
        disableAccordion={isEditing || disableAccordion}
        headerProps={{
          ...headerProps,
          actions: [
            ...getActions(hasError),
            ...Array.isArray(headerProps.actions) ? headerProps.actions : [],
          ],
        }}
        variant={variant}
      >
        <div
          className={clsx(
            `grid grid-cols-1 mobile:grid-cols-2 desktop:grid-cols-${numberOfColumns}`,
            {
              'gap-0': numberOfColumns === 1 && !isEditing,
              'gap-6 pb-4': !isEditing && numberOfColumns !== 1,
              'gap-6 pb-4 px-4': isEditing,
            },
          )}
        >
          {renderPanels()}
        </div>
      </Card>
      {showDialog && (
        <Dialog
          actions={[
            { danger: true, label: 'Discard', onClick: handleDiscardAnywayClick, variant: 'tertiary' },
            { label: 'Save', onClick: handleSaveBeforeLeaving, variant: 'tertiary' },
          ]}
          onClickDismiss={() => setShowDialog(false)}
          title="Unsaved changes"
        >
          You have unsaved changes.
          Do you want to discard or save them, before continuing?
        </Dialog>
      )}
    </>
  );
};

const CardListItem = (props: CardListItemProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(props.editByDefault || false);

  useEffect(() => {
    setIsEditing(props.editByDefault || false);
  }, [props.editByDefault]);

  if (props.displayOnly) {
    return <CardListItemComponent {...props} isEditing={isEditing} setIsEditing={setIsEditing} />;
  }

  const handleSubmit = (data: DynamicDataElementValues, { callback }: SubmitValues) => {
    if (props.onSubmit) {
      props.onSubmit(data, () => setIsEditing(false), props.widgetId, callback);
    }
  };

  return (
    <DynamicFormProvider onSubmit={handleSubmit} shouldUnregister>
      <CardListItemComponent {...props} isEditing={isEditing} setIsEditing={setIsEditing} />
    </DynamicFormProvider>
  );
};

export { CardListItem };
