import { useQuery } from '@tanstack/react-query';

import { ExecuteBorrowerInviteUrlCommandRequest, ExecuteBorrowerInviteUrlCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { GetUserProvisioningTicketQueryRequest, GetUserProvisioningTicketQueryResponse } from '@/API/Models/Wilqo_API_Users_UserProvisioningTicketsQueries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest';

const useBorrowerInvite = (dealId = '', enabled = false) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery<ExecuteBorrowerInviteUrlCommandResponse.AsObject, { message: string }>([MESSAGE_NAME, dealId], async () => {
    const ticketRequest = new GetUserProvisioningTicketQueryRequest();
    ticketRequest.setId(dealId || '');
    const ticketResponseObj = await sendMessage<GetUserProvisioningTicketQueryResponse.AsObject>({
      msg: ticketRequest,
      msgName: 'wilqo.api.users.GetUserProvisioningTicketQueryRequest',
    }, GetUserProvisioningTicketQueryResponse);
    if (!ticketResponseObj.error?.error && ticketResponseObj?.ticket?.displayName) {
      localStorage.setItem('displayName', ticketResponseObj?.ticket?.displayName);
    }

    const request = new ExecuteBorrowerInviteUrlCommandRequest();
    request.setInviteId(dealId || '');
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    return sendMessage<ExecuteBorrowerInviteUrlCommandResponse.AsObject>(message, ExecuteBorrowerInviteUrlCommandResponse);
  }, { enabled: Boolean(dealId) && enabled && Boolean(bpdId) });
};

export { useBorrowerInvite };
