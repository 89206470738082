import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { Int32Value } from 'google-protobuf/google/protobuf/wrappers_pb';

import { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';

export const toInt32ValueFromObject = (obj: Int32Value.AsObject): Int32Value => {
  const int = new Int32Value();
  int.setValue(obj.value);

  return int;
};

export const toProtoDecimalFromObject = (obj: ProtoDecimal.AsObject): ProtoDecimal => {
  const protoDecimal = new ProtoDecimal();
  protoDecimal.setV1(obj.v1);
  protoDecimal.setV2(obj.v2);
  protoDecimal.setV3(obj.v3);
  protoDecimal.setV4(obj.v4);

  return protoDecimal;
};

export const toTimestampFromObject = (obj: Timestamp.AsObject): Timestamp => {
  const timestamp = new Timestamp();
  timestamp.setNanos(obj.nanos);
  timestamp.setSeconds(obj.seconds);

  return timestamp;
};
