import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { Badge } from '@/Components/Atoms/Badge';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { convertRichText } from '@/Utils/Helpers/richTextConversion';

import { ChecklistItemForm } from './checklist/checklistForm';
import { DynamicChecklistForm } from './validation/validationForm';

interface CompleteProps {
  isLoading: boolean;
  type: 'checklist' | 'validation';
  progress: Array<any>;
}

const CompleteList = (props: CompleteProps) => {
  const { isLoading, progress, type } = props;

  const progressValueToString = (progressItem: any) => {
    const values = Object.values(progressItem.value);
    return values.toString();
  };

  const renderHeader = (progressItem: any, index: number, expanded: boolean) => {
    const fallbackTitle = `Item ${index + 1}`;
    if (!expanded) {
      return (
        <div
          className={clsx(
            'flex flex-col gap-y-[2px] px-4 py-2 text-left',
            {
              'px-4 pt-2 pb-2': expanded,
            },
          )}
        >
          <span className="text-xs leading-4">
            {progressItem.checklistItem.title || fallbackTitle}
          </span>
          <span className="text-xs leading-4 text-on-surface-inactive">
            {convertRichText(progressItem.checklistItem.panel?.headerText)}
          </span>
          <span className="text-on-surface-active">
            {progressValueToString(progressItem)}
          </span>
          {type === 'validation' && progressItem.checklistItem.completedByAutomation && (
            <Badge
              label="Completed by Wilqo"
              variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE}
            />
          )}
        </div>
      );
    }
    return (
      <div
        className={clsx(
          'flex flex-col gap-y-[2px] px-4 py-2 text-left',
          {
            'px-4 pt-2 pb-2': expanded,
          },
        )}
      >
        <span className="text-xs leading-4 text-on-surface-inactive">
          {convertRichText(progressItem.checklistItem.panel?.headerText || fallbackTitle)}
        </span>
        <span className="text-on-surface-active">
          {convertRichText(progressItem.checklistItem.panel?.summaryText)}
        </span>
      </div>
    );
  };

  const renderForm = (item: any) => {
    if (type === 'checklist') {
      return (
        <ChecklistItemForm
          checklistItem={item.checklistItem}
          isEdit
          panelElements={item.checklistItem?.panel?.panelElementList}
        />
      );
    }
    return (
      <DynamicChecklistForm
        checklistItem={item.checklistItem}
        isEdit
        panelElements={item.checklistItem?.panel?.panelElementsList}
      />
    );
  };

  const renderList = () => {
    if (isLoading) {
      return <Skeleton height="12px" rows={4} variant="rect" width="" />;
    }
    if (progress && progress.length > 0) {
      return (
        <div>
          {
            progress.map((completeItem, index) => {
              if (type === 'validation' && completeItem.checklistItem.completedByAutomation) {
                return (
                  <div key={completeItem?.checklistItem?.id} className="border-b border-on-surface-stroke">
                    {renderHeader(completeItem, index, false)}
                  </div>
                );
              }
              return (
                <Disclosure key={uuid()} as="div">
                  {({ open }) => (
                    <div className={
                    clsx('py-2', {
                      'bg-surface-background': !open,
                      'bg-surface-variant border-on-surface-stroke border-b': open,
                    })
                  }
                    >
                      <Disclosure.Button className={
                    clsx('flex items-center justify-between cursor-pointer w-full px-2', {
                      'border-b-on-surface-stroke': !open,
                    })
                  }
                      >
                        {renderHeader(completeItem, index, open)}
                        <Icon icon={open ? 'ExpandLess' : 'ExpandMore'} />
                      </Disclosure.Button>
                      <Disclosure.Panel className="py-0 px-4 w-full [&_button]:m-0">
                        {renderForm(completeItem)}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              );
            })
          }
        </div>
      );
    }
    return (
      <div className="m-4">
        <ContingencyMessage subtitle="No completed items yet" title="No results" />
      </div>
    );
  };

  return (
    <div
      className={clsx(
        'overflow-x-hidden overflow-y-auto h-[calc(100vh-120px)]',
        {
          'p-3': isLoading || progress?.length === 0,
        },
      )}
    >
      {renderList()}
    </div>
  );
};

export { CompleteList };
