import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useRef, useState } from 'react';

export type PollState = {
  isPolling: boolean;
  startPolling: (a?: string) => void;
};
export type UsePollReturn<T> = PollState & UseQueryResult<T>;

export type QueryOptions<T> = Omit<UseQueryOptions<T | undefined, Error>, 'initialData' | 'queryFn' | 'queryKey'> & { initialData?: () => undefined };

export const usePoll = <T = any>(curriedQuery: (options: QueryOptions<T>) => () => UseQueryResult<T, Error>): UsePollReturn<T> => {
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const countRef = useRef(0);

  const query = curriedQuery({
    onSettled: () => {
      if (isPolling && countRef.current !== 10) {
        countRef.current += 1;
      } else {
        setIsPolling(false);
        countRef.current = 0;
      }
    },
    refetchInterval: () => (isPolling && countRef.current < 10 ? (countRef.current + 1) * 1000 : false),
  });
  const queryData = query();

  const handleStartPolling = () => {
    countRef.current = 0;
    setIsPolling(true);
  };

  return {
    isPolling,
    startPolling: handleStartPolling,
    ...queryData,
  };
};
