import { useQuery } from '@tanstack/react-query';

import { GetProcessStepInformationQueryRequest, GetProcessStepInformationQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetProcessStepInformationQueryRequest';

export interface ProcessStepInformationParameters {
  processId: string;
  stepName: string;
  enabled: boolean;
}

const useProcessStepInformation = (props: ProcessStepInformationParameters) => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, props.processId, props.stepName], async () => {
    const request = new GetProcessStepInformationQueryRequest();
    request.setInstitutionId(institutionId);
    request.setBusinessProcessDomainId(bpdId);
    request.setInstanceId(props.processId);
    request.setStepName(props.stepName);
    // console.log(props);
    return sendMessage<GetProcessStepInformationQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetProcessStepInformationQueryResponse,
    );
  },
  { enabled: props.enabled });
};

export { useProcessStepInformation };
