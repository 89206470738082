// source: Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Validations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.wilqo.shared.rulesengine.Validation', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.ValidationResult', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.ValidationStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.Validation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.Validation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.Validation.displayName = 'proto.wilqo.shared.rulesengine.Validation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.ValidationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.ValidationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.ValidationResult.displayName = 'proto.wilqo.shared.rulesengine.ValidationResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.Validation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.Validation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Validation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: (f = msg.getResult()) && proto.wilqo.shared.rulesengine.ValidationResult.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.Validation}
 */
proto.wilqo.shared.rulesengine.Validation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.Validation;
  return proto.wilqo.shared.rulesengine.Validation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.Validation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.Validation}
 */
proto.wilqo.shared.rulesengine.Validation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.ValidationResult;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.ValidationResult.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 4:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.Validation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.Validation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Validation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.ValidationResult.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Validation} returns this
 */
proto.wilqo.shared.rulesengine.Validation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ValidationResult Result = 3;
 * @return {?proto.wilqo.shared.rulesengine.ValidationResult}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.getResult = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.ValidationResult} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.ValidationResult, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.ValidationResult|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.Validation} returns this
*/
proto.wilqo.shared.rulesengine.Validation.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.Validation} returns this
 */
proto.wilqo.shared.rulesengine.Validation.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.hasResult = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Any settings = 4;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.getSettings = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 4));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.Validation} returns this
*/
proto.wilqo.shared.rulesengine.Validation.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.Validation} returns this
 */
proto.wilqo.shared.rulesengine.Validation.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.Validation.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.ValidationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.ValidationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.ValidationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourceDataVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    leftValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rightValue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult}
 */
proto.wilqo.shared.rulesengine.ValidationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.ValidationResult;
  return proto.wilqo.shared.rulesengine.ValidationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.ValidationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult}
 */
proto.wilqo.shared.rulesengine.ValidationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.ValidationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourceDataVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.ValidationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.ValidationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.ValidationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSourceDataVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLeftValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRightValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ValidationStatus Status = 1;
 * @return {!proto.wilqo.shared.rulesengine.ValidationStatus}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.ValidationStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.ValidationStatus} value
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult} returns this
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 source_data_version = 2;
 * @return {number}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.getSourceDataVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult} returns this
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.setSourceDataVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string left_value = 3;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.getLeftValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult} returns this
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.setLeftValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string right_value = 4;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.getRightValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.ValidationResult} returns this
 */
proto.wilqo.shared.rulesengine.ValidationResult.prototype.setRightValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.ValidationStatus = {
  VALIDATIONSTATUS_UNKNOWN: 0,
  VALIDATIONSTATUS_VALID: 1,
  VALIDATIONSTATUS_INVALID: 2,
  VALIDATIONSTATUS_DIRTY: 3,
  VALIDATIONSTATUS_FAILED: 4
};

goog.object.extend(exports, proto.wilqo.shared.rulesengine);
