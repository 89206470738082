// source: Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_CustomWrappers_pb = require('../Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeePaidToEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeePercentBasisEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeProcessEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeToleranceCategoryType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.GFEAggregationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.PartyRoleTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.SectionClassificationEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.displayName = 'proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ruleGroupSummaryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feeCalculationType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    feeSpecifiedFixedAmount: (f = msg.getFeeSpecifiedFixedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeTotalPercent: (f = msg.getFeeTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePercentBasisType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter;
  return proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleGroupSummaryId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum} */ (reader.readEnum());
      msg.setFeeCalculationType(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeSpecifiedFixedAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeTotalPercent(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} */ (reader.readEnum());
      msg.setFeePercentBasisType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRuleGroupSummaryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeeCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFeeSpecifiedFixedAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeTotalPercent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePercentBasisType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string rule_group_summary_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.getRuleGroupSummaryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.setRuleGroupSummaryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FeeCalculationTypeEnum fee_calculation_type = 3;
 * @return {!proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.getFeeCalculationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.setFeeCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_specified_fixed_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.getFeeSpecifiedFixedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
*/
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.setFeeSpecifiedFixedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.clearFeeSpecifiedFixedAmount = function() {
  return this.setFeeSpecifiedFixedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.hasFeeSpecifiedFixedAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_total_percent = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.getFeeTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
*/
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.setFeeTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.clearFeeTotalPercent = function() {
  return this.setFeeTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.hasFeeTotalPercent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FeePercentBasisEnum fee_percent_basis_type = 6;
 * @return {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum}
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.getFeePercentBasisType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} value
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} returns this
 */
proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter.prototype.setFeePercentBasisType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeeEnum = {
  FEE_ENUM_UNKNOWN: 0,
  FEE_ENUM_203_K_CONSULTANT_FEE: 1,
  FEE_ENUM_203_K_DISCOUNT_ON_REPAIRS: 2,
  FEE_ENUM_203_K_INSPECTION_FEE: 3,
  FEE_ENUM_203_K_PERMITS: 4,
  FEE_ENUM_203_K_SUPPLEMENTAL_ORIGINATION_FEE: 5,
  FEE_ENUM_203_K_TITLE_UPDATE: 6,
  FEE_ENUM_APPLICATION_FEE: 7,
  FEE_ENUM_APPRAISAL_DESK_REVIEW_FEE: 8,
  FEE_ENUM_APPRAISAL_FEE: 9,
  FEE_ENUM_APPRAISAL_FIELD_REVIEW_FEE: 10,
  FEE_ENUM_APPRAISAL_MANAGEMENT_COMPANY_FEE: 11,
  FEE_ENUM_ARM_CONVERSION_FEE: 12,
  FEE_ENUM_ASBESTOS_INSPECTION_FEE: 13,
  FEE_ENUM_ASSIGNMENT_PREPARATION_FEE: 14,
  FEE_ENUM_ASSUMPTION_FEE: 15,
  FEE_ENUM_ATTORNEY_FEE: 16,
  FEE_ENUM_AUTOMATED_UNDERWRITING_FEE: 17,
  FEE_ENUM_AVM_FEE: 18,
  FEE_ENUM_BANKRUPTCY_MONITORING_FEE: 19,
  FEE_ENUM_BOND_FEE: 20,
  FEE_ENUM_BOND_REVIEW_FEE: 21,
  FEE_ENUM_CERTIFICATION_FEE: 22,
  FEE_ENUM_CHOSEN_INTEREST_RATE_CREDIT_OR_CHARGE_TOTAL: 23,
  FEE_ENUM_COMMITMENT_FEE: 24,
  FEE_ENUM_CONDOMINIUM_ASSOCIATION_DUES: 25,
  FEE_ENUM_CONDOMINIUM_ASSOCIATION_SPECIAL_ASSESSMENT: 26,
  FEE_ENUM_CONSTRUCTION_HANDLING_FEE: 27,
  FEE_ENUM_CONSTRUCTION_INSPECTION_FEE: 28,
  FEE_ENUM_COOPERATIVE_ASSOCIATION_DUES: 29,
  FEE_ENUM_COOPERATIVE_ASSOCIATION_SPECIAL_ASSESSMENT: 30,
  FEE_ENUM_COPY_OR_FAX_FEE: 31,
  FEE_ENUM_COURIER_FEE: 32,
  FEE_ENUM_CREDIT_DISABILITY_INSURANCE_PREMIUM: 33,
  FEE_ENUM_CREDIT_LIFE_INSURANCE_PREMIUM: 34,
  FEE_ENUM_CREDIT_PROPERTY_INSURANCE_PREMIUM: 35,
  FEE_ENUM_CREDIT_REPORT_FEE: 36,
  FEE_ENUM_CREDIT_UNEMPLOYMENT_INSURANCE_PREMIUM: 37,
  FEE_ENUM_DEBT_CANCELLATION_INSURANCE_PREMIUM: 38,
  FEE_ENUM_DEBT_SUSPENSION_INSURANCE_PREMIUM: 39,
  FEE_ENUM_DEED_PREPARATION_FEE: 40,
  FEE_ENUM_DISASTER_INSPECTION_FEE: 41,
  FEE_ENUM_DISCOUNT_ON_REPAIRS_FEE: 42,
  FEE_ENUM_DOCUMENTARY_STAMP_FEE: 43,
  FEE_ENUM_DOCUMENT_PREPARATION_FEE: 44,
  FEE_ENUM_DOWN_PAYMENT_PROTECTION_FEE: 45,
  FEE_ENUM_DRY_WALL_INSPECTION_FEE: 46,
  FEE_ENUM_ELECTRICAL_INSPECTION_FEE: 47,
  FEE_ENUM_ELECTRONIC_DOCUMENT_DELIVERY_FEE: 48,
  FEE_ENUM_ENVIRONMENTAL_INSPECTION_FEE: 49,
  FEE_ENUM_ESCROW_HOLDBACK_FEE: 50,
  FEE_ENUM_ESCROW_SERVICE_FEE: 51,
  FEE_ENUM_ESCROW_WAIVER_FEE: 52,
  FEE_ENUM_FILING_FEE: 53,
  FEE_ENUM_FLOOD_CERTIFICATION: 54,
  FEE_ENUM_FOUNDATION_INSPECTION_FEE: 55,
  FEE_ENUM_HEATING_COOLING_INSPECTION_FEE: 56,
  FEE_ENUM_HELOC_ANNUAL_FEE: 57,
  FEE_ENUM_HELOC_OVER_LIMIT_FEE: 58,
  FEE_ENUM_HIGH_COST_MORTGAGE_COUNSELING_FEE: 59,
  FEE_ENUM_HOME_INSPECTION_FEE: 60,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_DUES: 61,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_SERVICE_FEE: 62,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_SPECIAL_ASSESSMENT: 63,
  FEE_ENUM_HOME_WARRANTY_FEE: 64,
  FEE_ENUM_LATE_CHARGE: 65,
  FEE_ENUM_LEAD_INSPECTION_FEE: 66,
  FEE_ENUM_LENDERS_ATTORNEY_FEE: 67,
  FEE_ENUM_LOAN_DISCOUNT_POINTS: 68,
  FEE_ENUM_LOAN_LEVEL_PRICE_ADJUSTMENT: 69,
  FEE_ENUM_LOAN_ORIGINATION_FEE: 70,
  FEE_ENUM_LOAN_ORIGINATOR_COMPENSATION: 71,
  FEE_ENUM_MANUAL_UNDERWRITING_FEE: 72,
  FEE_ENUM_MANUFACTURED_HOUSING_INSPECTION_FEE: 73,
  FEE_ENUM_MANUFACTURED_HOUSING_PROCESSING_FEE: 74,
  FEE_ENUM_MERS_REGISTRATION_FEE: 75,
  FEE_ENUM_MI_INITIAL_PREMIUM: 76,
  FEE_ENUM_MI_UPFRONT_PREMIUM: 77,
  FEE_ENUM_MODIFICATION_FEE: 78,
  FEE_ENUM_MOLD_INSPECTION_FEE: 79,
  FEE_ENUM_MORTGAGE_BROKER_FEE: 80,
  FEE_ENUM_MORTGAGE_SURCHARGE_COUNTY_OR_PARISH: 81,
  FEE_ENUM_MORTGAGE_SURCHARGE_MUNICIPAL: 82,
  FEE_ENUM_MORTGAGE_SURCHARGE_STATE: 83,
  FEE_ENUM_MORTGAGE_TAX_CREDIT_SERVICE_FEE: 84,
  FEE_ENUM_MULTIPLE_LOANS_CLOSING_FEE: 85,
  FEE_ENUM_MUNICIPAL_LIEN_CERTIFICATE_FEE: 86,
  FEE_ENUM_NOTARY_FEE: 87,
  FEE_ENUM_NSF_FEE: 88,
  FEE_ENUM_OTHER: 89,
  FEE_ENUM_OUR_ORIGINATION_CHARGE_TOTAL: 90,
  FEE_ENUM_PARTIAL_LIEN_RELEASE_FEE: 91,
  FEE_ENUM_PAYOFF_REQUEST_FEE: 92,
  FEE_ENUM_PEST_INSPECTION_FEE: 93,
  FEE_ENUM_PLUMBING_INSPECTION_FEE: 94,
  FEE_ENUM_POWER_OF_ATTORNEY_PREPARATION_FEE: 95,
  FEE_ENUM_POWER_OF_ATTORNEY_RECORDING_FEE: 96,
  FEE_ENUM_PRECLOSING_VERIFICATION_CONTROL_FEE: 97,
  FEE_ENUM_PROCESSING_FEE: 98,
  FEE_ENUM_PROGRAM_GUARANTEE_FEE: 99,
  FEE_ENUM_PROPERTY_INSPECTION_WAIVER_FEE: 100,
  FEE_ENUM_RADON_INSPECTION_FEE: 101,
  FEE_ENUM_RATE_LOCK_FEE: 102,
  FEE_ENUM_REAL_ESTATE_COMMISSION_BUYERS_BROKER: 103,
  FEE_ENUM_REAL_ESTATE_COMMISSION_SELLERS_BROKER: 104,
  FEE_ENUM_RECAST_FEE: 105,
  FEE_ENUM_RECONVEYANCE_FEE: 106,
  FEE_ENUM_RECONVEYANCE_TRACKING_FEE: 107,
  FEE_ENUM_RECORDING_FEE_FOR_ASSIGNMENT: 108,
  FEE_ENUM_RECORDING_FEE_FOR_DEED: 109,
  FEE_ENUM_RECORDING_FEE_FOR_MORTGAGE: 110,
  FEE_ENUM_RECORDING_FEE_FOR_MUNICIPAL_LIEN_CERTIFICATE: 111,
  FEE_ENUM_RECORDING_FEE_FOR_OTHER_DOCUMENT: 112,
  FEE_ENUM_RECORDING_FEE_FOR_RELEASE: 113,
  FEE_ENUM_RECORDING_FEE_FOR_SUBORDINATION: 114,
  FEE_ENUM_RECORDING_FEE_TOTAL: 115,
  FEE_ENUM_RECORDING_SERVICE_FEE: 116,
  FEE_ENUM_REDRAW_FEE: 117,
  FEE_ENUM_REINSPECTION_FEE: 118,
  FEE_ENUM_RENOVATION_CONSULTANT_FEE: 119,
  FEE_ENUM_REPAIRS_FEE: 120,
  FEE_ENUM_ROOF_INSPECTION_FEE: 121,
  FEE_ENUM_SEPTIC_INSPECTION_FEE: 122,
  FEE_ENUM_SETTLEMENT_FEE: 123,
  FEE_ENUM_SIGNING_AGENT_FEE: 124,
  FEE_ENUM_SMOKE_DETECTOR_INSPECTION_FEE: 125,
  FEE_ENUM_STATE_TITLE_INSURANCE_FEE: 126,
  FEE_ENUM_STRUCTURAL_INSPECTION_FEE: 127,
  FEE_ENUM_SUBORDINATION_FEE: 128,
  FEE_ENUM_SURVEY_FEE: 129,
  FEE_ENUM_TAX_SERVICE_FEE: 130,
  FEE_ENUM_TAX_STAMP_FOR_CITY_DEED: 131,
  FEE_ENUM_TAX_STAMP_FOR_CITY_MORTGAGE: 132,
  FEE_ENUM_TAX_STAMP_FOR_COUNTY_DEED: 133,
  FEE_ENUM_TAX_STAMP_FOR_COUNTY_MORTGAGE: 134,
  FEE_ENUM_TAX_STAMP_FOR_STATE_DEED: 135,
  FEE_ENUM_TAX_STAMP_FOR_STATE_MORTGAGE: 136,
  FEE_ENUM_TAX_STATUS_RESEARCH_FEE: 137,
  FEE_ENUM_TEMPORARY_BUYDOWN_ADMINISTRATION_FEE: 138,
  FEE_ENUM_TEMPORARY_BUYDOWN_POINTS: 139,
  FEE_ENUM_TITLE_ABSTRACT_FEE: 140,
  FEE_ENUM_TITLE_BORROWER_CLOSING_PROTECTION_LETTER_FEE: 141,
  FEE_ENUM_TITLE_CERTIFICATION_FEE: 142,
  FEE_ENUM_TITLE_CLOSING_COORDINATION_FEE: 143,
  FEE_ENUM_TITLE_CLOSING_FEE: 144,
  FEE_ENUM_TITLE_CLOSING_PROTECTION_LETTER_FEE: 145,
  FEE_ENUM_TITLE_COMMITMENT_FEE: 146,
  FEE_ENUM_TITLE_DOCUMENT_PREPARATION_FEE: 147,
  FEE_ENUM_TITLE_ENDORSEMENT_FEE: 148,
  FEE_ENUM_TITLE_EXAMINATION_FEE: 149,
  FEE_ENUM_TITLE_FINAL_POLICY_SHORT_FORM_FEE: 150,
  FEE_ENUM_TITLE_INSURANCE_BINDER_FEE: 151,
  FEE_ENUM_TITLE_INSURANCE_FEE: 152,
  FEE_ENUM_TITLE_LENDERS_COVERAGE_PREMIUM: 153,
  FEE_ENUM_TITLE_NOTARY_FEE: 154,
  FEE_ENUM_TITLE_OWNERS_COVERAGE_PREMIUM: 155,
  FEE_ENUM_TITLE_SEARCH_FEE: 156,
  FEE_ENUM_TITLE_SERVICES_FEE_TOTAL: 157,
  FEE_ENUM_TITLE_SERVICES_SALES_TAX: 158,
  FEE_ENUM_TITLE_SUB_ESCROW_FEE: 159,
  FEE_ENUM_TITLE_SUBORDINATION_PROCESSING_FEE: 160,
  FEE_ENUM_TITLE_UNDERWRITING_ISSUE_RESOLUTION_FEE: 161,
  FEE_ENUM_TRANSFER_TAX_TOTAL: 162,
  FEE_ENUM_UNDERWRITING_FEE: 163,
  FEE_ENUM_USDA_RURAL_DEVELOPMENT_GUARANTEE_FEE: 164,
  FEE_ENUM_VA_FUNDING_FEE: 165,
  FEE_ENUM_VERIFICATION_OF_ASSETS_FEE: 166,
  FEE_ENUM_VERIFICATION_OF_EMPLOYMENT_FEE: 167,
  FEE_ENUM_VERIFICATION_OF_INCOME_FEE: 168,
  FEE_ENUM_VERIFICATION_OF_RESIDENCY_STATUS_FEE: 169,
  FEE_ENUM_VERIFICATION_OF_TAXPAYER_IDENTIFICATION_FEE: 170,
  FEE_ENUM_VERIFICATION_OF_TAX_RETURN_FEE: 171,
  FEE_ENUM_WATER_TESTING_FEE: 172,
  FEE_ENUM_WELL_INSPECTION_FEE: 173,
  FEE_ENUM_WIRE_TRANSFER_FEE: 174,
  FEE_ENUM_TRANSFER_MORTGAGE_TAX: 175,
  FEE_ENUM_TRANSFER_DEED_TAX: 176,
  FEE_ENUM_TRANSFER_TAX: 177,
  FEE_ENUM_203_K_ARCHITECTURAL_AND_ENGINEERING_FEE: 178,
  FEE_ENUM_AMORTIZATION_FEE: 179,
  FEE_ENUM_CLO_ACCESS_FEE: 180,
  FEE_ENUM_DOCUMENT_SIGNING_FEE: 181,
  FEE_ENUM_EARTHQUAKE_INSURANCE_PREMIUM: 182,
  FEE_ENUM_FLOOD_DETERMINATION: 183,
  FEE_ENUM_FLOOD_INSURANCE_PREMIUM: 184,
  FEE_ENUM_FLOOD_MONITORING: 185,
  FEE_ENUM_FUNDING_WIRE_OR_DISBURSEMENT_FEE: 186,
  FEE_ENUM_HAIL_INSURANCE_PREMIUM: 187,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_CERTIFICATION: 188,
  FEE_ENUM_INTANGIBLE_TAX: 189,
  FEE_ENUM_MORTGAGE_INSURANCE_PMI_SINGLE_PREMIUM: 190,
  FEE_ENUM_MORTGAGE_INSURANCE_APPLICATION_FEE: 191,
  FEE_ENUM_NEW_LOAN_ADMINISTRATION_FEE: 192,
  FEE_ENUM_RATE_LOCK_EXTENSION: 193,
  FEE_ENUM_TAX_TRANSCRIPT_FEE: 194,
  FEE_ENUM_THIRD_PARTY_SUBORDINATE_FEE: 195,
  FEE_ENUM_TIE_IN_FEE: 196,
  FEE_ENUM_TITLE_ATTORNEY_FEE: 197,
  FEE_ENUM_TITLE_COURIER_FEE: 198,
  FEE_ENUM_TITLE_SETTLMENT_FEE: 199,
  FEE_ENUM_TITLE_SURVEY_FEE: 200,
  FEE_ENUM_TITLE_WARRANTY_FEE: 201,
  FEE_ENUM_VERIFICATION_FEE: 202,
  FEE_ENUM_VERIFICATION_FEE_3RD_PARTY: 203,
  FEE_ENUM_VOLCANO_INSURANCE_PREMIUM: 204,
  FEE_ENUM_WAREHOUSING_FEE: 205,
  FEE_ENUM_WIND_AND_STORM_INSURANCE_PREMIUM: 206
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeePaidToEnum = {
  FEE_PAID_TO_ENUM_UNKNOWN: 0,
  FEE_PAID_TO_ENUM_BROKER: 1,
  FEE_PAID_TO_ENUM_BROKER_AFFILIATE: 2,
  FEE_PAID_TO_ENUM_INVESTOR: 3,
  FEE_PAID_TO_ENUM_LENDER: 4,
  FEE_PAID_TO_ENUM_LENDER_AFFILIATE: 5,
  FEE_PAID_TO_ENUM_OTHER: 6,
  FEE_PAID_TO_ENUM_THIRD_PARTY_PROVIDER: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeePercentBasisEnum = {
  FEE_PERCENT_BASIS_ENUM_UNKNOWN: 0,
  FEE_PERCENT_BASIS_ENUM_BASE_LOAN_AMOUNT: 1,
  FEE_PERCENT_BASIS_ENUM_NOTE_AMOUNT: 2,
  FEE_PERCENT_BASIS_ENUM_OTHER: 3,
  FEE_PERCENT_BASIS_ENUM_PROPERTY_APPRAISED_VALUE_AMOUNT: 4,
  FEE_PERCENT_BASIS_ENUM_PURCHASE_PRICE_AMOUNT: 5,
  FEE_PERCENT_BASIS_ENUM_FIXED: 6,
  FEE_PERCENT_BASIS_ENUM_VARIABLE: 7,
  FEE_PERCENT_BASIS_ENUM_ORIGINAL_LOAN_AMOUNT: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeeProcessEnum = {
  FEE_PROCESS_ENUM_UNKNOWN: 0,
  FEE_PROCESS_ENUM_ORIGINATION: 1,
  FEE_PROCESS_ENUM_OTHER: 2,
  FEE_PROCESS_ENUM_SERVICING: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.GFEAggregationEnum = {
  GFE_AGGREGATION_ENUM_UNKNOWN: 0,
  GFE_AGGREGATION_ENUM_CHOSEN_INTEREST_RATE_CREDIT_OR_CHARGE: 1,
  GFE_AGGREGATION_ENUM_COMBINED_OUR_ORIGINATION_AND_INTEREST_RATE_CREDIT_OR_CHARGE: 2,
  GFE_AGGREGATION_ENUM_GOVERNMENT_RECORDING_CHARGES: 3,
  GFE_AGGREGATION_ENUM_HOMEOWNERS_INSURANCE: 4,
  GFE_AGGREGATION_ENUM_NONE: 5,
  GFE_AGGREGATION_ENUM_OTHER: 6,
  GFE_AGGREGATION_ENUM_OUR_ORIGINATION_CHARGE: 7,
  GFE_AGGREGATION_ENUM_OWNERS_TITLE_INSURANCE: 8,
  GFE_AGGREGATION_ENUM_REQUIRED_SERVICES_LENDER_SELECTED: 9,
  GFE_AGGREGATION_ENUM_REQUIRED_SERVICES_YOU_CAN_SHOP_FOR: 10,
  GFE_AGGREGATION_ENUM_TITLE_SERVICES: 11,
  GFE_AGGREGATION_ENUM_TRANSFER_TAXES: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum = {
  INTEGRATED_DISCLOSURE_SECTION_ENUM_UNKNOWN: 0,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_FROM_BORROWER_AT_CLOSING: 1,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_FROM_SELLER_AT_CLOSING: 2,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_TO_SELLER_AT_CLOSING: 3,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_INITIAL_ESCROW_PAYMENT_AT_CLOSING: 4,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_ORIGINATION_CHARGES: 5,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_OTHER: 6,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_OTHER_COSTS: 7,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PAID_ALREADY_BY_OR_ON_BEHALF_OF_BORROWER_AT_CLOSING: 8,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PAYOFFS_AND_PAYMENTS: 9,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PREPAIDS: 10,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_BORROWER_DID_NOT_SHOP_FOR: 11,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_BORROWER_DID_SHOP_FOR: 12,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CANNOT_SHOP_FOR: 13,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CAN_SHOP_FOR: 14,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TAXES_AND_OTHER_GOVERNMENT_FEES: 15,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_CLOSING_COSTS: 16,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_LOAN_COSTS: 17,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_OTHER_COSTS: 18
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum = {
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_UNKNOWN: 0,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_ADJUSTMENTS: 1,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_ADJUSTMENTS_FOR_ITEMS_PAID_BY_SELLER_IN_ADVANCE: 2,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_ADJUSTMENTS_FOR_ITEMS_UNPAID_BY_SELLER: 3,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_CLOSING_COSTS_SUBTOTAL: 4,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_LENDER_CREDITS: 5,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_LOAN_COSTS_SUBTOTAL: 6,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_OTHER: 7,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_OTHER_COSTS_SUBTOTAL: 8,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_OTHER_CREDITS: 9,
  INTEGRATED_DISCLOSURE_SUBSECTION_ENUM_TOTAL_CLOSING_COSTS_SELLER_ONLY: 10
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.SectionClassificationEnum = {
  SECTION_CLASSIFICATION_ENUM_UNKNOWN: 0,
  SECTION_CLASSIFICATION_ENUM_100: 1,
  SECTION_CLASSIFICATION_ENUM_1000: 2,
  SECTION_CLASSIFICATION_ENUM_1100: 3,
  SECTION_CLASSIFICATION_ENUM_1200: 4,
  SECTION_CLASSIFICATION_ENUM_1300: 5,
  SECTION_CLASSIFICATION_ENUM_200: 6,
  SECTION_CLASSIFICATION_ENUM_300: 7,
  SECTION_CLASSIFICATION_ENUM_400: 8,
  SECTION_CLASSIFICATION_ENUM_500: 9,
  SECTION_CLASSIFICATION_ENUM_600: 10,
  SECTION_CLASSIFICATION_ENUM_700: 11,
  SECTION_CLASSIFICATION_ENUM_800: 12,
  SECTION_CLASSIFICATION_ENUM_900: 13
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum = {
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_UNKNOWN: 0,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_BROKER: 1,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_BROKER_AFFILIATE: 2,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_INVESTOR: 3,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_LENDER: 4,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_LENDER_AFFILIATE: 5,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_OTHER: 6,
  DEFAULT_FEE_PAID_TO_TYPE_ENUM_THIRD_PARTY_PROVIDER: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum = {
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_UNKNOWN: 0,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_BORROWER: 1,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_BROKER: 2,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_BUYER: 3,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_CORRESPONDENT: 4,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_LENDER: 5,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_SELLER: 6,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_SERVICE: 7,
  DEFAULT_FEE_PAYMENT_PAID_BY_TYPE_ENUM_THIRD_PARTY: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum = {
  DEFAULT_FEE_PAYMENT_TIMING_TYPE_ENUM_UNKNOWN: 0,
  DEFAULT_FEE_PAYMENT_TIMING_TYPE_ENUM_AT_CLOSING: 1,
  DEFAULT_FEE_PAYMENT_TIMING_TYPE_ENUM_PRIOR_TO_CLOSING: 2,
  DEFAULT_FEE_PAYMENT_TIMING_TYPE_ENUM_FINANCED: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum = {
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_UNKNOWN: 0,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_100_GROSS_AMOUNT_DUE_FROM_BORROWER: 1,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_200_AMOUNTS_PAID_BY_OR_IN_BEHALF_OF_BORROWER: 2,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_300_CASH_AT_SETTLEMENT_FROM_TO_BORROWER: 3,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_400_GROSS_AMOUNT_DUE_TO_SELLER: 4,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_500_REDUCTIONS_IN_AMOUNT_DUE_SELLER: 5,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_600_CASH_AT_SETTLEMENT_TO_FROM_SELLER: 6,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_700_DIVISION_OF_COMISSIONS: 7,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_800_LOAN_FEES: 8,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_900_LENDER_REQUIRED_PAID_IN_ADVANCE: 9,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_1000_RESERVES_DEPOSITED_WITH_LENDER: 10,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_1100_TITLE_CHANGES: 11,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_1200_RECORDING_AND_TRANSFER_CHARGES: 12,
  HUD_1_SECTION_CLASSIFICATION_TYPE_ENUM_1300_ADDITIONAL_SETTLEMENT_CHARGES: 13
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.PartyRoleTypeEnum = {
  PARTY_ROLE_TYPE_ENUM_LOAN_ORIGINATOR: 0,
  PARTY_ROLE_TYPE_ENUM_LOAN_PROCESSOR: 1,
  PARTY_ROLE_TYPE_ENUM_LOAN_UNDERWRITER: 2,
  PARTY_ROLE_TYPE_ENUM_LOAN_CLOSER: 3,
  PARTY_ROLE_TYPE_ENUM_LOAN_FUNDER: 4,
  PARTY_ROLE_TYPE_ENUM_LOAN_DELIVER_FILE_PREPARER: 5,
  PARTY_ROLE_TYPE_ENUM_LOAN_ASSISTANT: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeeCalculationTypeEnum = {
  FEE_CALCULATION_TYPE_ENUM_UNKNOWN: 0,
  FEE_CALCULATION_TYPE_ENUM_FIXED: 1,
  FEE_CALCULATION_TYPE_ENUM_PERCENT: 2,
  FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT: 3,
  FEE_CALCULATION_TYPE_ENUM_VARIABLE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.FeeToleranceCategoryType = {
  FEE_TOLERANCE_CATEGORY_TYPE_UNKNOWN: 0,
  FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE: 1,
  FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE: 2,
  FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE: 3,
  FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT: 4
};

goog.object.extend(exports, proto.wilqo.api.mortgage_config);
