import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { AdminLoanPartyRoleTypesEnum } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { GetUserListByInstitutionQueryRequest, GetUserListByInstitutionQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.GetUserListByInstitutionQueryRequest';

const useInstitutionUsers = (searchTerm?: string, selectedPartyType?: AdminLoanPartyRoleTypesEnum, enabled = true) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, searchTerm, selectedPartyType], async () => {
    const request = new GetUserListByInstitutionQueryRequest();
    request.setSearchTerm(searchTerm || '');
    if (selectedPartyType !== undefined) request.setPartyRoleTypesList([selectedPartyType]);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetUserListByInstitutionQueryResponse();
    GetUserListByInstitutionQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, {
      list: responseObj.usersList,
      totalItems: 0,
      totalPages: 0,
    });
  }, {
    enabled,
    select: (response) => ({
      ...response,
      list: response?.list.map((user) => ({
        ...user,
        loanPartyRoleTypesList: user.loanPartyRoleTypesList.map((partyType) => ({ id: partyType, name: ConvertEnum(AdminLoanPartyRoleTypesEnum, partyType) })),
      })),
    }),
  });
};
export { useInstitutionUsers };
