import Checkbox from '../Checkbox/Checkbox.component';
import { Icon } from '../Icon';
import { Switch } from '../Switch';
import { Typography } from '../Typography';

export interface Props {
  title: string;
  subtitle: string;
  options: Array<{
    name: string;
    disabled?: boolean;
  }>;
  displayOnly: true;
  checked: boolean;
  selectedOption?: string;
  onClickCheckbox: (bool: boolean) => void;
  onClickSelect: (id: string) => void;
}

export default (props: Props) => {
  const {
    checked,
    onClickCheckbox,
    onClickSelect,
    options,
    selectedOption,
    subtitle,
    title,
  } = props;

  const onSelect = () => {
    onClickCheckbox(true);
    onClickSelect(options[0].name);
  };

  const renderUnchecked = () => (
    <Checkbox label={title} onClick={onSelect} value={false} />
  );

  const renderChecked = () => (
    <div className="flex flex-row justify-between w-full items-center bg-primary-on-surface-states-activated py-2 px-4">
      <div className="flex-1">
        <div
          className="flex flex-row items-center"
          onClick={() => onClickCheckbox(false)}
          onKeyDown={() => onClickCheckbox(false)}
          role="button"
          tabIndex={0}
        >
          <Icon className="mr-3 text-primary" icon="CheckBox" />
          <Typography variant="body2">{title}</Typography>
        </div>
        <Typography variant="caption">{subtitle}</Typography>
      </div>
      <Switch
        className="w-fit"
        displayOnly
        onSelect={onClickSelect}
        optionsLabels={options}
        selectedItem={selectedOption || options[0].name}
      />
    </div>
  );

  return (
    <div className="flex flex-col justify-between">
      {!checked ? renderUnchecked() : renderChecked()}
    </div>
  );
};
