import type { ComponentProps } from './Checkbox.component';
import Component from './Checkbox.component';
import type { ICheckboxProps as DDEProps } from './Checkbox.dde';
import DDE from './Checkbox.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

export type CheckboxProps = ExtendedComponentProps | ExtendedDDEProps;

const Checkbox = (props: CheckboxProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { Checkbox };
