import { useMemo, useState } from 'react';

import type { AdminActivitySummary } from '@/API/Models/Wilqo_API_Activity_Admin_pb';
import { useArchiveActivityTemplate } from '@/API/Queries/activities/useArchiveActivityTemplate';
import { useBPDActivitiesTemplates } from '@/API/Queries/activities/useBPDActivitiesTemplates';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useTableConfig } from '@/Components/Features/table/useTableConfig';
import { useNavigate } from '@/Routes/NavigationContext';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { ActivityTemplateCreator } from '../creator/activityTemplateCreator';

const ActivityListComponent = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  const [openModal, setOpenModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<Array<AdminActivitySummary.AsObject>>([]);

  const tableConfig = useTableConfig();
  const { data, isLoading, refetch } = useBPDActivitiesTemplates(
    tableConfig.page,
    tableConfig.pagePerPage,
    tableConfig.search,
    true,
    showArchived,
  );
  const { mutate: archiveActivity } = useArchiveActivityTemplate();

  const onClickEdit = (id: string) => {
    navigate(`/admin/activity/${id}/configurator/content`);
  };

  const columns = useMemo((): Column<AdminActivitySummary.AsObject>[] => [
    { header: 'Activity Name', id: 'name', key: { text: 'title', type: 'text' } },
    { header: 'Internal Name', id: 'internalName', key: { text: 'internalName', type: 'text' } },
    { header: 'Last published', id: 'published', key: { text: (activity: any) => activity.lastPublish?.tzDetails?.dateTime, type: 'text' } },
  ], []);

  const handleArchive = (activities = selectedActivities, archived = !showArchived) => {
    archiveActivity(
      {
        activities: activities.map((activity) => activity.entityId),
        archived,
      },
      {

        onError: (error: Error) => showSnackBar({ message: error.message || 'Something went wrong', open: true }),
        onSuccess: () => {
          showSnackBar({ message: 'Activity(ies) updated', open: true });
          setSelectedActivities([]);
          refetch();
        },
      },
    );
  };

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: () => setOpenModal(true) }],
            contextualHeaderProps: {
              buttons: [
                { label: showArchived ? 'Unarchive' : 'Archive', onClick: () => handleArchive() },
              ],
              caption: `${selectedActivities.length}(s) selected`,
            },
            filtersProps: {
              items: [{ onClick: () => setShowArchived(!showArchived), selected: showArchived, value: 'Archived' }],
              variant: 'flex',
            },
            searchProps: { onChange: tableConfig.setSearch, value: tableConfig.search },
            showContextual: selectedActivities.length > 0,
            title: 'All Activity Templates',
          },
        }}
        columns={columns}
        currentPage={tableConfig.page}
        data={data?.list || []}
        defaultPageSize={tableConfig.pagePerPage}
        handleNewPage={tableConfig.setPage}
        handleNewPageSize={tableConfig.setPagePerPage}
        hasOwnPagination
        hoverActions={selectedActivities.length > 0
          ? []
          : [{ label: showArchived ? 'Unarchive' : 'Archive', onClick: (activity) => handleArchive([activity]) },
          ]}
        isLoading={isLoading}
        numberOfPages={data?.totalPages}
        onClickRow={(item) => onClickEdit(item.entityId)}
        rowActionType="dropdown"
        setRowSelection={setSelectedActivities}
        totalItems={data?.totalItems}
      />
      <ActivityTemplateCreator
        closeModal={() => setOpenModal(false)}
        open={openModal}
        showActivity={() => {}}
      />
    </>
  );
};

const ActivityList = ActivityListComponent;

export { ActivityList };
