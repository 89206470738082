import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import Component from './CheckboxSelect.component';

interface SelectedItem {
  isSelected: boolean;
  selectedOptionId: string;
}

export default (props: PanelElement.AsObject) => {
  const [value, setSelected] = useDynamicFormRegister<SelectedItem>(
    props.id,
    {
      fromDynamic: (dynamic) => JSON.parse(toString(dynamic)) as SelectedItem,
      toDynamic: (value) => fromString(JSON.stringify(value)),
    },
  );

  return (
    <Component
      checked={value?.isSelected || false}
      displayOnly
      onClickCheckbox={(isSelected) => setSelected({ isSelected, selectedOptionId: '' }, '')}
      onClickSelect={(selectedOptionId) => setSelected({ isSelected: true, selectedOptionId }, '')}
      options={props.optionsList.map((opt) => ({
        disabled: false,
        name: opt.headerText,
      }))}
      selectedOption={value?.selectedOptionId}
      subtitle={props.summaryText}
      title={props.headerText}
    />
  );
};
