import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetIntentToProceedStatusQueryRequest, GetIntentToProceedStatusQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const GET_INTENT_TO_PROCEED_MESSAGE = 'wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest';

const useGetIntentToProceedStatus = (dealId: string) => {
  const { commandResponse } = useBackend();
  const authContext = useAuth();
  const bpid = authContext.secureAuthValues.authorizedBusinessProcessDomainIds[0];

  return useQuery([GET_INTENT_TO_PROCEED_MESSAGE, dealId], async () => {
    const request = new GetIntentToProceedStatusQueryRequest();
    request.setDealId(dealId);
    request.setBusinessProcessDomainId(bpid);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: GET_INTENT_TO_PROCEED_MESSAGE,
    });
    const response = new GetIntentToProceedStatusQueryResponse();
    GetIntentToProceedStatusQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(GET_INTENT_TO_PROCEED_MESSAGE, responseObj.error, responseObj);
  }, {
    cacheTime: 1,
    enabled: Boolean(dealId) && Boolean(bpid),
    keepPreviousData: false,
  });
};
export { useGetIntentToProceedStatus };
