export interface IRole {
  readonly name: string;
  readonly mask: Uint8Array;
}
export const Authenticated: IRole = {
  mask: new Uint8Array([0x01]),
  name: 'Authenticated',
};
export const Consumer: IRole = {
  mask: new Uint8Array([0x02]),
  name: 'Consumer',
};
export const LoanListReader: IRole = {
  mask: new Uint8Array([0x04]),
  name: 'Loan List Reader',
};
export const LoanListCreateRead: IRole = {
  mask: new Uint8Array([0x40]),
  name: 'Loan List Create Read',
};
export const ActivityListReader: IRole = {
  mask: new Uint8Array([0x08]),
  name: 'Activity List Reader',
};
export const Admin: IRole = {
  mask: new Uint8Array([0x10]),
  name: 'Admin',
};
