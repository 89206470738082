import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb';

import type { DataFieldTemplateSummary } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb';
import { GetDataFieldTemplatesQueryRequest, GetDataFieldTemplatesQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetDataFieldTemplatesQueryRequest';
interface Field extends DataFieldTemplateSummary.AsObject {
  pathname: string;
}

const useDataFieldTemplatesForContent = (search: string) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, search], async () => {
    const request = new GetDataFieldTemplatesQueryRequest();
    request.setSearchTerm(search);
    request.setAllowUseInActivityContentEditor(new BoolValue().setValue(true));
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetDataFieldTemplatesQueryResponse();
    GetDataFieldTemplatesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.fieldGroupsList);
  },
  {
    select: (data) => data?.reduce((acc: Array<Field>, current) => {
      const currentFields = current.dataFieldsList.map((field) => ({ ...field, pathname: current.displayName }));
      return [
        ...acc,
        ...currentFields,
      ];
    }, []),
  });
};
export { useDataFieldTemplatesForContent };
