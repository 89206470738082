import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateFeesOriginationDiscountCommandRequest, UpdateFeesOriginationDiscountCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { FeesOriginationDiscountSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toFeesOriginationDiscountSectionFromObject } from '../helpers/feesCreditsHelper';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface UseUpdateFeesOriginationDiscountDTO {
  dealId: string;
  feesOriginationDiscountSection: FeesOriginationDiscountSection.AsObject;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.UpdateFeesOriginationDiscountCommandRequest';

const useUpdateFeesOriginationDiscount = () => {
  const { commandResponse } = useBackend();

  const UpdateFeesOriginationDiscount = async (updateObject: UseUpdateFeesOriginationDiscountDTO) => {
    const request = new UpdateFeesOriginationDiscountCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setSection(toFeesOriginationDiscountSectionFromObject(updateObject.feesOriginationDiscountSection));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateFeesOriginationDiscountCommandResponse();
    UpdateFeesOriginationDiscountCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpdateFeesOriginationDiscount);
};

export { useUpdateFeesOriginationDiscount };
