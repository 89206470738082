import { Disclosure } from '@headlessui/react';
import { useMemo } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { LicenseStatusEnum } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { AccordionButton } from '@/Components/Atoms/AccordionButton';
import { Button } from '@/Components/Atoms/Button';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import states from '@/Components/Atoms/StateSelect/states';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { getDateFormatFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { getPanelElement, getPanelElementMask, getPanelElementOption, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';

import type { FormProgress } from '../dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '../dynamicForm/DynamicFormContext';

const STATES: Array<PanelElementOption.AsObject> = states.map((s) => getPanelElementOption({ headerText: s.name, id: s.abbreviation.toLowerCase(), summaryText: s.abbreviation }));

const STATUS_OPTIONS: Array<PanelElementOption.AsObject> = [
  getPanelElementOption({ headerText: 'Approved', id: String(LicenseStatusEnum.LICENSE_STATUS_ENUM_APPROVED) }),
  getPanelElementOption({ headerText: 'Pending', id: String(LicenseStatusEnum.LICENSE_STATUS_ENUM_PENDING) }),
  getPanelElementOption({ headerText: 'Temporary', id: String(LicenseStatusEnum.LICENSE_STATUS_ENUM_TEMPORARY) }),
  getPanelElementOption({ headerText: 'Unknown', id: String(LicenseStatusEnum.LICENSE_STATUS_ENUM_UNKNOWN) }),
];

const ENABLED_OPTIONS = [
  getPanelElementOption({ headerText: 'Yes', id: 'yes' }),
  getPanelElementOption({ headerText: 'No', id: 'no' }),
];

const panelElements = [
  getPanelElement({ headerText: 'Regulator Name', id: 'regulator', optionsList: STATES, path: 'licenseScope', requirement: getPanelElementRequiment('Required'), type: 'state-select' }),
  getPanelElement({ headerText: 'License Identifier', id: 'licenseId', requirement: getPanelElementRequiment('Required'), type: 'text' }),
  getPanelElement({ headerText: 'Start Date', id: 'startDate', mask: getPanelElementMask({ type: 'date' }), requirement: getPanelElementRequiment('Required'), type: 'date' }),
  getPanelElement({ headerText: 'License Name', id: 'licenseName', requirement: getPanelElementRequiment('Required'), type: 'text' }),
  getPanelElement({ headerText: 'NMLS License Status', id: 'status', optionsList: STATUS_OPTIONS, requirement: getPanelElementRequiment('Required'), type: 'select' }),
  getPanelElement({ headerText: 'Expiration Date', id: 'expirationDate', mask: getPanelElementMask({ type: 'date' }), requirement: getPanelElementRequiment('Required'), type: 'date' }),
  getPanelElement({ headerText: 'License Enabled', id: 'enabled', optionsList: ENABLED_OPTIONS, requirement: getPanelElementRequiment('Required'), type: 'select' }),
];

interface LicenseFormProps {
  selectedLicense?: License.AsObject;
  handleSubmit: (formData: any) => void;
  closeModal: () => void;
}

const LicenseForm = (props: LicenseFormProps) => {
  const { closeModal, handleSubmit, selectedLicense } = props;

  const progress = useMemo((): FormProgress | undefined => {
    if (selectedLicense) {
      const selectedState = states.find((state) => selectedLicense.licenseScope.toUpperCase() === state.abbreviation.toUpperCase());
      return {
        values: {
          enabled: { value: ENABLED_OPTIONS[selectedLicense.enabled ? 0 : 1] },
          expirationDate: { value: selectedLicense.expirationDate ? getDateFormatFromTimestamp(selectedLicense.expirationDate) : '' },
          licenseId: { value: selectedLicense.licenseId },
          licenseName: { value: selectedLicense.licenseLegalName },
          regulator: { value: getPanelElementOption({ headerText: selectedState?.name, id: selectedState?.abbreviation }) },
          startDate: { value: selectedLicense.startDate ? getDateFormatFromTimestamp(selectedLicense.startDate) : '' },
          status: { value: STATUS_OPTIONS.find((option) => option.id === String(selectedLicense.licenseStatusType)) },
        },
      };
    }
    return undefined;
  }, [selectedLicense]);

  return (
    <DialogLayout asChild>
      <DynamicFormProvider className="h-full flex flex-col" onSubmit={handleSubmit} progress={progress}>
        {
          ({ hasError }) => (
            <>
              <DialogHeader title={selectedLicense?.licenseLegalName || 'Create new License'} />

              <DialogContent>
                <div className="flex-1 p-12">
                  <Disclosure defaultOpen>
                    {
                      ({ open }: { open: boolean }) => (
                        <>
                          <AccordionButton open={open} title="License Details" />
                          <Disclosure.Panel className="grid grid-cols-3 gap-6 mt-10">
                            {panelElements.map((element) => (
                              <PanelElementRenderer {...element} variant="default" />
                            ))}
                          </Disclosure.Panel>
                        </>
                      )
                    }
                  </Disclosure>
                </div>
              </DialogContent>

              <DialogFooter borderTop span>
                <Button
                  label="cancel"
                  onClick={closeModal}
                  variant="tertiary"
                />
                <Button
                  disabled={hasError}
                  label="save"
                  type="submit"
                />
              </DialogFooter>
            </>
          )
        }
      </DynamicFormProvider>
    </DialogLayout>
  );
};

export { LicenseForm };
