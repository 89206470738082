import { Message } from 'google-protobuf';
import type { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import useEvent from 'react-use-event-hook';

import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useHttp } from './useHttp';
import { useWebSocket } from './useWebSocket';

export abstract class RequestMessage extends Message {
  abstract getBusinessProcessDomainId(): (StringValue | string | undefined);
  abstract setBusinessProcessDomainId(id: (StringValue | string | undefined)): void;
}

export interface WilqoMessage {
  msg: RequestMessage;
  msgName: string;
}

const useBackend = (allowUnAuthenticated = false) => {
  const { commandResponse: httpCommandResponse } = useHttp();
  const { commandResponse: wsCommandResponse, isConnected } = useWebSocket();
  const bpdId = useBPDId();

  const commandResponse = useEvent((message: WilqoMessage) => {
    const existingBpdIdInRequest = message.msg.getBusinessProcessDomainId();
    if (existingBpdIdInRequest === '' || existingBpdIdInRequest === null || existingBpdIdInRequest === undefined) {
      message.msg.setBusinessProcessDomainId(bpdId);
    }
    if (isConnected) {
      return wsCommandResponse(message);
    }
    return httpCommandResponse(message, allowUnAuthenticated);
  });

  return {
    commandResponse,
  };
};

export { useBackend };
