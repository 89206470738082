// source: Wilqo_API_Mortgage_Integrations_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_CustomWrappers_pb = require('./Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_API_Mortgage_Models_pb = require('./Wilqo_API_Mortgage_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Models_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb = require('./Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb);
goog.exportSymbol('proto.wilqo.api.mortgage.AusSearchResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocutechDocument', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeQuestionResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocutechDocumentListResult', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanProspectorResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntegrationVendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProduct', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProductDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProductRateDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProductStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OptionalFieldsResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PricingSearchRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PricingSearchResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Product', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Rate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ReportType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PricingSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PricingSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PricingSearchRequest.displayName = 'proto.wilqo.api.mortgage.PricingSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Product.displayName = 'proto.wilqo.api.mortgage.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PricingSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.PricingSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.PricingSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PricingSearchResponse.displayName = 'proto.wilqo.api.mortgage.PricingSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanProduct.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanProduct.displayName = 'proto.wilqo.api.mortgage.LoanProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Rate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Rate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Rate.displayName = 'proto.wilqo.api.mortgage.Rate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeQuestionResponse.displayName = 'proto.wilqo.api.mortgage.FeeQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.OptionalFieldsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.OptionalFieldsResponse.displayName = 'proto.wilqo.api.mortgage.OptionalFieldsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AusSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusSearchResponse.displayName = 'proto.wilqo.api.mortgage.AusSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanProspectorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanProspectorResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanProspectorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocutechDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocutechDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocutechDocument.displayName = 'proto.wilqo.api.mortgage.DocutechDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetDocutechDocumentListResult.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocutechDocumentListResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocutechDocumentListResult.displayName = 'proto.wilqo.api.mortgage.GetDocutechDocumentListResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.displayName = 'proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanProductDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanProductDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanProductDetail.displayName = 'proto.wilqo.api.mortgage.LoanProductDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanProductRateDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanProductRateDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanProductRateDetail.displayName = 'proto.wilqo.api.mortgage.LoanProductRateDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PricingSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PricingSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    occupancyType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    addressLine1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    county: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zipCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    estimatedValue: (f = msg.getEstimatedValue()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    estimatedRemainingBalance: (f = msg.getEstimatedRemainingBalance()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    estimatedCredit: (f = msg.getEstimatedCredit()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    refinanceReason: jspb.Message.getFieldWithDefault(msg, 10, 0),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fico: jspb.Message.getFieldWithDefault(msg, 15, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    dealAsJson: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PricingSearchRequest;
  return proto.wilqo.api.mortgage.PricingSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum} */ (reader.readEnum());
      msg.setOccupancyType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLine1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZipCode(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEstimatedValue(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEstimatedRemainingBalance(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEstimatedCredit(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason} */ (reader.readEnum());
      msg.setRefinanceReason(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFico(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealAsJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PricingSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PricingSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOccupancyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCounty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZipCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEstimatedValue();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedRemainingBalance();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedCredit();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRefinanceReason();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFico();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDealAsJson();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum = {
  SINGLE_FAMILY: 0,
  DUPLEX: 1,
  CONDO: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum = {
  PRIMARY_RESIDENCE: 0,
  SECOND_HOME: 1,
  INVESTMENT: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason = {
  LOWER_INTEREST: 0,
  GET_CASH_FROM_EQUITY: 1
};

/**
 * optional PropertyTypeEnum property_type = 1;
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getPropertyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest.PropertyTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional OccupancyTypeEnum occupancy_type = 2;
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getOccupancyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest.OccupancyTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setOccupancyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string address_line_1 = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string county = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getCounty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setCounty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zip_code = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal estimated_value = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getEstimatedValue = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
*/
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setEstimatedValue = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.clearEstimatedValue = function() {
  return this.setEstimatedValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.hasEstimatedValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal estimated_remaining_balance = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getEstimatedRemainingBalance = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
*/
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setEstimatedRemainingBalance = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.clearEstimatedRemainingBalance = function() {
  return this.setEstimatedRemainingBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.hasEstimatedRemainingBalance = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal estimated_credit = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getEstimatedCredit = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
*/
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setEstimatedCredit = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.clearEstimatedCredit = function() {
  return this.setEstimatedCredit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.hasEstimatedCredit = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional RefinanceReason refinance_reason = 10;
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getRefinanceReason = function() {
  return /** @type {!proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PricingSearchRequest.RefinanceReason} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setRefinanceReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string phone = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 fico = 15;
 * @return {number}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getFico = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setFico = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string first_name = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string last_name = 17;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string deal_as_json = 18;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.getDealAsJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchRequest} returns this
 */
proto.wilqo.api.mortgage.PricingSearchRequest.prototype.setDealAsJson = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    rate: (f = msg.getRate()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanTermYears: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isEligible: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    rateLockPeriodDays: jspb.Message.getFieldWithDefault(msg, 10, 0),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Product}
 */
proto.wilqo.api.mortgage.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Product;
  return proto.wilqo.api.mortgage.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Product}
 */
proto.wilqo.api.mortgage.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermYears(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmortizationType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEligible(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRateLockPeriodDays(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermYears();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAmortizationType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsEligible();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRateLockPeriodDays();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string search_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Product.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.Product.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Product.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
*/
proto.wilqo.api.mortgage.Product.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Product.prototype.hasApr = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal rate = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Product.prototype.getRate = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
*/
proto.wilqo.api.mortgage.Product.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Product.prototype.hasRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Product.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
*/
proto.wilqo.api.mortgage.Product.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Product.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 loan_term_years = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage.Product.prototype.getLoanTermYears = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setLoanTermYears = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string amortization_type = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.Product.prototype.getAmortizationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_eligible = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Product.prototype.getIsEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setIsEligible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Product.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
*/
proto.wilqo.api.mortgage.Product.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Product.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint32 rate_lock_period_days = 10;
 * @return {number}
 */
proto.wilqo.api.mortgage.Product.prototype.getRateLockPeriodDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setRateLockPeriodDays = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string unique_identifier = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.Product.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Product} returns this
 */
proto.wilqo.api.mortgage.Product.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.PricingSearchResponse.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PricingSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PricingSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PricingSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.wilqo.api.mortgage.Product.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    integrationSourceTrackId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanProductsList: jspb.Message.toObjectList(msg.getLoanProductsList(),
    proto.wilqo.api.mortgage.LoanProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PricingSearchResponse;
  return proto.wilqo.api.mortgage.PricingSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PricingSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.Product;
      reader.readMessage(value,proto.wilqo.api.mortgage.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationSourceTrackId(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.LoanProduct;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanProduct.deserializeBinaryFromReader);
      msg.addLoanProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PricingSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PricingSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PricingSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.Product.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIntegrationSourceTrackId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.LoanProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Product products = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.Product>}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.Product, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.Product>} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
*/
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.Product}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string integration_source_track_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.getIntegrationSourceTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.setIntegrationSourceTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated LoanProduct loan_products = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanProduct>}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.getLoanProductsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanProduct, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanProduct>} value
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
*/
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.setLoanProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanProduct}
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.addLoanProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.LoanProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.PricingSearchResponse} returns this
 */
proto.wilqo.api.mortgage.PricingSearchResponse.prototype.clearLoanProductsList = function() {
  return this.setLoanProductsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanProduct.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanTermYears: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ratesList: jspb.Message.toObjectList(msg.getRatesList(),
    proto.wilqo.api.mortgage.Rate.toObject, includeInstance),
    uniqueIdentifier: jspb.Message.getFieldWithDefault(msg, 7, ""),
    code: jspb.Message.getFieldWithDefault(msg, 8, ""),
    investorName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    investorCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    loanTermMonths: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanProduct}
 */
proto.wilqo.api.mortgage.LoanProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanProduct;
  return proto.wilqo.api.mortgage.LoanProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanProduct}
 */
proto.wilqo.api.mortgage.LoanProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermYears(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.Rate;
      reader.readMessage(value,proto.wilqo.api.mortgage.Rate.deserializeBinaryFromReader);
      msg.addRates(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifier(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermMonths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanTermYears();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage.Rate.serializeBinaryToWriter
    );
  }
  f = message.getUniqueIdentifier();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvestorName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvestorCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLoanTermMonths();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional string search_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 loan_term_years = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getLoanTermYears = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setLoanTermYears = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional TreasuryNPVLoanAmortizationEnum amortization_type = 4;
 * @return {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional LoanProductStatus status = 5;
 * @return {!proto.wilqo.api.mortgage.LoanProductStatus}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanProductStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanProductStatus} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated Rate rates = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.Rate>}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getRatesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.Rate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.Rate, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.Rate>} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
*/
proto.wilqo.api.mortgage.LoanProduct.prototype.setRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.Rate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.Rate}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.addRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.Rate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.clearRatesList = function() {
  return this.setRatesList([]);
};


/**
 * optional string unique_identifier = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getUniqueIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setUniqueIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string code = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string investor_name = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getInvestorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setInvestorName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string investor_code = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getInvestorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setInvestorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 loan_term_months = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.getLoanTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProduct} returns this
 */
proto.wilqo.api.mortgage.LoanProduct.prototype.setLoanTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Rate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Rate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Rate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Rate.toObject = function(includeInstance, msg) {
  var f, obj = {
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    percent: (f = msg.getPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lockDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rateId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Rate}
 */
proto.wilqo.api.mortgage.Rate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Rate;
  return proto.wilqo.api.mortgage.Rate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Rate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Rate}
 */
proto.wilqo.api.mortgage.Rate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLockDays(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Rate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Rate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Rate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Rate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLockDays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Rate.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
*/
proto.wilqo.api.mortgage.Rate.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Rate.prototype.hasApr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Rate.prototype.getPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
*/
proto.wilqo.api.mortgage.Rate.prototype.setPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.clearPercent = function() {
  return this.setPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Rate.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Rate.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
*/
proto.wilqo.api.mortgage.Rate.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Rate.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Rate.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
*/
proto.wilqo.api.mortgage.Rate.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Rate.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 lock_days = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.Rate.prototype.getLockDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.setLockDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional RateStatus status = 6;
 * @return {!proto.wilqo.api.mortgage.RateStatus}
 */
proto.wilqo.api.mortgage.Rate.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.RateStatus} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string rate_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.Rate.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Rate} returns this
 */
proto.wilqo.api.mortgage.Rate.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasOptionalFields: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    optionalFieldsPage: (f = msg.getOptionalFieldsPage()) && Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeQuestionResponse}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeQuestionResponse;
  return proto.wilqo.api.mortgage.FeeQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeQuestionResponse}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOptionalFields(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.setOptionalFieldsPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasOptionalFields();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOptionalFieldsPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool has_optional_fields = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.getHasOptionalFields = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeeQuestionResponse} returns this
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.setHasOptionalFields = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional wilqo.api.mortgage_dynamic_data.LoanPage optional_fields_page = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.getOptionalFieldsPage = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPage|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeQuestionResponse} returns this
*/
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.setOptionalFieldsPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeQuestionResponse} returns this
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.clearOptionalFieldsPage = function() {
  return this.setOptionalFieldsPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeQuestionResponse.prototype.hasOptionalFieldsPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.OptionalFieldsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.OptionalFieldsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasOptionalFields: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    optionalFieldsPage: (f = msg.getOptionalFieldsPage()) && Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.OptionalFieldsResponse}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.OptionalFieldsResponse;
  return proto.wilqo.api.mortgage.OptionalFieldsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.OptionalFieldsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.OptionalFieldsResponse}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasOptionalFields(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.setOptionalFieldsPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.OptionalFieldsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.OptionalFieldsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasOptionalFields();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOptionalFieldsPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool has_optional_fields = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.getHasOptionalFields = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.OptionalFieldsResponse} returns this
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.setHasOptionalFields = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional wilqo.api.mortgage_dynamic_data.LoanPage optional_fields_page = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.getOptionalFieldsPage = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPage|undefined} value
 * @return {!proto.wilqo.api.mortgage.OptionalFieldsResponse} returns this
*/
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.setOptionalFieldsPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.OptionalFieldsResponse} returns this
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.clearOptionalFieldsPage = function() {
  return this.setOptionalFieldsPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.OptionalFieldsResponse.prototype.hasOptionalFieldsPage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AusSearchResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ausResultList: jspb.Message.toObjectList(msg.getAusResultList(),
    Wilqo_API_Mortgage_Models_pb.AusResult.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    ausUxDisplayList: jspb.Message.toObjectList(msg.getAusUxDisplayList(),
    Wilqo_API_Mortgage_Models_pb.AusUxDisplay.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse}
 */
proto.wilqo.api.mortgage.AusSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusSearchResponse;
  return proto.wilqo.api.mortgage.AusSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse}
 */
proto.wilqo.api.mortgage.AusSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.AusResult;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.AusResult.deserializeBinaryFromReader);
      msg.addAusResult(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.AusUxDisplay;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.AusUxDisplay.deserializeBinaryFromReader);
      msg.addAusUxDisplay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAusResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.AusResult.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAusUxDisplayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.AusUxDisplay.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AusResult aus_result = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.AusResult>}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.getAusResultList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AusResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.AusResult, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusResult>} value
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse} returns this
*/
proto.wilqo.api.mortgage.AusSearchResponse.prototype.setAusResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AusResult}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.addAusResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.AusResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse} returns this
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.clearAusResultList = function() {
  return this.setAusResultList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse} returns this
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated AusUxDisplay aus_ux_display = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.AusUxDisplay>}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.getAusUxDisplayList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AusUxDisplay>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.AusUxDisplay, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusUxDisplay>} value
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse} returns this
*/
proto.wilqo.api.mortgage.AusSearchResponse.prototype.setAusUxDisplayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusUxDisplay=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay}
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.addAusUxDisplay = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.AusUxDisplay, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AusSearchResponse} returns this
 */
proto.wilqo.api.mortgage.AusSearchResponse.prototype.clearAusUxDisplayList = function() {
  return this.setAusUxDisplayList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanProspectorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    trackId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    xmlResponseUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanProspectorResponse}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanProspectorResponse;
  return proto.wilqo.api.mortgage.GetLoanProspectorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanProspectorResponse}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setXmlResponseUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanProspectorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getXmlResponseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string track_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string xml_response_url = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.getXmlResponseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanProspectorResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanProspectorResponse.prototype.setXmlResponseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocutechDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocutechDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocutechDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signingType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocutechDocument}
 */
proto.wilqo.api.mortgage.DocutechDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocutechDocument;
  return proto.wilqo.api.mortgage.DocutechDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocutechDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocutechDocument}
 */
proto.wilqo.api.mortgage.DocutechDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSequence(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSigningType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocutechDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocutechDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocutechDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSigningType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocutechDocument} returns this
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sequence = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.getSequence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocutechDocument} returns this
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.setSequence = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocutechDocument} returns this
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signing_type = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.getSigningType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocutechDocument} returns this
 */
proto.wilqo.api.mortgage.DocutechDocument.prototype.setSigningType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocutechDocumentListResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    loanNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage.DocutechDocument.toObject, includeInstance),
    reportType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocutechDocumentListResult;
  return proto.wilqo.api.mortgage.GetDocutechDocumentListResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanNumber(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.DocutechDocument;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocutechDocument.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.ReportType} */ (reader.readEnum());
      msg.setReportType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocutechDocumentListResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.DocutechDocument.serializeBinaryToWriter
    );
  }
  f = message.getReportType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string loan_number = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.getLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} returns this
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.setLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DocutechDocument documents = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DocutechDocument>}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocutechDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocutechDocument, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocutechDocument>} value
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} returns this
*/
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocutechDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocutechDocument}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.DocutechDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} returns this
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional ReportType report_type = 3;
 * @return {!proto.wilqo.api.mortgage.ReportType}
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.getReportType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ReportType} value
 * @return {!proto.wilqo.api.mortgage.GetDocutechDocumentListResult} returns this
 */
proto.wilqo.api.mortgage.GetDocutechDocumentListResult.prototype.setReportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.toObject = function(includeInstance, msg) {
  var f, obj = {
    disclosuresPackageType: (f = msg.getDisclosuresPackageType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures;
  return proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDisclosuresPackageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisclosuresPackageType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue disclosures_package_type = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.getDisclosuresPackageType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures} returns this
*/
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.setDisclosuresPackageType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures} returns this
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.clearDisclosuresPackageType = function() {
  return this.setDisclosuresPackageType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RequiredFieldsValidationAdditionalInformationDisclosures.prototype.hasDisclosuresPackageType = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanProductDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanProductDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProductDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    investorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    investorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    loanTermMonths: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rateDetail: (f = msg.getRateDetail()) && proto.wilqo.api.mortgage.LoanProductRateDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail}
 */
proto.wilqo.api.mortgage.LoanProductDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanProductDetail;
  return proto.wilqo.api.mortgage.LoanProductDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanProductDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail}
 */
proto.wilqo.api.mortgage.LoanProductDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermMonths(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.AmortizationEnum} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.LoanProductRateDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanProductRateDetail.deserializeBinaryFromReader);
      msg.setRateDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanProductDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanProductDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProductDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvestorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvestorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLoanTermMonths();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRateDetail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.LoanProductRateDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string investor_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getInvestorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setInvestorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string investor_code = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getInvestorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setInvestorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 loan_term_months = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getLoanTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setLoanTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional AmortizationEnum amortization_type = 7;
 * @return {!proto.wilqo.api.mortgage.AmortizationEnum}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.AmortizationEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AmortizationEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional LoanProductRateDetail rate_detail = 8;
 * @return {?proto.wilqo.api.mortgage.LoanProductRateDetail}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.getRateDetail = function() {
  return /** @type{?proto.wilqo.api.mortgage.LoanProductRateDetail} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.LoanProductRateDetail, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.LoanProductRateDetail|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductDetail.prototype.setRateDetail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.clearRateDetail = function() {
  return this.setRateDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductDetail.prototype.hasRateDetail = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanProductRateDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanProductRateDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    percent: (f = msg.getPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lockDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    productChannel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mortgageType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    communityLendingProductType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanTermType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    presetLoanTerm: jspb.Message.getFieldWithDefault(msg, 11, 0),
    presetLoanMaturity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentInterval: jspb.Message.getFieldWithDefault(msg, 13, 0),
    interestOnlyPeriod: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    lienPriority: jspb.Message.getFieldWithDefault(msg, 15, 0),
    maxPriceLimit: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    channelAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    cltv: (f = msg.getCltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    downPaymentPercentage: (f = msg.getDownPaymentPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaLtv: (f = msg.getFhaLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaUfmip: (f = msg.getFhaUfmip()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    financedMi: (f = msg.getFinancedMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    hcltv: (f = msg.getHcltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanTermAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    ltv: (f = msg.getLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    mortgageTypeAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    totalOfAllLiensWithFixedSecond: (f = msg.getTotalOfAllLiensWithFixedSecond()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalOfAllLiensWithHeloc: (f = msg.getTotalOfAllLiensWithHeloc()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinanced: (f = msg.getUsdaFinanced()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFee: (f = msg.getUsdaFinancedGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFeeCents: (f = msg.getUsdaFinancedGuaranteeFeeCents()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageAmount: (f = msg.getUsdaFinancePercentageAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageGuaranteeFee: (f = msg.getUsdaFinancePercentageGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentagePayAtClosing: (f = msg.getUsdaFinancePercentagePayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaGuaranteeFee: (f = msg.getUsdaGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit9: (f = msg.getUsdaIncomeLimit9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk9: (f = msg.getUsdaIncomeLimitAk9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaPayAtClosing: (f = msg.getUsdaPayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMi: (f = msg.getFhaPurchaseFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 40, 0),
    fhaPurchaseFirstPremium: (f = msg.getFhaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseCutoff: (f = msg.getFhaPurchaseCutoff()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMi: (f = msg.getFhaRefiFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 44, 0),
    fhaRefiFirstPremium: (f = msg.getFhaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiCutoffPercentage: (f = msg.getFhaRefiCutoffPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit1To4: (f = msg.getUsdaIncomeLimit1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit5To8: (f = msg.getUsdaIncomeLimit5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk1To4: (f = msg.getUsdaIncomeLimitAk1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk5To8: (f = msg.getUsdaIncomeLimitAk5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseFirstPremium: (f = msg.getVaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseSubsequentPremium: (f = msg.getVaPurchaseSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiFirstPremium: (f = msg.getVaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiSubsequentPremium: (f = msg.getVaRefiSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    miAndFundingFeeFinancedAmount: (f = msg.getMiAndFundingFeeFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaFundingFee: (f = msg.getVaFundingFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    indexType: jspb.Message.getFieldWithDefault(msg, 57, 0),
    firstRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 58, 0),
    subsequentRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 59, 0),
    firstMaxIncreaseRatePercent: (f = msg.getFirstMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    subsequentMaxIncreaseRatePercent: (f = msg.getSubsequentMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    maximumIncreaseRatePercent: (f = msg.getMaximumIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    floorRatePercent: (f = msg.getFloorRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    rateId: jspb.Message.getFieldWithDefault(msg, 64, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanProductRateDetail;
  return proto.wilqo.api.mortgage.LoanProductRateDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanProductRateDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLockDays(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.ProductChannel} */ (reader.readEnum());
      msg.setProductChannel(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (reader.readEnum());
      msg.setMortgageType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.CommunityLendingProductType} */ (reader.readEnum());
      msg.setCommunityLendingProductType(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanTermType} */ (reader.readEnum());
      msg.setLoanTermType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanTerm(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanMaturity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentInterval(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInterestOnlyPeriod(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage.LienPriorityEnum} */ (reader.readEnum());
      msg.setLienPriority(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaxPriceLimit(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannelAllowed(value);
      break;
    case 18:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCltv(value);
      break;
    case 19:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDownPaymentPercentage(value);
      break;
    case 20:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaLtv(value);
      break;
    case 21:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaUfmip(value);
      break;
    case 22:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFinancedMi(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHcltv(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoanTermAllowed(value);
      break;
    case 25:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLtv(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMortgageTypeAllowed(value);
      break;
    case 27:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithFixedSecond(value);
      break;
    case 28:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithHeloc(value);
      break;
    case 29:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinanced(value);
      break;
    case 30:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFee(value);
      break;
    case 31:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFeeCents(value);
      break;
    case 32:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageAmount(value);
      break;
    case 33:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageGuaranteeFee(value);
      break;
    case 34:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentagePayAtClosing(value);
      break;
    case 35:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaGuaranteeFee(value);
      break;
    case 36:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit9(value);
      break;
    case 37:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk9(value);
      break;
    case 38:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaPayAtClosing(value);
      break;
    case 39:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstMonthlyMi(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaPurchaseFirstMonthlyMiMonths(value);
      break;
    case 41:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstPremium(value);
      break;
    case 42:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseCutoff(value);
      break;
    case 43:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstMonthlyMi(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaRefiFirstMonthlyMiMonths(value);
      break;
    case 45:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstPremium(value);
      break;
    case 46:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiCutoffPercentage(value);
      break;
    case 47:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit1To4(value);
      break;
    case 48:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit5To8(value);
      break;
    case 49:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk1To4(value);
      break;
    case 50:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk5To8(value);
      break;
    case 51:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseFirstPremium(value);
      break;
    case 52:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseSubsequentPremium(value);
      break;
    case 53:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiFirstPremium(value);
      break;
    case 54:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiSubsequentPremium(value);
      break;
    case 55:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMiAndFundingFeeFinancedAmount(value);
      break;
    case 56:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaFundingFee(value);
      break;
    case 57:
      var value = /** @type {!proto.wilqo.api.mortgage.IndexEnum} */ (reader.readEnum());
      msg.setIndexType(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFirstRateChangeMonthsCount(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubsequentRateChangeMonthsCount(value);
      break;
    case 60:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFirstMaxIncreaseRatePercent(value);
      break;
    case 61:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSubsequentMaxIncreaseRatePercent(value);
      break;
    case 62:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMaximumIncreaseRatePercent(value);
      break;
    case 63:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFloorRatePercent(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanProductRateDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanProductRateDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLockDays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProductChannel();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMortgageType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCommunityLendingProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLoanTermType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPresetLoanTerm();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getPresetLoanMaturity();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getPaymentInterval();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getInterestOnlyPeriod();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLienPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getMaxPriceLimit();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getChannelAllowed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCltv();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDownPaymentPercentage();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaLtv();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaUfmip();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFinancedMi();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHcltv();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermAllowed();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getLtv();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMortgageTypeAllowed();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getTotalOfAllLiensWithFixedSecond();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalOfAllLiensWithHeloc();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinanced();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFeeCents();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageAmount();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentagePayAtClosing();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit9();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk9();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaPayAtClosing();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      40,
      f
    );
  }
  f = message.getFhaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseCutoff();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      44,
      f
    );
  }
  f = message.getFhaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiCutoffPercentage();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit1To4();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit5To8();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk1To4();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk5To8();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMiAndFundingFeeFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaFundingFee();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getIndexType();
  if (f !== 0.0) {
    writer.writeEnum(
      57,
      f
    );
  }
  f = message.getFirstRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      58,
      f
    );
  }
  f = message.getSubsequentRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      59,
      f
    );
  }
  f = message.getFirstMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSubsequentMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMaximumIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFloorRatePercent();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasApr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearPercent = function() {
  return this.setPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 lock_days = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getLockDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setLockDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional RateStatus status = 6;
 * @return {!proto.wilqo.api.mortgage.RateStatus}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.RateStatus} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ProductChannel product_channel = 7;
 * @return {!proto.wilqo.api.mortgage.ProductChannel}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getProductChannel = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProductChannel} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProductChannel} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setProductChannel = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional MortgageEnum mortgage_type = 8;
 * @return {!proto.wilqo.api.mortgage.MortgageEnum}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMortgageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MortgageEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMortgageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CommunityLendingProductType community_lending_product_type = 9;
 * @return {!proto.wilqo.api.mortgage.CommunityLendingProductType}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getCommunityLendingProductType = function() {
  return /** @type {!proto.wilqo.api.mortgage.CommunityLendingProductType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CommunityLendingProductType} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setCommunityLendingProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional LoanTermType loan_term_type = 10;
 * @return {!proto.wilqo.api.mortgage.LoanTermType}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getLoanTermType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanTermType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanTermType} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setLoanTermType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint32 preset_loan_term = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getPresetLoanTerm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setPresetLoanTerm = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 preset_loan_maturity = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getPresetLoanMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setPresetLoanMaturity = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 payment_interval = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getPaymentInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setPaymentInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool interest_only_period = 14;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getInterestOnlyPeriod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setInterestOnlyPeriod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional LienPriorityEnum lien_priority = 15;
 * @return {!proto.wilqo.api.mortgage.LienPriorityEnum}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getLienPriority = function() {
  return /** @type {!proto.wilqo.api.mortgage.LienPriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LienPriorityEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setLienPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool max_price_limit = 16;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMaxPriceLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMaxPriceLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool channel_allowed = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getChannelAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setChannelAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal cltv = 18;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getCltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 18));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setCltv = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearCltv = function() {
  return this.setCltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasCltv = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal down_payment_percentage = 19;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getDownPaymentPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 19));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setDownPaymentPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearDownPaymentPercentage = function() {
  return this.setDownPaymentPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasDownPaymentPercentage = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ltv = 20;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 20));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaLtv = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaLtv = function() {
  return this.setFhaLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaLtv = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ufmip = 21;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaUfmip = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 21));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaUfmip = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaUfmip = function() {
  return this.setFhaUfmip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaUfmip = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal financed_mi = 22;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFinancedMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 22));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFinancedMi = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFinancedMi = function() {
  return this.setFinancedMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFinancedMi = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal hcltv = 23;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getHcltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 23));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setHcltv = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearHcltv = function() {
  return this.setHcltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasHcltv = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool loan_term_allowed = 24;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getLoanTermAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setLoanTermAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal ltv = 25;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 25));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setLtv = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearLtv = function() {
  return this.setLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasLtv = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool mortgage_type_allowed = 26;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMortgageTypeAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMortgageTypeAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_fixed_second = 27;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getTotalOfAllLiensWithFixedSecond = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 27));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setTotalOfAllLiensWithFixedSecond = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearTotalOfAllLiensWithFixedSecond = function() {
  return this.setTotalOfAllLiensWithFixedSecond(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasTotalOfAllLiensWithFixedSecond = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_heloc = 28;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getTotalOfAllLiensWithHeloc = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 28));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setTotalOfAllLiensWithHeloc = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearTotalOfAllLiensWithHeloc = function() {
  return this.setTotalOfAllLiensWithHeloc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasTotalOfAllLiensWithHeloc = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed = 29;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinanced = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 29));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinanced = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinanced = function() {
  return this.setUsdaFinanced(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinanced = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee = 30;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinancedGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 30));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinancedGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinancedGuaranteeFee = function() {
  return this.setUsdaFinancedGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinancedGuaranteeFee = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee_cents = 31;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinancedGuaranteeFeeCents = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 31));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinancedGuaranteeFeeCents = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinancedGuaranteeFeeCents = function() {
  return this.setUsdaFinancedGuaranteeFeeCents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinancedGuaranteeFeeCents = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_amount = 32;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinancePercentageAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 32));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinancePercentageAmount = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinancePercentageAmount = function() {
  return this.setUsdaFinancePercentageAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinancePercentageAmount = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_guarantee_fee = 33;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinancePercentageGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 33));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinancePercentageGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinancePercentageGuaranteeFee = function() {
  return this.setUsdaFinancePercentageGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinancePercentageGuaranteeFee = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_pay_at_closing = 34;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaFinancePercentagePayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 34));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaFinancePercentagePayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaFinancePercentagePayAtClosing = function() {
  return this.setUsdaFinancePercentagePayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaFinancePercentagePayAtClosing = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_guarantee_fee = 35;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 35));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaGuaranteeFee = function() {
  return this.setUsdaGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaGuaranteeFee = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_9 = 36;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimit9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 36));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimit9 = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimit9 = function() {
  return this.setUsdaIncomeLimit9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimit9 = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_9 = 37;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimitAk9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 37));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimitAk9 = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimitAk9 = function() {
  return this.setUsdaIncomeLimitAk9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimitAk9 = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_pay_at_closing = 38;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaPayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 38));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaPayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaPayAtClosing = function() {
  return this.setUsdaPayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaPayAtClosing = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_monthly_mi = 39;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaPurchaseFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 39));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaPurchaseFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaPurchaseFirstMonthlyMi = function() {
  return this.setFhaPurchaseFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaPurchaseFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional uint32 fha_purchase_first_monthly_mi_months = 40;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaPurchaseFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaPurchaseFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_premium = 41;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 41));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaPurchaseFirstPremium = function() {
  return this.setFhaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_cutoff = 42;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaPurchaseCutoff = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 42));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaPurchaseCutoff = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaPurchaseCutoff = function() {
  return this.setFhaPurchaseCutoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaPurchaseCutoff = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_monthly_mi = 43;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaRefiFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 43));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaRefiFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaRefiFirstMonthlyMi = function() {
  return this.setFhaRefiFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaRefiFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional uint32 fha_refi_first_monthly_mi_months = 44;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaRefiFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaRefiFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_premium = 45;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 45));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaRefiFirstPremium = function() {
  return this.setFhaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_cutoff_percentage = 46;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFhaRefiCutoffPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 46));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFhaRefiCutoffPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFhaRefiCutoffPercentage = function() {
  return this.setFhaRefiCutoffPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFhaRefiCutoffPercentage = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_1_to_4 = 47;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimit1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 47));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimit1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimit1To4 = function() {
  return this.setUsdaIncomeLimit1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimit1To4 = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_5_to_8 = 48;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimit5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 48));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimit5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimit5To8 = function() {
  return this.setUsdaIncomeLimit5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimit5To8 = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_1_to_4 = 49;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimitAk1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 49));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimitAk1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimitAk1To4 = function() {
  return this.setUsdaIncomeLimitAk1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimitAk1To4 = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_5_to_8 = 50;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getUsdaIncomeLimitAk5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 50));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setUsdaIncomeLimitAk5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearUsdaIncomeLimitAk5To8 = function() {
  return this.setUsdaIncomeLimitAk5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasUsdaIncomeLimitAk5To8 = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_first_premium = 51;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getVaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 51));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setVaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearVaPurchaseFirstPremium = function() {
  return this.setVaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasVaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_subsequent_premium = 52;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getVaPurchaseSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 52));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setVaPurchaseSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearVaPurchaseSubsequentPremium = function() {
  return this.setVaPurchaseSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasVaPurchaseSubsequentPremium = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_first_premium = 53;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getVaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 53));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setVaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearVaRefiFirstPremium = function() {
  return this.setVaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasVaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_subsequent_premium = 54;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getVaRefiSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 54));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setVaRefiSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearVaRefiSubsequentPremium = function() {
  return this.setVaRefiSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasVaRefiSubsequentPremium = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal mi_and_funding_fee_financed_amount = 55;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMiAndFundingFeeFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 55));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMiAndFundingFeeFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearMiAndFundingFeeFinancedAmount = function() {
  return this.setMiAndFundingFeeFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasMiAndFundingFeeFinancedAmount = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_funding_fee = 56;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getVaFundingFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 56));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setVaFundingFee = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearVaFundingFee = function() {
  return this.setVaFundingFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasVaFundingFee = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional IndexEnum index_type = 57;
 * @return {!proto.wilqo.api.mortgage.IndexEnum}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getIndexType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IndexEnum} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IndexEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setIndexType = function(value) {
  return jspb.Message.setProto3EnumField(this, 57, value);
};


/**
 * optional uint32 first_rate_change_months_count = 58;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFirstRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFirstRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional uint32 subsequent_rate_change_months_count = 59;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getSubsequentRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setSubsequentRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal first_max_increase_rate_percent = 60;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFirstMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 60));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFirstMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFirstMaxIncreaseRatePercent = function() {
  return this.setFirstMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFirstMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal subsequent_max_increase_rate_percent = 61;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getSubsequentMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 61));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setSubsequentMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearSubsequentMaxIncreaseRatePercent = function() {
  return this.setSubsequentMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasSubsequentMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal maximum_increase_rate_percent = 62;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getMaximumIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 62));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setMaximumIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearMaximumIncreaseRatePercent = function() {
  return this.setMaximumIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasMaximumIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal floor_rate_percent = 63;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getFloorRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 63));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
*/
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setFloorRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.clearFloorRatePercent = function() {
  return this.setFloorRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.hasFloorRatePercent = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional string rate_id = 64;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanProductRateDetail} returns this
 */
proto.wilqo.api.mortgage.LoanProductRateDetail.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanProductStatus = {
  LOAN_PRODUCT_STATUS_UNSPECIFIED: 0,
  LOAN_PRODUCT_STATUS_ELIGIBLE: 1,
  LOAN_PRODUCT_STATUS_NEEDS_MORE_DATA: 2,
  LOAN_PRODUCT_STATUS_INELIGIBLE: 3,
  LOAN_PRODUCT_STATUS_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IntegrationVendor = {
  UNKNOWN: 0,
  OPTIMAL_BLUE: 1,
  POLLY: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ReportType = {
  REPORT_TYPE_UNKNOWN: 0,
  REPORT_TYPE_XML: 1,
  REPORT_TYPE_PDF: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage);
