// source: Wilqo_API_Activity_AdminQueries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('./Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb = require('./Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_API_Activity_Admin_pb = require('./Wilqo_API_Activity_Admin_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Admin_pb);
var Wilqo_API_Activity_Models_pb = require('./Wilqo_API_Activity_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Models_pb);
goog.exportSymbol('proto.wilqo.api.activity.ExportActivityInstancesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ExportActivityInstancesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ExportConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ExportConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAssetQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAssetQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAssetsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAssetsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOperatorQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOperatorQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOperatorsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOperatorsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.displayName = 'proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.displayName = 'proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.displayName = 'proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.displayName = 'proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.displayName = 'proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.displayName = 'proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetOperatorsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOperatorsQueryRequest.displayName = 'proto.wilqo.api.activity.GetOperatorsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetOperatorsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetOperatorsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOperatorsQueryResponse.displayName = 'proto.wilqo.api.activity.GetOperatorsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.displayName = 'proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.displayName = 'proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOperatorQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetOperatorQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOperatorQueryRequest.displayName = 'proto.wilqo.api.activity.GetOperatorQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOperatorQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetOperatorQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOperatorQueryResponse.displayName = 'proto.wilqo.api.activity.GetOperatorQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.displayName = 'proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.displayName = 'proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.displayName = 'proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.displayName = 'proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.displayName = 'proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.displayName = 'proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.displayName = 'proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.displayName = 'proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAssetQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAssetQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAssetQueryRequest.displayName = 'proto.wilqo.api.activity.GetAssetQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAssetQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAssetQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAssetQueryResponse.displayName = 'proto.wilqo.api.activity.GetAssetQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAssetsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAssetsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAssetsQueryRequest.displayName = 'proto.wilqo.api.activity.GetAssetsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAssetsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAssetsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAssetsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAssetsQueryResponse.displayName = 'proto.wilqo.api.activity.GetAssetsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExportConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExportConfigurationQueryRequest.displayName = 'proto.wilqo.api.activity.ExportConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExportConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExportConfigurationQueryResponse.displayName = 'proto.wilqo.api.activity.ExportConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.displayName = 'proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.displayName = 'proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExportActivityInstancesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.displayName = 'proto.wilqo.api.activity.ExportActivityInstancesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExportActivityInstancesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.displayName = 'proto.wilqo.api.activity.ExportActivityInstancesQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allowUseInTrigger: (f = msg.getAllowUseInTrigger()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    allowUseInActivityContentEditor: (f = msg.getAllowUseInActivityContentEditor()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    allowUseAsTextTemplateVariable: (f = msg.getAllowUseAsTextTemplateVariable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    resultLimit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest;
  return proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAllowUseInTrigger(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAllowUseInActivityContentEditor(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAllowUseAsTextTemplateVariable(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultLimit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllowUseInTrigger();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAllowUseInActivityContentEditor();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAllowUseAsTextTemplateVariable();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getResultLimit();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 2;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.BoolValue allow_use_in_trigger = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getAllowUseInTrigger = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setAllowUseInTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.clearAllowUseInTrigger = function() {
  return this.setAllowUseInTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.hasAllowUseInTrigger = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue allow_use_in_activity_content_editor = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getAllowUseInActivityContentEditor = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setAllowUseInActivityContentEditor = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.clearAllowUseInActivityContentEditor = function() {
  return this.setAllowUseInActivityContentEditor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.hasAllowUseInActivityContentEditor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue allow_use_as_text_template_variable = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getAllowUseAsTextTemplateVariable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setAllowUseAsTextTemplateVariable = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.clearAllowUseAsTextTemplateVariable = function() {
  return this.setAllowUseAsTextTemplateVariable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.hasAllowUseAsTextTemplateVariable = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 result_limit = 7;
 * @return {number}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getResultLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setResultLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string business_process_domain_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    fieldGroupsList: jspb.Message.toObjectList(msg.getFieldGroupsList(),
    Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse;
  return proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.deserializeBinaryFromReader);
      msg.addFieldGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getFieldGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.DataFieldGroupSummary field_groups = 2;
 * @return {!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.getFieldGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.setFieldGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DataFieldGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DataFieldGroupSummary}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.addFieldGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DataFieldGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesQueryResponse.prototype.clearFieldGroupsList = function() {
  return this.setFieldGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest;
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dataField: (f = msg.getDataField()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse;
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setDataField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDataField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} returns this
*/
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.DataFieldTemplate data_field = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.getDataField = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} returns this
*/
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.setDataField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.clearDataField = function() {
  return this.setDataField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateQueryResponse.prototype.hasDataField = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest;
  return proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTemplateIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string template_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.getTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.setTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.addTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.clearTemplateIdsList = function() {
  return this.setTemplateIdsList([]);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dataFieldTemplatesList: jspb.Message.toObjectList(msg.getDataFieldTemplatesList(),
    Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse;
  return proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.addDataFieldTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDataFieldTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.DataFieldTemplate data_field_templates = 2;
 * @return {!Array<!proto.wilqo.shared.models.DataFieldTemplate>}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.getDataFieldTemplatesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DataFieldTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataFieldTemplate>} value
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.setDataFieldTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DataFieldTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.addDataFieldTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DataFieldTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDataFieldTemplatesByIdsQueryResponse.prototype.clearDataFieldTemplatesList = function() {
  return this.setDataFieldTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOperatorsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryRequest}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOperatorsQueryRequest;
  return proto.wilqo.api.activity.GetOperatorsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryRequest}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOperatorsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 1;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOperatorsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOperatorsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    operatorsList: jspb.Message.toObjectList(msg.getOperatorsList(),
    Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOperatorsQueryResponse;
  return proto.wilqo.api.activity.GetOperatorsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.deserializeBinaryFromReader);
      msg.addOperators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOperatorsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOperatorsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOperatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.rulesengine.Operator operators = 2;
 * @return {!Array<!proto.wilqo.shared.rulesengine.Operator>}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.getOperatorsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.Operator>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.Operator>} value
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.setOperatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.Operator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.Operator}
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.addOperators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.rulesengine.Operator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetOperatorsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOperatorsQueryResponse.prototype.clearOperatorsList = function() {
  return this.setOperatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest;
  return proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dataTypesList: jspb.Message.toObjectList(msg.getDataTypesList(),
    Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.DataTypeSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse;
  return proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.DataTypeSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.DataTypeSummary.deserializeBinaryFromReader);
      msg.addDataTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDataTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.DataTypeSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.rulesengine.DataTypeSummary data_types = 2;
 * @return {!Array<!proto.wilqo.shared.rulesengine.DataTypeSummary>}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.getDataTypesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.DataTypeSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.DataTypeSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.DataTypeSummary>} value
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.setDataTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DataTypeSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary}
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.addDataTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.rulesengine.DataTypeSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAllOperatorsByDataTypeQueryResponse.prototype.clearDataTypesList = function() {
  return this.setDataTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOperatorQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOperatorQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryRequest}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOperatorQueryRequest;
  return proto.wilqo.api.activity.GetOperatorQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOperatorQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryRequest}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOperatorQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOperatorQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.shared.rulesengine.OperatorEnum id = 1;
 * @return {!proto.wilqo.shared.rulesengine.OperatorEnum}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.getId = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorEnum} value
 * @return {!proto.wilqo.api.activity.GetOperatorQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetOperatorQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOperatorQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOperatorQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOperatorQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    operator: (f = msg.getOperator()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOperatorQueryResponse;
  return proto.wilqo.api.activity.GetOperatorQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOperatorQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.deserializeBinaryFromReader);
      msg.setOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOperatorQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOperatorQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOperator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.rulesengine.Operator operator = 2;
 * @return {?proto.wilqo.shared.rulesengine.Operator}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.getOperator = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.Operator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.Operator, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.Operator|undefined} value
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.setOperator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetOperatorQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.clearOperator = function() {
  return this.setOperator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetOperatorQueryResponse.prototype.hasOperator = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 2, ""),
    page: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsPerPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    includeArchived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    businessProcessTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest;
  return proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemsPerPage(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeArchived(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessTemplateIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsPerPage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIncludeArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBusinessProcessTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search_term = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 page = 3;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 items_per_page = 4;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getItemsPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setItemsPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool include_archived = 5;
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getIncludeArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setIncludeArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string business_process_template_ids = 6;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.getBusinessProcessTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.setBusinessProcessTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.addBusinessProcessTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryRequest.prototype.clearBusinessProcessTemplateIdsList = function() {
  return this.setBusinessProcessTemplateIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Activity_Admin_pb.AdminActivitySummary.toObject, includeInstance),
    currentPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentPageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse;
  return proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Admin_pb.AdminActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.AdminActivitySummary.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPageSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Admin_pb.AdminActivitySummary.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCurrentPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AdminActivitySummary items = 2;
 * @return {!Array<!proto.wilqo.api.activity.AdminActivitySummary>}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.AdminActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Admin_pb.AdminActivitySummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.AdminActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.AdminActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AdminActivitySummary}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.AdminActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 current_page = 3;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getCurrentPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setCurrentPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 current_page_size = 4;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getCurrentPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setCurrentPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 total_items = 5;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_pages = 6;
 * @return {number}
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplatesQueryResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest;
  return proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string internal_name = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    adminTemplate: (f = msg.getAdminTemplate()) && Wilqo_API_Activity_Admin_pb.AdminActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse;
  return proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Admin_pb.AdminActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.AdminActivitySummary.deserializeBinaryFromReader);
      msg.setAdminTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAdminTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Admin_pb.AdminActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdminActivitySummary admin_template = 2;
 * @return {?proto.wilqo.api.activity.AdminActivitySummary}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.getAdminTemplate = function() {
  return /** @type{?proto.wilqo.api.activity.AdminActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.AdminActivitySummary, 2));
};


/**
 * @param {?proto.wilqo.api.activity.AdminActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.setAdminTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.clearAdminTemplate = function() {
  return this.setAdminTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminActivityTemplateByInternalNameQueryResponse.prototype.hasAdminTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest;
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activityDesign: (f = msg.getActivityDesign()) && Wilqo_API_Activity_Admin_pb.AdminPanelSequenceDesignActivity.toObject(includeInstance, f),
    runtime: (f = msg.getRuntime()) && Wilqo_API_Activity_Models_pb.PanelSequence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse;
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Admin_pb.AdminPanelSequenceDesignActivity;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.AdminPanelSequenceDesignActivity.deserializeBinaryFromReader);
      msg.setActivityDesign(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.PanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PanelSequence.deserializeBinaryFromReader);
      msg.setRuntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivityDesign();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Admin_pb.AdminPanelSequenceDesignActivity.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.PanelSequence.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdminPanelSequenceDesignActivity activity_design = 3;
 * @return {?proto.wilqo.api.activity.AdminPanelSequenceDesignActivity}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.getActivityDesign = function() {
  return /** @type{?proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.AdminPanelSequenceDesignActivity, 3));
};


/**
 * @param {?proto.wilqo.api.activity.AdminPanelSequenceDesignActivity|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.setActivityDesign = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.clearActivityDesign = function() {
  return this.setActivityDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.hasActivityDesign = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PanelSequence runtime = 4;
 * @return {?proto.wilqo.api.activity.PanelSequence}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.getRuntime = function() {
  return /** @type{?proto.wilqo.api.activity.PanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PanelSequence, 4));
};


/**
 * @param {?proto.wilqo.api.activity.PanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.setRuntime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.clearRuntime = function() {
  return this.setRuntime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignQueryResponse.prototype.hasRuntime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminPanelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest;
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string admin_panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.getAdminPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.setAdminPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse;
  return proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 2;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryResponse.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAssetQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAssetQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAssetQueryRequest}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAssetQueryRequest;
  return proto.wilqo.api.activity.GetAssetQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAssetQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAssetQueryRequest}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAssetQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAssetQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAssetQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAssetQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAssetQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAssetQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAssetQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    asset: (f = msg.getAsset()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAssetQueryResponse;
  return proto.wilqo.api.activity.GetAssetQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAssetQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAssetQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAssetQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.DataFieldTemplate asset = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.getAsset = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAssetQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAssetQueryResponse.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAssetsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAssetsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryRequest}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAssetsQueryRequest;
  return proto.wilqo.api.activity.GetAssetsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAssetsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryRequest}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAssetsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAssetsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAssetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAssetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAssetsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAssetsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAssetsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAssetsQueryResponse;
  return proto.wilqo.api.activity.GetAssetsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAssetsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAssetsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAssetsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.DataFieldTemplateSummary assets = 2;
 * @return {!Array<!proto.wilqo.shared.models.DataFieldTemplateSummary>}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DataFieldTemplateSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataFieldTemplateSummary>} value
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DataFieldTemplateSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSummary}
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DataFieldTemplateSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAssetsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAssetsQueryResponse.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExportConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExportConfigurationQueryRequest;
  return proto.wilqo.api.activity.ExportConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExportConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryRequest} returns this
 */
proto.wilqo.api.activity.ExportConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExportConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    zipFile: msg.getZipFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExportConfigurationQueryResponse;
  return proto.wilqo.api.activity.ExportConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZipFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExportConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getZipFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} returns this
*/
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes zip_file = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.getZipFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes zip_file = 2;
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {string}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.getZipFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZipFile()));
};


/**
 * optional bytes zip_file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.getZipFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZipFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.activity.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.activity.ExportConfigurationQueryResponse.prototype.setZipFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templatePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest;
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplatePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplatePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string template_path = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.getTemplatePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.setTemplatePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dataField: (f = msg.getDataField()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse;
  return proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setDataField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDataField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} returns this
*/
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.DataFieldTemplate data_field = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.getDataField = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} returns this
*/
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.setDataField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse} returns this
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.clearDataField = function() {
  return this.setDataField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetSingleDataFieldTemplateByPathQueryResponse.prototype.hasDataField = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExportActivityInstancesQueryRequest;
  return proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest} returns this
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryRequest} returns this
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    zipFile: msg.getZipFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExportActivityInstancesQueryResponse;
  return proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZipFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getZipFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} returns this
*/
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} returns this
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes zip_file = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.getZipFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes zip_file = 2;
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {string}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.getZipFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZipFile()));
};


/**
 * optional bytes zip_file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.getZipFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZipFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.activity.ExportActivityInstancesQueryResponse} returns this
 */
proto.wilqo.api.activity.ExportActivityInstancesQueryResponse.prototype.setZipFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.api.activity);
