import { useParams } from 'react-router';

import { useActivityChecklist } from '@/API/Queries/activities/useActivityChecklist';

import { CompleteList } from '../../completeList';
import { useChecklistProgress } from '../checklistProgressContext';

const ChecklistCompleteTab = () => {
  const { checklistProgress } = useChecklistProgress();
  const { activityId = '' } = useParams();
  const { isLoading } = useActivityChecklist(activityId);

  return (
    <CompleteList
      isLoading={isLoading}
      progress={checklistProgress}
      type="checklist"
    />
  );
};

export { ChecklistCompleteTab };
