import { useQuery } from '@tanstack/react-query';

import { DynamicDataActivityQueryResponse, GetDynamicDataActivityQueryRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/DynamicDataActivity/Wilqo_API_Mortgage_DynamicData_DynamicDataActivity_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.GetDynamicDataActivityQueryRequest';

export const useDynamicActivity = (id: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, id], async () => {
    const request = new GetDynamicDataActivityQueryRequest();
    request.setActivityId(id);
    request.setBusinessProcessDomainId(bpdId);
    return sendMessage<DynamicDataActivityQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      DynamicDataActivityQueryResponse,
    );
  });
};
