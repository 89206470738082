import { useCallback, useEffect } from 'react';

import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { useSearchParams } from '@/Routes/NavigationContext';

export const useDynamicQueryTrigger = () => {
  const [urlSearchParams] = useSearchParams();
  const { toggleAction } = useDynamicContext();
  const { triggerAction, ...queryParams } = Object.fromEntries([...urlSearchParams]);

  const resetModalType = useCallback(() => {
    toggleAction(undefined);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (triggerAction) {
      toggleAction({ actionType: Number(triggerAction) });

      urlSearchParams.delete('triggerAction');
      const stringParams = Object.entries(queryParams).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
      window.history.replaceState(queryParams, '', `${window.location.pathname}?${stringParams}`);
    }
  }, [queryParams, triggerAction, urlSearchParams]);

  return { resetModalType };
};
