import clsx from 'clsx';
import { snakeCase } from 'lodash';

import { AttachmentType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

import { DynamicIcon } from '../DynamicIcon';
import { HelperText } from '../HelperText';
import type { SelectionSurfaceVariant } from '../SelectionSurface';
import { SelectionSurface } from '../SelectionSurface';
import { Typography } from '../Typography';

export enum RadioGroupOrientationEnum {
  COL = 0,
  ROW = 1,
  MIX = 2,
}

export interface ComponentProps {
  className?: string;
  label: string;
  isAccordion?: boolean;
  required?: boolean;
  options: Array<any>;
  optionLabelKey: string;
  valueLabelKey: string;
  onSelect: (option: any) => void;
  inputRef?: React.Ref<any>;
  value?: string;
  disabled?: boolean;
  errorMessage?: string;
  variant?: SelectionSurfaceVariant;
  orientation?: RadioGroupOrientationEnum;
  id?: string;
}

const RadioGroup = (props: ComponentProps) => {
  const {
    className,
    disabled,
    errorMessage,
    inputRef,
    isAccordion,
    label,
    onSelect,
    optionLabelKey,
    options,
    orientation = RadioGroupOrientationEnum.COL,
    required,
    value,
    valueLabelKey,
    variant,
    id,
  } = props;

  return (
    <div className={
      clsx('w-full relative flex', {
        'flex-col': [RadioGroupOrientationEnum.COL, RadioGroupOrientationEnum.MIX].includes(orientation),
        'flex-row gap-x-3': orientation === RadioGroupOrientationEnum.ROW,
      },
      className)
      }
    >
      {id && (
        <div
          className="empty:hidden relative"
          id={`${id}-${AttachmentType.ATTACHMENT_TYPE_ABOVE}`}
        />
      )}
      {
        !isAccordion && (
          <div className="mb-4 w-full">
            <Typography className="text-on-surface-inactive" variant="body1">
              {`${label}${label && required ? '*' : ''}`}
            </Typography>
          </div>
        )
      }
      <div className={clsx('flex', {
        'flex-col': orientation === RadioGroupOrientationEnum.COL,
        'flex-row gap-x-2': [RadioGroupOrientationEnum.MIX, RadioGroupOrientationEnum.ROW].includes(orientation),
      })}
      >
        {
        options?.map((option) => {
          const isChecked = value === option[valueLabelKey];
          return (
            <div key={option[optionLabelKey]}>
              {id && (
                <div
                  key={`${id}-${snakeCase(option.headerText)}-${AttachmentType.ATTACHMENT_TYPE_ABOVE}`}
                  className="empty:hidden relative mb-4"
                  id={`${id}-${snakeCase(option.headerText)}-${AttachmentType.ATTACHMENT_TYPE_ABOVE}`}
                />
              )}
              <div key={option[optionLabelKey]} className="mb-4 relative">
                <SelectionSurface
                  onClick={() => onSelect(option)}
                  selected={isChecked}
                  variant={variant}
                >
                  <input
                    ref={inputRef}
                    checked={isChecked}
                    className="cursor-pointer h-full opacity-0 absolute top-0 left-0 w-full"
                    disabled={disabled}
                    name={option[optionLabelKey]}
                    onChange={() => { }}
                    type="radio"
                  />
                  <label className="flex flex-row cursor-pointer" htmlFor={option[optionLabelKey]}>
                    <DynamicIcon
                      className={clsx(
                        'min-h-[24px] min-w-[24px]',
                        {
                          'text-on-surface-inactive': !isChecked,
                          'text-primary': isChecked,
                        },
                      )}
                      icon={isChecked ? 'RadioButtonChecked' : 'RadioButtonUnchecked'}
                    />
                    <Typography className={clsx('text-on-surface-active ml-[12px]', { 'whitespace-nowrap': orientation === RadioGroupOrientationEnum.ROW })} variant="body1">
                      {option[optionLabelKey]}
                    </Typography>
                  </label>
                </SelectionSurface>
              </div>

              {option.childPanelElement && (
                <PanelElementRenderer
                  key={`panel-${option[optionLabelKey]}`}
                  {...option.childPanelElement}
                />
              )}
              {id && (
                <div
                  key={`${id}-${snakeCase(option.headerText)}-${AttachmentType.ATTACHMENT_TYPE_BELOW}`}
                  className="empty:hidden relative mb-4"
                  id={`${id}-${snakeCase(option.headerText)}-${AttachmentType.ATTACHMENT_TYPE_BELOW}`}
                />
              )}
            </div>
          );
        })
      }
      </div>
      {errorMessage && <HelperText message={errorMessage} />}
      {id && (
        <div
          className="empty:hidden relative"
          id={`${id}-${AttachmentType.ATTACHMENT_TYPE_BELOW}`}
        />
      )}
    </div>
  );
};

export default RadioGroup;
