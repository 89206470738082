import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { VerifyBorrowerInviteUrlQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';

const MESSAGE_NAME = 'wilqo.api.users.VerifyBorrowerInviteUrlQueryRequest';

const useInvitationVerification = (id: string) => useQuery(
  [MESSAGE_NAME, id],
  async () => {
    const response = await fetch(`/mortgage/api/configuration/verifyInvite?inviteId=${id}`);
    const responseBody = await response.arrayBuffer();
    const responseObj = new VerifyBorrowerInviteUrlQueryResponse();
    VerifyBorrowerInviteUrlQueryResponse.deserializeBinaryFromReader(responseObj, new BinaryReader(responseBody));
    return responseObj.toObject();
  },
  { enabled: Boolean(id) },
);

export { useInvitationVerification };
