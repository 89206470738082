import clsx from 'clsx';

import type { IButtonProps } from '@/Components/Atoms/Button';
import { Button } from '@/Components/Atoms/Button';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';

export interface IButtonGroupProps {
  buttons: Array<IButtonProps>;
  className?: string;
  fullWidth?: boolean;
  justifyContent?: 'center' | 'end' | 'start';
  isLoading?: boolean;
}

const ButtonGroup = (props: IButtonGroupProps) => {
  const { buttons, className = '', fullWidth = false, isLoading = false, justifyContent = 'start' } = props;
  const { hasError } = useDynamicForm();

  return (
    <div className={clsx(
      'flex flex-row w-full gap-2',
      {
        'justify-center': justifyContent === 'center',
        'justify-end': justifyContent === 'end',
        'justify-start': justifyContent === 'start',
      },
      className,
    )}
    >
      {buttons.map((buttonProps) => (
        <Button
          key={buttonProps.label}
          className={clsx(
            'w-auto justify-center',
            {
              'flex-auto': fullWidth,
            },
          )}
          disabled={buttonProps.type === 'submit' ? hasError : false}
          isLoading={isLoading}
          {...buttonProps}
        />
      ))}
    </div>
  );
};

export { ButtonGroup };
