import { Icon } from './Icon';

export interface ISearchBarProps {
  searchQuery: string;
  onChange: (value: string) => void;
}

const SearchHeader = (props: ISearchBarProps) => {
  const { onChange, searchQuery } = props;

  return (
    <div className="items-center border-b-[1px] border-b-on-surface-stroke flex
    justify-center max-w-full overflow-x-hidden py-2 px-4 w-full"
    >
      <Icon className="text-on-surface-inactive cursor-pointer block text-[24px]" icon="Search" />
      <input
        autoComplete="off"
        className="border-none text-[16px] min-h-10 py-0 px-[12px] w-[242px]"
        name="search"
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search fields"
        type="search"
        value={searchQuery || ''}
      />
    </div>
  );
};

export { SearchHeader };
