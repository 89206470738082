import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/Routes/Auth/AppAuthContext';

const urlRegex = /^([^:]+):\/\/([^/]+)\/([^?]+)\??(.*)$/gi;
function parseURI(uri: string) {
  urlRegex.lastIndex = 0;
  const values = urlRegex.exec(uri);

  if (!values) {
    return {};
  }

  const [, scheme, host, path, query] = values;

  return { host, path, query, scheme };
}

const useGetFile = () => {
  const { withAccessToken } = useAuth();

  const getFile = async (uri: string) => {
    const { host, path, scheme } = parseURI(uri);

    if (scheme !== 'wilqo-sfs') {
      return uri;
    }

    const headers = new Headers();
    const accessToken = await withAccessToken();
    const url = `/api/public/files/untrusted/institutions/${host}/${path}`;
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
      headers,
      method: 'GET',
    };
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return `${window.location.origin}/api/public/files/untrusted/file/${data.value}`;
  };

  return useMutation(getFile);
};

export { useGetFile };
