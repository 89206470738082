import clsx from 'clsx';

import { Icon } from './Icon';
import { Typography } from './Typography';

export interface MenuItemProps {
  label: string;
  animation?: string;
  leadingIcon?: string;
  trailingIcon?: string;
  isToggle?: boolean;
  onClick: (event: any) => void;
}

const MenuOption = (props: MenuItemProps) => {
  const { animation, isToggle, label, leadingIcon, onClick, trailingIcon } = props;

  return (
    <div
      className={clsx(
        'flex flex-row items-center py-3 bg-surface gap-[20px] hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed select-none cursor-pointer',
        {
          'px-4': !leadingIcon && !isToggle && !trailingIcon,
          'px-6': leadingIcon || isToggle || trailingIcon,
        },
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      {leadingIcon && <Icon animation={animation} icon={leadingIcon} size="md" />}
      {label && (
        <Typography
          className="flex-1 whitespace-nowrap"
          variant={isToggle ? 'body1Bold' : 'body1'}
        >
          {label}
        </Typography>
      )}
      {trailingIcon && <Icon icon={trailingIcon} size="md" />}
    </div>
  );
};

export { MenuOption };
