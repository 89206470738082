import { useMemo, useState } from 'react';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type {
  IndividualEscrowPayment,
  LoanEscrowFee,
  LoanFeeCategoryTypeEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  EscrowItemEnum,
  FeeCalculationTypeEnum,
  FeeSourceEnum,
  IntegratedDisclosureSectionEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { convertUxEnum } from '@/Utils/Helpers/getUxStatus';
import { convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { createListItems, handleAdditionalInfoSubmit } from '../../../helpers/individualFeesHelper';
import { FeePaidBy } from './FeePaidBy';
import { FeePaidTo } from './FeePaidTo';

interface IndividualFeeEditProps {
  fee: LoanEscrowFee.AsObject;
  categoryType: LoanFeeCategoryTypeEnum;
  clearFee: VoidFunction;
}

const additionalInfo = [
  { enumType: FeeCalculationTypeEnum, id: 'escrowCalculationType', label: 'Fee Calculation Type' },
  { enumType: IntegratedDisclosureSectionEnum, id: 'integratedDisclosureSectionType', label: 'Integrated Disclosure Section', type: 'select' },
  { enumType: EscrowItemEnum, id: 'escrowType', label: 'Fee Type', type: 'select' },
  { field: 'regulationZPointsAndFeesIndicator', id: 'regulationZPointsAndFees', label: 'Regulation Z Points and Fees', type: 'switch' },
  { field: 'escrowTotalPercent', id: 'escrowTotalPercent', label: 'Fee Total Percent' },
  { enumType: FeeSourceEnum, id: 'escrowSource', label: 'Fee Source Type' }];

const enabledFields = ['escrowType', 'regulationZPointsAndFees', 'integratedDisclosureSectionType'];

const EscrowIndividualFeeEdit = (props: IndividualFeeEditProps) => {
  const { categoryType, clearFee, fee } = props;
  const { escrowPayment: currentEscrowPayment, total } = fee;
  const { feePermissions, totalMet } = useFeesContext();
  const emptyValue = numberFormatter.format(0);
  const { isMobile } = useWindow();
  const [errorState, setErrorState] = useState<string[]>([]);
  const [escrowPayment, setEscrowPayment] = useState<IndividualEscrowPayment.AsObject | undefined>(currentEscrowPayment);

  const addError = (error: string) => {
    setErrorState((prevErrors) => [...prevErrors, error]);
  };
  const removeError = (error: string) => {
    setErrorState((prevErrors) => prevErrors.filter((e) => e !== error));
  };

  const listItems = useMemo(() => createListItems(escrowPayment, additionalInfo, enabledFields), [escrowPayment]);

  return (
    <DialogLayout>
      <DialogHeader icon={totalMet.exceeded || errorState.length > 0 ? 'Error' : 'ArrowBack'} iconAction={(totalMet.exceeded || errorState.length > 0 ? () => undefined : clearFee)} title={fee.name} />
      <DialogContent>
        <div className="flex flex-col gap-6 h-full w-full p-12 overflow-auto">
          <div className="w-min">
            <InfoCard
              caption={totalMet.exceeded || errorState.length > 0 ? totalMet.message : 'TOTAL FEE AMOUNT'}
              isMobile={isMobile}
              value={convertProtoToCurrency(total) || emptyValue}
              variant={convertUxEnum(totalMet.exceeded || errorState.length > 0 ? UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER : UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW)}
            />
          </div>
          <FeePaidBy
            addError={addError}
            categoryType={categoryType}
            errorState={errorState}
            escrowPayment={escrowPayment}
            fee={fee}
            isInErrorState={errorState.length > 0}
            removeError={removeError}
            total={ConvertProtoDecimalAsObjectToNumber(total)}
          />
          <FeePaidTo escrowPayment={escrowPayment} />
          <CardListItem
            headerProps={{ title: 'Additional info' }}
            hideActions={!feePermissions?.editFeeDetailValue}
            isEditable
            isLoading={false}
            listItems={listItems}
            onSubmit={handleAdditionalInfoSubmit(enabledFields, additionalInfo, escrowPayment, setEscrowPayment)}
            revertEmphasis
            variant="border"
          />
        </div>
      </DialogContent>
    </DialogLayout>
  );
};

export default EscrowIndividualFeeEdit;
