import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetDocumentCategoryByIdQueryRequest, GetDocumentCategoryByIdQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest';

const useDocumentCategory = (id: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();
  return useQuery([MESSAGE_NAME, bpdId, id], async () => {
    const request = new GetDocumentCategoryByIdQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setId(id);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetDocumentCategoryByIdQueryResponse();
    GetDocumentCategoryByIdQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.documentCategory);
  }, { enabled: Boolean(bpdId) && Boolean(id) });
};

export { useDocumentCategory };
