import clsx from 'clsx';
import type { MouseEventHandler } from 'react';

export type SelectionSurfaceVariant = 'default' | 'outlined' | 'transparent';

interface SelectionSurfaceProps {
  selected: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  variant?: SelectionSurfaceVariant;
  focused?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const SelectionSurface = (props: SelectionSurfaceProps) => {
  const { children, className, disabled, focused, onClick, selected, variant = 'default' } = props;

  const renderSurface = () => {
    switch (variant) {
      case 'outlined':
        return (
          <div
            className={
              clsx(
                'rounded text-on-surface-inactive py-2 pl-4 pr-5 [user-select:none] [&_i]:text-on-surface-inactive',
                ' relative',
                {
                  'bg-on-surface-states-focus': focused,
                  'bg-surface': !disabled && !focused,
                  'cursor-pointer hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed': !disabled,
                },
              )
            }
            onClick={onClick}
            onKeyDown={() => null}
            role="button"
            tabIndex={-1}
          >
            {children}
          </div>
        );
      case 'transparent':
        return (
          <div
            className={
              clsx(
                '[user-select:none]',
                {
                  '[&_i]:text-primary-on-surface': selected,
                },
                className,
              )
            }
            onClick={onClick}
            onKeyDown={() => null}
            role="button"
            tabIndex={-1}
          >
            {children}
          </div>
        );
      default:
        return (
          <div
            className={
              clsx(
                'rounded text-on-surface-inactive py-2 pl-4 pr-5 [user-select:none] [&_i]:text-on-surface-inactive ',
                {
                  'bg-on-surface-states-focus': focused,
                  'bg-primary-on-surface-states-activated text-on-surface-active [&_i]:text-primary-on-surface': selected && !focused,
                  'bg-surface-variant': !selected && !focused,
                  'cursor-pointer hover:bg-on-surface-states-resting active:bg-on-surface-states-pressed': !disabled && !focused,
                },
              )
            }
            onClick={onClick}
            onKeyDown={() => null}
            role="button"
            tabIndex={-1}
          >
            {children}
          </div>
        );
    }
  };

  return renderSurface();
};

export { SelectionSurface };
