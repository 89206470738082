import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useRef } from 'react';

import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';

import { BottomNav } from './BottomNav';
import type { IButtonProps } from './Button';
import { Icon } from './Icon';
import { Typography } from './Typography';

export interface DialogProps {
  title: string;
  children?: ReactNode;
  className?: string;
  actions: IButtonProps[];
  dismissButtonLabel?: string;
  onClickDismiss?: (outside?: boolean) => void;
  showHeaderDivider?: boolean;
  size?: 'default' | 'medium' | 'small';
  dismissIcon?: string;
}

const Dialog = (props: DialogProps) => {
  const {
    actions,
    children,
    className,
    dismissButtonLabel = '',
    dismissIcon = 'Clear',
    onClickDismiss,
    size = 'default',
    title,
  } = props;

  const localRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(localRef, () => {
    if (onClickDismiss) {
      onClickDismiss(true);
    }
  });

  const navActions: IButtonProps[] = dismissButtonLabel ? [
    { label: dismissButtonLabel, onClick: () => onClickDismiss?.(), variant: 'tertiary' },
    ...actions,
  ] : actions;

  return (
    <>
      <div
        className="fixed bg-black/50 h-full w-full pointer-events-auto right-0 top-0 z-[98] transition-opacity"
        id="backDrop"
      />
      <div
        ref={localRef}
        className={
          clsx(
            'fixed flex flex-col bg-white rounded shadow-two z-[99] pointer-events-auto h-[100%-150px] right-0 left-0 top-[150px] mx-auto transition-opacity',
            {
              'h-auto w-80': size === 'small',
              'h-auto w-full max-w-[560px]': size === 'medium',
              'max-h-[450px] max-w-[320px] mobile:max-w-[560px]': size === 'default',
            },
            className,
          )
        }
      >
        <div className="flex flex-row py-3 px-4 border-b border-b-on-primary-stroke items-center">
          <Icon className="mr-2" icon={dismissIcon} onClick={() => onClickDismiss?.()} variant="interactive" />
          <Typography className="m-0 text-on-surface-active" variant="display6">
            {title}
          </Typography>
        </div>
        <div className="h-full p-6 overflow-y-auto text-sm">
          {children}
        </div>
        {navActions.length > 0 && (
          <BottomNav
            actions={navActions}
            align="right"
            borderTop={navActions.length > 0}
            variant="dense"
          />
        )}
      </div>
    </>
  );
};

export { Dialog };
