import type { InfoCard } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/InfoCard_pb';
import { InfoCard as InfoCardComponent } from '@/Components/Atoms/InfoCard';
import { convertUxEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';

export function InfoCardWidget(props: InfoCard.AsObject) {
  const { itemsList } = props;
  const { isMobile } = useWindow();
  return (
    <div className="flex flex-col md:flex-row gap-4 w-full">
      {itemsList.map((item) => (
        <InfoCardComponent
          key={item.caption}
          isMobile={isMobile}
          {...item}
          variant={convertUxEnum(item.variant)}
        />
      ))}
    </div>
  );
}
