import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { BatchReorderPanelCommandRequest, PanelSequenceDesignCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.BatchReorderPanelCommandRequest';

const useBatchReorderPanel = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();
  const {
    designerPanel: { entityId, panelSequenceDesignId },
  } = useActivityConfiguratorContext();

  const BatchReorderPanelCommand = async (panelMoves: BatchReorderPanelCommandRequest.MoveDetail.AsObject[]) => {
    const request = new BatchReorderPanelCommandRequest();
    request.setActivityDesignId(entityId);
    request.setPanelSequenceDesignId(panelSequenceDesignId);
    request.setBusinessProcessDomainId(bpdId);
    panelMoves.forEach((moveObject) => {
      const move = new BatchReorderPanelCommandRequest.MoveDetail();
      move.setPanelId(moveObject.panelId);
      move.setNewIndex(moveObject.newIndex);
      request.getPanelMovesList().push(move);
    });
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PanelSequenceDesignCommandResponse();
    PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(BatchReorderPanelCommand);
};

export { useBatchReorderPanel };
