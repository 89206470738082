import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { Footer } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { GetFooterByUserTypeQueryRequest, GetFooterByUserTypeQueryResponse } from '@/API/Models/Wilqo_API_Brand_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.brand.GetFooterByUserTypeQueryRequest';

const useGetFooterByUserType = (userType: Footer.UserType, enabled?: boolean) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, userType], async () => {
    const request = new GetFooterByUserTypeQueryRequest();

    request.setUsertype(userType);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetFooterByUserTypeQueryResponse();
    GetFooterByUserTypeQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.footerobject);
  }, { enabled });
};
export { useGetFooterByUserType };
