import { useParams } from 'react-router';

import { useActivityValidationChecklist } from '@/API/Queries/activities/useActivityValidationChecklist';

import { IncompleteList } from '../../incompleteList';
import { useValidationProgress } from '../validationProgressContext';

const ValidationIncompleteTab = () => {
  const { activityId = '' } = useParams();
  const { checklistProgress } = useValidationProgress();
  const { data: activity, isLoading } = useActivityValidationChecklist(activityId);

  return (
    <IncompleteList
      checklistProgress={checklistProgress}
      isLoading={isLoading}
      items={activity?.checklist?.itemsList || []}
      type="validation"
    />
  );
};

export { ValidationIncompleteTab };
