// TODO: deprecate this once backend is sending UXDisplay for all status

import { InfoCardTypeEnum } from '@/API/Models/Wilqo.Shared.Models/BrandAdminModels_pb';
import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';

export enum ExtendedStatusEnum {
  NEW = 'new',
  PROGRESS = 'progress',
  SUCCESS = 'success',
  DANGER = 'danger',
  RESTING = 'resting',
  COMPLETE = 'complete',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
  DEFAULT = 'default',
}

export const convertStatusEnum = (value: InfoCardTypeEnum): ExtendedStatusEnum => {
  switch (value) {
    case InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_DANGER:
      return ExtendedStatusEnum.DANGER;
    case InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_NEUTRAL:
      return ExtendedStatusEnum.NEUTRAL;
    case InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_DEFAULT:
      return ExtendedStatusEnum.DEFAULT;
    case InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_SUCCESS:
      return ExtendedStatusEnum.SUCCESS;
    default:
      return ExtendedStatusEnum.NEUTRAL;
  }
};

export const convertUxEnum = (value: UxDisplayStatusEnum): ExtendedStatusEnum => {
  switch (value) {
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW:
      return ExtendedStatusEnum.NEW;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_PROGRESS:
      return ExtendedStatusEnum.PROGRESS;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS:
      return ExtendedStatusEnum.SUCCESS;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER:
      return ExtendedStatusEnum.DANGER;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_RESTING:
      return ExtendedStatusEnum.RESTING;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE:
      return ExtendedStatusEnum.COMPLETE;
    case UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_WARNING:
      return ExtendedStatusEnum.WARNING;
    default:
      return ExtendedStatusEnum.NEUTRAL;
  }
};
