import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetQuestionnaireQueryResponse } from '@/API/Models/Wilqo_API_Questionnaire_Queries_pb';
import { useSearchParams } from '@/Routes/NavigationContext';

const useQuestionnaire = (runId: string) => {
  const [urlSearchParams] = useSearchParams();
  const { lo: loanOfficerId } = Object.fromEntries([...urlSearchParams]);
  return useQuery(['getQuestionnaire', runId, loanOfficerId], async () => {
    let url = '/mortgage/api/configuration/questionnaire';
    let queryParams = {};
    if (loanOfficerId) queryParams = { loanOfficerId };
    if (runId) queryParams = { ...queryParams, questionnaireRunId: runId };
    const urlParams = new URLSearchParams(queryParams).toString();
    if (urlParams) url = `${url}?${urlParams}`;
    const response = await fetch(url, {
      headers: [
        ['caller-url', window?.location?.href],
      ],
      method: 'POST',
    });
    if (!response.ok) {
      throw new Error('Communication error');
    }
    const responseBody = await response.arrayBuffer();
    const responseObject = new GetQuestionnaireQueryResponse();
    GetQuestionnaireQueryResponse.deserializeBinaryFromReader(responseObject, new BinaryReader(responseBody));
    return responseObject.toObject();
  }, {
    cacheTime: 0,
    onSuccess: (response) => {
      if (response) {
        sessionStorage.setItem('loanId', response.questionnaireRunId);
      }
    },
  });
};

export { useQuestionnaire };
