import { type HTMLProps, useMemo } from 'react';

import { cn } from '@/Utils/Helpers/uiBuilders';

import { AnimatedBackdrop } from '../AnimatedBackdrop';

export interface IDrawerRoot extends HTMLProps<HTMLDivElement> {
  openFrom?: 'bottom' | 'left' | 'right';
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const DrawerRoot = ({
  children,
  className,
  onOpenChange,
  open,
  openFrom,
}: IDrawerRoot) => {
  const drawerStyling = useMemo(() => {
    if (openFrom === 'right') {
      return {
        'right-0': open,
        'w-0 right-[-256px]': !open,
      };
    }
    if (openFrom === 'bottom') {
      return {
        'bottom-0 top-8 inset-x-0': open,
        'h-0 top-full': !open,
        'rounded-t-2xl': true,
      };
    }

    return {
      'left-0': open,
      'w-0 left-[-256px]': !open,
    };
  }, [open, openFrom]);

  return (
    <>
      <div
        hidden={!open}
        onClick={() => onOpenChange(!open)}
        onKeyDown={() => onOpenChange(!open)}
        role="button"
        tabIndex={0}
      >
        <AnimatedBackdrop className="right-0 top-0 w-full" />
      </div>

      <div
        className={cn(
          'min-w-[256px] flex flex-col w-full bg-surface-variant overflow-hidden ease-in-out duration-300 fixed top-0 z-50 h-[100vh]',
          {
            'max-w-[256px]': openFrom !== 'bottom',
            ...drawerStyling,
          },
          className,
        )}
      >
        {children}
      </div>
    </>
  );
};
