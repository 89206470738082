import { useMemo } from 'react';

import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { ddeAreEqual, DynamicDataElementMap, fromPanelElementOption } from '@/Utils/Helpers/dynamicDataElementHelper';

import type { SelectionSurfaceVariant } from '../SelectionSurface';
import Component from './RadioGroup.component';

interface ExtendedPanelElementProps extends PanelElement.AsObject {
  variant?: string;
  orientation?: number;
}

export interface IRadioGroupProps {
  className?: string;
  isAccordion?: boolean;
  panelElement: ExtendedPanelElementProps;
}

const RadioGroup = (props: IRadioGroupProps) => {
  const {
    className,
    isAccordion = false,
    panelElement: {
      disabled,
      headerText: label,
      id,
      optionsList,
      orientation,
      requirement,
      variant,
    },
  } = props;

  const [
    selectedOption,
    setValue,
    { disabled: conditionallyDisabled, errorMessage, hidden, ref },
  ] = useDynamicFormRegister<PanelElementOption.AsObject | undefined>(
    id,
    {
      fromDynamic: (value) => optionsList.find((option) => {
        if (option.value) return ddeAreEqual(DynamicDataElementMap(option.value), value);
        return false;
      }),
      toDynamic: (value) => fromPanelElementOption(value || optionsList[0]),
    },
    { requirement },
  );

  const handleSelect = (option: PanelElementOption.AsObject) => {
    if (
      option.value
      && selectedOption?.value
      && ddeAreEqual(DynamicDataElementMap(option.value), DynamicDataElementMap(selectedOption.value))
    ) {
      setValue(undefined, '', '');
    } else {
      setValue(option, option.headerText, option.path);
    }
  };

  const options = useMemo(() => optionsList.filter((option) => option.headerText !== '--'), [optionsList]);

  if (hidden) return null;
  return (
    <Component
      className={className}
      disabled={disabled || conditionallyDisabled}
      errorMessage={errorMessage}
      id={id}
      inputRef={ref}
      isAccordion={isAccordion}
      label={label}
      onSelect={handleSelect}
      optionLabelKey="headerText"
      options={options}
      orientation={orientation}
      required={requirement?.required}
      value={selectedOption?.id}
      valueLabelKey="id"
      variant={variant as SelectionSurfaceVariant}
    />
  );
};

export default RadioGroup;
