import clsx from 'clsx';

import { Icon } from './Icon';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { ProgressBar } from './ProgressBar';

interface MobileProgressAppBarProps {
  panelHeaderText: string;
  currentStep: number;
  logoExternal?: boolean;
  stepsLength: number;
  dropdownOptions?: Array<any>;
}

const MobileProgressAppBar = (props: MobileProgressAppBarProps) => {
  const { currentStep, dropdownOptions, logoExternal, panelHeaderText, stepsLength } = props;

  const progressContainerCname = clsx(
    'pb-8 bg-surface-background p-4 sticky top-0 z-[1] ease-linear transition-colors duration-500',
  );

  const headerContainerCname = clsx(
    'flex items-end bg-primary px-4 pt-8 pb-4',
  );

  return (
    <>
      <div className={progressContainerCname}>
        <Logo className="mb-8" external={logoExternal} variant="default" />
        <div className="flex items-center justify-center">
          {dropdownOptions && (
          <Menu
            options={dropdownOptions}
            triggerItem={<Icon className="!text-on-surface-inactive" icon="Menu" />}
          />
          )}
          <div className="ml-2 w-full">
            <ProgressBar currentStep={currentStep} stepsNumber={stepsLength} variant="dark" />
          </div>
        </div>
      </div>
      <div className={headerContainerCname}>
        <span className="font-five font-semibold text-on-primary-solid block text-2xl">{panelHeaderText}</span>
      </div>
    </>
  );
};

export { MobileProgressAppBar };
