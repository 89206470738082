import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { OperatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { GetAllOperatorsByDataTypeQueryRequest, GetAllOperatorsByDataTypeQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetAllOperatorsByDataTypeQueryRequest';

export interface OperatorsMap {
  [key: string]: Array<OperatorSummary.AsObject>;
}

const useOperators = () => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME], async () => {
    const request = new GetAllOperatorsByDataTypeQueryRequest();
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAllOperatorsByDataTypeQueryResponse();
    GetAllOperatorsByDataTypeQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.dataTypesList);
  },
  {
    select: (data) => {
      let operators: OperatorsMap = {};
      if (data) {
        operators = data?.reduce((prevValue, current) => ({
          ...prevValue,
          [current.dataType]: current.operatorsList,
        }), {});
      }
      return operators;
    },
  });
};
export { useOperators };
