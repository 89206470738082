import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useLenderCondition } from '@/API/Queries/mortgageConfig/useLenderCondition';
import { usePublishCondition } from '@/API/Queries/mortgageConfig/usePublishCondition';
import { Button } from '@/Components/Atoms/Button';
import { ContextualAppBar } from '@/Components/Atoms/ContextualAppBar';
import { Tabs } from '@/Components/Atoms/Tab';
import { useNavigateContext } from '@/Routes/NavigationContext';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { ConditionDetails } from './ConditionDetails';
import { ConditionTrigger } from './ConditionTrigger';

type Tab = 'details' | 'triggers';

export const Condition = () => {
  // libraries
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  // queries and mutations
  const { data: condition, isLoading } = useLenderCondition(id);
  const { isLoading: isPublishing, mutate: publishCondition } = usePublishCondition();

  // state and context
  const [currentTab, setCurrentTab] = useState<Tab>('details');
  const [chicken, setChicken] = useState(false);
  const { prompts } = useNavigateContext();

  // memo
  const prompt = useMemo(() => (prompts ? prompts['Condition Detail'] : undefined), [prompts]);

  // event handlers
  const handleClickArrow = () => {
    navigate(-1);
  };

  const handlePublishSuccess = () => {
    showSnackBar({ message: 'Condition published', open: true });
    navigate(-1);
  };

  const handlePublishError = (error: Error) => {
    showSnackBar({ message: error.message, open: true });
  };

  const handlePublishClick = (ignorePrompt = false) => {
    if (prompt && !ignorePrompt) {
      setChicken(true);
    } else {
      publishCondition(
        id,
        {
          onError: handlePublishError,
          onSuccess: handlePublishSuccess,
        },
      );
    }
  };

  return (
    <div className="h-full">
      <ContextualAppBar
        icon="ArrowBack"
        loading={isLoading}
        onClickIcon={handleClickArrow}
        title={condition?.title}
      >
        <Button
          isLoading={isPublishing}
          label="Publish"
          onClick={() => handlePublishClick(false)}
          variant="primary"
        />
      </ContextualAppBar>
      <Tabs
        tabsData={[
          { active: currentTab === 'details', label: 'Details', onClick: () => setCurrentTab('details') },
          { active: currentTab === 'triggers', label: 'Triggers', onClick: () => setCurrentTab('triggers') },
        ]}
      />
      {
        currentTab === 'details' ? (
          <ConditionDetails
            chicken={chicken}
            handlePublish={() => handlePublishClick(true)}
            setChicken={setChicken}
          />
        ) : (
          <ConditionTrigger />
        )
      }
    </div>
  );
};
