import clsx from 'clsx';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { Typography } from './Typography';

export interface IBadgeProps {
  variant: UxDisplayStatusEnum;
  label: string;
  caps?: boolean;
  className?: string;
}

const Badge = (props: IBadgeProps) => {
  const { caps = false, className, label, variant } = props;
  return (
    <div className={clsx(
      className,
      'flex items-center justify-center rounded my-0.5 px-1 max-w-max text-center max-h-4',
      {
        'bg-status-completed': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE === variant,
        'bg-status-danger': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER === variant,
        'bg-status-inprogress': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_PROGRESS === variant,
        'bg-status-new': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW === variant,
        'bg-status-resting': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_RESTING === variant,
        'bg-status-success': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS === variant,
        'bg-status-warning': UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_WARNING === variant,
        uppercase: caps,
      },
    )}
    >
      <Typography className="text-on-surface-inactive uppercase" variant="caption">
        {label}
      </Typography>
    </div>
  );
};

export { Badge };
