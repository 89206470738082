const arrayToString = <T extends Record<string, unknown>>(list: Array<T>, key: keyof T): string => list.reduce(
  (acc: string, current, index) => {
    const text = current[key];
    if (index === 0) {
      return `${text}`;
    }
    if (index <= list.length - 1) {
      return `${acc}, ${text}`;
    }
    return `${acc} ${text}`;
  }, '',
);

const manageList = <T extends any>(list: Array<T>, newItem: T, key?: keyof T): Array<T> => {
  if (list.some((listItem) => (key ? listItem[key] === newItem[key] : listItem === newItem))) {
    return list.filter((listItem) => (key ? listItem[key] !== newItem[key] : listItem !== newItem));
  }
  return list.concat(newItem);
};

export { arrayToString, manageList };
