import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useSkillset } from '@/API/Queries/user/useSkillset';
import { useSkillsetGroups } from '@/API/Queries/user/useSkillsetGroups';
import { useUpdateSkillset } from '@/API/Queries/user/useUpdateSkillset';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { getAssignedBadgeProps } from '@/Utils/Helpers/getAssignedBadgeProps';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

type TableItem = {
  id: string;
  displayName: string;
  description: string;
  badge?: IBadgeProps;
};

const SkillsetSkillsetGroups = () => {
  const { id = '' } = useParams();
  const { showSnackBar } = useShared();

  const { data: skillset, refetch } = useSkillset(id);
  const { data: skillsetGroups = [], isLoading } = useSkillsetGroups();
  const { mutate: updateSkillset } = useUpdateSkillset();

  const [selectedItems, setSelectedItems] = useState<TableItem[]>([]);

  const handleSubmit = useCallback((items: string[]) => {
    if (skillset) {
      updateSkillset({
        displayName: skillset.displayName,
        longDescription: skillset.longDescription,
        skillsetGroupIds: items,
        skillsetId: id,
      }, {
        onError: (error) => showSnackBar({ message: String(error), open: true }),
        onSuccess: () => setTimeout(refetch, 500),
      });
    }
  }, [id, updateSkillset, skillset, refetch, showSnackBar]);

  const handleClickItem = useCallback((item: TableItem) => {
    if (skillset) {
      if (item.badge) {
        const items = skillset.skillsetGroupsList.filter((group) => group.id !== item.id);
        const ids = items.map((item) => item.id);
        handleSubmit(ids);
      } else {
        const items = [...skillset?.skillsetGroupsList, item];
        const ids = items.map((item) => item.id);
        handleSubmit(ids);
      }
    }
  }, [handleSubmit, skillset]);

  const handleAssignBulk = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    const currentIds = skillset?.skillsetGroupsList.map((item) => item.id) || [];
    const assignedSkillsetGroups = [...selectedIds, ...currentIds];
    handleSubmit(assignedSkillsetGroups);
  };

  const handleUnassignBulk = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    const currentIds = skillset?.skillsetGroupsList.map((item) => item.id) || [];
    const assignedSkillsetGroups = currentIds.filter((id) => !selectedIds.some((selectedId) => selectedId === id));
    handleSubmit(assignedSkillsetGroups);
  };

  const data = useMemo((): TableItem[] => {
    const items = skillsetGroups.map((group): TableItem => ({
      badge: skillset?.skillsetGroupsList.some((i) => i.id === group.id) ? getAssignedBadgeProps() : undefined,
      description: group.longDescription,
      displayName: group.displayName,
      id: group.id,
    }));
    const assigned = items.filter((item) => item.badge);
    const unassigned = items.filter((item) => !item.badge);
    return [...assigned, ...unassigned];
  }, [skillsetGroups, skillset]);

  const columns = useMemo((): Column<TableItem>[] => [
    {
      header: 'Name',
      id: 'name',
      key: { text: 'displayName', type: 'text' },
    },
    {
      header: 'Description',
      id: 'description',
      key: { text: 'description', type: 'text' },
    },
    {
      header: 'Assign',
      id: 'badge',
      key: { badge: 'badge', type: 'badge' },
    },
  ], []);

  return (
    <Table
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Assign', onClick: handleAssignBulk },
              { label: 'Unassign', onClick: handleUnassignBulk },
            ],
            caption: `${selectedItems.length} selected skillset groups(s)`,
          },
          showContextual: selectedItems.length > 0,
          title: 'All Skillset Groups',
        },
      }}
      columns={columns}
      data={data}
      hoverActions={[
        { label: (item) => (item.badge ? 'Unassign' : 'Assign'), onClick: handleClickItem },
      ]}
      isLoading={isLoading}
      setRowSelection={setSelectedItems}
    />
  );
};

export { SkillsetSkillsetGroups };
