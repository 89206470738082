import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { Panel, PanelElementResponse } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { WilqoTimestamp } from '@/API/Models/Wilqo.Shared.Models/Timestamp_pb';
import type { OperatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';

import { fromBool, fromNumber, fromString, fromTimestamp } from './dynamicDataElementHelper';
import { getPanelElementOption } from './getPanelElement';

interface FormProgress {
  panelId: string;
  panelElements: Array<PanelElementResponse.AsObject>;
}

const mapFromToProgress = (panelId: string, formData: any, keys: Array<string>) => keys.map((key) => ({
  id: key,
  panelId,
  value: formData[key],
}));

export const getProgressFromPanelElementValue = (
  progressItemId: string,
  panelElementValue?: any,
  formData?: any,
  panels?: Array<Panel.AsObject>,
) => {
  let progressValue: DynamicDataElement = new DynamicDataElement();

  let panelElement = panels?.flatMap((p) => p.panelElementsList)?.find((pe) => pe.id === progressItemId);

  if (!panelElement) {
    return fromString(formData).toObject();
  }

  if (panelElement?.externalLink?.value) {
    panelElement = panels?.filter((p) => p.id === panelElement?.externalLink?.value)[0].panelElementsList[0];
  }

  const panelElementType = panelElement?.type;

  if (!panelElementValue.value) {
    return undefined;
  }

  switch (panelElementType) {
    case 'auto-complete-postal-code':
    case 'auto-complete-address':
    case 'auto-complete':
      progressValue = fromString(formData[panelElementValue.id]);
      break;
    case 'radioGroup':
    case 'buttonGroup':
    case 'select': {
      const panelElementOptionSelected = panelElement?.optionsList.find((oi) => oi.id === (panelElementValue.value?.id ?? panelElementValue.value));
      // The adding of the value property has not been merged in yet, this work around is for testing (works with the hard coded data)
      if (panelElementOptionSelected) {
        return panelElementOptionSelected.value;
      } // backwards compatible
      progressValue = fromString(panelElementValue.value?.id || panelElementValue.value || '');
    }
      break;
    case 'checkbox':
      progressValue = fromBool(panelElementValue.value);
      break;
    case 'date': {
      const date = new WilqoTimestamp();
      date.setStorage(Timestamp.fromDate(new Date(Date.parse(panelElementValue.value))));
      progressValue = fromTimestamp(date);
    }
      break;
    case 'number':
      {
        const result = parseFloat(panelElementValue.value.replace(/\D+/g, ''));
        progressValue = fromNumber(result);
      }
      break;
    case 'text':
      progressValue = fromString(panelElementValue.value);
      break;
    default:
      throw new Error(`unhandled type ${panelElementType}`);
      break;
  }
  return progressValue.toObject();
};

export const getRulePanelElementType = (panelElementType: string, operator?: OperatorSummary.AsObject) => {
  let type = panelElementType;
  if (type === 'radioGroup') {
    type = 'select';
  }
  if (operator?.panelElementTypeOverride) {
    type = operator.panelElementTypeOverride;
  }
  return type;
};

const createBackProgress = (
  panelId: string,
  formData: any,
  panelList?: Array<Panel.AsObject>,
): FormProgress => {
  if (typeof formData !== 'object') {
    const panelElement = {
      id: formData,
      panelId,
      value: fromString(formData).toObject(),
    };
    return {
      panelElements: [panelElement],
      panelId,
    };
  }

  const keys = Object.keys(formData);
  const filteredKeys = keys.filter((key) => key !== 'undefined' && !key.startsWith('$-'));
  const progress = mapFromToProgress(panelId, formData, filteredKeys);

  const backendProgress = progress.map((progressItem) => {
    const value = getProgressFromPanelElementValue(progressItem.id, progressItem, formData, panelList);
    return {
      ...progressItem,
      value,
    };
  });

  return {
    panelElements: backendProgress,
    panelId,
  };
};

const createFrontProgress = (
  panelId: string,
  formData: any,
) => {
  if (typeof formData !== 'object') {
    return {
      panelElements: [{
        id: formData,
        panelId,
        value: formData,
      }],
      panelId,
    };
  }

  const keys = Object.keys(formData);
  const panelElements = mapFromToProgress(panelId, formData, keys);

  return {
    panelElements,
    panelId,
  };
};

const toDeprecatedProgress = (value: any): string => {
  let progressValue = '';

  if (typeof value === 'object') {
    progressValue = value.id;
  }

  if (Array.isArray(value)) {
    progressValue = value.map((item) => item.id).join(',');
  }

  if (typeof value === 'string') {
    progressValue = value;
  }

  if (typeof value === 'boolean') {
    progressValue = JSON.stringify(value);
  }

  return progressValue;
};

const fromDeprecatedProgress = (value: string, type: string) => {
  let progressValue;
  switch (type) {
    case 'select':
      progressValue = getPanelElementOption({ id: value });
      break;
    case 'multi-select':
      if (value) {
        progressValue = value.split(',').map((id) => getPanelElementOption({ id }));
      } else {
        progressValue = [];
      }
      break;
    default:
      progressValue = value;
  }

  return progressValue;
};

const getValuesFromWidgetLists = (widgets?: LoanPageWidget.AsObject[], initialValues: DynamicDataElementValues = {}) => (widgets || [])
  .reduce((widgetProgress: DynamicDataElementValues, currentWidget) => ({
    ...widgetProgress,
    ...currentWidget.cardListItem?.fieldsList.reduce((fieldProgress, currentField) => {
      if (currentField.panelElement?.type === 'chip-list' && currentField.panelElement.optionsList.length === 1) {
        return {
          ...fieldProgress,
          [currentField.id]: { fieldConfig: currentField, value: [currentField.panelElement?.optionsList[0].value] },
        };
      }
      return {
        ...fieldProgress,
        [currentField.id]: { dynamic: undefined, fieldConfig: currentField, value: undefined },
      };
    }, {}),
  }), initialValues);

export {
  createBackProgress,
  createFrontProgress,
  fromDeprecatedProgress,
  getValuesFromWidgetLists,
  toDeprecatedProgress,
};
