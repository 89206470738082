import { useQuery } from '@tanstack/react-query';

import { GetBusinessProcessTemplateDesignTriggerRulesQueryRequest, GetBusinessProcessTemplateDesignTriggerRulesQueryResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.business_process.GetBusinessProcessTemplateDesignTriggerRulesQueryRequest';

export const useBusinessProcessTemplateTrigger = (templateId: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, templateId, bpdId], async () => {
    const request = new GetBusinessProcessTemplateDesignTriggerRulesQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setTemplateDesignId(templateId);
    const response = await sendMessage<GetBusinessProcessTemplateDesignTriggerRulesQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetBusinessProcessTemplateDesignTriggerRulesQueryResponse,
    );
    return response.triggerRules;
  });
};
