import { MenuContent } from './MenuContent';
import { MenuFooter } from './MenuFooter';
import { MenuHeader } from './MenuHeader';
import { MenuItem } from './MenuItem';
import { MenuRadioGroup } from './MenuRadioGroup';
import { MenuRoot } from './MenuRoot';
import { MenuSubItem } from './MenuSubItem';
import { MenuTrigger } from './MenuTrigger';

export const DropdownMenu = {
  Content: MenuContent,
  Footer: MenuFooter,
  Header: MenuHeader,
  Item: MenuItem,
  RadioGroup: MenuRadioGroup,
  Root: MenuRoot,
  SubItem: MenuSubItem,
  Trigger: MenuTrigger,
};
