import { useState } from 'react';

import type { FeeParametrizedRuleAggregatorSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin_pb';
import type { FeeParametrizedRuleGroupSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import { Button } from '@/Components/Atoms/Button';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Switch } from '@/Components/Atoms/Switch';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { RuleGroup } from './RuleGroup';
import { TriggerProvider, useTriggerContext } from './TriggerContext';
import { TriggerTitle } from './TriggerTitle';

type ChildrenParams = {
  group: FeeParametrizedRuleGroupSummary.AsObject;
  trigger: FeeParametrizedRuleAggregatorSummary.AsObject;
};
type FunctionChildren = (props: ChildrenParams) => React.ReactNode;

interface TriggerProps {
  title: string[] | string;
  isLoading: boolean;
  onUpdateTrigger: (trigger: FeeParametrizedRuleAggregatorSummary.AsObject) => void;
  trigger?: FeeParametrizedRuleAggregatorSummary.AsObject;
  children?: FunctionChildren | React.ReactNode;
  getGroupId?: (groupId: string) => string;
  addDefaultAutomatically?: boolean;
}

const TriggerComponent = (props: TriggerProps) => {
  const { addDefaultAutomatically, children, isLoading, title } = props;

  const { addNewGroup, currentTrigger, toggleTriggerCondition } = useTriggerContext();
  const { windowType } = useWindow();
  const [currentRuleGroupId, setCurrentRuleGroupId] = useState('');

  const renderRuleGroups = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height="200px" variant="rect" width="100%" />
          <div className="bg-primary-on-surface-states-activated h-6 ml-6 w-1" />
          <Skeleton height="200px" variant="rect" width="100%" />
        </>
      );
    }

    return currentTrigger?.groupsList.map((group, index) => (
      <RuleGroup
        key={group.id}
        addDefaultAutomatically={addDefaultAutomatically}
        collapsed={windowType && windowType !== 'mobile' ? false : group.id !== currentRuleGroupId}
        group={group}
        index={index}
        onClickGroup={() => setCurrentRuleGroupId(group.id)}
      >
        {typeof children === 'function' ? children({ group, trigger: currentTrigger }) : children}
      </RuleGroup>
    ));
  };

  return (
    <div className="bg-surface-variant h-full p-12 w-full">
      <TriggerTitle text={title} />
      <div className="items-center flex flex-row mt-10">
        <Switch
          displayOnly
          elevation
          onSelect={() => toggleTriggerCondition()}
          optionsLabels={[{
            disabled: false,
            name: 'AND',
          }, {
            disabled: false,
            name: 'OR',
          }]}
          selectedItem={currentTrigger?.applyAndBetweenGroups ? 'AND' : 'OR'}
        />
        <span className="text-on-surface-active ml-3">Between rule groups</span>
      </div>
      <div className="bg-primary-on-surface-states-activated h-6 ml-6 w-1" />
      {renderRuleGroups()}
      <div className="bg-primary-on-surface-states-activated h-6 ml-6 w-1" />
      <Button label="Add Rule Group" leftIcon="Add" onClick={addNewGroup} variant="secondary" />
    </div>
  );
};

const Trigger = (props: TriggerProps) => (
  <TriggerProvider trigger={props.trigger} updateTrigger={props.onUpdateTrigger}>
    <TriggerComponent {...props} />
  </TriggerProvider>
);

export { Trigger };
