import { useMemo, useState } from 'react';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type {
  IndividualPrepaidPayment,
  LoanFeeCategoryTypeEnum,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  FeeCalculationTypeEnum,
  FeeSourceEnum,
  IntegratedDisclosureSectionEnum,
  PrepaidItemEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { convertUxEnum } from '@/Utils/Helpers/getUxStatus';
import { convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { createListItems, handleAdditionalInfoSubmit } from '../../../helpers/individualFeesHelper';
import { FeePaidBy } from './FeePaidBy';
import { FeePaidTo } from './FeePaidTo';

interface IndividualFeeEditProps {
  fee: LoanPrepaidFee.AsObject;
  categoryType: LoanFeeCategoryTypeEnum;
  clearFee: VoidFunction;
}

const additionalInfo = [
  { enumType: FeeCalculationTypeEnum, id: 'prepaidCalculationType', label: 'Fee Calculation Type' },
  { enumType: IntegratedDisclosureSectionEnum, id: 'integratedDisclosureSectionType', label: 'Integrated Disclosure Section', type: 'select' },
  { enumType: PrepaidItemEnum, id: 'prepaidType', label: 'Fee Type', type: 'select' },
  { field: 'regulationZPointsAndFeesIndicator', id: 'regulationZPointsAndFees', label: 'Regulation Z Points and Fees', type: 'switch' },
  { enumType: FeeSourceEnum, id: 'prepaidSource', label: 'Fee Source Type' },
];

const enabledFields = ['prepaidType', 'regulationZPointsAndFees', 'integratedDisclosureSectionType'];

const PrepaidIndividualFeeEdit = (props: IndividualFeeEditProps) => {
  const { categoryType, clearFee, fee } = props;
  const { prepaidPayment: currentFeePayment, total } = fee;
  const emptyValue = numberFormatter.format(0);
  const { isMobile } = useWindow();
  const { feePermissions, totalMet } = useFeesContext();
  const [errorState, setErrorState] = useState<string[]>([]);
  const [prepaidPayment, setPrepaidPayment] = useState<IndividualPrepaidPayment.AsObject | undefined>(currentFeePayment);

  const addError = (error: string) => {
    setErrorState((prevErrors) => [...prevErrors, error]);
  };
  const removeError = (error: string) => {
    setErrorState((prevErrors) => prevErrors.filter((e) => e !== error));
  };

  const listItems = useMemo(() => createListItems(prepaidPayment, additionalInfo, enabledFields), [prepaidPayment]);

  return (
    <DialogLayout>
      <DialogHeader icon={totalMet.exceeded || errorState.length > 0 ? 'Error' : 'ArrowBack'} iconAction={(totalMet.exceeded || errorState.length > 0 ? () => undefined : clearFee)} title={fee.name} />
      <DialogContent>
        <div className="flex flex-col gap-6 h-full w-full p-12 overflow-auto">
          <div className="w-min">
            <InfoCard
              caption={totalMet.exceeded || errorState.length > 0 ? totalMet.message : 'TOTAL FEE AMOUNT'}
              isMobile={isMobile}
              value={convertProtoToCurrency(total) || emptyValue}
              variant={convertUxEnum(totalMet.exceeded || errorState.length > 0 ? UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER : UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW)}
            />
          </div>
          <FeePaidBy
            addError={addError}
            categoryType={categoryType}
            errorState={errorState}
            fee={fee}
            isInErrorState={errorState.length > 0}
            prepaidPayment={prepaidPayment}
            removeError={removeError}
            total={ConvertProtoDecimalAsObjectToNumber(total)}
          />
          <FeePaidTo prepaidPayment={prepaidPayment} />

          <CardListItem
            headerProps={{ title: 'Additional info' }}
            hideActions={!feePermissions?.editFeeDetailValue}
            isEditable
            isLoading={false}
            listItems={listItems}
            onSubmit={handleAdditionalInfoSubmit(enabledFields, additionalInfo, prepaidPayment, setPrepaidPayment)}
            revertEmphasis
            variant="border"
          />
        </div>
      </DialogContent>
    </DialogLayout>
  );
};

export default PrepaidIndividualFeeEdit;
