import { useMutation } from '@tanstack/react-query';

import { UpsertLenderConditionTemplateCommandRequest, UpsertLenderConditionTemplateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import type { ConditionTemplateCategoryEnum } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import type { ConditionSatisfactionResponsiblePartyEnum, ConditionSatisfactionTimeframeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest';

interface UpdateObject {
  category: ConditionTemplateCategoryEnum;
  conditionId?: string;
  description: string;
  title: string;
  partyType: ConditionSatisfactionResponsiblePartyEnum;
  timeframeType: ConditionSatisfactionTimeframeEnum;
  internalName: string;
}

const useUpsertLenderCondition = () => {
  const sendMessage = useWilqoMessage();
  const auth = useAuth();

  const updateLenderConditionCommand = async (updateObject: UpdateObject) => {
    const { category, conditionId, description, internalName, partyType, timeframeType, title } = updateObject;
    const request = new UpsertLenderConditionTemplateCommandRequest();
    request.setBusinessProcessDomainId(auth.secureAuthValues.authorizedBusinessProcessDomainIds[0]);
    if (conditionId) request.setConditionId(conditionId);
    request.setDescription(description);
    request.setName(title);
    request.setCategory(category);
    request.setInternalName(internalName);
    request.setConditionSatisfactionResponsiblePartyType(partyType);
    request.setConditionSatisfactionTimeframeType(timeframeType);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    return sendMessage<UpsertLenderConditionTemplateCommandResponse.AsObject>(msg, UpsertLenderConditionTemplateCommandResponse);
  };

  return useMutation<UpsertLenderConditionTemplateCommandResponse.AsObject, Error, UpdateObject>(updateLenderConditionCommand);
};

export { useUpsertLenderCondition };
