import { useMutation } from '@tanstack/react-query';

import type { ConsentField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { ConsentConfig, DealWriteCommandResponse, SaveToggleConsentCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest';

interface SaveConsentsDTO {
  dealId: string;
  dealVersion: number;
  scopeToken: string;
  consentFieldsList: ConsentField[];
  bpdId: string;
  pageId: string;
  consentSource: SaveToggleConsentCommandRequest.ToggleConsentSourceEnum;
}

const useSaveToggleConsent = () => {
  const sendMessage = useWilqoMessage();

  const saveRequest = async (consentsDto: SaveConsentsDTO) => {
    const request = new SaveToggleConsentCommandRequest();
    const consentConfig = new ConsentConfig();

    consentConfig.setConsentFieldList(consentsDto.consentFieldsList);

    request.setDealId(consentsDto.dealId);
    request.setDealVersion(consentsDto.dealVersion);
    request.setBusinessProcessDomainId(consentsDto.bpdId);
    request.setPageId(consentsDto.pageId);
    request.setConsents(consentConfig);
    request.setScopeToken(consentsDto.scopeToken);
    request.setToggleConsentSource(consentsDto.consentSource);

    return sendMessage<DealWriteCommandResponse.AsObject>({ msg: request, msgName: MESSAGE_NAME }, DealWriteCommandResponse);
  };

  return useMutation(saveRequest);
};

export { useSaveToggleConsent };
