// source: Wilqo.Shared.Security/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.wilqo.security.LegacyRole', null, global);
goog.exportSymbol('proto.wilqo.security.SecurityContext', null, global);
goog.exportSymbol('proto.wilqo.security.SecuritySession', null, global);
goog.exportSymbol('proto.wilqo.security.TenancyAuthorization', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.security.TenancyAuthorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.security.TenancyAuthorization.repeatedFields_, null);
};
goog.inherits(proto.wilqo.security.TenancyAuthorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.security.TenancyAuthorization.displayName = 'proto.wilqo.security.TenancyAuthorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.security.SecurityContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.security.SecurityContext.repeatedFields_, null);
};
goog.inherits(proto.wilqo.security.SecurityContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.security.SecurityContext.displayName = 'proto.wilqo.security.SecurityContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.security.SecuritySession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.security.SecuritySession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.security.SecuritySession.displayName = 'proto.wilqo.security.SecuritySession';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.security.TenancyAuthorization.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.security.TenancyAuthorization.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.security.TenancyAuthorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.security.TenancyAuthorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.TenancyAuthorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    authorizedBusinessProcessDomainIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.security.TenancyAuthorization}
 */
proto.wilqo.security.TenancyAuthorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.security.TenancyAuthorization;
  return proto.wilqo.security.TenancyAuthorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.security.TenancyAuthorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.security.TenancyAuthorization}
 */
proto.wilqo.security.TenancyAuthorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthorizedBusinessProcessDomainIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.security.TenancyAuthorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.security.TenancyAuthorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.security.TenancyAuthorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.TenancyAuthorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuthorizedBusinessProcessDomainIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.security.TenancyAuthorization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string short_name = 2;
 * @return {string}
 */
proto.wilqo.security.TenancyAuthorization.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.security.TenancyAuthorization.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string authorized_business_process_domain_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.security.TenancyAuthorization.prototype.getAuthorizedBusinessProcessDomainIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.setAuthorizedBusinessProcessDomainIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.addAuthorizedBusinessProcessDomainIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.security.TenancyAuthorization} returns this
 */
proto.wilqo.security.TenancyAuthorization.prototype.clearAuthorizedBusinessProcessDomainIdsList = function() {
  return this.setAuthorizedBusinessProcessDomainIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.security.SecurityContext.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.security.SecurityContext.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.security.SecurityContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.security.SecurityContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.SecurityContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleMask: msg.getRoleMask_asB64(),
    tenancyAuthorization: (f = msg.getTenancyAuthorization()) && proto.wilqo.security.TenancyAuthorization.toObject(includeInstance, f),
    clientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.security.SecurityContext}
 */
proto.wilqo.security.SecurityContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.security.SecurityContext;
  return proto.wilqo.security.SecurityContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.security.SecurityContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.security.SecurityContext}
 */
proto.wilqo.security.SecurityContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRoleMask(value);
      break;
    case 3:
      var value = new proto.wilqo.security.TenancyAuthorization;
      reader.readMessage(value,proto.wilqo.security.TenancyAuthorization.deserializeBinaryFromReader);
      msg.setTenancyAuthorization(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.security.SecurityContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.security.SecurityContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.security.SecurityContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.SecurityContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleMask_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getTenancyAuthorization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.security.TenancyAuthorization.serializeBinaryToWriter
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.wilqo.security.SecurityContext.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes role_mask = 2;
 * @return {string}
 */
proto.wilqo.security.SecurityContext.prototype.getRoleMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes role_mask = 2;
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {string}
 */
proto.wilqo.security.SecurityContext.prototype.getRoleMask_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRoleMask()));
};


/**
 * optional bytes role_mask = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {!Uint8Array}
 */
proto.wilqo.security.SecurityContext.prototype.getRoleMask_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRoleMask()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.setRoleMask = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional TenancyAuthorization tenancy_authorization = 3;
 * @return {?proto.wilqo.security.TenancyAuthorization}
 */
proto.wilqo.security.SecurityContext.prototype.getTenancyAuthorization = function() {
  return /** @type{?proto.wilqo.security.TenancyAuthorization} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.security.TenancyAuthorization, 3));
};


/**
 * @param {?proto.wilqo.security.TenancyAuthorization|undefined} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
*/
proto.wilqo.security.SecurityContext.prototype.setTenancyAuthorization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.clearTenancyAuthorization = function() {
  return this.setTenancyAuthorization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.security.SecurityContext.prototype.hasTenancyAuthorization = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string client_id = 4;
 * @return {string}
 */
proto.wilqo.security.SecurityContext.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.wilqo.security.SecurityContext.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string roles = 6;
 * @return {!Array<string>}
 */
proto.wilqo.security.SecurityContext.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.security.SecurityContext} returns this
 */
proto.wilqo.security.SecurityContext.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.security.SecuritySession.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.security.SecuritySession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.security.SecuritySession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.SecuritySession.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    securityContext: (f = msg.getSecurityContext()) && proto.wilqo.security.SecurityContext.toObject(includeInstance, f),
    accessTokenExpiration: (f = msg.getAccessTokenExpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    refreshTokenExpiration: (f = msg.getRefreshTokenExpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.security.SecuritySession}
 */
proto.wilqo.security.SecuritySession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.security.SecuritySession;
  return proto.wilqo.security.SecuritySession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.security.SecuritySession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.security.SecuritySession}
 */
proto.wilqo.security.SecuritySession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    case 5:
      var value = new proto.wilqo.security.SecurityContext;
      reader.readMessage(value,proto.wilqo.security.SecurityContext.deserializeBinaryFromReader);
      msg.setSecurityContext(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccessTokenExpiration(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRefreshTokenExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.security.SecuritySession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.security.SecuritySession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.security.SecuritySession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.security.SecuritySession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSecurityContext();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.security.SecurityContext.serializeBinaryToWriter
    );
  }
  f = message.getAccessTokenExpiration();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRefreshTokenExpiration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.wilqo.security.SecuritySession.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.wilqo.security.SecuritySession.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string refresh_token = 4;
 * @return {string}
 */
proto.wilqo.security.SecuritySession.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional SecurityContext security_context = 5;
 * @return {?proto.wilqo.security.SecurityContext}
 */
proto.wilqo.security.SecuritySession.prototype.getSecurityContext = function() {
  return /** @type{?proto.wilqo.security.SecurityContext} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.security.SecurityContext, 5));
};


/**
 * @param {?proto.wilqo.security.SecurityContext|undefined} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
*/
proto.wilqo.security.SecuritySession.prototype.setSecurityContext = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.clearSecurityContext = function() {
  return this.setSecurityContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.security.SecuritySession.prototype.hasSecurityContext = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp access_token_expiration = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.security.SecuritySession.prototype.getAccessTokenExpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
*/
proto.wilqo.security.SecuritySession.prototype.setAccessTokenExpiration = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.clearAccessTokenExpiration = function() {
  return this.setAccessTokenExpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.security.SecuritySession.prototype.hasAccessTokenExpiration = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp refresh_token_expiration = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.security.SecuritySession.prototype.getRefreshTokenExpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.security.SecuritySession} returns this
*/
proto.wilqo.security.SecuritySession.prototype.setRefreshTokenExpiration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.security.SecuritySession} returns this
 */
proto.wilqo.security.SecuritySession.prototype.clearRefreshTokenExpiration = function() {
  return this.setRefreshTokenExpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.security.SecuritySession.prototype.hasRefreshTokenExpiration = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.security.LegacyRole = {
  LEGACY_ROLE_UNKNOWN: 0,
  LEGACY_ROLE_CONSUMER: 1,
  LEGACY_ROLE_LOAN_LIST_READER: 2,
  LEGACY_ROLE_LOAN_LIST_CREATE_READ: 3,
  LEGACY_ROLE_ACTIVITY_LIST_READER: 4,
  LEGACY_ROLE_ADMIN: 5
};

goog.object.extend(exports, proto.wilqo.security);
