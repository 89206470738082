// source: Wilqo.Shared.Models/BrandAdminModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_ActivityAdminModels_pb = require('../Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
goog.exportSymbol('proto.wilqo.shared.models.Action', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ActionTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Card', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DealSummaryConfigurationTemplate', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DealSummaryLink', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Document', null, global);
goog.exportSymbol('proto.wilqo.shared.models.InfoCardTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.LoanSidesheetConfiguration', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Page', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Section', null, global);
goog.exportSymbol('proto.wilqo.shared.models.SectionField', null, global);
goog.exportSymbol('proto.wilqo.shared.models.SectionTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.SidesheetSection', null, global);
goog.exportSymbol('proto.wilqo.shared.models.SidesheetSectionIconProperties', null, global);
goog.exportSymbol('proto.wilqo.shared.models.SidesheetSectionItem', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Tab', null, global);
goog.exportSymbol('proto.wilqo.shared.models.WidgetTypeEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DealSummaryConfigurationTemplate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DealSummaryConfigurationTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DealSummaryConfigurationTemplate.displayName = 'proto.wilqo.shared.models.DealSummaryConfigurationTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Action.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Action.displayName = 'proto.wilqo.shared.models.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Page.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Page.displayName = 'proto.wilqo.shared.models.Page';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Card.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Card.displayName = 'proto.wilqo.shared.models.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Tab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Tab.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Tab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Tab.displayName = 'proto.wilqo.shared.models.Tab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Section = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Section.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Section, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Section.displayName = 'proto.wilqo.shared.models.Section';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.SectionField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.SectionField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.SectionField.displayName = 'proto.wilqo.shared.models.SectionField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Document.displayName = 'proto.wilqo.shared.models.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.LoanSidesheetConfiguration.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.LoanSidesheetConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.LoanSidesheetConfiguration.displayName = 'proto.wilqo.shared.models.LoanSidesheetConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.SidesheetSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.SidesheetSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.SidesheetSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.SidesheetSection.displayName = 'proto.wilqo.shared.models.SidesheetSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.SidesheetSectionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.SidesheetSectionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.SidesheetSectionItem.displayName = 'proto.wilqo.shared.models.SidesheetSectionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.SidesheetSectionIconProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.SidesheetSectionIconProperties.displayName = 'proto.wilqo.shared.models.SidesheetSectionIconProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DealSummaryLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DealSummaryLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DealSummaryLink.displayName = 'proto.wilqo.shared.models.DealSummaryLink';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DealSummaryConfigurationTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishedEntityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.wilqo.shared.models.Page.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DealSummaryConfigurationTemplate;
  return proto.wilqo.shared.models.DealSummaryConfigurationTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishedEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.Page;
      reader.readMessage(value,proto.wilqo.shared.models.Page.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DealSummaryConfigurationTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishedEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.shared.models.Page.serializeBinaryToWriter
    );
  }
};


/**
 * optional string published_entity_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.getPublishedEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} returns this
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.setPublishedEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} returns this
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} returns this
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Page pages = 5;
 * @return {!Array<!proto.wilqo.shared.models.Page>}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Page>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Page, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Page>} value
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} returns this
*/
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.Page=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Page}
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.Page, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DealSummaryConfigurationTemplate} returns this
 */
proto.wilqo.shared.models.DealSummaryConfigurationTemplate.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Action.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Action}
 */
proto.wilqo.shared.models.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Action;
  return proto.wilqo.shared.models.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Action}
 */
proto.wilqo.shared.models.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.ActionTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Action.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Action.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActionTypeEnum type = 3;
 * @return {!proto.wilqo.shared.models.ActionTypeEnum}
 */
proto.wilqo.shared.models.Action.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.ActionTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.ActionTypeEnum} value
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.wilqo.shared.models.Action.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string roles = 5;
 * @return {!Array<string>}
 */
proto.wilqo.shared.models.Action.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Action} returns this
 */
proto.wilqo.shared.models.Action.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Page.repeatedFields_ = [3,9,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Page.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    labelVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tabsList: jspb.Message.toObjectList(msg.getTabsList(),
    proto.wilqo.shared.models.Tab.toObject, includeInstance),
    hideUnknowns: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    defaultColumnSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
    scopesList: jspb.Message.toObjectList(msg.getScopesList(),
    Wilqo_Shared_Models_DynamicData_pb.ScopeValue.toObject, includeInstance),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.wilqo.shared.models.Section.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.wilqo.shared.models.Action.toObject, includeInstance),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.wilqo.shared.models.Card.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Page}
 */
proto.wilqo.shared.models.Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Page;
  return proto.wilqo.shared.models.Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Page}
 */
proto.wilqo.shared.models.Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelVirtualFieldId(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.Tab;
      reader.readMessage(value,proto.wilqo.shared.models.Tab.deserializeBinaryFromReader);
      msg.addTabs(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideUnknowns(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDefaultColumnSize(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_DynamicData_pb.ScopeValue;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.ScopeValue.deserializeBinaryFromReader);
      msg.addScopes(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.Section;
      reader.readMessage(value,proto.wilqo.shared.models.Section.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    case 7:
      var value = new proto.wilqo.shared.models.Action;
      reader.readMessage(value,proto.wilqo.shared.models.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 8:
      var value = new proto.wilqo.shared.models.Card;
      reader.readMessage(value,proto.wilqo.shared.models.Card.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabelVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTabsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.Tab.serializeBinaryToWriter
    );
  }
  f = message.getHideUnknowns();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDefaultColumnSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_Shared_Models_DynamicData_pb.ScopeValue.serializeBinaryToWriter
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.shared.models.Section.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.shared.models.Action.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wilqo.shared.models.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Page.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Page.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label_virtual_field_id = 10;
 * @return {string}
 */
proto.wilqo.shared.models.Page.prototype.getLabelVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.setLabelVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Tab tabs = 3;
 * @return {!Array<!proto.wilqo.shared.models.Tab>}
 */
proto.wilqo.shared.models.Page.prototype.getTabsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Tab>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Tab, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Tab>} value
 * @return {!proto.wilqo.shared.models.Page} returns this
*/
proto.wilqo.shared.models.Page.prototype.setTabsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.Tab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Tab}
 */
proto.wilqo.shared.models.Page.prototype.addTabs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.Tab, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.clearTabsList = function() {
  return this.setTabsList([]);
};


/**
 * optional bool hide_unknowns = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.Page.prototype.getHideUnknowns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.setHideUnknowns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 default_column_size = 5;
 * @return {number}
 */
proto.wilqo.shared.models.Page.prototype.getDefaultColumnSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.setDefaultColumnSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated ScopeValue scopes = 9;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.shared.models.Page.prototype.getScopesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.ScopeValue, 9));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.shared.models.Page} returns this
*/
proto.wilqo.shared.models.Page.prototype.setScopesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.Page.prototype.addScopes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * repeated Section sections = 6;
 * @return {!Array<!proto.wilqo.shared.models.Section>}
 */
proto.wilqo.shared.models.Page.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Section>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Section, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Section>} value
 * @return {!proto.wilqo.shared.models.Page} returns this
*/
proto.wilqo.shared.models.Page.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.models.Section=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Section}
 */
proto.wilqo.shared.models.Page.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.models.Section, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};


/**
 * repeated Action actions = 7;
 * @return {!Array<!proto.wilqo.shared.models.Action>}
 */
proto.wilqo.shared.models.Page.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Action, 7));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Action>} value
 * @return {!proto.wilqo.shared.models.Page} returns this
*/
proto.wilqo.shared.models.Page.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.shared.models.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Action}
 */
proto.wilqo.shared.models.Page.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.shared.models.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * repeated Card cards = 8;
 * @return {!Array<!proto.wilqo.shared.models.Card>}
 */
proto.wilqo.shared.models.Page.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Card, 8));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Card>} value
 * @return {!proto.wilqo.shared.models.Page} returns this
*/
proto.wilqo.shared.models.Page.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.shared.models.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Card}
 */
proto.wilqo.shared.models.Page.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.shared.models.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Page} returns this
 */
proto.wilqo.shared.models.Page.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Card.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mainField: (f = msg.getMainField()) && proto.wilqo.shared.models.SectionField.toObject(includeInstance, f),
    secondaryFieldsList: jspb.Message.toObjectList(msg.getSecondaryFieldsList(),
    proto.wilqo.shared.models.SectionField.toObject, includeInstance),
    variant: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Card}
 */
proto.wilqo.shared.models.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Card;
  return proto.wilqo.shared.models.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Card}
 */
proto.wilqo.shared.models.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.SectionField;
      reader.readMessage(value,proto.wilqo.shared.models.SectionField.deserializeBinaryFromReader);
      msg.setMainField(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.SectionField;
      reader.readMessage(value,proto.wilqo.shared.models.SectionField.deserializeBinaryFromReader);
      msg.addSecondaryFields(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMainField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.SectionField.serializeBinaryToWriter
    );
  }
  f = message.getSecondaryFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.SectionField.serializeBinaryToWriter
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Card.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Card} returns this
 */
proto.wilqo.shared.models.Card.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SectionField main_field = 2;
 * @return {?proto.wilqo.shared.models.SectionField}
 */
proto.wilqo.shared.models.Card.prototype.getMainField = function() {
  return /** @type{?proto.wilqo.shared.models.SectionField} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.SectionField, 2));
};


/**
 * @param {?proto.wilqo.shared.models.SectionField|undefined} value
 * @return {!proto.wilqo.shared.models.Card} returns this
*/
proto.wilqo.shared.models.Card.prototype.setMainField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.Card} returns this
 */
proto.wilqo.shared.models.Card.prototype.clearMainField = function() {
  return this.setMainField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.Card.prototype.hasMainField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SectionField secondary_fields = 3;
 * @return {!Array<!proto.wilqo.shared.models.SectionField>}
 */
proto.wilqo.shared.models.Card.prototype.getSecondaryFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.SectionField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.SectionField, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.SectionField>} value
 * @return {!proto.wilqo.shared.models.Card} returns this
*/
proto.wilqo.shared.models.Card.prototype.setSecondaryFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.SectionField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.SectionField}
 */
proto.wilqo.shared.models.Card.prototype.addSecondaryFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.SectionField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Card} returns this
 */
proto.wilqo.shared.models.Card.prototype.clearSecondaryFieldsList = function() {
  return this.setSecondaryFieldsList([]);
};


/**
 * optional InfoCardTypeEnum variant = 4;
 * @return {!proto.wilqo.shared.models.InfoCardTypeEnum}
 */
proto.wilqo.shared.models.Card.prototype.getVariant = function() {
  return /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.InfoCardTypeEnum} value
 * @return {!proto.wilqo.shared.models.Card} returns this
 */
proto.wilqo.shared.models.Card.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Tab.repeatedFields_ = [6,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Tab.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Tab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Tab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Tab.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scopesList: jspb.Message.toObjectList(msg.getScopesList(),
    Wilqo_Shared_Models_DynamicData_pb.ScopeValue.toObject, includeInstance),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.wilqo.shared.models.Section.toObject, includeInstance),
    widgetType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Tab}
 */
proto.wilqo.shared.models.Tab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Tab;
  return proto.wilqo.shared.models.Tab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Tab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Tab}
 */
proto.wilqo.shared.models.Tab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_DynamicData_pb.ScopeValue;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.ScopeValue.deserializeBinaryFromReader);
      msg.addScopes(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.Section;
      reader.readMessage(value,proto.wilqo.shared.models.Section.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.models.WidgetTypeEnum} */ (reader.readEnum());
      msg.setWidgetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Tab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Tab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Tab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Tab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_Shared_Models_DynamicData_pb.ScopeValue.serializeBinaryToWriter
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.Section.serializeBinaryToWriter
    );
  }
  f = message.getWidgetType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Tab.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Tab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.shared.models.Tab.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ScopeValue scopes = 6;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.shared.models.Tab.prototype.getScopesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.ScopeValue, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
*/
proto.wilqo.shared.models.Tab.prototype.setScopesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.Tab.prototype.addScopes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * repeated Section sections = 4;
 * @return {!Array<!proto.wilqo.shared.models.Section>}
 */
proto.wilqo.shared.models.Tab.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Section>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Section, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Section>} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
*/
proto.wilqo.shared.models.Tab.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.Section=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Section}
 */
proto.wilqo.shared.models.Tab.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.Section, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};


/**
 * optional WidgetTypeEnum widget_type = 5;
 * @return {!proto.wilqo.shared.models.WidgetTypeEnum}
 */
proto.wilqo.shared.models.Tab.prototype.getWidgetType = function() {
  return /** @type {!proto.wilqo.shared.models.WidgetTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WidgetTypeEnum} value
 * @return {!proto.wilqo.shared.models.Tab} returns this
 */
proto.wilqo.shared.models.Tab.prototype.setWidgetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Section.repeatedFields_ = [8,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Section.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Section.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Section} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Section.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    scopesList: jspb.Message.toObjectList(msg.getScopesList(),
    Wilqo_Shared_Models_DynamicData_pb.ScopeValue.toObject, includeInstance),
    fieldListList: jspb.Message.toObjectList(msg.getFieldListList(),
    proto.wilqo.shared.models.SectionField.toObject, includeInstance),
    documentListList: jspb.Message.toObjectList(msg.getDocumentListList(),
    proto.wilqo.shared.models.Document.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Section}
 */
proto.wilqo.shared.models.Section.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Section;
  return proto.wilqo.shared.models.Section.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Section} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Section}
 */
proto.wilqo.shared.models.Section.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.SectionTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_DynamicData_pb.ScopeValue;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.ScopeValue.deserializeBinaryFromReader);
      msg.addScopes(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.SectionField;
      reader.readMessage(value,proto.wilqo.shared.models.SectionField.deserializeBinaryFromReader);
      msg.addFieldList(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.Document;
      reader.readMessage(value,proto.wilqo.shared.models.Document.deserializeBinaryFromReader);
      msg.addDocumentList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Section.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Section.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Section} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Section.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Wilqo_Shared_Models_DynamicData_pb.ScopeValue.serializeBinaryToWriter
    );
  }
  f = message.getFieldListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.SectionField.serializeBinaryToWriter
    );
  }
  f = message.getDocumentListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.shared.models.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.wilqo.shared.models.Section.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Section.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.wilqo.shared.models.Section.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SectionTypeEnum type = 3;
 * @return {!proto.wilqo.shared.models.SectionTypeEnum}
 */
proto.wilqo.shared.models.Section.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.SectionTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.SectionTypeEnum} value
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool editable = 7;
 * @return {boolean}
 */
proto.wilqo.shared.models.Section.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated ScopeValue scopes = 8;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.shared.models.Section.prototype.getScopesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.ScopeValue, 8));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.shared.models.Section} returns this
*/
proto.wilqo.shared.models.Section.prototype.setScopesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.Section.prototype.addScopes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * repeated SectionField field_list = 4;
 * @return {!Array<!proto.wilqo.shared.models.SectionField>}
 */
proto.wilqo.shared.models.Section.prototype.getFieldListList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.SectionField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.SectionField, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.SectionField>} value
 * @return {!proto.wilqo.shared.models.Section} returns this
*/
proto.wilqo.shared.models.Section.prototype.setFieldListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.SectionField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.SectionField}
 */
proto.wilqo.shared.models.Section.prototype.addFieldList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.SectionField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.clearFieldListList = function() {
  return this.setFieldListList([]);
};


/**
 * repeated Document document_list = 6;
 * @return {!Array<!proto.wilqo.shared.models.Document>}
 */
proto.wilqo.shared.models.Section.prototype.getDocumentListList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.Document, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Document>} value
 * @return {!proto.wilqo.shared.models.Section} returns this
*/
proto.wilqo.shared.models.Section.prototype.setDocumentListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.models.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Document}
 */
proto.wilqo.shared.models.Section.prototype.addDocumentList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.models.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Section} returns this
 */
proto.wilqo.shared.models.Section.prototype.clearDocumentListList = function() {
  return this.setDocumentListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.SectionField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.SectionField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.SectionField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SectionField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    value: jspb.Message.getFieldWithDefault(msg, 4, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    leadingIconProperties: (f = msg.getLeadingIconProperties()) && proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject(includeInstance, f),
    trailingIconProperties: (f = msg.getTrailingIconProperties()) && proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject(includeInstance, f),
    dealSummaryLink: (f = msg.getDealSummaryLink()) && proto.wilqo.shared.models.DealSummaryLink.toObject(includeInstance, f),
    externalLink: jspb.Message.getFieldWithDefault(msg, 9, ""),
    columnSize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    template: (f = msg.getTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f),
    dynamicValue: (f = msg.getDynamicValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.SectionField}
 */
proto.wilqo.shared.models.SectionField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.SectionField;
  return proto.wilqo.shared.models.SectionField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.SectionField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.SectionField}
 */
proto.wilqo.shared.models.SectionField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.SidesheetSectionIconProperties;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader);
      msg.setLeadingIconProperties(value);
      break;
    case 7:
      var value = new proto.wilqo.shared.models.SidesheetSectionIconProperties;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader);
      msg.setTrailingIconProperties(value);
      break;
    case 8:
      var value = new proto.wilqo.shared.models.DealSummaryLink;
      reader.readMessage(value,proto.wilqo.shared.models.DealSummaryLink.deserializeBinaryFromReader);
      msg.setDealSummaryLink(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalLink(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColumnSize(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setDynamicValue(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.SectionField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.SectionField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.SectionField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SectionField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLeadingIconProperties();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getTrailingIconProperties();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getDealSummaryLink();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.shared.models.DealSummaryLink.serializeBinaryToWriter
    );
  }
  f = message.getExternalLink();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getColumnSize();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
  f = message.getDynamicValue();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.SectionField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.wilqo.shared.models.SectionField.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.shared.models.SectionField.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.wilqo.shared.models.SectionField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string leading_text = 5;
 * @return {string}
 */
proto.wilqo.shared.models.SectionField.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SidesheetSectionIconProperties leading_icon_properties = 6;
 * @return {?proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SectionField.prototype.getLeadingIconProperties = function() {
  return /** @type{?proto.wilqo.shared.models.SidesheetSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.SidesheetSectionIconProperties, 6));
};


/**
 * @param {?proto.wilqo.shared.models.SidesheetSectionIconProperties|undefined} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
*/
proto.wilqo.shared.models.SectionField.prototype.setLeadingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.clearLeadingIconProperties = function() {
  return this.setLeadingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.hasLeadingIconProperties = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SidesheetSectionIconProperties trailing_icon_properties = 7;
 * @return {?proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SectionField.prototype.getTrailingIconProperties = function() {
  return /** @type{?proto.wilqo.shared.models.SidesheetSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.SidesheetSectionIconProperties, 7));
};


/**
 * @param {?proto.wilqo.shared.models.SidesheetSectionIconProperties|undefined} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
*/
proto.wilqo.shared.models.SectionField.prototype.setTrailingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.clearTrailingIconProperties = function() {
  return this.setTrailingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.hasTrailingIconProperties = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DealSummaryLink deal_summary_link = 8;
 * @return {?proto.wilqo.shared.models.DealSummaryLink}
 */
proto.wilqo.shared.models.SectionField.prototype.getDealSummaryLink = function() {
  return /** @type{?proto.wilqo.shared.models.DealSummaryLink} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DealSummaryLink, 8));
};


/**
 * @param {?proto.wilqo.shared.models.DealSummaryLink|undefined} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
*/
proto.wilqo.shared.models.SectionField.prototype.setDealSummaryLink = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.clearDealSummaryLink = function() {
  return this.setDealSummaryLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.hasDealSummaryLink = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string external_link = 9;
 * @return {string}
 */
proto.wilqo.shared.models.SectionField.prototype.getExternalLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setExternalLink = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 column_size = 10;
 * @return {number}
 */
proto.wilqo.shared.models.SectionField.prototype.getColumnSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setColumnSize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional DataFieldTemplate template = 11;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.shared.models.SectionField.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 11));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
*/
proto.wilqo.shared.models.SectionField.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DynamicDataElement dynamic_value = 12;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.SectionField.prototype.getDynamicValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 12));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
*/
proto.wilqo.shared.models.SectionField.prototype.setDynamicValue = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.clearDynamicValue = function() {
  return this.setDynamicValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.hasDynamicValue = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool readonly = 13;
 * @return {boolean}
 */
proto.wilqo.shared.models.SectionField.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.SectionField} returns this
 */
proto.wilqo.shared.models.SectionField.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Document}
 */
proto.wilqo.shared.models.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Document;
  return proto.wilqo.shared.models.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Document}
 */
proto.wilqo.shared.models.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Document.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Document} returns this
 */
proto.wilqo.shared.models.Document.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Document.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Document} returns this
 */
proto.wilqo.shared.models.Document.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.LoanSidesheetConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.LoanSidesheetConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    publishedEntityId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.wilqo.shared.models.SidesheetSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.LoanSidesheetConfiguration;
  return proto.wilqo.shared.models.LoanSidesheetConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.LoanSidesheetConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishedEntityId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.SidesheetSection;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.LoanSidesheetConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.LoanSidesheetConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublishedEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.SidesheetSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string published_entity_id = 5;
 * @return {string}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.getPublishedEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.setPublishedEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SidesheetSection sections = 3;
 * @return {!Array<!proto.wilqo.shared.models.SidesheetSection>}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.SidesheetSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.SidesheetSection, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.SidesheetSection>} value
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
*/
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.SidesheetSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.SidesheetSection}
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.SidesheetSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.LoanSidesheetConfiguration} returns this
 */
proto.wilqo.shared.models.LoanSidesheetConfiguration.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.SidesheetSection.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.SidesheetSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.SidesheetSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.SidesheetSectionItem.toObject, includeInstance),
    hideUnknowns: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.SidesheetSection}
 */
proto.wilqo.shared.models.SidesheetSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.SidesheetSection;
  return proto.wilqo.shared.models.SidesheetSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.SidesheetSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.SidesheetSection}
 */
proto.wilqo.shared.models.SidesheetSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.SidesheetSectionItem;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSectionItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideUnknowns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.SidesheetSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.SidesheetSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.SidesheetSectionItem.serializeBinaryToWriter
    );
  }
  f = message.getHideUnknowns();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
 */
proto.wilqo.shared.models.SidesheetSection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
 */
proto.wilqo.shared.models.SidesheetSection.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
 */
proto.wilqo.shared.models.SidesheetSection.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated SidesheetSectionItem items = 4;
 * @return {!Array<!proto.wilqo.shared.models.SidesheetSectionItem>}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.SidesheetSectionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.SidesheetSectionItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.SidesheetSectionItem>} value
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
*/
proto.wilqo.shared.models.SidesheetSection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.SidesheetSectionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.SidesheetSectionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
 */
proto.wilqo.shared.models.SidesheetSection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool hide_unknowns = 5;
 * @return {boolean}
 */
proto.wilqo.shared.models.SidesheetSection.prototype.getHideUnknowns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.SidesheetSection} returns this
 */
proto.wilqo.shared.models.SidesheetSection.prototype.setHideUnknowns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.SidesheetSectionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.SidesheetSectionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSectionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 8, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fieldPath: jspb.Message.getFieldWithDefault(msg, 7, ""),
    leadingIconProperties: (f = msg.getLeadingIconProperties()) && proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject(includeInstance, f),
    trailingIconProperties: (f = msg.getTrailingIconProperties()) && proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject(includeInstance, f),
    dealSummaryLink: (f = msg.getDealSummaryLink()) && proto.wilqo.shared.models.DealSummaryLink.toObject(includeInstance, f),
    externalLink: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem}
 */
proto.wilqo.shared.models.SidesheetSectionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.SidesheetSectionItem;
  return proto.wilqo.shared.models.SidesheetSectionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.SidesheetSectionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem}
 */
proto.wilqo.shared.models.SidesheetSectionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldPath(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.SidesheetSectionIconProperties;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader);
      msg.setLeadingIconProperties(value);
      break;
    case 11:
      var value = new proto.wilqo.shared.models.SidesheetSectionIconProperties;
      reader.readMessage(value,proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader);
      msg.setTrailingIconProperties(value);
      break;
    case 9:
      var value = new proto.wilqo.shared.models.DealSummaryLink;
      reader.readMessage(value,proto.wilqo.shared.models.DealSummaryLink.deserializeBinaryFromReader);
      msg.setDealSummaryLink(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.SidesheetSectionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.SidesheetSectionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSectionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFieldPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLeadingIconProperties();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getTrailingIconProperties();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getDealSummaryLink();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.shared.models.DealSummaryLink.serializeBinaryToWriter
    );
  }
  f = message.getExternalLink();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string caption = 8;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string leading_text = 4;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string field_path = 7;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getFieldPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setFieldPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SidesheetSectionIconProperties leading_icon_properties = 6;
 * @return {?proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getLeadingIconProperties = function() {
  return /** @type{?proto.wilqo.shared.models.SidesheetSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.SidesheetSectionIconProperties, 6));
};


/**
 * @param {?proto.wilqo.shared.models.SidesheetSectionIconProperties|undefined} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
*/
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setLeadingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.clearLeadingIconProperties = function() {
  return this.setLeadingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.hasLeadingIconProperties = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SidesheetSectionIconProperties trailing_icon_properties = 11;
 * @return {?proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getTrailingIconProperties = function() {
  return /** @type{?proto.wilqo.shared.models.SidesheetSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.SidesheetSectionIconProperties, 11));
};


/**
 * @param {?proto.wilqo.shared.models.SidesheetSectionIconProperties|undefined} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
*/
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setTrailingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.clearTrailingIconProperties = function() {
  return this.setTrailingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.hasTrailingIconProperties = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DealSummaryLink deal_summary_link = 9;
 * @return {?proto.wilqo.shared.models.DealSummaryLink}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getDealSummaryLink = function() {
  return /** @type{?proto.wilqo.shared.models.DealSummaryLink} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DealSummaryLink, 9));
};


/**
 * @param {?proto.wilqo.shared.models.DealSummaryLink|undefined} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
*/
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setDealSummaryLink = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.clearDealSummaryLink = function() {
  return this.setDealSummaryLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.hasDealSummaryLink = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string external_link = 10;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.getExternalLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionItem} returns this
 */
proto.wilqo.shared.models.SidesheetSectionItem.prototype.setExternalLink = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.SidesheetSectionIconProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.SidesheetSectionIconProperties;
  return proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.SidesheetSectionIconProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.SidesheetSectionIconProperties}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.SidesheetSectionIconProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionIconProperties} returns this
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionIconProperties} returns this
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variant = 3;
 * @return {string}
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.SidesheetSectionIconProperties} returns this
 */
proto.wilqo.shared.models.SidesheetSectionIconProperties.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DealSummaryLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DealSummaryLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DealSummaryLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tabId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DealSummaryLink}
 */
proto.wilqo.shared.models.DealSummaryLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DealSummaryLink;
  return proto.wilqo.shared.models.DealSummaryLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DealSummaryLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DealSummaryLink}
 */
proto.wilqo.shared.models.DealSummaryLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DealSummaryLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DealSummaryLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DealSummaryLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTabId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryLink} returns this
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tab_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.getTabId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryLink} returns this
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.setTabId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link_to = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryLink} returns this
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DealSummaryLink} returns this
 */
proto.wilqo.shared.models.DealSummaryLink.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.ActionTypeEnum = {
  ACTION_TYPE_ENUM_UNKNOWN: 0,
  ACTION_TYPE_ENUM_POPUP: 1,
  ACTION_TYPE_ENUM_INTEGRATION: 2,
  ACTION_TYPE_ENUM_EXPANDER: 3,
  ACTION_TYPE_ENUM_PAGE_EDIT: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.WidgetTypeEnum = {
  WIDGET_TYPE_ENUM_UNKNOWN: 0,
  WIDGET_TYPE_ENUM_AUS_INTEGRATION_RESULTS: 1
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.InfoCardTypeEnum = {
  INFO_CARD_TYPE_ENUM_DEFAULT: 0,
  INFO_CARD_TYPE_ENUM_DANGER: 1,
  INFO_CARD_TYPE_ENUM_WARNING: 2,
  INFO_CARD_TYPE_ENUM_SUCCESS: 3,
  INFO_CARD_TYPE_ENUM_NEUTRAL: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.SectionTypeEnum = {
  SECTION_TYPE_ENUM_FIELD_LIST: 0,
  SECTION_TYPE_ENUM_DOCUMENT_TABLE: 1
};

goog.object.extend(exports, proto.wilqo.shared.models);
