import { Close, Title } from '@radix-ui/react-dialog';
import type { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@/Utils/Helpers/uiBuilders';

import { Icon } from '../Icon';
import { Typography } from '../Typography';

export const DialogHeader = (
  props: HTMLAttributes<HTMLElement> & {
    icon?: string;
    iconAction?: VoidFunction;
    title?: string;
    actions?: ReactNode;
    subHeader?: ReactNode;
    logo?: ReactNode;
  },
) => {
  const {
    actions,
    icon,
    iconAction,
    logo,
    subHeader,
    title,
    ...rest
  } = props;

  return (
    <header
      {...rest}
      className={
        cn('col-start-1 col-span-3 border-b border-b-on-surface-stroke flex flex-col justify-center', rest.className)
      }
    >
      <div className="p-2 tablet:p-4 tablet:min-h-[72px] flex items-center gap-2 md:gap-8">
        {iconAction ? (
          <Icon aria-label="Close" icon={icon ?? 'Clear'} onClick={iconAction} variant="interactive" />
        ) : (
          <Close aria-label="Close" asChild>
            <Icon icon={icon ?? 'Clear'} variant="interactive" />
          </Close>
        )}

        {Boolean(title) && (
        <Title asChild>
          <Typography className="break-keep whitespace-nowrap" variant="display6">{title}</Typography>
        </Title>
        )}

        {Boolean(logo) && (
        <div>
          {logo}
        </div>
        )}

        <div className="contents w-full h-full [&:has([data-component='button'])_[data-component='button']:first-of-type]:ml-auto">
          {actions}
        </div>
      </div>
      {subHeader}
    </header>
  );
};
