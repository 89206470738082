import { useState } from 'react';

import type { IGeoCodeData } from '@/Utils/Helpers/geoCodeConversion';

import { AutoComplete } from '../AutoComplete';
import { Button } from '../Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '../RadixDialog';
import states from '../StateSelect/states';
import { TextField } from '../TextField';
import type { SelectedValue } from './AddressAutoComplete.component';

interface ManualAddressDialogProps {
  open: boolean;
  close: () => void;
  handleSubmit: (values: SelectedValue) => void;
}

interface ManualAddressValues {
  city: string;
  county: string;
  lineOne: string;
  lineTwo: string;
  state: { abbreviation: string; name: string } | null;
  zip: string;
}

const initialValue = {
  city: '',
  county: '',
  lineOne: '',
  lineTwo: '',
  state: null,
  zip: '',
};

export const ManualAddressDialog = (props: ManualAddressDialogProps) => {
  const { close, handleSubmit, open } = props;

  const [values, setValues] = useState<ManualAddressValues>(initialValue);

  const clearForm = () => setValues(initialValue);

  const handleNewAddressSubmit = () => {
    const geocode: IGeoCodeData = {
      additionalLineText: values.lineTwo,
      city: values.city,
      country: 'US',
      county: values.county,
      state: values.state?.name || '',
      state_abbr: values.state?.abbreviation || '',
      street: values.lineOne,
      zip: values.zip,
    };
    const fullAddress = `${values.lineOne} ${values.lineTwo}, ${values.city} ${values.county} ${values.state?.abbreviation} ${values.zip}`;
    handleSubmit({ geocode, text: fullAddress });
  };

  const handleTextChange = (key: keyof ManualAddressValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [key]: event.target.value,
    });
  };

  return (
    <Dialog
      onOpenChange={close}
      open={open}
    >
      <DialogLayout>
        <DialogHeader
          actions={(
            <Button
              label="Clear"
              leftIcon="Delete"
              onClick={clearForm}
              variant="tertiary"
            />
          )}
          title="Manual Address"
        />

        <DialogContent className="p-4 sm:p-12">
          <div className="mx-auto min-w-[508px] flex-1 flex flex-col gap-2">
            <TextField autoComplete="address-line1" id="street" onChange={handleTextChange('lineOne')} placeholder="Address" value={values.lineOne} />
            <TextField autoComplete="address-line2" onChange={handleTextChange('lineTwo')} placeholder="Additional Line" value={values.lineTwo} />
            <TextField autoComplete="locality" onChange={handleTextChange('city')} placeholder="City" value={values.city} />
            <AutoComplete
              autoComplete="administrative-region"
              displayOnly
              onSelect={(selectedState) => setValues({ ...values, state: selectedState })}
              optionKey="name"
              options={states}
              placeholder="State"
              value={values.state?.name}
            />
            <TextField autoComplete="postal-code" onChange={handleTextChange('zip')} placeholder="Zip" value={values.zip} />
          </div>
        </DialogContent>

        <DialogFooter borderTop>
          <Button
            label="Create New Addess"
            onClick={handleNewAddressSubmit}
          />
        </DialogFooter>
      </DialogLayout>
    </Dialog>
  );
};
