import { useQuery } from '@tanstack/react-query';

const MESSAGE_NAME = 'wilqo.api.tenancy.GetTenantsQueryRequest';

interface TenantsResponse {
  clientId: string;
  domain: string;
  institutionId: string;
}

export const useAuthSettings = () => useQuery([MESSAGE_NAME], async () => {
  try {
    const response = await fetch(`/mortgage/api/message/tenants/${window.location.hostname}`);
    return await response.json() as TenantsResponse;
  } catch (error) {
    throw new Error('Error getting tenant authentication configuration');
  }
});
