import { useMemo } from 'react';

import type { LoanFeeTemplate } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { WilqoPartyType } from '@/API/Models/Wilqo.Shared.Models/WilqoPartyType_pb';
import { useLoanFeeTemplate } from '@/API/Queries/mortgageConfig/useLoanFeeTemplate';
import { useUpdateLoanFeeTemplate } from '@/API/Queries/mortgageConfig/useUpdateLoanFeeTemplate';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useSearchParams } from '@/Routes/NavigationContext';
import { getEnumPanelElementOptionList } from '@/Utils/Helpers/enumHelpers';
import { getPanelElement, getPanelElementOption, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { defaultFeePaymentTimingTypeOptionList, getFeeEnumPanelElementOptionList } from './FeeTemplateHelper';

const FeeTemplateDetails = () => {
  const [searchParams] = useSearchParams();
  const feeTemplateId = useMemo(() => searchParams.get('feeTemplateId'), [searchParams]);

  const { data: loanFeeTemplate, isLoading, refetch } = useLoanFeeTemplate(feeTemplateId);
  const { isLoading: isSaving, mutate } = useUpdateLoanFeeTemplate();
  const { showSnackBar } = useShared();

  const SWITCH_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => [
    getPanelElementOption({ headerText: 'Yes' }),
    getPanelElementOption({ headerText: 'No' }),
  ], []);

  const FEE_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => getFeeEnumPanelElementOptionList(), []);
  const INTEGRATED_DISCLOSURE_SECTION_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => [
    getPanelElementOption({ headerText: 'Origination Charges', id: '5' }),
    getPanelElementOption({ headerText: 'Services You Can Shop For', id: '14' }),
    getPanelElementOption({ headerText: 'Services You Cannot Shop For', id: '13' }),
    getPanelElementOption({ headerText: 'Taxes And Other Government Fees', id: '15' }),
    getPanelElementOption({ headerText: 'Other', id: '6' }),
  ], []);
  const DEFAULT_FEE_TOLERANCE_CATEGORY_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => [
    getPanelElementOption({ headerText: 'Can Change', id: '1' }),
    getPanelElementOption({ headerText: 'Cannot Decrease', id: '2' }),
    getPanelElementOption({ headerText: 'Cannot Increase', id: '3' }),
    getPanelElementOption({ headerText: 'Total Cannot Increase More Than 10%', id: '4' }),
  ], []);
  const DEFAULT_PAID_TO_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => [
    getPanelElementOption({ headerText: 'Broker', id: '1' }),
    getPanelElementOption({ headerText: 'Broker Affiliate', id: '2' }),
    getPanelElementOption({ headerText: 'Investor', id: '3' }),
    getPanelElementOption({ headerText: 'Lender', id: '4' }),
    getPanelElementOption({ headerText: 'Lender Affiliate', id: '5' }),
    getPanelElementOption({ headerText: 'Other', id: '6' }),
    getPanelElementOption({ headerText: 'Third Party Provider', id: '7' }),
  ], []);
  const DEFAULT_PAID_BY_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => [
    getPanelElementOption({ headerText: 'Borrower', id: '1' }),
    getPanelElementOption({ headerText: 'Broker', id: '2' }),
    getPanelElementOption({ headerText: 'Buyer', id: '3' }),
    getPanelElementOption({ headerText: 'Correspondent', id: '4' }),
    getPanelElementOption({ headerText: 'Lender', id: '5' }),
    getPanelElementOption({ headerText: 'Seller', id: '6' }),
    getPanelElementOption({ headerText: 'Service', id: '7' }),
    getPanelElementOption({ headerText: 'Third Party', id: '8' }),
  ], []);
  const DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => defaultFeePaymentTimingTypeOptionList, []);
  const DEFAULT_PAID_TO_PARTY_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => getEnumPanelElementOptionList(WilqoPartyType, true)
    .sort((a, b) => a.headerText.localeCompare(b.headerText)), []);

  const feeType = useMemo(() => FEE_TYPE_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.type.toString()),
  [loanFeeTemplate?.type, FEE_TYPE_OPTIONS]);
  const integratedDisclosureSectionType = useMemo(() => INTEGRATED_DISCLOSURE_SECTION_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.integratedDisclosureSectionType.toString()),
  [loanFeeTemplate?.integratedDisclosureSectionType, INTEGRATED_DISCLOSURE_SECTION_OPTIONS]);
  const defaultFeeToleranceCategoryType = useMemo(() => DEFAULT_FEE_TOLERANCE_CATEGORY_TYPE_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.defaultFeeToleranceCategoryType?.toString()),
  [loanFeeTemplate?.defaultFeeToleranceCategoryType, DEFAULT_FEE_TOLERANCE_CATEGORY_TYPE_OPTIONS]);
  const defaultFeePaidToType = useMemo(() => DEFAULT_PAID_TO_TYPE_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.defaultFeePaidToType?.toString()),
  [loanFeeTemplate?.defaultFeePaidToType, DEFAULT_PAID_TO_TYPE_OPTIONS]);
  const defaultFeePaidToWilqoPartyType = useMemo(() => DEFAULT_PAID_TO_PARTY_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.defaultFeePaidToWilqoPartyType?.toString()),
  [DEFAULT_PAID_TO_PARTY_OPTIONS, loanFeeTemplate?.defaultFeePaidToWilqoPartyType]);
  const defaultFeePaymentPaidByType = useMemo(() => DEFAULT_PAID_BY_TYPE_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.defaultFeePaymentPaidByType?.toString()),
  [loanFeeTemplate?.defaultFeePaymentPaidByType, DEFAULT_PAID_BY_TYPE_OPTIONS]);
  const defaultFeePaymentTimingType = useMemo(() => DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS
    .find((f) => f.id === loanFeeTemplate?.defaultFeePaymentTimingType?.toString()),
  [loanFeeTemplate?.defaultFeePaymentTimingType, DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS]);

  const handleMutation = (updatedLoanFeeTemplate: LoanFeeTemplate.AsObject, close: () => void) => {
    mutate(updatedLoanFeeTemplate, {
      onError: () => {
        showSnackBar({ message: 'Something went wrong, please try again later.', open: true });
      },
      onSuccess: () => {
        close();
        refetch();
        showSnackBar({ message: 'Fee Template successfully updated', open: true });
      },
    });
  };

  const handleUpdateFeeDetails = (formData: DynamicDataElementValues, close: () => void) => {
    if (loanFeeTemplate) {
      const updatedFeeTemplate: LoanFeeTemplate.AsObject = {
        ...loanFeeTemplate,
        description: formData.description?.value || loanFeeTemplate?.description,
        id: feeTemplateId,
        name: formData.name?.value || loanFeeTemplate?.name,
        type: formData.type?.value?.id || loanFeeTemplate?.type,
      };
      handleMutation(updatedFeeTemplate, close);
    }
  };

  const handleUpdateFeeLocationPlacement = (formData: DynamicDataElementValues, close: () => void) => {
    if (loanFeeTemplate) {
      const updatedFeeTemplate: LoanFeeTemplate.AsObject = {
        ...loanFeeTemplate,
        defaultFeeToleranceCategoryType: formData.defaultFeeToleranceCategoryType?.value?.id || loanFeeTemplate?.defaultFeeToleranceCategoryType,
        id: feeTemplateId,
        integratedDisclosureSectionType: formData.integratedDisclosureSectionType?.value?.id || loanFeeTemplate?.integratedDisclosureSectionType,
      };
      handleMutation(updatedFeeTemplate, close);
    }
  };

  const handleUpdateFeeIndicators = (formData: DynamicDataElementValues, close: () => void) => {
    if (loanFeeTemplate) {
      const updatedFeeTemplate: LoanFeeTemplate.AsObject = {
        ...loanFeeTemplate,
        defaultFeeIncludedInAprIndicator: formData.defaultFeeIncludedInAprIndicator?.value === 'Yes',
        id: feeTemplateId,
        regulationZPointsAndFeesIndicator: formData.regulationZPointsAndFeesIndicator?.value === 'Yes',
        requiredProviderOfServiceIndicator: formData.requiredProviderOfServiceIndicator?.value === 'Yes',
      };
      handleMutation(updatedFeeTemplate, close);
    }
  };

  const handleUpdatePaidToPaidBy = (formData: DynamicDataElementValues, close: () => void) => {
    if (loanFeeTemplate) {
      const updatedFeeTemplate: LoanFeeTemplate.AsObject = {
        ...loanFeeTemplate,
        defaultFeePaidToType: formData.defaultFeePaidToType?.value?.id || loanFeeTemplate?.defaultFeePaidToType,
        defaultFeePaidToWilqoPartyType: formData.defaultFeePaidToWilqoPartyType?.value?.id || loanFeeTemplate?.defaultFeePaidToWilqoPartyType,
        defaultFeePaymentPaidByType: formData.defaultFeePaymentPaidByType?.value?.id || loanFeeTemplate?.defaultFeePaymentPaidByType,
        defaultFeePaymentPaidByTypeThirdPartyName: formData.defaultFeePaymentPaidByTypeThirdPartyName?.value?.id || loanFeeTemplate?.defaultFeePaymentPaidByTypeThirdPartyName,
        defaultFeePaymentTimingType: formData.defaultFeePaymentTimingType?.value?.id || loanFeeTemplate?.defaultFeePaymentTimingType,
        id: feeTemplateId,
      };
      handleMutation(updatedFeeTemplate, close);
    }
  };

  return (
    <div className="flex flex-col gap-10 px-6 py-12">
      <CardListItem
        headerProps={{ title: 'Fee Details' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: loanFeeTemplate?.name,
            label: 'Internal Fee Name*',
            panelElement: getPanelElement({
              headerText: 'Internal Fee Name',
              id: 'name',
              requirement: getPanelElementRequiment('Required'),
              type: 'text',
            }),
            value: { value: loanFeeTemplate?.name },
          },
          {
            caption: loanFeeTemplate?.description,
            label: 'External Name*',
            panelElement: getPanelElement({
              headerText: 'External Name',
              id: 'description',
              requirement: getPanelElementRequiment('Required'),
              type: 'text',
            }),
            value: { value: loanFeeTemplate?.description },
          },
          {
            caption: feeType?.headerText,
            label: 'Fee Type*',
            panelElement: getPanelElement({
              headerText: 'Fee Type*',
              id: 'type',
              optionsList: FEE_TYPE_OPTIONS,
              type: 'select',
            }),
            value: { value: feeType },
          },
        ]}
        onSubmit={handleUpdateFeeDetails}
        variant="border"
      />
      <CardListItem
        headerProps={{ title: 'Fee Location / Placement' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: integratedDisclosureSectionType?.headerText,
            label: 'Integrated Disclosure Section*',
            panelElement: getPanelElement({
              headerText: 'Integrated Disclosure Section*',
              id: 'integratedDisclosureSectionType',
              optionsList: INTEGRATED_DISCLOSURE_SECTION_OPTIONS,
              requirement: getPanelElementRequiment('Required'),
              type: 'select',
            }),
            value: { value: integratedDisclosureSectionType },
          },
          {
            caption: defaultFeeToleranceCategoryType?.headerText,
            label: 'Default Fee Tolerance Category*',
            panelElement: getPanelElement({
              headerText: 'Default Fee Tolerance Category*',
              id: 'defaultFeeToleranceCategoryType',
              optionsList: DEFAULT_FEE_TOLERANCE_CATEGORY_TYPE_OPTIONS,
              type: 'select',
            }),
            value: { value: defaultFeeToleranceCategoryType },
          },
        ]}
        onSubmit={handleUpdateFeeLocationPlacement}
        variant="border"
      />
      <CardListItem
        headerProps={{ title: 'Fee Indicators' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: loanFeeTemplate?.defaultFeeIncludedInAprIndicator ? 'Yes' : 'No',
            label: 'Payment Included in APR',
            panelElement: getPanelElement({
              headerText: 'Payment Included in APR',
              id: 'defaultFeeIncludedInAprIndicator',
              optionsList: SWITCH_OPTIONS,
              type: 'switch',
            }),
            value: { value: loanFeeTemplate?.defaultFeeIncludedInAprIndicator ? 'Yes' : 'No' },
          },
          {
            caption: loanFeeTemplate?.regulationZPointsAndFeesIndicator ? 'Yes' : 'No',
            label: 'Default Included in Regulation Z Points & Fees',
            panelElement: getPanelElement({
              headerText: 'Default Included in Regulation Z Points & Fees',
              id: 'regulationZPointsAndFeesIndicator',
              optionsList: SWITCH_OPTIONS,
              type: 'switch',
            }),
            value: { value: loanFeeTemplate?.regulationZPointsAndFeesIndicator ? 'Yes' : 'No' },
          },
          {
            caption: loanFeeTemplate?.requiredProviderOfServiceIndicator ? 'Yes' : 'No',
            label: 'Required Provider of Service',
            panelElement: getPanelElement({
              headerText: 'Required Provider of Service',
              id: 'requiredProviderOfServiceIndicator',
              optionsList: SWITCH_OPTIONS,
              type: 'switch',
            }),
            value: { value: loanFeeTemplate?.requiredProviderOfServiceIndicator ? 'Yes' : 'No' },
          },
        ]}
        onSubmit={handleUpdateFeeIndicators}
        variant="border"
      />
      <CardListItem
        headerProps={{ title: 'Paid To / Paid By' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: defaultFeePaidToType?.headerText,
            label: 'Default "paid to" type*',
            panelElement: getPanelElement({
              headerText: 'Default "paid to" type*',
              id: 'defaultFeePaidToType',
              optionsList: DEFAULT_PAID_TO_TYPE_OPTIONS,
              requirement: getPanelElementRequiment('Required'),
              type: 'select',
            }),
            value: { value: defaultFeePaidToType },
          },
          {
            caption: defaultFeePaidToWilqoPartyType?.headerText,
            label: 'Default "paid to" party',
            panelElement: getPanelElement({
              headerText: 'Default "paid to" party',
              id: 'defaultFeePaidToWilqoPartyType',
              optionsList: DEFAULT_PAID_TO_PARTY_OPTIONS,
              type: 'select',
            }),
            value: { value: defaultFeePaidToWilqoPartyType },
          },
          {
            caption: loanFeeTemplate?.defaultFeePaymentPaidByTypeThirdPartyName,
            label: 'Default "paid to" third party name',
            panelElement: getPanelElement({
              headerText: 'Default "paid to" third party name',
              id: 'defaultFeePaymentPaidByTypeThirdPartyName',
              type: 'text',
            }),
            value: { value: loanFeeTemplate?.defaultFeePaymentPaidByTypeThirdPartyName },
          },
          {
            caption: defaultFeePaymentPaidByType?.headerText,
            label: 'Default "paid by"*',
            panelElement: getPanelElement({
              headerText: 'Default "paid by"*',
              id: 'defaultFeePaymentPaidByType',
              optionsList: DEFAULT_PAID_BY_TYPE_OPTIONS,
              requirement: getPanelElementRequiment('Required'),
              type: 'select',
            }),
            value: { value: defaultFeePaymentPaidByType },
          },
          {
            caption: defaultFeePaymentTimingType?.headerText,
            label: 'Default fee payment timing type*',
            panelElement: getPanelElement({
              headerText: 'Default fee payment timing type*',
              id: 'defaultFeePaymentTimingType',
              optionsList: DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS,
              requirement: getPanelElementRequiment('Required'),
              type: 'select',
            }),
            value: { value: defaultFeePaymentTimingType },
          },
        ]}
        onSubmit={handleUpdatePaidToPaidBy}
        variant="border"
      />
    </div>
  );
};

export { FeeTemplateDetails };
