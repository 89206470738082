import { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import type { FieldAttachment } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';
import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { getAttachId } from '@/Utils/Helpers/attachComponentHelper';
import { DynamicDataElementMap, fromPanelElementOption, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import Component from './Select.component';

export interface SelectProps {
  panelElement: PanelElement.AsObject;
  attach?: FieldAttachment.AsObject;
}

const Select = (props: SelectProps) => {
  const {
    attach,
    panelElement: {
      disabled,
      headerText: placeholder,
      id,
      optionsList: options,
      requirement,
    },
  } = props;

  const [value, setValue, { errorMessage, ref }] = useDynamicFormRegister<PanelElementOption.AsObject | undefined>(
    id,
    {
      fromDynamic: (v) => options.find((option) => {
        const type = v.getDataType();
        if (type === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_ENUMERATION && option.value) {
          return toString(DynamicDataElementMap(option.value)) === toString(v);
        }
        return option.id === toString(v);
      }),
      toDynamic: fromPanelElementOption,
    },
    { requirement },
  );

  useEffect(() => {
    if (value && value.id && !value.headerText) {
      const fullSelectedOption = options.find((option) => option.id === value.id);
      setValue(fullSelectedOption, fullSelectedOption?.headerText || '');
    }
  }, [value, options, setValue]);

  const handleSelectOption = useCallback((option: PanelElementOption.AsObject) => {
    setValue(option, option.headerText);
  }, [setValue]);

  const component = useMemo(() => (
    <Component
      ref={ref}
      disabled={disabled}
      errorMessage={errorMessage}
      fullWidth
      onSelect={handleSelectOption}
      options={options}
      placeholder={placeholder}
      required={requirement?.required}
      titleKey="headerText"
      value={value?.headerText}
    />
  ), [disabled, errorMessage, handleSelectOption, options, placeholder, ref, requirement?.required, value?.headerText]);

  if (attach) {
    const element = document.getElementById(getAttachId(attach));
    if (element) return createPortal(component, element);
  }

  return component;
};

export default Select;
