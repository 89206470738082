import { useCallback, useEffect, useRef, useState } from 'react';

import { SectionAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb';
import type { DealWriteCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { type IButtonProps, Button } from '@/Components/Atoms/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import type { DynamicDataElementValues, DynamicFormContextState, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { ButtonGroup } from '@/Components/Features/PanelElement/ButtonGroup';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { useDynamicPageSave } from '../../../../API/Queries/mortgageDynamicData/useDynamicSavePage';
import { WidgetRenderer } from '../../WidgetRenderer';
import { useWidgetContext, useWidgetContextValues, WidgetProvider } from '../WidgetContext';
import type { DynamicDialogProps } from '.';

export const PageDialog = (props: DynamicDialogProps) => {
  const { isLoading: isSaving, mutate: savePage } = useDynamicPageSave();
  const { loanId } = useDynamicLoanInfo();
  const { showSnackBar } = useShared();
  const { setVersion, version } = useDynamicContext();
  const { reloadMainPage } = useWidgetContext();
  const contextValues = useWidgetContextValues();
  const { isMobile } = useWindow();

  const [isSavingNew, setIsSavingNew] = useState(false);
  const saveNewRef = useRef(false);

  const handleSubmitError = (error: any) => showSnackBar(error.message || 'Something went wrong');

  const handleSubmitSuccess = (response: DealWriteCommandResponse.AsObject | undefined) => {
    if (response) {
      if (response.error?.error) {
        handleSubmitError({ message: response.error.errorMessage });
      } else {
        if (!saveNewRef.current) {
          props.onClose();
        }
        showSnackBar('Loan Updated');
        setVersion(response.dealVersion);
        reloadMainPage();
      }
      saveNewRef.current = false;
      setIsSavingNew(false);
    }
  };

  const handleSubmit = (values: DynamicDataElementValues, { callback }: SubmitValues) => {
    if (props.page) {
      savePage(
        {
          createNew: props.isNew,
          dealId: loanId,
          dealVersion: version,
          hiddenFieldIds: [],
          page: props.page,
          pageScopeToken: props.scopeToken || '',
          values,
        },
        {
          onError: handleSubmitError,
          onSuccess: (response) => {
            handleSubmitSuccess(response);
            callback?.();
          },
        },
      );
    }
  };

  const handleDiscardClick = useCallback(() => {
    contextValues.setEditingWidgetIds([]);
    props.onClose();
  }, [contextValues, props]);

  const handleEditClick = useCallback(() => {
    contextValues.setEditAllWidgets(true);
  }, [contextValues]);

  const hasSetWidget = useRef(false);
  // ? set all widgets to edit mode if is new or if edit mode
  useEffect(() => {
    if (!hasSetWidget.current && (props.isNew || (!hasSetWidget.current && props.editMode && props.page))) {
      contextValues.setEditAllWidgets(true);
      hasSetWidget.current = true;
    }
  }, [contextValues, props]);

  const getButtonActions = useCallback(({ clearForm, hasError, imperativeSubmit }: DynamicFormContextState): Array<IButtonProps> => {
    if (!props.isEditable || props.page?.widgetsList[0].dynamicText) return [];
    if (contextValues.editingWidgetIds.length === 0 && !contextValues.editAllWidgets) {
      return [
        { label: 'Edit', onClick: handleEditClick },
      ];
    }

    const editingStateButtons: Record<number, IButtonProps> = {
      [SectionAction.SECTION_ACTION_CANCEL]: {
        label: 'Discard',
        onClick: handleDiscardClick,
        variant: 'tertiary',
      },
      [SectionAction.SECTION_ACTION_SAVE_AND_NEW_ITEM]: {
        disabled: hasError,
        isLoading: isSavingNew && isSaving,
        label: 'Save & new item',
        onClick: () => {
          imperativeSubmit(() => clearForm(true));
          setIsSavingNew(true);
          saveNewRef.current = true;
        },
        variant: 'secondary',
      },
      [SectionAction.SECTION_ACTION_SAVE]: {
        disabled: hasError,
        isLoading: isSaving && !isSavingNew,
        label: 'Save',
        type: 'submit',
      },
    };
    const actions = props.actions || [SectionAction.SECTION_ACTION_CANCEL, SectionAction.SECTION_ACTION_SAVE];
    return actions
      .map((actionEnum) => editingStateButtons[actionEnum])
      .filter((button) => button !== undefined);
  }, [props, handleDiscardClick, isSavingNew, contextValues, handleEditClick, isSaving]);

  const renderWidgets = () => {
    if (props.isLoading) return <Skeleton height="400px" variant="rect" />;

    return (
      <WidgetRenderer widgetList={props.page?.widgetsList || []} />
    );
  };

  return (
    <Dialog onOpenChange={props.onClose} open={props.isOpen} renderWithin={false}>
      <DialogLayout asChild>
        <DynamicFormProvider
          onSubmit={handleSubmit}
          page={props.page}
        >
          {(values) => (
            <WidgetProvider
              pageId=""
              {...contextValues}
              context="dialog"
              editAllWidgets={props.editMode || props.isNew || contextValues.editAllWidgets}
            >
              <DialogHeader
                actions={!isMobile && (
                  <ButtonGroup
                    buttons={getButtonActions(values)}
                  />
                )}
                title={props.page?.header?.title || ''}
              />
              <DialogContent>
                <div className="p-4 desktop:p-12 flex-1 h-full overflow-auto flex flex-col gap-10">
                  {renderWidgets()}
                </div>
              </DialogContent>
              {isMobile && (
                <DialogFooter borderTop span>
                  {
                    getButtonActions(values).map((btn) => <Button key={btn.label} {...btn} />)
                  }
                </DialogFooter>
              )}
            </WidgetProvider>
          )}
        </DynamicFormProvider>
      </DialogLayout>
    </Dialog>
  );
};
