import { useQuery } from '@tanstack/react-query';

import { GetIntegrationReportQueryRequest, GetIntegrationReportQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetIntegrationReportQueryRequest';

const useIntegrationReport = (trackId: string) => {
  const sendMessage = useWilqoMessage();

  return useQuery([MESSAGE_NAME, trackId], async () => {
    const request = new GetIntegrationReportQueryRequest();
    request.setTrackId(trackId);
    const response = await sendMessage<GetIntegrationReportQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetIntegrationReportQueryResponse,
    );
    return {
      documentType: response.documentType,
      documentUrl: response.documentUrl,
      hasReport: response.hasreport,
    };
  },
  {
    enabled: Boolean(trackId),
  });
};
export { useIntegrationReport };
