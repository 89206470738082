import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpsertOrgLicenseCommandRequest, UpsertOrgLicenseCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { getInstaceFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.UpsertOrgLicenseCommandRequest';

interface UpdateObject {
  license: License.AsObject;
  orgId: string;
}

const useUpsertOrgLicense = () => {
  const { commandResponse } = useBackend();
  const UpsertOrgLicenseCommand = async (updateObject: UpdateObject) => {
    const { license, orgId } = updateObject;
    const request = new UpsertOrgLicenseCommandRequest();
    const orgLicense = new License();
    orgLicense.setId(license.id);
    orgLicense.setEnabled(license.enabled);
    orgLicense.setExpirationDate(getInstaceFromTimestamp(license.expirationDate));
    orgLicense.setIssueDate(getInstaceFromTimestamp(license.issueDate));
    orgLicense.setLicenseAuthorityType(license.licenseAuthorityType);
    orgLicense.setLicenseDescription(license.licenseDescription);
    orgLicense.setLicenseId(license.licenseId);
    orgLicense.setLicenseLegalName(license.licenseLegalName);
    orgLicense.setLicenseScope(license.licenseScope);
    orgLicense.setLicenseScopeType(license.licenseScopeType);
    orgLicense.setLicenseStatusType(license.licenseStatusType);
    orgLicense.setStartDate(getInstaceFromTimestamp(license.startDate));
    orgLicense.setEnabled(license.enabled);
    request.setLicense(orgLicense);
    request.setOrganizationId(orgId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertOrgLicenseCommandResponse();
    UpsertOrgLicenseCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpsertOrgLicenseCommand);
};

export { useUpsertOrgLicense };
