// source: Wilqo_API_Users_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_API_Users_Models_pb = require('./Wilqo_API_Users_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Users_Models_pb);
var Wilqo_API_Users_InstitutionModels_pb = require('./Wilqo_API_Users_InstitutionModels_pb.js');
goog.object.extend(proto, Wilqo_API_Users_InstitutionModels_pb);
goog.exportSymbol('proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddLicenseInformationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddLicenseInformationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillToSkillsetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillToSkillsetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddUserDealAssociationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddUserDealAssociationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.AddUsersToInstitutionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.ArchiveOrganizationsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.ArchiveOrganizationsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomainCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateSkillsetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateSkillsetGroupCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateUserBackendCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.CreateUserCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.DeactivateOrganizationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.DeactivateOrganizationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillSetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillSetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillsetGroupCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitSkillsetGroupCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitUserCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitUserCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitializeDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InitializeDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InstitutionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.InstitutionInitCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.InstitutionUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.OrganizationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.OrganizationInitCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.OrganizationUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveOrgLicenseCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveOrgLicenseCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveUserLicenseCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveUserLicenseCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillsetCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillsetGroupCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeUserUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.SubscribeUserUpdateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateLicenseInformationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateLicenseInformationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateSkillsetCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertOrgLicenseCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertOrgLicenseCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertUserLicenseCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.users.UpsertUserLicenseCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.users.UserCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitializeDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitializeDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitializeDataCommandRequest.displayName = 'proto.wilqo.api.users.InitializeDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitializeDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitializeDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitializeDataCommandResponse.displayName = 'proto.wilqo.api.users.InitializeDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillCommandRequest.displayName = 'proto.wilqo.api.users.InitSkillCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillCommandResponse.displayName = 'proto.wilqo.api.users.InitSkillCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillSetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillSetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillSetCommandRequest.displayName = 'proto.wilqo.api.users.InitSkillSetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillSetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillSetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillSetCommandResponse.displayName = 'proto.wilqo.api.users.InitSkillSetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillsetGroupCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillsetGroupCommandRequest.displayName = 'proto.wilqo.api.users.InitSkillsetGroupCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitSkillsetGroupCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitSkillsetGroupCommandResponse.displayName = 'proto.wilqo.api.users.InitSkillsetGroupCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitUserCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitUserCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitUserCommandRequest.displayName = 'proto.wilqo.api.users.InitUserCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InitUserCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InitUserCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InitUserCommandResponse.displayName = 'proto.wilqo.api.users.InitUserCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillToSkillsetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.displayName = 'proto.wilqo.api.users.AddSkillToSkillsetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillToSkillsetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.displayName = 'proto.wilqo.api.users.AddSkillToSkillsetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.displayName = 'proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.displayName = 'proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.displayName = 'proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.displayName = 'proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.displayName = 'proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.displayName = 'proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.displayName = 'proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.displayName = 'proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.displayName = 'proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.displayName = 'proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.displayName = 'proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.displayName = 'proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.displayName = 'proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.displayName = 'proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.displayName = 'proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.displayName = 'proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeUserUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.displayName = 'proto.wilqo.api.users.SubscribeUserUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SubscribeUserUpdateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.displayName = 'proto.wilqo.api.users.SubscribeUserUpdateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.CreateSkillsetGroupCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.displayName = 'proto.wilqo.api.users.CreateSkillsetGroupCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.displayName = 'proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SkillsetGroupCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillsetGroupCommandResponse.displayName = 'proto.wilqo.api.users.SkillsetGroupCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.CreateSkillsetCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.CreateSkillsetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateSkillsetCommandRequest.displayName = 'proto.wilqo.api.users.CreateSkillsetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateSkillsetCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateSkillsetCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateSkillsetCommandRequest.displayName = 'proto.wilqo.api.users.UpdateSkillsetCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillsetCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.SkillsetCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillsetCommandResponse.displayName = 'proto.wilqo.api.users.SkillsetCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateUserCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.CreateUserCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.CreateUserCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateUserCommandRequest.displayName = 'proto.wilqo.api.users.CreateUserCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UserCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UserCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UserCommandResponse.displayName = 'proto.wilqo.api.users.UserCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddUserDealAssociationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddUserDealAssociationCommandRequest.displayName = 'proto.wilqo.api.users.AddUserDealAssociationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddUserDealAssociationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddUserDealAssociationCommandResponse.displayName = 'proto.wilqo.api.users.AddUserDealAssociationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.CreateUserBackendCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.CreateUserBackendCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateUserBackendCommandRequest.displayName = 'proto.wilqo.api.users.CreateUserBackendCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InstitutionInitCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InstitutionInitCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InstitutionInitCommandRequest.displayName = 'proto.wilqo.api.users.InstitutionInitCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InstitutionUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InstitutionUpdateCommandRequest.displayName = 'proto.wilqo.api.users.InstitutionUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.displayName = 'proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.displayName = 'proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.AddUsersToInstitutionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.displayName = 'proto.wilqo.api.users.AddUsersToInstitutionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.displayName = 'proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InstitutionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InstitutionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InstitutionCommandResponse.displayName = 'proto.wilqo.api.users.InstitutionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.OrganizationInitCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.OrganizationInitCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.OrganizationInitCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.OrganizationInitCommandRequest.displayName = 'proto.wilqo.api.users.OrganizationInitCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.OrganizationUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.OrganizationUpdateCommandRequest.displayName = 'proto.wilqo.api.users.OrganizationUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.OrganizationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.OrganizationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.OrganizationCommandResponse.displayName = 'proto.wilqo.api.users.OrganizationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.displayName = 'proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.displayName = 'proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.displayName = 'proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.displayName = 'proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomainCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomainCommandResponse.displayName = 'proto.wilqo.api.users.BusinessProcessDomainCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddLicenseInformationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddLicenseInformationCommandRequest.displayName = 'proto.wilqo.api.users.AddLicenseInformationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AddLicenseInformationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AddLicenseInformationCommandResponse.displayName = 'proto.wilqo.api.users.AddLicenseInformationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateLicenseInformationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.displayName = 'proto.wilqo.api.users.UpdateLicenseInformationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateLicenseInformationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.displayName = 'proto.wilqo.api.users.UpdateLicenseInformationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertUserLicenseCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertUserLicenseCommandRequest.displayName = 'proto.wilqo.api.users.UpsertUserLicenseCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertUserLicenseCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertUserLicenseCommandResponse.displayName = 'proto.wilqo.api.users.UpsertUserLicenseCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveUserLicenseCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveUserLicenseCommandRequest.displayName = 'proto.wilqo.api.users.RemoveUserLicenseCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveUserLicenseCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveUserLicenseCommandResponse.displayName = 'proto.wilqo.api.users.RemoveUserLicenseCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertOrgLicenseCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.displayName = 'proto.wilqo.api.users.UpsertOrgLicenseCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertOrgLicenseCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.displayName = 'proto.wilqo.api.users.UpsertOrgLicenseCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveOrgLicenseCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.displayName = 'proto.wilqo.api.users.RemoveOrgLicenseCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.RemoveOrgLicenseCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.displayName = 'proto.wilqo.api.users.RemoveOrgLicenseCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.DeactivateOrganizationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.DeactivateOrganizationCommandRequest.displayName = 'proto.wilqo.api.users.DeactivateOrganizationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.DeactivateOrganizationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.DeactivateOrganizationCommandResponse.displayName = 'proto.wilqo.api.users.DeactivateOrganizationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.ArchiveOrganizationsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.displayName = 'proto.wilqo.api.users.ArchiveOrganizationsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ArchiveOrganizationsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.displayName = 'proto.wilqo.api.users.ArchiveOrganizationsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.displayName = 'proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.displayName = 'proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.displayName = 'proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.displayName = 'proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.displayName = 'proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.displayName = 'proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.displayName = 'proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.displayName = 'proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.displayName = 'proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.displayName = 'proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.displayName = 'proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.displayName = 'proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.displayName = 'proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.displayName = 'proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.displayName = 'proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.displayName = 'proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitializeDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitializeDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitializeDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seedSkills: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitializeDataCommandRequest}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitializeDataCommandRequest;
  return proto.wilqo.api.users.InitializeDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitializeDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitializeDataCommandRequest}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSeedSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitializeDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitializeDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitializeDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeedSkills();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool seed_skills = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.getSeedSkills = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.users.InitializeDataCommandRequest.prototype.setSeedSkills = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitializeDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitializeDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitializeDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitializeDataCommandResponse}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitializeDataCommandResponse;
  return proto.wilqo.api.users.InitializeDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitializeDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitializeDataCommandResponse}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitializeDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitializeDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitializeDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InitializeDataCommandResponse} returns this
*/
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitializeDataCommandResponse} returns this
 */
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitializeDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skill: (f = msg.getSkill()) && Wilqo_API_Users_Models_pb.Skill.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillCommandRequest}
 */
proto.wilqo.api.users.InitSkillCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillCommandRequest;
  return proto.wilqo.api.users.InitSkillCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillCommandRequest}
 */
proto.wilqo.api.users.InitSkillCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.Skill;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.Skill.deserializeBinaryFromReader);
      msg.setSkill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkill();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitSkillCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Skill skill = 3;
 * @return {?proto.wilqo.api.users.Skill}
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.getSkill = function() {
  return /** @type{?proto.wilqo.api.users.Skill} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.Skill, 3));
};


/**
 * @param {?proto.wilqo.api.users.Skill|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillCommandRequest} returns this
*/
proto.wilqo.api.users.InitSkillCommandRequest.prototype.setSkill = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.clearSkill = function() {
  return this.setSkill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillCommandRequest.prototype.hasSkill = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillCommandResponse}
 */
proto.wilqo.api.users.InitSkillCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillCommandResponse;
  return proto.wilqo.api.users.InitSkillCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillCommandResponse}
 */
proto.wilqo.api.users.InitSkillCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InitSkillCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillCommandResponse} returns this
*/
proto.wilqo.api.users.InitSkillCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillCommandResponse} returns this
 */
proto.wilqo.api.users.InitSkillCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillSetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillSetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillSet: (f = msg.getSkillSet()) && Wilqo_API_Users_Models_pb.SkillSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandRequest}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillSetCommandRequest;
  return proto.wilqo.api.users.InitSkillSetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillSetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandRequest}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.SkillSet;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSet.deserializeBinaryFromReader);
      msg.setSkillSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillSetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillSetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.SkillSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitSkillSetCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SkillSet skill_set = 2;
 * @return {?proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.getSkillSet = function() {
  return /** @type{?proto.wilqo.api.users.SkillSet} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillSet, 2));
};


/**
 * @param {?proto.wilqo.api.users.SkillSet|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillSetCommandRequest} returns this
*/
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.setSkillSet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.clearSkillSet = function() {
  return this.setSkillSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillSetCommandRequest.prototype.hasSkillSet = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillSetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillSetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandResponse}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillSetCommandResponse;
  return proto.wilqo.api.users.InitSkillSetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillSetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandResponse}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillSetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillSetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillSetCommandResponse} returns this
*/
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillSetCommandResponse} returns this
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillSetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillsetGroupCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetGroup: (f = msg.getSkillsetGroup()) && Wilqo_API_Users_Models_pb.SkillsetGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillsetGroupCommandRequest;
  return proto.wilqo.api.users.InitSkillsetGroupCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.SkillsetGroup;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillsetGroup.deserializeBinaryFromReader);
      msg.setSkillsetGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillsetGroupCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.SkillsetGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SkillsetGroup skillset_group = 2;
 * @return {?proto.wilqo.api.users.SkillsetGroup}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.getSkillsetGroup = function() {
  return /** @type{?proto.wilqo.api.users.SkillsetGroup} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillsetGroup, 2));
};


/**
 * @param {?proto.wilqo.api.users.SkillsetGroup|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} returns this
*/
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.setSkillsetGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.clearSkillsetGroup = function() {
  return this.setSkillsetGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandRequest.prototype.hasSkillsetGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitSkillsetGroupCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitSkillsetGroupCommandResponse;
  return proto.wilqo.api.users.InitSkillsetGroupCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitSkillsetGroupCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse} returns this
*/
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitSkillsetGroupCommandResponse} returns this
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitSkillsetGroupCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitUserCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitUserCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitUserCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitUserCommandRequest}
 */
proto.wilqo.api.users.InitUserCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitUserCommandRequest;
  return proto.wilqo.api.users.InitUserCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitUserCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitUserCommandRequest}
 */
proto.wilqo.api.users.InitUserCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitUserCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitUserCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitUserCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional User user = 2;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.InitUserCommandRequest} returns this
*/
proto.wilqo.api.users.InitUserCommandRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitUserCommandRequest} returns this
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InitUserCommandRequest} returns this
 */
proto.wilqo.api.users.InitUserCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InitUserCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InitUserCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InitUserCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitUserCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InitUserCommandResponse}
 */
proto.wilqo.api.users.InitUserCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InitUserCommandResponse;
  return proto.wilqo.api.users.InitUserCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InitUserCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InitUserCommandResponse}
 */
proto.wilqo.api.users.InitUserCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InitUserCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InitUserCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InitUserCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InitUserCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InitUserCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InitUserCommandResponse} returns this
*/
proto.wilqo.api.users.InitUserCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InitUserCommandResponse} returns this
 */
proto.wilqo.api.users.InitUserCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InitUserCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillToSkillsetCommandRequest;
  return proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string skill_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.getSkillId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedUsersList: jspb.Message.toObjectList(msg.getAssignedUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance),
    assignedSkillSetsList: jspb.Message.toObjectList(msg.getAssignedSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillSetsList: jspb.Message.toObjectList(msg.getAvailableSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillToSkillsetCommandResponse;
  return proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addAssignedUsers(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillSets(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User assigned_users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.getAssignedUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.setAssignedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.addAssignedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.clearAssignedUsersList = function() {
  return this.setAssignedUsersList([]);
};


/**
 * repeated SkillSetSummary assigned_skill_sets = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.getAssignedSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.setAssignedSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.addAssignedSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.clearAssignedSkillSetsList = function() {
  return this.setAssignedSkillSetsList([]);
};


/**
 * repeated SkillSetSummary available_skill_sets = 5;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.getAvailableSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.setAvailableSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.addAvailableSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddSkillToSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillToSkillsetCommandResponse.prototype.clearAvailableSkillSetsList = function() {
  return this.setAvailableSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skillsetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest;
  return proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_group_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.getSkillsetGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.setSkillsetGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string skillset_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandRequest.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse;
  return proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddSkillSetToSkillsetGroupCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillsetGroupId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest;
  return proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillsetGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string skillset_group_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.getSkillsetGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest} returns this
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandRequest.prototype.setSkillsetGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse;
  return proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse} returns this
*/
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse} returns this
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddSkillsetGroupToUserCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetGroupId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skillSetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest;
  return proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetGroupId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillSetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetGroupId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillSetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_group_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.getSkillsetGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.setSkillsetGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string skill_set_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.getSkillSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandRequest.prototype.setSkillSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse;
  return proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.RemoveSkillSetFromSkillsetGroupCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillsetGroupId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest;
  return proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillsetGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string skillset_group_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.getSkillsetGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest.prototype.setSkillsetGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse;
  return proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.RemoveSkillsetGroupFromUserCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    skillId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest;
  return proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSkillId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string skill_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.getSkillId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandRequest.prototype.setSkillId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedUsersList: jspb.Message.toObjectList(msg.getAssignedUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance),
    assignedSkillSetsList: jspb.Message.toObjectList(msg.getAssignedSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillSetsList: jspb.Message.toObjectList(msg.getAvailableSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse;
  return proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addAssignedUsers(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillSets(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated User assigned_users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.getAssignedUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.setAssignedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.addAssignedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.clearAssignedUsersList = function() {
  return this.setAssignedUsersList([]);
};


/**
 * repeated SkillSetSummary assigned_skill_sets = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.getAssignedSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.setAssignedSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.addAssignedSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.clearAssignedSkillSetsList = function() {
  return this.setAssignedSkillSetsList([]);
};


/**
 * repeated SkillSetSummary available_skill_sets = 5;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.getAvailableSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.setAvailableSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.addAvailableSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveSkillFromSkillsetCommandResponse.prototype.clearAvailableSkillSetsList = function() {
  return this.setAvailableSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest;
  return proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse;
  return proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} returns this
*/
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillUpdateCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest;
  return proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse;
  return proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} returns this
*/
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillSetUpdateCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest;
  return proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse;
  return proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} returns this
*/
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeSkillsetGroupUpdateCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeUserUpdateCommandRequest;
  return proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SubscribeUserUpdateCommandResponse;
  return proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} returns this
*/
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SubscribeUserUpdateCommandResponse} returns this
 */
proto.wilqo.api.users.SubscribeUserUpdateCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillSetIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateSkillsetGroupCommandRequest;
  return proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillSetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string skill_set_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.getSkillSetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.setSkillSetIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.addSkillSetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.clearSkillSetIdsList = function() {
  return this.setSkillSetIdsList([]);
};


/**
 * repeated string user_ids = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetGroupCommandRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    skillSetIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest;
  return proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSkillSetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_description = 4;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string skill_set_ids = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getSkillSetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setSkillSetIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.addSkillSetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.clearSkillSetIdsList = function() {
  return this.setSkillSetIdsList([]);
};


/**
 * repeated string user_ids = 6;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetGroupCommandRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillsetGroupCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillsetGroupCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillsetGroup: (f = msg.getSkillsetGroup()) && Wilqo_API_Users_Models_pb.SkillsetGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillsetGroupCommandResponse;
  return proto.wilqo.api.users.SkillsetGroupCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillsetGroupCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillsetGroup;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillsetGroup.deserializeBinaryFromReader);
      msg.setSkillsetGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillsetGroupCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillsetGroupCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetGroup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillsetGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse} returns this
*/
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse} returns this
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SkillsetGroup skillset_group = 3;
 * @return {?proto.wilqo.api.users.SkillsetGroup}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.getSkillsetGroup = function() {
  return /** @type{?proto.wilqo.api.users.SkillsetGroup} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillsetGroup, 3));
};


/**
 * @param {?proto.wilqo.api.users.SkillsetGroup|undefined} value
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse} returns this
*/
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.setSkillsetGroup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SkillsetGroupCommandResponse} returns this
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.clearSkillsetGroup = function() {
  return this.setSkillsetGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SkillsetGroupCommandResponse.prototype.hasSkillsetGroup = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateSkillsetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateSkillsetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    skillsetGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateSkillsetCommandRequest;
  return proto.wilqo.api.users.CreateSkillsetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateSkillsetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateSkillsetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateSkillsetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getSkillsetGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string skill_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.getSkillIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.setSkillIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.addSkillIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.clearSkillIdsList = function() {
  return this.setSkillIdsList([]);
};


/**
 * repeated string skillset_group_ids = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.getSkillsetGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.setSkillsetGroupIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.addSkillsetGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.CreateSkillsetCommandRequest.prototype.clearSkillsetGroupIdsList = function() {
  return this.setSkillsetGroupIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateSkillsetCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    skillIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    skillsetGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateSkillsetCommandRequest;
  return proto.wilqo.api.users.UpdateSkillsetCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateSkillsetCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSkillIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSkillsetGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_description = 4;
 * @return {string}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string skill_ids = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getSkillIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setSkillIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.addSkillIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.clearSkillIdsList = function() {
  return this.setSkillIdsList([]);
};


/**
 * repeated string skillset_group_ids = 6;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.getSkillsetGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.setSkillsetGroupIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.addSkillsetGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateSkillsetCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateSkillsetCommandRequest.prototype.clearSkillsetGroupIdsList = function() {
  return this.setSkillsetGroupIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillsetCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillsetCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    skillSet: (f = msg.getSkillSet()) && Wilqo_API_Users_Models_pb.SkillSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse}
 */
proto.wilqo.api.users.SkillsetCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillsetCommandResponse;
  return proto.wilqo.api.users.SkillsetCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillsetCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse}
 */
proto.wilqo.api.users.SkillsetCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillSet;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSet.deserializeBinaryFromReader);
      msg.setSkillSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillsetCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillsetCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSkillSet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.SkillsetCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SkillSet skill_set = 3;
 * @return {?proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.getSkillSet = function() {
  return /** @type{?proto.wilqo.api.users.SkillSet} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.SkillSet, 3));
};


/**
 * @param {?proto.wilqo.api.users.SkillSet|undefined} value
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse} returns this
*/
proto.wilqo.api.users.SkillsetCommandResponse.prototype.setSkillSet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.SkillsetCommandResponse} returns this
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.clearSkillSet = function() {
  return this.setSkillSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.SkillsetCommandResponse.prototype.hasSkillSet = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.CreateUserCommandRequest.repeatedFields_ = [5,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateUserCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateUserCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateUserCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    skillsetGroupIdsList: jspb.Message.toObjectList(msg.getSkillsetGroupIdsList(),
    Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.toObject, includeInstance),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    loanPartyRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    Wilqo_API_Users_InstitutionModels_pb.License.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest}
 */
proto.wilqo.api.users.CreateUserCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateUserCommandRequest;
  return proto.wilqo.api.users.CreateUserCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateUserCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest}
 */
proto.wilqo.api.users.CreateUserCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.deserializeBinaryFromReader);
      msg.addSkillsetGroupIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoanPartyRoleTypes(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 9:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateUserCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateUserCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateUserCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSkillsetGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLoanPartyRoleTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEmailVerified();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated BusinessProcessDomainEntityId skillset_group_ids = 5;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getSkillsetGroupIdsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
*/
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setSkillsetGroupIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomainEntityId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.addSkillsetGroupIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.BusinessProcessDomainEntityId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.clearSkillsetGroupIdsList = function() {
  return this.setSkillsetGroupIdsList([]);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AdminLoanPartyRoleTypesEnum loan_party_role_types = 7;
 * @return {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getLoanPartyRoleTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setLoanPartyRoleTypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.addLoanPartyRoleTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.clearLoanPartyRoleTypesList = function() {
  return this.setLoanPartyRoleTypesList([]);
};


/**
 * repeated string organization_ids = 8;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated License licenses = 9;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
*/
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * optional string business_process_domain_id = 10;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone_number = 11;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string profile_image_url = 18;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool email_verified = 12;
 * @return {boolean}
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.CreateUserCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserCommandRequest.prototype.setEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserCommandRequest;
  return proto.wilqo.api.users.UpdateUserCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearFirstName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setLastName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearLastName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string national_licensing_id = 7;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearNationalLicensingId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasNationalLicensingId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearPhoneNumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string profile_image_url = 18;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearProfileImageUrl = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasProfileImageUrl = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool email_verified = 9;
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.setEmailVerified = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.clearEmailVerified = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserCommandRequest.prototype.hasEmailVerified = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UserCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UserCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UserCommandResponse}
 */
proto.wilqo.api.users.UserCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UserCommandResponse;
  return proto.wilqo.api.users.UserCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UserCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UserCommandResponse}
 */
proto.wilqo.api.users.UserCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UserCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UserCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UserCommandResponse} returns this
*/
proto.wilqo.api.users.UserCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UserCommandResponse} returns this
 */
proto.wilqo.api.users.UserCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 3;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.UserCommandResponse} returns this
*/
proto.wilqo.api.users.UserCommandResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UserCommandResponse} returns this
 */
proto.wilqo.api.users.UserCommandResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UserCommandResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddUserDealAssociationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddUserDealAssociationCommandRequest;
  return proto.wilqo.api.users.AddUserDealAssociationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddUserDealAssociationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} returns this
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string party_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} returns this
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandRequest} returns this
 */
proto.wilqo.api.users.AddUserDealAssociationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddUserDealAssociationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddUserDealAssociationCommandResponse;
  return proto.wilqo.api.users.AddUserDealAssociationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddUserDealAssociationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse} returns this
*/
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddUserDealAssociationCommandResponse} returns this
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddUserDealAssociationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateUserBackendCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateUserBackendCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    skillsetGroupIdsList: jspb.Message.toObjectList(msg.getSkillsetGroupIdsList(),
    Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.toObject, includeInstance),
    userId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    Wilqo_API_Users_InstitutionModels_pb.License.toObject, includeInstance),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateUserBackendCommandRequest;
  return proto.wilqo.api.users.CreateUserBackendCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateUserBackendCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.deserializeBinaryFromReader);
      msg.addSkillsetGroupIds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 7:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateUserBackendCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateUserBackendCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSkillsetGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getEmailVerified();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated BusinessProcessDomainEntityId skillset_group_ids = 5;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getSkillsetGroupIdsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.BusinessProcessDomainEntityId, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomainEntityId>} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
*/
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setSkillsetGroupIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomainEntityId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.addSkillsetGroupIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.BusinessProcessDomainEntityId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.clearSkillsetGroupIdsList = function() {
  return this.setSkillsetGroupIdsList([]);
};


/**
 * optional string user_id = 6;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated License licenses = 7;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
*/
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string profile_image_url = 18;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool email_verified = 19;
 * @return {boolean}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string business_process_domain_id = 20;
 * @return {string}
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateUserBackendCommandRequest} returns this
 */
proto.wilqo.api.users.CreateUserBackendCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InstitutionInitCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InstitutionInitCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 12, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InstitutionInitCommandRequest;
  return proto.wilqo.api.users.InstitutionInitCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InstitutionInitCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InstitutionInitCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InstitutionInitCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_text = 3;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_additional_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_name = 5;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string plus_four_zip_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state_name = 11;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string legal_entity_indicator = 12;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string business_process_domain_id = 13;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionInitCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionInitCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InstitutionUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 13, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InstitutionUpdateCommandRequest;
  return proto.wilqo.api.users.InstitutionUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InstitutionUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address_additional_line_text = 5;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city_name = 6;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_code = 7;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country_name = 8;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string plus_four_zip_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state_code = 11;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string state_name = 12;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string legal_entity_indicator = 13;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string business_process_domain_id = 14;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.InstitutionUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest;
  return proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessDomainIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string business_process_domain_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.getBusinessProcessDomainIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.setBusinessProcessDomainIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.addBusinessProcessDomainIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.clearBusinessProcessDomainIdsList = function() {
  return this.setBusinessProcessDomainIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddBusinessProcessDomainsToInstitutionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest;
  return proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessDomain(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string business_process_domain = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.getBusinessProcessDomainList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.setBusinessProcessDomainList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.addBusinessProcessDomain = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.clearBusinessProcessDomainList = function() {
  return this.setBusinessProcessDomainList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveBusinessProcessDomainsFromInstitutionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddUsersToInstitutionCommandRequest;
  return proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddUsersToInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.AddUsersToInstitutionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest;
  return proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUsersFromInstitutionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InstitutionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InstitutionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    institution: (f = msg.getInstitution()) && Wilqo_API_Users_InstitutionModels_pb.InstitutionSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse}
 */
proto.wilqo.api.users.InstitutionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InstitutionCommandResponse;
  return proto.wilqo.api.users.InstitutionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InstitutionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse}
 */
proto.wilqo.api.users.InstitutionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.InstitutionSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.InstitutionSummary.deserializeBinaryFromReader);
      msg.setInstitution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InstitutionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InstitutionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getInstitution();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.InstitutionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse} returns this
*/
proto.wilqo.api.users.InstitutionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse} returns this
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InstitutionSummary institution = 3;
 * @return {?proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.getInstitution = function() {
  return /** @type{?proto.wilqo.api.users.InstitutionSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.InstitutionSummary, 3));
};


/**
 * @param {?proto.wilqo.api.users.InstitutionSummary|undefined} value
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse} returns this
*/
proto.wilqo.api.users.InstitutionCommandResponse.prototype.setInstitution = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.InstitutionCommandResponse} returns this
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.clearInstitution = function() {
  return this.setInstitution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.InstitutionCommandResponse.prototype.hasInstitution = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.repeatedFields_ = [18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.OrganizationInitCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.OrganizationInitCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownedByBusinessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    nationalLicensingDescription: jspb.Message.getFieldWithDefault(msg, 14, ""),
    fullAddress: jspb.Message.getFieldWithDefault(msg, 15, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 17, ""),
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    Wilqo_API_Users_InstitutionModels_pb.License.toObject, includeInstance),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    tollFreeNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    website: jspb.Message.getFieldWithDefault(msg, 22, ""),
    email: jspb.Message.getFieldWithDefault(msg, 23, ""),
    preferredStartContactHour: (f = msg.getPreferredStartContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preferredEndContactHour: (f = msg.getPreferredEndContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.OrganizationInitCommandRequest;
  return proto.wilqo.api.users.OrganizationInitCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.OrganizationInitCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnedByBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingDescription(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullAddress(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 18:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTollFreeNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredStartContactHour(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredEndContactHour(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.OrganizationInitCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.OrganizationInitCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnedByBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNationalLicensingDescription();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFullAddress();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTollFreeNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPreferredStartContactHour();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreferredEndContactHour();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owned_by_business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getOwnedByBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setOwnedByBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address_additional_line_text = 5;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city_name = 6;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_code = 7;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country_name = 8;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string plus_four_zip_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state_code = 11;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string state_name = 12;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string national_licensing_id = 13;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string national_licensing_description = 14;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getNationalLicensingDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setNationalLicensingDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string full_address = 15;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setFullAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string external_id = 16;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string legal_entity_indicator = 17;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated License licenses = 18;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 18));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
*/
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * optional string phone_number = 19;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string toll_free_number = 20;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getTollFreeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setTollFreeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fax_number = 21;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string website = 22;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string email = 23;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional google.protobuf.Timestamp preferred_start_contact_hour = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getPreferredStartContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
*/
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setPreferredStartContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.clearPreferredStartContactHour = function() {
  return this.setPreferredStartContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.hasPreferredStartContactHour = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Timestamp preferred_end_contact_hour = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getPreferredEndContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
*/
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setPreferredEndContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.clearPreferredEndContactHour = function() {
  return this.setPreferredEndContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.hasPreferredEndContactHour = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string business_process_domain_id = 26;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationInitCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationInitCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.OrganizationUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    nationalLicensingDescription: jspb.Message.getFieldWithDefault(msg, 14, ""),
    fullAddress: jspb.Message.getFieldWithDefault(msg, 15, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 17, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    tollFreeNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    website: jspb.Message.getFieldWithDefault(msg, 21, ""),
    email: jspb.Message.getFieldWithDefault(msg, 22, ""),
    preferredStartContactHour: (f = msg.getPreferredStartContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preferredEndContactHour: (f = msg.getPreferredEndContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.OrganizationUpdateCommandRequest;
  return proto.wilqo.api.users.OrganizationUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingDescription(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullAddress(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTollFreeNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredStartContactHour(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredEndContactHour(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.OrganizationUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPreferredStartContactHour();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreferredEndContactHour();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearDisplayName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasDisplayName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setFullName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearFullName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasFullName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string address_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setAddressLineText = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearAddressLineText = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasAddressLineText = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string address_additional_line_text = 5;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearAddressAdditionalLineText = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasAddressAdditionalLineText = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string city_name = 6;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setCityName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearCityName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasCityName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string country_code = 7;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearCountryCode = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasCountryCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string country_name = 8;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setCountryName = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearCountryName = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasCountryName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearPostalCode = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string plus_four_zip_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearPlusFourZipCode = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasPlusFourZipCode = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string state_code = 11;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearStateCode = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasStateCode = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string state_name = 12;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setStateName = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearStateName = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasStateName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string national_licensing_id = 13;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearNationalLicensingId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasNationalLicensingId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string national_licensing_description = 14;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getNationalLicensingDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setNationalLicensingDescription = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearNationalLicensingDescription = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasNationalLicensingDescription = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string full_address = 15;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setFullAddress = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearFullAddress = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasFullAddress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string external_id = 16;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearExternalId = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasExternalId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string legal_entity_indicator = 17;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearLegalEntityIndicator = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasLegalEntityIndicator = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string phone_number = 18;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearPhoneNumber = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string toll_free_number = 19;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getTollFreeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setTollFreeNumber = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearTollFreeNumber = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasTollFreeNumber = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string fax_number = 20;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setFaxNumber = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearFaxNumber = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasFaxNumber = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string website = 21;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setWebsite = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearWebsite = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasWebsite = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string email = 22;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp preferred_start_contact_hour = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getPreferredStartContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
*/
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setPreferredStartContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearPreferredStartContactHour = function() {
  return this.setPreferredStartContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasPreferredStartContactHour = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp preferred_end_contact_hour = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getPreferredEndContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
*/
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setPreferredEndContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.clearPreferredEndContactHour = function() {
  return this.setPreferredEndContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.hasPreferredEndContactHour = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string business_process_domain_id = 25;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.OrganizationUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.OrganizationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.OrganizationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse}
 */
proto.wilqo.api.users.OrganizationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.OrganizationCommandResponse;
  return proto.wilqo.api.users.OrganizationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.OrganizationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse}
 */
proto.wilqo.api.users.OrganizationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.OrganizationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.OrganizationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse} returns this
*/
proto.wilqo.api.users.OrganizationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse} returns this
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional OrganizationSummary organization = 3;
 * @return {?proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.getOrganization = function() {
  return /** @type{?proto.wilqo.api.users.OrganizationSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary, 3));
};


/**
 * @param {?proto.wilqo.api.users.OrganizationSummary|undefined} value
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse} returns this
*/
proto.wilqo.api.users.OrganizationCommandResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.OrganizationCommandResponse} returns this
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationCommandResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ownedByInstitutionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest;
  return proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnedByInstitutionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwnedByInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string short_description = 2;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string owned_by_institution_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.getOwnedByInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.setOwnedByInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainInitCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest;
  return proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_description = 4;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest;
  return proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string organization_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.AddOrganizationsToBusinessProcessDomainsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest;
  return proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string organization_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrganizationsFromBusinessProcessDomainsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomainCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessDomain: (f = msg.getBusinessProcessDomain()) && Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomainCommandResponse;
  return proto.wilqo.api.users.BusinessProcessDomainCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.deserializeBinaryFromReader);
      msg.setBusinessProcessDomain(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomainCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomain();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} returns this
*/
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessDomainSummary business_process_domain = 3;
 * @return {?proto.wilqo.api.users.BusinessProcessDomainSummary}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.getBusinessProcessDomain = function() {
  return /** @type{?proto.wilqo.api.users.BusinessProcessDomainSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.BusinessProcessDomainSummary, 3));
};


/**
 * @param {?proto.wilqo.api.users.BusinessProcessDomainSummary|undefined} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} returns this
*/
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.setBusinessProcessDomain = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.clearBusinessProcessDomain = function() {
  return this.setBusinessProcessDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.hasBusinessProcessDomain = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainCommandResponse} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainCommandResponse.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddLicenseInformationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licenseDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    licenseIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddLicenseInformationCommandRequest;
  return proto.wilqo.api.users.AddLicenseInformationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseIdentifier(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddLicenseInformationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicenseDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLicenseIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string license_description = 2;
 * @return {string}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.getLicenseDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.setLicenseDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organization_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string license_identifier = 4;
 * @return {string}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.getLicenseIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.setLicenseIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AddLicenseInformationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    licenseInformation: (f = msg.getLicenseInformation()) && Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AddLicenseInformationCommandResponse;
  return proto.wilqo.api.users.AddLicenseInformationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.LicenseInformation;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.deserializeBinaryFromReader);
      msg.setLicenseInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AddLicenseInformationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInformation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} returns this
*/
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicenseInformation license_information = 2;
 * @return {?proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.getLicenseInformation = function() {
  return /** @type{?proto.wilqo.api.users.LicenseInformation} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.LicenseInformation, 2));
};


/**
 * @param {?proto.wilqo.api.users.LicenseInformation|undefined} value
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} returns this
*/
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.setLicenseInformation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.AddLicenseInformationCommandResponse} returns this
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.clearLicenseInformation = function() {
  return this.setLicenseInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.AddLicenseInformationCommandResponse.prototype.hasLicenseInformation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    licenseDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    licenseIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateLicenseInformationCommandRequest;
  return proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseIdentifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLicenseDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLicenseIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string license_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getLicenseDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setLicenseDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string license_identifier = 5;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getLicenseIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setLicenseIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    licenseInformation: (f = msg.getLicenseInformation()) && Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateLicenseInformationCommandResponse;
  return proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.LicenseInformation;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.deserializeBinaryFromReader);
      msg.setLicenseInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInformation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.LicenseInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LicenseInformation license_information = 2;
 * @return {?proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.getLicenseInformation = function() {
  return /** @type{?proto.wilqo.api.users.LicenseInformation} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.LicenseInformation, 2));
};


/**
 * @param {?proto.wilqo.api.users.LicenseInformation|undefined} value
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.setLicenseInformation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateLicenseInformationCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.clearLicenseInformation = function() {
  return this.setLicenseInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateLicenseInformationCommandResponse.prototype.hasLicenseInformation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertUserLicenseCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    license: (f = msg.getLicense()) && Wilqo_API_Users_InstitutionModels_pb.License.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertUserLicenseCommandRequest;
  return proto.wilqo.api.users.UpsertUserLicenseCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertUserLicenseCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional License license = 2;
 * @return {?proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.getLicense = function() {
  return /** @type{?proto.wilqo.api.users.License} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 2));
};


/**
 * @param {?proto.wilqo.api.users.License|undefined} value
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} returns this
*/
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.setLicense = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertUserLicenseCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertUserLicenseCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertUserLicenseCommandResponse;
  return proto.wilqo.api.users.UpsertUserLicenseCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertUserLicenseCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse} returns this
*/
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertUserLicenseCommandResponse} returns this
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertUserLicenseCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveUserLicenseCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveUserLicenseCommandRequest;
  return proto.wilqo.api.users.RemoveUserLicenseCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveUserLicenseCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveUserLicenseCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveUserLicenseCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveUserLicenseCommandResponse;
  return proto.wilqo.api.users.RemoveUserLicenseCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveUserLicenseCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.RemoveUserLicenseCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.RemoveUserLicenseCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    license: (f = msg.getLicense()) && Wilqo_API_Users_InstitutionModels_pb.License.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertOrgLicenseCommandRequest;
  return proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional License license = 2;
 * @return {?proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.getLicense = function() {
  return /** @type{?proto.wilqo.api.users.License} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 2));
};


/**
 * @param {?proto.wilqo.api.users.License|undefined} value
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} returns this
*/
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.setLicense = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertOrgLicenseCommandResponse;
  return proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse} returns this
*/
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertOrgLicenseCommandResponse} returns this
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertOrgLicenseCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveOrgLicenseCommandRequest;
  return proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandRequest} returns this
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.RemoveOrgLicenseCommandResponse;
  return proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse} returns this
*/
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.RemoveOrgLicenseCommandResponse} returns this
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.RemoveOrgLicenseCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.DeactivateOrganizationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.DeactivateOrganizationCommandRequest;
  return proto.wilqo.api.users.DeactivateOrganizationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.DeactivateOrganizationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest} returns this
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandRequest} returns this
 */
proto.wilqo.api.users.DeactivateOrganizationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.DeactivateOrganizationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.DeactivateOrganizationCommandResponse;
  return proto.wilqo.api.users.DeactivateOrganizationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.DeactivateOrganizationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse} returns this
*/
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.DeactivateOrganizationCommandResponse} returns this
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.DeactivateOrganizationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ArchiveOrganizationsCommandRequest;
  return proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string organization_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ArchiveOrganizationsCommandResponse;
  return proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse} returns this
*/
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ArchiveOrganizationsCommandResponse} returns this
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ArchiveOrganizationsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest;
  return proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string organization_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse;
  return proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse} returns this
*/
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse} returns this
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UnarchiveOrganizationsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leadSource: (f = msg.getLeadSource()) && Wilqo_API_Users_Models_pb.LeadSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest;
  return proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.LeadSource;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LeadSource.deserializeBinaryFromReader);
      msg.setLeadSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeadSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.LeadSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LeadSource lead_source = 2;
 * @return {?proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.getLeadSource = function() {
  return /** @type{?proto.wilqo.api.users.LeadSource} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LeadSource, 2));
};


/**
 * @param {?proto.wilqo.api.users.LeadSource|undefined} value
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} returns this
*/
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.setLeadSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest} returns this
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.clearLeadSource = function() {
  return this.setLeadSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandRequest.prototype.hasLeadSource = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse;
  return proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse} returns this
*/
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse} returns this
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpsertLeadSourceConfigCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    leadSource: (f = msg.getLeadSource()) && Wilqo_API_Users_Models_pb.LeadSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest;
  return proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.LeadSource;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.LeadSource.deserializeBinaryFromReader);
      msg.setLeadSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLeadSource();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.LeadSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LeadSource lead_source = 3;
 * @return {?proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.getLeadSource = function() {
  return /** @type{?proto.wilqo.api.users.LeadSource} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.LeadSource, 3));
};


/**
 * @param {?proto.wilqo.api.users.LeadSource|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} returns this
*/
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.setLeadSource = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.clearLeadSource = function() {
  return this.setLeadSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandRequest.prototype.hasLeadSource = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse;
  return proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLeadSourceCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanPartyRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest;
  return proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoanPartyRoleTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanPartyRoleTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AdminLoanPartyRoleTypesEnum loan_party_role_types = 3;
 * @return {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.getLoanPartyRoleTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.setLoanPartyRoleTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.addLoanPartyRoleTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest.prototype.clearLoanPartyRoleTypesList = function() {
  return this.setLoanPartyRoleTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse;
  return proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest;
  return proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string organization_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse;
  return proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserOrganizationsCommandResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest;
  return proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string borrower_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_email = 3;
 * @return {string}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.getBorrowerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.setBorrowerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    inviteUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse;
  return proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getInviteUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} returns this
*/
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string invite_url = 2;
 * @return {string}
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.getInviteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse} returns this
 */
proto.wilqo.api.users.CreateBorrowerInviteUrlCommandResponse.prototype.setInviteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    inviteId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest;
  return proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInviteId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string invite_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.getInviteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.setInviteId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest} returns this
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse;
  return proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse} returns this
*/
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse} returns this
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ExecuteBorrowerInviteUrlCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest;
  return proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} returns this
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} returns this
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest} returns this
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanOfficerQuestionnaireUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse;
  return proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerQuestionnaireUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanOfficerQuestionnaireUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} returns this
*/
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} returns this
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string loan_officer_questionnaire_url = 2;
 * @return {string}
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.getLoanOfficerQuestionnaireUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse} returns this
 */
proto.wilqo.api.users.GenerateLoanOfficerQuestionnaireUrlCommandResponse.prototype.setLoanOfficerQuestionnaireUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allowedOrgIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    allowedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest;
  return proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedOrgIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedUserIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllowedOrgIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAllowedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string allowed_org_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.getAllowedOrgIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.setAllowedOrgIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.addAllowedOrgIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.clearAllowedOrgIdsList = function() {
  return this.setAllowedOrgIdsList([]);
};


/**
 * repeated string allowed_user_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.getAllowedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.setAllowedUserIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.addAllowedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.clearAllowedUserIdsList = function() {
  return this.setAllowedUserIdsList([]);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse;
  return proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserLoanPermissionCommandResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allowAll: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    userNotificationPreferencesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest;
  return proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowAll(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserNotificationPreferences(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllowAll();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserNotificationPreferencesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool allow_all = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.getAllowAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.setAllowAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated UserNotificationType user_notification_preferences = 4;
 * @return {!Array<!proto.wilqo.api.users.UserNotificationType>}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.getUserNotificationPreferencesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserNotificationType>} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.setUserNotificationPreferencesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.users.UserNotificationType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.addUserNotificationPreferences = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.clearUserNotificationPreferencesList = function() {
  return this.setUserNotificationPreferencesList([]);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    user: (f = msg.getUser()) && Wilqo_API_Users_Models_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse;
  return proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional User user = 2;
 * @return {?proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.getUser = function() {
  return /** @type{?proto.wilqo.api.users.User} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Users_Models_pb.User, 2));
};


/**
 * @param {?proto.wilqo.api.users.User|undefined} value
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} returns this
*/
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse} returns this
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.UpdateUserNotificationPreferencesCommandResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.users);
