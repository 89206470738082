import { useParams } from 'react-router';

import { useActivityValidationChecklist } from '@/API/Queries/activities/useActivityValidationChecklist';

import { CompleteList } from '../../completeList';
import { useValidationProgress } from '../validationProgressContext';

const ValidationCompleteTab = () => {
  const { activityId = '' } = useParams();
  const { checklistProgress } = useValidationProgress();
  const { isLoading } = useActivityValidationChecklist(activityId);
  return (
    <CompleteList
      isLoading={isLoading}
      progress={checklistProgress}
      type="validation"
    />
  );
};

export { ValidationCompleteTab };
