import { useMutation } from '@tanstack/react-query';

import { ArchiveAdminLoanFeeTemplatesCommandRequest, ArchiveAdminLoanFeeTemplatesCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest';

export const useLoanFeeTemplateArchive = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation(async (ids: string[]) => {
    const request = new ArchiveAdminLoanFeeTemplatesCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setInstitutionId(institutionId);
    request.setLoanFeeTemplateIdsList(ids);
    return sendMessage<ArchiveAdminLoanFeeTemplatesCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      ArchiveAdminLoanFeeTemplatesCommandResponse,
    );
  });
};
