import type { MosaicWidgetContainerSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { Masonry } from '@/Components/Atoms/Masonry';

import { CardListItemWidget } from './CardListItemWidget';
import { HMDADenialReason } from './HMDA/HMDADenialReason';
import { useWidgetContext } from './WidgetContext';

const denialReasonWidgetId = 'SVoP278KLFpPGP68MP5XD';

export const MosaicContainer = (props: MosaicWidgetContainerSettings.AsObject) => {
  const { pageId, scopeToken } = useWidgetContext();
  const {
    cardListItemWidgetsList,
    fieldGroupingsMap,
    maxNumberOfColumns,
  } = props;

  return (
    <Masonry maxNumberOfColumns={maxNumberOfColumns}>
      {
        cardListItemWidgetsList.map((cardListItemWidget) => {
          if (cardListItemWidget.id === denialReasonWidgetId) {
            return (
              <HMDADenialReason
                key={`cardListItemWidget-${cardListItemWidget.id}-${cardListItemWidget.label}`}
                cardListItem={{ ...cardListItemWidget, numberOfColumns: 1 }}
                id={cardListItemWidget.id}
                validationMessagesList={[]}
              />
            );
          }

          return (
            <CardListItemWidget
              key={`cardListItemWidget-${cardListItemWidget.id}-${cardListItemWidget.label}`}
              allowPrompt
              cardListItem={{ ...cardListItemWidget, numberOfColumns: 1 }}
              fieldGrouping={fieldGroupingsMap.find(([id]) => id === cardListItemWidget.id)?.[1]}
              id={cardListItemWidget.id}
              ignoreContext
              validationMessagesList={[]}
            />
          );
        })
      }
      {props.cardListItemWidgetsList.map((cardListItem) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          key={`submit-mosaic-${cardListItem.id}`}
          data-additionalinfo={JSON.stringify({ pageId, scopeToken, type: 'cardListItem', widgetId: cardListItem.id, widgets: [{ cardListItem }] })}
          hidden
          id="submit-mosaic-widget"
          type="submit"
        />
      ))}
    </Masonry>
  );
};
