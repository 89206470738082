import { useGetFile } from '@/API/Queries/other/useGetFile';

import { Icon } from './Icon';

interface IDocumentViewProps {
  title: string;
  path?: string;
}

const DocumentView = (props: IDocumentViewProps) => {
  const { mutate: getDocument } = useGetFile();
  const { path = '', title } = props;

  const handleOpenDOcument = () => {
    getDocument(path, {
      onSuccess: (url) => {
        window.open(url, '_blank')?.focus();
      },
    });
  };

  return (
    <button
      className="flex items-center text-hyperlink-unclicked cursor-pointer text-sm leading-5 no-underline m-0 tracking-[0.25px] font-one"
      data-testid="document-view"
      onClick={handleOpenDOcument}
      type="button"
    >
      <Icon
        className="text-on-surface-active text-xl mr-2"
        icon="OpenInNew"
      />
      {title}
    </button>
  );
};

export { DocumentView };
