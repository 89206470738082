// source: Wilqo.API.Mortgage.DynamicData/Circuit/CircuitPage.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb = require('../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/CircuitComponent_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb = require('../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitRule_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb = require('../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitRoute_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb = require('../../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitSideEffect_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitPage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CircuitPage.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CircuitPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CircuitPage.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CircuitPage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.repeatedFields_ = [2,3,4,5,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CircuitPage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alwaysSave: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    componentsList: jspb.Message.toObjectList(msg.getComponentsList(),
    Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb.CircuitComponent.toObject, includeInstance),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb.CircuitRule.toObject, includeInstance),
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb.CircuitRoute.toObject, includeInstance),
    conditionalAsyncSubmitConfigsList: jspb.Message.toObjectList(msg.getConditionalAsyncSubmitConfigsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.DynamicFieldBehavior.toObject, includeInstance),
    sideEffectsList: jspb.Message.toObjectList(msg.getSideEffectsList(),
    Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb.CircuitSideEffect.toObject, includeInstance),
    panelElementConditionalOverridesList: jspb.Message.toObjectList(msg.getPanelElementConditionalOverridesList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldPanelElementOverrides.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CircuitPage;
  return proto.wilqo.api.mortgage_dynamic_data.CircuitPage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysSave(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb.CircuitComponent;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb.CircuitComponent.deserializeBinaryFromReader);
      msg.addComponents(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb.CircuitRule;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb.CircuitRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb.CircuitRoute;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb.CircuitRoute.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    case 5:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.DynamicFieldBehavior;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.DynamicFieldBehavior.deserializeBinaryFromReader);
      msg.addConditionalAsyncSubmitConfigs(value);
      break;
    case 6:
      var value = new Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb.CircuitSideEffect;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb.CircuitSideEffect.deserializeBinaryFromReader);
      msg.addSideEffects(value);
      break;
    case 8:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldPanelElementOverrides;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldPanelElementOverrides.deserializeBinaryFromReader);
      msg.addPanelElementConditionalOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CircuitPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlwaysSave();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getComponentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb.CircuitComponent.serializeBinaryToWriter
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb.CircuitRule.serializeBinaryToWriter
    );
  }
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb.CircuitRoute.serializeBinaryToWriter
    );
  }
  f = message.getConditionalAsyncSubmitConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.DynamicFieldBehavior.serializeBinaryToWriter
    );
  }
  f = message.getSideEffectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb.CircuitSideEffect.serializeBinaryToWriter
    );
  }
  f = message.getPanelElementConditionalOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldPanelElementOverrides.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool always_save = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getAlwaysSave = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setAlwaysSave = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated CircuitComponent components = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getComponentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitComponent_CircuitComponent_pb.CircuitComponent, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setComponentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addComponents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.CircuitComponent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearComponentsList = function() {
  return this.setComponentsList([]);
};


/**
 * repeated CircuitRule rules = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRule>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRule_pb.CircuitRule, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRule>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitRule}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.CircuitRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * repeated CircuitRoute routes = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRoute>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRoute>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitRoute_pb.CircuitRoute, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitRoute>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitRoute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitRoute}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.CircuitRoute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearRoutesList = function() {
  return this.setRoutesList([]);
};


/**
 * repeated DynamicFieldBehavior conditional_async_submit_configs = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getConditionalAsyncSubmitConfigsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.DynamicFieldBehavior, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setConditionalAsyncSubmitConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addConditionalAsyncSubmitConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.DynamicFieldBehavior, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearConditionalAsyncSubmitConfigsList = function() {
  return this.setConditionalAsyncSubmitConfigsList([]);
};


/**
 * repeated CircuitSideEffect side_effects = 6;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getSideEffectsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb.CircuitSideEffect, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setSideEffectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addSideEffects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearSideEffectsList = function() {
  return this.setSideEffectsList([]);
};


/**
 * repeated FieldPanelElementOverrides panel_element_conditional_overrides = 8;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.getPanelElementConditionalOverridesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldPanelElementOverrides, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.setPanelElementConditionalOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.addPanelElementConditionalOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldPanelElementOverrides, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitPage.prototype.clearPanelElementConditionalOverridesList = function() {
  return this.setPanelElementConditionalOverridesList([]);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
