import { useQuery } from '@tanstack/react-query';

import { GetIntegrationRunResponseDataQueryRequest, GetIntegrationRunResponseDataQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetIntegrationRunResponseDataQueryRequest';

const useIntegrationRunResponse = (trackId: string) => {
  const sendMessage = useWilqoMessage();

  return useQuery([MESSAGE_NAME, trackId], async () => {
    const request = new GetIntegrationRunResponseDataQueryRequest();
    request.setTrackId(trackId);
    const response = await sendMessage<GetIntegrationRunResponseDataQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetIntegrationRunResponseDataQueryResponse,
    );
    return {
      responseObject: response.responseObject,
      responseType: response.responseType,
    };
  },
  {
    enabled: Boolean(trackId),
  });
};
export { useIntegrationRunResponse };
