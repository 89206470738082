import { Outlet, useNavigate, useParams } from 'react-router';

import { useBusinessProcessTemplate } from '@/API/Queries/businessProcess/useBusinessProcessTemplate';
import { usePublishBusinessProcessTemplate } from '@/API/Queries/businessProcess/usePublishBusinessProcessTemplate';
import { Button } from '@/Components/Atoms/Button';
import { ContextualAppBar } from '@/Components/Atoms/ContextualAppBar';
import { Tabs } from '@/Components/Atoms/Tab';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const BusinessProcessTemplatePage = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  const { data, isLoading } = useBusinessProcessTemplate(id);
  const { isLoading: isPublishing, mutate: publishBPTemplate } = usePublishBusinessProcessTemplate();

  const handlePublishClick = () => {
    publishBPTemplate(
      id,
      {
        onError: (error: any) => showSnackBar({ message: error?.message || 'Something went wrong', open: true }),
        onSuccess: () => {
          navigate(-1);
          showSnackBar({ message: 'Business Process Template Published', open: true });
        },
      },
    );
  };

  return (
    <div className="flex flex-col">
      <ContextualAppBar
        icon="Clear"
        loading={isLoading}
        onClickIcon={() => navigate(-1)}
        title={data?.title}
      >
        <Button isLoading={isPublishing} label="Publish" onClick={handlePublishClick} />
      </ContextualAppBar>
      <Tabs
        tabsData={[
          { label: 'Details', linkTo: `/admin/businessProcessTemplate/${id}/details` },
          { label: 'Trigger', linkTo: `/admin/businessProcessTemplate/${id}/trigger` },
        ]}
      />
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
};
