import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useIdToken } from '@/API/Queries/mortgage/useIdToken';
import { useUser } from '@/API/Queries/user/useUser';
import { hasRole } from '@/Utils/Helpers/authHelpers';

import { Consumer } from './Roles';

interface IAuthContextProps {
  authenticated: boolean;
  isConsumer: boolean;
  isLoadingWilqoAuth: boolean;
  login: (redirectUri: string, state?: any) => void;
  signup: (redirectUri: string, state?: any, email?: string) => void;
  logout: () => void;
  secureAuthValues: SecureAuthValues;
  withAccessToken: () => Promise<string>;
  manualTokenUpdate: (token: string) => void;
}

const initialState: IAuthContextProps = {
  authenticated: false,
  isConsumer: true,
  isLoadingWilqoAuth: true,
  login: () => null,
  logout: () => null,
  manualTokenUpdate: () => null,
  secureAuthValues: {
    authorizedBusinessProcessDomainIds: [],
    authorizedOrganizationIds: [],
    clientId: '',
    currentOrganizationId: '',
    displayName: '',
    email: '',
    institutionId: '',
    roleMask: '',
    roles: [],
    sessionId: '',
    userId: '',
  },
  signup: () => null,
  withAccessToken: () => new Promise<string>((success) => { success(''); }),
};

export const AuthContext = createContext<IAuthContextProps>(initialState);
AuthContext.displayName = 'useAuthContext';

interface SecureAuthValues {
  authorizedBusinessProcessDomainIds: string[];
  authorizedOrganizationIds: string[];
  clientId: string;
  institutionId: string;
  roleMask: string;
  roles: string[];
  email: string;
  displayName: string;
  sessionId: string;
  currentOrganizationId: string;
  userId: string;
}

interface IAuthProps {
  children: React.ReactNode;
}

export const AuthProvider = (props: IAuthProps) => {
  const [secureAuthValues, setSecureAuthValues] = useState<SecureAuthValues>(initialState.secureAuthValues);
  const [questionnareToken, setQuestionnaireToken] = useState('');
  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated: isAuth0Authenticated,
    loginWithRedirect: auth0Login,
    logout: auth0Logout,
  } = useAuth0();
  const { children } = props;
  const [counter, setCounter] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const { data: userData } = useUser(hasRole(Consumer, secureAuthValues.roleMask) ? secureAuthValues.userId : '');
  const [isFullyAuthenticated, setIsFullyAuthenticated] = useState(false);
  const { isLoading: isLoadingWilqoAuth, mutate: exchangeToken } = useIdToken();
  const displayName = 'http://wilqo.com/display_name';

  const exchange = useCallback(async () => {
    if (!isAuth0Authenticated) {
      return;
    }

    const idToken = await getIdTokenClaims();

    if (idToken == null) {
      return;
    }

    await new Promise<void>((r) => {
      const authenticated = (authed: boolean) => {
        setIsFullyAuthenticated(authed);
        if (authed && idToken) {
          setSecureAuthValues({
            authorizedBusinessProcessDomainIds: idToken['http://wilqo.com/authorized_bpd_ids'],
            authorizedOrganizationIds: idToken['http://wilqo.com/authorized_org_ids'],
            clientId: idToken['http://wilqo.com/client_id'],
            currentOrganizationId: idToken['http://wilqo.com/org_id'],
            displayName: idToken[displayName],
            email: idToken.email as string,
            institutionId: idToken['http://wilqo.com/institution_id'],
            roleMask: idToken['http://wilqo.com/role_mask'],
            roles: idToken['http://wilqo.com/roles'],
            sessionId: idToken['http://wilqo.com/session_id'],
            userId: idToken['http://wilqo.com/id'],
          });
        }
        r();
      };

      // eslint-disable-next-line no-underscore-dangle
      exchangeToken(idToken.__raw, {
        onError: () => authenticated(false),
        onSuccess: () => authenticated(true),
      });
    });
  }, [isAuth0Authenticated, getIdTokenClaims, exchangeToken]);

  useEffect(() => {
    exchange();
  }, [exchange]);

  useEffect(() => {
    if (userData) {
      sessionStorage.setItem('loanId', userData.associatedDealsList[0]);
    }
  }, [isAuth0Authenticated, secureAuthValues, userData]);

  const fetchData = useCallback(async () => {
    if (secureAuthValues) {
      const idTokenRetry = await getIdTokenClaims();
      if (!idTokenRetry) {
        return;
      }

      setSecureAuthValues({
        ...secureAuthValues,
        displayName: idTokenRetry[displayName],
      });
    }
  }, [getIdTokenClaims, secureAuthValues]);

  const fetchDisplayName = useCallback(async () => {
    setCounter((prevCounter) => prevCounter + 1);
    if (counter === 4) clearInterval(intervalId as number);
    await fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, fetchData]);

  useEffect(() => {
    if (!secureAuthValues.displayName) {
      const interval: any = setInterval(fetchDisplayName, 4000);
      setIntervalId(interval);
      return () => clearInterval(interval);
    } return undefined;
  }, [fetchDisplayName, secureAuthValues.displayName]);

  const cleanUpStorage = () => {
    localStorage.clear();
    sessionStorage.removeItem('loanId');
    sessionStorage.removeItem('skillId');
    sessionStorage.removeItem('selectedOriginator');
  };

  const logout = () => {
    cleanUpStorage();
    auth0Logout({
      returnTo: `${window.location.protocol}//${window.location.host}/mortgage`,
    });
  };

  const manualTokenUpdate = useCallback((newToken: string) => {
    setQuestionnaireToken(newToken);
  }, []);

  const handleLogin = (redirectUri: string, state: any) => {
    const appState = {
      returnTo: null,
    };
    let fragment = null;

    if (state) {
      appState.returnTo = state.returnTo;
      if (state.roleMask) {
        fragment = state.roleMask;
      }
    }

    auth0Login({
      appState,
      fragment,
      redirectUri,
    });
  };

  const handleSignup = (redirectUri: string, state?: any, email = '') => {
    cleanUpStorage();
    auth0Login({ appState: state, display: 'popup', login_hint: email, redirectUri, screen_hint: 'signup' });
  };

  const withAccessToken = async (): Promise<string> => {
    if (questionnareToken) return questionnareToken;
    return getAccessTokenSilently();
  };

  return (
    <AuthContext.Provider value={{
      authenticated: isFullyAuthenticated,
      isConsumer: secureAuthValues.roles.indexOf('Consumer') >= 0,
      isLoadingWilqoAuth,
      login: handleLogin,
      logout,
      manualTokenUpdate,
      secureAuthValues,
      signup: handleSignup,
      withAccessToken,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export const useBPDId = () => useContext(AuthContext).secureAuthValues.authorizedBusinessProcessDomainIds[0];
export const useInstitutionId = () => useContext(AuthContext).secureAuthValues.institutionId;
