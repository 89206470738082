import clsx from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

export type TypographyVariant = 'body1' | 'body1Bold' | 'body2' | 'body2Bold' | 'button' | 'caption' | 'captionBold' | 'display1' | 'display2' | 'display3' | 'display4' | 'display5' | 'display6' | 'overline' | 'subtitle1' | 'subtitle1Bold' | 'subtitle2' | 'subtitle2Bold';

export interface TypographyProps {
  className?: string;
  children?: ReactNode;
  variant: TypographyVariant;
}

const FORM_NORMAL = 'font-normal';
const FORM_SEMIBOLD = 'font-semibold';
const BODY1_BASE = 'block !text-body1Size leading-body1Height tracking-body1Spacing';
const BODY2_BASE = 'block !text-body2Size leading-body2Height tracking-body2Spacing';
const SUBTITLE1_BASE = 'block !text-subtitle1Size leading-subtitle1Height tracking-subtitle1Spacing';
const SUBTITLE2_BASE = 'block !text-subtitle2Size leading-subtitle2Height tracking-subtitle2Spacing';
const CAPTION_BASE = 'block !text-captionSize leading-captionHeight tracking-captionSpacing';

const Typography = forwardRef((props: TypographyProps, ref: any) => {
  const { children, className, variant, ...rest } = props;

  switch (variant) {
    case 'body1':
      return <span {...rest} ref={ref} className={clsx(BODY1_BASE, FORM_NORMAL, className)}>{children}</span>;
    case 'body1Bold':
      return <span {...rest} ref={ref} className={clsx(BODY1_BASE, FORM_SEMIBOLD, className)}>{children}</span>;
    case 'body2':
      return <span {...rest} ref={ref} className={clsx(BODY2_BASE, FORM_NORMAL, className)}>{children}</span>;
    case 'body2Bold':
      return <span {...rest} ref={ref} className={clsx(BODY2_BASE, FORM_SEMIBOLD, className)}>{children}</span>;
    case 'display1':
      return <h1 {...rest} ref={ref} className={clsx('font-one font-semibold !text-display1Size leading-display1Height tracking-display1Spacing', className)}>{children}</h1>;
    case 'display2':
      return <h2 {...rest} ref={ref} className={clsx('font-two font-semibold !text-display2Size leading-display2Height tracking-display2Spacing', className)}>{children}</h2>;
    case 'display3':
      return <h3 {...rest} ref={ref} className={clsx('font-three font-semibold !text-display3Size leading-display3Height tracking-display3Spacing', className)}>{children}</h3>;
    case 'display4':
      return <h4 {...rest} ref={ref} className={clsx('font-four font-bold !text-display4Size leading-display4Height tracking-display4Spacing', className)}>{children}</h4>;
    case 'display5':
      return <h5 {...rest} ref={ref} className={clsx('font-five font-bold !text-display5Size leading-display5Height tracking-display5Spacing', className)}>{children}</h5>;
    case 'display6':
      return <h6 {...rest} ref={ref} className={clsx('font-six font-bold !text-display6Size leading-display6Height tracking-display6Spacing', className)}>{children}</h6>;
    case 'subtitle1':
      return <span {...rest} ref={ref} className={clsx(SUBTITLE1_BASE, FORM_NORMAL, className)}>{children}</span>;
    case 'subtitle1Bold':
      return <span {...rest} ref={ref} className={clsx(SUBTITLE1_BASE, FORM_SEMIBOLD, className)}>{children}</span>;
    case 'subtitle2':
      return <span {...rest} ref={ref} className={clsx(SUBTITLE2_BASE, FORM_NORMAL, className)}>{children}</span>;
    case 'subtitle2Bold':
      return <span {...rest} ref={ref} className={clsx(SUBTITLE2_BASE, FORM_SEMIBOLD, className)}>{children}</span>;
    case 'caption':
      return <span {...rest} ref={ref} className={clsx(FORM_NORMAL, CAPTION_BASE, className)}>{children}</span>;
    case 'captionBold':
      return <span {...rest} ref={ref} className={clsx(FORM_SEMIBOLD, CAPTION_BASE, className)}>{children}</span>;
    case 'overline':
      return <span {...rest} ref={ref} className={clsx('font-normal !text-overlineSize leading-overlineHeight tracking-overlineSpacing uppercase', className)}>{children}</span>;
    case 'button':
      return <span {...rest} ref={ref} className={clsx('font-bold !text-[14px] leading-[16px] tracking-[1.25px] uppercase', className)}>{children}</span>;
    default:
      return <span {...props} ref={ref}>{children}</span>;
  }
});

export { Typography };
