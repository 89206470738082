// source: Wilqo.Shared.Models/UIComponents/ApplicationAction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.models.ApplicationAction', null, global);
/**
 * @enum {number}
 */
proto.wilqo.shared.models.ApplicationAction = {
  APPLICATION_ACTION_UNKNOWN: 0,
  APPLICATION_ACTION_URLA: 1,
  APPLICATION_ACTION_SEND_INVITE: 2,
  APPLICATION_ACTION_PRODUCT: 3,
  APPLICATION_ACTION_PRE_QUALIFICATION: 4,
  APPLICATION_ACTION_CREDIT: 5,
  APPLICATION_ACTION_ADD_PARTY: 6,
  APPLICATION_ACTION_GO_TO_NEW_LOAN: 7
};

goog.object.extend(exports, proto.wilqo.shared.models);
