import { useQuery } from '@tanstack/react-query';

import { GetBusinessProcessTemplateDesignListQueryRequest, GetBusinessProcessTemplateDesignListQueryResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Queries_pb';
import { PaginationConfig } from '@/API/Models/Wilqo.Shared.Models/PaginationModels_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.business_process.GetBusinessProcessTemplateDesignListQueryRequest';

export const useBusinessProcessTemplates = (
  requestObj: Omit<GetBusinessProcessTemplateDesignListQueryRequest.AsObject, 'businessProcessDomainId' | 'institutionId'>,
) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useQuery([MESSAGE_NAME, bpdId, institutionId, requestObj], async () => {
    const request = new GetBusinessProcessTemplateDesignListQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    if (requestObj.searchTerm) request.setSearchTerm(requestObj.searchTerm);
    request.setInstitutionId(institutionId);
    const paging = new PaginationConfig();
    paging.setCurrentPage(requestObj.pageConfig?.currentPage || 0);
    paging.setPageLength(requestObj.pageConfig?.pageLength || 0);
    if (requestObj.templateTypesList.length > 0) request.setTemplateTypesList(requestObj.templateTypesList);
    if (requestObj.pageConfig?.sortAscending !== undefined) {
      paging.setSortAscending(requestObj.pageConfig?.sortAscending);
    }
    if (requestObj.pageConfig?.sortField !== undefined) {
      paging.setSortField(requestObj.pageConfig.sortField);
    }
    request.setPageConfig(paging);
    request.setShowArchived(requestObj.showArchived);
    if (requestObj.filterTemplatesAllowingConcurrentInstances !== undefined) {
      request.setFilterTemplatesAllowingConcurrentInstances(requestObj.filterTemplatesAllowingConcurrentInstances);
    }
    if (requestObj.filterTemplatesAllowingMultipleInstances !== undefined) {
      request.setFilterTemplatesAllowingMultipleInstances(requestObj.filterTemplatesAllowingMultipleInstances);
    }
    const response = await sendMessage<GetBusinessProcessTemplateDesignListQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetBusinessProcessTemplateDesignListQueryResponse,
    );
    return { itemsList: response.itemsList, pageDetails: response.pageDetails };
  });
};
