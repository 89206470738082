import { useCallback, useState } from 'react';

import { SectionAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb';
import type { GroupedTableFormWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { useDynamicLoanPage } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { Card } from '@/Components/Atoms/Card/Card';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import { DynamicDialog } from './DynamicDialog';
import { ScopeSelector } from './ScopeSelector';
import { TableCollection } from './TableForm/TableCollection';

export const GroupedTable = (props: LoanPageWidget.AsObject & { groupedTableForm: GroupedTableFormWidgetSettings.AsObject }) => {
  const { groupedTableForm } = props;
  const { helpText = 'Help Text', newItemPageId, newItemScopeToken, newItemVisible, tablesSettingsList, title } = groupedTableForm;

  const [open, setOpen] = useState(false);
  const [selectedScopeToken, setSelectedScopeToken] = useState(newItemScopeToken);
  const { loanId } = useDynamicLoanInfo();

  const { data: newItemPageData, isLoading } = useDynamicLoanPage(loanId, newItemPageId, selectedScopeToken, '', true);

  const handleNewItemClick = useCallback((scopeToken = '') => {
    setSelectedScopeToken(scopeToken);
    setOpen(true);
  }, []);

  const handleCloseClick = () => {
    setOpen(false);
    setSelectedScopeToken('');
  };

  return (
    <>
      <Card
        defaultOpen
        disableAccordion
        headerProps={{
          actions: (
            <ScopeSelector
              label={groupedTableForm.newItemLabel}
              newItemScopeToken={newItemScopeToken}
              newItemVisible={newItemVisible}
              onSelectItem={handleNewItemClick}
              settings={groupedTableForm.newItemAssociation}
            />
          ),
          open: true,
          subtitle: helpText,
          title,
        }}
        variant="borderless"
      >
        <div className="flex flex-col gap-4">
          {tablesSettingsList && tablesSettingsList.map((groupTable) => {
            if (!groupTable.table) return null;
            const { actionsList, title, ...restTable } = groupTable.table;
            const tableTitle = groupTable.groupTitleLabel
              ? `${groupTable.groupTitleLabel}: ${renderValue(groupTable.groupTitleValue, undefined, groupTable.groupTitlePanelElement)}`
              : `${renderValue(groupTable.groupTitleValue, undefined, groupTable.groupTitlePanelElement)}`;
            return (
              <TableCollection
                key={tableTitle}
                actionsList={[SectionAction.SECTION_ACTION_CANCEL, SectionAction.SECTION_ACTION_SAVE]}
                title={tableTitle}
                {...restTable}
              />
            );
          })}
        </div>
      </Card>
      {open && (
        <DynamicDialog
          editMode
          isEditable
          isLoading={isLoading}
          isNew
          isOpen
          onClose={handleCloseClick}
          page={newItemPageData?.page}
          scopeToken={selectedScopeToken}
        />
      )}
    </>
  );
};
