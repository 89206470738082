/* eslint-disable sonarjs/no-small-switch */
import { useCallback, useEffect, useRef } from 'react';

import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { useManualDocumentGeneration } from '@/API/Queries/mortgage/useManualDocumentGeneration';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';
import { useUnmount } from '@/Utils/Hooks/useUnmount';

export const useDynamicAction = () => {
  const { showSnackBar } = useShared();
  const { closeAction, currentAction } = useDynamicContext();

  const { mutate: generateDocument } = useManualDocumentGeneration();
  const hasCalledAction = useRef(false);
  const { loanId } = useDynamicLoanInfo();

  const clearActions = useCallback(() => {
    hasCalledAction.current = false;
    closeAction();
  }, [closeAction]);

  const generateDocumentAction = useCallback(() => {
    generateDocument(
      {
        dealId: loanId, docTypes: [currentAction?.additionalArgsMap?.find((map) => map[0] === 'doc_type')?.[1]],
      },
      {
        onError: (error) => showSnackBar({ message: error.message || 'Something went wrong', open: true }),
        onSettled: clearActions,
        onSuccess: () => showSnackBar({ message: 'Generating documents', open: true }),
      },
    );
  }, [currentAction, loanId, generateDocument, showSnackBar, clearActions]);

  useEffect(() => {
    if (!hasCalledAction.current && currentAction) {
      switch (currentAction?.type) {
        case LoanPageActionType.LOAN_PAGE_ACTION_TYPE_DOCUMENT_GENERATION:
          generateDocumentAction();
          break;
        default:
          break;
      }
    }
  }, [currentAction, generateDocumentAction]);

  useUnmount(() => {
    clearActions();
  }, [clearActions]);
};
