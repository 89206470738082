import type { OrganizationInitCommandRequest } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useCreateOrganization } from '@/API/Queries/user/useCreateOrganization';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { getPanel, getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface Props {
  onClose: () => void;
}

const CreateOrganization = (props: Props) => {
  const { onClose } = props;

  const { isLoading, mutate } = useCreateOrganization();
  const { showSnackBar } = useShared();
  const bpdId = useBPDId();

  const handleSubmit = (formData: DynamicDataElementValues) => {
    const organization: OrganizationInitCommandRequest.AsObject = {
      addressAdditionalLineText: '',
      addressLineText: '',
      businessProcessDomainId: bpdId,
      cityName: '',
      countryCode: 'US',
      countryName: 'United States',
      displayName: formData.name.value,
      email: '',
      externalId: formData.orgId.value,
      faxNumber: '',
      fullAddress: '',
      fullName: formData['legal-name'].value,
      legalEntityIndicator: '',
      licensesList: [],
      nationalLicensingDescription: '',
      nationalLicensingId: '',
      ownedByBusinessProcessDomainId: bpdId,
      phoneNumber: '',
      plusFourZipCode: '',
      postalCode: '',
      stateCode: '',
      stateName: '',
      tollFreeNumber: '',
      website: '',
    };
    mutate(organization, {
      onError: () => {
        showSnackBar({ message: 'Something wrong happened, try again later.', open: true });
      },
      onSuccess: () => {
        showSnackBar({ message: 'Organization successfully created', open: true });
        onClose();
      },
    });
  };

  return (
    <FullPageStepper
      close={onClose}
      handleSubmit={handleSubmit}
      headerAction={{
        label: 'Discard',
        leftIcon: 'Delete',
        onClick: onClose,
        variant: 'tertiary',
      }}
      openModal
      panels={[
        getPanel({
          headerText: 'Organization name',
          id: 'org-name',
          panelElementsList: [
            getPanelElement({
              headerText: 'Organization name',
              id: 'name',
              requirement: getPanelElementRequiment(),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'External Organization ID',
              id: 'orgId',
              requirement: getPanelElementRequiment(),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Organization Legal Name',
              id: 'legal-name',
              requirement: getPanelElementRequiment(),
              type: 'text',
            }),
          ],
          summaryText: 'What would you like to name this Organization',
        }),
      ]}
      saving={isLoading}
      title="Create new Organization"
    />
  );
};

export { CreateOrganization };
