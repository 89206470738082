import { FeesComparisonDetail, FeesComparisonFee, FeesComparisonSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toProtoDecimalFromObject } from './helper';

const toFeesComparisonDetailFromObject = (obj: FeesComparisonDetail.AsObject): FeesComparisonDetail => {
  const feesComparisonDetail = new FeesComparisonDetail();

  if (obj.current) feesComparisonDetail.setCurrent(toProtoDecimalFromObject(obj.current));
  if (obj.difference) feesComparisonDetail.setDifference(toProtoDecimalFromObject(obj.difference));
  if (obj.lastDisclosed) feesComparisonDetail.setLastDisclosed(toProtoDecimalFromObject(obj.lastDisclosed));
  if (obj.appliedCure) feesComparisonDetail.setAppliedCure(toProtoDecimalFromObject(obj.appliedCure));

  return feesComparisonDetail;
};

const toFeesComparisonFeeFromObject = (obj: FeesComparisonFee.AsObject): FeesComparisonFee => {
  const feesComparisonFee = new FeesComparisonFee();

  if (obj.detail) feesComparisonFee.setDetail(toFeesComparisonDetailFromObject(obj.detail));
  if (obj.feeId) feesComparisonFee.setFeeId(obj.feeId);
  if (obj.feeName) feesComparisonFee.setFeeName(obj.feeName);
  if (obj.paidTo) feesComparisonFee.setPaidTo(obj.paidTo);

  return feesComparisonFee;
};

export const toFeesComparisonSectionFromObject = (obj: FeesComparisonSection.AsObject): FeesComparisonSection => {
  const feesComparisonSection = new FeesComparisonSection();

  if (obj.feesList) {
    obj.feesList.forEach((fee) => {
      feesComparisonSection.addFees(toFeesComparisonFeeFromObject(fee));
    });
  }
  if (obj.requiredCure) feesComparisonSection.setRequiredCure(toProtoDecimalFromObject(obj.requiredCure));
  if (obj.sectionName) feesComparisonSection.setSectionName(obj.sectionName);
  if (obj.sectionType) feesComparisonSection.setSectionType(obj.sectionType);
  if (obj.total) feesComparisonSection.setTotal(toFeesComparisonDetailFromObject(obj.total));
  if (obj.totalCureApplied) feesComparisonSection.setTotalCureApplied(toProtoDecimalFromObject(obj.totalCureApplied));
  if (obj.totalDifference) feesComparisonSection.setTotalDifference(toProtoDecimalFromObject(obj.totalDifference));

  return feesComparisonSection;
};
