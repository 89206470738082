import { useMutation } from '@tanstack/react-query';

import type { UpdateUserLoanPartyRoleTypesCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { UpdateUserLoanPartyRoleTypesCommandRequest } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import type { AdminLoanPartyRoleTypesEnum } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import type { WilqoMessage } from '../useBackend';
import { useWilqoMessage } from '../useWilqoMessage';

interface UpdateObject {
  userId: string;
  loanRoleTypes: Array<AdminLoanPartyRoleTypesEnum>;
}

const MESSAGE_NAME = 'wilqo.api.users.UpdateUserLoanPartyRoleTypesCommandRequest';

const useUpdateUserPartyRoleType = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  const updateUserPartyRoleType = async (updateObj: UpdateObject) => {
    const request = new UpdateUserLoanPartyRoleTypesCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setLoanPartyRoleTypesList(updateObj.loanRoleTypes);
    request.setUserId(updateObj.userId);
    const wilqoMessage: WilqoMessage = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    return sendMessage<UpdateUserLoanPartyRoleTypesCommandResponse>(wilqoMessage, UpdateUserLoanPartyRoleTypesCommandRequest);
  };

  return useMutation(updateUserPartyRoleType);
};

export { useUpdateUserPartyRoleType };
