import { useQuery } from '@tanstack/react-query';

import { DefaultValueConfig } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/DefaultValueConfig_pb';
import { GetLoanPageDataQueryResponse, GetLoanPageDataWithDefaultValueConfigQueryRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPageQueries_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useWilqoMessage } from '../useWilqoMessage';

export const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.GetLoanPageDataWithDefaultValueConfigQueryRequest';

export const useDynamicPageWithDefaults = (
  dealId: string,
  pageId: string,
  scopeToken: string,
  tabId: string,
  withoutData: boolean,
  dynamicValue?: DynamicDataElement.AsObject,
  virtualId?: string,
  widgetType?: DefaultValueConfig.WidgetType,
  enabled = true,
) => {
  const sendMessage = useWilqoMessage();

  return useQuery([MESSAGE_NAME, dealId, pageId, scopeToken, tabId, withoutData, dynamicValue, virtualId, widgetType], async () => {
    const request = new GetLoanPageDataWithDefaultValueConfigQueryRequest();
    request.setDealId(dealId);
    request.setPageId(pageId);
    request.setScopeToken(scopeToken);
    request.setTabId(tabId);
    request.setWithoutData(withoutData);
    if (dynamicValue && widgetType) {
      const defaultValueConfig = new DefaultValueConfig();
      defaultValueConfig.setDefaultValue(DynamicDataElementMap(dynamicValue));
      defaultValueConfig.setVirtualFieldId(virtualId || '');
      defaultValueConfig.setSourceWidgetType(widgetType);
      request.setDefaultValueConfig(defaultValueConfig);
    }
    return sendMessage<GetLoanPageDataQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetLoanPageDataQueryResponse,
    );
  }, { enabled });
};
