import clsx from 'clsx';
import { get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type {
  LoanAvailableFeeInfo,
  LoanFee,
  LoanFeeCategory,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  FeeCalculationTypeEnum,
  FeePercentBasisEnum,
  IntegratedDisclosureSectionEnum,
  LoanFeeCategoryTypeEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useDeleteFee } from '@/API/Queries/mortgage/useDeleteFee';
import { useGetAvailableFee } from '@/API/Queries/mortgage/useGetAvailableFee';
import type { UseUpsertFeeDTO } from '@/API/Queries/mortgage/useUpsertFee';
import { useUpsertFee } from '@/API/Queries/mortgage/useUpsertFee';
import { Badge } from '@/Components/Atoms/Badge';
import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Input } from '@/Components/Atoms/Input';
import { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Menu } from '@/Components/Atoms/Menu';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import {
  canTotalBeEditedOnFeeType,
  ColumnEnum,
  emptyValue,
  FEES_COLUMNS,
  updateLoanFees,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import { ConvertEnum, getEnumFromParsedValue } from '@/Utils/Helpers/enumHelpers';
import { convertProtoToCurrency, convertProtoToPercentage, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import {
  ConvertNumberToProtoDecimal,
  ConvertProtoDecimalAsObjectToNumber,
  ConvertProtoDecimalToInstance,
} from '@/Utils/Helpers/protoDecimalConversion';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export interface FeeEditTotalsProps {
  onEditFee: (
    fee: LoanFee.AsObject,
    categoryType: LoanFeeCategoryTypeEnum,
    feeSectionType: IntegratedDisclosureSectionEnum
  ) => void;
}

interface ExtendedInterface extends LoanAvailableFeeInfo.AsObject {
  hidden: boolean;
}

const Fees = (props: FeeEditTotalsProps) => {
  const { onEditFee } = props;
  const [addingNewFee, setAddingNewFee] = useState<boolean>(false);
  const [feeToRemove, setFeeToRemove] = useState<string>('');
  const [feeToAddToAvailableFees, setFeeToAddToAvailableFees] = useState<any>({
    fee: undefined,
    index: undefined,
  });
  const { showSnackBar } = useShared();
  const { mutate: deleteFee } = useDeleteFee();
  const { mutate: upsertFee } = useUpsertFee();
  const { loanId = '' } = useParams();
  const {
    handleBlurInput,
    hasError,
    loanFees,
    totalMet,
  } = useFeesContext();

  const availableSections: string[] = [
    'Origination Charges',
    'Services You Cannot Shop For',
    'Services You Can Shop For',
    'Taxes And Other Government Fees',
    'Other',
  ];

  const availableFees = useGetAvailableFee({
    dealId: loanId,
    loanId: '',
    queries: availableSections.map((section: string) => ({
      sectionName: getEnumFromParsedValue(
        IntegratedDisclosureSectionEnum,
        section,
      ) || 0,
    })),
  });

  const handleDropDownFees = useCallback(() => {
    if (availableFees && feeToAddToAvailableFees.index >= 0) {
      const availablesFeesOnCategory = availableFees[feeToAddToAvailableFees.index];
      const hiddenFeeIndex = availablesFeesOnCategory?.data?.findIndex((fee: LoanAvailableFeeInfo.AsObject) => fee.adminFeeTemplateId === feeToAddToAvailableFees.fee.adminFeeTemplateId);
      if (hiddenFeeIndex !== -1) {
        const selectedFee: any = availableFees[feeToAddToAvailableFees.index];
        if (selectedFee.data && hiddenFeeIndex !== undefined) {
          selectedFee.data[hiddenFeeIndex].hidden = false;
        }
      } else {
        const feeObject = {
          ...feeToAddToAvailableFees.fee,
          adminFeeTemplateId: feeToAddToAvailableFees.fee.feeId,
          feeName: feeToAddToAvailableFees.fee.name,
        };
        if (availablesFeesOnCategory.data) {
          availablesFeesOnCategory?.data.push(feeObject);
        }
      }

      setFeeToAddToAvailableFees({});
    }
  }, [availableFees, feeToAddToAvailableFees.fee, feeToAddToAvailableFees.index]);

  useEffect(() => {
    handleDropDownFees();
  }, [availableFees, feeToAddToAvailableFees.index, feeToAddToAvailableFees.fee, handleDropDownFees]);

  const handleDeleteFee = useCallback((fee: LoanFee.AsObject, currentCategory: LoanFeeCategory.AsObject, currentCategoryIndex: number) => {
    deleteFee({
      dealId: loanId,
      feeId: fee.feeId,
      loanId: '',
    }, {
      onError() {
        showSnackBar({ message: 'Something went wrong', open: true });
      },
      onSuccess() {
        loanFees[currentCategoryIndex] = updateLoanFees(
          fee,
          currentCategory,
          'remove',
        );

        setFeeToAddToAvailableFees({
          fee,
          index: currentCategoryIndex,
        });
      },
    });
  }, [deleteFee, loanFees, loanId, showSnackBar]);

  const handleUpsertFee = useCallback((dto: Omit<UseUpsertFeeDTO, 'dealId' | 'loanId'>, fee: any, categoryIndex: number) => {
    upsertFee({
      dealId: loanId,
      loanId: '',
      ...dto,
    }, {
      onError() {
        // clear id to show in available fees
        setFeeToRemove('');
        showSnackBar({ message: 'Something went wrong', open: true });
      },
      onSuccess(loanFee) {
        loanFees[categoryIndex] = updateLoanFees(
          loanFee,
          loanFees[categoryIndex],
          'add',
        );

        // save id to not show in available fees
        setFeeToRemove(fee.adminFeeTemplateId);
      },
    });
  }, [loanId, showSnackBar, upsertFee, loanFees]);

  const cardButtons = useMemo(() => {
    const availableFeesLoaded = availableFees.every((fee) => fee.status === 'success');

    if (availableFeesLoaded) {
      const arr: any = [];
      availableFees.forEach(({ data }: any, index: number) => {
        const innerArr: any[] = [];

        data?.forEach((fee: ExtendedInterface) => {
          if (feeToRemove === fee.adminFeeTemplateId) {
            fee.hidden = true;
            setFeeToRemove('');
          }
          innerArr.push({
            ...fee,
            key: get(fee, 'adminFeeTemplateId'),
            label: get(fee, 'feeName'),
            leftIcon: 'Add',
            onClick: () => {
              handleUpsertFee({
                borrowerPaid: fee.borrowerPaid ? ConvertProtoDecimalToInstance(fee.borrowerPaid) : ConvertNumberToProtoDecimal(0),
                categoryType: LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE,
                feeId: fee.adminFeeTemplateId,
                feeName: fee.feeName,
                lenderPaid: fee.lenderPaid ? ConvertProtoDecimalToInstance(fee.lenderPaid) : ConvertNumberToProtoDecimal(0),
                otherPaid: fee.otherPaid ? ConvertProtoDecimalToInstance(fee.otherPaid) : ConvertNumberToProtoDecimal(0),
                sellerPaid: fee.sellerPaid ? ConvertProtoDecimalToInstance(fee.sellerPaid) : ConvertNumberToProtoDecimal(0),
                total: fee.total ? ConvertProtoDecimalToInstance(fee.total) : ConvertNumberToProtoDecimal(0),
              }, fee, index);
            },
          });
        });
        arr.push(innerArr);
      });
      return arr;
    }
    return [];
  }, [availableFees, handleUpsertFee, feeToRemove]);

  const isInputDisabled = (fee: LoanFee.AsObject, column: any, isBorrowerColumn: boolean) => {
    // Greyed out borrower column on manually added fees
    const isGreyedOutManualZeroBasis = isBorrowerColumn && fee.basedOn === 0;

    // Greyed out if there's an error
    const isGreyedOutTotalMetExceeded = fee.feeId !== totalMet.feeId && totalMet.exceeded;

    // Greyed out based on the hidden property of the column.
    const isGreyedOutByColumnHidden = column.hidden;

    // Greyed out if this fee is a special case
    const isFeeSpecialCase = fee.specialCase > 2;

    const inputStatusCheck = [
      isGreyedOutManualZeroBasis,
      isGreyedOutTotalMetExceeded,
      isBorrowerColumn,
      isGreyedOutByColumnHidden,
      isFeeSpecialCase,
      // Greyed out if the user is adding a new fee.
      addingNewFee,
    ];
    return inputStatusCheck.includes(true);
  };

  return (
    <div className="grid gap-y-6">
      {loanFees ? loanFees.map((loanFeeCategory: LoanFeeCategory.AsObject, currentCategoryIndex: number) => {
        const getCurrentItemCategory = (value: string, column: string, fee: LoanFee.AsObject) => handleBlurInput(value, { key: column }, fee, loanFeeCategory, undefined, undefined);

        return (
          <Card
            key={loanFeeCategory.category}
            defaultOpen
            headerProps={{
              actions: cardButtons ? cardButtons[currentCategoryIndex] : [],
              caption: 'Total',
              captionStrong: loanFeeCategory.sumTotal ? numberFormatter.format(ConvertProtoDecimalAsObjectToNumber(loanFeeCategory.sumTotal)) : '',
              dropdownAction: () => setAddingNewFee(true),
              headerActionStyle: 'dropdown',
              onBlurHandler: () => setAddingNewFee(false),
              title: loanFeeCategory.category,
            }}
            variant="border"
          >
            <table className="w-full">
              <thead className="border-b border-b-on-surface-stroke">
                <tr
                  key={loanFeeCategory.category}
                >
                  {FEES_COLUMNS.map((column, index) => (
                    <th
                      key={`${loanFeeCategory.category}-${column.id}`}
                      className={clsx(
                        'px-3 py-3 text-center border-r border-r-on-surface-stroke w-[215px]',
                        {
                          '!border-r-0': index === FEES_COLUMNS.length - 1,
                          '!text-left': index === 0,
                        },
                      )}
                      scope="col"
                    >
                      <Typography className="uppercase text-on-surface-inactive" variant="overline">
                        {column.header}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loanFeeCategory.feesList.map((fee: LoanFee.AsObject) => (
                  <tr
                    key={`${loanFeeCategory.category}-${fee.feeId}`}
                    className="transition duration-150 ease-in-out hover:bg-on-surface-states-hover"
                  >
                    {FEES_COLUMNS?.map((column) => {
                      const value = fee[column.id as keyof Omit<LoanFee.AsObject, 'feeId' | 'feeName' | 'isApr' | 'paidTo'>];
                      const isFeeSpecialCase = fee.specialCase > 2;

                      if (column.id === ColumnEnum.NAME) {
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="text-left px-3 py-4 border-r border-r-on-surface-stroke w-[215px] relative"
                          >
                            <div className="flex gap-3 items-center justify-start relative">
                              <div className="flex-shrink-0">
                                <button
                                  onClick={() => onEditFee && onEditFee(
                                    fee, LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE,
                                    loanFeeCategory.categoryType,
                                  )}
                                  type="button"
                                >
                                  <Typography
                                    className="text-primary-on-surface text-left max-w-[150px] break-words"
                                    variant="body2"
                                  >
                                    {fee.name}
                                  </Typography>
                                </button>
                                <Typography className="text-on-surface-inactive max-w-[150px] break-words" variant="caption">
                                  {`to ${fee.feePayment?.payeePartySummary?.fullName || fee.paidTo}`}
                                </Typography>
                              </div>
                              {
                              fee.isApr && (
                                <Badge
                                  label="apr"
                                  variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS}
                                />
                              )
                            }
                            </div>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.TOTAL) {
                        const feeInErrorArray = hasError(fee.feeId, LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE);
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 min-w-[200px] relative"
                          >
                            <div className="flex items-center justify-end gap-3">
                              {
                              ((totalMet.feeId === fee.feeId && totalMet.exceeded && totalMet.category === LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE) || feeInErrorArray) && (
                                <div>
                                  <Tooltip message={totalMet.message || 'Amount entered exceeds total'}>
                                    <Icon className="!text-danger" icon="Error" />
                                  </Tooltip>
                                </div>
                              )
                            }
                              <div className="flex-grow">
                                <Input
                                  disabled={isInputDisabled(fee, column, false) || !canTotalBeEditedOnFeeType(fee.basedOn)}
                                  displayOnly
                                  id={`input-${column.id}-${fee.feeId}`}
                                  mask={MaskType.CURRENCY}
                                  onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                                  value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                                />
                              </div>
                              <div className={clsx({
                                invisible: isFeeSpecialCase,
                              })}
                              >
                                <Menu
                                  options={[{
                                    icon: 'Delete',
                                    key: fee.feeId,
                                    label: 'Delete',
                                    onClick: () => handleDeleteFee(fee, loanFeeCategory, currentCategoryIndex),
                                  }]}
                                  triggerItem={(
                                    <Icon icon="MoreVert" variant="interactive" />
                                )}
                                />
                              </div>
                            </div>
                            <div className="invisible h-4" />
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.SELLER_PAID) {
                        const paidBySellerCreditAmount = ConvertProtoDecimalAsObjectToNumber(fee.feePayment?.paidBySellerCreditAmount);
                        const sellerCreditAmount = paidBySellerCreditAmount > 0;
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-[24px] border-r border-r-on-surface-stroke relative"
                          >
                            <div>
                              <Input
                                disabled={isInputDisabled(fee, column, false)}
                                displayOnly
                                id={`input-${column.id}-${fee.feeId}`}
                                mask={MaskType.CURRENCY}
                                onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                                prefix="$"
                                value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                              />
                            </div>
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !sellerCreditAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(paidBySellerCreditAmount)} (credit)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.LENDER_PAID) {
                        const paidByLenderCreditAmount = ConvertProtoDecimalAsObjectToNumber(fee.feePayment?.paidByLenderCreditAmount);
                        const lenderCreditAmount = paidByLenderCreditAmount > 0;
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-[24px] border-r border-r-on-surface-stroke relative"
                          >
                            <div>
                              <Input
                                disabled={isInputDisabled(fee, column, false)}
                                displayOnly
                                id={`input-${column.id}-${fee.feeId}`}
                                mask={MaskType.CURRENCY}
                                onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                                prefix="$"
                                value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                              />
                            </div>
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !lenderCreditAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(paidByLenderCreditAmount)} (credit)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.BASIS) {
                        const feeCalculationType = fee.feePayment?.feeCalculationType;

                        if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT) {
                          return (
                            <td
                              key={`table-row-cell-${column.id}`}
                              className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px]"
                              id={`table-row-cell-${fee.name}`}
                            >
                              <div className="flex flex-col items-end">
                                <span className="text-sm text-on-surface-active font-normal">
                                  {`${convertProtoToCurrency(fee.feePayment?.feeSpecifiedFixedAmount)} + ${convertProtoToPercentage(fee.feePayment?.feeTotalPercent)}`}
                                </span>
                                <span className="text-xs font-normal text-on-surface-inactive">
                                  {ConvertEnum(FeePercentBasisEnum, fee.feePayment?.feePercentBasisType || 0)}
                                </span>
                              </div>
                            </td>
                          );
                        }

                        if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_PERCENT) {
                          return (
                            <td
                              key={`table-row-cell-${column.id}`}
                              className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px]"
                              id={`table-row-cell-${fee.name}`}
                            >
                              <div className="flex flex-col items-end">
                                <span className="text-sm text-on-surface-active font-normal">
                                  {convertProtoToPercentage(fee.feePayment?.feeTotalPercent)}
                                </span>
                                <span className="text-xs font-normal text-on-surface-inactive">
                                  {ConvertEnum(FeePercentBasisEnum, fee.feePayment?.feePercentBasisType || 0)}
                                </span>
                              </div>
                            </td>
                          );
                        }

                        if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED) {
                          return (
                            <td
                              key={`table-row-cell-${column.id}`}
                              className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px]"
                              id={`table-row-cell-${fee.name}`}
                            >
                              <div className="flex flex-col items-end">
                                <span className="text-sm text-on-surface-active font-normal">
                                  {convertProtoToCurrency(fee.feePayment?.feeSpecifiedFixedAmount)}
                                </span>
                              </div>
                            </td>
                          );
                        }

                        // Default for FEE_CALCULATION_TYPE_ENUM_UNKNOWN && FEE_CALCULATION_TYPE_ENUM_VARIABLE
                        return (
                          <td
                            key={`table-row-cell-${column.id}`}
                            className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px]"
                            id={`table-row-cell-${fee.name}`}
                          >
                            <div className="flex flex-col items-end">
                              <span className="text-sm text-on-surface-active font-normal">
                                --
                              </span>
                            </div>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.BORROWER_PAID) {
                        const pocAmount = fee.feePayment?.paidByBorrowerOutsideOfClosingAmount
                          ? ConvertProtoDecimalAsObjectToNumber(fee.feePayment?.paidByBorrowerOutsideOfClosingAmount) : 0;

                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-[24px] border-r border-r-on-surface-stroke relative"
                          >
                            <div>
                              <Input
                                disabled={isInputDisabled(fee, column, true)}
                                displayOnly
                                id={`input-${column.id}-${fee.feeId}`}
                                mask={MaskType.CURRENCY}
                                onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                                prefix="$"
                                value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                              />
                            </div>
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !pocAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(pocAmount)} (POC)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.OTHER_PAID) {
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-[24px] border-r border-r-on-surface-stroke relative"
                          >
                            <Input
                              disabled={isInputDisabled(fee, column, false)}
                              displayOnly
                              id={`input-${column.id}-${fee.feeId}`}
                              mask={MaskType.CURRENCY}
                              onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                              prefix="$"
                              value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                            />
                            <div className="invisible h-4" />
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        );
      }) : (
        <ContingencyMessage
          icon="NoItems"
          subtitle="No fees found"
          title="No results, yet"
          variant="image"
        />
      )}
    </div>
  );
};

export default Fees;
