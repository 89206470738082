import type { ShowFieldValidation } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { SourcePanelElement } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { ddeAreEqual, DynamicDataElementMap, fromList, toNumber } from '@/Utils/Helpers/dynamicDataElementHelper';

import type { DynamicDataElementValues } from './DynamicFormContext';

const isEqual = (v1: DynamicDataElement, v2: DynamicDataElement) => ddeAreEqual(v1, v2);
const notEqual = (v1: DynamicDataElement, v2: DynamicDataElement) => !isEqual(v1, v2);
const biggerThan = (v1: DynamicDataElement, v2: DynamicDataElement) => toNumber(v1) > toNumber(v2);
const lessThan = (v1: DynamicDataElement, v2: DynamicDataElement) => toNumber(v1) < toNumber(v2);
const isIn = (v1: DynamicDataElement, v2: DynamicDataElement) => {
  const list = fromList(v2);
  return list.some((item) => ddeAreEqual(v1, item));
};
const notIn = (v1: DynamicDataElement, v2: DynamicDataElement) => !isIn(v1, v2);

export const isConditionOperationValid = (
  operator: SourcePanelElement.ValidationOperator,
  v1?: DynamicDataElement,
  v2?: DynamicDataElement,
) => {
  if (v1 && v2) {
    let condition = false;
    switch (operator) {
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_EQUALS:
        condition = isEqual(v1, v2);
        break;
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_NOT_EQUALS:
        condition = notEqual(v1, v2);
        break;
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_GREATER_THAN: {
        condition = biggerThan(v1, v2);
        break;
      }
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_LESS_THAN:
        condition = lessThan(v1, v2);
        break;
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_IN:
        condition = isIn(v1, v2);
        break;
      case SourcePanelElement.ValidationOperator.VALIDATION_OPERATOR_NOT_IN:
        condition = notIn(v1, v2);
        break;
      default:
        return false;
    }
    return condition;
  }
  return false;
};

export const isConditionValid = (condition: ShowFieldValidation.AsObject, formValues: DynamicDataElementValues) => {
  if (!condition.sourcePanelElement || !condition.sourcePanelElement.value?.value) return false;
  const sourceValue = formValues[condition.sourcePanelElement?.panelElementId];
  const noValueOnSourceField = Boolean(!sourceValue?.dynamic && !sourceValue?.value);
  if (noValueOnSourceField) return false;
  return isConditionOperationValid(
    condition.sourcePanelElement.validationOperator,
    sourceValue.dynamic,
    DynamicDataElementMap(condition.sourcePanelElement.value.value),
  );
};

export type FieldType = 'headerAction' | 'panelElement' | 'widgetElement';

export const getValidConditionsTargetIds = (conditions: Array<ShowFieldValidation.AsObject>, formValues: DynamicDataElementValues, fieldType?: FieldType) => conditions
  .reduce((targetIds: Array<string>, condition) => {
    const conditionValid = isConditionValid(condition, formValues);
    if (!conditionValid && condition.targetElementsList.length > 0) {
      return [
        ...targetIds,
        ...condition.targetElementsList.flatMap((target) => {
          switch (fieldType) {
            case 'panelElement':
              return target.panelElementsIdsList;
            case 'widgetElement':
              return target.widgetsIdsList;
            case 'headerAction':
              return target.headerActionsList.map((headerAction) => headerAction.headerActionId);
            default:
              return target.panelElementsIdsList;
          }
        }),
      ];
    }
    return targetIds;
  }, []);
