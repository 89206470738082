import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';

const allowedWidgets = ['mosaicWidgetContainer'];

export const watchUpdateField = (
  widgets: Array<LoanPageWidget.AsObject> | undefined,
  updatedVirtualFieldId?: string,
) => {
  if (widgets && widgets.length > 0) {
    let fieldsToWatch: (string | undefined)[] = [];
    const widget = widgets[0];
    const { changeTracking } = widget;

    if (!changeTracking) return null;
    const { excludedFieldsList, includedFieldsList } = changeTracking;

    const filteredObj = allowedWidgets.reduce((prev: { [key: string]: any }, curr: string) => {
      if (curr in widget) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        prev[curr] = widget[curr];
      }
      return prev;
    }, {}) as LoanPageWidget.AsObject;

    const virtualFieldIdentifierIds = filteredObj?.mosaicWidgetContainer?.cardListItemWidgetsList.flatMap(
      (cardList) => cardList.fieldsList.flatMap((field) => field.panelElement),
    ).map((panel) => panel?.virtualFieldIdentifier?.value);

    // Filter included fields
    if (includedFieldsList.length > 0 && virtualFieldIdentifierIds) {
      fieldsToWatch = virtualFieldIdentifierIds.filter((virtualId) => includedFieldsList.includes(virtualId || ''));
    }
    // // Filter excluded fields
    if (excludedFieldsList.length > 0 && virtualFieldIdentifierIds) {
      fieldsToWatch = virtualFieldIdentifierIds.filter((virtualId) => !excludedFieldsList.includes(virtualId || ''));
    }

    return fieldsToWatch.includes(updatedVirtualFieldId);
  }
  return false;
};
