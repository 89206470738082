import dayjs from 'dayjs';
import type { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type {
  CentralizedAddressLoanPageData,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  AddressFormatEnum,
  BorrowerResidencyBasisEnum,
  BorrowerResidencyEnum,
  OwnedPropertyDispositionStatusEnum,
  OwnedPropertyHasRentalIncome,
  OwnedPropertyLienInstallmentItemization,
  OwnedPropertyMaintenanceExpenseItemization,
  OwnedPropertyRentalIncomeDocumentationSource,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { ddeAreEqual, DynamicDataElementMap, fromString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getDateFromTimestamp, getTimestampFromDate } from '@/Utils/Helpers/getDateFromTimestamp';
import { getPageAction } from '@/Utils/Helpers/getPanelElement';
import { convertProtoToPercentage } from '@/Utils/Helpers/numberFormatter';
import { ConvertNumberToProtoDecimal, ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';
import { renderValue as render } from '@/Utils/Helpers/renderableHelper';

export const getResidenceType = (type?: BorrowerResidencyBasisEnum) => {
  switch (type) {
    case BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_LIVING_RENT_FREE:
      return 'Rent Free';
    case BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_OWN:
      return 'Own';
    case BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_RENT:
      return 'Rent';
    default:
      return '';
  }
};

export const getREOType = (type?: OwnedPropertyDispositionStatusEnum) => {
  switch (type) {
    case OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_PENDING_SALE:
      return 'Pending';
    case OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_RETAIN:
      return 'Retain';
    case OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_SOLD:
      return 'Sold';
    default:
      return '';
  }
};

export const getDate = (timestamp?: Timestamp.AsObject, suffix = ''): string => {
  if (timestamp?.seconds || timestamp?.nanos) return `${dayjs(getDateFromTimestamp(timestamp)).format('MM/DD/YYYY')}${suffix}`;
  return '--';
};

export const renderValue = (value: any, pe: PanelElement.AsObject) => {
  try {
    if (value === undefined) return '--';
    if (pe.type === 'date') return getDate(value);
    if (pe.type === 'switch') return value;
    if (pe.type === 'select') {
      return pe.optionsList.find((op) => op.id === String(value))?.headerText || '';
    }
    if (pe.mask?.type === 'currency') {
      if (typeof value === 'string' || typeof value === 'number') {
        return render({ displayType: 1, value: fromString(String(value)).toObject() });
      }
      return render({ displayType: 1, value: fromString(String(ConvertProtoDecimalAsObjectToNumber(value))).toObject() });
    }
    if (pe.mask?.type === 'percentage') {
      if (typeof value === 'string') {
        return render({ decimal: { decimalPlaces: 3, emptyAsZero: false, formatAsPercent: true }, displayType: 2, value: fromString(value).toObject() });
      }
      return convertProtoToPercentage(value);
    }
    if (typeof value === 'string') return value || '--';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (Array.isArray(value)) {
      return value.map((v) => pe.optionsList.find((op) => (!op.value ? false : ddeAreEqual(DynamicDataElementMap(op.value), DynamicDataElementMap(v.value))))?.headerText).toString();
    }
    if (value?.headerText) return value.headerText;
    if (value?.text) return value.text;
    return value?.headerText || '--';
  } catch (error) {
    throw Error(`${value} ${pe.headerText}`);
  }
};

export const getAssociations = (associations?: CentralizedAddressLoanPageData.Association.AsObject[], value?: any) => associations?.map((a) => ({
  ...a,
  isAssociated: value?.some((e: any) => e.id === a.borrowerId),
})) || [];

const getResidenceTypeEnum = (selectedOptionId: string) => {
  switch (selectedOptionId) {
    case 'Own':
      return BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_OWN;
    case 'Rent':
      return BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_RENT;
    case 'Rent Free':
      return BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_LIVING_RENT_FREE;
    default:
      return BorrowerResidencyBasisEnum.BORROWER_RESIDENCY_BASIS_ENUM_UNKNOWN;
  }
};

export const getResidence = (values: DynamicDataElementValues, type: 'current' | 'prior'): CentralizedAddressLoanPageData.Residence.AsObject => {
  const startDate = dayjs(values?.residenceDuration?.value.start);
  const endDate = dayjs(values?.residenceDuration?.value?.end);
  const diffInYears = endDate.diff(startDate, 'year');
  const diffInMonths = endDate.diff(startDate.add(diffInYears, 'year'), 'month');
  return {
    borrowerCommunityPropertyStateResidentIndicator: values?.communityStateProperty?.value,
    borrowerResidencyBasisType: getResidenceTypeEnum(type === 'current' ? values?.currentResidence?.value.selectedOptionId : values?.priorResidence.value.selectedOptionId),
    borrowerResidencyDurationMonthsCount: type === 'prior' ? diffInMonths : 0,
    borrowerResidencyDurationYearsCount: type === 'prior' ? diffInYears : 0,
    borrowerResidencyEndDate: type === 'current' ? undefined : getTimestampFromDate(values?.residenceDuration?.value?.end).toObject(),
    borrowerResidencyStartDate: getTimestampFromDate(type === 'current' ? values?.startDate?.value : values?.residenceDuration?.value?.start).toObject(),
    borrowerResidencyType: type === 'current' ? BorrowerResidencyEnum.BORROWER_RESIDENCY_ENUM_CURRENT : BorrowerResidencyEnum.BORROWER_RESIDENCY_ENUM_PRIOR,
    monthlyRentAmount: ConvertNumberToProtoDecimal(Number(values?.rentAmount?.value || 0)).toObject(),
  };
};

export const getREOTypeEnum = (id: string): OwnedPropertyDispositionStatusEnum => {
  switch (id) {
    case 'Retain':
      return OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_RETAIN;
    case 'Pending':
      return OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_PENDING_SALE;
    case 'Sold':
      return OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_SOLD;
    default:
      return OwnedPropertyDispositionStatusEnum.OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_UNKNOWN;
  }
};

export const getProto = (value?: string) => ConvertNumberToProtoDecimal(Number(value || '0')).toObject();

export const getMortgageItemizationType = (value: string) => {
  switch (value) {
    case 'Itemized':
      return OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ITEMIZED;
    case 'Non-Itemized':
      return OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_NON_ITEMIZED;
    case 'Liabilities':
      return OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ASSOCIATED_LIABILITIES;
    default:
      return OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_UNKNOWN;
  }
};

export const getMortgageItemization = (value?: OwnedPropertyLienInstallmentItemization) => {
  switch (value) {
    case OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ITEMIZED:
      return 'Itemized';
    case OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_NON_ITEMIZED:
      return 'Non-Itemized';
    case OwnedPropertyLienInstallmentItemization.OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ASSOCIATED_LIABILITIES:
      return 'Liabilities';
    default:
      return 'Non-Itemized';
  }
};

export const getDocumentationSource = (value?: OwnedPropertyRentalIncomeDocumentationSource): string => {
  switch (value) {
    case OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_TAX_RETURN:
      return 'Tax Return';
    case OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_LEASE_AGREEMENT:
      return 'Lease';
    case OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_MARKET_RENT:
      return 'Market';
    default:
      return 'Tax Return';
  }
};

export const getPropertyHasRentalIncomeFromEnum = (value?: OwnedPropertyHasRentalIncome): string => {
  switch (value) {
    case OwnedPropertyHasRentalIncome.OWNED_PROPERTY_HAS_RENTAL_INCOME_YES:
      return 'Yes';
    case OwnedPropertyHasRentalIncome.OWNED_PROPERTY_HAS_RENTAL_INCOME_NO:
      return 'No';
    default:
      return 'Unknown';
  }
};

export const getPropertyHasRentalIncomeFromString = (value?: string) => {
  switch (value) {
    case 'Yes':
      return OwnedPropertyHasRentalIncome.OWNED_PROPERTY_HAS_RENTAL_INCOME_YES;
    case 'No':
      return OwnedPropertyHasRentalIncome.OWNED_PROPERTY_HAS_RENTAL_INCOME_NO;
    default:
      return OwnedPropertyHasRentalIncome.OWNED_PROPERTY_HAS_RENTAL_INCOME_UNKNOWN;
  }
};

export const getDocumentationSourceType = (value: string) => {
  switch (value) {
    case 'Tax Return':
      return OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_TAX_RETURN;
    case 'Lease':
      return OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_LEASE_AGREEMENT;
    case 'Market':
      return OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_MARKET_RENT;
    default:
      return OwnedPropertyRentalIncomeDocumentationSource.OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_UNKNOWN;
  }
};

export const getMaintenanceExpenseItemizationType = (value: string) => {
  switch (value) {
    case 'Itemized':
      return OwnedPropertyMaintenanceExpenseItemization.OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_ITEMIZED;
    case 'Non-Itemized':
      return OwnedPropertyMaintenanceExpenseItemization.OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_NON_ITEMIZED;
    default:
      return OwnedPropertyMaintenanceExpenseItemization.OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_UNKNOWN;
  }
};

export const getMaintenanceExpenseItemization = (value?: OwnedPropertyMaintenanceExpenseItemization) => {
  switch (value) {
    case OwnedPropertyMaintenanceExpenseItemization.OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_ITEMIZED:
      return 'Itemized';
    case OwnedPropertyMaintenanceExpenseItemization.OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_NON_ITEMIZED:
      return 'Non-Itemized';
    default:
      return 'Non-Itemized';
  }
};

export const getAddressInfo = (formatType: AddressFormatEnum, values: DynamicDataElementValues) => {
  switch (formatType) {
    case AddressFormatEnum.ADDRESS_FORMAT_ENUM_INTERNATIONAL:
      return { city: values?.city?.value, county: values?.county?.value, lineOne: values?.addressLineOne?.value, state: values?.state?.value };
    case AddressFormatEnum.ADDRESS_FORMAT_ENUM_MILITARY:
      return { city: values?.militaryCity?.value?.headerText, county: values?.county?.value, lineOne: values?.militaryAddressLineOne?.value, state: values?.militaryState?.value?.headerText };
    case AddressFormatEnum.ADDRESS_FORMAT_ENUM_US_STANDARD:
      return { city: values?.city?.value, county: values?.county?.value?.headerText, lineOne: values?.addressLineOne?.value, state: values?.state?.value?.headerText };
    default:
      return { county: '', state: '' };
  }
};

export const getUsage = (current: string, prior: string, mailing: boolean, reo: string, subject: boolean) => {
  const usages = [];
  if (current) {
    usages.push(`Current: ${current}`);
  }
  if (prior) {
    usages.push(`Prior: ${prior}`);
  }
  if (mailing) {
    usages.push('Mailing');
  }
  if (reo && !subject) {
    usages.push(`Real Estate Owned: ${reo}`);
  }
  if (subject) {
    usages.push('Subject');
  }
  return usages.join(' • ');
};

export const convertDate = (timestamp?: Timestamp.AsObject) => {
  if (timestamp && timestamp?.seconds) {
    return dayjs(getDateFromTimestamp(timestamp)).format('MM/DD/YYYY');
  }
  return 'MM/DD/YYYY';
};

export const isAssociated = (associations?: CentralizedAddressLoanPageData.Association.AsObject[]) => associations?.some((a) => a.isAssociated) || false;

export const getAddressAction = (args: { mode: 'add' | 'edit' | 'read'; id: string }) => getPageAction({
  additionalArgsMap: [
    ['id', args.id],
    ['mode', args.mode],
  ],
  type: LoanPageActionType.LOAN_PAGE_ACTION_TYPE_CENTRALIZED_ADDRESS,
});
