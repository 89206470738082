import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';

import { useUploadFile } from '@/API/Queries/other/useUploadFile';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { cn } from '@/Utils/Helpers/uiBuilders';

import { Icon } from '../Icon';
import { ListItem } from '../ListItem';
import { ProgressBar } from '../ProgressBar';
import { Typography } from '../Typography';

export interface FileItemProps {
  fileId: string;
  fileName: string;
  mimeType: string;
  preview: string;
}

export interface ComponentProps {
  onSelect: (items: FileItemProps[]) => void;
  disabled?: boolean;
  onError?: (error: any) => void;
  onRemoveFile?: (id: string) => void;
  files?: Array<any>;
}

const FileUploader = (props: ComponentProps) => {
  const {
    disabled,
    files: defaultFiles,
    onError,
    onRemoveFile,
    onSelect,
  } = props;

  const [animateLoading, setAnimateLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(0);
  const [files, setFiles] = useState<Array<any>>(defaultFiles ?? []);
  const { secureAuthValues } = useAuth();
  const { institutionId } = secureAuthValues;
  const { isLoading, mutateAsync } = useUploadFile();

  useEffect(() => {
    if (isLoading) {
      setAnimateLoading(true);
      setMaxProgress(80);
    }

    if (!isLoading) {
      if (maxProgress > 0 && maxProgress < 100) {
        setMaxProgress(100);
      } else if (maxProgress === 100 && loadingProgress === 100) {
        setAnimateLoading(false);
        setMaxProgress(0);

        const items: FileItemProps[] = files.map((file) => ({
          fileId: file.fileId,
          fileName: file.name,
          mimeType: file.type,
          preview: file.preview,
        }));

        onSelect(items);
      }
    }
  }, [isLoading, maxProgress, loadingProgress, files, onSelect]);

  useEffect(() => {
    let timmerAnimation: NodeJS.Timer;

    if (animateLoading) {
      timmerAnimation = setInterval(() => {
        if (loadingProgress < maxProgress) {
          setLoadingProgress((prev) => prev + 1);
        }
      }, maxProgress < 100 ? 50 : 5);
    }

    return () => clearInterval(timmerAnimation);
  }, [animateLoading, loadingProgress, maxProgress]);

  const postFile = async (files: Array<any>) => {
    for (let i = 0; i < files.length; i += 1) {
      try {
        const file = files[i];
        const formData = new FormData();
        formData.append('file', file);

        // eslint-disable-next-line no-await-in-loop
        const [fileId] = await mutateAsync(
          { formData, institutionId },
        );
        file.fileId = fileId;

        setFiles((prevFiles) => [...prevFiles, file]);
      } catch (error) {
        if (onError) onError(error as Error);
        setFiles([]);
      }
    }
  };

  const { getInputProps, getRootProps, isFocused } = useDropzone(
    {
      maxFiles: 10,
      multiple: true,
      onDrop: async (acceptedFiles: any) => {
        const files = acceptedFiles.map((file: any) => Object.assign(file, {
          preview: URL.createObjectURL(file),
        }));
        postFile(files);
      },
    },
  );

  const handleRemoveFile = (id: string) => {
    onRemoveFile?.(id);
    setFiles((prev) => prev?.filter((doc) => doc.fileId !== id));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const [removed] = files.splice(result.source.index, 1);
    files.splice(result.destination.index, 0, removed);

    setFiles(files);

    const items: FileItemProps[] = files.map((file) => ({
      fileId: file.fileId,
      fileName: file.name,
      mimeType: file.type,
      preview: file.preview,
    }));

    onSelect(items);
  };

  return (
    <>
      <div
        className={
        clsx(
          'flex items-center justify-center flex-col',
          'border-dashed border-2 h-[150px] rounded-2xl transition-border ease-in-out min-h-[120px]',
          {
            '!border-none': animateLoading,
            'border-on-surface-stroke': !isFocused,
            'border-primary': isFocused,
            'opacity-80 cursor-not-allowed': disabled,
          },
        )
      }
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          className="w-full h-full"
          disabled={disabled}
        />
        {!animateLoading
          ? (
            <>
              <p className="font-six text-xl leading-6">Drag &amp; Drop</p>
              <p className="pt-2">
                your file here or
                <span className="text-hyperlink-unclicked mx-1 text-base hover:cursor-pointer">browse</span>
              </p>
            </>
          ) : (
            <div className="flex flex-col gap-y-4 w-full">
              <p>File uploading</p>
              <ProgressBar
                currentStep={loadingProgress}
                stepsNumber={100}
              />
            </div>
          )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="doc-droppable">
          {(provided) => (
            <div
              className={cn({ hidden: !files?.length })}
              {...provided.droppableProps}
              ref={provided.innerRef.bind(this)}
            >
              {files?.map((file, index) => (
                <Draggable key={file.fileId} draggableId={file.fileId} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef.bind(this)}
                      className="flex items-center border-b px-2 py-[10px]"
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                    >
                      <Icon
                        className="mr-1"
                        icon="DragIndicator"
                        variant="interactive"
                      />
                      <Typography className="flex-1 select-none" variant="body2">
                        {file?.name || ''}
                      </Typography>
                      <Icon icon="Delete" onClick={() => handleRemoveFile(file.fileId)} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!files?.length && (
        <ListItem
          className="border-b-[1px] border-b-slate-300"
          label="No File Uploaded"
          revertEmphasis
        />
      )}
    </>
  );
};

export default FileUploader;
