import { useMemo } from 'react';

import { useValidationChecklist } from '@/API/Queries/businessProcess/useValidationChecklist';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';

interface TableDataItem {
  creationDate: string;
  lastPublished: string;
  name: string;
  validationsCount: string;
}

const ValidationChecklists = () => {
  const { isLoading, data: validationChecklists = [] } = useValidationChecklist();

  const columns = useMemo((): Array<Column<TableDataItem>> => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'name',
        type: 'text',
      },
    },
  ], []);

  const data = useMemo(() => validationChecklists.map((checklist) => ({
    creationDate: checklist.creationDate,
    lastPublished: checklist.lastPublishedDate,
    name: checklist.name,
    validationsCount: checklist.validationsCount,
  })), [validationChecklists]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'All Validation Checklists' } }}
      columns={columns}
      data={data}
      isLoading={isLoading}
    />
  );
};

export { ValidationChecklists };
