import { useQuery } from '@tanstack/react-query';

import { SearchUsersQueryRequest, SearchUsersQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.SearchUsersQueryRequest';

export const useUserSearch = (search: string, page: number, pageSize: number, userId: string, enabled = false) => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();

  return useQuery([MESSAGE_NAME, search, page, pageSize, userId], async () => {
    const request = new SearchUsersQueryRequest();
    request.setInstitutionId(institutionId);
    request.setPage(page);
    request.setPageSize(pageSize);
    request.setParentUserId(userId);
    request.setSearchTerms(search);
    return sendMessage<SearchUsersQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      SearchUsersQueryResponse,
    );
  }, { enabled });
};
