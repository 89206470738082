import { Any } from 'google-protobuf/google/protobuf/any_pb';

import { useAuth } from '@/Routes/Auth/AppAuthContext';

import type { WilqoMessage } from './useBackend';

const useHttp = () => {
  const { authenticated, withAccessToken } = useAuth();

  const commandResponse = async (msg: WilqoMessage, allowUnAuthenticated = false): Promise<Any | false> => {
    try {
      const token = await withAccessToken();
      if (!token || token === '' || (!authenticated && !allowUnAuthenticated)) {
        throw new Error('No token found');
      }
      const response = await fetch(`/mortgage/api/message?messageType=google.protobuf/${msg.msgName}`, {
        body: msg.msg.serializeBinary(),
        cache: 'no-cache',
        headers: [
          ['authorization', `Bearer ${token}`],
          ['caller-url', window?.location?.href],
        ],
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseBody = await response.arrayBuffer();

      const anyWrapper = new Any();
      anyWrapper.setValue(new Uint8Array(responseBody));
      anyWrapper.setTypeUrl(response.headers.get('protobuf-type-url') ?? '');

      return anyWrapper;
    } catch (e: any) {
      throw new Error(e);
    }
  };

  return {
    commandResponse,
  };
};

export { useHttp };
