import { useMutation } from '@tanstack/react-query';

import { DeleteItemCommandRequest, DeleteItemCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';

import { useWilqoMessage } from '../useWilqoMessage';

export interface IMortgageDeleteParams {
  dealId: string;
  dealVersion: number;
  scopeToken: string;
  type: DeleteItemCommandRequest.DeleteType;
}

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest';

export const useCollectionItemDelete = () => {
  const sendMessage = useWilqoMessage();

  const deleteCollectionItem = async (updateObject: IMortgageDeleteParams) => {
    const request = new DeleteItemCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setDealVersion(updateObject.dealVersion);
    request.setScopeToken(updateObject.scopeToken);
    const deleteConfirmation = new DeleteItemCommandRequest.DeleteConfirmation();
    deleteConfirmation.setDeleteType(updateObject.type);
    request.setDeleteConfirmation(deleteConfirmation);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    return sendMessage<DeleteItemCommandResponse.AsObject>(message, DeleteItemCommandResponse);
  };

  return useMutation(deleteCollectionItem);
};
