import { FeesProvider } from './FeesContext';
import { FeesPage } from './FeesPage';

const Fees = () => (
  <FeesProvider>
    <FeesPage />
  </FeesProvider>
);

export { Fees };
