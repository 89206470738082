interface IconProps {
  className?: string;
}

const NumberTwo = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M14.9766 14.6816V16H9.14062V14.875L11.9004 11.916C12.1777 11.6074 12.3965 11.3359 12.5566 11.1016C12.7168 10.8633 12.832 10.6504 12.9023 10.4629C12.9766 10.2715 13.0137 10.0898 13.0137 9.91797C13.0137 9.66016 12.9707 9.43945 12.8848 9.25586C12.7988 9.06836 12.6719 8.92383 12.5039 8.82227C12.3398 8.7207 12.1367 8.66992 11.8945 8.66992C11.6367 8.66992 11.4141 8.73242 11.2266 8.85742C11.043 8.98242 10.9023 9.15625 10.8047 9.37891C10.7109 9.60156 10.6641 9.85352 10.6641 10.1348H8.9707C8.9707 9.62695 9.0918 9.16211 9.33398 8.74023C9.57617 8.31445 9.91797 7.97656 10.3594 7.72656C10.8008 7.47266 11.3242 7.3457 11.9297 7.3457C12.5273 7.3457 13.0312 7.44336 13.4414 7.63867C13.8555 7.83008 14.168 8.10742 14.3789 8.4707C14.5938 8.83008 14.7012 9.25977 14.7012 9.75977C14.7012 10.041 14.6562 10.3164 14.5664 10.5859C14.4766 10.8516 14.3477 11.1172 14.1797 11.3828C14.0156 11.6445 13.8164 11.9102 13.582 12.1797C13.3477 12.4492 13.0879 12.7285 12.8027 13.0176L11.3203 14.6816H14.9766Z" />
  </svg>
);

export default NumberTwo;
