import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import type { BusinessProcessTemplateDesignSummary } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb';
import { BusinessProcessTypeEnum } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb';
import { useArchiveBPTemplate } from '@/API/Queries/businessProcess/useArchiveBPTemplate';
import { useBusinessProcessTemplates } from '@/API/Queries/businessProcess/useBusinessProcessTemplates';
import type { Column, TableSort } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useTableConfig } from '@/Components/Features/table/useTableConfig';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { BusinessProcessTemplateCreate } from './BusinessProcessTemplateCreate';

const filterTypes: Array<BusinessProcessTypeEnum> = [
  BusinessProcessTypeEnum.BUSINESS_PROCESS_TYPE_ENUM_ACTIVITY,
  BusinessProcessTypeEnum.BUSINESS_PROCESS_TYPE_ENUM_APPLY_LENDER_CONDITION,
  BusinessProcessTypeEnum.BUSINESS_PROCESS_TYPE_ENUM_APPLY_FEE_TEMPLATES,
];
const types: BusinessProcessTypeEnum[] = Object.values(BusinessProcessTypeEnum)
  .filter((value) => typeof value === 'number' && !filterTypes.includes(value as BusinessProcessTypeEnum)) as BusinessProcessTypeEnum[];

export const BusinessProcessTemplatesList = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  // STATE
  const [openModal, setOpenModal] = useState(false);
  const [selectedBPTemplate, setSelectedBPTemplate] = useState<Array<any>>([]);
  const tableConfig = useTableConfig();
  const [showArchived, setShowArchived] = useState(false);
  const [sort, setSort] = useState<TableSort>();

  // QUERY
  const { mutate: archiveBPTemplate } = useArchiveBPTemplate();
  const { data, isLoading, refetch: reloadList } = useBusinessProcessTemplates({
    filterTemplatesAllowingConcurrentInstances: false,
    filterTemplatesAllowingMultipleInstances: false,
    pageConfig: {
      currentPage: tableConfig.page,
      pageLength: tableConfig.pagePerPage,
      sortAscending: sort?.isAscending || false,
      sortField: sort?.columnId || '',
    },
    progressItemIdentifier: '',
    searchTerm: tableConfig.search,
    showArchived,
    templateTypesList: types,
  });

  // EVENT HANDLERS
  const handleArchiveSuccess = useCallback(() => {
    showSnackBar({ message: 'Business Processes Archived', open: true });
    reloadList();
  }, [showSnackBar, reloadList]);
  const handleArchiveError = useCallback((error: any) => showSnackBar({ message: error.message, open: true }), [showSnackBar]);
  const handleArchiveClick = useCallback((items = selectedBPTemplate) => {
    archiveBPTemplate(
      items.map((item) => item.id),
      {
        onError: handleArchiveError,
        onSuccess: handleArchiveSuccess,
      },
    );
  }, [selectedBPTemplate, archiveBPTemplate, handleArchiveError, handleArchiveSuccess]);
  const handleSelectedChange = useCallback((newList: any) => {
    setSelectedBPTemplate(newList);
  }, []);

  // MEMO
  const columns = useMemo((): Array<Column<BusinessProcessTemplateDesignSummary.AsObject>> => [
    { header: 'External Name', id: 'title', key: { text: 'title', type: 'text' } },
    { header: 'Internal Name', id: 'internalName', key: { text: 'internalName', type: 'text' } },
    {
      header: 'Last Published',
      id: 'lastPublished',
      key: {
        text: (item) => getDateFromTimestamp(item.lastPublishDate)?.toDateString() || '',
        type: 'text',
      },
    },
  ], []);

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            contextualHeaderProps: {
              buttons: [
                { label: 'Archive', onClick: () => handleArchiveClick() },
              ],
              caption: `${selectedBPTemplate.length} business processes selected`,
            },
            filtersProps: {
              items: [{ onClick: () => setShowArchived(!showArchived), selected: showArchived, value: 'Archived' }],
              variant: 'flex',
            },
            // actions: [{ label: 'Create new', onClick: () => setOpenModal(true) }],
            searchProps: { onChange: tableConfig.setSearch, value: tableConfig.search },
            showContextual: selectedBPTemplate.length > 0,
            title: 'All Business Process Templates',
          },
        }}
        columns={columns}
        currentPage={tableConfig.page}
        data={data?.itemsList || []}
        handleNewPage={tableConfig.setPage}
        handleNewPageSize={tableConfig.setPagePerPage}
        hasOwnPagination
        hoverActions={showArchived ? [] : [{
          label: 'Archive',
          onClick: (bpTemplate: BusinessProcessTemplateDesignSummary.AsObject) => handleArchiveClick([bpTemplate]),
        }]}
        isLoading={isLoading}
        numberOfPages={data?.pageDetails ? Math.ceil(data.pageDetails.totalCount / tableConfig.pagePerPage) : 0}
        onClickRow={(item) => navigate(`/admin/businessProcessTemplate/${item.id}/details`)}
        setRowSelection={handleSelectedChange}
        setSort={setSort}
        sort={sort}
        totalItems={data?.pageDetails?.totalCount}
      />
      <BusinessProcessTemplateCreate close={() => setOpenModal(false)} open={openModal} />
    </>

  );
};
