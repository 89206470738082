import { PartyFeePayeeContactInfoCardData, PartySummary, PartySummaryLink } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toInt32ValueFromObject } from './helper';

export const toPartyFeePayeeContactInfoCardDataFromObject = (obj: PartyFeePayeeContactInfoCardData.AsObject): PartyFeePayeeContactInfoCardData => {
  const partyFeePayeeContactInfoCardData = new PartyFeePayeeContactInfoCardData();
  if (obj.address) partyFeePayeeContactInfoCardData.setAddress(obj.address);
  if (obj.addressAdditionalLineText) partyFeePayeeContactInfoCardData.setAddressAdditionalLineText(obj.addressAdditionalLineText);
  if (obj.email) partyFeePayeeContactInfoCardData.setEmail(obj.email);
  if (obj.name) partyFeePayeeContactInfoCardData.setName(obj.name);
  if (obj.partyId) partyFeePayeeContactInfoCardData.setPartyId(obj.partyId);
  if (obj.phone) partyFeePayeeContactInfoCardData.setPhone(obj.phone);

  return partyFeePayeeContactInfoCardData;
};

const toPartySummaryLinkFromObject = (obj: PartySummaryLink.AsObject): PartySummaryLink => {
  const partySummaryLink = new PartySummaryLink();
  if (obj.pageId) partySummaryLink.setPageId(obj.pageId);
  if (obj.tabId) partySummaryLink.setTabId(obj.tabId);

  return partySummaryLink;
};

export const toPartySummaryFromObject = (obj: PartySummary.AsObject): PartySummary => {
  const partySummary = new PartySummary();
  if (obj.fullName) partySummary.setFullName(obj.fullName);
  if (obj.id) partySummary.setId(obj.id);
  if (obj.initials) partySummary.setInitials(obj.initials);
  if (obj.partySummaryLink) partySummary.setPartySummaryLink(toPartySummaryLinkFromObject(obj.partySummaryLink));
  if (obj.partyTypes) partySummary.setPartyTypes(obj.partyTypes);
  if (obj.scopeId) partySummary.setScopeId(toInt32ValueFromObject(obj.scopeId));

  return partySummary;
};
