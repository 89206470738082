import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useMemo } from 'react';

import { GetCircuitDataQueryRequest, GetCircuitDataQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitQueries_pb';

import { useBackend } from '../useBackend';

export const CIRCUIT_MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.GetCircuitDataQueryRequest';

const isDefined = (maybeString: string | undefined): maybeString is string => Boolean(maybeString?.trim());

const useCircuitData = (dealId: string | undefined, circuitId: string | undefined, scopeToken: string | undefined, withoutData = false) => {
  const { commandResponse } = useBackend();

  const isEnabled = useMemo(() => isDefined(dealId) && isDefined(circuitId) && isDefined(scopeToken),
    [dealId, circuitId, scopeToken]);

  return useQuery([CIRCUIT_MESSAGE_NAME, circuitId, withoutData], async () => {
    const request = new GetCircuitDataQueryRequest();

    request.setDealId(dealId || '');
    request.setCircuitId(circuitId || '');
    request.setScopeToken(scopeToken || '');
    request.setWithoutData(withoutData);

    const msg = {
      msg: request,
      msgName: CIRCUIT_MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(msg);
    const response = new GetCircuitDataQueryResponse();
    GetCircuitDataQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    /// NOTE: Not using middleware here because api can return error and good data at the same time
    return response.toObject();
  }, { cacheTime: 0, enabled: isEnabled });
};

export { useCircuitData };
