// source: Wilqo_API_Brand_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
goog.exportSymbol('proto.wilqo.api.brand.Action', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Action.ActionTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.CardConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.CellConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.CellConfig.CellTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ColumnCellConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ColumnConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ConfigurationType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ConsumerActionData', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ConsumerActionType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Content', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Content.ContentType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Content.HyperLinkType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.DescriptionListItem', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Font', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Footer', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Footer.SizeType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Footer.UserType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Header', null, global);
goog.exportSymbol('proto.wilqo.api.brand.HomePageHeader', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Link', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Link.LinkType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Logo', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Logo.LogoType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.LogoUrl', null, global);
goog.exportSymbol('proto.wilqo.api.brand.LogoUrl.LogoType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Navigation', null, global);
goog.exportSymbol('proto.wilqo.api.brand.PageConfiguration', null, global);
goog.exportSymbol('proto.wilqo.api.brand.PageData', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Route', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Sidebar', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Sidebar.SidebarTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.SidebarItemConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.SidebarItemConfig.SidebarType', null, global);
goog.exportSymbol('proto.wilqo.api.brand.TableConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.TableFilterConfig', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Theme', null, global);
goog.exportSymbol('proto.wilqo.api.brand.ThemeLogo', null, global);
goog.exportSymbol('proto.wilqo.api.brand.UxDisplayStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Widget', null, global);
goog.exportSymbol('proto.wilqo.api.brand.Widget.WidgetTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.brand.WidgetContent', null, global);
goog.exportSymbol('proto.wilqo.api.brand.WidgetContentSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Footer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.Footer.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.Footer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Footer.displayName = 'proto.wilqo.api.brand.Footer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Logo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Logo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Logo.displayName = 'proto.wilqo.api.brand.Logo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Theme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Theme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Theme.displayName = 'proto.wilqo.api.brand.Theme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.Content.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Content.displayName = 'proto.wilqo.api.brand.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Link.displayName = 'proto.wilqo.api.brand.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.LogoUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.LogoUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.LogoUrl.displayName = 'proto.wilqo.api.brand.LogoUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Font = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Font, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Font.displayName = 'proto.wilqo.api.brand.Font';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.ThemeLogo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.ThemeLogo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.ThemeLogo.displayName = 'proto.wilqo.api.brand.ThemeLogo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Header = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Header, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Header.displayName = 'proto.wilqo.api.brand.Header';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.HomePageHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.HomePageHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.HomePageHeader.displayName = 'proto.wilqo.api.brand.HomePageHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Navigation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.Navigation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.Navigation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Navigation.displayName = 'proto.wilqo.api.brand.Navigation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Sidebar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Sidebar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Sidebar.displayName = 'proto.wilqo.api.brand.Sidebar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.WidgetContentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.WidgetContentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.WidgetContentSettings.displayName = 'proto.wilqo.api.brand.WidgetContentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.WidgetContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.WidgetContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.WidgetContent.displayName = 'proto.wilqo.api.brand.WidgetContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Widget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.Widget.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.Widget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Widget.displayName = 'proto.wilqo.api.brand.Widget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Action.displayName = 'proto.wilqo.api.brand.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.PageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.PageData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.PageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.PageData.displayName = 'proto.wilqo.api.brand.PageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.Route.displayName = 'proto.wilqo.api.brand.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.PageConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.PageConfiguration.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.PageConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.PageConfiguration.displayName = 'proto.wilqo.api.brand.PageConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.SidebarItemConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.SidebarItemConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.SidebarItemConfig.displayName = 'proto.wilqo.api.brand.SidebarItemConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.CellConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.CellConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.CellConfig.displayName = 'proto.wilqo.api.brand.CellConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.ColumnCellConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.ColumnCellConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.ColumnCellConfig.displayName = 'proto.wilqo.api.brand.ColumnCellConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.ColumnConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.ColumnConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.ColumnConfig.displayName = 'proto.wilqo.api.brand.ColumnConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.TableFilterConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.TableFilterConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.TableFilterConfig.displayName = 'proto.wilqo.api.brand.TableFilterConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.TableConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.TableConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.TableConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.TableConfig.displayName = 'proto.wilqo.api.brand.TableConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.DescriptionListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.DescriptionListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.DescriptionListItem.displayName = 'proto.wilqo.api.brand.DescriptionListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.CardConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.brand.CardConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.brand.CardConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.CardConfig.displayName = 'proto.wilqo.api.brand.CardConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.brand.ConsumerActionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.brand.ConsumerActionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.brand.ConsumerActionData.displayName = 'proto.wilqo.api.brand.ConsumerActionData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.Footer.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Footer.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Footer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Footer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Footer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mainText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bodyRichText: jspb.Message.getFieldWithDefault(msg, 10, ""),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.wilqo.api.brand.Link.toObject, includeInstance),
    logoUrlsList: jspb.Message.toObjectList(msg.getLogoUrlsList(),
    proto.wilqo.api.brand.LogoUrl.toObject, includeInstance),
    sizetype: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Footer}
 */
proto.wilqo.api.brand.Footer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Footer;
  return proto.wilqo.api.brand.Footer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Footer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Footer}
 */
proto.wilqo.api.brand.Footer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.brand.Footer.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainText(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodyRichText(value);
      break;
    case 11:
      var value = new proto.wilqo.api.brand.Link;
      reader.readMessage(value,proto.wilqo.api.brand.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 12:
      var value = new proto.wilqo.api.brand.LogoUrl;
      reader.readMessage(value,proto.wilqo.api.brand.LogoUrl.deserializeBinaryFromReader);
      msg.addLogoUrls(value);
      break;
    case 13:
      var value = /** @type {!proto.wilqo.api.brand.Footer.SizeType} */ (reader.readEnum());
      msg.setSizetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Footer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Footer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Footer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Footer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMainText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBodyRichText();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.wilqo.api.brand.Link.serializeBinaryToWriter
    );
  }
  f = message.getLogoUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.wilqo.api.brand.LogoUrl.serializeBinaryToWriter
    );
  }
  f = message.getSizetype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Footer.UserType = {
  CONSUMER: 0,
  LENDER: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.brand.Footer.SizeType = {
  SMALL: 0,
  LARGE: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Footer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_id = 8;
 * @return {string}
 */
proto.wilqo.api.brand.Footer.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Footer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserType userType = 3;
 * @return {!proto.wilqo.api.brand.Footer.UserType}
 */
proto.wilqo.api.brand.Footer.prototype.getUsertype = function() {
  return /** @type {!proto.wilqo.api.brand.Footer.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Footer.UserType} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string main_text = 9;
 * @return {string}
 */
proto.wilqo.api.brand.Footer.prototype.getMainText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setMainText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string body_rich_text = 10;
 * @return {string}
 */
proto.wilqo.api.brand.Footer.prototype.getBodyRichText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setBodyRichText = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated Link links = 11;
 * @return {!Array<!proto.wilqo.api.brand.Link>}
 */
proto.wilqo.api.brand.Footer.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Link, 11));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Link>} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
*/
proto.wilqo.api.brand.Footer.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.api.brand.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Link}
 */
proto.wilqo.api.brand.Footer.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.api.brand.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * repeated LogoUrl logo_urls = 12;
 * @return {!Array<!proto.wilqo.api.brand.LogoUrl>}
 */
proto.wilqo.api.brand.Footer.prototype.getLogoUrlsList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.LogoUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.LogoUrl, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.LogoUrl>} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
*/
proto.wilqo.api.brand.Footer.prototype.setLogoUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.brand.LogoUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.LogoUrl}
 */
proto.wilqo.api.brand.Footer.prototype.addLogoUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.brand.LogoUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.clearLogoUrlsList = function() {
  return this.setLogoUrlsList([]);
};


/**
 * optional SizeType sizeType = 13;
 * @return {!proto.wilqo.api.brand.Footer.SizeType}
 */
proto.wilqo.api.brand.Footer.prototype.getSizetype = function() {
  return /** @type {!proto.wilqo.api.brand.Footer.SizeType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Footer.SizeType} value
 * @return {!proto.wilqo.api.brand.Footer} returns this
 */
proto.wilqo.api.brand.Footer.prototype.setSizetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Logo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Logo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Logo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Logo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    host: jspb.Message.getFieldWithDefault(msg, 4, ""),
    extension: jspb.Message.getFieldWithDefault(msg, 5, ""),
    height: jspb.Message.getFieldWithDefault(msg, 6, 0),
    width: jspb.Message.getFieldWithDefault(msg, 7, 0),
    logocontent: msg.getLogocontent_asB64(),
    themeLogo: (f = msg.getThemeLogo()) && proto.wilqo.api.brand.ThemeLogo.toObject(includeInstance, f),
    institutionId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Logo}
 */
proto.wilqo.api.brand.Logo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Logo;
  return proto.wilqo.api.brand.Logo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Logo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Logo}
 */
proto.wilqo.api.brand.Logo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.brand.Logo.LogoType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLogocontent(value);
      break;
    case 9:
      var value = new proto.wilqo.api.brand.ThemeLogo;
      reader.readMessage(value,proto.wilqo.api.brand.ThemeLogo.deserializeBinaryFromReader);
      msg.setThemeLogo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Logo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Logo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Logo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Logo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLogocontent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getThemeLogo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.brand.ThemeLogo.serializeBinaryToWriter
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Logo.LogoType = {
  LOGO: 0,
  FAVICON: 1
};

/**
 * optional LogoType type = 1;
 * @return {!proto.wilqo.api.brand.Logo.LogoType}
 */
proto.wilqo.api.brand.Logo.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.Logo.LogoType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Logo.LogoType} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string host = 4;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string extension = 5;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 height = 6;
 * @return {number}
 */
proto.wilqo.api.brand.Logo.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 width = 7;
 * @return {number}
 */
proto.wilqo.api.brand.Logo.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bytes logoContent = 8;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getLogocontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes logoContent = 8;
 * This is a type-conversion wrapper around `getLogocontent()`
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getLogocontent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLogocontent()));
};


/**
 * optional bytes logoContent = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLogocontent()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Logo.prototype.getLogocontent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLogocontent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setLogocontent = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional ThemeLogo theme_logo = 9;
 * @return {?proto.wilqo.api.brand.ThemeLogo}
 */
proto.wilqo.api.brand.Logo.prototype.getThemeLogo = function() {
  return /** @type{?proto.wilqo.api.brand.ThemeLogo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.ThemeLogo, 9));
};


/**
 * @param {?proto.wilqo.api.brand.ThemeLogo|undefined} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
*/
proto.wilqo.api.brand.Logo.prototype.setThemeLogo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.clearThemeLogo = function() {
  return this.setThemeLogo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.Logo.prototype.hasThemeLogo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string institution_id = 10;
 * @return {string}
 */
proto.wilqo.api.brand.Logo.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Logo} returns this
 */
proto.wilqo.api.brand.Logo.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Theme.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Theme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Theme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Theme.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    host: jspb.Message.getFieldWithDefault(msg, 3, ""),
    themeContent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Theme}
 */
proto.wilqo.api.brand.Theme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Theme;
  return proto.wilqo.api.brand.Theme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Theme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Theme}
 */
proto.wilqo.api.brand.Theme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThemeContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Theme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Theme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Theme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Theme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThemeContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Theme.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Theme} returns this
 */
proto.wilqo.api.brand.Theme.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Theme.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Theme} returns this
 */
proto.wilqo.api.brand.Theme.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Theme.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Theme} returns this
 */
proto.wilqo.api.brand.Theme.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string theme_content = 4;
 * @return {string}
 */
proto.wilqo.api.brand.Theme.prototype.getThemeContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Theme} returns this
 */
proto.wilqo.api.brand.Theme.prototype.setThemeContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string institution_id = 5;
 * @return {string}
 */
proto.wilqo.api.brand.Theme.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Theme} returns this
 */
proto.wilqo.api.brand.Theme.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.Content.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    contenttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imagecontentList: msg.getImagecontentList_asB64(),
    extension: jspb.Message.getFieldWithDefault(msg, 3, ""),
    textcontent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hyperlinktype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hyperlinkcontent: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Content}
 */
proto.wilqo.api.brand.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Content;
  return proto.wilqo.api.brand.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Content}
 */
proto.wilqo.api.brand.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.brand.Content.ContentType} */ (reader.readEnum());
      msg.setContenttype(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addImagecontent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextcontent(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.brand.Content.HyperLinkType} */ (reader.readEnum());
      msg.setHyperlinktype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHyperlinkcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getImagecontentList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTextcontent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHyperlinktype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getHyperlinkcontent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Content.ContentType = {
  IMAGE: 0,
  TEXT: 1,
  URL: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.brand.Content.HyperLinkType = {
  NEW_TAB: 0,
  SAME_TAB: 1
};

/**
 * optional ContentType contentType = 1;
 * @return {!proto.wilqo.api.brand.Content.ContentType}
 */
proto.wilqo.api.brand.Content.prototype.getContenttype = function() {
  return /** @type {!proto.wilqo.api.brand.Content.ContentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Content.ContentType} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setContenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated bytes imageContent = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.brand.Content.prototype.getImagecontentList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes imageContent = 2;
 * This is a type-conversion wrapper around `getImagecontentList()`
 * @return {!Array<string>}
 */
proto.wilqo.api.brand.Content.prototype.getImagecontentList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getImagecontentList()));
};


/**
 * repeated bytes imageContent = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImagecontentList()`
 * @return {!Array<!Uint8Array>}
 */
proto.wilqo.api.brand.Content.prototype.getImagecontentList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getImagecontentList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setImagecontentList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.addImagecontent = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.clearImagecontentList = function() {
  return this.setImagecontentList([]);
};


/**
 * optional string extension = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Content.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string textContent = 4;
 * @return {string}
 */
proto.wilqo.api.brand.Content.prototype.getTextcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setTextcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional HyperLinkType hyperLinkType = 5;
 * @return {!proto.wilqo.api.brand.Content.HyperLinkType}
 */
proto.wilqo.api.brand.Content.prototype.getHyperlinktype = function() {
  return /** @type {!proto.wilqo.api.brand.Content.HyperLinkType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Content.HyperLinkType} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setHyperlinktype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string hyperLinkContent = 6;
 * @return {string}
 */
proto.wilqo.api.brand.Content.prototype.getHyperlinkcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Content} returns this
 */
proto.wilqo.api.brand.Content.prototype.setHyperlinkcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Link}
 */
proto.wilqo.api.brand.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Link;
  return proto.wilqo.api.brand.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Link}
 */
proto.wilqo.api.brand.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.brand.Link.LinkType} */ (reader.readEnum());
      msg.setLinkType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Link.LinkType = {
  NEW_TAB: 0,
  SAME_TAB: 1
};

/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Link.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Link} returns this
 */
proto.wilqo.api.brand.Link.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Link.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Link} returns this
 */
proto.wilqo.api.brand.Link.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LinkType link_type = 3;
 * @return {!proto.wilqo.api.brand.Link.LinkType}
 */
proto.wilqo.api.brand.Link.prototype.getLinkType = function() {
  return /** @type {!proto.wilqo.api.brand.Link.LinkType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Link.LinkType} value
 * @return {!proto.wilqo.api.brand.Link} returns this
 */
proto.wilqo.api.brand.Link.prototype.setLinkType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.LogoUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.LogoUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.LogoUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.LogoUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.LogoUrl}
 */
proto.wilqo.api.brand.LogoUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.LogoUrl;
  return proto.wilqo.api.brand.LogoUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.LogoUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.LogoUrl}
 */
proto.wilqo.api.brand.LogoUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.brand.LogoUrl.LogoType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.LogoUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.LogoUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.LogoUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.LogoUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.LogoUrl.LogoType = {
  INTERNAL: 0,
  EXTERNAL: 1
};

/**
 * optional string url = 1;
 * @return {string}
 */
proto.wilqo.api.brand.LogoUrl.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.LogoUrl} returns this
 */
proto.wilqo.api.brand.LogoUrl.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LogoType type = 2;
 * @return {!proto.wilqo.api.brand.LogoUrl.LogoType}
 */
proto.wilqo.api.brand.LogoUrl.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.LogoUrl.LogoType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.brand.LogoUrl.LogoType} value
 * @return {!proto.wilqo.api.brand.LogoUrl} returns this
 */
proto.wilqo.api.brand.LogoUrl.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Font.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Font.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Font} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Font.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    style: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fontcontent: msg.getFontcontent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Font}
 */
proto.wilqo.api.brand.Font.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Font;
  return proto.wilqo.api.brand.Font.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Font} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Font}
 */
proto.wilqo.api.brand.Font.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyle(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setFontcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Font.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Font.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Font} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Font.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStyle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFontcontent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Font} returns this
 */
proto.wilqo.api.brand.Font.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Font} returns this
 */
proto.wilqo.api.brand.Font.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Font} returns this
 */
proto.wilqo.api.brand.Font.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string style = 4;
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Font} returns this
 */
proto.wilqo.api.brand.Font.prototype.setStyle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bytes fontContent = 5;
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getFontcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes fontContent = 5;
 * This is a type-conversion wrapper around `getFontcontent()`
 * @return {string}
 */
proto.wilqo.api.brand.Font.prototype.getFontcontent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getFontcontent()));
};


/**
 * optional bytes fontContent = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getFontcontent()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Font.prototype.getFontcontent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getFontcontent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.brand.Font} returns this
 */
proto.wilqo.api.brand.Font.prototype.setFontcontent = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.ThemeLogo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.ThemeLogo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ThemeLogo.toObject = function(includeInstance, msg) {
  var f, obj = {
    basicThemeLogo: msg.getBasicThemeLogo_asB64(),
    darkThemeLogo: msg.getDarkThemeLogo_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.ThemeLogo}
 */
proto.wilqo.api.brand.ThemeLogo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.ThemeLogo;
  return proto.wilqo.api.brand.ThemeLogo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.ThemeLogo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.ThemeLogo}
 */
proto.wilqo.api.brand.ThemeLogo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBasicThemeLogo(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDarkThemeLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.ThemeLogo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.ThemeLogo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ThemeLogo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasicThemeLogo_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getDarkThemeLogo_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes basic_theme_logo = 1;
 * @return {string}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getBasicThemeLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes basic_theme_logo = 1;
 * This is a type-conversion wrapper around `getBasicThemeLogo()`
 * @return {string}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getBasicThemeLogo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBasicThemeLogo()));
};


/**
 * optional bytes basic_theme_logo = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBasicThemeLogo()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getBasicThemeLogo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBasicThemeLogo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.brand.ThemeLogo} returns this
 */
proto.wilqo.api.brand.ThemeLogo.prototype.setBasicThemeLogo = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes dark_theme_logo = 2;
 * @return {string}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getDarkThemeLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes dark_theme_logo = 2;
 * This is a type-conversion wrapper around `getDarkThemeLogo()`
 * @return {string}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getDarkThemeLogo_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDarkThemeLogo()));
};


/**
 * optional bytes dark_theme_logo = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDarkThemeLogo()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ThemeLogo.prototype.getDarkThemeLogo_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDarkThemeLogo()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.brand.ThemeLogo} returns this
 */
proto.wilqo.api.brand.ThemeLogo.prototype.setDarkThemeLogo = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Header.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Header.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Header} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Header.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkTo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    external: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Header}
 */
proto.wilqo.api.brand.Header.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Header;
  return proto.wilqo.api.brand.Header.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Header} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Header}
 */
proto.wilqo.api.brand.Header.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Header.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Header.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Header} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Header.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternal();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Header.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Header} returns this
 */
proto.wilqo.api.brand.Header.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link_to = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Header.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Header} returns this
 */
proto.wilqo.api.brand.Header.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool external = 3;
 * @return {boolean}
 */
proto.wilqo.api.brand.Header.prototype.getExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.brand.Header} returns this
 */
proto.wilqo.api.brand.Header.prototype.setExternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.HomePageHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.HomePageHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.HomePageHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.HomePageHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkTo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dynamicPageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.HomePageHeader}
 */
proto.wilqo.api.brand.HomePageHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.HomePageHeader;
  return proto.wilqo.api.brand.HomePageHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.HomePageHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.HomePageHeader}
 */
proto.wilqo.api.brand.HomePageHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDynamicPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.HomePageHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.HomePageHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.HomePageHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.HomePageHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDynamicPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string link_to = 1;
 * @return {string}
 */
proto.wilqo.api.brand.HomePageHeader.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.HomePageHeader} returns this
 */
proto.wilqo.api.brand.HomePageHeader.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dynamic_page_id = 2;
 * @return {string}
 */
proto.wilqo.api.brand.HomePageHeader.prototype.getDynamicPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.HomePageHeader} returns this
 */
proto.wilqo.api.brand.HomePageHeader.prototype.setDynamicPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.brand.HomePageHeader.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.HomePageHeader} returns this
 */
proto.wilqo.api.brand.HomePageHeader.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.Navigation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Navigation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Navigation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Navigation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Navigation.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    submenuListList: jspb.Message.toObjectList(msg.getSubmenuListList(),
    proto.wilqo.api.brand.Navigation.toObject, includeInstance),
    dynamicPageId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tabId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.Navigation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Navigation;
  return proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Navigation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 4:
      var value = new proto.wilqo.api.brand.Navigation;
      reader.readMessage(value,proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader);
      msg.addSubmenuList(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDynamicPageId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Navigation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Navigation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Navigation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Navigation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubmenuListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.brand.Navigation.serializeBinaryToWriter
    );
  }
  f = message.getDynamicPageId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTabId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link_to = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Navigation submenu_list = 4;
 * @return {!Array<!proto.wilqo.api.brand.Navigation>}
 */
proto.wilqo.api.brand.Navigation.prototype.getSubmenuListList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Navigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Navigation, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Navigation>} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
*/
proto.wilqo.api.brand.Navigation.prototype.setSubmenuListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.brand.Navigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.Navigation.prototype.addSubmenuList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.brand.Navigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.clearSubmenuListList = function() {
  return this.setSubmenuListList([]);
};


/**
 * optional string dynamic_page_id = 5;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getDynamicPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setDynamicPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string tab_id = 7;
 * @return {string}
 */
proto.wilqo.api.brand.Navigation.prototype.getTabId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Navigation} returns this
 */
proto.wilqo.api.brand.Navigation.prototype.setTabId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Sidebar.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Sidebar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Sidebar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Sidebar.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Sidebar}
 */
proto.wilqo.api.brand.Sidebar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Sidebar;
  return proto.wilqo.api.brand.Sidebar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Sidebar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Sidebar}
 */
proto.wilqo.api.brand.Sidebar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.brand.Sidebar.SidebarTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Sidebar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Sidebar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Sidebar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Sidebar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Sidebar.SidebarTypeEnum = {
  OVERVIEW: 0,
  LOAN_LIST: 1,
  ANALYTICS: 2,
  LOAN_PROGRESS: 3,
  LOAN_PARTIES: 4,
  LOAN_TRANSACTION: 5,
  LOAN_OVERVIEW: 6,
  LOAN_DOCUMENTS: 7,
  LOAN_COMPLIANCE: 8,
  LOAN_FRAUD_TESTS: 9,
  LOAN_COMMENTS: 10,
  LOAN_CHANGE_HISTORY: 11,
  HELP: 12,
  LO_ANALYTICS: 13,
  LOAN_CONTACTS: 14,
  LOAN_WILQO_PARTIES: 15,
  DOCUMENTS_POC: 16
};

/**
 * optional SidebarTypeEnum type = 1;
 * @return {!proto.wilqo.api.brand.Sidebar.SidebarTypeEnum}
 */
proto.wilqo.api.brand.Sidebar.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.Sidebar.SidebarTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Sidebar.SidebarTypeEnum} value
 * @return {!proto.wilqo.api.brand.Sidebar} returns this
 */
proto.wilqo.api.brand.Sidebar.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.brand.Sidebar.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Sidebar} returns this
 */
proto.wilqo.api.brand.Sidebar.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Sidebar.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Sidebar} returns this
 */
proto.wilqo.api.brand.Sidebar.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> additional_settings = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.brand.Sidebar.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.brand.Sidebar} returns this
 */
proto.wilqo.api.brand.Sidebar.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.WidgetContentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.WidgetContentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.WidgetContentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    api: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.WidgetContentSettings}
 */
proto.wilqo.api.brand.WidgetContentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.WidgetContentSettings;
  return proto.wilqo.api.brand.WidgetContentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.WidgetContentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.WidgetContentSettings}
 */
proto.wilqo.api.brand.WidgetContentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.WidgetContentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.WidgetContentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.WidgetContentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.WidgetContentSettings} returns this
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api = 2;
 * @return {string}
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.getApi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.WidgetContentSettings} returns this
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.setApi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.WidgetContentSettings} returns this
 */
proto.wilqo.api.brand.WidgetContentSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.WidgetContent.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.WidgetContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.WidgetContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.WidgetContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: (f = msg.getSettings()) && proto.wilqo.api.brand.WidgetContentSettings.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subTitle: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.WidgetContent}
 */
proto.wilqo.api.brand.WidgetContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.WidgetContent;
  return proto.wilqo.api.brand.WidgetContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.WidgetContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.WidgetContent}
 */
proto.wilqo.api.brand.WidgetContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.wilqo.api.brand.WidgetContentSettings;
      reader.readMessage(value,proto.wilqo.api.brand.WidgetContentSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.brand.ConfigurationType} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.brand.ConfigurationType} */ (reader.readEnum());
      msg.setSubTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.WidgetContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.WidgetContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.WidgetContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.WidgetContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.brand.WidgetContentSettings.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSubTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.brand.WidgetContent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.WidgetContent} returns this
 */
proto.wilqo.api.brand.WidgetContent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WidgetContentSettings settings = 2;
 * @return {?proto.wilqo.api.brand.WidgetContentSettings}
 */
proto.wilqo.api.brand.WidgetContent.prototype.getSettings = function() {
  return /** @type{?proto.wilqo.api.brand.WidgetContentSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.WidgetContentSettings, 2));
};


/**
 * @param {?proto.wilqo.api.brand.WidgetContentSettings|undefined} value
 * @return {!proto.wilqo.api.brand.WidgetContent} returns this
*/
proto.wilqo.api.brand.WidgetContent.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.WidgetContent} returns this
 */
proto.wilqo.api.brand.WidgetContent.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.WidgetContent.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConfigurationType title = 3;
 * @return {!proto.wilqo.api.brand.ConfigurationType}
 */
proto.wilqo.api.brand.WidgetContent.prototype.getTitle = function() {
  return /** @type {!proto.wilqo.api.brand.ConfigurationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.brand.ConfigurationType} value
 * @return {!proto.wilqo.api.brand.WidgetContent} returns this
 */
proto.wilqo.api.brand.WidgetContent.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ConfigurationType sub_title = 4;
 * @return {!proto.wilqo.api.brand.ConfigurationType}
 */
proto.wilqo.api.brand.WidgetContent.prototype.getSubTitle = function() {
  return /** @type {!proto.wilqo.api.brand.ConfigurationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.brand.ConfigurationType} value
 * @return {!proto.wilqo.api.brand.WidgetContent} returns this
 */
proto.wilqo.api.brand.WidgetContent.prototype.setSubTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.Widget.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Widget.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Widget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Widget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Widget.toObject = function(includeInstance, msg) {
  var f, obj = {
    area: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentList: jspb.Message.toObjectList(msg.getContentList(),
    proto.wilqo.api.brand.WidgetContent.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Widget}
 */
proto.wilqo.api.brand.Widget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Widget;
  return proto.wilqo.api.brand.Widget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Widget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Widget}
 */
proto.wilqo.api.brand.Widget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 2:
      var value = new proto.wilqo.api.brand.WidgetContent;
      reader.readMessage(value,proto.wilqo.api.brand.WidgetContent.deserializeBinaryFromReader);
      msg.addContent(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.brand.Widget.WidgetTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Widget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Widget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Widget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Widget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.brand.WidgetContent.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Widget.WidgetTypeEnum = {
  UNKNOWN: 0,
  DISPLAY_NAME: 1,
  CONSUMER_ACTIVITIES: 2,
  LENDER_ACTIVITIES: 3
};

/**
 * optional string area = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Widget.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Widget} returns this
 */
proto.wilqo.api.brand.Widget.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated WidgetContent content = 2;
 * @return {!Array<!proto.wilqo.api.brand.WidgetContent>}
 */
proto.wilqo.api.brand.Widget.prototype.getContentList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.WidgetContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.WidgetContent, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.WidgetContent>} value
 * @return {!proto.wilqo.api.brand.Widget} returns this
*/
proto.wilqo.api.brand.Widget.prototype.setContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.brand.WidgetContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.WidgetContent}
 */
proto.wilqo.api.brand.Widget.prototype.addContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.brand.WidgetContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.Widget} returns this
 */
proto.wilqo.api.brand.Widget.prototype.clearContentList = function() {
  return this.setContentList([]);
};


/**
 * optional WidgetTypeEnum type = 3;
 * @return {!proto.wilqo.api.brand.Widget.WidgetTypeEnum}
 */
proto.wilqo.api.brand.Widget.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.Widget.WidgetTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Widget.WidgetTypeEnum} value
 * @return {!proto.wilqo.api.brand.Widget} returns this
 */
proto.wilqo.api.brand.Widget.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * map<string, string> additional_settings = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.brand.Widget.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.brand.Widget} returns this
 */
proto.wilqo.api.brand.Widget.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    leftIcon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rightIcon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : [],
    messageTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    leftImage: jspb.Message.getFieldWithDefault(msg, 7, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    row: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Action}
 */
proto.wilqo.api.brand.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Action;
  return proto.wilqo.api.brand.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Action}
 */
proto.wilqo.api.brand.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.brand.Action.ActionTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightIcon(value);
      break;
    case 5:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftImage(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLeftIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRightIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getMessageTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLeftImage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getRow();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.Action.ActionTypeEnum = {
  UNKNOWN: 0,
  CREATE_LOAN: 1,
  CONTINUE_APPLICATION: 2,
  CONTINUE_CONSENTS: 3,
  REVIEW_INFO: 4,
  DEFAULT: 5,
  INTERVIEW: 6
};

/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Action.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActionTypeEnum type = 2;
 * @return {!proto.wilqo.api.brand.Action.ActionTypeEnum}
 */
proto.wilqo.api.brand.Action.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.Action.ActionTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.brand.Action.ActionTypeEnum} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string left_icon = 3;
 * @return {string}
 */
proto.wilqo.api.brand.Action.prototype.getLeftIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setLeftIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string right_icon = 4;
 * @return {string}
 */
proto.wilqo.api.brand.Action.prototype.getRightIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setRightIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> additional_settings = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.brand.Action.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};


/**
 * optional string message_title = 6;
 * @return {string}
 */
proto.wilqo.api.brand.Action.prototype.getMessageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setMessageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string left_image = 7;
 * @return {string}
 */
proto.wilqo.api.brand.Action.prototype.getLeftImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setLeftImage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 8;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.brand.Action.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 8));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.brand.Action} returns this
*/
proto.wilqo.api.brand.Action.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.Action.prototype.hasError = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 row = 9;
 * @return {number}
 */
proto.wilqo.api.brand.Action.prototype.getRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.brand.Action} returns this
 */
proto.wilqo.api.brand.Action.prototype.setRow = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.PageData.repeatedFields_ = [2,3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.PageData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.PageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.PageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.PageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    layoutsList: jspb.Message.toObjectList(msg.getLayoutsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    widgetsList: jspb.Message.toObjectList(msg.getWidgetsList(),
    proto.wilqo.api.brand.Widget.toObject, includeInstance),
    layoutType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sidebarsList: jspb.Message.toObjectList(msg.getSidebarsList(),
    proto.wilqo.api.brand.Sidebar.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.wilqo.api.brand.Action.toObject, includeInstance),
    header: (f = msg.getHeader()) && proto.wilqo.api.brand.HomePageHeader.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.PageData}
 */
proto.wilqo.api.brand.PageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.PageData;
  return proto.wilqo.api.brand.PageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.PageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.PageData}
 */
proto.wilqo.api.brand.PageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addLayouts(value);
      break;
    case 3:
      var value = new proto.wilqo.api.brand.Widget;
      reader.readMessage(value,proto.wilqo.api.brand.Widget.deserializeBinaryFromReader);
      msg.addWidgets(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLayoutType(value);
      break;
    case 5:
      var value = new proto.wilqo.api.brand.Sidebar;
      reader.readMessage(value,proto.wilqo.api.brand.Sidebar.deserializeBinaryFromReader);
      msg.addSidebars(value);
      break;
    case 6:
      var value = new proto.wilqo.api.brand.Action;
      reader.readMessage(value,proto.wilqo.api.brand.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 10:
      var value = new proto.wilqo.api.brand.HomePageHeader;
      reader.readMessage(value,proto.wilqo.api.brand.HomePageHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.PageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.PageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.PageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.PageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLayoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.brand.Widget.serializeBinaryToWriter
    );
  }
  f = message.getLayoutType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSidebarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.brand.Sidebar.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.brand.Action.serializeBinaryToWriter
    );
  }
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.wilqo.api.brand.HomePageHeader.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.brand.PageData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated google.protobuf.Int32Value layouts = 2;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.wilqo.api.brand.PageData.prototype.getLayoutsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
*/
proto.wilqo.api.brand.PageData.prototype.setLayoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.brand.PageData.prototype.addLayouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.clearLayoutsList = function() {
  return this.setLayoutsList([]);
};


/**
 * repeated Widget widgets = 3;
 * @return {!Array<!proto.wilqo.api.brand.Widget>}
 */
proto.wilqo.api.brand.PageData.prototype.getWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Widget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Widget, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Widget>} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
*/
proto.wilqo.api.brand.PageData.prototype.setWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.brand.Widget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Widget}
 */
proto.wilqo.api.brand.PageData.prototype.addWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.brand.Widget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.clearWidgetsList = function() {
  return this.setWidgetsList([]);
};


/**
 * optional string layout_type = 4;
 * @return {string}
 */
proto.wilqo.api.brand.PageData.prototype.getLayoutType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.setLayoutType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Sidebar sidebars = 5;
 * @return {!Array<!proto.wilqo.api.brand.Sidebar>}
 */
proto.wilqo.api.brand.PageData.prototype.getSidebarsList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Sidebar>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Sidebar, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Sidebar>} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
*/
proto.wilqo.api.brand.PageData.prototype.setSidebarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.brand.Sidebar=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Sidebar}
 */
proto.wilqo.api.brand.PageData.prototype.addSidebars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.brand.Sidebar, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.clearSidebarsList = function() {
  return this.setSidebarsList([]);
};


/**
 * repeated Action actions = 6;
 * @return {!Array<!proto.wilqo.api.brand.Action>}
 */
proto.wilqo.api.brand.PageData.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Action, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Action>} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
*/
proto.wilqo.api.brand.PageData.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.brand.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Action}
 */
proto.wilqo.api.brand.PageData.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.brand.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional HomePageHeader header = 10;
 * @return {?proto.wilqo.api.brand.HomePageHeader}
 */
proto.wilqo.api.brand.PageData.prototype.getHeader = function() {
  return /** @type{?proto.wilqo.api.brand.HomePageHeader} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.HomePageHeader, 10));
};


/**
 * @param {?proto.wilqo.api.brand.HomePageHeader|undefined} value
 * @return {!proto.wilqo.api.brand.PageData} returns this
*/
proto.wilqo.api.brand.PageData.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.PageData} returns this
 */
proto.wilqo.api.brand.PageData.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.PageData.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageData: (f = msg.getPageData()) && proto.wilqo.api.brand.PageData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.Route}
 */
proto.wilqo.api.brand.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.Route;
  return proto.wilqo.api.brand.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.Route}
 */
proto.wilqo.api.brand.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = new proto.wilqo.api.brand.PageData;
      reader.readMessage(value,proto.wilqo.api.brand.PageData.deserializeBinaryFromReader);
      msg.setPageData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.brand.PageData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.wilqo.api.brand.Route.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.Route} returns this
 */
proto.wilqo.api.brand.Route.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageData page_data = 2;
 * @return {?proto.wilqo.api.brand.PageData}
 */
proto.wilqo.api.brand.Route.prototype.getPageData = function() {
  return /** @type{?proto.wilqo.api.brand.PageData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.PageData, 2));
};


/**
 * @param {?proto.wilqo.api.brand.PageData|undefined} value
 * @return {!proto.wilqo.api.brand.Route} returns this
*/
proto.wilqo.api.brand.Route.prototype.setPageData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.Route} returns this
 */
proto.wilqo.api.brand.Route.prototype.clearPageData = function() {
  return this.setPageData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.Route.prototype.hasPageData = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.PageConfiguration.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.PageConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.PageConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.PageConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    header: (f = msg.getHeader()) && proto.wilqo.api.brand.Header.toObject(includeInstance, f),
    footer: (f = msg.getFooter()) && proto.wilqo.api.brand.Footer.toObject(includeInstance, f),
    navigationList: jspb.Message.toObjectList(msg.getNavigationList(),
    proto.wilqo.api.brand.Navigation.toObject, includeInstance),
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    proto.wilqo.api.brand.Route.toObject, includeInstance),
    businessprocessdomainid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    roleMask: msg.getRoleMask_asB64(),
    loanOverviewNavigation: (f = msg.getLoanOverviewNavigation()) && proto.wilqo.api.brand.Navigation.toObject(includeInstance, f),
    actionNavigation: (f = msg.getActionNavigation()) && proto.wilqo.api.brand.Navigation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.PageConfiguration}
 */
proto.wilqo.api.brand.PageConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.PageConfiguration;
  return proto.wilqo.api.brand.PageConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.PageConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.PageConfiguration}
 */
proto.wilqo.api.brand.PageConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.brand.Header;
      reader.readMessage(value,proto.wilqo.api.brand.Header.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 4:
      var value = new proto.wilqo.api.brand.Footer;
      reader.readMessage(value,proto.wilqo.api.brand.Footer.deserializeBinaryFromReader);
      msg.setFooter(value);
      break;
    case 5:
      var value = new proto.wilqo.api.brand.Navigation;
      reader.readMessage(value,proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader);
      msg.addNavigation(value);
      break;
    case 6:
      var value = new proto.wilqo.api.brand.Route;
      reader.readMessage(value,proto.wilqo.api.brand.Route.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessprocessdomainid(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRoleMask(value);
      break;
    case 9:
      var value = new proto.wilqo.api.brand.Navigation;
      reader.readMessage(value,proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader);
      msg.setLoanOverviewNavigation(value);
      break;
    case 10:
      var value = new proto.wilqo.api.brand.Navigation;
      reader.readMessage(value,proto.wilqo.api.brand.Navigation.deserializeBinaryFromReader);
      msg.setActionNavigation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.PageConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.PageConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.PageConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.brand.Header.serializeBinaryToWriter
    );
  }
  f = message.getFooter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.brand.Footer.serializeBinaryToWriter
    );
  }
  f = message.getNavigationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.brand.Navigation.serializeBinaryToWriter
    );
  }
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.brand.Route.serializeBinaryToWriter
    );
  }
  f = message.getBusinessprocessdomainid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRoleMask_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getLoanOverviewNavigation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.brand.Navigation.serializeBinaryToWriter
    );
  }
  f = message.getActionNavigation();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.wilqo.api.brand.Navigation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Header header = 3;
 * @return {?proto.wilqo.api.brand.Header}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getHeader = function() {
  return /** @type{?proto.wilqo.api.brand.Header} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.Header, 3));
};


/**
 * @param {?proto.wilqo.api.brand.Header|undefined} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Footer footer = 4;
 * @return {?proto.wilqo.api.brand.Footer}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getFooter = function() {
  return /** @type{?proto.wilqo.api.brand.Footer} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.Footer, 4));
};


/**
 * @param {?proto.wilqo.api.brand.Footer|undefined} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setFooter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearFooter = function() {
  return this.setFooter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.hasFooter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Navigation navigation = 5;
 * @return {!Array<!proto.wilqo.api.brand.Navigation>}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getNavigationList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Navigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Navigation, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Navigation>} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setNavigationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.brand.Navigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.addNavigation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.brand.Navigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearNavigationList = function() {
  return this.setNavigationList([]);
};


/**
 * repeated Route routes = 6;
 * @return {!Array<!proto.wilqo.api.brand.Route>}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.Route>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.Route, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.Route>} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.brand.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.Route}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.brand.Route, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearRoutesList = function() {
  return this.setRoutesList([]);
};


/**
 * optional string businessProcessDomainId = 7;
 * @return {string}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getBusinessprocessdomainid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.setBusinessprocessdomainid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bytes role_mask = 8;
 * @return {string}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getRoleMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes role_mask = 8;
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {string}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getRoleMask_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRoleMask()));
};


/**
 * optional bytes role_mask = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getRoleMask_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRoleMask()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.setRoleMask = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional Navigation loan_overview_navigation = 9;
 * @return {?proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getLoanOverviewNavigation = function() {
  return /** @type{?proto.wilqo.api.brand.Navigation} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.Navigation, 9));
};


/**
 * @param {?proto.wilqo.api.brand.Navigation|undefined} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setLoanOverviewNavigation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearLoanOverviewNavigation = function() {
  return this.setLoanOverviewNavigation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.hasLoanOverviewNavigation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Navigation action_navigation = 10;
 * @return {?proto.wilqo.api.brand.Navigation}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.getActionNavigation = function() {
  return /** @type{?proto.wilqo.api.brand.Navigation} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.Navigation, 10));
};


/**
 * @param {?proto.wilqo.api.brand.Navigation|undefined} value
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
*/
proto.wilqo.api.brand.PageConfiguration.prototype.setActionNavigation = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.PageConfiguration} returns this
 */
proto.wilqo.api.brand.PageConfiguration.prototype.clearActionNavigation = function() {
  return this.setActionNavigation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.PageConfiguration.prototype.hasActionNavigation = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.SidebarItemConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.SidebarItemConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.SidebarItemConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    sidebartype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.SidebarItemConfig}
 */
proto.wilqo.api.brand.SidebarItemConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.SidebarItemConfig;
  return proto.wilqo.api.brand.SidebarItemConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.SidebarItemConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.SidebarItemConfig}
 */
proto.wilqo.api.brand.SidebarItemConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.brand.SidebarItemConfig.SidebarType} */ (reader.readEnum());
      msg.setSidebartype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.SidebarItemConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.SidebarItemConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.SidebarItemConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSidebartype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.SidebarItemConfig.SidebarType = {
  OVERVIEW: 0,
  PARTIES: 1,
  DOCUMENTS: 2,
  PROGRESS: 3,
  COMPLIANCETESTS: 4,
  FRAUDTESTS: 5,
  COMMENTS: 6,
  CHANGEHISTORY: 7,
  HELP: 8,
  TRANSACTION: 9
};

/**
 * optional SidebarType sidebarType = 1;
 * @return {!proto.wilqo.api.brand.SidebarItemConfig.SidebarType}
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.getSidebartype = function() {
  return /** @type {!proto.wilqo.api.brand.SidebarItemConfig.SidebarType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.brand.SidebarItemConfig.SidebarType} value
 * @return {!proto.wilqo.api.brand.SidebarItemConfig} returns this
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.setSidebartype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.SidebarItemConfig} returns this
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.SidebarItemConfig} returns this
 */
proto.wilqo.api.brand.SidebarItemConfig.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.CellConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.CellConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.CellConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.CellConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    display: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badge: jspb.Message.getFieldWithDefault(msg, 4, 0),
    buttonVariant: jspb.Message.getFieldWithDefault(msg, 5, 0),
    linkTo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    buttonLabel: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.CellConfig}
 */
proto.wilqo.api.brand.CellConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.CellConfig;
  return proto.wilqo.api.brand.CellConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.CellConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.CellConfig}
 */
proto.wilqo.api.brand.CellConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.brand.CellConfig.CellTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplay(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.brand.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setBadge(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum} */ (reader.readEnum());
      msg.setButtonVariant(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.CellConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.CellConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.CellConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.CellConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDisplay();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadge();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getButtonVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getButtonLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.CellConfig.CellTypeEnum = {
  BADGE: 0,
  BUTTON: 1,
  ICON: 2,
  LINK: 3,
  TEXT: 4,
  TOGGLE: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum = {
  PRIMARY: 0,
  SECONDARY: 1,
  TERTIARY: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.CellConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CellTypeEnum type = 2;
 * @return {!proto.wilqo.api.brand.CellConfig.CellTypeEnum}
 */
proto.wilqo.api.brand.CellConfig.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.brand.CellConfig.CellTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.brand.CellConfig.CellTypeEnum} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string display = 3;
 * @return {string}
 */
proto.wilqo.api.brand.CellConfig.prototype.getDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setDisplay = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UxDisplayStatusEnum badge = 4;
 * @return {!proto.wilqo.api.brand.UxDisplayStatusEnum}
 */
proto.wilqo.api.brand.CellConfig.prototype.getBadge = function() {
  return /** @type {!proto.wilqo.api.brand.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.brand.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setBadge = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ButtonVariantTypeEnum button_variant = 5;
 * @return {!proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum}
 */
proto.wilqo.api.brand.CellConfig.prototype.getButtonVariant = function() {
  return /** @type {!proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.brand.CellConfig.ButtonVariantTypeEnum} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setButtonVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string link_to = 6;
 * @return {string}
 */
proto.wilqo.api.brand.CellConfig.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string button_label = 7;
 * @return {string}
 */
proto.wilqo.api.brand.CellConfig.prototype.getButtonLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CellConfig} returns this
 */
proto.wilqo.api.brand.CellConfig.prototype.setButtonLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.ColumnCellConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.ColumnCellConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ColumnCellConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    primary: (f = msg.getPrimary()) && proto.wilqo.api.brand.CellConfig.toObject(includeInstance, f),
    secondary: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tertiary: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.ColumnCellConfig}
 */
proto.wilqo.api.brand.ColumnCellConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.ColumnCellConfig;
  return proto.wilqo.api.brand.ColumnCellConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.ColumnCellConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.ColumnCellConfig}
 */
proto.wilqo.api.brand.ColumnCellConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.brand.CellConfig;
      reader.readMessage(value,proto.wilqo.api.brand.CellConfig.deserializeBinaryFromReader);
      msg.setPrimary(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondary(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.brand.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setTertiary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.ColumnCellConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.ColumnCellConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ColumnCellConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrimary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.brand.CellConfig.serializeBinaryToWriter
    );
  }
  f = message.getSecondary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTertiary();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional CellConfig primary = 1;
 * @return {?proto.wilqo.api.brand.CellConfig}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.getPrimary = function() {
  return /** @type{?proto.wilqo.api.brand.CellConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.CellConfig, 1));
};


/**
 * @param {?proto.wilqo.api.brand.CellConfig|undefined} value
 * @return {!proto.wilqo.api.brand.ColumnCellConfig} returns this
*/
proto.wilqo.api.brand.ColumnCellConfig.prototype.setPrimary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.ColumnCellConfig} returns this
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.clearPrimary = function() {
  return this.setPrimary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.hasPrimary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string secondary = 2;
 * @return {string}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.getSecondary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ColumnCellConfig} returns this
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.setSecondary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UxDisplayStatusEnum tertiary = 3;
 * @return {!proto.wilqo.api.brand.UxDisplayStatusEnum}
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.getTertiary = function() {
  return /** @type {!proto.wilqo.api.brand.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.brand.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.brand.ColumnCellConfig} returns this
 */
proto.wilqo.api.brand.ColumnCellConfig.prototype.setTertiary = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.ColumnConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.ColumnConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ColumnConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cellConfig: (f = msg.getCellConfig()) && proto.wilqo.api.brand.ColumnCellConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.ColumnConfig}
 */
proto.wilqo.api.brand.ColumnConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.ColumnConfig;
  return proto.wilqo.api.brand.ColumnConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.ColumnConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.ColumnConfig}
 */
proto.wilqo.api.brand.ColumnConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.wilqo.api.brand.ColumnCellConfig;
      reader.readMessage(value,proto.wilqo.api.brand.ColumnCellConfig.deserializeBinaryFromReader);
      msg.setCellConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.ColumnConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.ColumnConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ColumnConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCellConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.brand.ColumnCellConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ColumnConfig} returns this
 */
proto.wilqo.api.brand.ColumnConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ColumnConfig} returns this
 */
proto.wilqo.api.brand.ColumnConfig.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ColumnCellConfig cell_config = 3;
 * @return {?proto.wilqo.api.brand.ColumnCellConfig}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.getCellConfig = function() {
  return /** @type{?proto.wilqo.api.brand.ColumnCellConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.brand.ColumnCellConfig, 3));
};


/**
 * @param {?proto.wilqo.api.brand.ColumnCellConfig|undefined} value
 * @return {!proto.wilqo.api.brand.ColumnConfig} returns this
*/
proto.wilqo.api.brand.ColumnConfig.prototype.setCellConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.ColumnConfig} returns this
 */
proto.wilqo.api.brand.ColumnConfig.prototype.clearCellConfig = function() {
  return this.setCellConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.ColumnConfig.prototype.hasCellConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.TableFilterConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.TableFilterConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.TableFilterConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.TableFilterConfig}
 */
proto.wilqo.api.brand.TableFilterConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.TableFilterConfig;
  return proto.wilqo.api.brand.TableFilterConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.TableFilterConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.TableFilterConfig}
 */
proto.wilqo.api.brand.TableFilterConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.TableFilterConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.TableFilterConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.TableFilterConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.TableFilterConfig} returns this
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.TableFilterConfig} returns this
 */
proto.wilqo.api.brand.TableFilterConfig.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.TableConfig.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.TableConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.TableConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.TableConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.TableConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    proto.wilqo.api.brand.TableFilterConfig.toObject, includeInstance),
    columnsList: jspb.Message.toObjectList(msg.getColumnsList(),
    proto.wilqo.api.brand.ColumnConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.TableConfig}
 */
proto.wilqo.api.brand.TableConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.TableConfig;
  return proto.wilqo.api.brand.TableConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.TableConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.TableConfig}
 */
proto.wilqo.api.brand.TableConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.wilqo.api.brand.TableFilterConfig;
      reader.readMessage(value,proto.wilqo.api.brand.TableFilterConfig.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    case 3:
      var value = new proto.wilqo.api.brand.ColumnConfig;
      reader.readMessage(value,proto.wilqo.api.brand.ColumnConfig.deserializeBinaryFromReader);
      msg.addColumns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.TableConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.TableConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.TableConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.TableConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.brand.TableFilterConfig.serializeBinaryToWriter
    );
  }
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.brand.ColumnConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.brand.TableConfig.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.TableConfig} returns this
 */
proto.wilqo.api.brand.TableConfig.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TableFilterConfig filters = 2;
 * @return {!Array<!proto.wilqo.api.brand.TableFilterConfig>}
 */
proto.wilqo.api.brand.TableConfig.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.TableFilterConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.TableFilterConfig, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.TableFilterConfig>} value
 * @return {!proto.wilqo.api.brand.TableConfig} returns this
*/
proto.wilqo.api.brand.TableConfig.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.brand.TableFilterConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.TableFilterConfig}
 */
proto.wilqo.api.brand.TableConfig.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.brand.TableFilterConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.TableConfig} returns this
 */
proto.wilqo.api.brand.TableConfig.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};


/**
 * repeated ColumnConfig columns = 3;
 * @return {!Array<!proto.wilqo.api.brand.ColumnConfig>}
 */
proto.wilqo.api.brand.TableConfig.prototype.getColumnsList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.ColumnConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.ColumnConfig, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.ColumnConfig>} value
 * @return {!proto.wilqo.api.brand.TableConfig} returns this
*/
proto.wilqo.api.brand.TableConfig.prototype.setColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.brand.ColumnConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.ColumnConfig}
 */
proto.wilqo.api.brand.TableConfig.prototype.addColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.brand.ColumnConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.TableConfig} returns this
 */
proto.wilqo.api.brand.TableConfig.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.DescriptionListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.DescriptionListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.DescriptionListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.DescriptionListItem}
 */
proto.wilqo.api.brand.DescriptionListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.DescriptionListItem;
  return proto.wilqo.api.brand.DescriptionListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.DescriptionListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.DescriptionListItem}
 */
proto.wilqo.api.brand.DescriptionListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.DescriptionListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.DescriptionListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.DescriptionListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.DescriptionListItem} returns this
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.DescriptionListItem} returns this
 */
proto.wilqo.api.brand.DescriptionListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.brand.CardConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.CardConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.CardConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.CardConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.CardConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descriptionListList: jspb.Message.toObjectList(msg.getDescriptionListList(),
    proto.wilqo.api.brand.DescriptionListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.CardConfig}
 */
proto.wilqo.api.brand.CardConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.CardConfig;
  return proto.wilqo.api.brand.CardConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.CardConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.CardConfig}
 */
proto.wilqo.api.brand.CardConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.wilqo.api.brand.DescriptionListItem;
      reader.readMessage(value,proto.wilqo.api.brand.DescriptionListItem.deserializeBinaryFromReader);
      msg.addDescriptionList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.CardConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.CardConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.CardConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.CardConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescriptionListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.brand.DescriptionListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.brand.CardConfig.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CardConfig} returns this
 */
proto.wilqo.api.brand.CardConfig.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.brand.CardConfig.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.CardConfig} returns this
 */
proto.wilqo.api.brand.CardConfig.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DescriptionListItem description_list = 3;
 * @return {!Array<!proto.wilqo.api.brand.DescriptionListItem>}
 */
proto.wilqo.api.brand.CardConfig.prototype.getDescriptionListList = function() {
  return /** @type{!Array<!proto.wilqo.api.brand.DescriptionListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.brand.DescriptionListItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.brand.DescriptionListItem>} value
 * @return {!proto.wilqo.api.brand.CardConfig} returns this
*/
proto.wilqo.api.brand.CardConfig.prototype.setDescriptionListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.brand.DescriptionListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.brand.DescriptionListItem}
 */
proto.wilqo.api.brand.CardConfig.prototype.addDescriptionList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.brand.DescriptionListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.brand.CardConfig} returns this
 */
proto.wilqo.api.brand.CardConfig.prototype.clearDescriptionListList = function() {
  return this.setDescriptionListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.brand.ConsumerActionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.brand.ConsumerActionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ConsumerActionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentusername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    leftImage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.brand.ConsumerActionData}
 */
proto.wilqo.api.brand.ConsumerActionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.brand.ConsumerActionData;
  return proto.wilqo.api.brand.ConsumerActionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.brand.ConsumerActionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.brand.ConsumerActionData}
 */
proto.wilqo.api.brand.ConsumerActionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentusername(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.brand.ConsumerActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftImage(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.brand.ConsumerActionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.brand.ConsumerActionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.brand.ConsumerActionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentusername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMessageTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLeftImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currentUserName = 1;
 * @return {string}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.getCurrentusername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.setCurrentusername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConsumerActionType action_type = 2;
 * @return {!proto.wilqo.api.brand.ConsumerActionType}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.getActionType = function() {
  return /** @type {!proto.wilqo.api.brand.ConsumerActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.brand.ConsumerActionType} value
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string message_title = 3;
 * @return {string}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.getMessageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.setMessageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string left_image = 4;
 * @return {string}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.getLeftImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.setLeftImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 5;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 5));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
*/
proto.wilqo.api.brand.ConsumerActionData.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.brand.ConsumerActionData} returns this
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.brand.ConsumerActionData.prototype.hasError = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.api.brand.ConfigurationType = {
  CONFIGURATION_TYPE_UNKNOWN: 0,
  BORROWER_NAME: 1,
  ENTITY_ID: 2,
  LOAN_NUMBER: 3,
  PROPERTY_ADDRESS: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.brand.UxDisplayStatusEnum = {
  UX_DISPLAY_STATUS_ENUM_NEW: 0,
  UX_DISPLAY_STATUS_ENUM_PROGRESS: 1,
  UX_DISPLAY_STATUS_ENUM_SUCCESS: 2,
  UX_DISPLAY_STATUS_ENUM_DANGER: 3,
  UX_DISPLAY_STATUS_ENUM_RESTING: 4,
  UX_DISPLAY_STATUS_ENUM_COMPLETE: 5,
  UX_DISPLAY_STATUS_ENUM_WARNING: 6,
  UX_DISPLAY_STATUS_ENUM_CRITICAL: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.brand.ConsumerActionType = {
  CONSUMER_ACTION_TYPE_UNKNOWN: 0,
  CONSUMER_ACTION_TYPE_CONTINUE: 1,
  CONSUMER_ACTION_TYPE_CONSENTS: 2,
  CONSUMER_ACTION_TYPE_CONTINUE_PRE_QUAL: 3,
  CONSUMER_ACTION_TYPE_REVIEW: 4,
  CONSUMER_ACTION_TYPE_DEFAULT: 5
};

goog.object.extend(exports, proto.wilqo.api.brand);
