import clsx from 'clsx';
import { useEffect, useState } from 'react';

import type { IndividualFeePayment, LoanFee, LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import { Chip } from '@/Components/Atoms/Chip';
import { Icon } from '@/Components/Atoms/Icon';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import {
  canTotalBeEditedOnFeeType,
  ColumnEnum,
  emptyValue,
  INDIVIDUAL_FEES_COLUMNS,
  mapPaymentChanges,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import type { TableContent } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import {
  createTableContent,
  PAYMENT_PORTIONS_OF_FEE,
  validatePaymentValues,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import { convertProtoToCurrency } from '@/Utils/Helpers/numberFormatter';

interface IFeePaidBy {
  fee: LoanFee.AsObject;
  feePayment?: IndividualFeePayment.AsObject;
  total?: number;
  categoryType: LoanFeeCategoryTypeEnum;
  isChipHidden?: (inputString: string) => boolean;
  addError: (error: string) => void;
  removeError: (error: string) => void;
  errorState: string[];
}

export const FeePaidBy = (props: IFeePaidBy) => {
  const { addError, categoryType, errorState, fee, feePayment, isChipHidden, removeError, total } = props;
  const { handleBlurInput, sellerTotalExceeded } = useFeesContext();
  const [flag, setFlag] = useState<{ value: number }>({ value: 0 });
  const [dataTable, setDataTable] = useState<Array<TableContent>>();

  const isChipHiddenSafe = (payer: string) => isChipHidden && isChipHidden(payer);

  const captions: string[] = [
    'Only timing of payments can be made on this modal; to change or add payer, navigate back to the edit modal. ',
    'Fee totals cannot be edited in this dialog.',
  ];

  useEffect(() => {
    if (!fee || !feePayment || !total) return;
    setDataTable(createTableContent(feePayment));
  }, [fee, feePayment, total, flag]);

  const handleSendUpdatedValues = (updatedValue: string, column: string, editedRowData: TableContent) => {
    const EditFeeColumn = `${editedRowData.payer.charAt(0).toLowerCase()}${editedRowData.payer.slice(1)}Paid`;
    setFlag((prevObj) => ({ ...prevObj, value: prevObj.value + 1 }));

    if (PAYMENT_PORTIONS_OF_FEE.includes(column)) {
      mapPaymentChanges(updatedValue, column, editedRowData, fee, categoryType);
    } else {
      handleBlurInput(updatedValue, { key: EditFeeColumn }, fee, undefined, undefined, categoryType);
    }
    validatePaymentValues(editedRowData.payer, feePayment, addError, removeError);
  };
  return (
    <Card
      defaultOpen
      headerProps={{
        caption: canTotalBeEditedOnFeeType(fee.basedOn) ? captions.join(' ') : captions[0],
        title: 'Fee paid by',
      }}
      variant="border"
    >
      <div className="relative">
        <table className="w-full p-2">
          <thead className="border-b border-b-on-surface-stroke">
            <tr>
              {INDIVIDUAL_FEES_COLUMNS.map((column, index) => (
                <th
                  key={column.id}
                  className={clsx('px-3 py-3 text-center border-r border-r-on-surface-stroke', {
                    '!border-r-0': index === INDIVIDUAL_FEES_COLUMNS.length - 1,
                    '!text-left w-[300px]': index === 0,
                  })}
                  scope="col"
                >
                  <Typography className="uppercase text-on-surface-inactive" variant="overline">
                    {column.header}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {
              dataTable?.map((rowData) => !isChipHiddenSafe(rowData.payer) && (
              <tr
                key={rowData.payer}
                className="border-b border-b-on-surface-stroke"
              >
                {INDIVIDUAL_FEES_COLUMNS.map((column, columnIndex) => {
                  const isFirstColumn = columnIndex === 0;
                  const isLastColumn = columnIndex === INDIVIDUAL_FEES_COLUMNS.length - 1;
                  const value = rowData[column.id as keyof typeof rowData] || 0;
                  const negativePaidAtClosingError = isLastColumn && Number(value) < 0;

                  if (isFirstColumn) {
                    return (
                      <td
                        key={column.id}
                        className="text-left px-3 py-4 min-w-[250px]"
                      >
                        <Chip label={rowData.payer} />
                      </td>
                    );
                  }
                  if (isLastColumn) {
                    return (
                      <td
                        key={column.id}
                        className="px-3 min-w-[200px] border-l"
                      >
                        <div className="block">
                          {
                            (sellerTotalExceeded && rowData.payer === 'Seller') && (
                              <div className="absolute">
                                <Tooltip message="Seller credit exceeded Total amount paid">
                                  <Icon className="!text-danger" icon="Error" />
                                </Tooltip>
                              </div>
                            )
                          }
                          <div>
                            {
                              negativePaidAtClosingError && (
                                <div className="absolute">
                                  <Tooltip message="Paid at closing cannot be negative">
                                    <Icon className="!text-danger" icon="Error" />
                                  </Tooltip>
                                </div>
                              )
                            }
                          </div>
                          <div className="flex-grow">
                            <Typography className="text-on-surface-active text-center" variant="body2">
                              {convertProtoToCurrency(value)}
                            </Typography>
                          </div>
                        </div>
                      </td>
                    );
                  }

                  const disabled = (canTotalBeEditedOnFeeType(fee.basedOn) && column.id === ColumnEnum.TOTAL_AMOUNT)
                    || ((rowData.payer !== 'Seller' && rowData.payer !== 'Lender') && column.id === ColumnEnum.CREDIT_AMOUNT)
                    || (rowData.payer !== 'Borrower' && column.id === ColumnEnum.FINANCED_AMOUNT)
                    || (fee.specialCase > 2);

                  const errorKey = column.id.charAt(0).toUpperCase() + column.id.slice(1);
                  const isInErrorState = errorState.some((error) => error.includes(errorKey));

                  return (
                    <td
                      key={column.id}
                      className={clsx('px-3 py-4 border', {
                        '!border-danger': isInErrorState,
                        'border-r-0': !isInErrorState,
                      })}
                      id={column.id}
                    >
                      <Input
                        disabled={disabled}
                        mask={MaskType.CURRENCY}
                        onBlur={(e) => handleSendUpdatedValues(e.target.value, column.id, rowData)}
                        value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                      />
                    </td>
                  );
                })}
              </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Card>
  );
};
