import type { BusinessProcessTemplateDesignSummary } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb';
import { useAddActivityManually } from '@/API/Queries/mortgage/useAddActivityManually';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { AddActivity } from './AddActivity';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export const AddActivityLoan = (props: Props) => {
  const { showSnackBar } = useShared();
  const { data: loan } = useLoan();

  const { isLoading, mutate: addActivity } = useAddActivityManually();

  const handleAddSuccess = () => {
    showSnackBar('Added Successfully');
    props.onClose();
  };

  const handleOnClickFinish = (selectedActivities: BusinessProcessTemplateDesignSummary.AsObject[]) => {
    addActivity(
      {
        conditionId: '',
        dealId: loan?.dealId || '',
        loanId: loan?.loanId || '',
        templateIdsList: selectedActivities.map((activity) => activity.id),
      },
      {
        onError: (error) => showSnackBar(error.message || 'Something went wrong'),
        onSuccess: handleAddSuccess,
      },
    );
  };

  return (
    <AddActivity
      isLoading={isLoading}
      onClickFinish={handleOnClickFinish}
      onClose={props.onClose}
      open={props.open}
    />
  );
};
