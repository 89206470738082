import clsx from 'clsx';
import type { MouseEvent, MutableRefObject, Ref } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import { Icon } from '@/Components/Atoms/Icon';

const useCombinedRefs = (...refs: any): MutableRefObject<any> => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const TableCheckbox = forwardRef<any, any>(
  ({ header, indeterminate, ...rest }, ref: Ref<HTMLInputElement>) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    const handleCheckboxClick = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      rest.onChange(event);
    };

    return (
      <div
        className={
          clsx({
            '[&_svg]:text-primary-on-surface': rest.checked,
            'py-2': header,
          })
        }
        onClick={handleCheckboxClick}
        onKeyDown={() => null}
        role="button"
        tabIndex={-1}
      >
        <input
          ref={combinedRef}
          checked={rest.checked}
          className="hidden"
          type="checkbox"
          {...rest}
        />
        <Icon
          icon={rest.checked ? 'CheckBox' : 'CheckBoxBlank'}
        />
      </div>
    );
  },
);

TableCheckbox.displayName = 'TableCheckbox';
