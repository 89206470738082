// source: Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../../../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AttachmentType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldAttachment', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldAttachment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldAttachment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    helpText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    attach: (f = msg.getAttach()) && proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.toObject(includeInstance, f),
    staticValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField;
  return proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldAttachment;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.deserializeBinaryFromReader);
      msg.setAttach(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaticValue(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAttach();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.serializeBinaryToWriter
    );
  }
  f = message.getStaticValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string leading_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 4;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 4));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 5;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string help_text = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool required = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional FieldAttachment attach = 8;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FieldAttachment}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getAttach = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldAttachment, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FieldAttachment|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setAttach = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.clearAttach = function() {
  return this.setAttach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.hasAttach = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string static_value = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getStaticValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setStaticValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool hidden = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceItem: (f = msg.getSourceItem()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldAttachment;
  return proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.AttachmentType} */ (reader.readEnum());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setSourceItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional AttachmentType position = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AttachmentType}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.getPosition = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.AttachmentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AttachmentType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.setPosition = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string source_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement source_item = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.getSourceItem = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.setSourceItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldAttachment} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.clearSourceItem = function() {
  return this.setSourceItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldAttachment.prototype.hasSourceItem = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.repeatedFields_ = [3,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.toObject, includeInstance),
    helpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    renderType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    hyperLinksList: jspb.Message.toObjectList(msg.getHyperLinksList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent;
  return proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (reader.readEnum());
      msg.setRenderType(value);
      break;
    case 11:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.deserializeBinaryFromReader);
      msg.addHyperLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRenderType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getHyperLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FormCircuitComponentField fields = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string help_text = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CustomRenderType render_type = 5;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getRenderType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setRenderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated HyperLink hyper_links = 11;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.getHyperLinksList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink, 11));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.setHyperLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.addHyperLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.api.mortgage_dynamic_data.HyperLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FormCircuitComponent.prototype.clearHyperLinksList = function() {
  return this.setHyperLinksList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.AttachmentType = {
  ATTACHMENT_TYPE_NONE: 0,
  ATTACHMENT_TYPE_ABOVE: 1,
  ATTACHMENT_TYPE_BELOW: 2,
  ATTACHMENT_TYPE_RIGHT: 3,
  ATTACHMENT_TYPE_LEFT: 4
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
