import { indexOf } from 'lodash';
import { useMemo, useState } from 'react';

import type { FeeTemplateRuleGroupParameter } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb';
import { FeeCalculationTypeEnum, FeePercentBasisEnum } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import Select from '@/Components/Atoms/Select/Select.component';
import { ConvertEnum, getEnumFromParsedValue } from '@/Utils/Helpers/enumHelpers';
import { convertProtoToCurrency } from '@/Utils/Helpers/numberFormatter';
import { ConvertNumberToProtoDecimal, ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';

import { useTriggerContext } from './TriggerContext';

interface FeeParametersProps {
  groupId: string;
  feeParameters?: FeeTemplateRuleGroupParameter.AsObject;
}

const FeeCalculationTypeEnumBlacklist = [
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_UNKNOWN,
];

const FeePercentBasisEnumBlacklist = [
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_UNKNOWN,
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_OTHER,
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_FIXED,
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_VARIABLE,
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_ORIGINAL_LOAN_AMOUNT,
];

const FeeTypeRequiresFixedAmount = [
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED,
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT,
];

const FeeTypeRequiresPercent = [
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_PERCENT,
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT,
];

const FeeTypeRequiresDefaultAmount = [
  FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_VARIABLE,
];

const calculationTypeOptions = Object
  .values(FeeCalculationTypeEnum)
  .filter((f) => indexOf(FeeCalculationTypeEnumBlacklist, f) < 0)
  .map((enumValue) => ({ value: ConvertEnum(FeeCalculationTypeEnum, enumValue as FeeCalculationTypeEnum) }));

const percentBasisOptions = Object
  .values(FeePercentBasisEnum)
  .filter((f) => indexOf(FeePercentBasisEnumBlacklist, f) < 0)
  .map((enumValue) => ({ value: ConvertEnum(FeePercentBasisEnum, enumValue as FeePercentBasisEnum) }));

export function FeeParameters(props: FeeParametersProps) {
  const { feeParameters, groupId } = props;
  const { updateRuleGroupFees } = useTriggerContext();

  const parameters: FeeTemplateRuleGroupParameter.AsObject = useMemo(() => {
    const zeroProtoDecimal = ConvertNumberToProtoDecimal(0).toObject();

    return {
      feeCalculationType: feeParameters?.feeCalculationType || FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED,
      feePercentBasisType: feeParameters?.feePercentBasisType || FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_NOTE_AMOUNT,
      feeSpecifiedFixedAmount: feeParameters?.feeSpecifiedFixedAmount || zeroProtoDecimal,
      feeTotalPercent: feeParameters?.feeTotalPercent || zeroProtoDecimal,
      ruleGroupSummaryId: feeParameters?.ruleGroupSummaryId || '',
    };
  }, [feeParameters]);

  const fixedAmountIsZero = useMemo(() => {
    if (parameters.feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_PERCENT) return false;

    return parameters.feeSpecifiedFixedAmount !== undefined && ConvertProtoDecimalAsObjectToNumber(parameters.feeSpecifiedFixedAmount) === 0;
  }, [parameters]);

  const percentIsZero = useMemo(() => {
    if (parameters.feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED) return false;

    return parameters.feeTotalPercent !== undefined && ConvertProtoDecimalAsObjectToNumber(parameters.feeTotalPercent) === 0;
  }, [parameters]);

  const [currencyInputValue, setCurrencyInputValue] = useState<string>(convertProtoToCurrency(parameters.feeSpecifiedFixedAmount));
  const [percetageInputValue, setPercetageInputValue] = useState<string>(convertProtoToCurrency(parameters.feeTotalPercent));

  const handleInputBlur = (key: 'feeSpecifiedFixedAmount' | 'feeTotalPercent') => {
    if (key === 'feeSpecifiedFixedAmount' && currencyInputValue === '') setCurrencyInputValue('$0');
    else if (key === 'feeTotalPercent' && percetageInputValue === '') setPercetageInputValue('0%');
  };

  const handleChangePercentageBasis = (value: string) => {
    const enumValue = getEnumFromParsedValue(FeePercentBasisEnum, value);
    updateRuleGroupFees(groupId, { ...parameters, feePercentBasisType: enumValue || 0 });
  };

  const handleChangeCalculationType = (value: string) => {
    const enumValue = getEnumFromParsedValue(FeeCalculationTypeEnum, value);
    const protoZero = ConvertNumberToProtoDecimal(0).toObject();
    const withPercent = indexOf(FeeTypeRequiresPercent, enumValue) >= 0;
    const withFixed = indexOf(FeeTypeRequiresFixedAmount, enumValue) >= 0;
    const withDefault = indexOf(FeeTypeRequiresDefaultAmount, enumValue) >= 0;

    const newFeeParameters = { ...parameters, feeCalculationType: enumValue || 0 };

    newFeeParameters.feeTotalPercent = withPercent
      ? newFeeParameters.feeTotalPercent ?? protoZero
      : undefined;

    newFeeParameters.feePercentBasisType = withPercent
      ? newFeeParameters.feePercentBasisType ?? FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_ORIGINAL_LOAN_AMOUNT
      : FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_UNKNOWN;

    newFeeParameters.feeSpecifiedFixedAmount = withFixed || withDefault
      ? newFeeParameters.feeSpecifiedFixedAmount ?? protoZero
      : undefined;

    updateRuleGroupFees(groupId, newFeeParameters);
    setCurrencyInputValue(`$${ConvertProtoDecimalAsObjectToNumber(newFeeParameters.feeSpecifiedFixedAmount || protoZero)}`);
    setPercetageInputValue(`${ConvertProtoDecimalAsObjectToNumber(newFeeParameters.feeTotalPercent || protoZero)}%`);
  };

  const handleChangePercentage = (value: string) => {
    const onlyNumbers = value.replace(/[^0-9.-]/g, '');
    const formattedValue = ConvertNumberToProtoDecimal(Number(onlyNumbers)).toObject();
    updateRuleGroupFees(groupId, { ...parameters, feeTotalPercent: formattedValue });
    setPercetageInputValue(value);
  };

  const handleChangeCurrency = (value: string) => {
    const onlyNumbers = value.replace(/[^0-9.-]/g, '');
    const formattedValue = ConvertNumberToProtoDecimal(Number(onlyNumbers)).toObject();
    updateRuleGroupFees(groupId, { ...parameters, feeSpecifiedFixedAmount: formattedValue });
    setCurrencyInputValue(value);
  };

  const renderFields = () => {
    const showPercent = indexOf(FeeTypeRequiresPercent, parameters.feeCalculationType) >= 0;
    const showFixed = indexOf(FeeTypeRequiresFixedAmount, parameters.feeCalculationType) >= 0;
    const showDefault = indexOf(FeeTypeRequiresDefaultAmount, parameters.feeCalculationType) >= 0;

    return (
      <>
        <div className="grow">
          <Select
            onSelect={(option) => handleChangeCalculationType(option.value)}
            options={calculationTypeOptions}
            placeholder="Amount Value Type"
            titleKey="value"
            value={ConvertEnum(FeeCalculationTypeEnum, parameters.feeCalculationType)}
          />
        </div>
        {showDefault && (
          <div className="grow" onBlur={() => handleInputBlur('feeSpecifiedFixedAmount')}>
            <Input
              label="Default Amount"
              mask={MaskType.CURRENCY}
              onChange={handleChangeCurrency}
              value={currencyInputValue}
            />
          </div>
        )}
        {showFixed && (
          <div
            className="grow"
            onBlur={() => handleInputBlur('feeSpecifiedFixedAmount')}
          >
            <Input
              helperText={fixedAmountIsZero ? 'Value must be > 0' : undefined}
              label="Fixed amount"
              mask={MaskType.CURRENCY}
              onChange={handleChangeCurrency}
              value={currencyInputValue}
            />
          </div>
        )}

        {showPercent && (
          <div
            className="grow"
            onBlur={() => handleInputBlur('feeTotalPercent')}
          >
            <Input
              helperText={percentIsZero ? 'Value must be > 0' : undefined}
              label="Percentage"
              mask={MaskType.PERCENTAGE}
              onChange={handleChangePercentage}
              type="number"
              value={percetageInputValue}
            />
          </div>
        )}
        {showPercent && (
          <div className="grow">
            <Select
              onSelect={(option) => handleChangePercentageBasis(option.value)}
              options={percentBasisOptions}
              placeholder="Percentage Basis Type"
              titleKey="value"
              value={ConvertEnum(FeePercentBasisEnum, parameters.feePercentBasisType)}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full flex gap-4">
      {renderFields()}
    </div>
  );
}
