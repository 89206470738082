import { useQuery } from '@tanstack/react-query';

import { GetLoansCountQueryRequest, GetLoansCountQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import type { QueryOptions } from '@/Utils/Hooks/usePoll';

import { useWilqoMessage } from '../useWilqoMessage';

export const MESSAGE_NAME = 'wilqo.api.mortgage.GetLoansCountQueryRequest';

export const useLoanCount = (options: QueryOptions<number>, id: string) => {
  const sendMessage = useWilqoMessage();

  return useQuery<number | undefined, Error>([MESSAGE_NAME, id], async () => {
    const request = new GetLoansCountQueryRequest();
    request.setUserId(id);
    const response = await sendMessage<GetLoansCountQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetLoansCountQueryResponse,
    );
    return response.accessibleLoansCount;
  }, options);
};
