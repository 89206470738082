import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useState } from 'react';
import { List } from 'react-virtualized';

import { Button } from '../Button';
import { Chip } from '../Chip';
import { Icon } from '../Icon';
import { Menu } from '../Menu';
import { Toggle } from '../Toggle';
import { Typography } from '../Typography';

export interface CardFilterItem {
  id?: any;
  value: string;
  selected: boolean;
  onClick: () => void;
}

export type CardHeaderFilterVariant = 'dropdown' | 'flex' | 'multi-select';

export interface CardHeaderFilterProps {
  variant: CardHeaderFilterVariant;
  items: CardFilterItem[];
  noItemsLabel?: string;
  onRemoveFilter?: () => void;
  onSelectAll?: (items: CardFilterItem[]) => void;
  selectedOptions?: any[];
}

const CardFilters = (props: CardHeaderFilterProps) => {
  const { items, noItemsLabel, onRemoveFilter, onSelectAll, selectedOptions = [], variant } = props;

  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<CardFilterItem[]>(selectedOptions);

  const onClear = () => {
    setSelectedItems([]);
    onSelectAll?.([]);
  };

  const onApply = () => {
    onSelectAll?.(selectedItems);
    setOpen(false);
  };

  const selectedLabel = selectedOptions.length > 1
    ? `${items.find((item) => item.selected)?.value} + ${selectedOptions.length - 1}`
    : items.find((item) => item.selected)?.value;

  const handleOnClickClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onRemoveFilter) onRemoveFilter();
  };

  if (variant === 'flex') {
    return (
      <div className="flex flex-row gap-2 overflow-auto">
        {
        items.map((filter) => (
          <Chip
            key={filter.value}
            label={filter.value}
            onClick={filter.onClick}
            selected={filter.selected}
          />
        ))
      }
      </div>
    );
  }
  if (variant === 'dropdown') {
    return (
      <Menu
        options={items.map((item) => ({ label: item.value, onClick: item.onClick }))}
        triggerItem={(
          <Chip
            label={selectedLabel || noItemsLabel || ''}
            onClickClose={selectedLabel ? handleOnClickClose : undefined}
            rightIcon={!selectedLabel ? 'ArrowDropDown' : undefined}
          />
        )}
      />
    );
  }

  return (
    <DropdownMenu.Root onOpenChange={setOpen} open={open}>
      <DropdownMenu.Trigger>
        <Chip
          label={selectedLabel || noItemsLabel || ''}
          rightIcon="ArrowDropDown"
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        align="start"
        className="shadow-two bg-surface z-50 py-2 min-w-4 w-72"
      >
        <div className="flex flex-row justify-between py-3 px-4 items-center">
          <Typography variant="body1Bold">Select one or more options</Typography>
          <Toggle
            onClick={() => (selectedItems?.length === items.length ? setSelectedItems([]) : setSelectedItems(items))}
            value={selectedItems?.length === items.length}
          />
        </div>
        <List
          autoWidth
          height={208}
          rowCount={items.length}
          rowHeight={48}
          rowRenderer={({ index, key, style }) => {
            const item = items[index];
            const isSelected = selectedItems?.some((option) => item.id === option.id);
            return (
              <DropdownMenu.Item
                key={key}
                className="flex flex-row items-center py-3 px-4"
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedItems((prev) => (prev.some((selectedItem) => selectedItem.id === item.id)
                    ? prev.filter((selectedItem) => selectedItem.id !== item.id)
                    : [...prev, item]));
                }}
                style={style}
              >
                <Icon
                  className="mr-[20px]"
                  icon={!isSelected ? 'CheckBoxBlank' : 'CheckBox'}
                  selected={isSelected}
                  size="md"
                />
                <Typography variant="body2">{item.value}</Typography>
              </DropdownMenu.Item>
            );
          }}
          width={278}
        />

        <div className="flex flex-row justify-end pt-2 gap-1">
          <Button label="Clear" onClick={onClear} variant="secondary" />
          <Button label="Apply" onClick={onApply} variant="tertiary" />
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export { CardFilters };
