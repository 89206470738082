import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateActivityPanelInstanceDetailsCommandRequest, UpdateActivityPanelInstanceDetailsCommandResponse } from '@/API/Models/Wilqo_API_Activity_Commands_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest';

interface UpdateObject {
  activityInternalName: string;
  activityTitle: string;
  entityId?: string;
  entityVersion: number;
  panelHeaderText: string;
  panelId: string;
  panelSequenceId: string;
  panelSummaryText: string;
}

const useUpdateActivityPanelInstanceDetails = () => {
  const { commandResponse } = useBackend();

  const UpdateActivityPanelInstanceDetailsCommand = async (updateObject: UpdateObject) => {
    const {
      activityInternalName,
      activityTitle,
      entityId = '',
      entityVersion,
      panelHeaderText,
      panelId,
      panelSequenceId,
      panelSummaryText,
    } = updateObject;
    const request = new UpdateActivityPanelInstanceDetailsCommandRequest();

    request.setActivityInstanceId(entityId);
    request.setActivityTitle(activityTitle);
    request.setActivityInternalName(activityInternalName);
    request.setActivityVersion(entityVersion);
    request.setPanelHeaderText(panelHeaderText);
    request.setPanelId(panelId);
    request.setPanelSequenceId(panelSequenceId);
    request.setPanelSummaryText(panelSummaryText);
    request.setVersion(1);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new UpdateActivityPanelInstanceDetailsCommandResponse();
    UpdateActivityPanelInstanceDetailsCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpdateActivityPanelInstanceDetailsCommand);
};

export { useUpdateActivityPanelInstanceDetails };
