import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import {
  UpsertLeadSourceConfigCommandRequest, UpsertLeadSourceConfigCommandResponse,
} from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { LeadSource, ReferralSource } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.UpsertLeadSourceConfigCommandRequest';

const useUpsertLeadSource = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const upsertLeadSource = async (updateObject: LeadSource.AsObject) => {
    const request = new UpsertLeadSourceConfigCommandRequest();
    const {
      active,
      id,
      name,
      referralSourcesList,
    } = updateObject;
    const leadSource = new LeadSource();
    leadSource.setActive(active);
    leadSource.setName(name);
    leadSource.setId(id || '');

    if (referralSourcesList) {
      const referralList: ReferralSource[] = [];
      referralSourcesList.forEach((referralSource) => {
        const obj = new ReferralSource();
        obj.setId(referralSource.id || '');
        obj.setActive(referralSource.active);
        obj.setName(referralSource.name);
        referralList.push(obj);
      });

      leadSource.setReferralSourcesList(referralList);
    }

    request.setBusinessProcessDomainId(bpdId);
    request.setLeadSource(leadSource);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertLeadSourceConfigCommandResponse();
    UpsertLeadSourceConfigCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(upsertLeadSource);
};

export { useUpsertLeadSource };
