import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { useSkillsetGroup } from '@/API/Queries/user/useSkillsetGroup';
import { useUpdateSkillsetGroup } from '@/API/Queries/user/useUpdateSkillsetGroup';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

type TableItem = {
  displayName: string;
  usersCount: number;
  badge?: IBadgeProps;
  id: string;
};

const SkillsetGroupSkillsets = () => {
  const { id = '' } = useParams();
  const { showSnackBar } = useShared();

  const { data: skillsetGroup, refetch } = useSkillsetGroup(id);
  const { mutate: updateSkillsetGroup } = useUpdateSkillsetGroup();

  const [selectedItems, setSelectedItems] = useState<TableItem[]>([]);

  const isAssigned = useCallback((skillsetId: string) => skillsetGroup?.skillSetsIdsList.some((id) => id === skillsetId), [skillsetGroup]);

  const handleSubmit = useCallback((assignedSkillsets: string[]) => {
    updateSkillsetGroup(
      {
        displayName: skillsetGroup?.displayName || '',
        longDescription: skillsetGroup?.longDescription || '',
        skillsetGroupId: id,
        skillsetIds: assignedSkillsets,
        userIds: skillsetGroup?.usersIdsList || [],
      },
      {
        onError: () => showSnackBar({ message: 'Something went wrong.', open: true }),
        onSuccess: () => {
          refetch();
          setSelectedItems([]);
        },
      },
    );
  }, [id, updateSkillsetGroup, skillsetGroup, showSnackBar, refetch]);

  const handleClickItem = useCallback((item: TableItem) => {
    let assignedSkillsets = skillsetGroup?.skillSetsIdsList || [];
    if (isAssigned(item.id)) {
      assignedSkillsets = assignedSkillsets.filter((skillsetId) => skillsetId !== item.id);
    } else {
      assignedSkillsets = [...assignedSkillsets, item.id];
    }
    handleSubmit(assignedSkillsets);
  }, [skillsetGroup, handleSubmit, isAssigned]);

  const handleAssignBulk = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    const assignedIds = skillsetGroup?.skillSetsIdsList.concat(selectedIds) || [];
    handleSubmit(assignedIds);
  };
  const handleUnassignBulk = () => {
    const assignedIds = skillsetGroup?.skillSetsIdsList.filter((id) => !selectedItems
      .some((selectedItem) => selectedItem.id === id)) || [];
    handleSubmit(assignedIds);
  };

  const renderBadge = useCallback((skillset: TableItem) => {
    if (isAssigned(skillset.id)) {
      return (
        <Badge
          label="Assigned"
          variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE}
        />
      );
    }
    return null;
  }, [isAssigned]);

  const handleSetSelection = useCallback((newList: any) => {
    setSelectedItems(newList);
  }, []);

  const columns = useMemo((): TableBuilderColumn => [
    ['Skillset Name', { text: 'displayName', type: 'text' }],
    ['# of users', { text: 'usersCount', type: 'text' }],
    ['Assigned', { type: 'any', value: renderBadge }],
  ], [renderBadge]);

  return (
    <TableBuilder
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Assign', onClick: handleAssignBulk },
              { label: 'Unassign', onClick: handleUnassignBulk },
            ],
            caption: `${selectedItems.length} selected skillset(s)`,
          },
          showContextual: selectedItems.length > 0,
          title: 'All Skillsets',
        },
      }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.SKILLSETS}
      hoverActions={[
        {
          label: (item) => (isAssigned(item.id) ? 'Unassign' : 'Assign'),
          onClick: handleClickItem,
        },
      ]}
      orderBy="Assigned"
      setRowSelection={handleSetSelection}
    />
  );
};

export { SkillsetGroupSkillsets };
