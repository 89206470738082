// source: Wilqo.Shared.Mortgage.Integration/Pricing/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('../../Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.AmortizationType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.AusSelectionType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.BuydownType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.CommunityLendingProductType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.CustomValue', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.IndexType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LienPriorityType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LoanProduct', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LoanProductRequiredField', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LoanProductStatusType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LoanPurposeType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.LoanTermType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MiDurationType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MiPremiumSourceType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MortgageType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.PricingProductSummary', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductChannel', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductPricingDetail', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductSearchOptions', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductSearchVendorType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProductStatusType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProjectDesignType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.PropertyUsageType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.Rate', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.RateStatus', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.RateStatusType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.CustomValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.CustomValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.CustomValue.displayName = 'proto.wilqo.shared.mortgage_integration.CustomValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.LoanProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.mortgage_integration.LoanProduct.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.LoanProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.LoanProduct.displayName = 'proto.wilqo.shared.mortgage_integration.LoanProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.LoanProductRequiredField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.displayName = 'proto.wilqo.shared.mortgage_integration.LoanProductRequiredField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.ProductPricingDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.ProductPricingDetail.displayName = 'proto.wilqo.shared.mortgage_integration.ProductPricingDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.displayName = 'proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.PricingProductSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.PricingProductSummary.displayName = 'proto.wilqo.shared.mortgage_integration.PricingProductSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.mortgage_integration.ProductSearchOptions.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.ProductSearchOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.ProductSearchOptions.displayName = 'proto.wilqo.shared.mortgage_integration.ProductSearchOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.Rate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.Rate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.Rate.displayName = 'proto.wilqo.shared.mortgage_integration.Rate';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.CustomValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.CustomValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.CustomValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueCategory: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.CustomValue;
  return proto.wilqo.shared.mortgage_integration.CustomValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.CustomValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.CustomValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.CustomValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.CustomValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue} returns this
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue} returns this
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value_category = 3;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.getValueCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue} returns this
 */
proto.wilqo.shared.mortgage_integration.CustomValue.prototype.setValueCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.repeatedFields_ = [6,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.LoanProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanTermMonths: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ratesList: jspb.Message.toObjectList(msg.getRatesList(),
    proto.wilqo.shared.mortgage_integration.Rate.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    code: jspb.Message.getFieldWithDefault(msg, 8, ""),
    investorName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    investorCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    requiredFieldsList: jspb.Message.toObjectList(msg.getRequiredFieldsList(),
    proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.toObject, includeInstance),
    ineligibleReasonsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.LoanProduct;
  return proto.wilqo.shared.mortgage_integration.LoanProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermMonths(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.LoanProductStatusType} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.mortgage_integration.Rate;
      reader.readMessage(value,proto.wilqo.shared.mortgage_integration.Rate.deserializeBinaryFromReader);
      msg.addRates(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorCode(value);
      break;
    case 11:
      var value = new proto.wilqo.shared.mortgage_integration.LoanProductRequiredField;
      reader.readMessage(value,proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.deserializeBinaryFromReader);
      msg.addRequiredFields(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addIneligibleReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.LoanProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanTermMonths();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getRatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.shared.mortgage_integration.Rate.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInvestorName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInvestorCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRequiredFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.serializeBinaryToWriter
    );
  }
  f = message.getIneligibleReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string search_id = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 loan_term_months = 3;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getLoanTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setLoanTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AmortizationType amortization_type = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.AmortizationType}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.AmortizationType} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional LoanProductStatusType status = 5;
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProductStatusType}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.LoanProductStatusType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProductStatusType} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated Rate rates = 6;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.Rate>}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getRatesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.Rate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.mortgage_integration.Rate, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.Rate>} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
*/
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setRatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.Rate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.Rate}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.addRates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.mortgage_integration.Rate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.clearRatesList = function() {
  return this.setRatesList([]);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string code = 8;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string investor_name = 9;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getInvestorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setInvestorName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string investor_code = 10;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getInvestorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setInvestorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated LoanProductRequiredField required_fields = 11;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField>}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getRequiredFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.mortgage_integration.LoanProductRequiredField, 11));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField>} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
*/
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setRequiredFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.addRequiredFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.shared.mortgage_integration.LoanProductRequiredField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.clearRequiredFieldsList = function() {
  return this.setRequiredFieldsList([]);
};


/**
 * repeated string ineligible_reasons = 12;
 * @return {!Array<string>}
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.getIneligibleReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.setIneligibleReasonsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.addIneligibleReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProduct} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProduct.prototype.clearIneligibleReasonsList = function() {
  return this.setIneligibleReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField}
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.LoanProductRequiredField;
  return proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField}
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.LoanProductRequiredField} returns this
 */
proto.wilqo.shared.mortgage_integration.LoanProductRequiredField.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.ProductPricingDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    investorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    investorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    loanTermMonths: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rateDetail: (f = msg.getRateDetail()) && proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.ProductPricingDetail;
  return proto.wilqo.shared.mortgage_integration.ProductPricingDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermMonths(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 8:
      var value = new proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail;
      reader.readMessage(value,proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.deserializeBinaryFromReader);
      msg.setRateDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.ProductPricingDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvestorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvestorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLoanTermMonths();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRateDetail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string investor_name = 4;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getInvestorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setInvestorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string investor_code = 5;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getInvestorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setInvestorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 loan_term_months = 6;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getLoanTermMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setLoanTermMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional AmortizationType amortization_type = 7;
 * @return {!proto.wilqo.shared.mortgage_integration.AmortizationType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.AmortizationType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional ProductPricingRateDetail rate_detail = 8;
 * @return {?proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.getRateDetail = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail, 8));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.setRateDetail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.clearRateDetail = function() {
  return this.setRateDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingDetail.prototype.hasRateDetail = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    percent: (f = msg.getPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lockDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    productChannel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mortgageType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    communityLendingProductType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanTermType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    presetLoanTerm: jspb.Message.getFieldWithDefault(msg, 11, 0),
    presetLoanMaturity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentInterval: jspb.Message.getFieldWithDefault(msg, 13, 0),
    interestOnlyPeriod: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    lienPriority: jspb.Message.getFieldWithDefault(msg, 15, 0),
    maxPriceLimit: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    channelAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    cltv: (f = msg.getCltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    downPaymentPercentage: (f = msg.getDownPaymentPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaLtv: (f = msg.getFhaLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaUfmip: (f = msg.getFhaUfmip()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    financedMi: (f = msg.getFinancedMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    hcltv: (f = msg.getHcltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanTermAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    ltv: (f = msg.getLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    mortgageTypeAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    totalOfAllLiensWithFixedSecond: (f = msg.getTotalOfAllLiensWithFixedSecond()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalOfAllLiensWithHeloc: (f = msg.getTotalOfAllLiensWithHeloc()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinanced: (f = msg.getUsdaFinanced()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFee: (f = msg.getUsdaFinancedGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFeeCents: (f = msg.getUsdaFinancedGuaranteeFeeCents()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageAmount: (f = msg.getUsdaFinancePercentageAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageGuaranteeFee: (f = msg.getUsdaFinancePercentageGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentagePayAtClosing: (f = msg.getUsdaFinancePercentagePayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaGuaranteeFee: (f = msg.getUsdaGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit9: (f = msg.getUsdaIncomeLimit9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk9: (f = msg.getUsdaIncomeLimitAk9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaPayAtClosing: (f = msg.getUsdaPayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMi: (f = msg.getFhaPurchaseFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 40, 0),
    fhaPurchaseFirstPremium: (f = msg.getFhaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseCutoff: (f = msg.getFhaPurchaseCutoff()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMi: (f = msg.getFhaRefiFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 44, 0),
    fhaRefiFirstPremium: (f = msg.getFhaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiCutoffPercentage: (f = msg.getFhaRefiCutoffPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit1To4: (f = msg.getUsdaIncomeLimit1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit5To8: (f = msg.getUsdaIncomeLimit5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk1To4: (f = msg.getUsdaIncomeLimitAk1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk5To8: (f = msg.getUsdaIncomeLimitAk5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseFirstPremium: (f = msg.getVaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseSubsequentPremium: (f = msg.getVaPurchaseSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiFirstPremium: (f = msg.getVaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiSubsequentPremium: (f = msg.getVaRefiSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    miAndFundingFeeFinancedAmount: (f = msg.getMiAndFundingFeeFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaFundingFee: (f = msg.getVaFundingFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    indexType: jspb.Message.getFieldWithDefault(msg, 57, 0),
    firstRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 58, 0),
    subsequentRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 59, 0),
    firstMaxIncreaseRatePercent: (f = msg.getFirstMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    subsequentMaxIncreaseRatePercent: (f = msg.getSubsequentMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    maximumIncreaseRatePercent: (f = msg.getMaximumIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    floorRatePercent: (f = msg.getFloorRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    rateId: jspb.Message.getFieldWithDefault(msg, 64, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail;
  return proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLockDays(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.RateStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProductChannel} */ (reader.readEnum());
      msg.setProductChannel(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageType} */ (reader.readEnum());
      msg.setMortgageType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.CommunityLendingProductType} */ (reader.readEnum());
      msg.setCommunityLendingProductType(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.LoanTermType} */ (reader.readEnum());
      msg.setLoanTermType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanTerm(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanMaturity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentInterval(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInterestOnlyPeriod(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.LienPriorityType} */ (reader.readEnum());
      msg.setLienPriority(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaxPriceLimit(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChannelAllowed(value);
      break;
    case 18:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCltv(value);
      break;
    case 19:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDownPaymentPercentage(value);
      break;
    case 20:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaLtv(value);
      break;
    case 21:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaUfmip(value);
      break;
    case 22:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFinancedMi(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHcltv(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoanTermAllowed(value);
      break;
    case 25:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLtv(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMortgageTypeAllowed(value);
      break;
    case 27:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithFixedSecond(value);
      break;
    case 28:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithHeloc(value);
      break;
    case 29:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinanced(value);
      break;
    case 30:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFee(value);
      break;
    case 31:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFeeCents(value);
      break;
    case 32:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageAmount(value);
      break;
    case 33:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageGuaranteeFee(value);
      break;
    case 34:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentagePayAtClosing(value);
      break;
    case 35:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaGuaranteeFee(value);
      break;
    case 36:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit9(value);
      break;
    case 37:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk9(value);
      break;
    case 38:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaPayAtClosing(value);
      break;
    case 39:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstMonthlyMi(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaPurchaseFirstMonthlyMiMonths(value);
      break;
    case 41:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstPremium(value);
      break;
    case 42:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseCutoff(value);
      break;
    case 43:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstMonthlyMi(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaRefiFirstMonthlyMiMonths(value);
      break;
    case 45:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstPremium(value);
      break;
    case 46:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiCutoffPercentage(value);
      break;
    case 47:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit1To4(value);
      break;
    case 48:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit5To8(value);
      break;
    case 49:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk1To4(value);
      break;
    case 50:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk5To8(value);
      break;
    case 51:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseFirstPremium(value);
      break;
    case 52:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseSubsequentPremium(value);
      break;
    case 53:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiFirstPremium(value);
      break;
    case 54:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiSubsequentPremium(value);
      break;
    case 55:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMiAndFundingFeeFinancedAmount(value);
      break;
    case 56:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaFundingFee(value);
      break;
    case 57:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.IndexType} */ (reader.readEnum());
      msg.setIndexType(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFirstRateChangeMonthsCount(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubsequentRateChangeMonthsCount(value);
      break;
    case 60:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFirstMaxIncreaseRatePercent(value);
      break;
    case 61:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSubsequentMaxIncreaseRatePercent(value);
      break;
    case 62:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMaximumIncreaseRatePercent(value);
      break;
    case 63:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFloorRatePercent(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLockDays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProductChannel();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMortgageType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCommunityLendingProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLoanTermType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPresetLoanTerm();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getPresetLoanMaturity();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getPaymentInterval();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getInterestOnlyPeriod();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLienPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getMaxPriceLimit();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getChannelAllowed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCltv();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDownPaymentPercentage();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaLtv();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaUfmip();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFinancedMi();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHcltv();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermAllowed();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getLtv();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMortgageTypeAllowed();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getTotalOfAllLiensWithFixedSecond();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalOfAllLiensWithHeloc();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinanced();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFeeCents();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageAmount();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentagePayAtClosing();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit9();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk9();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaPayAtClosing();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      40,
      f
    );
  }
  f = message.getFhaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseCutoff();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      44,
      f
    );
  }
  f = message.getFhaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiCutoffPercentage();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit1To4();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit5To8();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk1To4();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk5To8();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMiAndFundingFeeFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaFundingFee();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getIndexType();
  if (f !== 0.0) {
    writer.writeEnum(
      57,
      f
    );
  }
  f = message.getFirstRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      58,
      f
    );
  }
  f = message.getSubsequentRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      59,
      f
    );
  }
  f = message.getFirstMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSubsequentMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMaximumIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFloorRatePercent();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasApr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearPercent = function() {
  return this.setPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 lock_days = 5;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getLockDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setLockDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional RateStatus status = 6;
 * @return {!proto.wilqo.shared.mortgage_integration.RateStatus}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.RateStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.RateStatus} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ProductChannel product_channel = 7;
 * @return {!proto.wilqo.shared.mortgage_integration.ProductChannel}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getProductChannel = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProductChannel} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProductChannel} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setProductChannel = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional MortgageType mortgage_type = 8;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMortgageType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMortgageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CommunityLendingProductType community_lending_product_type = 9;
 * @return {!proto.wilqo.shared.mortgage_integration.CommunityLendingProductType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getCommunityLendingProductType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.CommunityLendingProductType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.CommunityLendingProductType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setCommunityLendingProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional LoanTermType loan_term_type = 10;
 * @return {!proto.wilqo.shared.mortgage_integration.LoanTermType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getLoanTermType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.LoanTermType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LoanTermType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setLoanTermType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint32 preset_loan_term = 11;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getPresetLoanTerm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setPresetLoanTerm = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 preset_loan_maturity = 12;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getPresetLoanMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setPresetLoanMaturity = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 payment_interval = 13;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getPaymentInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setPaymentInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool interest_only_period = 14;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getInterestOnlyPeriod = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setInterestOnlyPeriod = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional LienPriorityType lien_priority = 15;
 * @return {!proto.wilqo.shared.mortgage_integration.LienPriorityType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getLienPriority = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.LienPriorityType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LienPriorityType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setLienPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool max_price_limit = 16;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMaxPriceLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMaxPriceLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool channel_allowed = 17;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getChannelAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setChannelAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal cltv = 18;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getCltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 18));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setCltv = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearCltv = function() {
  return this.setCltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasCltv = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal down_payment_percentage = 19;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getDownPaymentPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 19));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setDownPaymentPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearDownPaymentPercentage = function() {
  return this.setDownPaymentPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasDownPaymentPercentage = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ltv = 20;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 20));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaLtv = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaLtv = function() {
  return this.setFhaLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaLtv = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ufmip = 21;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaUfmip = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 21));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaUfmip = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaUfmip = function() {
  return this.setFhaUfmip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaUfmip = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal financed_mi = 22;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFinancedMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 22));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFinancedMi = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFinancedMi = function() {
  return this.setFinancedMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFinancedMi = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal hcltv = 23;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getHcltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 23));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setHcltv = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearHcltv = function() {
  return this.setHcltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasHcltv = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool loan_term_allowed = 24;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getLoanTermAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setLoanTermAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal ltv = 25;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 25));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setLtv = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearLtv = function() {
  return this.setLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasLtv = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool mortgage_type_allowed = 26;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMortgageTypeAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMortgageTypeAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_fixed_second = 27;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getTotalOfAllLiensWithFixedSecond = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 27));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setTotalOfAllLiensWithFixedSecond = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearTotalOfAllLiensWithFixedSecond = function() {
  return this.setTotalOfAllLiensWithFixedSecond(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasTotalOfAllLiensWithFixedSecond = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_heloc = 28;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getTotalOfAllLiensWithHeloc = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 28));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setTotalOfAllLiensWithHeloc = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearTotalOfAllLiensWithHeloc = function() {
  return this.setTotalOfAllLiensWithHeloc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasTotalOfAllLiensWithHeloc = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed = 29;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinanced = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 29));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinanced = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinanced = function() {
  return this.setUsdaFinanced(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinanced = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee = 30;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinancedGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 30));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinancedGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinancedGuaranteeFee = function() {
  return this.setUsdaFinancedGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinancedGuaranteeFee = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee_cents = 31;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinancedGuaranteeFeeCents = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 31));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinancedGuaranteeFeeCents = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinancedGuaranteeFeeCents = function() {
  return this.setUsdaFinancedGuaranteeFeeCents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinancedGuaranteeFeeCents = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_amount = 32;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinancePercentageAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 32));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinancePercentageAmount = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinancePercentageAmount = function() {
  return this.setUsdaFinancePercentageAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinancePercentageAmount = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_guarantee_fee = 33;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinancePercentageGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 33));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinancePercentageGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinancePercentageGuaranteeFee = function() {
  return this.setUsdaFinancePercentageGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinancePercentageGuaranteeFee = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_pay_at_closing = 34;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaFinancePercentagePayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 34));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaFinancePercentagePayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaFinancePercentagePayAtClosing = function() {
  return this.setUsdaFinancePercentagePayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaFinancePercentagePayAtClosing = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_guarantee_fee = 35;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 35));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaGuaranteeFee = function() {
  return this.setUsdaGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaGuaranteeFee = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_9 = 36;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimit9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 36));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimit9 = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimit9 = function() {
  return this.setUsdaIncomeLimit9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimit9 = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_9 = 37;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimitAk9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 37));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimitAk9 = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimitAk9 = function() {
  return this.setUsdaIncomeLimitAk9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimitAk9 = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_pay_at_closing = 38;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaPayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 38));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaPayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaPayAtClosing = function() {
  return this.setUsdaPayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaPayAtClosing = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_monthly_mi = 39;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaPurchaseFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 39));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaPurchaseFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaPurchaseFirstMonthlyMi = function() {
  return this.setFhaPurchaseFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaPurchaseFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional uint32 fha_purchase_first_monthly_mi_months = 40;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaPurchaseFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaPurchaseFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_premium = 41;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 41));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaPurchaseFirstPremium = function() {
  return this.setFhaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_cutoff = 42;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaPurchaseCutoff = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 42));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaPurchaseCutoff = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaPurchaseCutoff = function() {
  return this.setFhaPurchaseCutoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaPurchaseCutoff = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_monthly_mi = 43;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaRefiFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 43));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaRefiFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaRefiFirstMonthlyMi = function() {
  return this.setFhaRefiFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaRefiFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional uint32 fha_refi_first_monthly_mi_months = 44;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaRefiFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaRefiFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_premium = 45;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 45));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaRefiFirstPremium = function() {
  return this.setFhaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_cutoff_percentage = 46;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFhaRefiCutoffPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 46));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFhaRefiCutoffPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFhaRefiCutoffPercentage = function() {
  return this.setFhaRefiCutoffPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFhaRefiCutoffPercentage = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_1_to_4 = 47;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimit1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 47));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimit1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimit1To4 = function() {
  return this.setUsdaIncomeLimit1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimit1To4 = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_5_to_8 = 48;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimit5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 48));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimit5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimit5To8 = function() {
  return this.setUsdaIncomeLimit5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimit5To8 = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_1_to_4 = 49;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimitAk1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 49));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimitAk1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimitAk1To4 = function() {
  return this.setUsdaIncomeLimitAk1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimitAk1To4 = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_5_to_8 = 50;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getUsdaIncomeLimitAk5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 50));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setUsdaIncomeLimitAk5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearUsdaIncomeLimitAk5To8 = function() {
  return this.setUsdaIncomeLimitAk5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasUsdaIncomeLimitAk5To8 = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_first_premium = 51;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getVaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 51));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setVaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearVaPurchaseFirstPremium = function() {
  return this.setVaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasVaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_subsequent_premium = 52;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getVaPurchaseSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 52));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setVaPurchaseSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearVaPurchaseSubsequentPremium = function() {
  return this.setVaPurchaseSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasVaPurchaseSubsequentPremium = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_first_premium = 53;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getVaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 53));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setVaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearVaRefiFirstPremium = function() {
  return this.setVaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasVaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_subsequent_premium = 54;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getVaRefiSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 54));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setVaRefiSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearVaRefiSubsequentPremium = function() {
  return this.setVaRefiSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasVaRefiSubsequentPremium = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal mi_and_funding_fee_financed_amount = 55;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMiAndFundingFeeFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 55));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMiAndFundingFeeFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearMiAndFundingFeeFinancedAmount = function() {
  return this.setMiAndFundingFeeFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasMiAndFundingFeeFinancedAmount = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_funding_fee = 56;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getVaFundingFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 56));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setVaFundingFee = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearVaFundingFee = function() {
  return this.setVaFundingFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasVaFundingFee = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional IndexType index_type = 57;
 * @return {!proto.wilqo.shared.mortgage_integration.IndexType}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getIndexType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.IndexType} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.IndexType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setIndexType = function(value) {
  return jspb.Message.setProto3EnumField(this, 57, value);
};


/**
 * optional uint32 first_rate_change_months_count = 58;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFirstRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFirstRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional uint32 subsequent_rate_change_months_count = 59;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getSubsequentRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setSubsequentRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal first_max_increase_rate_percent = 60;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFirstMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 60));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFirstMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFirstMaxIncreaseRatePercent = function() {
  return this.setFirstMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFirstMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal subsequent_max_increase_rate_percent = 61;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getSubsequentMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 61));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setSubsequentMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearSubsequentMaxIncreaseRatePercent = function() {
  return this.setSubsequentMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasSubsequentMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal maximum_increase_rate_percent = 62;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getMaximumIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 62));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setMaximumIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearMaximumIncreaseRatePercent = function() {
  return this.setMaximumIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasMaximumIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal floor_rate_percent = 63;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getFloorRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 63));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setFloorRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.clearFloorRatePercent = function() {
  return this.setFloorRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.hasFloorRatePercent = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional string rate_id = 64;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductPricingRateDetail.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.PricingProductSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    needsMoreDataCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eligibleCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ineligibleCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    errorCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.PricingProductSummary;
  return proto.wilqo.shared.mortgage_integration.PricingProductSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNeedsMoreDataCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEligibleCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIneligibleCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.PricingProductSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeedsMoreDataCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEligibleCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIneligibleCount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getErrorCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 needs_more_data_count = 1;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.getNeedsMoreDataCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} returns this
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.setNeedsMoreDataCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 eligible_count = 2;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.getEligibleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} returns this
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.setEligibleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 ineligible_count = 3;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.getIneligibleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} returns this
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.setIneligibleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 error_count = 4;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.getErrorCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.PricingProductSummary} returns this
 */
proto.wilqo.shared.mortgage_integration.PricingProductSummary.prototype.setErrorCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.repeatedFields_ = [27,29];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.ProductSearchOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    loanPurposeType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    baseLoanAmount: (f = msg.getBaseLoanAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanAmortizationPeriodCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    mortgageType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanLevelCreditScoreValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    miDurationType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    miPremiumSourceType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    escrowIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lockBuySideDurationDayCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    refinanceType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 31, 0),
    propertyUsageType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    stateCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    countyName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    countyCode: jspb.Message.getFieldWithDefault(msg, 19, ""),
    financedUnitCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    propertyEstimatedValueAmount: (f = msg.getPropertyEstimatedValueAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    salesContractAmount: (f = msg.getSalesContractAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    projectLegalStructureType: jspb.Message.getFieldWithDefault(msg, 23, 0),
    projectDesignType: jspb.Message.getFieldWithDefault(msg, 24, 0),
    stateName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    buydownType: jspb.Message.getFieldWithDefault(msg, 30, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 26, 0),
    customValuesList: jspb.Message.toObjectList(msg.getCustomValuesList(),
    proto.wilqo.shared.mortgage_integration.CustomValue.toObject, includeInstance),
    processId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    productStatusesList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
    ausSelection: jspb.Message.getFieldWithDefault(msg, 32, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.ProductSearchOptions;
  return proto.wilqo.shared.mortgage_integration.ProductSearchOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.LoanPurposeType} */ (reader.readEnum());
      msg.setLoanPurposeType(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBaseLoanAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoanAmortizationPeriodCount(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageType} */ (reader.readEnum());
      msg.setMortgageType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanLevelCreditScoreValue(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MiDurationType} */ (reader.readEnum());
      msg.setMiDurationType(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MiPremiumSourceType} */ (reader.readEnum());
      msg.setMiPremiumSourceType(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEscrowIndicator(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLockBuySideDurationDayCount(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType} */ (reader.readEnum());
      msg.setRefinanceType(value);
      break;
    case 31:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.PropertyUsageType} */ (reader.readEnum());
      msg.setPropertyUsageType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyCode(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinancedUnitCount(value);
      break;
    case 21:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPropertyEstimatedValueAmount(value);
      break;
    case 22:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSalesContractAmount(value);
      break;
    case 23:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType} */ (reader.readEnum());
      msg.setProjectLegalStructureType(value);
      break;
    case 24:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProjectDesignType} */ (reader.readEnum());
      msg.setProjectDesignType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 30:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.BuydownType} */ (reader.readEnum());
      msg.setBuydownType(value);
      break;
    case 26:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 27:
      var value = new proto.wilqo.shared.mortgage_integration.CustomValue;
      reader.readMessage(value,proto.wilqo.shared.mortgage_integration.CustomValue.deserializeBinaryFromReader);
      msg.addCustomValues(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    case 29:
      var values = /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProductStatusType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProductStatuses(values[i]);
      }
      break;
    case 32:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.AusSelectionType} */ (reader.readEnum());
      msg.setAusSelection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.ProductSearchOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLoanPurposeType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getBaseLoanAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanAmortizationPeriodCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMortgageType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLoanLevelCreditScoreValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMiDurationType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getMiPremiumSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getEscrowIndicator();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLockBuySideDurationDayCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getRefinanceType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      31,
      f
    );
  }
  f = message.getPropertyUsageType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCountyName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCountyCode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFinancedUnitCount();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getPropertyEstimatedValueAmount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSalesContractAmount();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProjectLegalStructureType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getProjectDesignType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getBuydownType();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getCustomValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.wilqo.shared.mortgage_integration.CustomValue.serializeBinaryToWriter
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getProductStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      29,
      f
    );
  }
  f = message.getAusSelection();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organization_id = 4;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool mortgage_originator_first_time_home_buyer_eligibility_indicator = 5;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional LoanPurposeType loan_purpose_type = 6;
 * @return {!proto.wilqo.shared.mortgage_integration.LoanPurposeType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getLoanPurposeType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.LoanPurposeType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.LoanPurposeType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setLoanPurposeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal base_loan_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getBaseLoanAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setBaseLoanAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.clearBaseLoanAmount = function() {
  return this.setBaseLoanAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.hasBaseLoanAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 loan_amortization_period_count = 8;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getLoanAmortizationPeriodCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setLoanAmortizationPeriodCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional MortgageType mortgage_type = 9;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getMortgageType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setMortgageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string loan_level_credit_score_value = 10;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getLoanLevelCreditScoreValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setLoanLevelCreditScoreValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional MiDurationType mi_duration_type = 11;
 * @return {!proto.wilqo.shared.mortgage_integration.MiDurationType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getMiDurationType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MiDurationType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MiDurationType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setMiDurationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional MiPremiumSourceType mi_premium_source_type = 12;
 * @return {!proto.wilqo.shared.mortgage_integration.MiPremiumSourceType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getMiPremiumSourceType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MiPremiumSourceType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MiPremiumSourceType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setMiPremiumSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional bool escrow_indicator = 13;
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getEscrowIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setEscrowIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 lock_buy_side_duration_day_count = 14;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getLockBuySideDurationDayCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setLockBuySideDurationDayCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional RefinanceCashOutDeterminationType refinance_type = 15;
 * @return {!proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getRefinanceType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setRefinanceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional AmortizationType amortization_type = 31;
 * @return {!proto.wilqo.shared.mortgage_integration.AmortizationType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.AmortizationType} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.AmortizationType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 31, value);
};


/**
 * optional PropertyUsageType property_usage_type = 16;
 * @return {!proto.wilqo.shared.mortgage_integration.PropertyUsageType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getPropertyUsageType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.PropertyUsageType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.PropertyUsageType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setPropertyUsageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional string state_code = 17;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string county_name = 18;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getCountyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setCountyName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string county_code = 19;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getCountyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setCountyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 financed_unit_count = 20;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getFinancedUnitCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setFinancedUnitCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal property_estimated_value_amount = 21;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getPropertyEstimatedValueAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 21));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setPropertyEstimatedValueAmount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.clearPropertyEstimatedValueAmount = function() {
  return this.setPropertyEstimatedValueAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.hasPropertyEstimatedValueAmount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal sales_contract_amount = 22;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getSalesContractAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 22));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setSalesContractAmount = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.clearSalesContractAmount = function() {
  return this.setSalesContractAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.hasSalesContractAmount = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional ProjectLegalStructureType project_legal_structure_type = 23;
 * @return {!proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getProjectLegalStructureType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setProjectLegalStructureType = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional ProjectDesignType project_design_type = 24;
 * @return {!proto.wilqo.shared.mortgage_integration.ProjectDesignType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getProjectDesignType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProjectDesignType} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProjectDesignType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setProjectDesignType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional string state_name = 25;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional BuydownType buydown_type = 30;
 * @return {!proto.wilqo.shared.mortgage_integration.BuydownType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getBuydownType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.BuydownType} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.BuydownType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setBuydownType = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional ProductSearchVendorType vendor = 26;
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProductSearchVendorType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * repeated CustomValue custom_values = 27;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getCustomValuesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.mortgage_integration.CustomValue, 27));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.CustomValue>} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setCustomValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.CustomValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.CustomValue}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.addCustomValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.wilqo.shared.mortgage_integration.CustomValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.clearCustomValuesList = function() {
  return this.setCustomValuesList([]);
};


/**
 * optional string process_id = 28;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * repeated ProductStatusType product_statuses = 29;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.ProductStatusType>}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getProductStatusesList = function() {
  return /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ProductStatusType>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.ProductStatusType>} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setProductStatusesList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProductStatusType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.addProductStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.clearProductStatusesList = function() {
  return this.setProductStatusesList([]);
};


/**
 * optional AusSelectionType aus_selection = 32;
 * @return {!proto.wilqo.shared.mortgage_integration.AusSelectionType}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.getAusSelection = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.AusSelectionType} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.AusSelectionType} value
 * @return {!proto.wilqo.shared.mortgage_integration.ProductSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.ProductSearchOptions.prototype.setAusSelection = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.Rate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.Rate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.Rate.toObject = function(includeInstance, msg) {
  var f, obj = {
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    percent: (f = msg.getPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lockDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rateId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate}
 */
proto.wilqo.shared.mortgage_integration.Rate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.Rate;
  return proto.wilqo.shared.mortgage_integration.Rate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.Rate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate}
 */
proto.wilqo.shared.mortgage_integration.Rate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLockDays(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.RateStatusType} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.Rate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.Rate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.Rate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLockDays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
*/
proto.wilqo.shared.mortgage_integration.Rate.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.hasApr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
*/
proto.wilqo.shared.mortgage_integration.Rate.prototype.setPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.clearPercent = function() {
  return this.setPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
*/
proto.wilqo.shared.mortgage_integration.Rate.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
*/
proto.wilqo.shared.mortgage_integration.Rate.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 lock_days = 5;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getLockDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.setLockDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional RateStatusType status = 6;
 * @return {!proto.wilqo.shared.mortgage_integration.RateStatusType}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.RateStatusType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.RateStatusType} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string rate_id = 7;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.Rate} returns this
 */
proto.wilqo.shared.mortgage_integration.Rate.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.AmortizationType = {
  AMORTIZATION_TYPE_UNSPECIFIED: 0,
  AMORTIZATION_TYPE_FIXED: 1,
  AMORTIZATION_TYPE_ADJUSTABLE: 2,
  AMORTIZATION_TYPE_STEP: 3,
  AMORTIZATION_TYPE_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.BuydownType = {
  BUYDOWN_TYPE_UNSPECIFIED: 0,
  BUYDOWN_TYPE_321: 1,
  BUYDOWN_TYPE_21: 2,
  BUYDOWN_TYPE_11: 3,
  BUYDOWN_TYPE_10: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.CommunityLendingProductType = {
  COMMUNITY_LENDING_PRODUCT_TYPE_UNSPECIFIED: 0,
  COMMUNITY_LENDING_PRODUCT_TYPE_NONE: 1,
  COMMUNITY_LENDING_PRODUCT_TYPE_FHLMC_HOME_ONE: 2,
  COMMUNITY_LENDING_PRODUCT_TYPE_FHLMC_HOME_POSSIBLE: 3,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HIGH_LTV_REFINANCE: 4,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HOME_READY: 5,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HOME_STYLE: 6
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.IndexType = {
  INDEX_TYPE_UNSPECIFIED: 0,
  INDEX_TYPE_BANK_PRIME_LOAN: 1,
  INDEX_TYPE_CERTIFICATE_OF_DEPOSIT_INDEX: 2,
  INDEX_TYPE_CONSTANT_MATURITY_TREASURY: 3,
  INDEX_TYPE_COST_OF_SAVINGS_INDEX: 4,
  INDEX_TYPE_ELEVENTH_DISTRICT_COST_OF_FUNDS_INDEX: 5,
  INDEX_TYPE_LIBOR: 6,
  INDEX_TYPE_TREASURY_BILL: 7,
  INDEX_TYPE_TWELVE_MONTH_TREASURY_AVERAGE: 8,
  INDEX_TYPE_OTHER: 9
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.LienPriorityType = {
  LIEN_PRIORITY_TYPE_UNSPECIFIED: 0,
  LIEN_PRIORITY_TYPE_FIRST: 1,
  LIEN_PRIORITY_TYPE_SECOND: 2,
  LIEN_PRIORITY_TYPE_THIRD: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.LoanProductStatusType = {
  LOAN_PRODUCT_STATUS_TYPE_UNSPECIFIED: 0,
  LOAN_PRODUCT_STATUS_TYPE_ELIGIBLE: 1,
  LOAN_PRODUCT_STATUS_TYPE_NEEDS_MORE_DATA: 2,
  LOAN_PRODUCT_STATUS_TYPE_INELIGIBLE: 3,
  LOAN_PRODUCT_STATUS_TYPE_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.LoanPurposeType = {
  LOAN_PURPOSE_TYPE_UNSPECIFIED: 0,
  LOAN_PURPOSE_TYPE_MORTGAGE_MODIFICATION: 1,
  LOAN_PURPOSE_TYPE_OTHER: 2,
  LOAN_PURPOSE_TYPE_PURCHASE: 3,
  LOAN_PURPOSE_TYPE_REFINANCE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.LoanTermType = {
  LOAN_TERM_TYPE_UNSPECIFIED: 0,
  LOAN_TERM_TYPE_PRESET: 1,
  LOAN_TERM_TYPE_RANGE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.MiDurationType = {
  MI_DURATION_TYPE_UNSPECIFIED: 0,
  MI_DURATION_TYPE_ANNUAL: 1,
  MI_DURATION_TYPE_NOT_APPLICABLE: 2,
  MI_DURATION_TYPE_PERIODIC_MONTHLY: 3,
  MI_DURATION_TYPE_SINGLE_LIFE_OF_LOAN: 4,
  MI_DURATION_TYPE_SINGLE_SPECIFIC: 5,
  MI_DURATION_TYPE_SPLIT_PREMIUM: 6,
  MI_DURATION_TYPE_OTHER: 7
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.MiPremiumSourceType = {
  MI_PREMIUM_SOURCE_TYPE_UNSPECIFIED: 0,
  MI_PREMIUM_SOURCE_TYPE_BORROWER: 1,
  MI_PREMIUM_SOURCE_TYPE_BORROWER_AND_LENDER: 2,
  MI_PREMIUM_SOURCE_TYPE_LENDER: 3,
  MI_PREMIUM_SOURCE_TYPE_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.MortgageType = {
  MORTGAGE_TYPE_UNSPECIFIED: 0,
  MORTGAGE_TYPE_CONVENTIONAL: 1,
  MORTGAGE_TYPE_FHA: 2,
  MORTGAGE_TYPE_LOCAL_AGENCY: 3,
  MORTGAGE_TYPE_PUBLIC_AND_INDIAN_HOUSING: 4,
  MORTGAGE_TYPE_STATE_AGENCY: 5,
  MORTGAGE_TYPE_USDA_RURAL_DEVELOPMENT: 6,
  MORTGAGE_TYPE_VA: 7,
  MORTGAGE_TYPE_OTHER: 8
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProductChannel = {
  PRODUCT_CHANNEL_UNSPECIFIED: 0,
  PRODUCT_CHANNEL_CORRESPONDENT: 1,
  PRODUCT_CHANNEL_WHOLESALE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProductSearchVendorType = {
  PRODUCT_SEARCH_VENDOR_TYPE_UNSPECIFIED: 0,
  PRODUCT_SEARCH_VENDOR_TYPE_LOANPASS: 1,
  PRODUCT_SEARCH_VENDOR_TYPE_OPTIMAL_BLUE: 2,
  PRODUCT_SEARCH_VENDOR_TYPE_POLLY: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProductStatusType = {
  PRODUCT_STATUS_TYPE_UNSPECIFIED: 0,
  PRODUCT_STATUS_TYPE_ELIGIBLE: 1,
  PRODUCT_STATUS_TYPE_NEEDS_MORE_DATA: 2,
  PRODUCT_STATUS_TYPE_INELIGIBLE: 3,
  PRODUCT_STATUS_TYPE_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProjectDesignType = {
  PROJECT_DESIGN_TYPE_UNSPECIFIED: 0,
  PROJECT_DESIGN_TYPE_GARDEN_PROJECT: 1,
  PROJECT_DESIGN_TYPE_HIGHRISE_PROJECT: 2,
  PROJECT_DESIGN_TYPE_MIDRISE_PROJECT: 3,
  PROJECT_DESIGN_TYPE_TOWNHOUSE_ROWHOUSE: 4,
  PROJECT_DESIGN_TYPE_OTHER: 5
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProjectLegalStructureType = {
  PROJECT_LEGAL_STRUCTURE_TYPE_UNSPECIFIED: 0,
  PROJECT_LEGAL_STRUCTURE_TYPE_COMMON_INTEREST_APARTMENT: 1,
  PROJECT_LEGAL_STRUCTURE_TYPE_CONDOMINIUM: 2,
  PROJECT_LEGAL_STRUCTURE_TYPE_COOPERATIVE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.PropertyUsageType = {
  PROPERTY_USAGE_TYPE_UNSPECIFIED: 0,
  PROPERTY_USAGE_TYPE_INVESTMENT: 1,
  PROPERTY_USAGE_TYPE_PRIMARY_RESIDENCE: 2,
  PROPERTY_USAGE_TYPE_SECOND_HOME: 3,
  PROPERTY_USAGE_TYPE_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.RateStatus = {
  RATE_STATUS_UNSPECIFIED: 0,
  RATE_STATUS_APPROVED: 1,
  RATE_STATUS_REJECTED: 2
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.RateStatusType = {
  RATE_STATUS_TYPE_UNSPECIFIED: 0,
  RATE_STATUS_TYPE_APPROVED: 1,
  RATE_STATUS_TYPE_REJECTED: 2
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.RefinanceCashOutDeterminationType = {
  REFINANCE_CASH_OUT_DETERMINATION_TYPE_UNKNOWN: 0,
  REFINANCE_CASH_OUT_DETERMINATION_TYPE_CASH_OUT: 1,
  REFINANCE_CASH_OUT_DETERMINATION_TYPE_LIMITED_CASH_OUT: 2,
  REFINANCE_CASH_OUT_DETERMINATION_TYPE_NO_CASH_OUT: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.AusSelectionType = {
  AUS_SELECTION_TYPE_UNSPECIFIED: 0,
  AUS_SELECTION_TYPE_MANUAL: 1,
  AUS_SELECTION_TYPE_DU_DO: 2,
  AUS_SELECTION_TYPE_LP: 3,
  AUS_SELECTION_TYPE_OTHER: 4
};

goog.object.extend(exports, proto.wilqo.shared.mortgage_integration);
