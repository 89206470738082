import clsx from 'clsx';

interface ITile {
  id?: string;
  disabled?: boolean;
  thumbnail?: string;
  path?: string;
  label: string;
}

export interface ComponentProps extends ITile {
  options?: Array<ITile>;
  onClick: (id: string, path?: string) => void;
}

const Tile = (props: ComponentProps) => {
  const {
    disabled = false,
    id,
    label,
    onClick,
    options,
    path,
    thumbnail,
  } = props;

  const renderTile = (id: string, title: string, disabled: boolean, thumbnail?: string, path?: string) => (
    <div
      key={id}
      className={clsx(
        'bg-surface-background justify-center items-center border-on-surface-stroke border-[1px] rounded text-on-surface-active',
        'cursor-pointer flex py-8 px-6 text-center select-none w-full',
        'hover:bg-primary-on-surface-states-hover active:bg-primary-on-surface-states-pressed mobile:p-6',
        {
          'bg-on-surface-states-disabled pointer-events-none': disabled,
          'justify-between py-4 px-6 text-left mobile:py-3 mobile:px-4': Boolean(thumbnail),
        },
      )}
      data-testid="tile"
      id={id}
      onClick={() => onClick(id, path)}
      onKeyDown={() => onClick(id, path)}
      role="button"
      tabIndex={0}
    >
      <span
        className={clsx(
          'font-six text-xl leading-6 font-semibold',
          {
            '!text-on-surface-disabled': disabled,
          },
        )}
      >
        {title}
      </span>
      {
        thumbnail && (
          <img
            alt="preview"
            className="border-[1px] border-on-surface-stroke rounded h-14 ml-4 w-14 mobile:h-12 mobile:w-12"
            src={thumbnail}
          />
        )
      }
    </div>
  );

  if (options) {
    return (
      <>
        {options.map((option) => renderTile(option.id || '', option.label, option.disabled || false, '', option.path))}
      </>
    );
  }

  return renderTile(id || '', label, disabled, thumbnail, path);
};

export default Tile;
