import { usePageConfiguration } from '@/API/Queries/brand/usePageConfiguration';

import { useShared } from '../useShared/useShared';

const useLayout = () => {
  const { drawer, toggleDrawer, toggleSheet } = useShared();
  const { data: pageConfiguration } = usePageConfiguration();

  return {
    drawer,
    footer: pageConfiguration?.footer,
    navigationList: pageConfiguration?.navigationList,
    toggleDrawer,
    toggleSheet,
  };
};

export { useLayout };
