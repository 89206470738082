import { useMutation } from '@tanstack/react-query';

import { PublishLenderConditionCommandRequest, PublishLenderConditionCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.PublishLenderConditionCommandRequest';

const usePublishCondition = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation<PublishLenderConditionCommandResponse.AsObject, Error, string>(async (conditionId: string) => {
    const request = new PublishLenderConditionCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setLenderConditionId(conditionId);
    return sendMessage<PublishLenderConditionCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      PublishLenderConditionCommandResponse,
    );
  });
};

export { usePublishCondition };
