import { useParams } from 'react-router';

import { LoanPageSaveStrategy } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { SaveFieldItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { WilqoPartyType } from '@/API/Models/Wilqo.Shared.Models/WilqoPartyType_pb';
import { useUpdateWilqoPartyMember } from '@/API/Queries/mortgageDynamicData/useUpdateWilqoPartyMember';
import type { AdditionalFormInfo, DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import type { SaveConfig } from '@/Components/Widgets/content/useSaveWidget';
import { useSaveWidget } from '@/Components/Widgets/content/useSaveWidget';
import { useWidgetContextValues } from '@/Components/Widgets/content/WidgetContext';
import { useSearchParams } from '@/Routes/NavigationContext';
import { ddeAreEqual } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getEnumFromParsedValue } from '@/Utils/Helpers/enumHelpers';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const useSaveLoanPage = (refetchPagaData: VoidFunction, saveStrategy?: LoanPageSaveStrategy) => {
  const { showSnackBar } = useShared();
  const contextValues = useWidgetContextValues();
  const { loanId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { setVersion } = useDynamicContext();

  const saveWidget = useSaveWidget();
  const { mutate } = useUpdateWilqoPartyMember();

  const widgetHasChangedValues = (widget: LoanPageWidget.AsObject, values: DynamicDataElementValues, initialValues?: DynamicDataElementValues) => {
    const widgetFieldIds = (widget.cardListItem ?? widget.form)?.fieldsList.map((field) => field.id);
    const updateValues = Object.keys(values).reduce((acc: DynamicDataElementValues, id: string): DynamicDataElementValues => {
      if (widgetFieldIds?.some((fieldId) => fieldId === id)) {
        return { ...acc, [id]: values[id] };
      }
      return acc;
    }, {});
    return Object.keys(updateValues).reduce((hasChanged, fieldKey) => hasChanged || !ddeAreEqual(updateValues[fieldKey]?.dynamic || '', initialValues?.[fieldKey]?.dynamic || ''), false);
  };

  const finishSaveCicle = (callback?: VoidFunction) => {
    refetchPagaData();
    contextValues.setSavingWidgetIds([]);
    if (contextValues.editAllWidgets) contextValues.setEditAllWidgets(false);
    showSnackBar({ message: 'Loan Updated', open: true });
    callback?.();
  };

  const onError = () => {
    showSnackBar({ message: 'Something went wrong', open: true });
    contextValues.setSavingWidgetIds([]);
  };

  const onSuccess = (response: { dealVersion: number }, savingIds: string[], callback?: VoidFunction, isLastUpdate?: boolean) => {
    const newEditingIds = contextValues.editingWidgetIds.filter((editId) => !savingIds.some((savingId) => savingId === editId));
    contextValues.setEditingWidgetIds(newEditingIds);
    contextValues.setHasSavedIds(savingIds);
    setVersion(response.dealVersion);

    if (isLastUpdate) finishSaveCicle(callback);
  };

  const sendUpdate = (values: DynamicDataElementValues, saveConfig: SaveConfig, callback?: VoidFunction, isLastUpdate?: boolean) => {
    let savingIds: Array<string> = [];
    if (saveConfig.widgetId) savingIds = [saveConfig.widgetId];
    if (saveConfig.widgets) savingIds = saveConfig.widgets.map((w) => w.id);
    contextValues.setSavingWidgetIds(savingIds);
    saveWidget(values, { ...saveConfig, loanId },
      {
        onError,
        onSuccess: (response) => onSuccess(response, savingIds, callback, isLastUpdate),
      });

    if (saveConfig.widgetId) contextValues.setSavingWidgetIds(savingIds.concat(saveConfig.widgetId));
  };

  const handleUpdateLoanPage = (values: DynamicDataElementValues, saveConfigStr: string, callback?: VoidFunction, event?: any, additional?: AdditionalFormInfo) => {
    const isGlobalSave = event.nativeEvent.submitter.id === 'globalSave';
    const saveConfig: SaveConfig | undefined = !saveConfigStr ? undefined : JSON.parse(saveConfigStr);

    if (isGlobalSave || !saveConfig) {
      const configs = Array.from(document.querySelectorAll('button[data-additionalinfo]')).map((button) => JSON.parse(button.getAttribute('data-additionalinfo') || '') as SaveConfig);
      const filteredConfigs = configs.filter((config) => config.widgets?.some((widget) => widgetHasChangedValues(widget, values, additional?.initialValue)));

      if (filteredConfigs.length) {
        filteredConfigs.forEach((additionalInfo, index) => {
          setTimeout(() => {
            const widgetFieldIds = additionalInfo.widgets?.flatMap((w) => w.cardListItem?.fieldsList || w.form?.fieldsList).map((f) => f?.id);
            const updateValues = Object.keys(values).reduce((acc: DynamicDataElementValues, id: string): DynamicDataElementValues => {
              if (widgetFieldIds?.some((fieldId) => fieldId === id)) {
                return { ...acc, [id]: values[id] };
              }
              return acc;
            }, {});

            const isLast = index === filteredConfigs.length - 1;
            const hasChanged = Object.keys(updateValues).reduce((hasChanged, fieldKey) => hasChanged || !ddeAreEqual(updateValues[fieldKey]?.dynamic || '', additional?.initialValue[fieldKey]?.dynamic || ''), false);
            if (hasChanged) {
              sendUpdate(
                updateValues,
                additionalInfo,
                callback,
                isLast,
              );
            } else if (isLast && !hasChanged) {
              finishSaveCicle(callback);
            }
          }, index * 2000);
        });
      } else {
        callback?.();
      }
    } else {
      sendUpdate(values, saveConfig, callback, true);
    }
  };

  const handleUpdateWilqoParty = (values: DynamicDataElementValues, saveConfigStr: string, callback?: VoidFunction) => {
    const saveConfig: SaveConfig | undefined = !saveConfigStr ? undefined : JSON.parse(saveConfigStr);
    if (saveConfig) {
      let savingIds: Array<string> = [];
      if (saveConfig.widgetId) savingIds = [saveConfig.widgetId];
      contextValues.setSavingWidgetIds(savingIds);
      mutate({
        dealId: loanId,
        itemsList: (saveConfig.widgets?.[0].cardListItem?.fieldsList ?? []).reduce((listItems, currentField) => {
          const field = values[currentField.id];
          if (field) {
            const saveItem = new SaveFieldItem();
            saveItem.setConsolidatedItemScopeToken(currentField.consolidatedItemScopeToken);
            saveItem.setFieldId(currentField.id);
            saveItem.setValue(field.dynamic);
            return [...listItems, saveItem.toObject()];
          }
          return listItems;
        }, [] as Array<SaveFieldItem.AsObject>),
        pageId: saveConfig.pageId,
        partyType: getEnumFromParsedValue(WilqoPartyType, searchParams.get('partyTypeLabel')) ?? WilqoPartyType.WILQO_PARTY_TYPE_UNKNOWN,
        scopeToken: saveConfig.scopeToken,
        widgetId: saveConfig.widgetId ?? '',
      }, {
        onError,
        onSuccess: (response) => onSuccess(response, savingIds, callback, true),
      });
    }
  };

  const getSaveStrategy = (values: DynamicDataElementValues, saveConfigStr: string, callback?: VoidFunction, event?: any, additional?: AdditionalFormInfo) => {
    switch (saveStrategy) {
      case LoanPageSaveStrategy.LOAN_PAGE_SAVE_STRATEGY_FULL_PAGE:
      case LoanPageSaveStrategy.LOAN_PAGE_SAVE_STRATEGY_UNKNOWN:
        return handleUpdateLoanPage(values, saveConfigStr, callback, event, additional);
      case LoanPageSaveStrategy.LOAN_PAGE_SAVE_STRATEGY_WILQO_PARTY_MEMBER:
        return handleUpdateWilqoParty(values, saveConfigStr, callback);
      default:
        return () => null;
    }
  };

  return {
    contextValues,
    savePage: getSaveStrategy,
  };
};
