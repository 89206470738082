import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetUserLeadSourcesQueryRequest, GetUserLeadSourcesQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const DOMAIN_USER_LEAD_SOURCE = 'wilqo.api.users.GetUserLeadSourcesQueryRequest';

const useUserLeadSources = (id: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([DOMAIN_USER_LEAD_SOURCE, id], async () => {
    const request = new GetUserLeadSourcesQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setUserId(id);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: DOMAIN_USER_LEAD_SOURCE,
    });
    const response = new GetUserLeadSourcesQueryResponse();
    GetUserLeadSourcesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(DOMAIN_USER_LEAD_SOURCE, responseObj.error, responseObj.leadSourcesList);
  }, { enabled: Boolean(id) && Boolean(bpdId) });
};

export { useUserLeadSources };
