import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import { TextGenerate as Component } from './TextGenerate.component';

export interface TextGenerateProps {
  panelElement: PanelElement.AsObject;
}

export const TextGenerate = (props: TextGenerateProps) => {
  const {
    panelElement: {
      headerText,
      id,
      path,
    },
  } = props;

  const [value, setValue, { hidden }] = useDynamicFormRegister(
    id,
    {
      fromDynamic: toString,
      toDynamic: fromString,
    },
  );

  if (hidden) return null;
  return (
    <Component label={headerText} onValueChange={(v) => setValue(v, v, path)} value={value} />
  );
};
