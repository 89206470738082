import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { forwardRef, useRef } from 'react';

import { HelperText } from '../HelperText';
import type { TextFieldVariant } from '../TextField';
import { TextField } from '../TextField';

export interface SelectProps{
  placeholder?: string;
  options: Array<any>;
  titleKey: string;
  uniqueKey?: string;
  onSelect: (option: any) => void;
  value?: string;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  variant?: TextFieldVariant;
  required?: boolean;
  errorMessage?: string;
  allowClearValue?: boolean;
  id?: string;
  readonly?: boolean;
  scrollIntoView?: boolean;
}

const Select = forwardRef<any, SelectProps>((props: SelectProps, ref) => {
  const {
    allowClearValue,
    className,
    disabled = false,
    errorMessage,
    fullWidth = true,
    onSelect,
    options,
    placeholder = '',
    required = false,
    scrollIntoView = true,
    titleKey,
    uniqueKey = titleKey,
    value = '',
    variant = 'default',
    readonly = false,
    id = `select-${placeholder}`,
  } = props;

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={divRef} className="relative">
      <Listbox disabled={disabled} value={value}>
        <Listbox.Button
          as="button"
          className="relative w-full"
          onClick={scrollIntoView ? () => {
            setTimeout(() => {
              divRef?.current?.scrollIntoView({ behavior: 'smooth' });
            }, 50);
          } : undefined}
        >
          <TextField
            ref={ref}
            autoComplete="off"
            disabled={disabled}
            error={Boolean(errorMessage)}
            id={id}
            message={errorMessage}
            placeholder={`${placeholder}${required ? '*' : ''}`}
            readOnly={readonly}
            trailingIcon={{ icon: 'ArrowDropDown' }}
            value={value || ''}
            variant={variant}
          />
        </Listbox.Button>
        <Listbox.Options className={clsx(
          'absolute mb-4 bg-surface rounded shadow-two overflow-y-auto max-h-[300px] overflow-auto min-w-min w-full z-20',
          className,
          {
            'w-fit': !fullWidth,
            'w-full': fullWidth,
          },
        )}
        >
          {allowClearValue && (
            <Listbox.Option
              className="text-sm cursor-pointer hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed text-on-surface-active items-center flex py-[14px] px-4 w-full whitespace-nowrap border-b"
              onClick={() => onSelect({})}
              value={undefined}
            >
              None
            </Listbox.Option>
          )}
          {
              options?.map((option) => (
                <Listbox.Option
                  key={option[uniqueKey] as string}
                  className="text-sm cursor-pointer hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed text-on-surface-active items-center flex py-[14px] px-4 w-full whitespace-nowrap"
                  onClick={() => onSelect(option)}
                  value={option}
                >
                  {option[titleKey] as string}
                </Listbox.Option>
              ))
            }
        </Listbox.Options>
      </Listbox>
      {errorMessage && <HelperText message={errorMessage} />}
    </div>
  );
});

export default Select;
