import { Disclosure } from '@headlessui/react';

import type { Panel, PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElementValues, FormProgress, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';

import { PanelElementForm } from '../Features/PanelElement/PanelElementForm';
import { Typography } from './Typography';

interface AccordionPanelProps {
  panel: Panel.AsObject;
  panels: Array<Panel.AsObject>;
  onSubmit: (formData: DynamicDataElementValues, e: SubmitValues, id: string) => void;
  progress?: FormProgress;
  navigationButtons: any;
}

const AccordionPanel = (props: AccordionPanelProps) => {
  const { navigationButtons, onSubmit, panel: { panelElementsList }, panels, progress } = props;

  const getInternalPanelElements = (panelElement: PanelElement.AsObject) => {
    if (panelElement.externalLink) {
      const internalPanel = panels.find((panel) => panel.id === panelElement.externalLink?.value);
      return internalPanel?.panelElementsList;
    }
    return [panelElement];
  };

  return (
    <div className="flex gap-4 desktop:gap-6 flex-col">
      {panelElementsList.map((panelElement) => (
        <div key={panelElement.id} className="bg-surface-background border border-on-surface-states-activated rounded">
          <Disclosure key={panelElement.id}>
            <Disclosure.Button className="cursor-pointer w-full max-h-[88px] min-h-[88px] inline-grid">
              <div className="flex items-center justify-center h-full cursor-pointer w-full p-4">
                <Typography className="text-on-surface-active" variant="display6">
                  {panelElement.headerText}
                </Typography>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="p-4 mb-6 sm:px-7 sm:py-0">
              <div className="flex items-center flex-col">
                <span className="font-semibold text-on-surface-active mb-4">{panelElement.summaryText}</span>
                <PanelElementForm
                  buttonGroupProps={{
                    buttons: [{ label: navigationButtons['panel-sequence-next-button'], type: 'submit', variant: 'primary' }],
                    fullWidth: true,
                  }}
                  onSubmit={(formData, e) => onSubmit(formData, e, panelElement.id)}
                  panelElements={getInternalPanelElements(panelElement) || []}
                  progress={progress}
                />
              </div>
            </Disclosure.Panel>
          </Disclosure>
        </div>
      ))}
    </div>
  );
};

export { AccordionPanel };
