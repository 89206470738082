// source: Wilqo.Shared.Mortgage.Integration/MortgageInsurance/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_CustomWrappers_pb = require('../../Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ProgramOptions', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.RateQuotePayment', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.UnderwritingStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.displayName = 'proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.displayName = 'proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.RateQuotePayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.RateQuotePayment.displayName = 'proto.wilqo.shared.mortgage_integration.RateQuotePayment';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    program: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    housingDebtToIncomeRatio: (f = msg.getHousingDebtToIncomeRatio()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalDebtToIncomeRatio: (f = msg.getTotalDebtToIncomeRatio()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions;
  return proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.ProgramOptions} */ (reader.readEnum());
      msg.setProgram(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.UnderwritingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHousingDebtToIncomeRatio(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalDebtToIncomeRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgram();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getHousingDebtToIncomeRatio();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalDebtToIncomeRatio();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional MortgageInsuranceProductType type = 1;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MortgageInsuranceVendor vendor = 2;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ProgramOptions program = 3;
 * @return {!proto.wilqo.shared.mortgage_integration.ProgramOptions}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getProgram = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.ProgramOptions} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ProgramOptions} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setProgram = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional UnderwritingStatus status = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.UnderwritingStatus}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.UnderwritingStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.UnderwritingStatus} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal housing_debt_to_income_ratio = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getHousingDebtToIncomeRatio = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setHousingDebtToIncomeRatio = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.clearHousingDebtToIncomeRatio = function() {
  return this.setHousingDebtToIncomeRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.hasHousingDebtToIncomeRatio = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_debt_to_income_ratio = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.getTotalDebtToIncomeRatio = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
*/
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.setTotalDebtToIncomeRatio = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.clearTotalDebtToIncomeRatio = function() {
  return this.setTotalDebtToIncomeRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions.prototype.hasTotalDebtToIncomeRatio = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentsList: jspb.Message.toObjectList(msg.getPaymentsList(),
    proto.wilqo.shared.mortgage_integration.RateQuotePayment.toObject, includeInstance),
    url: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote;
  return proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.mortgage_integration.RateQuotePayment;
      reader.readMessage(value,proto.wilqo.shared.mortgage_integration.RateQuotePayment.deserializeBinaryFromReader);
      msg.addPayments(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.mortgage_integration.RateQuotePayment.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional MortgageInsuranceProductType type = 1;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional MortgageInsuranceVendor vendor = 2;
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated RateQuotePayment payments = 3;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.RateQuotePayment>}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.getPaymentsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.RateQuotePayment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.mortgage_integration.RateQuotePayment, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.RateQuotePayment>} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} returns this
*/
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.setPaymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.RateQuotePayment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.addPayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.mortgage_integration.RateQuotePayment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.clearPaymentsList = function() {
  return this.setPaymentsList([]);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote} returns this
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceQuote.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.RateQuotePayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: (f = msg.getAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    months: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rate: (f = msg.getRate()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.RateQuotePayment;
  return proto.wilqo.shared.mortgage_integration.RateQuotePayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonths(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.RateQuotePayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonths();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.getAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} returns this
*/
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} returns this
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 months = 2;
 * @return {number}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.getMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} returns this
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.setMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal rate = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.getRate = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} returns this
*/
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.mortgage_integration.RateQuotePayment} returns this
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.mortgage_integration.RateQuotePayment.prototype.hasRate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceProductType = {
  MORTGAGE_INSURANCE_PRODUCT_TYPE_UNSPECIFIED: 0,
  MORTGAGE_INSURANCE_PRODUCT_TYPE_MONTHLY_BORROWER_PAID_NON_REFUNDABLE: 1,
  MORTGAGE_INSURANCE_PRODUCT_TYPE_SINGLE_BORROWER_PAID_REFUNDABLE: 2,
  MORTGAGE_INSURANCE_PRODUCT_TYPE_SINGLE_BORROWER_PAID_NON_REFUNDABLE: 3,
  MORTGAGE_INSURANCE_PRODUCT_TYPE_SINGLE_LENDER_PAID_NON_REFUNDABLE: 4,
  MORTGAGE_INSURANCE_PRODUCT_TYPE_SPLIT_BUY_DOWN: 5
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.MortgageInsuranceVendor = {
  MORTGAGE_INSURANCE_VENDOR_UNSPECIFIED: 0,
  MORTGAGE_INSURANCE_VENDOR_ARCH_MORTGAGE_INSURANCE: 1,
  MORTGAGE_INSURANCE_VENDOR_RADIAN_MORTGAGE_INSURANCE: 2,
  MORTGAGE_INSURANCE_VENDOR_ENAT_MORTGAGE_INSURANCE: 3,
  MORTGAGE_INSURANCE_VENDOR_ESSENT_MORTGAGE_INSURANCE: 4,
  MORTGAGE_INSURANCE_VENDOR_NATIONAL_MORTGAGE_INSURANCE: 5,
  MORTGAGE_INSURANCE_VENDOR_MGIC_MORTGAGE_INSURANCE: 6
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ProgramOptions = {
  PROGRAM_OPTIONS_UNSPECIFIED: 0,
  PROGRAM_OPTIONS_HFA_PREFERRED: 1,
  PROGRAM_OPTIONS_HFA_PREFERRED_RISK_SHARING: 2,
  PROGRAM_OPTIONS_HOME_POSSIBLE: 3,
  PROGRAM_OPTIONS_HOME_POSSIBLE_ADVANTAGE: 4,
  PROGRAM_OPTIONS_HOME_READY: 5,
  PROGRAM_OPTIONS_MY_COMMUNITY_MORTGAGE: 6,
  PROGRAM_OPTIONS_REFI_NOW: 7,
  PROGRAM_OPTIONS_REFI_POSSIBLE: 8
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.UnderwritingStatus = {
  UNDERWRITING_STATUS_UNSPECIFIED: 0,
  UNDERWRITING_STATUS_APPROVE_ELIGIBLE: 1,
  UNDERWRITING_STATUS_APPROVE_IN_ELIGIBLE: 2,
  UNDERWRITING_STATUS_OUT_OF_SCOPE: 3,
  UNDERWRITING_STATUS_REFER_ELIGIBLE: 4,
  UNDERWRITING_STATUS_REFER_IN_ELIGIBLE: 5,
  UNDERWRITING_STATUS_FREDDIE_MAC_ELIGIBLE: 6,
  UNDERWRITING_STATUS_FREDDIE_MAC_IN_ELIGIBLE: 7
};

goog.object.extend(exports, proto.wilqo.shared.mortgage_integration);
