import clsx from 'clsx';

import { Icon } from '@/Components/Atoms/Icon';

import type { Column, TableSort } from '..';

export type SortingType = 'down' | 'none' | 'up';

export interface TableColumnHeaderProps<T extends Record<string, unknown>> {
  setSort?: (sort: TableSort) => void;
  sort?: TableSort;
  column?: Column<T>;
}

export const TableColumnHeader = <T extends Record<string, unknown>>(props: TableColumnHeaderProps<T>) => {
  const { column, setSort, sort } = props;

  const handleClick = () => {
    if (setSort && column) {
      if (!sort || !sort.isAscending) {
        setSort({ columnId: column.id, isAscending: true });
      } else {
        setSort({ columnId: column.id, isAscending: false });
      }
    }
  };

  return (
    <button
      key={column?.id}
      className={
        clsx(
          'flex items-center cursor-auto flex-row bg-white min-h-[41px] min-w-max p-3 uppercase w-full [user-select:none]',
          '[&_span]:text-on-surface-inactive [&_.margin]:mr-1',
          {
            'cursor-pointer hover:bg-on-surface-states-hover': Boolean(setSort),
          },
        )
      }
      onClick={setSort && column ? handleClick : undefined}
      onKeyDown={() => null}
      tabIndex={-1}
      type="button"
    >
      <span
        className={
          clsx(
            'text-on-surface-active text-[10px] tracking-[1.5px] font-semibold',
            { margin: Boolean(sort) },
          )
        }
      >
        {column?.header}
      </span>
      {sort && sort.columnId === column?.id && <Icon icon={sort.isAscending ? 'ArrowDown' : 'ArrowUp'} size="xs" />}
    </button>
  );
};
