import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useAddUserSkillsetGroup } from '@/API/Queries/user/useAddUserSkillsetGroup';
import { useRemoveUserSkillsetGroup } from '@/API/Queries/user/useRemoveUserSkillsetGroup';
import { useSkillsetGroups } from '@/API/Queries/user/useSkillsetGroups';
import { useUser } from '@/API/Queries/user/useUser';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { getAssignedBadgeProps } from '@/Utils/Helpers/getAssignedBadgeProps';
import { normalizeString } from '@/Utils/Helpers/normalizeString';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

type TableItem = {
  displayName: string;
  id: string;
  description: string;
  isAssigned?: IBadgeProps;
};

export const UserSkillsetGroups = () => {
  const { id = '' } = useParams();
  const { showSnackBar } = useShared();

  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState<TableItem[]>([]);

  const { data: user, isLoading: isLoadingUser } = useUser(id);
  const { data: skillsetGroups = [], isLoading: isLoadingSkillsetGroups } = useSkillsetGroups();
  const { mutate: addUserSkillsetGroup } = useAddUserSkillsetGroup();
  const { mutate: removeUserSkillsetGroup } = useRemoveUserSkillsetGroup();

  const isLoading = isLoadingSkillsetGroups || isLoadingUser;

  const handleClickTable = useCallback((item: TableItem) => {
    const isCurrentlyAssigned = Boolean(item.isAssigned);

    const onSuccess = () => {
      showSnackBar({
        message: `${item.displayName} ${isCurrentlyAssigned ? 'unassigned' : 'assigned'} `,
        open: true,
      });
    };

    const onError = (error: any) => {
      showSnackBar({
        message: error.message || 'Something went wrong, try again later',
        open: true,
      });
    };
    const mutateOptions = { onError, onSuccess };

    if (isCurrentlyAssigned) {
      removeUserSkillsetGroup({ skillsetGroupId: item.id, userId: user?.id || '' }, mutateOptions);
    } else {
      addUserSkillsetGroup({ skillsetGroupId: item.id, userId: user?.id || '' }, mutateOptions);
    }
  }, [user, removeUserSkillsetGroup, addUserSkillsetGroup, showSnackBar]);

  const bulkAssign = async (assign: boolean, selected: TableItem[]) => {
    const item = selected.shift();

    if (!item) {
      setSelected([]);
      return;
    }

    const assigned = Boolean(item.isAssigned);

    if (assigned === assign) {
      // Already in the state we want.
      bulkAssign(assign, selected);
      return;
    }

    const toggle = assign ? addUserSkillsetGroup : removeUserSkillsetGroup;
    const onSuccess = () => { bulkAssign(assign, selected); };
    toggle({ skillsetGroupId: item.id, userId: id }, { onSuccess });
  };

  const data = useMemo(() => {
    let items = skillsetGroups.reduce((acc: TableItem[], group) => {
      const isAssigned = user?.skillsetGroupList.some((userGroup) => userGroup.id === group.id);
      const item: TableItem = {
        description: group.longDescription,
        displayName: group.displayName,
        id: group.id,
        isAssigned: isAssigned ? getAssignedBadgeProps() : undefined,
      };
      if (isAssigned) {
        return [item, ...acc];
      }
      return [...acc, item];
    }, []);
    if (searchText) {
      items = items.filter((item) => normalizeString(item.displayName).includes(normalizeString(searchText)));
    }
    return items;
  }, [skillsetGroups, searchText, user]);

  const columns = useMemo((): Column<TableItem>[] => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'displayName',
        type: 'text',
      },
    },
    {
      header: 'Description',
      id: 'description',
      key: {
        text: 'description',
        type: 'text',
      },
    },
    {
      header: '',
      id: 'isAssigned',
      key: {
        badge: 'isAssigned',
        type: 'badge',
      },
    },
  ], []);

  return (
    <Table
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [{
              label: 'assign',
              onClick: () => bulkAssign(true, selected),
            }, {
              label: 'unassign',
              onClick: () => bulkAssign(false, selected),
            }],
            caption: `${selected.length} selected skillset group(s)`,
          },
          searchProps: {
            onChange: setSearchText,
            value: searchText,
          },
          showContextual: selected.length > 0,
          title: 'Assign skillset groups',
        },
      }}
      columns={columns}
      data={data}
      hoverActions={[
        { label: (item) => (item.isAssigned ? 'Unassign' : 'Assign'), onClick: handleClickTable },
      ]}
      isLoading={isLoading}
      setRowSelection={setSelected}
    />
  );
};
