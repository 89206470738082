import { useMutation } from '@tanstack/react-query';

import { GetFilteredFieldValueQueryRequest, GetFilteredFieldValueQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest';

export const useGetFilteredFieldValue = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation((dto: Omit<GetFilteredFieldValueQueryRequest.AsObject, 'businessProcessDomainId'>) => {
    const request = new GetFilteredFieldValueQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setChildFieldId(dto.childFieldId);
    request.setDealId(dto.dealId);
    request.setPageId(dto.pageId);
    request.setParentFieldsList(dto.parentFieldsList.map((parentField) => {
      const instance = new GetFilteredFieldValueQueryRequest.ParentField();
      instance.setFieldId(parentField.fieldId);
      instance.setFieldValue(parentField.fieldValue ? DynamicDataElementMap(parentField.fieldValue) : undefined);
      return instance;
    }));
    request.setScopeToken(dto.scopeToken);
    request.setWidgetId(dto.widgetId);
    request.setWithoutData(dto.withoutData);

    return sendMessage<GetFilteredFieldValueQueryResponse.AsObject>({
      msg: request,
      msgName: MESSAGE_NAME,
    },
    GetFilteredFieldValueQueryResponse);
  });
};
