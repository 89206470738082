import { useMutation } from '@tanstack/react-query';

import { UpdateProgressItemCommandRequest, UpdateProgressItemCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage.UpdateProgressItemCommandRequest';

const useUpdateLoanProgressStatus = () => {
  const sendMessage = useWilqoMessage();

  const UpdateLoanProgressStatusCommand = async (params: Omit<UpdateProgressItemCommandRequest.AsObject, 'businessProcessDomainId'>) => {
    const request = new UpdateProgressItemCommandRequest();
    request.setDealId(params.dealId);
    request.setIdentifier(params.identifier);
    request.setStatusType(params.statusType);
    if (params.statusValue) request.setStatusValue(DynamicDataElementMap(params.statusValue));
    return sendMessage<UpdateProgressItemCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      UpdateProgressItemCommandResponse,
    );
  };

  return useMutation(UpdateLoanProgressStatusCommand);
};

export { useUpdateLoanProgressStatus };
