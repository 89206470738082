import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { normalizeString } from '@/Utils/Helpers/normalizeString';
import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { ListItem } from '../ListItem';
import type { TextFieldVariant } from '../TextField';
import { TextField } from '../TextField';

export interface AutoCompleteProps {
  className?: string;
  onSelect: (item: any) => void;
  onChangeInputValue?: (value: string) => void;
  value?: string;
  placeholder: string;
  options: Array<any>;
  variant?: TextFieldVariant;
  inputRef?: React.Ref<any>;
  optionKey?: string;
  disabled?: boolean;
  disableText?: boolean;
  required?: boolean;
  errorMessage?: string;
  focused?: boolean;
  controlFilter?: boolean;
  noAutoFocus?: boolean;
  helperText?: string;
  isLoading?: boolean;
  noItemsMessage?: string;
  noItemsClick?: () => void;
  autoComplete?: string;
}

function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }
  const nodeClasses = node.getAttribute('class');
  if (node.scrollHeight > node.clientHeight && nodeClasses?.includes('overflow')) {
    return node;
  }
  return getScrollParent(node.parentElement);
}

const AutoComplete = forwardRef((props: AutoCompleteProps, ref: any) => {
  const {
    autoComplete,
    className,
    controlFilter = true,
    disableText,
    disabled = false,
    errorMessage,
    focused,
    helperText,
    isLoading,
    noAutoFocus = false,
    noItemsClick,
    noItemsMessage = 'Nothing found.',
    onChangeInputValue,
    onSelect,
    optionKey = 'headerText',
    options,
    placeholder,
    required = false,
    value,
  } = props;

  const [open, setOpen] = useState(focused);
  const [inputValue, setInputValue] = useState(value || '');
  const [searchValue, setSearchValue] = useState('');
  const [currentSelectedIndex, setCurrenSelectedIndex] = useState(0);
  const [optionsHeight, setOptionsHeight] = useState(0);
  const componentRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindow();

  useOnClickOutside(componentRef, () => setOpen(false));

  const renderableOptions = useMemo(() => options.sort((a, b) => a.order - b.order).map((option) => ({
    ...option,
    [optionKey]: option[optionKey],
  })), [options, optionKey]);

  const hasSetInitialSelectedIndex = useRef(false);
  useEffect(() => {
    if (!hasSetInitialSelectedIndex.current && value) {
      setCurrenSelectedIndex(options.findIndex((option) => option[optionKey] === value));
      hasSetInitialSelectedIndex.current = true;
    }
  }, [value, optionKey, options]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    const scrollableParent = getScrollParent(componentRef?.current?.parentElement || null);
    if (scrollableParent) {
      if (open) {
        scrollableParent.style.overflow = 'hidden';
      } else {
        scrollableParent.style.overflow = 'auto';
      }
    }
  }, [open]);

  const filteredOptions = useMemo(() => {
    if (!controlFilter) return renderableOptions;
    if (searchValue) return renderableOptions.filter((option) => normalizeString(option[optionKey]).includes(normalizeString(searchValue)));
    return renderableOptions;
  }, [renderableOptions, optionKey, searchValue, controlFilter]);

  // eslint-disable-next-line no-restricted-globals
  const [dropdownStyle, setDropdownStyle] = useState<{ top: number | undefined }>();

  const calculateDropdownPosition = useCallback(() => {
    let style = { top: componentRef.current?.getBoundingClientRect().bottom };
    const parentTop = 0;
    const parentBottom = Math.min(document.documentElement.clientHeight, window.innerHeight);

    const optionsBottomOutOfBounds = (componentRef.current?.getBoundingClientRect().bottom || 0) + optionsHeight > parentBottom;
    const optionsTopOutOfBounds = (componentRef.current?.getBoundingClientRect().top || 0) - optionsHeight < parentTop;

    if (open && optionsBottomOutOfBounds) {
      style = { top: (componentRef.current?.getBoundingClientRect().top || 0) - optionsHeight };

      if (optionsTopOutOfBounds) {
        style = { top: 0 };
      }
    }

    setDropdownStyle(style);
  }, [open, optionsHeight]);

  useEffect(() => {
    calculateDropdownPosition();
    window.addEventListener('resize', calculateDropdownPosition);
    return () => {
      window.removeEventListener('resize', calculateDropdownPosition);
    };
  }, [calculateDropdownPosition]);

  useEffect(() => {
    if (!optionsRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (optionsRef.current) setOptionsHeight(optionsRef.current.offsetHeight);
    });
    resizeObserver.observe(optionsRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const firstItemAutoFocus = useRef(false);
  const openByFocusRef = useRef(false);
  useEffect(() => {
    if (focused) {
      if (noAutoFocus && !firstItemAutoFocus.current) {
        firstItemAutoFocus.current = true;
      } else {
        openByFocusRef.current = true;
      }
    }

    return () => {
      openByFocusRef.current = false;
    };
  }, [focused, noAutoFocus]);

  const onSelectOption = (selectedOption: any) => {
    onSelect(selectedOption);
    setOpen(false);
    setInputValue(selectedOption[optionKey]);
    setSearchValue('');
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (open) {
      const selectedOption = filteredOptions[currentSelectedIndex];
      if (selectedOption) {
        onSelectOption(selectedOption);
      }
    } else {
      setOpen(true);
    }
  };

  const handleArrows = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const nextIndex = event.code === 'ArrowDown' ? currentSelectedIndex + 1 : currentSelectedIndex - 1;
    if (filteredOptions[nextIndex]) {
      optionsRef?.current?.children[nextIndex].scrollIntoView({ block: 'nearest', inline: 'nearest' });
      setCurrenSelectedIndex(nextIndex);
    }
  };

  const handleTab = () => {
    if (filteredOptions.length > 0 && open) {
      const option = filteredOptions[currentSelectedIndex];
      onSelect(option);
      setInputValue(option[optionKey]);
    } else {
      setInputValue('');
    }
    setSearchValue('');
    setOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') handleEnter(event);
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') handleArrows(event);
    if (event.code === 'Tab') handleTab();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setSearchValue(event.target.value);
    setOpen(true);
    if (onChangeInputValue) onChangeInputValue(event.target.value);
    setCurrenSelectedIndex(0);
  };

  const handleClickItem = (event: React.MouseEvent<HTMLButtonElement>, option: any) => {
    onSelectOption(option);
    event.stopPropagation();
  };

  const noOptionsFounds = useMemo(() => filteredOptions.length === 0 && inputValue, [filteredOptions.length, inputValue]);

  const handleComponentClick = () => {
    if (disabled) return;
    setOpen(true);
    ref?.current.focus();
  };

  const handleMouseEnter = (index: number) => {
    if (!openByFocusRef.current) {
      setCurrenSelectedIndex(index);
    } else {
      openByFocusRef.current = false;
    }
  };

  const renderValue = () => {
    if (!open && value) return value;
    return inputValue;
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      ref={componentRef}
      aria-autocomplete="list"
      className={clsx('relative w-full', className)}
      onClick={handleComponentClick}
    >
      <TextField
        ref={ref}
        autoComplete={autoComplete}
        disabled={disabled}
        disableText={disableText ?? isMobile}
        error={Boolean(errorMessage) && !open}
        id={placeholder}
        isDropdown
        isLoading={isLoading}
        message={!open && errorMessage ? errorMessage : helperText}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        required={required}
        trailingIcon={{ icon: 'ArrowDropDown' }}
        value={renderValue()}
        variant={props.variant}
      />
      <div
        ref={optionsRef}
        className={clsx('fixed bg-surface rounded shadow-two overflow-y-auto z-20 max-h-[300px]', {
          invisible: !open,
        })}
        style={{
          width: `${componentRef.current?.getBoundingClientRect().width}px`,
          ...dropdownStyle,
        }}
      >
        {noOptionsFounds && <ListItem label={noItemsMessage} onClick={noItemsClick} />}
        {filteredOptions.map((option, index) => (
          <div key={`option-${option[optionKey]}`} onMouseEnter={() => handleMouseEnter(index)}>
            <ListItem
              key={option[optionKey]}
              disableInteractions
              focused={index === currentSelectedIndex}
              label={option[optionKey]}
              leadingIconProps={option[optionKey] === 'Create new' ? { icon: 'Add' } : undefined}
              onClick={(e) => handleClickItem(e, option)}
            />
          </div>
        ))}
      </div>
    </div>

  );
});

export default AutoComplete;
