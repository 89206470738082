// source: Wilqo_API_Users_InstitutionModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Models_Configuration_pb = require('./Wilqo.Shared.Models/Configuration_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Configuration_pb);
goog.exportSymbol('proto.wilqo.api.users.AuthenticationProvider', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessDaysCalendar', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomain', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomainSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.EnvironmentType', null, global);
goog.exportSymbol('proto.wilqo.api.users.ExpirationSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.Institution', null, global);
goog.exportSymbol('proto.wilqo.api.users.InstitutionSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.License', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseAuthorityEnum', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseInformation', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseInformationSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseScopeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.users.Organization', null, global);
goog.exportSymbol('proto.wilqo.api.users.OrganizationSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.Tenant', null, global);
goog.exportSymbol('proto.wilqo.api.users.TenantEnvironment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.Tenant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.Tenant.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.Tenant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.Tenant.displayName = 'proto.wilqo.api.users.Tenant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.Institution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.Institution.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.Institution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.Institution.displayName = 'proto.wilqo.api.users.Institution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.InstitutionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.InstitutionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.InstitutionSummary.displayName = 'proto.wilqo.api.users.InstitutionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.BusinessProcessDomain.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomain.displayName = 'proto.wilqo.api.users.BusinessProcessDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomainSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomainSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomainSummary.displayName = 'proto.wilqo.api.users.BusinessProcessDomainSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.Organization.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.Organization.displayName = 'proto.wilqo.api.users.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.OrganizationSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.OrganizationSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.OrganizationSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.OrganizationSummary.displayName = 'proto.wilqo.api.users.OrganizationSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LicenseInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.LicenseInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LicenseInformation.displayName = 'proto.wilqo.api.users.LicenseInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LicenseInformationSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.LicenseInformationSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LicenseInformationSummary.displayName = 'proto.wilqo.api.users.LicenseInformationSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.License = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.License, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.License.displayName = 'proto.wilqo.api.users.License';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessDaysCalendar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.BusinessDaysCalendar.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.BusinessDaysCalendar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessDaysCalendar.displayName = 'proto.wilqo.api.users.BusinessDaysCalendar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ExpirationSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ExpirationSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ExpirationSummary.displayName = 'proto.wilqo.api.users.ExpirationSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.TenantEnvironment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.TenantEnvironment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.TenantEnvironment.displayName = 'proto.wilqo.api.users.TenantEnvironment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AuthenticationProvider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AuthenticationProvider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AuthenticationProvider.displayName = 'proto.wilqo.api.users.AuthenticationProvider';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.Tenant.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.Tenant.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.Tenant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.Tenant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Tenant.toObject = function(includeInstance, msg) {
  var f, obj = {
    institution: (f = msg.getInstitution()) && proto.wilqo.api.users.Institution.toObject(includeInstance, f),
    businessProcessDomainsList: jspb.Message.toObjectList(msg.getBusinessProcessDomainsList(),
    proto.wilqo.api.users.BusinessProcessDomain.toObject, includeInstance),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.wilqo.api.users.Organization.toObject, includeInstance),
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    proto.wilqo.api.users.LicenseInformation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.Tenant}
 */
proto.wilqo.api.users.Tenant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.Tenant;
  return proto.wilqo.api.users.Tenant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.Tenant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.Tenant}
 */
proto.wilqo.api.users.Tenant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.users.Institution;
      reader.readMessage(value,proto.wilqo.api.users.Institution.deserializeBinaryFromReader);
      msg.setInstitution(value);
      break;
    case 2:
      var value = new proto.wilqo.api.users.BusinessProcessDomain;
      reader.readMessage(value,proto.wilqo.api.users.BusinessProcessDomain.deserializeBinaryFromReader);
      msg.addBusinessProcessDomains(value);
      break;
    case 3:
      var value = new proto.wilqo.api.users.Organization;
      reader.readMessage(value,proto.wilqo.api.users.Organization.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.LicenseInformation;
      reader.readMessage(value,proto.wilqo.api.users.LicenseInformation.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.Tenant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.Tenant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.Tenant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Tenant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitution();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.users.Institution.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.users.BusinessProcessDomain.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.users.Organization.serializeBinaryToWriter
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.users.LicenseInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Institution institution = 1;
 * @return {?proto.wilqo.api.users.Institution}
 */
proto.wilqo.api.users.Tenant.prototype.getInstitution = function() {
  return /** @type{?proto.wilqo.api.users.Institution} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.Institution, 1));
};


/**
 * @param {?proto.wilqo.api.users.Institution|undefined} value
 * @return {!proto.wilqo.api.users.Tenant} returns this
*/
proto.wilqo.api.users.Tenant.prototype.setInstitution = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.Tenant} returns this
 */
proto.wilqo.api.users.Tenant.prototype.clearInstitution = function() {
  return this.setInstitution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.Tenant.prototype.hasInstitution = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BusinessProcessDomain business_process_domains = 2;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomain>}
 */
proto.wilqo.api.users.Tenant.prototype.getBusinessProcessDomainsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.BusinessProcessDomain, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomain>} value
 * @return {!proto.wilqo.api.users.Tenant} returns this
*/
proto.wilqo.api.users.Tenant.prototype.setBusinessProcessDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.Tenant.prototype.addBusinessProcessDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.BusinessProcessDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Tenant} returns this
 */
proto.wilqo.api.users.Tenant.prototype.clearBusinessProcessDomainsList = function() {
  return this.setBusinessProcessDomainsList([]);
};


/**
 * repeated Organization organizations = 3;
 * @return {!Array<!proto.wilqo.api.users.Organization>}
 */
proto.wilqo.api.users.Tenant.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.Organization, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.Organization>} value
 * @return {!proto.wilqo.api.users.Tenant} returns this
*/
proto.wilqo.api.users.Tenant.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.Organization}
 */
proto.wilqo.api.users.Tenant.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Tenant} returns this
 */
proto.wilqo.api.users.Tenant.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};


/**
 * repeated LicenseInformation licenses = 4;
 * @return {!Array<!proto.wilqo.api.users.LicenseInformation>}
 */
proto.wilqo.api.users.Tenant.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LicenseInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.LicenseInformation, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LicenseInformation>} value
 * @return {!proto.wilqo.api.users.Tenant} returns this
*/
proto.wilqo.api.users.Tenant.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.LicenseInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.Tenant.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.LicenseInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Tenant} returns this
 */
proto.wilqo.api.users.Tenant.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.Institution.repeatedFields_ = [5,23,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.Institution.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.Institution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.Institution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Institution.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: (f = msg.getOwner()) && proto.wilqo.api.users.InstitutionSummary.toObject(includeInstance, f),
    businessProcessDomainsList: jspb.Message.toObjectList(msg.getBusinessProcessDomainsList(),
    proto.wilqo.api.users.BusinessProcessDomain.toObject, includeInstance),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 15, ""),
    mersOrganizationIdentifier: jspb.Message.getFieldWithDefault(msg, 18, ""),
    institutionDisplayName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    institutionFullName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    defaultBusinessProcessId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    defaultOrganizationId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f,
    environmentsList: jspb.Message.toObjectList(msg.getEnvironmentsList(),
    proto.wilqo.api.users.TenantEnvironment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.Institution}
 */
proto.wilqo.api.users.Institution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.Institution;
  return proto.wilqo.api.users.Institution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.Institution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.Institution}
 */
proto.wilqo.api.users.Institution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.InstitutionSummary;
      reader.readMessage(value,proto.wilqo.api.users.InstitutionSummary.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = new proto.wilqo.api.users.BusinessProcessDomain;
      reader.readMessage(value,proto.wilqo.api.users.BusinessProcessDomain.deserializeBinaryFromReader);
      msg.addBusinessProcessDomains(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMersOrganizationIdentifier(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionDisplayName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionFullName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultBusinessProcessId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultOrganizationId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 24:
      var value = new proto.wilqo.api.users.TenantEnvironment;
      reader.readMessage(value,proto.wilqo.api.users.TenantEnvironment.deserializeBinaryFromReader);
      msg.addEnvironments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.Institution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.Institution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.Institution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Institution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.users.InstitutionSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.users.BusinessProcessDomain.serializeBinaryToWriter
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMersOrganizationIdentifier();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getInstitutionDisplayName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInstitutionFullName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDefaultBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDefaultOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      23,
      f
    );
  }
  f = message.getEnvironmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.wilqo.api.users.TenantEnvironment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InstitutionSummary owner = 4;
 * @return {?proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.Institution.prototype.getOwner = function() {
  return /** @type{?proto.wilqo.api.users.InstitutionSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.InstitutionSummary, 4));
};


/**
 * @param {?proto.wilqo.api.users.InstitutionSummary|undefined} value
 * @return {!proto.wilqo.api.users.Institution} returns this
*/
proto.wilqo.api.users.Institution.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.Institution.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated BusinessProcessDomain business_process_domains = 5;
 * @return {!Array<!proto.wilqo.api.users.BusinessProcessDomain>}
 */
proto.wilqo.api.users.Institution.prototype.getBusinessProcessDomainsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.BusinessProcessDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.BusinessProcessDomain, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.BusinessProcessDomain>} value
 * @return {!proto.wilqo.api.users.Institution} returns this
*/
proto.wilqo.api.users.Institution.prototype.setBusinessProcessDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.BusinessProcessDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.Institution.prototype.addBusinessProcessDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.BusinessProcessDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.clearBusinessProcessDomainsList = function() {
  return this.setBusinessProcessDomainsList([]);
};


/**
 * optional string address_line_text = 6;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address_additional_line_text = 7;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city_name = 8;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country_name = 10;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string plus_four_zip_code = 12;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string state_code = 13;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string state_name = 14;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string legal_entity_indicator = 15;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string mers_organization_identifier = 18;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getMersOrganizationIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setMersOrganizationIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string institution_display_name = 19;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getInstitutionDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setInstitutionDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string institution_full_name = 20;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getInstitutionFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setInstitutionFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string default_business_process_id = 21;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getDefaultBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setDefaultBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string default_organization_id = 22;
 * @return {string}
 */
proto.wilqo.api.users.Institution.prototype.getDefaultOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setDefaultOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated string organization_ids = 23;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.Institution.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated TenantEnvironment environments = 24;
 * @return {!Array<!proto.wilqo.api.users.TenantEnvironment>}
 */
proto.wilqo.api.users.Institution.prototype.getEnvironmentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.TenantEnvironment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.TenantEnvironment, 24));
};


/**
 * @param {!Array<!proto.wilqo.api.users.TenantEnvironment>} value
 * @return {!proto.wilqo.api.users.Institution} returns this
*/
proto.wilqo.api.users.Institution.prototype.setEnvironmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.wilqo.api.users.TenantEnvironment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.TenantEnvironment}
 */
proto.wilqo.api.users.Institution.prototype.addEnvironments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.wilqo.api.users.TenantEnvironment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Institution} returns this
 */
proto.wilqo.api.users.Institution.prototype.clearEnvironmentsList = function() {
  return this.setEnvironmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.InstitutionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.InstitutionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.InstitutionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.InstitutionSummary;
  return proto.wilqo.api.users.InstitutionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.InstitutionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.InstitutionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.InstitutionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.InstitutionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.InstitutionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_text = 3;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_additional_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_name = 5;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string plus_four_zip_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state_name = 11;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string legal_entity_indicator = 12;
 * @return {string}
 */
proto.wilqo.api.users.InstitutionSummary.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.InstitutionSummary} returns this
 */
proto.wilqo.api.users.InstitutionSummary.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.BusinessProcessDomain.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    owner: (f = msg.getOwner()) && proto.wilqo.api.users.InstitutionSummary.toObject(includeInstance, f),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.wilqo.api.users.OrganizationSummary.toObject, includeInstance),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.BusinessProcessDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomain;
  return proto.wilqo.api.users.BusinessProcessDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomain}
 */
proto.wilqo.api.users.BusinessProcessDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 5:
      var value = new proto.wilqo.api.users.InstitutionSummary;
      reader.readMessage(value,proto.wilqo.api.users.InstitutionSummary.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.OrganizationSummary;
      reader.readMessage(value,proto.wilqo.api.users.OrganizationSummary.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.users.InstitutionSummary.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.OrganizationSummary.serializeBinaryToWriter
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_description = 4;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional InstitutionSummary owner = 5;
 * @return {?proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getOwner = function() {
  return /** @type{?proto.wilqo.api.users.InstitutionSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.InstitutionSummary, 5));
};


/**
 * @param {?proto.wilqo.api.users.InstitutionSummary|undefined} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
*/
proto.wilqo.api.users.BusinessProcessDomain.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated OrganizationSummary organizations = 6;
 * @return {!Array<!proto.wilqo.api.users.OrganizationSummary>}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.OrganizationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.OrganizationSummary, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.OrganizationSummary>} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
*/
proto.wilqo.api.users.BusinessProcessDomain.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.OrganizationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.OrganizationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};


/**
 * optional bool is_default = 7;
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomain} returns this
 */
proto.wilqo.api.users.BusinessProcessDomain.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomainSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomainSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: (f = msg.getOwner()) && proto.wilqo.api.users.InstitutionSummary.toObject(includeInstance, f),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomainSummary;
  return proto.wilqo.api.users.BusinessProcessDomainSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDescription(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.InstitutionSummary;
      reader.readMessage(value,proto.wilqo.api.users.InstitutionSummary.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomainSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.users.InstitutionSummary.serializeBinaryToWriter
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.getShortDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.setShortDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional InstitutionSummary owner = 4;
 * @return {?proto.wilqo.api.users.InstitutionSummary}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.getOwner = function() {
  return /** @type{?proto.wilqo.api.users.InstitutionSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.InstitutionSummary, 4));
};


/**
 * @param {?proto.wilqo.api.users.InstitutionSummary|undefined} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
*/
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_default = 5;
 * @return {boolean}
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainSummary} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainSummary.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.Organization.repeatedFields_ = [6,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: (f = msg.getOwner()) && proto.wilqo.api.users.BusinessProcessDomainSummary.toObject(includeInstance, f),
    licenseInfosList: jspb.Message.toObjectList(msg.getLicenseInfosList(),
    proto.wilqo.api.users.LicenseInformationSummary.toObject, includeInstance),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    nationalLicensingDescription: jspb.Message.getFieldWithDefault(msg, 17, ""),
    fullAddress: jspb.Message.getFieldWithDefault(msg, 18, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 20, ""),
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    proto.wilqo.api.users.License.toObject, includeInstance),
    licenseStatesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 23, ""),
    tollFreeNumber: jspb.Message.getFieldWithDefault(msg, 24, ""),
    faxNumber: jspb.Message.getFieldWithDefault(msg, 25, ""),
    website: jspb.Message.getFieldWithDefault(msg, 26, ""),
    email: jspb.Message.getFieldWithDefault(msg, 27, ""),
    preferredStartContactHour: (f = msg.getPreferredStartContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    preferredEndContactHour: (f = msg.getPreferredEndContactHour()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 30, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.Organization}
 */
proto.wilqo.api.users.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.Organization;
  return proto.wilqo.api.users.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.Organization}
 */
proto.wilqo.api.users.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.BusinessProcessDomainSummary;
      reader.readMessage(value,proto.wilqo.api.users.BusinessProcessDomainSummary.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.LicenseInformationSummary;
      reader.readMessage(value,proto.wilqo.api.users.LicenseInformationSummary.deserializeBinaryFromReader);
      msg.addLicenseInfos(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingDescription(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullAddress(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 21:
      var value = new proto.wilqo.api.users.License;
      reader.readMessage(value,proto.wilqo.api.users.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addLicenseStates(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTollFreeNumber(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFaxNumber(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 28:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredStartContactHour(value);
      break;
    case 29:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPreferredEndContactHour(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.users.BusinessProcessDomainSummary.serializeBinaryToWriter
    );
  }
  f = message.getLicenseInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.LicenseInformationSummary.serializeBinaryToWriter
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNationalLicensingDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFullAddress();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.wilqo.api.users.License.serializeBinaryToWriter
    );
  }
  f = message.getLicenseStatesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTollFreeNumber();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getFaxNumber();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getPreferredStartContactHour();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPreferredEndContactHour();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BusinessProcessDomainSummary owner = 4;
 * @return {?proto.wilqo.api.users.BusinessProcessDomainSummary}
 */
proto.wilqo.api.users.Organization.prototype.getOwner = function() {
  return /** @type{?proto.wilqo.api.users.BusinessProcessDomainSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.BusinessProcessDomainSummary, 4));
};


/**
 * @param {?proto.wilqo.api.users.BusinessProcessDomainSummary|undefined} value
 * @return {!proto.wilqo.api.users.Organization} returns this
*/
proto.wilqo.api.users.Organization.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.Organization.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated LicenseInformationSummary license_infos = 6;
 * @return {!Array<!proto.wilqo.api.users.LicenseInformationSummary>}
 */
proto.wilqo.api.users.Organization.prototype.getLicenseInfosList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.LicenseInformationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.LicenseInformationSummary, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.LicenseInformationSummary>} value
 * @return {!proto.wilqo.api.users.Organization} returns this
*/
proto.wilqo.api.users.Organization.prototype.setLicenseInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.LicenseInformationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LicenseInformationSummary}
 */
proto.wilqo.api.users.Organization.prototype.addLicenseInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.LicenseInformationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearLicenseInfosList = function() {
  return this.setLicenseInfosList([]);
};


/**
 * optional string address_line_text = 7;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address_additional_line_text = 8;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string city_name = 9;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country_name = 11;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string postal_code = 12;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string plus_four_zip_code = 13;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string state_code = 14;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string state_name = 15;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string national_licensing_id = 16;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string national_licensing_description = 17;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getNationalLicensingDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setNationalLicensingDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string full_address = 18;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setFullAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string external_id = 19;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string legal_entity_indicator = 20;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated License licenses = 21;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.Organization.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.License, 21));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.Organization} returns this
*/
proto.wilqo.api.users.Organization.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.Organization.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * repeated string license_states = 22;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.Organization.prototype.getLicenseStatesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setLicenseStatesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.addLicenseStates = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearLicenseStatesList = function() {
  return this.setLicenseStatesList([]);
};


/**
 * optional string phone_number = 23;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string toll_free_number = 24;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getTollFreeNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setTollFreeNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string fax_number = 25;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getFaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setFaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string website = 26;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string email = 27;
 * @return {string}
 */
proto.wilqo.api.users.Organization.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional google.protobuf.Timestamp preferred_start_contact_hour = 28;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.Organization.prototype.getPreferredStartContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.Organization} returns this
*/
proto.wilqo.api.users.Organization.prototype.setPreferredStartContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearPreferredStartContactHour = function() {
  return this.setPreferredStartContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.Organization.prototype.hasPreferredStartContactHour = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.Timestamp preferred_end_contact_hour = 29;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.Organization.prototype.getPreferredEndContactHour = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 29));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.Organization} returns this
*/
proto.wilqo.api.users.Organization.prototype.setPreferredEndContactHour = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.clearPreferredEndContactHour = function() {
  return this.setPreferredEndContactHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.Organization.prototype.hasPreferredEndContactHour = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional bool is_default = 30;
 * @return {boolean}
 */
proto.wilqo.api.users.Organization.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.Organization} returns this
 */
proto.wilqo.api.users.Organization.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.OrganizationSummary.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.OrganizationSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.OrganizationSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    addressLineText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    plusFourZipCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    nationalLicensingDescription: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fullAddress: jspb.Message.getFieldWithDefault(msg, 14, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    legalEntityIndicator: jspb.Message.getFieldWithDefault(msg, 16, ""),
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    proto.wilqo.api.users.License.toObject, includeInstance),
    fullName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    isDefault: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.OrganizationSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.OrganizationSummary;
  return proto.wilqo.api.users.OrganizationSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.OrganizationSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.OrganizationSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlusFourZipCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingDescription(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullAddress(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntityIndicator(value);
      break;
    case 17:
      var value = new proto.wilqo.api.users.License;
      reader.readMessage(value,proto.wilqo.api.users.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.OrganizationSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.OrganizationSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.OrganizationSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlusFourZipCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNationalLicensingDescription();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFullAddress();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLegalEntityIndicator();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.wilqo.api.users.License.serializeBinaryToWriter
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getIsDefault();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address_line_text = 3;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_additional_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_name = 5;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country_code = 6;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country_name = 7;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string plus_four_zip_code = 9;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getPlusFourZipCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setPlusFourZipCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string state_code = 10;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string state_name = 11;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string national_licensing_id = 12;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string national_licensing_description = 13;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getNationalLicensingDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setNationalLicensingDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string full_address = 14;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setFullAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string external_id = 15;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string legal_entity_indicator = 16;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getLegalEntityIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setLegalEntityIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated License licenses = 17;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.License, 17));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
*/
proto.wilqo.api.users.OrganizationSummary.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * optional string full_name = 18;
 * @return {string}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool is_default = 19;
 * @return {boolean}
 */
proto.wilqo.api.users.OrganizationSummary.prototype.getIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.OrganizationSummary} returns this
 */
proto.wilqo.api.users.OrganizationSummary.prototype.setIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LicenseInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LicenseInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LicenseInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    licenseDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    licenseIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    organization: (f = msg.getOrganization()) && proto.wilqo.api.users.OrganizationSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.LicenseInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LicenseInformation;
  return proto.wilqo.api.users.LicenseInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LicenseInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LicenseInformation}
 */
proto.wilqo.api.users.LicenseInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseIdentifier(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.OrganizationSummary;
      reader.readMessage(value,proto.wilqo.api.users.OrganizationSummary.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LicenseInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LicenseInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LicenseInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLicenseDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLicenseIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.users.OrganizationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
 */
proto.wilqo.api.users.LicenseInformation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
 */
proto.wilqo.api.users.LicenseInformation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string license_description = 3;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformation.prototype.getLicenseDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
 */
proto.wilqo.api.users.LicenseInformation.prototype.setLicenseDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string license_identifier = 5;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformation.prototype.getLicenseIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
 */
proto.wilqo.api.users.LicenseInformation.prototype.setLicenseIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional OrganizationSummary organization = 4;
 * @return {?proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.LicenseInformation.prototype.getOrganization = function() {
  return /** @type{?proto.wilqo.api.users.OrganizationSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.OrganizationSummary, 4));
};


/**
 * @param {?proto.wilqo.api.users.OrganizationSummary|undefined} value
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
*/
proto.wilqo.api.users.LicenseInformation.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.LicenseInformation} returns this
 */
proto.wilqo.api.users.LicenseInformation.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.LicenseInformation.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LicenseInformationSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LicenseInformationSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseInformationSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    licenseInfo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LicenseInformationSummary}
 */
proto.wilqo.api.users.LicenseInformationSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LicenseInformationSummary;
  return proto.wilqo.api.users.LicenseInformationSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LicenseInformationSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LicenseInformationSummary}
 */
proto.wilqo.api.users.LicenseInformationSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LicenseInformationSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LicenseInformationSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseInformationSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLicenseInfo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformationSummary} returns this
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformationSummary} returns this
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string license_info = 3;
 * @return {string}
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.getLicenseInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseInformationSummary} returns this
 */
proto.wilqo.api.users.LicenseInformationSummary.prototype.setLicenseInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.License.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.License.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.License} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.License.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    licenseScopeType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    licenseScope: jspb.Message.getFieldWithDefault(msg, 3, ""),
    licenseAuthorityType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    issueDate: (f = msg.getIssueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    licenseStatusType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    licenseDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    licenseLegalName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    licenseId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.License.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.License;
  return proto.wilqo.api.users.License.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.License} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.License.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.users.LicenseScopeEnum} */ (reader.readEnum());
      msg.setLicenseScopeType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseScope(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.users.LicenseAuthorityEnum} */ (reader.readEnum());
      msg.setLicenseAuthorityType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssueDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.users.LicenseStatusEnum} */ (reader.readEnum());
      msg.setLicenseStatusType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseDescription(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseLegalName(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicenseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.License.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.License.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.License} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.License.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLicenseScopeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLicenseScope();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLicenseAuthorityType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getIssueDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLicenseStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLicenseDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLicenseLegalName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getLicenseId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.License.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LicenseScopeEnum license_scope_type = 2;
 * @return {!proto.wilqo.api.users.LicenseScopeEnum}
 */
proto.wilqo.api.users.License.prototype.getLicenseScopeType = function() {
  return /** @type {!proto.wilqo.api.users.LicenseScopeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.users.LicenseScopeEnum} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseScopeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string license_scope = 3;
 * @return {string}
 */
proto.wilqo.api.users.License.prototype.getLicenseScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseScope = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LicenseAuthorityEnum license_authority_type = 4;
 * @return {!proto.wilqo.api.users.LicenseAuthorityEnum}
 */
proto.wilqo.api.users.License.prototype.getLicenseAuthorityType = function() {
  return /** @type {!proto.wilqo.api.users.LicenseAuthorityEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.users.LicenseAuthorityEnum} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseAuthorityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp issue_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.License.prototype.getIssueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.License} returns this
*/
proto.wilqo.api.users.License.prototype.setIssueDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.clearIssueDate = function() {
  return this.setIssueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.License.prototype.hasIssueDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp start_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.License.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.License} returns this
*/
proto.wilqo.api.users.License.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.License.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.License.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.License} returns this
*/
proto.wilqo.api.users.License.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.License.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional LicenseStatusEnum license_status_type = 8;
 * @return {!proto.wilqo.api.users.LicenseStatusEnum}
 */
proto.wilqo.api.users.License.prototype.getLicenseStatusType = function() {
  return /** @type {!proto.wilqo.api.users.LicenseStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.users.LicenseStatusEnum} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string license_description = 9;
 * @return {string}
 */
proto.wilqo.api.users.License.prototype.getLicenseDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string license_legal_name = 10;
 * @return {string}
 */
proto.wilqo.api.users.License.prototype.getLicenseLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool enabled = 11;
 * @return {boolean}
 */
proto.wilqo.api.users.License.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string license_id = 12;
 * @return {string}
 */
proto.wilqo.api.users.License.prototype.getLicenseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.License} returns this
 */
proto.wilqo.api.users.License.prototype.setLicenseId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.BusinessDaysCalendar.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessDaysCalendar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessDaysCalendar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessDaysCalendar.toObject = function(includeInstance, msg) {
  var f, obj = {
    weekDaysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    holidaysList: jspb.Message.toObjectList(msg.getHolidaysList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar}
 */
proto.wilqo.api.users.BusinessDaysCalendar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessDaysCalendar;
  return proto.wilqo.api.users.BusinessDaysCalendar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessDaysCalendar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar}
 */
proto.wilqo.api.users.BusinessDaysCalendar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addWeekDays(values[i]);
      }
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addHolidays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessDaysCalendar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessDaysCalendar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessDaysCalendar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeekDaysList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getHolidaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated uint32 week_days = 1;
 * @return {!Array<number>}
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.getWeekDaysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar} returns this
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.setWeekDaysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar} returns this
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.addWeekDays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar} returns this
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.clearWeekDaysList = function() {
  return this.setWeekDaysList([]);
};


/**
 * repeated google.protobuf.Timestamp holidays = 2;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.getHolidaysList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar} returns this
*/
proto.wilqo.api.users.BusinessDaysCalendar.prototype.setHolidaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.addHolidays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.BusinessDaysCalendar} returns this
 */
proto.wilqo.api.users.BusinessDaysCalendar.prototype.clearHolidaysList = function() {
  return this.setHolidaysList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ExpirationSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ExpirationSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExpirationSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    originationDate: (f = msg.getOriginationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationDateConfiguration: (f = msg.getExpirationDateConfiguration()) && Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.toObject(includeInstance, f),
    remainingCalendarDays: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ExpirationSummary}
 */
proto.wilqo.api.users.ExpirationSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ExpirationSummary;
  return proto.wilqo.api.users.ExpirationSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ExpirationSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ExpirationSummary}
 */
proto.wilqo.api.users.ExpirationSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOriginationDate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.deserializeBinaryFromReader);
      msg.setExpirationDateConfiguration(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRemainingCalendarDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ExpirationSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ExpirationSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ExpirationSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOriginationDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDateConfiguration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getRemainingCalendarDays();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp expiration_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
*/
proto.wilqo.api.users.ExpirationSummary.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
 */
proto.wilqo.api.users.ExpirationSummary.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp origination_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.getOriginationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
*/
proto.wilqo.api.users.ExpirationSummary.prototype.setOriginationDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
 */
proto.wilqo.api.users.ExpirationSummary.prototype.clearOriginationDate = function() {
  return this.setOriginationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.hasOriginationDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ExpirationDateConfiguration expiration_date_configuration = 3;
 * @return {?proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.getExpirationDateConfiguration = function() {
  return /** @type{?proto.wilqo.shared.models.ExpirationDateConfiguration} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ExpirationDateConfiguration|undefined} value
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
*/
proto.wilqo.api.users.ExpirationSummary.prototype.setExpirationDateConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
 */
proto.wilqo.api.users.ExpirationSummary.prototype.clearExpirationDateConfiguration = function() {
  return this.setExpirationDateConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.hasExpirationDateConfiguration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 remaining_calendar_days = 4;
 * @return {number}
 */
proto.wilqo.api.users.ExpirationSummary.prototype.getRemainingCalendarDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.ExpirationSummary} returns this
 */
proto.wilqo.api.users.ExpirationSummary.prototype.setRemainingCalendarDays = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.TenantEnvironment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.TenantEnvironment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.TenantEnvironment.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    authenticationProvider: (f = msg.getAuthenticationProvider()) && proto.wilqo.api.users.AuthenticationProvider.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.TenantEnvironment}
 */
proto.wilqo.api.users.TenantEnvironment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.TenantEnvironment;
  return proto.wilqo.api.users.TenantEnvironment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.TenantEnvironment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.TenantEnvironment}
 */
proto.wilqo.api.users.TenantEnvironment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.users.EnvironmentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.wilqo.api.users.AuthenticationProvider;
      reader.readMessage(value,proto.wilqo.api.users.AuthenticationProvider.deserializeBinaryFromReader);
      msg.setAuthenticationProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.TenantEnvironment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.TenantEnvironment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.TenantEnvironment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAuthenticationProvider();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.users.AuthenticationProvider.serializeBinaryToWriter
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.TenantEnvironment} returns this
 */
proto.wilqo.api.users.TenantEnvironment.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EnvironmentType type = 2;
 * @return {!proto.wilqo.api.users.EnvironmentType}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.users.EnvironmentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.users.EnvironmentType} value
 * @return {!proto.wilqo.api.users.TenantEnvironment} returns this
 */
proto.wilqo.api.users.TenantEnvironment.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AuthenticationProvider authentication_provider = 3;
 * @return {?proto.wilqo.api.users.AuthenticationProvider}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.getAuthenticationProvider = function() {
  return /** @type{?proto.wilqo.api.users.AuthenticationProvider} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.AuthenticationProvider, 3));
};


/**
 * @param {?proto.wilqo.api.users.AuthenticationProvider|undefined} value
 * @return {!proto.wilqo.api.users.TenantEnvironment} returns this
*/
proto.wilqo.api.users.TenantEnvironment.prototype.setAuthenticationProvider = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.TenantEnvironment} returns this
 */
proto.wilqo.api.users.TenantEnvironment.prototype.clearAuthenticationProvider = function() {
  return this.setAuthenticationProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.TenantEnvironment.prototype.hasAuthenticationProvider = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AuthenticationProvider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AuthenticationProvider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AuthenticationProvider.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    swaggerClientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    frontEndClientId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AuthenticationProvider}
 */
proto.wilqo.api.users.AuthenticationProvider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AuthenticationProvider;
  return proto.wilqo.api.users.AuthenticationProvider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AuthenticationProvider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AuthenticationProvider}
 */
proto.wilqo.api.users.AuthenticationProvider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwaggerClientId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrontEndClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AuthenticationProvider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AuthenticationProvider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AuthenticationProvider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSwaggerClientId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrontEndClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string domain = 1;
 * @return {string}
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AuthenticationProvider} returns this
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string swagger_client_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.getSwaggerClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AuthenticationProvider} returns this
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.setSwaggerClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string front_end_client_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.getFrontEndClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AuthenticationProvider} returns this
 */
proto.wilqo.api.users.AuthenticationProvider.prototype.setFrontEndClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.users.LicenseScopeEnum = {
  LICENSE_SCOPE_ENUM_UNKNOWN: 0,
  LICENSE_SCOPE_ENUM_NATIONAL: 1,
  LICENSE_SCOPE_ENUM_STATE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.users.LicenseAuthorityEnum = {
  LICENSE_AUTHORITY_ENUM_UNKNOWN: 0,
  LICENSE_AUTHORITY_ENUM_NMLS: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.users.LicenseStatusEnum = {
  LICENSE_STATUS_ENUM_UNKNOWN: 0,
  LICENSE_STATUS_ENUM_PENDING: 1,
  LICENSE_STATUS_ENUM_APPROVED: 2,
  LICENSE_STATUS_ENUM_TEMPORARY: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.users.EnvironmentType = {
  ENVIRONMENT_TYPE_UNKNOWN: 0,
  ENVIRONMENT_TYPE_LOCAL: 1,
  ENVIRONMENT_TYPE_DEV: 2,
  ENVIRONMENT_TYPE_QA: 3,
  ENVIRONMENT_TYPE_STAGING: 4,
  ENVIRONMENT_TYPE_DEMO: 5,
  ENVIRONMENT_TYPE_PRODUCTION: 6,
  ENVIRONMENT_TYPE_DEBUG: 7,
  ENVIRONMENT_TYPE_SANDBOX: 8,
  ENVIRONMENT_TYPE_UAT: 9
};

goog.object.extend(exports, proto.wilqo.api.users);
