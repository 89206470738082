import { useEffect, useRef } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElement, DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useTriggerContext } from '@/Components/Features/trigger/TriggerContext';
import { toString } from '@/Utils/Helpers/dynamicDataElementHelper';

interface TriggerConstantProps {
  optionsList: PanelElementOption.AsObject[];
  ruleGroupId: string;
  ruleId: string;
}
export const TriggerConstant = (props: TriggerConstantProps) => {
  const { optionsList, ruleGroupId, ruleId } = props;

  const { updateRuleComparable } = useTriggerContext();
  const hasSetValue = useRef(false);

  useEffect(() => {
    if (!hasSetValue.current) {
      const [option] = optionsList;
      const target = option?.value;
      // Limitation: Currently, only string is supported.
      const dde = new DynamicDataElement();
      dde.setDataType(target?.dataType || DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_STRING);
      dde.setValue(target?.value || '');
      const decoded = toString(dde);
      updateRuleComparable(ruleGroupId, ruleId, decoded);
      hasSetValue.current = true;
    }
  }, [optionsList, ruleGroupId, ruleId, updateRuleComparable]);

  return <div hidden />;
};
