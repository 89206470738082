import { noop } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';

import Input from '../Input/Input.component';

export interface TextGenerateProps {
  label: string;
  value?: string;
  onValueChange?: (val: string) => void;
}

export const TextGenerate = (props: TextGenerateProps) => {
  const { label, onValueChange = noop, value } = props;

  useEffect(() => {
    if (value && value !== '--') {
      return;
    }

    const next = nanoid();
    onValueChange(next);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <Input label={label} onChange={noop} required value={value} />;
};
