// source: Wilqo_API_Activity_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var Wilqo_API_Users_Models_pb = require('./Wilqo_API_Users_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Users_Models_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb = require('./Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_API_Activity_Models_pb = require('./Wilqo_API_Activity_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Models_pb);
goog.exportSymbol('proto.wilqo.api.activity.ExternalActivityQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityInstanceListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.QuerySettingsCase', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityInstanceListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByObjectQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListByObjectQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivitySummaryQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivitySummaryQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTemplateListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTemplateListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityVersionsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetActivityVersionsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetChecklistActivityQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetChecklistActivityQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetExternalActivityQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityByIdQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityByIdQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByObjectQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityListByObjectQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByObjectQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityListByObjectQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.displayName = 'proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.displayName = 'proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.displayName = 'proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.displayName = 'proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetChecklistActivityQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetChecklistActivityQueryRequest.displayName = 'proto.wilqo.api.activity.GetChecklistActivityQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetChecklistActivityQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetChecklistActivityQueryResponse.displayName = 'proto.wilqo.api.activity.GetChecklistActivityQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.displayName = 'proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.displayName = 'proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTemplateListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityTemplateListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTemplateListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityTemplateListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.displayName = 'proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.displayName = 'proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.displayName = 'proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.displayName = 'proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityVersionsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityVersionsQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityVersionsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityVersionsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityVersionsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityVersionsQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityVersionsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivitySummaryQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivitySummaryQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivitySummaryQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivitySummaryQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivitySummaryQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivitySummaryQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.displayName = 'proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.displayName = 'proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.displayName = 'proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.displayName = 'proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.GetExternalActivityQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetExternalActivityQueryRequest.displayName = 'proto.wilqo.api.activity.GetExternalActivityQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExternalActivityQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExternalActivityQueryResponse.displayName = 'proto.wilqo.api.activity.ExternalActivityQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.oneofGroups_);
};
goog.inherits(proto.wilqo.api.activity.GetActivityInstanceListQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.displayName = 'proto.wilqo.api.activity.GetActivityInstanceListQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.GetActivityInstanceListQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.displayName = 'proto.wilqo.api.activity.GetActivityInstanceListQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryRequest}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityByIdQueryRequest;
  return proto.wilqo.api.activity.GetActivityByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryRequest}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityByIdQueryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityPanelSequence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityByIdQueryResponse;
  return proto.wilqo.api.activity.GetActivityByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityPanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityPanelSequence.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityPanelSequence.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityPanelSequence activity = 3;
 * @return {?proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityPanelSequence, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityPanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityByIdQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityByIdQueryResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListQueryRequest;
  return proto.wilqo.api.activity.GetActivityListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListQueryResponse;
  return proto.wilqo.api.activity.GetActivityListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivitySummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest;
  return proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string business_process_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.getBusinessProcessIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.setBusinessProcessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.addBusinessProcessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryRequest.prototype.clearBusinessProcessIdsList = function() {
  return this.setBusinessProcessIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse;
  return proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivitySummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByBusinessProcessQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByObjectQueryRequest;
  return proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByObjectQueryResponse;
  return proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivitySummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityListByObjectQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByObjectQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest;
  return proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse;
  return proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivitySummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAllOpenActivitiesByObjectQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest;
  return proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityPanelSequence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse;
  return proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.ActivityPanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityPanelSequence.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.ActivityPanelSequence.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivityPanelSequence activity = 4;
 * @return {?proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityPanelSequence, 4));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityPanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetPanelSequenceActivityQueryResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetChecklistActivityQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetChecklistActivityQueryRequest;
  return proto.wilqo.api.activity.GetChecklistActivityQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetChecklistActivityQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetChecklistActivityQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityChecklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetChecklistActivityQueryResponse;
  return proto.wilqo.api.activity.GetChecklistActivityQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.ActivityChecklist;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityChecklist.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetChecklistActivityQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.ActivityChecklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivityChecklist activity = 4;
 * @return {?proto.wilqo.api.activity.ActivityChecklist}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityChecklist, 4));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetChecklistActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetChecklistActivityQueryResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    panelSequenceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest;
  return proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPanelSequenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string panel_sequence_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.getPanelSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.setPanelSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse;
  return proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.PanelElementResponse responses = 4;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetPanelSequenceResponsesQueryResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    skillsetIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest;
  return proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string skillset_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.getSkillsetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.setSkillsetIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.addSkillsetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.clearSkillsetIdsList = function() {
  return this.setSkillsetIdsList([]);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse;
  return proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityTemplateSummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivityTemplateSummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityTemplateSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>} value
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivityTemplateSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary}
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivityTemplateSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivitiesFromSkillSetsQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTemplateListQueryRequest;
  return proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTemplateListQueryResponse;
  return proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityTemplateSummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityTemplateSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivityTemplateSummary activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityTemplateSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivityTemplateSummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivityTemplateSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary}
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivityTemplateSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityTemplateListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTemplateListQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest;
  return proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse;
  return proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivityValidationChecklist;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityValidationChecklist activity = 2;
 * @return {?proto.wilqo.api.activity.ActivityValidationChecklist}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityValidationChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityValidationChecklist, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityValidationChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetValidationChecklistActivityQueryResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest;
  return proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    onDemandActivitiesList: jspb.Message.toObjectList(msg.getOnDemandActivitiesList(),
    Wilqo_API_Activity_Models_pb.OnDemandActivity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse;
  return proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.OnDemandActivity;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.OnDemandActivity.deserializeBinaryFromReader);
      msg.addOnDemandActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getOnDemandActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.OnDemandActivity.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OnDemandActivity on_demand_activities = 2;
 * @return {!Array<!proto.wilqo.api.activity.OnDemandActivity>}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.getOnDemandActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.OnDemandActivity>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.OnDemandActivity, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.OnDemandActivity>} value
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.setOnDemandActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.OnDemandActivity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.OnDemandActivity}
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.addOnDemandActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.OnDemandActivity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetOnDemandActivitiesQueryResponse.prototype.clearOnDemandActivitiesList = function() {
  return this.setOnDemandActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityVersionsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityVersionsQueryRequest;
  return proto.wilqo.api.activity.GetActivityVersionsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityVersionsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityVersionsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityVersionsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    currentVersion: (f = msg.getCurrentVersion()) && Wilqo_API_Activity_Models_pb.ActivityVersion.toObject(includeInstance, f),
    dayGroupsList: jspb.Message.toObjectList(msg.getDayGroupsList(),
    Wilqo_API_Activity_Models_pb.ActivityVersionGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityVersionsQueryResponse;
  return proto.wilqo.api.activity.GetActivityVersionsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivityVersion;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityVersion.deserializeBinaryFromReader);
      msg.setCurrentVersion(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityVersionGroup;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityVersionGroup.deserializeBinaryFromReader);
      msg.addDayGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityVersionsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCurrentVersion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivityVersion.serializeBinaryToWriter
    );
  }
  f = message.getDayGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityVersionGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityVersion current_version = 2;
 * @return {?proto.wilqo.api.activity.ActivityVersion}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.getCurrentVersion = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityVersion} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityVersion, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityVersion|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.setCurrentVersion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.clearCurrentVersion = function() {
  return this.setCurrentVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.hasCurrentVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ActivityVersionGroup day_groups = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivityVersionGroup>}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.getDayGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivityVersionGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityVersionGroup, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivityVersionGroup>} value
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.setDayGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivityVersionGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup}
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.addDayGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivityVersionGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityVersionsQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityVersionsQueryResponse.prototype.clearDayGroupsList = function() {
  return this.setDayGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest;
  return proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dayGroupsList: jspb.Message.toObjectList(msg.getDayGroupsList(),
    Wilqo_API_Activity_Models_pb.UnpublishedActivityGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse;
  return proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.UnpublishedActivityGroup;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.UnpublishedActivityGroup.deserializeBinaryFromReader);
      msg.addDayGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDayGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.UnpublishedActivityGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UnpublishedActivityGroup day_groups = 3;
 * @return {!Array<!proto.wilqo.api.activity.UnpublishedActivityGroup>}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.getDayGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.UnpublishedActivityGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.UnpublishedActivityGroup, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.UnpublishedActivityGroup>} value
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.setDayGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.UnpublishedActivityGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup}
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.addDayGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.UnpublishedActivityGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityUnpublishedUpdatesQueryResponse.prototype.clearDayGroupsList = function() {
  return this.setDayGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest;
  return proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedSkillsetsList: jspb.Message.toObjectList(msg.getAssignedSkillsetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillsetsList: jspb.Message.toObjectList(msg.getAvailableSkillsetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse;
  return proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillsets(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillsets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillsetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.users.SkillSetSummary assigned_skillsets = 2;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.getAssignedSkillsetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.setAssignedSkillsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.addAssignedSkillsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.clearAssignedSkillsetsList = function() {
  return this.setAssignedSkillsetsList([]);
};


/**
 * repeated wilqo.api.users.SkillSetSummary available_skillsets = 3;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.getAvailableSkillsetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.setAvailableSkillsetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.addAvailableSkillsets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryResponse.prototype.clearAvailableSkillsetsList = function() {
  return this.setAvailableSkillsetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivitySummaryQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivitySummaryQueryRequest;
  return proto.wilqo.api.activity.GetActivitySummaryQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivitySummaryQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivitySummaryQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivitySummaryQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    item: (f = msg.getItem()) && Wilqo_API_Activity_Models_pb.ActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivitySummaryQueryResponse;
  return proto.wilqo.api.activity.GetActivitySummaryQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivitySummaryQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivitySummary item = 2;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.getItem = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivitySummaryQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivitySummaryQueryResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest;
  return proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse;
  return proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScopeReference items = 2;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityScopeReferencesQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest;
  return proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest} returns this
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse;
  return proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.StashPanelResponseSummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated StashPanelResponseSummary responses = 2;
 * @return {!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.StashPanelResponseSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>} value
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} returns this
*/
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.StashPanelResponseSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary}
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.StashPanelResponseSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse} returns this
 */
proto.wilqo.api.activity.GetDocumentDataCollectionQueryResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysToExpireLessOrEqual: jspb.Message.getFieldWithDefault(msg, 2, 0),
    daysSinceExpirationLessOrEqual: jspb.Message.getFieldWithDefault(msg, 3, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest;
  return proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDaysToExpireLessOrEqual(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDaysSinceExpirationLessOrEqual(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysToExpireLessOrEqual();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDaysSinceExpirationLessOrEqual();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 days_to_expire_less_or_equal = 2;
 * @return {number}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.getDaysToExpireLessOrEqual = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.setDaysToExpireLessOrEqual = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 days_since_expiration_less_or_equal = 3;
 * @return {number}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.getDaysSinceExpirationLessOrEqual = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.setDaysSinceExpirationLessOrEqual = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string entity_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse;
  return proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ActivitySummary activities = 2;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityListByExpirationQueryResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_API_Activity_Models_pb.AsyncValidationPanelElementWrapper.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest;
  return proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.activity.AsyncValidationTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.AsyncValidationPanelElementWrapper;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.AsyncValidationPanelElementWrapper.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.AsyncValidationPanelElementWrapper.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional AsyncValidationTypeEnum type = 1;
 * @return {!proto.wilqo.api.activity.AsyncValidationTypeEnum}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.activity.AsyncValidationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidationTypeEnum} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated AsyncValidationPanelElementWrapper responses = 2;
 * @return {!Array<!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper>}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.AsyncValidationPanelElementWrapper, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper>} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} returns this
*/
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.AsyncValidationPanelElementWrapper, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    result: (f = msg.getResult()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse;
  return proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Any result = 2;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.getResult = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 2));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} returns this
*/
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.setResult = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 3;
 * @return {boolean}
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse} returns this
 */
proto.wilqo.api.activity.GetAsyncValidationResultQueryResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest;
  return proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    triggertypesList: jspb.Message.toObjectList(msg.getTriggertypesList(),
    Wilqo_API_Activity_Models_pb.TriggerType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse;
  return proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.TriggerType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.TriggerType.deserializeBinaryFromReader);
      msg.addTriggertypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTriggertypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.TriggerType.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TriggerType triggerTypes = 2;
 * @return {!Array<!proto.wilqo.api.activity.TriggerType>}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.getTriggertypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.TriggerType>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.TriggerType, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.TriggerType>} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.setTriggertypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.TriggerType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.TriggerType}
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.addTriggertypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.TriggerType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerTypesQueryResponse.prototype.clearTriggertypesList = function() {
  return this.setTriggertypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest;
  return proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.rulesengine.RuleTypeEnum} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleTypeEnum field_type = 3;
 * @return {!proto.wilqo.shared.rulesengine.RuleTypeEnum}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.getFieldType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.RuleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleTypeEnum} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryRequest.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse;
  return proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.DataFieldGroupSummary groups = 2;
 * @return {!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldGroupSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataFieldGroupSummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DataFieldGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DataFieldGroupSummary}
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DataFieldGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityTriggerFieldListQueryResponse.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetExternalActivityQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetExternalActivityQueryRequest}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetExternalActivityQueryRequest;
  return proto.wilqo.api.activity.GetExternalActivityQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetExternalActivityQueryRequest}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetExternalActivityQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetExternalActivityQueryRequest} returns this
 */
proto.wilqo.api.activity.GetExternalActivityQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExternalActivityQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExternalActivityQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    item: (f = msg.getItem()) && Wilqo_API_Activity_Models_pb.ExternalActivity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExternalActivityQueryResponse;
  return proto.wilqo.api.activity.ExternalActivityQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExternalActivityQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ExternalActivity;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ExternalActivity.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExternalActivityQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExternalActivityQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ExternalActivity.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExternalActivity item = 2;
 * @return {?proto.wilqo.api.activity.ExternalActivity}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.getItem = function() {
  return /** @type{?proto.wilqo.api.activity.ExternalActivity} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ExternalActivity, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ExternalActivity|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse} returns this
*/
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivityQueryResponse} returns this
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivityQueryResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.QuerySettingsCase = {
  QUERY_SETTINGS_NOT_SET: 0,
  SKILL_SET: 2,
  APPLIES_TO: 3
};

/**
 * @return {proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.QuerySettingsCase}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.getQuerySettingsCase = function() {
  return /** @type {proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.QuerySettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillSet: (f = msg.getSkillSet()) && Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.toObject(includeInstance, f),
    appliesTo: (f = msg.getAppliesTo()) && Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.toObject(includeInstance, f),
    paging: (f = msg.getPaging()) && Wilqo_API_Activity_Models_pb.PagingArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityInstanceListQueryRequest;
  return proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.deserializeBinaryFromReader);
      msg.setSkillSet(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.deserializeBinaryFromReader);
      msg.setAppliesTo(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.PagingArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PagingArgs.deserializeBinaryFromReader);
      msg.setPaging(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.serializeBinaryToWriter
    );
  }
  f = message.getAppliesTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.serializeBinaryToWriter
    );
  }
  f = message.getPaging();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.PagingArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivitiesBySkillSetArgs skill_set = 2;
 * @return {?proto.wilqo.api.activity.ActivitiesBySkillSetArgs}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.getSkillSet = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitiesBySkillSetArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitiesBySkillSetArgs|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.setSkillSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.clearSkillSet = function() {
  return this.setSkillSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.hasSkillSet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivitiesByEntityArgs applies_to = 3;
 * @return {?proto.wilqo.api.activity.ActivitiesByEntityArgs}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.getAppliesTo = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitiesByEntityArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitiesByEntityArgs|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.setAppliesTo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.clearAppliesTo = function() {
  return this.setAppliesTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.hasAppliesTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PagingArgs paging = 4;
 * @return {?proto.wilqo.api.activity.PagingArgs}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.getPaging = function() {
  return /** @type{?proto.wilqo.api.activity.PagingArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PagingArgs, 4));
};


/**
 * @param {?proto.wilqo.api.activity.PagingArgs|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.setPaging = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryRequest} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.clearPaging = function() {
  return this.setPaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryRequest.prototype.hasPaging = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    pagingInfo: (f = msg.getPagingInfo()) && Wilqo_API_Activity_Models_pb.PagingInfo.toObject(includeInstance, f),
    currentActivitiesList: jspb.Message.toObjectList(msg.getCurrentActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.GetActivityInstanceListQueryResponse;
  return proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.PagingInfo;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PagingInfo.deserializeBinaryFromReader);
      msg.setPagingInfo(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addCurrentActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPagingInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.PagingInfo.serializeBinaryToWriter
    );
  }
  f = message.getCurrentActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PagingInfo paging_info = 2;
 * @return {?proto.wilqo.api.activity.PagingInfo}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.getPagingInfo = function() {
  return /** @type{?proto.wilqo.api.activity.PagingInfo} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PagingInfo, 2));
};


/**
 * @param {?proto.wilqo.api.activity.PagingInfo|undefined} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.setPagingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.clearPagingInfo = function() {
  return this.setPagingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.hasPagingInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ActivitySummary current_activities = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.getCurrentActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
*/
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.setCurrentActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.addCurrentActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.GetActivityInstanceListQueryResponse} returns this
 */
proto.wilqo.api.activity.GetActivityInstanceListQueryResponse.prototype.clearCurrentActivitiesList = function() {
  return this.setCurrentActivitiesList([]);
};


goog.object.extend(exports, proto.wilqo.api.activity);
