import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

export const usePropsState = <T = any>(propValue?: T, initialValue?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const [stateValue, setStateValue] = useState<T | undefined>(propValue || initialValue);

  useEffect(() => {
    if (propValue !== undefined) setStateValue(propValue);
  }, [propValue]);

  return [stateValue, setStateValue];
};
