import { useMemo, useState } from 'react';

import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { LicenseStatusEnum } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import type { CardListItemSubmitHandler } from '@/Components/Atoms/Card/CardListItem';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Dialog } from '@/Components/Atoms/RadixDialog';
import states from '@/Components/Atoms/StateSelect/states';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';

import type { Column } from '../table';
import { Table } from '../table';
import { LicenseForm } from './LicenseForm';

interface LicensesTableProps {
  title: string;
  licenseNumber: string;
  licenses: Array<License.AsObject>;
  handleSubmit: (formData: any, selectedLicense?: License.AsObject) => void;
  handleSubmitNMLS?: CardListItemSubmitHandler;
  isLoading: boolean;
}

const getStatusName = (status: LicenseStatusEnum) => {
  let name = '';
  switch (status) {
    case LicenseStatusEnum.LICENSE_STATUS_ENUM_APPROVED:
      name = 'Approved';
      break;
    case LicenseStatusEnum.LICENSE_STATUS_ENUM_PENDING:
      name = 'Pending';
      break;
    case LicenseStatusEnum.LICENSE_STATUS_ENUM_TEMPORARY:
      name = 'Temporary';
      break;
    case LicenseStatusEnum.LICENSE_STATUS_ENUM_UNKNOWN:
      name = 'Unknown';
      break;
    default:
      name = '';
      break;
  }
  return name;
};

const LicensesTable = (props: LicensesTableProps) => {
  const { handleSubmit, handleSubmitNMLS, isLoading, licenseNumber, licenses, title } = props;

  const [selectedLicense, setSelectedLicense] = useState<License.AsObject | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const handleAddLicenseClick = () => {
    setModalOpen(true);
  };

  const handleEditLicenseClick = (license: License.AsObject) => {
    setModalOpen(true);
    setSelectedLicense(license);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedLicense(undefined);
  };

  const columns = useMemo((): Array<Column<License.AsObject>> => [
    {
      header: 'Regulator',
      id: 'regulator',
      key: {
        text: (data) => states.find((state) => state.abbreviation.toUpperCase() === data.licenseScope.toUpperCase())?.name || '',
        type: 'text',
      },
    },
    {
      header: 'License Enabled',
      id: 'enabled',
      key: {
        text: (data) => (data.enabled ? 'Yes' : 'No'),
        type: 'text',
      },
    },
    {
      header: 'License Name / Identifier',
      id: 'name',
      key: {
        caption: (data) => `Lic# ${data.licenseId}`,
        text: 'licenseLegalName',
        type: 'text',
      },
    },
    {
      header: 'Status',
      id: 'status',
      key: {
        text: (data) => getStatusName(data.licenseStatusType),
        type: 'text',
      },
    },
    {
      header: 'Start Date',
      id: 'startDate',
      key: {
        text: (data) => getDateFromTimestamp(data.startDate)?.toDateString() || '',
        type: 'text',
      },
    },
    {
      header: 'Expiration Date',
      id: 'expirationDate',
      key: {
        text: (data) => getDateFromTimestamp(data.expirationDate)?.toDateString() || '',
        type: 'text',
      },
    },
  ], []);

  const handleLicenseSubmit = (formData: any) => {
    handleCloseModal();
    handleSubmit(formData, selectedLicense);
  };

  return (
    <div>
      <CardListItem
        headerProps={{ title: `${title} NMLS Information` }}
        isEditable={Boolean(handleSubmitNMLS)}
        isLoading={isLoading}
        listItems={[{
          caption: licenseNumber,
          disableInteractions: true,
          label: `${title} License`,
          panelElement: getPanelElement({
            headerText: `${title} License`,
            id: 'license',
            requirement: getPanelElementRequiment('Required'),
            type: 'text',
          }),
          value: { value: licenseNumber },
        }]}
        onSubmit={handleSubmitNMLS}
      />
      {licenses.length > 0 ? (
        <Table
          cardProps={{
            headerProps: {
              actions: [{ label: 'Add license', onClick: handleAddLicenseClick }],
              title: `${title} licenses`,
            },
          }}
          columns={columns}
          data={licenses}
          hoverActions={[{ label: 'Edit', onClick: handleEditLicenseClick }]}
          isLoading={isLoading}
          width="stretch"
        />
      ) : (
        <ContingencyMessage
          action={{ label: 'Add new', onClick: handleAddLicenseClick, variant: 'tertiary' }}
          icon="NoItems"
          subtitle=""
          title="No Licenses found"
          variant="image"
        />
      )}
      <Dialog
        onOpenChange={handleCloseModal}
        open={modalOpen}
      >
        <LicenseForm
          closeModal={handleCloseModal}
          handleSubmit={handleLicenseSubmit}
          selectedLicense={selectedLicense}
        />
      </Dialog>
    </div>
  );
};

export { LicensesTable };
