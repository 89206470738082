import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { User } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useSkillsetGroup } from '@/API/Queries/user/useSkillsetGroup';
import { useUpdateSkillsetGroup } from '@/API/Queries/user/useUpdateSkillsetGroup';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useLookup } from '@/Utils/Hooks/useLookup/useLookup';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const SkillsetGroupUsers = () => {
  const { id = '' } = useParams();

  const { data: skillsetGroup, refetch } = useSkillsetGroup(id);
  const { mutate: updateSkillsetGroup } = useUpdateSkillsetGroup();

  const { showSnackBar } = useShared();
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState<User.AsObject[]>([]);

  const { exists: isAssigned } = useLookup(skillsetGroup?.usersIdsList);

  const handleSubmit = useCallback((assignedUsers: string[]) => {
    updateSkillsetGroup({
      displayName: skillsetGroup?.displayName || '',
      longDescription: skillsetGroup?.longDescription || '',
      skillsetGroupId: id,
      skillsetIds: skillsetGroup?.skillSetsIdsList || [],
      userIds: assignedUsers,
    }, { onError: () => showSnackBar({ message: 'Something went wrong.', open: true }), onSuccess: () => refetch() });
  }, [updateSkillsetGroup, skillsetGroup, id, showSnackBar, refetch]);

  const handleClickItem = useCallback((user: User.AsObject) => {
    let assignedUsers = skillsetGroup?.usersIdsList || [];
    const isAssigned = user.skillsetGroupList.some((userSkillsetGroup) => userSkillsetGroup.id === id);
    if (isAssigned) {
      assignedUsers = assignedUsers?.filter((userId) => userId !== user.id);
    } else {
      assignedUsers?.push(user.id);
    }
    handleSubmit(assignedUsers);
  }, [skillsetGroup, handleSubmit, id]);

  const handleAssignBulk = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    const assignedUsers = skillsetGroup?.usersIdsList.concat(selectedIds) || [];
    handleSubmit(assignedUsers);
  };

  const handleUnassignBulk = () => {
    const assignedUsers = skillsetGroup?.usersIdsList.filter((id) => !selectedItems
      .some((selectedItem) => selectedItem.id === id)) || [];
    handleSubmit(assignedUsers);
  };

  const renderBadge = useCallback((user: User.AsObject) => {
    if (isAssigned(user.id)) {
      return <Badge label="Assigned" variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE} />;
    }
    return null;
  }, [isAssigned]);

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'displayName'],
    ['Assignment', { type: 'any', value: renderBadge }],
  ], [renderBadge]);

  return (
    <TableBuilder
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Assign', onClick: handleAssignBulk },
              { label: 'Unassign', onClick: handleUnassignBulk },
            ],
            caption: `${selectedItems.length} selected user(s)`,
          },
          searchProps: { onChange: setSearch, value: search },
          showContextual: selectedItems.length > 0,
          title: 'All Users',
        },
      }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.USERS}
      hoverActions={[
        { label: (item) => (isAssigned(item.id) ? 'Unassign' : 'Assign'), onClick: handleClickItem },
      ]}
      orderBy="Assignment"
      setRowSelection={setSelectedItems}
    />
  );
};

export { SkillsetGroupUsers };
