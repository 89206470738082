import clsx from 'clsx';
import type { HTMLAttributes } from 'react';

import { cn } from '@/Utils/Helpers/uiBuilders';

import type { IButtonProps } from './Button';
import { Button } from './Button';
import { Icon } from './Icon';
import { Typography } from './Typography';

export type ContingencyMessageVariant = 'icon' | 'image';
export type IconVariant = 'fill' | 'outline';

export interface ContingencyMessageProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle: string[] | string;
  icon?: string;
  action?: IButtonProps;
  variant?: ContingencyMessageVariant;
  success?: boolean;
  iconVariant?: IconVariant;
}

const ContingencyMessage = (props: ContingencyMessageProps) => {
  const {
    action,
    className,
    icon,
    iconVariant,
    subtitle,
    success = false,
    title,
    variant = 'icon',
    ...rest
  } = props;

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return <Typography className="text-center" variant="body2">{subtitle}</Typography>;
    }
    return (
      <div className="flex flex-col">
        {(subtitle.map((sub) => (
          <Typography key={sub} className="text-center text-balance" variant="body2">{sub}</Typography>
        )))}
      </div>
    );
  };

  return (
    <div
      {...rest}
      className={cn('mx-auto flex flex-col items-center p-6 gap-4 max-w-[288px] w-full', className)}
    >
      {icon && (
        <Icon
          className={clsx({
            'border border-on-surface-stroke': iconVariant === 'outline',
            'text-primary-on-surface': success,
          })}
          icon={icon}
          size={variant === 'icon' ? 'lg' : 'xl'}
          variant={variant === 'icon' && iconVariant === 'fill' ? 'fixed-background' : 'default'}
        />
      )}
      { title && <Typography className="text-center" variant="display6">{title}</Typography> }
      {renderSubtitle()}
      { action && <Button {...action} />}
    </div>
  );
};

export { ContingencyMessage };
