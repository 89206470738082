import { useMemo } from 'react';

import { useActivityPanelSequence } from '@/API/Queries/activities/useActivityPanelSequece';
import { useUpdateActivityPanelInstanceDetails } from '@/API/Queries/activities/useUpdateActivityPanelInstanceDetails';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues, FormProgress } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';
import { getPanel, getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface ActivityTemplateCreateProps {
  closeModal: () => void;
  entityId: string;
  entityVersion: number;
}

const EditLoanActivitiesModal = (props: ActivityTemplateCreateProps) => {
  const { closeModal, entityId, entityVersion } = props;
  const { updateDesignerPanel } = useActivityConfiguratorContext();
  const { isLoading: saving, mutate: updateActivityPanelInstanceDetails } = useUpdateActivityPanelInstanceDetails();
  const { data: activityPanelSequence, isLoading } = useActivityPanelSequence(entityId);
  const panelSequence = activityPanelSequence?.panelSequence;
  const firstPanel = panelSequence?.panelsList[0];
  const { showSnackBar } = useShared();

  const handleUpdateSuccess = (updateObj: any) => {
    closeModal();
    showSnackBar({ message: 'Activity updated successfully', open: true });
    updateDesignerPanel(updateObj);
  };

  const handleError = (error: any) => {
    showSnackBar({
      message: error.message || 'Something went wrong, try again later',
      open: true,
    });
  };

  const handleSubmit = (form: DynamicDataElementValues) => {
    const {
      activityTitle: { value: activityTitle },
      panelHeaderText: { value: panelHeaderText },
      summaryText: { value: panelSummaryText },
    } = form;

    if (firstPanel) {
      firstPanel.summaryText = panelSummaryText;
      firstPanel.headerText = panelHeaderText;
    }

    const updateObj = {
      activityInternalName: panelHeaderText,
      activityTitle,
      entityId,
      entityVersion,
      panelHeaderText,
      panelId: firstPanel ? firstPanel.id : '',
      panelSequenceId: panelSequence ? panelSequence.entityId : '',
      panelSummaryText,
    };

    updateActivityPanelInstanceDetails(
      updateObj,
      {
        onError: handleError,
        onSuccess: () => handleUpdateSuccess(updateObj),
      },
    );
  };

  const progress = useMemo((): FormProgress => {
    if (!panelSequence?.panelsList[0] && !isLoading) {
      return { values: { activityTitle: { value: activityPanelSequence?.title } } };
    }
    return {
      values: {
        activityTitle: { value: activityPanelSequence?.title },
        panelHeaderText: { value: activityPanelSequence?.panelSequence?.panelsList[0]?.headerText },
        summaryText: { value: activityPanelSequence?.panelSequence?.panelsList[0]?.summaryText },
      },
    };
  }, [activityPanelSequence, panelSequence, isLoading]);

  return (
    <FullPageStepper
      close={closeModal}
      handleSubmit={handleSubmit}
      headerAction={{ label: 'Discard', leftIcon: 'Delete', onClick: closeModal, variant: 'tertiary' }}
      openModal={!isLoading}
      panels={[
        getPanel({
          headerText: 'Edit Activity',
          id: 'activity-name',
          panelElementsList: [
            getPanelElement({
              headerText: 'External name',
              id: 'activityTitle',
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Title',
              id: 'panelHeaderText',
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Description',
              id: 'summaryText',
              type: 'text',
            }),
          ],
        }),
      ]}
      progress={progress}
      saving={saving}
      title={`Update ${activityPanelSequence?.title}`}
    />
  );
};

export { EditLoanActivitiesModal };
