import { useCallback, useState } from 'react';
import { useParams } from 'react-router';

import { useInitializeSandbox } from '@/API/Queries/mortgage/useInitializeSandbox';
import { Button } from '@/Components/Atoms/Button';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Loader } from '@/Components/Atoms/Loader';
import { Tabs } from '@/Components/Atoms/Tab';
import { Typography } from '@/Components/Atoms/Typography';
import { IntegrationHistory } from '@/Routes/Pages/loan/IntegrationHistory';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import ManageFeesPages from '@/Routes/Pages/loan/PurposeBuilt/Fees/ManageFeesPages';
import AdjustmentsCreditsDetails from '@/Routes/Pages/loan/PurposeBuilt/Fees/ViewFees/AdjustmentsCreditsDetails';
import ComparisonDetails from '@/Routes/Pages/loan/PurposeBuilt/Fees/ViewFees/ComparisonDetails';
import EscrowPrepaidDetails from '@/Routes/Pages/loan/PurposeBuilt/Fees/ViewFees/EscrowPrepaidDetails';
import FeeDetails from '@/Routes/Pages/loan/PurposeBuilt/Fees/ViewFees/FeeDetails';
import OriginationDiscountDetails from '@/Routes/Pages/loan/PurposeBuilt/Fees/ViewFees/OriginationDiscountDetails';
import RunFeesModal from '@/Routes/Pages/loan/PurposeBuilt/Fees/Widgets/RunFeesModal';
import { ExtendedStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import PermissionCheck from './Widgets/PermissionCheck';

const FeesPage = () => {
  const [openManageFeesModal, setOpenManageFeesModal] = useState(false);
  const [openRunFeesModal, setOpenRunFeesModal] = useState(false);
  const { isMobile } = useWindow();
  const { loanId = '' } = useParams();

  const [selectedTab, setSelectedTab] = useState<
  'adjustmentsCredits' |
  'comparison' |
  'integration' |
  'loanFees' |
  'originationDiscount' |
  'prepaidsAndEscrows'>('loanFees');
  const { disabledColumnsList, feesHeader, feesLoading, headerLoading, reloadFees } = useFeesContext();
  const { mutate: initializeSandbox } = useInitializeSandbox();
  const { showSnackBar } = useShared();

  const disabledColumnMapping = useCallback((inputString: string): boolean => {
    const mappedString = `${inputString.toLocaleLowerCase()}paid`;
    if (disabledColumnsList) {
      return disabledColumnsList.includes(mappedString);
    }
    return false;
  }, [disabledColumnsList]);

  const handleManageFeesClick = () => {
    const errorMessage = 'Something went wrong';
    initializeSandbox({
      dealId: loanId,
      loanId: '',
    },
    {
      onError() {
        showSnackBar({ message: errorMessage, open: true });
      },
      onSuccess() {
        setOpenManageFeesModal(true);
      },
    });
  };

  const handleClose = () => {
    setOpenManageFeesModal(false);
    reloadFees();
  };

  if (feesLoading || headerLoading) return <Loader />;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between p-4 tablet:p-8 desktop:pt-12 desktop:pb-0 desktop:px-6">
        <Typography variant="display4">
          Fee details
        </Typography>
        <div className="flex gap-x-4 items-center justify-center">
          <PermissionCheck permissionKey="runFees">
            <Button label="run fees" onClick={() => setOpenRunFeesModal(true)} variant="tertiary" />
          </PermissionCheck>
          <PermissionCheck permissionKey="manageFees">
            <Button label="manage fees" onClick={() => handleManageFeesClick()} variant="primary" />
          </PermissionCheck>
        </div>
      </div>
      {
        (feesHeader && feesHeader.length > 0) && (
          <div className="flex flex-nowrap justify-start gap-6 overflow-x-auto p-4 tablet:p-8 desktop:p-6 desktop:py-4">
            {feesHeader.map((card) => (
              <InfoCard
                key={card.id}
                caption={card.caption}
                isMobile={isMobile}
                items={card.verifiedListList}
                type={card.type}
                value={card.value}
                variant={ExtendedStatusEnum.RESTING}
              />
            ))}
          </div>
        )
      }

      <Tabs
        stretch
        tabsData={[
          { active: selectedTab === 'loanFees', label: 'Loan Fees', onClick: () => setSelectedTab('loanFees') },
          { active: selectedTab === 'prepaidsAndEscrows', label: 'Prepaids & Escrows', onClick: () => setSelectedTab('prepaidsAndEscrows') },
          { active: selectedTab === 'adjustmentsCredits', label: 'Adjustments/Credits', onClick: () => setSelectedTab('adjustmentsCredits') },
          { active: selectedTab === 'originationDiscount', label: 'Origination/Discount', onClick: () => setSelectedTab('originationDiscount') },
          { active: selectedTab === 'comparison', label: 'compare fees', onClick: () => setSelectedTab('comparison') },
          { active: selectedTab === 'integration', label: 'Integrations', onClick: () => setSelectedTab('integration') },
        ]}
      />

      <div className="p-4 tablet:p-8 desktop:p-6 desktop:py-4">
        {selectedTab === 'loanFees' && <FeeDetails />}
        {selectedTab === 'prepaidsAndEscrows' && <EscrowPrepaidDetails />}
        {selectedTab === 'adjustmentsCredits' && <AdjustmentsCreditsDetails />}
        {selectedTab === 'originationDiscount' && <OriginationDiscountDetails />}
        {selectedTab === 'comparison' && <ComparisonDetails />}
        {selectedTab === 'integration' && <IntegrationHistory hideBreadcrumbs sideSheetName="fees" />}
      </div>

      <RunFeesModal onClose={() => setOpenRunFeesModal(false)} open={openRunFeesModal} />
      <ManageFeesPages isChipHidden={disabledColumnMapping} onClose={handleClose} open={openManageFeesModal} />
    </div>
  );
};

export { FeesPage };
