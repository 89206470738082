import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { useGetFile } from '@/API/Queries/other/useGetFile';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { Icon } from './Icon';
import { Skeleton } from './Skeleton';
import { Typography } from './Typography';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface IDocumentViewerProps {
  file: string;
  extension?: string;
  name?: string;
  hideDownload?: boolean;
}

const PdfViewer = (props: IDocumentViewerProps) => {
  const { file, hideDownload, name = 'download' } = props;

  const [url, setUrl] = useState<string>();
  const [numPages, setNumPages] = useState<number>();
  const [extension, setExtension] = useState('');
  const [text, setText] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);
  const { isDesktop, isMobile } = useWindow();
  const documentWidth = (containerRef.current?.offsetWidth || 1000) - (isMobile ? 32 : 200);

  const { mutate: getDocument } = useGetFile();

  useEffect(() => {
    if (url) {
      fetch(url).then((response) => {
        const contentType = response.headers.get('content-type');
        setExtension(contentType || '');
        if (contentType === 'text/plain') {
          return response.text();
        }
        return '';
      }).then((text) => {
        setText(text || '');
      });
    }
  }, [url]);

  useEffect(() => {
    getDocument(file, {
      onError: () => {
        setUrl(undefined);
      },
      onSuccess: (uri) => {
        setUrl(uri);
      },
    });
  }, [getDocument, file]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const renderSkeleton = () => <Skeleton height="100%" variant="rect" width="100%" />;

  const renderPDF = () => (
    <Document
      className="w-full min-h-full justify-center flex flex-col gap-4"
      error={<p className="text-white">Failed to load PDF file.</p>}
      file={url}
      onLoadProgress={renderSkeleton}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page
          key={`page_${index + 1}`}
          className="mx-auto flex justify-center"
          pageNumber={index + 1}
          width={documentWidth}
        />
      ))}
    </Document>
  );

  const renderImage = () => <img alt="" className="mx-auto" height="auto" src={url} width={documentWidth} />;

  if (!url) return renderSkeleton();

  const handleDownloadClick = async () => {
    const data = await fetch(url);
    const blob = await data.blob();
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.setAttribute('href', objectUrl);
    link.setAttribute('download', name);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const renderText = () => (
    <Typography className="p-6" variant="body1">{text}</Typography>
  );

  const renderFile = () => {
    if (extension === 'application/pdf') return renderPDF();
    if (extension.includes('image')) return renderImage();
    if (extension === 'text/plain') return renderText();
    return null;
  };

  return (
    <div
      ref={containerRef}
      className={clsx('w-full flex justify-center flex-col relative', {
        'bg-on-unknown-white-states-enabled': extension !== 'text/plain',
      })}
    >
      {(isDesktop && !hideDownload) && (
        <div className="px-6 p-2 bg-on-unknown-white-states-activated sticky flex-row justify-end top-0 z-10 w-full flex">
          <Icon
            className="text-on-unknown-black-states-activated"
            icon="DownloadIcon"
            onClick={handleDownloadClick}
          />
        </div>
      )}
      {renderFile()}
    </div>
  );
};

export { PdfViewer };
