import { animated, config, useSpring } from 'react-spring';

import { Button } from './Button';

export interface SnackbarProps {
  message: string;
  onClick?: () => void;
  buttonLabel?: string;
}

const SnackBar = (props: SnackbarProps) => {
  const { buttonLabel = 'Click Here', message, onClick } = props;
  const defaultFinalHeight = -80;

  const snackBarAnimation = useSpring({
    config: config.molasses,
    delay: 100,
    from: { opacity: 0, transform: 'translateY(60px)' },
    reset: true,
    to: [
      { opacity: 1, transform: `translateY( ${defaultFinalHeight}px)` },
      { opacity: 0, transform: 'translateY(60px)' },
    ],
  });

  return (
    <animated.div
      className="items-center bg-on-surface-active rounded bottom-0 shadow-six flex justify-between left-0 mx-auto max-w-[calc(100vw-32px)]
      desktop:max-w-[400px] min-h-12 min-w-[300px] py-0 px-4 fixed right-0 z-[99] tablet:max-w-[300px]"
      style={snackBarAnimation}
    >
      <span className=" text-surface block max-w-[200px] py-4 px-0 text-sm">{message}</span>
      {onClick !== undefined && (
        <Button
          label={buttonLabel}
          onClick={onClick}
          variant="tertiary"
        />
      )}
    </animated.div>
  );
};

export { SnackBar };
