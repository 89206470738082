import { AnimatedContainer } from '@/Components/Atoms/AnimatedContainer';
import { Button } from '@/Components/Atoms/Button';
import { Typography } from '@/Components/Atoms/Typography';
import { useNavigate } from '@/Routes/NavigationContext';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <AnimatedContainer>
      <div className="grid sm:grid-cols-2">
        <div>
          <div className="flex items-center justify-center my-0 mx-[10%] h-screen flex-col">
            <Typography variant="display1">404</Typography>
            <Typography className="text-on-surface-inactive my-3 text-center" variant="body1">
              Sorry, We couldn&apos;t find that page,
              please check your URL or return to the Home Page.
            </Typography>
            <Button
              heightType="tall"
              label="Go to home page"
              onClick={() => navigate('/')}
              rightIcon="NavigateNext"
              variant="secondary"
            />
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="flex items-center justify-center bg-primary-variant2 h-screen w-full">
            <div
              className="bg-center bg-no-repeat bg-contain h-full w-[80%]"
              style={{ backgroundImage: 'url(/mortgage/api/configuration/logo)' }}
            />
          </div>
        </div>
      </div>
    </AnimatedContainer>
  );
};

export { NotFound };
