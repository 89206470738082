import { useCreateSkillset } from '@/API/Queries/user/useCreateSkillset';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanel, getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface CreateSkillsetProps {
  closeModal: () => void;
}

const CreateSkillsetComponent = (props: CreateSkillsetProps) => {
  const { closeModal } = props;

  const { showSnackBar } = useShared();
  const { isLoading, mutate: createSkillset } = useCreateSkillset();

  const handleSubmitSuccess = () => {
    showSnackBar({ message: 'Skillset created', open: true });
    closeModal();
  };

  const onSubmit = (data: DynamicDataElementValues) => {
    const {
      description: { value: description },
      skillsetName: { value: skillsetName },
    } = data;
    const skillset = {
      displayName: skillsetName,
      longDescription: description,
      skillIds: [],
      skillsetGroupIds: [],
    };
    createSkillset(skillset, { onSuccess: handleSubmitSuccess });
  };

  return (
    <FullPageStepper
      close={closeModal}
      handleSubmit={onSubmit}
      headerAction={{
        label: 'Discard',
        onClick: closeModal,
        rightIcon: 'Delete',
        variant: 'tertiary',
      }}
      openModal
      panels={[
        getPanel({
          headerText: 'Name & Description',
          id: 'name-description',
          panelElementsList: [
            getPanelElement({
              headerText: 'Skillset Name',
              id: 'skillsetName',
              requirement: getPanelElementRequiment('Skillset name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Description',
              id: 'description',
              requirement: getPanelElementRequiment('Description is required'),
              type: 'text',
            }),
          ],
          summaryText: 'Enter a name and description of the skillset',
        }),
      ]}
      saving={isLoading}
      title="Create new Skillset"
    />
  );
};

const CreateSkillset = CreateSkillsetComponent;
export { CreateSkillset };
