import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { convertRichText } from '@/Utils/Helpers/richTextConversion';

import { ChecklistItemForm } from './checklist/checklistForm';
import { DynamicChecklistForm } from './validation/validationForm';

interface IncompleteListProps {
  items: Array<any>;
  isLoading: boolean;
  checklistProgress: Array<any>;
  type: 'checklist' | 'validation';
}

const IncompleteList = (props: IncompleteListProps) => {
  const { checklistProgress, isLoading, items, type } = props;

  const incompleteChecklist = items
    ?.filter((checklistItem) => !checklistProgress
      .some((progress) => progress.checklistItem?.panel?.id === checklistItem?.panel?.id));

  const renderList = () => {
    if (isLoading) {
      return (
        <Skeleton height="12px" rows={4} variant="rect" width="" />
      );
    }

    const renderHeader = (open: boolean, item: any, index: number) => {
      const fallbackTitle = `Item ${index + 1}`;
      if (!open) {
        return (
          <div
            className={clsx(
              'flex flex-col gap-y-[2px] px-4 py-2 text-left',
              {
                'px-4 pt-2 pb-2': open,
              },
            )}
          >
            <span className="text-xs leading-4">
              {item.title || fallbackTitle}
            </span>
            <span className="text-xs leading-4 text-on-surface-inactive">
              {convertRichText(item.panel?.headerText)}
            </span>
          </div>
        );
      }
      return (
        <div
          className={clsx(
            'flex flex-col gap-y-[2px] px-4 py-2 text-left',
            {
              'px-4 pt-2 pb-2': open,
            },
          )}
        >
          <span className="text-xs leading-4 text-on-surface-inactive">
            {convertRichText(item.panel?.headerText || fallbackTitle)}
          </span>
          <span className="text-on-surface-active">{convertRichText(item?.panel?.summaryText)}</span>
        </div>
      );
    };

    const renderForm = (checklistItem: any) => {
      if (type === 'checklist') {
        return (
          <ChecklistItemForm
            checklistItem={checklistItem}
            panelElements={checklistItem.panel.panelElementsList}
          />
        );
      }
      return (
        <DynamicChecklistForm
          checklistItem={checklistItem}
          panelElements={checklistItem.panel.panelElementsList}
        />
      );
    };

    if (incompleteChecklist && incompleteChecklist.length > 0) {
      return (
        <>
          {
            incompleteChecklist.map((checklistItem, index) => (
              <Disclosure key={uuid()} as="div">
                {({ open }) => (
                  <div className={
                    clsx('py-2', {
                      'bg-surface-background': !open,
                      'bg-surface-variant border-on-surface-stroke border-b': open,
                    })
                  }
                  >
                    <Disclosure.Button className={
                    clsx('flex items-center justify-between cursor-pointer w-full px-2', {
                      'border-b-on-surface-stroke': !open,
                    })
                  }
                    >
                      { renderHeader(open, checklistItem, index)}
                      <Icon icon={open ? 'ExpandLess' : 'ExpandMore'} />
                    </Disclosure.Button>
                    <Disclosure.Panel className="py-0 px-4 w-full [&_button]:m-0">
                      {checklistItem.panel?.panelElementsList && renderForm(checklistItem)}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))
          }
        </>
      );
    }
    return (
      <ContingencyMessage subtitle="There's no incomplete items" title="No results" />
    );
  };

  return (
    <div
      className={clsx(
        'overflow-x-hidden overflow-y-auto h-[calc(100vh-120px)]',
        {
          'p-3': isLoading || incompleteChecklist?.length === 0,
        },
      )}
    >
      <div>
        {renderList()}
      </div>
    </div>
  );
};

export { IncompleteList };
