import { useNavigate } from 'react-router';

import { Icon } from './Icon';
import { Typography } from './Typography';

export interface NavigationRailProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface NavigationRailItemProps {
  icon: string;
  label: string;
  route: string;
}

export const NavigationRailItem = (props: NavigationRailItemProps) => {
  const navigate = useNavigate();
  return (
    <button className="flex flex-col" onClick={() => navigate(props.route)} type="button">
      <Icon icon={props.icon} size="sm" />
      <Typography variant="body2">{props.label}</Typography>
    </button>
  );
};

export const NavigationRail = ({ children, className, ...rest }: NavigationRailProps) => (
  <div
    className="h-full flex flex-col gap-4 w-fit bg-surface-variant p-3 pt-12 border-r-[1px] border-r-on-surface-stroke"
    {...rest}
  >
    {children}
  </div>
);
