import clsx from 'clsx';

import { useCurrentUser } from '@/API/Queries/user/useCurrentUser';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { Avatar } from './Avatar';
import { Icon } from './Icon';
import { Logo } from './Logo';
import { Menu } from './Menu';

interface IAppBarProps {
  className?: string;
  hideMenu?: boolean;
  onClickLogo?: VoidFunction;
}

function coalesce(str: string | null | undefined) {
  if (str === undefined || str === null || str.match(/^\s*$/) !== null) {
    return '';
  }
  return str;
}

const AppBar = (props: IAppBarProps) => {
  const { className = '', hideMenu = false, onClickLogo } = props;
  const { toggleDrawer } = useShared();
  const { logout, secureAuthValues } = useAuth();
  const { data: user } = useCurrentUser();

  const username = coalesce(user?.displayName)
      || coalesce(secureAuthValues.displayName)
      || coalesce(localStorage.getItem('displayName'));

  const handleDrawerToggle = () => {
    toggleDrawer();
  };

  return (
    <div
      className={
        clsx(
          'px-4 flex items-center left-0 sticky top-0 w-full py-0 md:px-6 z-[2]',
          'tablet:p-4 py-2 px-4 bg-surface border-b border-on-surface-stroke',
          className,
        )
      }
    >
      {!hideMenu && (
        <Icon
          className="mr-8 text-on-surface-inactive"
          icon="Menu"
          onClick={handleDrawerToggle}
          variant="interactive"
        />
      )}
      <button onClick={onClickLogo} type="button">
        <Logo />
      </button>
      <div className="grow" />
      <Menu
        options={[{
          icon: 'Logout',
          label: 'Logout',
          onClick: () => logout(),
        }]}
        triggerItem={<Avatar image={user?.profileImageUrl} text={username} />}
      />
    </div>
  );
};

export { AppBar };
