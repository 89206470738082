import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import Component from './Switch.component';

export interface SwitchProps {
  panelElement: PanelElement.AsObject;
}

const Switch = (props: SwitchProps) => {
  const {
    panelElement: {
      disabled,
      id,
      path,
    },
  } = props;

  const [value, setValue] = useDynamicFormRegister(
    id,
    {
      fromDynamic: toString,
      toDynamic: fromString,
    },
  );

  const optionsLabels = props.panelElement.optionsList.map((option) => ({
    disabled: option.disabled || false,
    name: option.headerText,
  }));

  return (
    <Component
      disabled={disabled}
      label={props.panelElement.headerText}
      onSelect={(v) => setValue(v, '', path)}
      optionsLabels={optionsLabels}
      selectedItem={value || ''}
    />
  );
};

export default Switch;
