import type { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { IndividualFeePayment } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { IntegratedDisclosureSectionEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useGetFeesPaidToParties } from '@/API/Queries/mortgage/useGetFeesPaidToParties';
import { Card } from '@/Components/Atoms/Card/Card';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { ListItem } from '@/Components/Atoms/ListItem';
import { Select } from '@/Components/Atoms/Select';
import { Toggle } from '@/Components/Atoms/Toggle';
import { Typography } from '@/Components/Atoms/Typography';
import { getDateFormatFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

interface IFeePaidBy {
  feePayment?: IndividualFeePayment.AsObject;
  feeSectionType: IntegratedDisclosureSectionEnum;
  updateDefaultTolerance: (flag: boolean) => void;
  isAdditionalInformationEditing: boolean;
}

const FeePaidTo = (props: IFeePaidBy) => {
  const { feePayment, feeSectionType, isAdditionalInformationEditing, updateDefaultTolerance } = props;
  const [invalidDate, setInvalidDate] = useState(false);
  const [borrowerToggleValue, setBorrowerToggleValue] = useState(false);
  const [lenderListToggleValue, setLenderListToggleValue] = useState(false);
  const [payee, setPayee] = useState({ label: '', value: '' });
  const [paymentDate, setPaymentDate] = useState<Timestamp.AsObject>();
  const { data: parties } = useGetFeesPaidToParties();

  useEffect(() => {
    if (feePayment) {
      setBorrowerToggleValue(Boolean(feePayment.borrowerChosenProviderIndicator));
      setLenderListToggleValue(Boolean(feePayment.providerSelectedFromLenderListIndicator));
      setPayee({ label: feePayment.payeePartySummary?.fullName || '', value: feePayment.payeePartySummary?.id || '' });
      setPaymentDate(feePayment.paymentDate);
    }
  }, [feePayment]);

  const partiesOptions = useMemo(() => {
    if (!parties?.partiesList) return [];
    return parties.partiesList.map((item) => ({ label: item.fullName, value: item.id }));
  },
  [parties]);

  const handleChangePayee = useCallback((selectedPayee: { label: string; value: string }) => {
    setPayee((prev) => {
      if (feePayment?.payeePartySummary?.id === selectedPayee.value) return prev;
      if (feePayment) {
        feePayment.payeePartySummary = {
          fullName: selectedPayee.label,
          id: selectedPayee.value,
        } as IndividualFeePayment.AsObject['payeePartySummary'];
      }
      return selectedPayee;
    });
  }, [feePayment]);

  const handleOnChangeData = useCallback((date: string) => {
    const dateRgx = /^\d{2}\/\d{2}\/\d{4}$/;

    if (!dateRgx.test(date)) {
      setInvalidDate(true);
      return;
    }

    setInvalidDate(false);
    if (feePayment) {
      const parsedDate = new Date(Date.parse(date));
      const currentDate = {
        nanos: (parsedDate.getTime() % 1000) * 1000000,
        seconds: Math.floor(parsedDate.getTime() / 1000),
      };
      feePayment.paymentDate = currentDate;
      setPaymentDate(currentDate);
    }
  }, [feePayment]);

  const handleToggleChange = useCallback((
    setToggleState: React.Dispatch<React.SetStateAction<boolean>>,
    updateObj: (flag: boolean) => void,
  ) => (flag: boolean) => {
    updateObj(flag);
    setToggleState(flag);
    if (!feePayment) return;
    if (feeSectionType === IntegratedDisclosureSectionEnum.INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CAN_SHOP_FOR) {
      updateDefaultTolerance(flag);
    }
  }, [feePayment, feeSectionType, updateDefaultTolerance]);

  const getFeedataInformation = (key: string) => {
    const { payeeContactInfo }: any = feePayment;
    if (payeeContactInfo && payeeContactInfo[key] !== '') {
      return payeeContactInfo[key];
    }
    return '--';
  };

  return (
    <Card
      defaultOpen
      headerProps={{
        title: 'Fee paid to',
      }}
      variant="border"
    >
      <div className="p-2 flex items-center">
        <div className="w-8/12">
          <div className="flex items-center gap-2 border-b border-r border-on-surface-stroke px-3 py-6">
            <Typography className="whitespace-nowrap" variant="body2">
              Select a Payee:
            </Typography>
            <Select
              allowClearValue
              className="!w-fit max-w-[224px]"
              displayOnly
              fullWidth={false}
              onSelect={(option) => handleChangePayee(option)}
              options={partiesOptions}
              placeholder="Party"
              readonly
              titleKey="label"
              uniqueKey="value"
              value={payee.label}
              variant="outlined"
            />
          </div>

          {
            (feeSectionType === IntegratedDisclosureSectionEnum.INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CANNOT_SHOP_FOR
            || feeSectionType === IntegratedDisclosureSectionEnum.INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CAN_SHOP_FOR) && (
              <div className="flex items-center gap-2 border-b border-r border-on-surface-stroke px-3 py-6">
                <Typography variant="body2">
                  Selected from lender list:
                </Typography>

                <Typography className="text-on-surface-active px-2" variant="body2Bold">
                  {lenderListToggleValue ? 'Yes' : 'No'}
                </Typography>

                <Toggle
                  disabled={isAdditionalInformationEditing}
                  onClick={handleToggleChange(setLenderListToggleValue,
                    (flag: boolean) => {
                      if (feePayment) {
                        feePayment.providerSelectedFromLenderListIndicator = flag;
                      }
                    })}
                  value={lenderListToggleValue}
                />
              </div>
            )
          }

          <div className="flex items-center gap-2 border-b border-r border-on-surface-stroke px-3 py-6">
            <Typography variant="body2">
              Borrower chosen provider:
            </Typography>

            <Typography className="text-on-surface-active px-2" variant="body2Bold">
              {borrowerToggleValue ? 'Yes' : 'No'}
            </Typography>

            <Toggle
              onClick={handleToggleChange(setBorrowerToggleValue,
                (flag: boolean) => {
                  if (feePayment) {
                    feePayment.borrowerChosenProviderIndicator = flag;
                  }
                })}
              value={borrowerToggleValue}
            />
          </div>

          <div className="flex items-center gap-2 border-r border-on-surface-stroke px-3 py-6">
            <Typography variant="body2">
              Payment date:
            </Typography>

            <Input
              error={invalidDate}
              helperText={invalidDate ? 'Invalid date' : ''}
              mask={MaskType.DATE}
              onBlur={() => !invalidDate}
              onChange={handleOnChangeData}
              value={getDateFormatFromTimestamp(paymentDate)}
            />
          </div>
        </div>
        <div className="w-4/12 flex flex-col">
          <ListItem
            caption={getFeedataInformation('phone')}
            className="ml-auto"
            label={getFeedataInformation('name')}
            leadingIconProps={{
              icon: 'Phone',
            }}
            overline="Contact Info"
          />

          <ListItem
            className="ml-auto"
            label={getFeedataInformation('email')}
            leadingIconProps={{
              icon: 'Email',
            }}
            overline="Email"
          />

          <ListItem
            caption={getFeedataInformation('addressAdditionalLineText')}
            className="ml-auto"
            label={getFeedataInformation('address')}
            leadingIconProps={{
              icon: 'Place',
            }}
            overline="Address"
          />
        </div>
      </div>
    </Card>
  );
};

export { FeePaidTo };
