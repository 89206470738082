import { Icon } from '@/Components/Atoms/Icon';
import { NavigationRailItem } from '@/Components/Atoms/NavigationRail';
import { Layout } from '@/Components/Layouts/NewLayout';

export const LoanListPageDesktop = () => (
  <Layout.Root>
    <Layout.Rail>
      <NavigationRailItem icon="Home" label="Home" route="/home" />
      <NavigationRailItem icon="Home" label="Loans" route="/loan-list" />
    </Layout.Rail>
    <Layout.Drawer>
      <div className="pt-3 px-2 flex flex-row justify-between">
        <Icon icon="ArrowBack" />
        <Icon icon="Search" />
      </div>
    </Layout.Drawer>
    <Layout.Content>
      <span>Hey</span>
    </Layout.Content>
  </Layout.Root>
);
