import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useParams } from 'react-router';

import type { LoanLender } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { GetLenderLoanByIdQueryRequest, GetLenderLoanByIdQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import type { WilqoMessage } from '../useBackend';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.mortgage.GetLenderLoanByIdQueryRequest';

const getLoan = async (commandResponse: (message: WilqoMessage) => Promise<any>, id: string) => {
  const request = new GetLenderLoanByIdQueryRequest();
  request.setDealId(id);
  const responseMsg = await commandResponse({
    msg: request,
    msgName: MESSAGE_NAME,
  });
  const response = new GetLenderLoanByIdQueryResponse();
  GetLenderLoanByIdQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
  const responseObj = response.toObject();
  return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.loanLender);
};

export const useLoan = (onSuccess?: (value: LoanLender.AsObject) => void, loanIdParam?: string) => {
  const { commandResponse } = useBackend();
  const { loanId = '' } = useParams();
  return useQuery([MESSAGE_NAME, loanIdParam || loanId], () => getLoan(commandResponse, loanIdParam || loanId || ''), { onSuccess });
};
