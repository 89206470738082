import { useState } from 'react';
import { useParams } from 'react-router';

import type { IntegratedDisclosureSectionEnum,
  LoanEscrowFee,
  LoanFee,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useResetSandbox } from '@/API/Queries/mortgage/useResetSandbox';
import { Dialog } from '@/Components/Atoms/RadixDialog';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import ManageFeesTable from './ManageFeesTable';
import EscrowIndividualFeeEdit from './ManageIndividuaFees/Escrows';
import IndividualFeeEdit from './ManageIndividuaFees/Fee';
import PrepaidIndividualFeeEdit from './ManageIndividuaFees/Prepaids';

interface AddLoanConditionProps {
  onClose: () => void;
  isChipHidden?: (inputString: string) => boolean;
  open: boolean;
}

const ManageIndividuaFees = (props: AddLoanConditionProps) => {
  const { onClose, open } = props;
  const { loanId = '' } = useParams();
  const { mutate: resetSandbox } = useResetSandbox();
  const { showSnackBar } = useShared();
  const [editFee, setEditFee] = useState<LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject>();
  const [feeSectionType, setFeeSectionType] = useState<IntegratedDisclosureSectionEnum>(0);
  const [categoryType, setCategoryType] = useState<LoanFeeCategoryTypeEnum>(0);
  const [updateFees, setUpdateFees] = useState(false);

  const errorMessage = 'Something went wrong';

  const handleDiscard = () => {
    resetSandbox({
      dealId: loanId,
      loanId: '',
    }, {
      onError() {
        showSnackBar({ message: errorMessage, open: true });
      },
      onSuccess() {
        setEditFee(undefined);
        onClose();
        showSnackBar({ message: 'Changes Discarded', open: true });
      },
    });
  };

  const handleClose = () => {
    if (!editFee && !updateFees) {
      handleDiscard();
    } else {
      setEditFee(undefined);
      onClose();
    }
  };

  const setEditInformation = (
    selectedFee: LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject,
    categoryType: LoanFeeCategoryTypeEnum,
    feeSectionType?: IntegratedDisclosureSectionEnum | undefined,
  ) => {
    if (feeSectionType) {
      setFeeSectionType(feeSectionType);
    }
    setEditFee(selectedFee);
    setCategoryType(categoryType);
  };

  return (
    <Dialog
      onOpenChange={handleClose}
      open={open}
    >
      {
        !editFee && (
          <ManageFeesTable
            handleClose={handleClose}
            onEditFee={(fee, categoryType, FeeSectionType) => setEditInformation(fee, categoryType, FeeSectionType)}
            onUpdateFees={() => {
              setUpdateFees(true);
              onClose();
            }}
          />
        )
      }

      {
        (editFee && categoryType === LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE) && (
          <IndividualFeeEdit
            categoryType={categoryType}
            clearFee={() => setEditFee(undefined)}
            fee={editFee as LoanFee.AsObject}
            feeSectionType={feeSectionType}
            isChipHidden={props.isChipHidden}
          />
        )
      }

      {
        (editFee && categoryType === LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID) && (
          <PrepaidIndividualFeeEdit
            categoryType={categoryType}
            clearFee={() => setEditFee(undefined)}
            fee={editFee as LoanPrepaidFee.AsObject}
          />
        )
      }

      {
        (editFee && categoryType === LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_ESCROW) && (
          <EscrowIndividualFeeEdit
            categoryType={categoryType}
            clearFee={() => setEditFee(undefined)}
            fee={editFee as LoanEscrowFee.AsObject}
          />
        )
      }
    </Dialog>
  );
};

export default ManageIndividuaFees;
