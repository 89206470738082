import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { Any } from 'google-protobuf/google/protobuf/any_pb';

import type { IntegrationTypeLocalEnum } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { IntegrationVendorEnum, ProcessType } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { GetRequiredFieldsValidationQueryRequest, GetRequiredFieldsValidationQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetRequiredFieldsValidationQueryRequest';

interface AdditionalObjectGeneric {
  serializeBinary: () => Uint8Array;
}

interface GetRequiredFieldsValidationProps<AdditionalInformation> {
  dealId: string;
  integrationType: IntegrationTypeLocalEnum;
  additionalInformation?: AdditionalInformation;
  packageName?: string;
  vendor?: IntegrationVendorEnum;
  processType?: ProcessType;
}

const useGetRequiredFieldsValidation = <AdditionalInformation extends AdditionalObjectGeneric>(props: GetRequiredFieldsValidationProps<AdditionalInformation>) => {
  const { additionalInformation, dealId, integrationType, packageName, processType, vendor } = props;
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, dealId, integrationType, additionalInformation, processType], async () => {
    const request = new GetRequiredFieldsValidationQueryRequest();
    request.setDealId(dealId);
    request.setIntegrationType(integrationType);
    request.setIntegrationVendor(vendor ?? IntegrationVendorEnum.INTEGRATION_VENDOR_ENUM_UNKNOWN);
    request.setProcessType(processType ?? ProcessType.PROCESS_TYPE_UNKNOWN);
    if (additionalInformation) {
      const additional = new Any();
      additional.pack(additionalInformation.serializeBinary(), packageName || '');
      request.setAdditionalInformation(additional);
    }

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetRequiredFieldsValidationQueryResponse();
    GetRequiredFieldsValidationQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj);
  }, {
    enabled: Boolean(dealId),
  });
};
export { useGetRequiredFieldsValidation };
