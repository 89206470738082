import { useMemo } from 'react';
import { useParams } from 'react-router';

import { useBusinessProcessTemplate } from '@/API/Queries/businessProcess/useBusinessProcessTemplate';
import { useUpdateBusinessProcessTemplate } from '@/API/Queries/businessProcess/useUpdateBusinessProcessTemplate';
import { useProgressItemTemplates } from '@/API/Queries/mortgageConfig/useProgressItemTemplates';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement, getPanelElementOption } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const BusinessProcessTemplateDetails = () => {
  const { id = '' } = useParams();
  const { data: bpTemplate, isLoading, refetch } = useBusinessProcessTemplate(id);
  const { data: progressItems = [] } = useProgressItemTemplates();
  const { isLoading: isSaving, mutate: updateBPTemplate } = useUpdateBusinessProcessTemplate();
  const { showSnackBar } = useShared();

  const progressOptions = useMemo(() => progressItems?.map((progressItem) => getPanelElementOption({
    headerText: progressItem.name,
    id: progressItem.identifier,
  })), [progressItems]);

  const selectedProgressItem = useMemo(() => progressOptions
    .find((option) => option.id === bpTemplate?.loanProgressItemIdentifier),
  [progressOptions, bpTemplate?.loanProgressItemIdentifier]);

  const handleSubmitSuccess = (close: () => void) => {
    close();
    showSnackBar({ message: 'Business Process Template updated successfully', open: true });
    refetch();
  };

  const handleSubmitError = (error: any) => showSnackBar({ message: error?.message || 'Something went wrong', open: true });

  const handleSubmit = (values: DynamicDataElementValues, close: () => void) => {
    const params = {
      id,
      internalName: values.internalName.value,
      progressItemId: values.progressItem.value?.id,
      title: values.externalName.value,
    };
    updateBPTemplate(params, { onError: handleSubmitError, onSuccess: () => handleSubmitSuccess(close) });
  };

  return (
    <div className=" p-12">
      <CardListItem
        headerProps={{ title: 'Business Process Template Details' }}
        isEditable
        isLoading={isLoading}
        isSaving={isSaving}
        listItems={[
          {
            caption: bpTemplate?.title || '',
            label: 'External Name',
            panelElement: getPanelElement({ headerText: 'External Name', id: 'externalName', type: 'text' }),
            value: { value: bpTemplate?.title || '' },
          },
          {
            caption: bpTemplate?.internalName || '',
            label: 'Internal Name',
            panelElement: getPanelElement({ headerText: 'Internal Name', id: 'internalName', type: 'text' }),
            value: { value: bpTemplate?.internalName || '' },
          },

          {
            caption: selectedProgressItem?.headerText,
            label: 'Progress Item',
            panelElement: getPanelElement({ headerText: 'Progress Item', id: 'progressItem', optionsList: progressOptions, type: 'select' }),
            value: { value: selectedProgressItem },
          },
        ]}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
