import { useLocation } from 'react-router';

import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

interface State {
  panel: Panel.AsObject;
}

const MessageRenderer = () => {
  const { state } = useLocation();
  const { panel: { panelElementsList } } = state as State;

  return (
    <div
      className="flex flex-col items-center justify-center my-[10%] mx-auto text-center w-max [&_>_*]:mb-4"
    >
      {
        panelElementsList.map((panelElement) => <PanelElementRenderer key={panelElement.id} variant="default" {...panelElement} />)
      }
    </div>
  );
};

export { MessageRenderer };
