// source: Wilqo.Shared.Models/UIComponents/InfoCard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb = require('../../Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb = require('../../Wilqo.Shared.Models/Wilqo_Shared_Models_UIComponents_Common_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb);
goog.exportSymbol('proto.wilqo.shared.models.InfoCard', null, global);
goog.exportSymbol('proto.wilqo.shared.models.InfoCardItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.InfoCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.InfoCard.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.InfoCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.InfoCard.displayName = 'proto.wilqo.shared.models.InfoCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.InfoCardItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.InfoCardItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.InfoCardItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.InfoCardItem.displayName = 'proto.wilqo.shared.models.InfoCardItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.InfoCard.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.InfoCard.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.InfoCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.InfoCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.InfoCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    variant: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.InfoCardItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.InfoCard}
 */
proto.wilqo.shared.models.InfoCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.InfoCard;
  return proto.wilqo.shared.models.InfoCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.InfoCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.InfoCard}
 */
proto.wilqo.shared.models.InfoCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.InfoCardItem;
      reader.readMessage(value,proto.wilqo.shared.models.InfoCardItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.InfoCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.InfoCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.InfoCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.InfoCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.InfoCardItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.wilqo.shared.models.InfoCard.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
 */
proto.wilqo.shared.models.InfoCard.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RenderableValue value = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.shared.models.InfoCard.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
*/
proto.wilqo.shared.models.InfoCard.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
 */
proto.wilqo.shared.models.InfoCard.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.InfoCard.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UxDisplayStatusEnum variant = 3;
 * @return {!proto.wilqo.shared.models.UxDisplayStatusEnum}
 */
proto.wilqo.shared.models.InfoCard.prototype.getVariant = function() {
  return /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
 */
proto.wilqo.shared.models.InfoCard.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated InfoCardItem items = 4;
 * @return {!Array<!proto.wilqo.shared.models.InfoCardItem>}
 */
proto.wilqo.shared.models.InfoCard.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.InfoCardItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.InfoCardItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.InfoCardItem>} value
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
*/
proto.wilqo.shared.models.InfoCard.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.InfoCardItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.InfoCardItem}
 */
proto.wilqo.shared.models.InfoCard.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.InfoCardItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.InfoCard} returns this
 */
proto.wilqo.shared.models.InfoCard.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.InfoCardItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.InfoCardItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.InfoCardItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.InfoCardItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    hyperlinksList: jspb.Message.toObjectList(msg.getHyperlinksList(),
    Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.InfoCardItem}
 */
proto.wilqo.shared.models.InfoCardItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.InfoCardItem;
  return proto.wilqo.shared.models.InfoCardItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.InfoCardItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.InfoCardItem}
 */
proto.wilqo.shared.models.InfoCardItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.deserializeBinaryFromReader);
      msg.addHyperlinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.InfoCardItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.InfoCardItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.InfoCardItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getHyperlinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.InfoCardItem} returns this
 */
proto.wilqo.shared.models.InfoCardItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RenderableValue value = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.shared.models.InfoCardItem} returns this
*/
proto.wilqo.shared.models.InfoCardItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.InfoCardItem} returns this
 */
proto.wilqo.shared.models.InfoCardItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated PanelHyperlinkElement hyperlinks = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.getHyperlinksList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>} value
 * @return {!proto.wilqo.shared.models.InfoCardItem} returns this
*/
proto.wilqo.shared.models.InfoCardItem.prototype.setHyperlinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelHyperlinkElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelHyperlinkElement}
 */
proto.wilqo.shared.models.InfoCardItem.prototype.addHyperlinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelHyperlinkElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.InfoCardItem} returns this
 */
proto.wilqo.shared.models.InfoCardItem.prototype.clearHyperlinksList = function() {
  return this.setHyperlinksList([]);
};


goog.object.extend(exports, proto.wilqo.shared.models);
