import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetSingleDataFieldTemplateQueryRequest, GetSingleDataFieldTemplateQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetSingleDataFieldTemplateQueryRequest';

const useSingleDataFieldTemplate = (id: string, enabled = true) => {
  const { commandResponse } = useBackend();
  const { secureAuthValues } = useAuth();
  const [bpdid] = secureAuthValues.authorizedBusinessProcessDomainIds;

  return useQuery([MESSAGE_NAME, id], async () => {
    const request = new GetSingleDataFieldTemplateQueryRequest();
    request.setTemplateId(id);
    request.setBusinessProcessDomainId(bpdid);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });

    const response = new GetSingleDataFieldTemplateQueryResponse();
    GetSingleDataFieldTemplateQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.dataField);
  },
  {
    enabled,
  });
};
export { useSingleDataFieldTemplate };
