import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';

import { loadScript } from '@/Utils/Helpers/loadScript';

type PredictionType = 'address' | 'zip';

export const useGooglePredictions = (inputValue: string, predictionType: PredictionType = 'address', filterInternational = true) => {
  const loaded = useRef(false);
  const autocompleteService = useRef<any>(null);

  const restrictionConfig = useMemo(() => {
    switch (predictionType) {
      case 'address':
        return ['address'];
      case 'zip':
        return ['(regions)'];
      default:
        return [];
    }
  }, [predictionType]);

  // load maps API
  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_KEY}&libraries=places&language=en`,
          document.querySelector('head'),
          'google-maps',
        );
      }
      loaded.current = true;
    }
  }, []);

  const asyncPlacePredictions = (request: any): Promise<any> => new Promise((resolve) => {
    if (!autocompleteService.current) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    autocompleteService.current?.getPlacePredictions(request, (response: any) => {
      resolve(response);
    });
  });

  return useQuery([inputValue], async () => {
    let request: any = {
      input: inputValue,
      types: restrictionConfig,
    };
    if (filterInternational) {
      request = {
        ...request,
        componentRestrictions: {
          country: 'us',
        },
      };
    }
    const response = await asyncPlacePredictions(request);
    return response || [];
  }, { enabled: Boolean(inputValue) });
};
