import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';

interface UpdateCredentialsProps {
  selectedCredentialType: string;
  credentialSet: any;
}

const UpdateCredentials = ({ selectedCredentialType }: UpdateCredentialsProps) => (
  <div className="mb-4">
    <Card
      defaultOpen
      disableAccordion
      headerProps={{
        headerIcon: {
          icon: 'Add',
          onClick: () => {},
        },
        open: true,
        subtitle: selectedCredentialType,
        title: 'Update Credentials',
      }}
      variant="border"
    >
      <div className="p-4 h-[278px]">
        <ContingencyMessage
          icon="NoItems"
          subtitle=""
          title="No Credentials Found"
          variant="image"
        />
      </div>
    </Card>
  </div>
);

export { UpdateCredentials };
