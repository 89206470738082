import clsx from 'clsx';
import type { MouseEvent, ReactNode } from 'react';
import { useMemo } from 'react';
import Markdown from 'react-markdown';

import { cleanString } from '@/Utils/Helpers/normalizeString';

import type { IAvatarProps } from './Avatar';
import { Avatar } from './Avatar';
import type { IBadgeProps } from './Badge';
import { Badge } from './Badge';
import { Icon } from './Icon';
import { Menu } from './Menu';
import { Skeleton } from './Skeleton';
import { Tooltip } from './Tooltip';
import { Typography } from './Typography';

export interface Option extends ListItemProps {
  label: string;
  onClick: () => void;
  icon?: string;
  children?: Array<Option>;
}

interface ListItemIconProps {
  dropdownOptions?: Array<Option>;
  icon?: string;
  onClick?: () => void;
  selected?: boolean;
}

export interface ListItemProps {
  label: ReactNode;
  avatarProps?: IAvatarProps;
  caption?: string[] | string;
  divider?: boolean;
  image?: string;
  leadingIconProps?: ListItemIconProps;
  leadingText?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  overline?: string;
  trailingIconProps?: ListItemIconProps;
  className?: string;
  disableInteractions?: boolean;
  badgeProps?: IBadgeProps & { placement?: 'horizontal' | 'vertical' };
  tooltip?: string;
  revertEmphasis?: boolean;
  isLoadingLabel?: boolean;
  isLoadingCaption?: boolean;
  focused?: boolean;
  selected?: boolean;
  trailingMenu?: ReactNode;
}

const ListItem = (props: ListItemProps) => {
  const {
    avatarProps,
    badgeProps,
    caption,
    className,
    disableInteractions = false,
    divider = false,
    focused = false,
    image,
    isLoadingCaption,
    isLoadingLabel,
    label,
    leadingIconProps,
    leadingText,
    onClick,
    overline,
    revertEmphasis = false,
    selected = false,
    tooltip,
    trailingIconProps,
    trailingMenu,
  } = props;

  const normalizedLabel = useMemo(() => (typeof label === 'string' ? cleanString(label) : label), [label]);

  const captions = useMemo(() => {
    if (Array.isArray(caption)) {
      return caption.map((value) => ({ id: `caption-${caption}-${value}`, value }));
    }

    return [{ id: caption, value: caption }];
  }, [caption]);

  const renderIcon = (listItemIconProps: ListItemIconProps) => {
    const { dropdownOptions, icon, onClick, selected } = listItemIconProps;

    if (dropdownOptions) {
      return (
        <Menu closeAfterSelecting options={dropdownOptions} triggerItem={<Icon icon={icon || ''} />} />
      );
    }
    return (
      <Icon className={clsx({ '!text-on-surface-inactive': !selected })} icon={icon || ''} onClick={onClick} selected={selected} />
    );
  };

  const renderLeadingItem = () => {
    let LeadingItem = null;
    if (leadingIconProps) LeadingItem = renderIcon(leadingIconProps);
    if (avatarProps) LeadingItem = <Avatar {...avatarProps} />;
    if (image) LeadingItem = <img alt="leading_img" className="h-14 w-14" src={image} />;
    if (leadingText) {
      LeadingItem = (
        <div className="items-center bg-surface-variant flex h-14 justify-center w-14">
          <span className="font-six text-xl leading-6">{leadingText}</span>
        </div>
      );
    }

    if (LeadingItem) {
      return (
        <div className="mr-4 max-h-14">
          {LeadingItem}
        </div>
      );
    }

    return null;
  };

  const renderTrailingItem = () => {
    const renderTrailingBadge = !badgeProps?.placement || badgeProps?.placement === 'horizontal';

    if (trailingIconProps && badgeProps && renderTrailingBadge) {
      return (
        <div className="min-w-fit flex flex-row gap-2 items-center">
          <Badge {...badgeProps} />
          {renderIcon(trailingIconProps)}
        </div>
      );
    }
    if (trailingIconProps) {
      return (
        <div className="min-w-fit mt-[6px]">
          {renderIcon(trailingIconProps)}
        </div>
      );
    }
    if (badgeProps && renderTrailingBadge) {
      return (
        <div className="min-w-fit mt-2">
          <Badge {...badgeProps} />
        </div>
      );
    }

    return null;
  };

  const renderTrailingMenu = () => {
    if (trailingMenu) {
      return (
        <div className="absolute top-0 right-0 h-full flex px-4 w-12 items-center justify-center hover:bg-on-surface-states-resting">
          {trailingMenu}
        </div>
      );
    }
    return null;
  };

  const renderListItem = () => (
    <div className="relative">
      <button
        className={
          clsx('appearance-none text-left flex items-start w-full', className, {
            'bg-on-surface-states-resting': focused && !selected,
            'bg-primary-on-surface-states-focus': selected,
            'bg-surface': !focused && !selected,
            'border-b-[1px] border-b-on-surface-stroke': divider,
            'cursor-pointer': Boolean(onClick),
            'hover:bg-on-surface-states-resting active:bg-on-surface-states-pressed': !disableInteractions,
            'pl-2': Boolean(leadingIconProps) || Boolean(image),
            'pl-4': !(Boolean(leadingIconProps) || Boolean(image)),
            'pr-4': label && !trailingIconProps,
            'px-4 desktop:pr-2': Boolean(trailingIconProps),
            'py-2': image,
            'py-4': label && (!image || !overline),
            'py-[14px]': overline,
          })
        }
        onClick={onClick}
        type="button"
      >
        {renderLeadingItem()}
        <div className="flex flex-col w-full">
          <div className="items-start flex w-full break-all">
            <div className="flex flex-col w-full justify-center mr-4">
              {badgeProps && badgeProps.placement === 'vertical' && (
                <div className="min-w-fit mb-1">
                  <Badge {...badgeProps} />
                </div>
              )}
              {overline
                && <Typography className="text-on-surface-inactive break-keep" variant="overline">{overline}</Typography>}
              {!isLoadingLabel ? (
                <Typography
                  className={clsx('break-words', {
                    'mb-1': caption,
                    'mt-1': overline,
                    'text-on-surface-active': !revertEmphasis,
                    'text-on-surface-inactive': revertEmphasis,
                  })}
                  variant={revertEmphasis ? 'caption' : 'body2'}
                >
                  {normalizedLabel}
                </Typography>
              ) : <Skeleton height="14px" margin="0px" variant="rect" />}
            </div>
            {renderTrailingItem()}
          </div>
          {caption && !isLoadingCaption && (
            <Typography
              className={clsx('break-words', {
                'text-on-surface-active': revertEmphasis,
                'text-on-surface-inactive': !revertEmphasis,
              })}
              variant={revertEmphasis ? 'body2' : 'caption'}
            >
              {captions.map((caption) => (
                <Markdown key={`caption-${caption.id}`}>
                  {caption.value}
                </Markdown>
              ))}
            </Typography>
          )}
          {isLoadingCaption && <Skeleton height="12px" margin="0px" variant="rect" />}
        </div>
      </button>
      {renderTrailingMenu()}
    </div>

  );

  if (tooltip) {
    return (
      <div className="relative">
        <Tooltip message={tooltip} position="right">
          {renderListItem()}
        </Tooltip>
      </div>
    );
  }

  return renderListItem();
};

export { ListItem };
