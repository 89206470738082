// source: Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb = require('../Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models_pb.js');
goog.object.extend(proto, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_DynamicModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
goog.exportSymbol('proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.displayName = 'proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.displayName = 'proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.displayName = 'proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.displayName = 'proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.displayName = 'proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.displayName = 'proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.displayName = 'proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.displayName = 'proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.displayName = 'proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.displayName = 'proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.displayName = 'proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.displayName = 'proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.displayName = 'proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.displayName = 'proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.displayName = 'proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    settings: (f = msg.getSettings()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.toObject(includeInstance, f),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    loanProgressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    completeProgressItemOnBPCompletion: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    allowConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    dynamicTrigger: (f = msg.getDynamicTrigger()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest;
  return proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 4:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProgressItemIdentifier(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteProgressItemOnBPCompletion(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrentInstances(value);
      break;
    case 12:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.deserializeBinaryFromReader);
      msg.setDynamicTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLoanProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompleteProgressItemOnBPCompletion();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAllowConcurrentInstances();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getDynamicTrigger();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BusinessProcessTypeEnum type = 2;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Any settings = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getSettings = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
*/
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregator trigger_rules = 4;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregator}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator, 4));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregator|undefined} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
*/
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string business_process_template_id = 5;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string internal_name = 6;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string loan_progress_item_identifier = 7;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getLoanProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setLoanProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_process_domain_id = 8;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool complete_progress_item_on_b_p_completion = 9;
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getCompleteProgressItemOnBPCompletion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setCompleteProgressItemOnBPCompletion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool allow_multiple_instances = 10;
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool allow_concurrent_instances = 11;
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getAllowConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setAllowConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional wilqo.shared.rulesengine.DynamicRuleAggregator dynamic_trigger = 12;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.getDynamicTrigger = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator, 12));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator|undefined} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
*/
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.setDynamicTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.clearDynamicTrigger = function() {
  return this.setDynamicTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest.prototype.hasDynamicTrigger = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse;
  return proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} returns this
*/
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_process_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.CreateBusinessProcessTemplateCommandResponse.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanProgressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    completeProgressItemOnBPCompletion: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProgressItemIdentifier(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteProgressItemOnBPCompletion(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrentInstances(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompleteProgressItemOnBPCompletion();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowConcurrentInstances();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_name = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_progress_item_identifier = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getLoanProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setLoanProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool complete_progress_item_on_b_p_completion = 5;
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getCompleteProgressItemOnBPCompletion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setCompleteProgressItemOnBPCompletion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool allow_multiple_instances = 6;
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allow_concurrent_instances = 7;
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getAllowConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setAllowConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string business_process_domain_id = 8;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessTemplateDesign: (f = msg.getBusinessProcessTemplateDesign()) && Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.deserializeBinaryFromReader);
      msg.setBusinessProcessTemplateDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateDesign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessTemplateDesign business_process_template_design = 2;
 * @return {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.getBusinessProcessTemplateDesign = function() {
  return /** @type{?proto.wilqo.api.business_process.BusinessProcessTemplateDesign} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign, 2));
};


/**
 * @param {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.setBusinessProcessTemplateDesign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.clearBusinessProcessTemplateDesign = function() {
  return this.setBusinessProcessTemplateDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateCommandResponse.prototype.hasBusinessProcessTemplateDesign = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settings: (f = msg.getSettings()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Any settings = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.getSettings = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandRequest.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessTemplateDesign: (f = msg.getBusinessProcessTemplateDesign()) && Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.deserializeBinaryFromReader);
      msg.setBusinessProcessTemplateDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateDesign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessTemplateDesign business_process_template_design = 2;
 * @return {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.getBusinessProcessTemplateDesign = function() {
  return /** @type{?proto.wilqo.api.business_process.BusinessProcessTemplateDesign} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign, 2));
};


/**
 * @param {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.setBusinessProcessTemplateDesign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.clearBusinessProcessTemplateDesign = function() {
  return this.setBusinessProcessTemplateDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateSettingsCommandResponse.prototype.hasBusinessProcessTemplateDesign = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 3;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandRequest.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse;
  return proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 2;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} returns this
*/
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse} returns this
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UpdateBusinessProcessTemplateTriggerCommandResponse.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest;
  return proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessTemplateIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string business_process_template_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.getBusinessProcessTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.setBusinessProcessTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.addBusinessProcessTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest.prototype.clearBusinessProcessTemplateIdsList = function() {
  return this.setBusinessProcessTemplateIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse;
  return proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse} returns this
*/
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse} returns this
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest;
  return proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessTemplateIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string business_process_template_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.getBusinessProcessTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.setBusinessProcessTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.addBusinessProcessTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandRequest.prototype.clearBusinessProcessTemplateIdsList = function() {
  return this.setBusinessProcessTemplateIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse;
  return proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse} returns this
*/
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse} returns this
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.UnarchiveBusinessProcessTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeArchived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest;
  return proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeArchived();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_archived = 2;
 * @return {boolean}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.getIncludeArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest} returns this
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandRequest.prototype.setIncludeArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse;
  return proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse} returns this
*/
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse} returns this
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.InitializeBusinessProcessTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest;
  return proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest} returns this
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse;
  return proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} returns this
*/
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string business_process_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse} returns this
 */
proto.wilqo.api.business_process.TriggerBusinessProcessTemplateCommandResponse.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest;
  return proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} returns this
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} returns this
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest} returns this
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse;
  return proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse} returns this
*/
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse} returns this
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest;
  return proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string internal_name = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    businessProcessTemplateDesign: (f = msg.getBusinessProcessTemplateDesign()) && Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse;
  return proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign;
      reader.readMessage(value,Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.deserializeBinaryFromReader);
      msg.setBusinessProcessTemplateDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateDesign();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} returns this
*/
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BusinessProcessTemplateDesign business_process_template_design = 2;
 * @return {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.getBusinessProcessTemplateDesign = function() {
  return /** @type{?proto.wilqo.api.business_process.BusinessProcessTemplateDesign} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_BusinessProcess_Wilqo_API_BusinessProcess_Models_pb.BusinessProcessTemplateDesign, 2));
};


/**
 * @param {?proto.wilqo.api.business_process.BusinessProcessTemplateDesign|undefined} value
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} returns this
*/
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.setBusinessProcessTemplateDesign = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse} returns this
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.clearBusinessProcessTemplateDesign = function() {
  return this.setBusinessProcessTemplateDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.EditBusinessProcessTemplateDesignNamesCommandResponse.prototype.hasBusinessProcessTemplateDesign = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.business_process);
