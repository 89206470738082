import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { RuleTypeEnum } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb';
import { GetActivityTriggerFieldListQueryRequest, GetActivityTriggerFieldListQueryResponse } from '@/API/Models/Wilqo_API_Activity_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetActivityTriggerFieldListQueryRequest';

const useDataFieldTemplateForTriggers = (search?: string, type?: RuleTypeEnum) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, type, search], async () => {
    const request = new GetActivityTriggerFieldListQueryRequest();

    request.setSearchTerm(search || '');
    request.setFieldType(type || RuleTypeEnum.RULE_TYPE_ENUM_UNKNOWN);
    request.setBusinessProcessDomainId(bpdId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });

    const response = new GetActivityTriggerFieldListQueryResponse();
    GetActivityTriggerFieldListQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.groupsList);
  }, { enabled: Boolean(bpdId) });
};
export { useDataFieldTemplateForTriggers };
