import { useQuery } from '@tanstack/react-query';

import type { LoanPermission } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { GetUserLoanPermissionQueryRequest, GetUserLoanPermissionQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';
import type { QueryOptions } from '@/Utils/Hooks/usePoll';

import { useWilqoMessage } from '../useWilqoMessage';

export const MESSAGE_NAME = 'wilqo.api.users.GetUserLoanPermissionQueryRequest';

export const useUserLoanPermissions = (userId: string, options?: QueryOptions<LoanPermission.AsObject>) => {
  const sendMessage = useWilqoMessage();
  const intitutionId = useInstitutionId();

  return useQuery<LoanPermission.AsObject | undefined, Error>([MESSAGE_NAME, userId, intitutionId], async () => {
    const request = new GetUserLoanPermissionQueryRequest();
    request.setUserId(userId);
    request.setInstitutionId(intitutionId);
    const response = await sendMessage<GetUserLoanPermissionQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetUserLoanPermissionQueryResponse,
    );
    return response.loanPermission;
  },
  {
    enabled: Boolean(intitutionId) && Boolean(userId),
    ...options,
  });
};
