import { useMutation } from '@tanstack/react-query';

import { ArchiveAdminLenderConditionTemplatesCommandRequest, ArchiveAdminLenderConditionTemplatesCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

interface Params {
  ids: string[];
  archive: boolean;
}

const MESSAGE_NAME = 'wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest';

export const useArchiveCondition = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation<ArchiveAdminLenderConditionTemplatesCommandResponse.AsObject, Error, Params, any>(async (params: Params) => {
    const request = new ArchiveAdminLenderConditionTemplatesCommandRequest();
    request.setArchived(params.archive);
    request.setBusinessProcessDomainId(bpdId);
    request.setInstitutionId(institutionId);
    request.setLenderConditionTemplateIdsList(params.ids);
    return sendMessage<ArchiveAdminLenderConditionTemplatesCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      ArchiveAdminLenderConditionTemplatesCommandResponse,
    );
  });
};
