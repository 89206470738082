import { useMutation } from '@tanstack/react-query';

import { useAuth } from '@/Routes/Auth/AppAuthContext';

interface FileData {
  institutionId: string;
  formData: FormData;
}

const useUploadFile = () => {
  const { withAccessToken } = useAuth();

  const putFileUploader = async (fileData: FileData) => {
    const { formData, institutionId } = fileData;
    const headers = new Headers();
    const accessToken = await withAccessToken();
    const url = `/api/public/files/untrusted/institutions/${institutionId}`;
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
      body: formData,
      headers,
      method: 'POST',
    };
    const response = await fetch(url, requestOptions);
    return response.json();
  };

  return useMutation(putFileUploader);
};

export { useUploadFile };
