import { useQuery } from '@tanstack/react-query';

import {
  GetBusinessProcessTemplateByIdQueryRequest,
  GetBusinessProcessTemplateByIdQueryResponse,
} from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.business_process.GetBusinessProcessTemplateByIdQueryRequest';

export const useBusinessProcessTemplate = (templateId: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, templateId], async () => {
    const request = new GetBusinessProcessTemplateByIdQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setBusinessProcessTemplateId(templateId);
    const response = await sendMessage<GetBusinessProcessTemplateByIdQueryResponse.AsObject>({
      msg: request,
      msgName: MESSAGE_NAME,
    }, GetBusinessProcessTemplateByIdQueryResponse);
    return response.businessProcessTemplateDesign;
  });
};
