import type { LoanPageAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { Button, ButtonVariants } from '@/Components/Atoms/Button';
import { Menu } from '@/Components/Atoms/Menu';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';

import { EditWidgetsAction } from './EditWidgetsAction';
import { ExpanderAction } from './ExpanderAction';
import { useDynamicAction } from './useDynamicAction';
import { useDynamicQueryTrigger } from './useDynamicQueryTrigger';

interface Props {
  actions: Array<LoanPageAction.AsObject>;
  onCloseDialog?: VoidFunction;
  defaultModalType?: LoanPageActionType;
}

export const DynamicActions = (props: Props) => {
  const { hiddenHeaderActions = [] } = useDynamicForm();
  const { actions } = props;
  const { currentAction, toggleAction } = useDynamicContext();

  useDynamicQueryTrigger();
  useDynamicAction();

  const renderActionButton = (action: LoanPageAction.AsObject) => {
    const hiddenSubActions = hiddenHeaderActions
      .filter((headerAction) => headerAction.headerActionId === action.id && !headerAction.hideParent)
      .flatMap((headerAction) => headerAction.actionSubtypeIdsList);

    if (!action.label) {
      return undefined;
    }

    if (action?.actionsListList?.length > 0) {
      return (
        <Menu
          key={action.id}
          closeAfterSelecting
          options={
            action.actionsListList.filter(
              (subAction) => !hiddenSubActions.includes(subAction.id),
            ).map((subAction) => ({
              key: subAction.label,
              label: subAction.label,
              onClick: () => toggleAction(action, subAction),
            }))
          }
          triggerItem={(
            <Button
              className="!h-[33px]"
              label={action.label}
              rightIcon="ArrowDropDown"
              variant={ButtonVariants[action.variant]}
            />
          )}
        />
      );
    }

    if (action.type === LoanPageActionType.LOAN_PAGE_ACTION_TYPE_EXPANDER) {
      return <ExpanderAction />;
    }
    if (action.type === LoanPageActionType.LOAN_PAGE_ACTION_TYPE_EDIT_MODE) {
      return <EditWidgetsAction />;
    }

    return (
      <Button
        key={action.label}
        label={action.label}
        onClick={() => toggleAction(action)}
        variant={ButtonVariants[action.variant]}
      />
    );
  };

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      {currentAction?.type === LoanPageActionType.LOAN_PAGE_ACTION_TYPE_EDIT_MODE ? (
        <EditWidgetsAction />
      )
        : actions.filter(
          (action) => !hiddenHeaderActions
            .filter((headerAction) => headerAction.hideParent)
            .map((headerAction) => headerAction.headerActionId)
            .includes(action.id),
        ).map(
          (action) => renderActionButton(action),
        )}
    </div>
  );
};
