import { useMutation } from '@tanstack/react-query';

import { SkillsetGroupCommandResponse, UpdateSkillsetGroupCommandRequest } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.UpdateSkillsetGroupCommandRequest';

interface UpdateObject {
  skillsetGroupId: string;
  displayName: string;
  longDescription: string;
  skillsetIds: string[];
  userIds: string[];
}

export const useUpdateSkillsetGroup = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  const UpdateSkillsetGroupCommand = async (updateObject: UpdateObject) => {
    const { displayName, longDescription, skillsetGroupId, skillsetIds, userIds } = updateObject;
    const request = new UpdateSkillsetGroupCommandRequest();
    request.setId(skillsetGroupId);
    request.setDisplayName(displayName);
    request.setLongDescription(longDescription);
    request.setSkillSetIdsList(skillsetIds);
    request.setUserIdsList(userIds);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    return sendMessage<SkillsetGroupCommandResponse.AsObject>(message, SkillsetGroupCommandResponse);
  };

  return useMutation(UpdateSkillsetGroupCommand);
};
