import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import type { ReactNode } from 'react';
import { Fragment, lazy, useMemo } from 'react';

import { useAuth } from '@/Routes/Auth/AppAuthContext';
// private routes
const Checklist = lazy(() => import(/* webpackChunkName: "Checklist" */ '@/Routes/Pages/loan/activities/checklists/checklist/checklist'));
const Validation = lazy(() => import(/* webpackChunkName: "Validation" */ '@/Routes/Pages/loan/activities/checklists/validation/validation'));
const PanelSequence = lazy(() => import(/* webpackChunkName: "PanelSequence" */ '@/Routes/Pages/loan/activities/panelSequence/panelSequence'));
const ActivityTemplateConfigurator = lazy(() => import(/* webpackChunkName: "ActivityTemplateConfigurator" */ '@/Routes/Pages/admin/activityTemplates/configurator/activityTemplateConfigurator'));
const ActivityPerformance = lazy(() => import(/* webpackChunkName: "ActivityPerformance" */ '@/Routes/Pages/admin/activityTemplates/performance/activityPerformance'));
const AdminList = lazy(() => import(/* webpackChunkName: "AdminList" */ '@/Routes/Pages/admin/lists/lists'));
const OrganizationDetail = lazy(() => import(/* webpackChunkName: "OrganizationDetail" */ '@/Routes/Pages/admin/organizations/OrganizationDetail'));
const CreateLoan = lazy(() => import(/* webpackChunkName: "CreateLoan" */ '@/Routes/Pages/loan/CreateLoan/CreateLoan'));
const Home = lazy(() => import(/* webpackChunkName: "Home" */ '@/Routes/Pages/home/Home'));
const LoanHome = lazy(() => import(/* webpackChunkName: "LoanHome" */ '@/Routes/Pages/loan/LoanHome'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '@/Routes/Pages/Login'));
const Reset = lazy(() => import(/* webpackChunkName: "Reset" */ '@/Routes/Pages/Reset'));
const SeedDeals = lazy(() => import(/* webpackChunkName: "SeedDeals" */ '@/Routes/Pages/SeedDeals'));
const FeeTemplateConfig = lazy(() => import(/* webpackChunkName: "FeeTemplateConfig" */ '@/Routes/Pages/admin/feeTemplate/FeeTemplateConfig'));
const ManageDocuments = lazy(() => import(/* webpackChunkName: "ManageDocuments" */ '@/Routes/Pages/loan/Document/ManageDocuments'));
const MultipleDocumentView = lazy(() => import(/* webpackChunkName: "MultipleDocumentView" */ '@/Routes/Pages/loan/Document/MultipleDocView'));
const MultipleDocumentViewPOC = lazy(() => import(/* webpackChunkName: "MultipleDocumentViewPOC" */ '@/Routes/Pages/loan/DocumentPOC/UpdateDocuments'));

export const PrivateSeedDeals = withAuthenticationRequired(SeedDeals);
export const PrivateReset = withAuthenticationRequired(Reset);
export const PrivateChecklist = withAuthenticationRequired(Checklist);
export const PrivateValidation = withAuthenticationRequired(Validation);
export const PrivatePanelSequence = withAuthenticationRequired(PanelSequence);
export const PrivateActivityTemplateConfigurator = withAuthenticationRequired(ActivityTemplateConfigurator);
export const PrivateActivityPerformance = withAuthenticationRequired(ActivityPerformance);
export const PrivateAdminList = withAuthenticationRequired(AdminList);
export const PrivateHome = withAuthenticationRequired(Home);
export const PrivateCreateLoan = withAuthenticationRequired(CreateLoan);
export const PrivateLoanPage = withAuthenticationRequired(LoanHome);
export const PrivateOrganization = withAuthenticationRequired(OrganizationDetail);
export const PrivateFeeTemplateConfig = withAuthenticationRequired(FeeTemplateConfig);
export const PrivateManageDocuments = withAuthenticationRequired(ManageDocuments);
export const PrivateMultipleDocumentView = withAuthenticationRequired(MultipleDocumentView);
export const PrivateMultipleDocumentViewPOC = withAuthenticationRequired(MultipleDocumentViewPOC);

interface PrivateRouteProps {
  children: ReactNode;
}
const PrivateRouteWrapper = (props: PrivateRouteProps) => {
  const { children } = props;
  const { authenticated: isAppAuthenticated, isLoadingWilqoAuth } = useAuth();
  const { isAuthenticated: isAuth0Authenticated, isLoading: isLoadingAuth0 } = useAuth0();

  const isLoadingAuth = useMemo(() => isLoadingWilqoAuth || isLoadingAuth0, [isLoadingAuth0, isLoadingWilqoAuth]);
  const isFullyAuthenticated = useMemo(() => isAppAuthenticated && isAuth0Authenticated, [isAppAuthenticated, isAuth0Authenticated]);

  if (isFullyAuthenticated || isLoadingAuth) {
    return (
      <Fragment key="private-router">
        {children}
      </Fragment>
    );
  }
  return <Login />;
};

export { PrivateRouteWrapper };
