import clsx from 'clsx';

import { Logo } from './Logo';
import { ProgressBar } from './ProgressBar';

interface IProgressBarProps {
  currentStep: number;
  stepsLength: number;
  logo: boolean;
  logoRedirect?: string;
  logoExternal?: boolean;
  border?: boolean;
}

const ProgressAppBar = (props: IProgressBarProps) => {
  const {
    border = false,
    currentStep,
    logo,
    logoExternal = false,
    logoRedirect = '/',
    stepsLength,
  } = props;

  const barCname = clsx(
    'flex items-center sticky top-0 w-full z-[2] py-0 px-[72px] bg-surface h-[72px]',
    {
      'border-b border-on-primary-stroke': border,
    },
  );

  return (
    <div className={barCname}>
      <div className="flex items-center flex-row h-[72px] relative w-full">
        {logo && (
          <div className="mt-6 mr-0 ml-0 mb-4">
            <Logo external={logoExternal} logoRedirect={logoRedirect} />
          </div>
        )}
        <div className="w-full">
          <ProgressBar
            currentStep={currentStep}
            stepsNumber={stepsLength}
          />
        </div>
      </div>
    </div>
  );
};

export { ProgressAppBar };
