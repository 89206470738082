import { useMutation } from '@tanstack/react-query';

import { SaveFieldItem, UpdateWilqoPartyMemberCommandRequest, UpdateWilqoPartyMemberCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useWilqoMessage } from '../useWilqoMessage';

const ADD_WILQO_PARTY_MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest';

type IUpdateWilqoPartyDto = Omit<UpdateWilqoPartyMemberCommandRequest.AsObject, 'businessProcessDomainId'>;

export const useUpdateWilqoPartyMember = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation([ADD_WILQO_PARTY_MESSAGE_NAME], async ({
    dealId,
    itemsList,
    pageId,
    partyType,
    scopeToken,
    widgetId,
  }: IUpdateWilqoPartyDto) => {
    const request = new UpdateWilqoPartyMemberCommandRequest();
    request.setDealId(dealId);
    request.setBusinessProcessDomainId(bpdId);
    request.setPageId(pageId);
    request.setPartyType(partyType);
    request.setScopeToken(scopeToken);
    request.setWidgetId(widgetId);
    request.setItemsList(itemsList.map((item) => {
      const saveField = new SaveFieldItem();
      saveField.setConsolidatedItemScopeToken(item.consolidatedItemScopeToken);
      saveField.setFieldId(item.fieldId);
      saveField.setValue(item.value ? DynamicDataElementMap(item.value) : undefined);
      return saveField;
    }));

    return sendMessage<UpdateWilqoPartyMemberCommandResponse.AsObject>(
      { msg: request, msgName: ADD_WILQO_PARTY_MESSAGE_NAME },
      UpdateWilqoPartyMemberCommandResponse,
    );
  });
};
