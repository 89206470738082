import { useMutation } from '@tanstack/react-query';

import { UpdateActivityStatusCommandRequest } from '@/API/Models/Wilqo_API_Activity_Commands_pb';
import type { ActivityStatus } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { UpdateConsumerConsentsCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.activity.UpdateActivityStatusCommandRequest';

interface UpdateObj {
  activityId: string;
  status: ActivityStatus;
}

export const useUpdateActivityStatus = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation(async (update: UpdateObj) => {
    const request = new UpdateActivityStatusCommandRequest();
    request.setActivityId(update.activityId);
    request.setBusinessProcessDomainId(bpdId);
    request.setStatus(update.status);
    request.setInstitutionId(institutionId);
    return sendMessage({
      msg: request,
      msgName: MESSAGE_NAME,
    }, UpdateConsumerConsentsCommandResponse);
  });
};
