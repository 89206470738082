import { BinaryReader } from 'google-protobuf';
import { useCallback, useState } from 'react';

import { RunFraudCommandRequest, RunFraudCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWebSocket } from '../../useWebSocket';
import { endpointResponseMiddleware } from '../../util';

const useRunFraud = () => {
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();
  const { commandResponse } = useWebSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const callRunFraud = useCallback(async (dealId: string) => {
    try {
      setIsLoading(true);
      const request = new RunFraudCommandRequest();
      const MESSAGE_NAME = 'wilqo.api.mortgage_integration.RunFraudCommandRequest';
      request.setDealId(dealId);
      request.setInstitutionId(institutionId);
      request.setBusinessProcessDomainId(bpdId);
      const responseMsg = await commandResponse({
        msg: request,
        msgName: MESSAGE_NAME,
      });

      const response = new RunFraudCommandResponse();
      RunFraudCommandResponse.deserializeBinaryFromReader(
        response,
        new BinaryReader(responseMsg.getValue()),
      );

      const responseObj = response.toObject();
      return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, response);
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setIsLoading(false);
    }
    return null;
  }, [institutionId, bpdId, commandResponse]);

  return {
    callRunFraud,
    error,
    isLoading,
  };
};

export { useRunFraud };
