import { CardListItem, FeesAdjustmentsCreditsSection, FeesOriginationDiscountSection, VerifiedListItem } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

const toVerifiedListItemFromObject = (obj: VerifiedListItem.AsObject): VerifiedListItem => {
  const verifiedListItem = new VerifiedListItem();

  if (obj.id) verifiedListItem.setId(obj.id);
  if (obj.caption) verifiedListItem.setCaption(obj.caption);
  if (obj.order) verifiedListItem.setOrder(obj.order);
  if (obj.type) verifiedListItem.setType(obj.type);
  if (obj.value) verifiedListItem.setValue(obj.value);
  if (obj.isEditable) verifiedListItem.setIsEditable(obj.isEditable);

  return verifiedListItem;
};

export const toCardListItemFromObject = (obj: CardListItem.AsObject): CardListItem => {
  const cardListItem = new CardListItem();

  if (obj.id) cardListItem.setId(obj.id);
  if (obj.caption) cardListItem.setCaption(obj.caption);
  if (obj.order) cardListItem.setOrder(obj.order);
  if (obj.infoCardType) cardListItem.setInfoCardType(obj.infoCardType);
  if (obj.verifiedListList) {
    obj.verifiedListList.forEach((verifiedListItem) => {
      cardListItem.addVerifiedList(toVerifiedListItemFromObject(verifiedListItem));
    });
  }
  if (obj.value) cardListItem.setValue(obj.value);
  if (obj.type) cardListItem.setType(obj.type);
  if (obj.isEditable) cardListItem.setIsEditable(obj.isEditable);

  return cardListItem;
};

export const toFeesAdjustmentsCreditsSectionFromObject = (obj: FeesAdjustmentsCreditsSection.AsObject): FeesAdjustmentsCreditsSection => {
  const feesAdjustmentsCreditsSection = new FeesAdjustmentsCreditsSection();

  if (obj.sectionName) feesAdjustmentsCreditsSection.setSectionName(obj.sectionName);
  if (obj.sectionType) feesAdjustmentsCreditsSection.setSectionType(obj.sectionType);
  if (obj.order) feesAdjustmentsCreditsSection.setOrder(obj.order);
  if (obj.cardListList) {
    obj.cardListList.forEach((cardListItem) => {
      feesAdjustmentsCreditsSection.addCardList(toCardListItemFromObject(cardListItem));
    });
  }

  return feesAdjustmentsCreditsSection;
};

export const toFeesOriginationDiscountSectionFromObject = (obj: FeesOriginationDiscountSection.AsObject): FeesOriginationDiscountSection => {
  const feesOriginationDiscountSection = new FeesOriginationDiscountSection();

  if (obj.sectionName) feesOriginationDiscountSection.setSectionName(obj.sectionName);
  if (obj.sectionType) feesOriginationDiscountSection.setSectionType(obj.sectionType);
  if (obj.order) feesOriginationDiscountSection.setOrder(obj.order);
  if (obj.cardListList) {
    obj.cardListList.forEach((cardListItem) => {
      feesOriginationDiscountSection.addCardList(toCardListItemFromObject(cardListItem));
    });
  }

  return feesOriginationDiscountSection;
};
