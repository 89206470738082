import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { LoanFeeTemplateUpdateCommandRequest, LoanFeeTemplateUpdateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import type { LoanFeeTemplate } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest';

const useUpdateLoanFeeTemplate = () => {
  const { commandResponse } = useBackend();
  const auth = useAuth();

  const updateLoanFeeTemplateCommand = async (updateObject: LoanFeeTemplate.AsObject) => {
    const { defaultFeeIncludedInAprIndicator, defaultFeePaidToPartyType, defaultFeePaidToType, defaultFeePaidToWilqoPartyType,
      defaultFeePaymentPaidByType, defaultFeePaymentPaidByTypeThirdPartyName, defaultFeePaymentTimingType,
      defaultFeeToleranceCategoryType, description, id, integratedDisclosureSectionType, name,
      regulationZPointsAndFeesIndicator, requiredProviderOfServiceIndicator, type } = updateObject;
    const request = new LoanFeeTemplateUpdateCommandRequest();
    request.setBusinessProcessDomainId(auth.secureAuthValues.authorizedBusinessProcessDomainIds[0]);
    if (id) request.setId(id);
    request.setDefaultFeeIncludedInAprIndicator(defaultFeeIncludedInAprIndicator);
    request.setDefaultFeePaidToPartyType(defaultFeePaidToPartyType);
    request.setDefaultFeePaidToType(defaultFeePaidToType);
    request.setDefaultFeePaidToWilqoPartyType(defaultFeePaidToWilqoPartyType);
    request.setDefaultFeePaymentPaidByTypeThirdPartyName(defaultFeePaymentPaidByTypeThirdPartyName);
    request.setDefaultFeePaymentPaidByType(defaultFeePaymentPaidByType);
    request.setDefaultFeePaymentTimingType(defaultFeePaymentTimingType);
    request.setDefaultFeeToleranceCategoryType(defaultFeeToleranceCategoryType);
    request.setDescription(description);
    request.setIntegratedDisclosureSectionType(integratedDisclosureSectionType);
    request.setName(name);
    request.setRegulationZPointsAndFeesIndicator(regulationZPointsAndFeesIndicator);
    request.setRequiredProviderOfServiceIndicator(requiredProviderOfServiceIndicator);
    request.setType(type);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new LoanFeeTemplateUpdateCommandResponse();
    LoanFeeTemplateUpdateCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(updateLoanFeeTemplateCommand);
};

export { useUpdateLoanFeeTemplate };
