import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { PanelElementResponse } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { PutPanelSequenceResponseCommandRequest, PutPanelSequenceResponseCommandResponse } from '@/API/Models/Wilqo_API_Activity_Commands_pb';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.PutPanelSequenceResponseCommandRequest';

interface UpdateObject {
  panelSequenceId: string;
  panelId: string;
  elements: Array<PanelElementResponse.AsObject>;
}

const usePutPanelSequenceProgress = () => {
  const { commandResponse } = useBackend();

  const PutPanelSequenceProgressCommand = async (updateObject: UpdateObject) => {
    const { elements, panelId, panelSequenceId } = updateObject;
    const request = new PutPanelSequenceResponseCommandRequest();
    request.setPanelSequenceId(panelSequenceId);
    elements.map((el) => {
      const element = new PanelElementResponse();
      element.setId(el.id);
      if (el.value) {
        element.setValue(DynamicDataElementMap(el.value));
      }
      element.setPanelId(panelId);
      return element;
    }).forEach((x) => request.addPanelElements(x));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new PutPanelSequenceResponseCommandResponse();
    PutPanelSequenceResponseCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };
  return useMutation(PutPanelSequenceProgressCommand);
};

export { usePutPanelSequenceProgress };
