import type { TextTemplate } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';

import { renderValue } from './renderableHelper';

export const renderTemplate = (template: TextTemplate.AsObject) => {
  const { missingFieldReplacementValue, text, textFieldsList } = template;
  const regex = /\{(.*?)}/g;
  return text.replace(regex, (match) => {
    const renderableValue = textFieldsList.find((field) => field.fieldId === match)?.fieldValue;
    return renderableValue ? renderValue(renderableValue) : missingFieldReplacementValue;
  });
};
