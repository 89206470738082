import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { MenuProvider } from './MenuContext';

export const MenuRoot = (props: DropdownMenu.DropdownMenuProps) => (
  <MenuProvider>
    <DropdownMenu.Root modal={false} {...props}>
      {props.children}
    </DropdownMenu.Root>
  </MenuProvider>
);
