import clsx from 'clsx';

import type { AsyncValidation } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import type { IButtonProps } from '@/Components/Atoms/Button';
import { Button } from '@/Components/Atoms/Button';
import type { DynamicDataElementValues, FormProgress, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider, useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { PanelElementExtended } from '@/Utils/Helpers/getPanelElement';

import type { IButtonGroupProps } from './ButtonGroup';
import { ButtonGroup } from './ButtonGroup';
import { PanelElementRenderer } from './PanelElementRenderer';

interface PanelElementFormProps {
  panelElements: Array<PanelElementExtended>;
  onSubmit: (formData: DynamicDataElementValues, extra: SubmitValues) => void;
  className?: string;
  buttonGroupProps?: IButtonGroupProps;
  submitProps?: IButtonProps;
  progress?: FormProgress;
  submitOnChange?: boolean;
  asyncValidations?: Array<AsyncValidation.AsObject>;
}

const SubmitButton = (props: IButtonProps) => {
  const { hasError } = useDynamicForm();

  return (
    <Button {...props} disabled={props.disabled || Boolean(hasError)} />
  );
};

const PanelElementForm = (props: PanelElementFormProps) => {
  const {
    asyncValidations,
    buttonGroupProps,
    className,
    onSubmit,
    panelElements,
    progress,
    submitOnChange = false,
    submitProps,
  } = props;

  const justifyContent = buttonGroupProps?.justifyContent || 'start';

  return (
    <DynamicFormProvider asyncValidations={asyncValidations} onSubmit={onSubmit} progress={progress} submitOnChange={submitOnChange}>
      {({ values }) => {
        const flatPanelsWithChildren = panelElements.flatMap((panelElement) => [
          panelElement,
          ...values[panelElement.id]?.value?.childPanelElement
            ? [{ ...values[panelElement.id]?.value?.childPanelElement }]
            : [],
        ]);

        return (
          <div className="flex flex-col">
            <div className={clsx('grid grid-cols-1 gap-2', className)}>
              {flatPanelsWithChildren.map((panelElement) => <PanelElementRenderer key={panelElement.id} variant="default" {...panelElement} />)}
            </div>
            <div className={
              clsx('flex flex-col items-end sm:items-center sm:flex-row w-full mt-6 gap-y-2',
                {
                  'justify-center': justifyContent === 'center',
                  'justify-end': justifyContent === 'end',
                  'justify-start': justifyContent === 'start',
                })
            }
            >
              {buttonGroupProps && (
                <ButtonGroup {...buttonGroupProps} className="flex-col items-end sm:items-center sm:flex-row mr-3" />
              )}
              {submitProps && (
                <SubmitButton {...submitProps} />
              )}
              {!buttonGroupProps && !submitProps && (
                <SubmitButton label="Submit" type="submit" />
              )}
            </div>
          </div>
        );
      }}
    </DynamicFormProvider>
  );
};

export { PanelElementForm };
