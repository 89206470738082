import clsx from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { convertRichText } from '@/Utils/Helpers/richTextConversion';

import { HelperText } from '../HelperText';
import { Icon } from '../Icon';
import type { SelectionSurfaceVariant } from '../SelectionSurface';
import { SelectionSurface } from '../SelectionSurface';
import { Typography } from '../Typography';

export interface ComponentProps {
  className?: string;
  disabled?: boolean;
  variant?: SelectionSurfaceVariant;
  onClick: (value: boolean) => void;
  value: boolean;
  label: ReactNode | string;
  inputRef?: React.Ref<any>;
  errorMessage?: string;
  required?: boolean;
  caption?: ReactNode | string;
  focused?: boolean;
  id?: string;
}

const Checkbox = forwardRef<HTMLInputElement, ComponentProps>((props: ComponentProps, ref) => {
  const {
    caption,
    className,
    disabled,
    errorMessage,
    focused,
    id,
    label,
    onClick,
    value,
    variant,
  } = props;

  const handleClick = () => {
    onClick(!value);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      handleClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={
        clsx(
          'relative w-full',
        )
      }
      data-testid={`checkbox-${id}`}
      onClick={handleClick}
    >
      <SelectionSurface
        className={className}
        disabled={disabled}
        focused={focused}
        selected={Boolean(value)}
        variant={variant}
      >
        <div
          className="flex items-center rounded w-full"
        >
          <input
            ref={ref}
            checked={Boolean(value)}
            className="absolute w-0 h-0"
            disabled={disabled}
            onChange={() => {}}
            onKeyDown={handleEnter}
            type="checkbox"
          />
          <div
            className={
              clsx(
                'flex',
                {
                  'items-center': !caption,
                  'items-start': caption,
                },
              )
            }
          >
            <Icon
              className={clsx(
                'mr-2 min-h-[24px] min-w-[24px]',
                {
                  'text-on-surface-disabled': disabled,
                  'text-on-surface-inactive': !value && !disabled,
                  'text-primary': value && !disabled,
                },
              )}
              icon={value ? 'CheckBox' : 'CheckBoxBlank'}
            />
            <div className="flex flex-col">
              <Typography className={clsx({ 'text-on-surface-disabled': disabled })} variant="body2">
                {convertRichText(label)}
              </Typography>
            </div>
          </div>
        </div>
      </SelectionSurface>
      {(errorMessage || caption) && <HelperText message={errorMessage || caption} />}
    </div>
  );
});

export default Checkbox;
