import './index.css';

import { setTag } from '@sentry/browser';
import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { createRoot } from 'react-dom/client';

import { AppWithErrorBoundary } from './App';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

if (process.env.NODE_ENV !== 'development') {
  init({
    debug: false,
    denyUrls: [
      '/localhost\\.com/',
      'wilqo.dev.wilqo-app.com',
      'wilqo.debug.wilqo-app.com',
      'wilqo.stg.wilqo-app.com',
      'wilqo.test.wilqo-app.com',
    ],
    dsn: 'https://f4a44dc55d25460f81dd7032444222ea@o962989.ingest.sentry.io/5911197',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    release: process.env.VERSION,
    tracesSampleRate: 0.2,
  });

  setTag('host', window.location.hostname);
}

const container = document.getElementById('app');
const root = createRoot(container!);

const StartUp = async () => {
  root.render(
    <AppWithErrorBoundary />,
  );
};

StartUp();
