import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useParams } from 'react-router';

import { GetFeesComparisonsQueryRequest, GetFeesComparisonsQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const FEES_COMPARISON_MESSAGE_NAME = 'wilqo.api.mortgage.GetFeesComparisonsQueryRequest';

const useGetFeesComparison = () => {
  const { commandResponse } = useBackend();
  const { loanId = '' } = useParams();

  return useQuery([FEES_COMPARISON_MESSAGE_NAME, loanId], async () => {
    const request = new GetFeesComparisonsQueryRequest();
    request.setDealId(loanId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: FEES_COMPARISON_MESSAGE_NAME,
    });
    const response = new GetFeesComparisonsQueryResponse();
    GetFeesComparisonsQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();

    return endpointResponseMiddleware(
      FEES_COMPARISON_MESSAGE_NAME,
      responseObj.error,
      {
        ...responseObj,
      },
    );
  });
};

export { useGetFeesComparison };
