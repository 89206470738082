import { useQueryClient } from '@tanstack/react-query';

import type { ToggleConsentSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { TriggerType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { ConsentField, ConsentFieldType, SaveFieldItem, SaveToggleConsentCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { CIRCUIT_MESSAGE_NAME } from '@/API/Queries/mortgageDynamicData/useCircuitData';
import { useSaveToggleConsent } from '@/API/Queries/mortgageDynamicData/useSaveToggleConsent';
import { useCircuit } from '@/Components/Circuits/Hooks/useCircuit';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { useWidgetContext } from '@/Components/Widgets/content/WidgetContext';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { fromBool, toBool } from '@/Utils/Helpers/dynamicDataElementHelper';
import type { PanelElementExtended } from '@/Utils/Helpers/getPanelElement';
import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import Component from './Checkbox.component';

export type CheckboxVariantType = 'default' | 'transparent';

export interface ICheckboxProps {
  className?: string;
  variant?: CheckboxVariantType;
  panelElement: PanelElementExtended;
}

const Checkbox = (props: ICheckboxProps) => {
  const {
    className,
    panelElement: {
      disabled,
      enrichedHeaderText,
      enrichedSummaryText,
      headerText,
      id,
      path,
      requirement,
      summaryText: caption,
    },
    variant,
  } = props;
  const { mutateAsync } = useSaveToggleConsent();
  const queryClient = useQueryClient();
  const bpdId = useBPDId();
  const { version } = useDynamicContext();
  const { loanId, scopeToken } = useDynamicLoanInfo();
  const { pageId } = useWidgetContext();
  const { currentCircuit } = useCircuit();
  const { conditionalAsyncSubmitConfig: consentsConfigurations, values } = useDynamicForm();
  const [value, setValue, { disabled: conditionallyDisabled, errorMessage, focused, hidden, ref }] = useDynamicFormRegister<boolean>(
    id,
    { fromDynamic: toBool, toDynamic: (v = false) => fromBool(v) },
    { initialValue: false, requirement },
  );

  const getFieldType = (fieldKey: keyof ToggleConsentSettings.AsObject) => {
    switch (fieldKey) {
      case 'consentProviderPanelElementId':
        return ConsentFieldType.CONSENT_FIELD_TYPE_TIMESTAMP;
      case 'clientIpPanelElementId':
        return ConsentFieldType.CONSENT_FIELD_TYPE_IP;
      case 'methodTypePanelElementId':
        return ConsentFieldType.CONSENT_FIELD_TYPE_METHOD;
      case 'checkboxPanelElementId':
        return ConsentFieldType.CONSENT_FIELD_TYPE_CONSENT;
      default:
        return ConsentFieldType.CONSENT_FIELD_TYPE_UNKNOWN;
    }
  };

  const getFieldItem = (id: string, value: DynamicDataElement | undefined) => {
    const saveItem = new SaveFieldItem();
    saveItem.setConsolidatedItemScopeToken(scopeToken);
    saveItem.setFieldId(id);
    saveItem.setValue(value);
    return saveItem;
  };

  const submitConsent = async (triggerType: TriggerType, value: boolean) => {
    const consentsToBeSaved = consentsConfigurations?.filter((config) => config.panelElementId === id && config.triggerType === triggerType);

    if (!consentsToBeSaved?.length) return;

    const consentFieldsList = consentsToBeSaved.reduce((consentFieldsList, consent) => {
      if (!consent.toggleConsent) return [...consentFieldsList];

      const fieldsList = Object.keys(consent.toggleConsent).reduce((acc, fieldKey) => {
        const fieldId = consent.toggleConsent?.[fieldKey as keyof ToggleConsentSettings.AsObject] || '';
        const panelElement = values[fieldId].fieldConfig?.panelElement;
        const hasDynamicValue = fieldKey === 'checkboxPanelElementId';

        const consentField = new ConsentField();
        consentField.setHasDynamicValue(hasDynamicValue);
        consentField.setPanelElementId(panelElement?.id || '');
        consentField.setConsentFieldType(getFieldType(fieldKey as keyof ToggleConsentSettings.AsObject));

        if (hasDynamicValue) {
          const saveItem = getFieldItem(panelElement?.virtualFieldIdentifier?.value || '', fromBool(value));
          consentField.setDynamicValue(saveItem);
        }

        return [...acc, consentField];
      }, [] as ConsentField[]);

      return [...consentFieldsList, ...fieldsList];
    }, [] as ConsentField[]);

    const consentSource = currentCircuit?.circuitId
      ? SaveToggleConsentCommandRequest.ToggleConsentSourceEnum.TOGGLE_CONSENT_SOURCE_CIRCUIT_PAGE
      : SaveToggleConsentCommandRequest.ToggleConsentSourceEnum.TOGGLE_CONSENT_SOURCE_LOAN_PAGE;

    await mutateAsync({
      bpdId,
      consentFieldsList,
      consentSource,
      dealId: loanId,
      dealVersion: version,
      pageId: pageId || currentCircuit?.circuitId || '',
      scopeToken: scopeToken || currentCircuit?.scopeToken,
    }, {
      onSuccess() {
        if (currentCircuit?.circuitId) {
          queryClient.invalidateQueries([CIRCUIT_MESSAGE_NAME, currentCircuit?.circuitId]);
        }
      },
    });
  };

  useOnClickOutside(ref, (value) => {
    submitConsent(TriggerType.TRIGGER_TYPE_ON_BLUR, value);
  });

  const handleClick = (value: boolean) => {
    if (!disabled) {
      setValue(value, value ? 'true' : 'false', path);
      submitConsent(TriggerType.TRIGGER_TYPE_ON_CHANGE, value);
    }
  };

  if (hidden) return null;
  return (
    <Component
      ref={ref}
      caption={enrichedSummaryText || caption}
      className={className}
      disabled={disabled || conditionallyDisabled}
      errorMessage={errorMessage}
      focused={focused}
      id={id}
      label={enrichedHeaderText || headerText}
      onClick={handleClick}
      required={requirement?.required}
      value={value || false}
      variant={variant}
    />
  );
};

export default Checkbox;
