import type { ComponentProps } from './AddressAutoComplete.component';
import Component from './AddressAutoComplete.component';
import type { AddressAutoCompleteProps as DDEProps } from './AddressAutoComplete.dde';
import DDE from './AddressAutoComplete.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

export type AddressAutoCompleteProps = ExtendedComponentProps | ExtendedDDEProps;

const AddressAutoComplete = (props: AddressAutoCompleteProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { AddressAutoComplete };
