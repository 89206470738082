import clsx from 'clsx';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';

import type { IconProps } from './Icon';
import { Icon } from './Icon';
import { Typography } from './Typography';

export type TextFieldVariant = 'default' | 'dense-variant' | 'dense' | 'outlined' | 'text-area';
export type FieldGenericType = HTMLInputElement | HTMLTextAreaElement;

export interface TextFieldProps extends React.InputHTMLAttributes<any> {
  placeholder: string;
  variant?: TextFieldVariant;
  message?: string;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
  onFocus?: (event: React.FocusEvent<any>) => void;
  value?: any;
  error?: boolean;
  disabled?: boolean;
  disableText?: boolean;
  leadingIcon?: IconProps;
  trailingIcon?: IconProps;
  isPrivate?: boolean;
  children?: React.ReactNode;
  focused?: boolean;
  isLoading?: boolean;
  isDropdown?: boolean;
}

const TextField = forwardRef<any, TextFieldProps>((props: TextFieldProps, ref) => {
  const {
    autoComplete,
    children,
    disableText = false,
    disabled = false,
    error = false,
    focused,
    isDropdown = false,
    isLoading = false,
    isPrivate = false,
    leadingIcon,
    message,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    trailingIcon,
    value,
    variant = 'default',
    ...inputsProps
  } = props;

  const hasValue = useMemo(() => (value !== undefined) && value !== '', [value]);
  const [isFocused, setIsFocused] = useState(focused);
  const [isEditing, setIsEditing] = useState(hasValue);

  useEffect(() => setIsFocused(focused), [focused]);

  const containerRef = useRef(null);

  useEffect(() => {
    if (hasValue && !isEditing) setIsEditing(true);
  }, [hasValue, isEditing]);

  useOnClickOutside(containerRef, () => {
    if (!hasValue && isEditing) {
      setIsEditing(false);
    }
  });

  const handleOnFocus = (event: React.FocusEvent<FieldGenericType>) => {
    setIsFocused(true);
    event.target.select();
    if (onFocus) onFocus(event);
  };

  const handleOnBlur = (event: React.FocusEvent<FieldGenericType>) => {
    setIsFocused(false);
    if (onBlur) onBlur(event);
  };

  const renderInputPlaceholder = () => {
    if (children) return children;
    if (props.variant === 'text-area') {
      return (
        <textarea
          ref={ref}
          cols={10}
          data-testid={`input-${inputsProps.id}`}
          disabled={disabled}
          onBlur={handleOnBlur}
          onChange={onChange}
          onFocus={handleOnFocus}
          placeholder={!isEditing ? `${placeholder}${required ? '*' : ''}` : undefined}
          readOnly={disabled}
          type={isPrivate ? 'password' : 'text'}
          value={value}
          {...inputsProps}
          autoComplete={autoComplete ?? 'new-password'}
          className={clsx(inputsProps.className,
            'h-48 resize-none text-body1Size leading-body1Height tracking-body1Spacing placeholder-on-surface-inactive outline-none bg-transparent select-all w-full',
            {
              'text-on-surface-active': !disabled,
              'text-on-surface-disabled': disabled,
            })}
        />
      );
    }
    return (
      <input
        ref={ref}
        data-testid={`input-${inputsProps.id}`}
        disabled={disabled}
        name={`input-${inputsProps.id}`}
        onBlur={handleOnBlur}
        onChange={!disableText ? onChange : undefined}
        onFocus={handleOnFocus}
        placeholder={!isEditing ? `${placeholder}${required ? '*' : ''}` : undefined}
        readOnly={disabled}
        type={isPrivate ? 'password' : 'text'}
        value={value}
        {...inputsProps}
        autoComplete={autoComplete ?? 'new-password'}
        className={clsx(inputsProps.className,
          'placeholder-on-surface-inactive outline-none bg-transparent select-all w-full',
          {
            'block !text-body2Size leading-body2Height tracking-body2Spacing': variant === 'dense-variant' || variant === 'dense',
            'text-body1Size leading-body1Height tracking-body1Spacing': variant !== 'dense-variant' && variant !== 'dense',
            'text-on-surface-active': !disabled || disableText,
            'text-on-surface-disabled': disabled && !disableText,
          })}
      />
    );
  };

  const handleOnClick = (event: any) => {
    if (event.code === 'Enter' && isDropdown) event.preventDefault();
    if (!disabled) {
      setIsEditing(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref?.current?.focus();
    }
  };

  const renderTrailingIcon = () => {
    if (isLoading) return <Icon className={clsx('animate-spin h-4')} icon="Loop" />;
    if (trailingIcon && !disabled) return <Icon {...trailingIcon} />;
    return null;
  };

  return (
    <div
      className={clsx('relative', props.className)}
      onClick={handleOnClick}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onFocus={() => ref?.current.focus()}
      onKeyDown={handleOnClick}
      role="button"
      tabIndex={0}
    >
      {variant === 'outlined' && isEditing && (
        <Typography className="absolute select-none -top-2 left-4 bg-surface px-1" variant="caption">{placeholder}</Typography>
      )}
      <div
        ref={containerRef}
        className={clsx('flex flex-row rounded-t-[4px] items-center', {
          '!bg-on-surface-states-disabled !hover:bg-on-surface-disabled cursor-not-allowed [&_*]:cursor-not-allowed': disabled,
          'bg-surface hover:bg-on-surface-states-hover': variant === 'dense',
          'bg-surface-variant hover:bg-on-surface-states-hover': variant === 'dense-variant',
          'bg-surface-variant hover:bg-on-surface-states-resting active:on-surface-states-focus': variant === 'default' || variant === 'text-area',
          'border border-on-surface-stroke !rounded-full h-8': variant === 'outlined',
          'border-[2px] border-danger': variant === 'outlined' && error,
          'border-b border-on-surface-inactive': (variant === 'dense-variant' || variant === 'dense' || variant === 'default' || variant === 'text-area') && !error,
          'border-b-[2px] border-b-primary-on-surface': isFocused,
          'border-b-[2px] border-danger': (variant === 'dense' || variant === 'default' || variant === 'text-area') && error,
          'p-2': variant === 'dense' || variant === 'dense-variant',
          'p-4': variant !== 'dense' && variant !== 'dense-variant',
          'py-2': variant === 'default' && isEditing,
        })}
      >
        {leadingIcon && (
          <Icon
            className={clsx('mr-4', {
              'text-on-surface-disabled': disabled,
              'text-on-surface-inactive': !disabled,
            })}
            {...leadingIcon}
          />
        )}
        <div className="flex-1 items-center">
          {isEditing && (variant === 'default' || variant === 'text-area') && (
            <Typography
              className="text-on-surface-inactive text-left"
              variant="caption"
            >
              {placeholder}
            </Typography>
          )}
          {renderInputPlaceholder()}
        </div>
        {renderTrailingIcon()}
      </div>
      {message && (
        <Typography
          className={clsx('mt-1', {
            'ml-2': variant === 'dense',
            'ml-4': variant !== 'dense',
            'text-danger': error,
            'text-on-surface-disabled': !error && disabled,
            'text-on-surface-inactive': !error && !disabled,
          })}
          variant="caption"
        >
          {message}
        </Typography>
      )}
    </div>
  );
});

export { TextField };
