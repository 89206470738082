import clsx from 'clsx';
import Markdown from 'react-markdown';

import type { LoanPageBannerMessageSetting } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Banner_pb';
import { UxDisplayStatusEnum } from '@/API/Models/Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb';
import { Icon } from '@/Components/Atoms/Icon';
import { Typography } from '@/Components/Atoms/Typography';
import { renderTemplate } from '@/Utils/Helpers/renderTemplateString';
import { cn } from '@/Utils/Helpers/uiBuilders';

import { DynamicActions } from '../actions/DynamicActions';

interface Props {
  message?: LoanPageBannerMessageSetting.AsObject;
}

export const BannerMessage = (props: Props) => {
  const renderMessage = () => {
    if (props.message) {
      if (props.message.template) {
        return renderTemplate(props.message.template);
      }

      return props.message.message;
    }

    return '';
  };

  return (
    <div
      className={cn(
        'flex flex-row tablet:py-4 tablet:px-12 p-4 bg-surface items-center border-b border-b-on-surface-stroke',
        {
          'bg-danger-on-surface text-on-danger-solid': props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_CRITICAL,
          'bg-status-danger': props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER,
          'bg-status-warning': props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_WARNING,
        },
      )}
    >
      <Icon
        className={clsx('mr-6 p-2 bg-surface', {
          '[&_svg]:text-danger-on-surface': props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_CRITICAL,
          '[&_svg]:text-on-surface-solid': props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_WARNING
            || props.message?.colorVariant === UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_DANGER,
        })}
        icon={props.message?.icon || ''}
        variant="fixed-background"
      />
      <Typography className="flex-1" variant="body2">
        <Markdown>{renderMessage()}</Markdown>
      </Typography>
      <DynamicActions
        actions={props.message?.action ? [props.message?.action] : []}
      />
    </div>
  );
};
