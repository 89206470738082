import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import type {
  IndividualPrepaidPayment,
  LoanFeeCategoryTypeEnum,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { Column } from '@/API/Queries/mortgage/useLoanFees';
import { Card } from '@/Components/Atoms/Card/Card';
import { Chip } from '@/Components/Atoms/Chip';
import { Icon } from '@/Components/Atoms/Icon';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import {
  ColumnEnum,
  emptyValue,
  INDIVIDUAL_FEES_COLUMNS,
  mapPaymentChanges,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import type { TableContent } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import {
  createTableContent, mapPayerToPayerPaid,
  PAYMENT_PORTIONS_OF_FEE,
  validatePaymentValues,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import { convertProtoToCurrency } from '@/Utils/Helpers/numberFormatter';

interface IFeePaidBy {
  fee: LoanPrepaidFee.AsObject;
  prepaidPayment?: IndividualPrepaidPayment.AsObject;
  total?: number;
  categoryType: LoanFeeCategoryTypeEnum;
  addError: (error: string) => void;
  removeError: (error: string) => void;
  isInErrorState: boolean;
  errorState: string[];
}

export const FeePaidBy: React.FC<IFeePaidBy> = ({ addError, categoryType, errorState, fee, prepaidPayment, removeError, total }) => {
  const [flag, setFlag] = useState<{ value: number }>({ value: 0 });

  const { handleBlurInput, sellerTotalExceeded } = useFeesContext();
  const [dataTable, setDataTable] = useState<Array<TableContent>>();

  useEffect(() => {
    if (!fee || !prepaidPayment || !total) return;
    setDataTable(createTableContent(prepaidPayment));
  }, [fee, prepaidPayment, total, flag]);

  const removeOtherChip = (payer: string) => !(payer === 'Other' && categoryType === 2);

  const handleSendUpdatedValues = (updatedValue: string, column: string, editedRowData: TableContent): void => {
    setFlag((prevObj: { value: number }): { value: number } => ({ ...prevObj, value: prevObj.value + 1 }));
    if (PAYMENT_PORTIONS_OF_FEE.includes(column)) {
      mapPaymentChanges(updatedValue, column, editedRowData, fee, categoryType);
    } else {
      handleBlurInput(updatedValue, { key: mapPayerToPayerPaid(editedRowData.payer) }, fee, undefined, undefined, categoryType);
    }
    validatePaymentValues(editedRowData.payer, prepaidPayment, addError, removeError);
  };

  const renderChip = ({ payer }: TableContent) => (
    <td
      key={payer}
      className="text-left px-3 py-4 border-r border-r-on-surface-stroke min-w-[250px]"
    >
      <Chip
        label={payer}
        selected
      />
    </td>
  );

  const renderTooltip = (column: Column, rowData: TableContent, value: number | string) => {
    const negativePaidAtClosingError = Number(value) < 0;

    return (
      <td
        key={column.id}
        className="px-3 min-w-[200px] border-l"
      >
        <div className="flex">
          {(sellerTotalExceeded && rowData.payer === 'Seller') && (
            <div>
              <Tooltip message="Seller credit exceeded Total amount paid">
                <Icon className="!text-danger" icon="Error" />
              </Tooltip>
            </div>
          )}
          <div>
            {
              negativePaidAtClosingError && (
                <div>
                  <Tooltip message="Paid at closing cannot be negative">
                    <Icon className="!text-danger" icon="Error" />
                  </Tooltip>
                </div>
              )
            }
          </div>
          <div className="flex-grow">
            <Typography className="text-on-surface-active text-center" variant="body2">
              {convertProtoToCurrency(value)}
            </Typography>
          </div>
        </div>
      </td>
    );
  };

  const renderDefaultInput = (column: Column, rowData: TableContent, value: number | string) => {
    const errorKey = column.id.charAt(0).toUpperCase() + column.id.slice(1);
    const isInErrorState = errorState.some((error) => error.includes(errorKey));

    return (
      <td
        key={column.id}
        className={clsx('px-3 py-4 border', {
          '!border-danger': isInErrorState,
          'border-r-0': !isInErrorState,
        })}
        id={column.id}
      >
        <Input
          disabled={((column.id !== ColumnEnum.CREDIT_AMOUNT && rowData.payer === 'Seller')
           || (column.id === ColumnEnum.CREDIT_AMOUNT && rowData.payer === 'Borrower'))}
          id={rowData.payer}
          mask={MaskType.CURRENCY}
          onBlur={(e) => handleSendUpdatedValues(e.target.value, column.id, rowData)}
          value={String(value ? convertProtoToCurrency(value) : emptyValue())}
        />
      </td>
    );
  };

  return (
    <Card
      defaultOpen
      headerProps={{
        title: 'Fee paid by',
      }}
      variant="border"
    >
      <div>
        <table className="w-full p-2">
          <thead className="border-b border-b-on-surface-stroke">
            <tr>
              {INDIVIDUAL_FEES_COLUMNS.map((column, index) => (
                <th
                  key={column.id}
                  className={clsx('px-3 py-3 text-center border-r border-r-on-surface-stroke', {
                    '!border-r-0': index === INDIVIDUAL_FEES_COLUMNS.length - 1,
                    '!text-left w-[300px]': index === 0,
                  })}
                  scope="col"
                >
                  <Typography className="uppercase text-on-surface-inactive" variant="overline">
                    {column.header}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {
              dataTable?.map((rowData: TableContent) => removeOtherChip(rowData.payer) && (
                <tr
                  key={rowData.payer}
                  className="border-b border-b-on-surface-stroke"
                >
                  {INDIVIDUAL_FEES_COLUMNS.map((column, index) => {
                    const value = rowData[column.id as keyof typeof rowData] as number;

                    if ([index === 0].includes(true)) {
                      return renderChip(rowData);
                    }
                    if ([index === INDIVIDUAL_FEES_COLUMNS.length - 1].includes(true)) {
                      return renderTooltip(column, rowData, value);
                    }
                    return renderDefaultInput(column, rowData, value);
                  })}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Card>
  );
};
