/* eslint-disable no-restricted-imports */
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { NavigateOptions, To } from 'react-router';
import { useNavigate as useRouterNavigate } from 'react-router';
import type { URLSearchParamsInit } from 'react-router-dom';
import { useSearchParams as useRouterSearchParams } from 'react-router-dom';
import { useEvent } from 'react-use-event-hook';

import type { IButtonProps } from '@/Components/Atoms/Button';
import type { DialogProps } from '@/Components/Atoms/Dialog';
import { Dialog } from '@/Components/Atoms/Dialog';

interface PromptAction extends Omit<IButtonProps, 'onClick'> {
  onClick: (event: any, callback: any) => void;
}

export interface Prompt extends Omit<DialogProps, 'actions'> {
  id: string;
  actions: Array<PromptAction>;
}

interface NavigationState {
  navigate: (ctx: any) => (to: To | number, options?: NavigateOptions | undefined, routerNavigateWithContext?: any) => void;
  setPrompt: (prompt: Prompt | string) => void;
  searchParams?: URLSearchParams;
  setSearchParams?: any;
  prompts?: Record<string, Prompt | undefined>;
}

const initialState: NavigationState = {
  navigate: () => () => {},
  setPrompt: () => {},
};

const NavigationContext = createContext(initialState);
NavigationContext.displayName = 'NavigationContext';

const NavigationProvider = (props: { children: React.ReactNode }) => {
  const [prompts, setPrompts] = useState<Record<string, Prompt | undefined>>({});
  const [showPrompt, setShowPrompt] = useState(false);
  const [searchParams, setSearchParams] = useRouterSearchParams();
  const callbackRef = useRef<any>();

  const promptKey = useMemo(() => Object.keys(prompts).find((key) => prompts[key]), [prompts]);
  const prompt = useMemo(() => (promptKey ? prompts[promptKey] : undefined), [promptKey, prompts]);

  const handleNavigationAttempt = useEvent((callback: any) => {
    if (Object.keys(prompts).some((key) => prompts[key])) {
      setShowPrompt(true);
      callbackRef.current = callback;
    } else {
      callback();
    }
  });

  const navigat = useEvent(
    (
      navCtx: any,
      to: To | number, options?: NavigateOptions | undefined,
    ) => handleNavigationAttempt(() => navCtx(to, options)),
  );

  const navigate = (ctx: any) => (to: To | number, options?: NavigateOptions | undefined) => navigat(ctx, to, options);

  const handleSetSearchParam = (nextInit: URLSearchParamsInit, navigateOptions?: {
    replace?: boolean | undefined;
    state?: any;
  } | undefined) => handleNavigationAttempt(() => setSearchParams(nextInit, navigateOptions));

  const handleSetPrompt = useCallback((prompt: Prompt | string) => {
    if (typeof prompt !== 'string') {
      const { id } = prompt;
      setPrompts((prompts) => ({
        ...prompts,
        [id]: prompt,
      }));
    } else {
      setPrompts((prompts) => ({
        ...prompts,
        [prompt]: undefined,
      }));
    }
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        navigate,
        prompts,
        searchParams,
        setPrompt: handleSetPrompt,
        setSearchParams: handleSetSearchParam,
      }}
    >
      {props.children}
      { prompt && showPrompt && (
        <Dialog
          actions={prompt.actions?.map((action) => ({
            ...action,
            onClick: (event) => {
              if (action.onClick) action.onClick(event, callbackRef.current);
              setShowPrompt(false);
            },
          }))}
          onClickDismiss={() => setShowPrompt(false)}
          title={prompt.title}
        >
          {prompt.children}
        </Dialog>
      )}
    </NavigationContext.Provider>
  );
};

const useNavigateContext = () => useContext(NavigationContext);
const useNavigate = () => {
  const ctx = useRouterNavigate();
  return useContext(NavigationContext).navigate(ctx);
};
const useSearchParams = () => {
  const { searchParams, setSearchParams } = useContext(NavigationContext);
  return [searchParams, setSearchParams];
};

const useBlocker = (block: boolean, blockerPrompt: Prompt) => {
  const { prompts, setPrompt } = useContext(NavigationContext);

  useEffect(() => {
    if (block && !(prompts && prompts[blockerPrompt.id])) {
      setPrompt(blockerPrompt);
    } else if (!block && prompts && prompts[blockerPrompt.id]) {
      setPrompt(blockerPrompt.id);
    }
  }, [block, blockerPrompt, prompts, setPrompt]);

  useEffect(() => () => {
    setPrompt(blockerPrompt.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export {
  NavigationProvider,
  useBlocker,
  useNavigate,
  useNavigateContext,
  useSearchParams,
};
