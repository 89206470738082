import type { ComponentProps } from './MultiSelect.component';
import Component from './MultiSelect.component';
import type { MultiSelectProps as DDEProps } from './MultiSelect.dde';
import DDE from './MultiSelect.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

type MultiSelectProps = ExtendedComponentProps | ExtendedDDEProps;

const MultiSelect = (props: MultiSelectProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { MultiSelect };
