import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';

import { Icon } from '../Icon';
import type { SurfaceProps } from '../Surface';
import { Surface } from '../Surface';
import { Typography } from '../Typography';

export interface MenuItemProps extends SurfaceProps {
  label: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  sub?: boolean;
}

export const MenuItemComponent = ({ children, icon, label, onClick, sub, ...rest }: MenuItemProps) => (
  <Surface {...rest} className="flex flex-row items-center justify-between py-3 px-4">
    {icon && (
      <div className="pl-2 pr-[20px]">
        {icon}
      </div>
    )}
    <Typography className="flex-1" variant="body1">{label}</Typography>
    { sub && <Icon icon="ChevronRight" />}
  </Surface>
);

export const MenuItem = (props: MenuItemProps) => {
  const { onClick } = props;

  const handleClick = (event: any) => {
    event.stopPropagation();
    if (onClick) onClick(event);
  };

  return (
    <DropdownMenu.Item onClick={handleClick}>
      <MenuItemComponent {...props} />
    </DropdownMenu.Item>
  );
};
