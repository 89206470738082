import { useOutletContext } from 'react-router';

import type { FeeParametrizedRuleAggregatorSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin_pb';
import { Typography } from '@/Components/Atoms/Typography';
import { FeeParameters } from '@/Components/Features/trigger/FeeParameters';
import { Trigger } from '@/Components/Features/trigger/Trigger';

interface TemplateTriggerProps {
  onUpdateTrigger: (triggers: FeeParametrizedRuleAggregatorSummary.AsObject) => void;
  triggerData: FeeParametrizedRuleAggregatorSummary.AsObject | undefined;
  isFetchingTriggerData: boolean;
  getGroupId: (groupId: string) => string;
}

export function FeeTemplateTrigger() {
  const { getGroupId, isFetchingTriggerData, onUpdateTrigger, triggerData } = useOutletContext<TemplateTriggerProps>();
  const triggerTitle = [
    'This fee gets added to a loan if the following data is collected.',
    'When input multiple rule groups and choose "OR" between rule groups, the system reviews the rule groups in order. As soon as an applicable rule group is found, the system will fire the fee. The remaining rule groups will not be reviewed.',
    'All rule groups must have a rule. The rule defaults to "active business process domain ID contains any value" which means the rule group will fire for ALL loans. You can update the rule if needed. If the only rule for a rule group is deleted the system will insert the default rule.',
  ];

  return (
    <Trigger
      addDefaultAutomatically
      getGroupId={getGroupId}
      isLoading={isFetchingTriggerData}
      onUpdateTrigger={onUpdateTrigger}
      title={triggerTitle}
      trigger={triggerData}
    >
      {({ group }) => (
        <div className="flex flex-col p-4">
          <Typography className="text-on-surface-inactive mb-3" variant="body2Bold">
            Then the fee total should be set to
          </Typography>

          <div className="flex flex-wrap">
            <FeeParameters feeParameters={group.feeParameters} groupId={group.id} />
          </div>
        </div>
      )}
    </Trigger>
  );
}
