import { useQuery } from '@tanstack/react-query';

import { AdminLoanPartyRoleTypesEnum } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { normalizeString } from '@/Utils/Helpers/normalizeString';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetAllPartyRolesQueryRequest';

const useGetAllPartyRoles = (search: string) => useQuery([MESSAGE_NAME, search], async () => {
  const items = Object.values(AdminLoanPartyRoleTypesEnum).map((val) => ({
    id: val,
    name: ConvertEnum(AdminLoanPartyRoleTypesEnum, val as AdminLoanPartyRoleTypesEnum),
  }));
  const list = search ? items.filter((e) => normalizeString(e.name).includes(normalizeString(search))) : items;
  return {
    list,
    totalItems: 0,
    totalPages: 0,
  };
});

export { useGetAllPartyRoles };
