import type { ReactNode } from 'react';

export interface IAnimatedContainerProps {
  children: ReactNode;
}

const AnimatedContainer = (props: IAnimatedContainerProps) => {
  const { children } = props;

  return (
    <div className="flex flex-col h-full transition-opacity">
      {children}
    </div>
  );
};

export { AnimatedContainer };
