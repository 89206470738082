import { useMemo } from 'react';

import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';

import { useWidgetContext } from './WidgetContext';

export const useWidgetIds = (widgets = [] as LoanPageWidget.AsObject[], conditionalWidgetSaving?: boolean) => {
  const { savingWidgetIds } = useWidgetContext();
  const widgetIds = useMemo(() => widgets.map((w) => w.id), [widgets]);
  const isSaving = useMemo(() => savingWidgetIds.some((savingId) => widgetIds.some((widgetId) => widgetId === savingId)), [savingWidgetIds, widgetIds]);

  return {
    isSaving: conditionalWidgetSaving ? savingWidgetIds.length > 0 : isSaving,
    widgetIds,
  };
};
