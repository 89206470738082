import { useMutation } from '@tanstack/react-query';

import { CreateBusinessProcessTemplateCommandRequest, CreateBusinessProcessTemplateCommandResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

interface ParamsObject {
  title: string;
  internalName: string;
  progressItemId: string;
}

const MESSAGE_NAME = 'wilqo.api.business_process.CreateBusinessProcessTemplateCommandRequest';

export const useCreateBusinessProcessTemplate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (params: ParamsObject) => {
    const { internalName, progressItemId, title } = params;
    const request = new CreateBusinessProcessTemplateCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setTitle(title);
    request.setInternalName(internalName);
    request.setLoanProgressItemIdentifier(progressItemId);
    return sendMessage<CreateBusinessProcessTemplateCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      CreateBusinessProcessTemplateCommandResponse,
    );
  });
};
