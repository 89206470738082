import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetActivityAvailableSkillsetsToAssignQueryRequest, GetActivityAvailableSkillsetsToAssignQueryResponse } from '@/API/Models/Wilqo_API_Activity_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetActivityAvailableSkillsetsToAssignQueryRequest';

const useSkillsetAssignmentList = (activityId: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, activityId], async () => {
    const request = new GetActivityAvailableSkillsetsToAssignQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setActivityDesignId(activityId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetActivityAvailableSkillsetsToAssignQueryResponse();
    GetActivityAvailableSkillsetsToAssignQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      {
        assignedSkillsetsList: responseObj.assignedSkillsetsList,
        availableSkillsetsList: responseObj.availableSkillsetsList,
      },
    );
  }, { enabled: Boolean(activityId) && Boolean(bpdId) });
};
export { useSkillsetAssignmentList };
