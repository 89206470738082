import { useMutation } from '@tanstack/react-query';

import { DocumentType } from '@/API/Models/Wilqo.API.Mortgage.DocGen/Models_pb';
import { TriggerManualDocumentGenerationBPCommandRequest, TriggerManualDocumentGenerationBPCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage.TriggerManualDocumentGenerationBPCommandRequest';

export interface ManualDocumentGenerationParams {
  dealId: string;
  subscriptionId?: string;
  docTypes: Array<string>;
}

export const useManualDocumentGeneration = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation<TriggerManualDocumentGenerationBPCommandResponse.AsObject, Error, any, any>(
    async (params: ManualDocumentGenerationParams) => {
      const request = new TriggerManualDocumentGenerationBPCommandRequest();
      request.setBusinessProcessDomainId(bpdId);
      request.setDealId(params.dealId);
      request.setDocumentTypesList(params.docTypes.map((docType): DocumentType => Object.keys(DocumentType).indexOf(docType)));
      request.setInstitutionId(institutionId);
      request.setSubscriptionId(params.subscriptionId || 'id');
      return sendMessage<TriggerManualDocumentGenerationBPCommandResponse.AsObject>(
        {
          msg: request,
          msgName: MESSAGE_NAME,
        },
        TriggerManualDocumentGenerationBPCommandResponse,
      );
    },
  );
};
