import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useEvent from 'react-use-event-hook';

import { CustomRenderType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { FormWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { Typography } from '@/Components/Atoms/Typography';
import type { FormProgress } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { useBlocker } from '@/Routes/NavigationContext';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { mapFormFieldToPanelElement } from '@/Utils/Helpers/widgetHelpers';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import type { SaveConfig } from './useSaveWidget';
import { useWidgetContext } from './WidgetContext';

export interface FormWidgetProps extends LoanPageWidget.AsObject {
  allowPrompt?: boolean;
  form?: FormWidgetSettings.AsObject & { disableAccordion?: boolean };
}

const FormWidget = (props: FormWidgetProps) => {
  const { fieldGrouping, hiddenFieldIds, imperativeSubmit, resetProgress } = useDynamicForm();
  const { generateDialogKeywordLinks } = useShared();

  const { loanId } = useDynamicLoanInfo();
  const {
    addEditingWidget,
    context,
    pageId = '',
    removeEditingWidget,
    savingWidgetIds,
    scopeToken = '',
  } = useWidgetContext();
  const [isEditing, setIsEditing] = useState(false);

  const additionalInfo: SaveConfig = useMemo(() => ({
    hiddenFieldIds,
    loanId,
    pageId,
    scopeToken,
    type: 'form',
    widgetId: props.id,
    widgets: [props],
  }), [hiddenFieldIds, loanId, pageId, props, scopeToken]);

  useEffect(() => {
    addEditingWidget(props.id);
    return () => {
      removeEditingWidget(props.id);
    };
  }, [addEditingWidget, props.id, removeEditingWidget]);

  useEffect(() => {
    if (props.form && props.form.fieldsList) {
      const progress = props.form.fieldsList.reduce((acc, currentField): FormProgress => ({
        ...acc,
        values: {
          ...acc.values,
          [currentField.id]: {
            dynamic: currentField.value?.value ? DynamicDataElementMap(currentField.value?.value) : undefined,
            fieldConfig: currentField,
          },
        },
      }), { values: {} } as FormProgress);
      resetProgress(progress);
    }
  }, [props.form, props.form?.fieldsList, resetProgress]);

  useEffect(() => {
    if (context !== 'page' && !isEditing) {
      setIsEditing(true);
    }
  }, [context, isEditing]);

  const handleDiscardAnywayClick = (_: any, callback?: any) => {
    setIsEditing(false);
    if (callback) callback();
  };

  const handleSaveBeforeLeaving = useEvent((_: any, callback?: any) => {
    imperativeSubmit(callback, JSON.stringify(additionalInfo));
  });

  const handleSave = useCallback(() => {
    imperativeSubmit(() => setIsEditing(false), JSON.stringify(additionalInfo));
  }, [additionalInfo, imperativeSubmit]);

  useBlocker(context === 'page' && isEditing && Boolean(props.allowPrompt), {
    actions: [
      { danger: true, label: 'Discard', onClick: handleDiscardAnywayClick, variant: 'tertiary' },
      { label: 'Save', onClick: handleSaveBeforeLeaving, variant: 'tertiary' },
    ],
    children: (
      <Typography variant="body2">
        To continue navigation, you must select Discard or Save.
      </Typography>
    ),
    id: props.id,
    onClickDismiss: () => setIsEditing(false),
    title: 'Selection Needed',
  });

  const isQuestionForm = props.form?.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_URLA_FORM;

  const actions: CardButton[] = useMemo(() => {
    if (context === 'page') {
      return isEditing
        ? [
          { label: 'Cancel', onClick: () => setIsEditing(false), variant: 'tertiary' },
          { isLoading: savingWidgetIds.includes(props.id), label: 'Save', onClick: handleSave, variant: 'primary' },
        ]
        : [{ label: 'Edit', onClick: () => setIsEditing(true), type: 'button', variant: 'primary' }];
    }

    return [];
  }, [context, handleSave, isEditing, props.id, savingWidgetIds]);

  const formRender = useMemo(() => (
    <div
      className={clsx('gap-4', {
        'desktop:grid-cols-1': !isQuestionForm && props.form?.numberOfColumns === 1,
        'desktop:grid-cols-2': !isQuestionForm && props.form?.numberOfColumns === 2,
        'desktop:grid-cols-3': !isQuestionForm && (props.form?.numberOfColumns === 3 || !props.form?.numberOfColumns),
        'flex flex-col': isQuestionForm,
        'grid grid-cols-1 tablet:grid-cols-2': !isQuestionForm,
        'p-4': props.form?.renderType !== CustomRenderType.CUSTOM_RENDER_TYPE_FORM_CARDLESS,
      })}
    >

      {props.form?.fieldsList.map((field) => {
        const panelElement = mapFormFieldToPanelElement(
          field,
          isQuestionForm,
          fieldGrouping?.googleAddress?.addressLineFieldId === field.id,
        );

        if (panelElement) {
          const isValidComponent = ['generate', 'text', 'number', 'date', 'auto-complete', 'select'].includes(panelElement.type);

          return (
            <PanelElementRenderer
              key={field.id}
              showAsQuestion={isQuestionForm && isValidComponent}
              {...panelElement}
              disabled={!isEditing || panelElement.disabled}
              enrichedSummaryText={
                field?.hyperLinksList.length && panelElement?.summaryText
                  ? generateDialogKeywordLinks(panelElement?.summaryText, field?.hyperLinksList)
                  : undefined
              }
              variant={panelElement.type === 'checkbox' ? 'transparent' : 'default'}
            />
          );
        }
        return null;
      }).filter((panel) => panel !== null)}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button data-additionalinfo={JSON.stringify(additionalInfo)} hidden id="submit-form-widget" type="submit" />
    </div>
  ), [additionalInfo, fieldGrouping?.googleAddress?.addressLineFieldId, generateDialogKeywordLinks, isEditing, isQuestionForm, props.form?.fieldsList, props.form?.numberOfColumns, props.form?.renderType]);

  if (props.form?.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_FORM_CARDLESS) {
    return formRender;
  }

  return (
    <Card
      defaultOpen
      disableAccordion={props.form?.disableAccordion}
      headerProps={{
        actions,
        open: true,
        subtitle: generateDialogKeywordLinks(props.form?.helpText || '', props.form?.hyperLinksList || []),
        title: props.form?.label || '',
      }}
      variant="border"
    >
      {formRender}
    </Card>
  );
};

export { FormWidget };
