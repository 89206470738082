import { useState } from 'react';

import type { TableSort } from '.';

export const useTableConfig = () => {
  const [page, setPage] = useState(0);
  const [pagePerPage, setPagePerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState<TableSort>();
  return {
    filters,
    page,
    pagePerPage,
    search,
    setFilters,
    setPage,
    setPagePerPage,
    setSearch,
    setSort,
    sort,
  };
};
