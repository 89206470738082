import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { ddeAreEqual, DynamicDataElementMap, fromList, toList } from '@/Utils/Helpers/dynamicDataElementHelper';
import type { PanelElementExtended } from '@/Utils/Helpers/getPanelElement';

import type { TextFieldVariant } from '../TextField';
import Component from './MultiSelect.component';

export interface MultiSelectProps {
  className?: string;
  panelElement: PanelElementExtended;
  variant?: TextFieldVariant;
  trigger?: 'chip' | 'text-field';
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    panelElement: {
      id,
      optionsList: options,
      path,
      requirement,
    },
  } = props;

  const [value = [], setValue, { hidden }] = useDynamicFormRegister<PanelElementOption.AsObject[]>(
    id,
    {
      fromDynamic: (value) => {
        if (value && value.getDataType() !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
          const selectedItemsList = fromList(value);
          return options.filter((option) => selectedItemsList.some((selectedItem) => (option.value ? ddeAreEqual(selectedItem, DynamicDataElementMap(option.value)) : false)));
        }
        return [];
      },
      toDynamic: (value) => {
        if (value) return toList(value.map((v) => DynamicDataElementMap(v?.value as DynamicDataElement.AsObject)));
        return toList([]);
      },
    },
    { panelElementType: 'multi-select', requirement },
  );

  const handleSetValue = (value: Array<PanelElementOption.AsObject>) => setValue(value, value.map((e) => e.headerText).join(), path);

  const handleSelectAll = () => {
    if (value.length === options.length) {
      handleSetValue([]);
    } else {
      handleSetValue(options);
    }
  };

  const handleSelectItem = (selectedOption: PanelElementOption.AsObject) => {
    if (!selectedOption.disabled) {
      const isSelected = value?.some((option) => option.id === selectedOption.id);
      if (isSelected) {
        handleSetValue(value?.filter((v) => v.id !== selectedOption.id));
      } else {
        handleSetValue(value?.concat(selectedOption));
      }
    }
  };

  const handleClear = () => {
    handleSetValue([]);
  };

  const handleApply = (closeDropdown: VoidFunction) => {
    closeDropdown();
  };

  if (hidden) return null;

  return (
    <Component
      onApply={handleApply}
      onClear={handleClear}
      onSelect={handleSelectItem}
      onSelectAll={handleSelectAll}
      selected={value}
      title={props.panelElement.headerText}
      {...props.panelElement}
      trigger={props.trigger || 'text-field'}
      variant={props.variant}
    />
  );
};

export default MultiSelect;
