import type { Message } from 'google-protobuf';
import { Duration } from 'google-protobuf/google/protobuf/duration_pb';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';
import { DynamicDataElement, DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { WilqoTimestamp } from '@/API/Models/Wilqo.Shared.Models/Timestamp_pb';

import { ConvertNumberToProtoDecimal, ConvertProtoDecimalToNumber } from './protoDecimalConversion';

const HandleEmptyValuesDecorator = <T>(fn: (v: T) => DynamicDataElement) => (value: T | null | undefined): DynamicDataElement => {
  if (value === null || value === undefined) {
    const element = new DynamicDataElement();
    element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL);
    return element;
  }
  return fn(value);
};

export const fromNull = () => {
  const dde = new DynamicDataElement();
  dde.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL);
  return dde;
};

const fromStringPrime = (value: string): DynamicDataElement => {
  const bytes = Uint8Array.from(Buffer.from(value));
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_STRING);
  element.setValue(bytes);
  return element;
};
const fromString = HandleEmptyValuesDecorator(fromStringPrime);

const fromTimestampPrime = (value: WilqoTimestamp): DynamicDataElement => {
  const bytes = value.serializeBinary();
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DATE);
  element.setValue(bytes);
  return element;
};
const fromTimestamp = HandleEmptyValuesDecorator(fromTimestampPrime);

const toTimestamp = (element: DynamicDataElement): WilqoTimestamp => {
  const bytes = element.getValue_asU8();
  return WilqoTimestamp.deserializeBinary(bytes);
};

const fromDurationPrime = (value: Duration): DynamicDataElement => {
  const bytes = value.serializeBinary();
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DURATION);
  element.setValue(bytes);
  return element;
};
const fromDuration = HandleEmptyValuesDecorator(fromDurationPrime);

const toDuration = (element: DynamicDataElement): Duration => {
  const bytes = element.getValue_asU8();
  return Duration.deserializeBinary(bytes);
};

const fromNumberPrime = (value: number): DynamicDataElement => {
  const protoDecimal = ConvertNumberToProtoDecimal(value);
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NUMBER);
  element.setValue(protoDecimal.serializeBinary());
  return element;
};
const fromNumber = HandleEmptyValuesDecorator(fromNumberPrime);

const toNumber = (element: DynamicDataElement): number => {
  const bytes = element.getValue_asU8();
  const protoDecimal = ProtoDecimal.deserializeBinary(bytes);
  return ConvertProtoDecimalToNumber(protoDecimal);
};

const toNumberFromObject = (element: DynamicDataElement.AsObject) => {
  const dde = new DynamicDataElement();
  dde.setDataType(element.dataType);
  dde.setValue(element.value);
  return toNumber(dde);
}

const fromBoolPrime = (value: boolean): DynamicDataElement => {
  const bytes = new Uint8Array([value ? 255 : 1]);
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_BOOLEAN);
  element.setValue(bytes);
  return element;
};
const fromBool = HandleEmptyValuesDecorator(fromBoolPrime);

const toBool = (element: DynamicDataElement): boolean => {
  const bytes = element.getValue_asU8();
  return bytes[0] === 255;
};

export function fromAny(obj: any): any {
  if (obj) {
    const type = typeof obj;
    if (type === 'string') {
      return fromString(obj);
    }
    throw new Error(`type ${type} is not supported`);
  }
  return obj;
}

const toDurationString = (d: Duration.AsObject): string => {
  if (d.seconds < 60) {
    return `${d.seconds.toFixed(0)} s`;
  }
  if (d.seconds < 3600) {
    const m = Math.floor(d.seconds / 60);
    const s = d.seconds - (m * 60);
    return `${m.toFixed(0)} m ${s.toFixed(0)} s`;
  }
  if (d.seconds < 86400) {
    const h = Math.floor(d.seconds / 3600);
    const m = Math.round((d.seconds - (h * 3600)) / 60);
    return `${h} h ${m} m`;
  }
  return `${Math.round(d.seconds / 86400).toString()} d`;
};

const toTimestampString = (t: WilqoTimestamp.AsObject): string => {
  if (t.tzDetails) { return t.tzDetails.date; }

  if (!t.storage) { return '-'; }

  const d = new Date(t.storage.seconds * 1000);
  return `${(d.getUTCMonth() + 1).toString().padStart(2, '0')}/${d.getUTCDate().toString().padStart(2, '0')}/${d.getUTCFullYear()}`;
};

const toString = (element: DynamicDataElement): string => {
  switch (element.getDataType()) {
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_BOOLEAN:
      return toBool(element) ? 'True' : 'False';
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NUMBER:
      return toNumber(element).toString();
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_STRING:
      return Buffer.from(element.getValue_asU8()).toString();
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DURATION:
      return toDurationString(toDuration(element).toObject());
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL:
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST | DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL:
      return '--';
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_ENUMERATION:
      return Buffer.from(element.getValue_asU8()).toString();
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_UNKNOWN:
      return 'unknown dynamic data element type';
    case DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DATE:
      return toTimestampString(toTimestamp(element).toObject());
      default:
      return `unknown dde type ${DynamicDataElementDataTypeEnum[element.getDataType()]}`;
  }
};

const toStringFromObject = (element: DynamicDataElement.AsObject): string => {
  const dde = new DynamicDataElement();
  dde.setDataType(element.dataType);
  dde.setValue(element.value);
  return toString(dde);
};

// Typescript enums are not like C# enums
// They do not share a common base type to use with generics, they are plain objects
// https://www.typescriptlang.org/docs/handbook/enums.html#enums-at-runtime
// Since we are using C# enums this function only supports enums that use number internally
const fromEnumPrime = (value: number): DynamicDataElement => fromNumber(value);
const fromEnum = HandleEmptyValuesDecorator(fromEnumPrime);

function toEnum<T>(dynamicDataElement: DynamicDataElement): T {
  return (toNumber(dynamicDataElement) as any) as T;
}

const fromObjectPrime = <TMessage extends Message>(value: TMessage): DynamicDataElement => {
  const bytes = value.serializeBinary();
  const element = new DynamicDataElement();
  element.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_COMPLEX_TYPE);
  element.setValue(bytes);
  return element;
};
const fromObject = HandleEmptyValuesDecorator(fromObjectPrime);

const toObject = <TMessage extends Message>(element: DynamicDataElement, type: typeof Message): TMessage => {
  const bytes = element.getValue_asU8();
  return type.deserializeBinary(bytes) as TMessage;
};

const DynamicDataElementMap = (v: DynamicDataElement.AsObject): DynamicDataElement => {
  const r = new DynamicDataElement();
  r.setValue(v.value);
  r.setDataType(v.dataType);
  return r;
};

const fromPanelElementOptionPrime = (option?: PanelElementOption.AsObject) => {
  if (option?.value) {
    return DynamicDataElementMap(option.value);
  }
  return fromString(option?.id);
};

const fromPanelElementOption = HandleEmptyValuesDecorator(fromPanelElementOptionPrime);

const toList = (collection: DynamicDataElement[]): DynamicDataElement => {
  const result = new DynamicDataElement();
  if (collection.length === 0) {
    result.setDataType(DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST);
    return result;
  }

  const dataType = collection[0].getDataType();
  for (let i = 1; i < collection.length; i += 1) {
    if (dataType !== collection[i].getDataType()) { throw new Error('All collection items must be of the same data type'); }
  }

  // eslint-disable-next-line no-bitwise
  result.setDataType(dataType | DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST);

  const dataArrays = collection
    .map((x) => {
      const data = x.serializeBinary();
      const dataLength = toBytes(data.length);
      const itemData = new Uint8Array(dataLength.length + data.length);
      itemData.set(dataLength);
      itemData.set(data, dataLength.length);
      return itemData;
    });
  const dataLength = dataArrays.reduce((previous, current) => previous + current.length, 0);
  const data = new Uint8Array(dataLength);
  let idx = 0;
  for (let i = 0; i < dataArrays.length; i += 1) {
    data.set(dataArrays[i], idx);
    idx += dataArrays[i].length;
  }

  result.setValue(data);

  return result;
};

function toBytes(value: number): Uint8ClampedArray {
  if (!Number.isSafeInteger(value) || value < 0 || value > 0xFFFFFFFF) {
    throw new Error('Number is out of range');
  }

  const size = 4;
  const bytes = new Uint8ClampedArray(size);
  let x = value;
  for (let i = 0; i < 4; i += 1) {
    // eslint-disable-next-line no-bitwise
    const rightByte = x & 0xff;
    bytes[i] = rightByte;
    x = Math.floor(x / 0x100);
  }

  return bytes;
}

const fromList = (collection: DynamicDataElement): DynamicDataElement[] => {
  // eslint-disable-next-line no-bitwise
  if ((collection.getDataType() & DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST) !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST) { throw new Error('DDE is not a list'); }

  const returnList = new Array<DynamicDataElement>();
  let i = 0;
  const data = collection.getValue_asU8();
  while (i + 4 < data.length) {
    const dataLength = data[i] + (data[i + 1] * 0xFF) + (data[i + 2] * 0xFFFF) + (data[i + 3] * 0xFFFFFF);
    if (dataLength + i >= data.length) { throw new Error('Block length would overflow bounds of data'); }

    const itemDDE = DynamicDataElement.deserializeBinary(data.slice(i + 4, i + 4 + dataLength));
    i += 4 + dataLength;

    returnList.push(itemDDE);
  }

  return returnList;
};

const ddeAreEqual = (a: DynamicDataElement, b: DynamicDataElement) => {
  try {
    return a.getDataType() === b.getDataType() && a.getValue_asB64() === b.getValue_asB64();
  } catch {
    return false;
  }
};

export {
  ddeAreEqual,
  DynamicDataElementMap,
  fromBool,
  fromDuration,
  fromEnum,
  fromList,
  fromNumber,
  fromObject,
  fromPanelElementOption,
  fromString,
  fromTimestamp,
  toNumberFromObject,
  toBool,
  toDuration,
  toDurationString,
  toEnum,
  toList,
  toNumber,
  toObject,
  toString,
  toStringFromObject,
  toTimestamp,
  toTimestampString,
};
