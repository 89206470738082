import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { AggregatedTableColumnSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/AggregatedTable_pb';
import type {
  CardListItemField,
  CardListItemWidgetSettings,
} from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { Typography } from '@/Components/Atoms/Typography';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import type { FormWidgetProps } from '@/Components/Widgets/content/FormWidget';
import type { SaveConfig } from '@/Components/Widgets/content/useSaveWidget';
import { useWidgetContext } from '@/Components/Widgets/content/WidgetContext';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

export interface AggregatedTableProps extends FormWidgetProps {
  id: string;
}

interface Row {
  id: string;
  scopeToken: string;
  [key: string]: any;
}
export const AggregatedTable = (props: AggregatedTableProps) => {
  const { isMobile } = useWindow();

  const { aggregatedTable } = props;
  const { loanId } = useDynamicLoanInfo();
  const { imperativeSubmit, resetProgress } = useDynamicForm();
  const {
    pageId = '',
    savingWidgetIds,
    scopeToken = '',
  } = useWidgetContext();
  const additionalInfo: SaveConfig = useMemo(() => ({
    isNew: false,
    loanId,
    pageId,
    scopeToken,
    type: 'page',
    widgetId: props.id,
    widgets: props.aggregatedTable?.rowsList.map((row): LoanPageWidget.AsObject => ({
      cardListItem: {
        fieldsList: props.aggregatedTable?.columnsList.filter((c) => c.panelElement).map((col) => ({ id: `${row.rowScopeToken}@${col.columnId}`, panelElement: col.panelElement })) as CardListItemField.AsObject[],
        scopeToken: row.rowScopeToken,
      } as CardListItemWidgetSettings.AsObject,
      id: props.id,
      validationMessagesList: [],
    })),
  }), [loanId, pageId, props, scopeToken]);

  const [isEditting, setIsEditting] = useState<boolean>(false);

  const handleSave = useCallback(() => {
    imperativeSubmit(() => setIsEditting(false), JSON.stringify(additionalInfo));
  }, [additionalInfo, imperativeSubmit]);

  const handleEditMode = useCallback(() => {
    setIsEditting(true);
    const values = props.aggregatedTable?.rowsList.reduce((prev, currentRow) => ({
      ...prev,
      ...currentRow.rowDataMap.reduce((prev2, currentData) => {
        const col = props.aggregatedTable?.columnsList.find((c) => c.columnId === currentData[1].columnId) as AggregatedTableColumnSettings.AsObject;
        if (col && col.panelElement) {
          return { ...prev,
            [`${currentRow.rowScopeToken}@${col.columnId}`]: {
              dynamic: currentData[1].editableData?.value ? DynamicDataElementMap(currentData[1].editableData?.value) : undefined,
              value: undefined,
            } };
        }
        return prev;
      }, {}),
    }), {});
    resetProgress({ values: { ...values } }, true);
  }, [resetProgress, props]);

  const rows: Row[] = useMemo(() => {
    if (!aggregatedTable?.rowsList) return [];
    const result: Row[] = [];
    aggregatedTable.rowsList.forEach((row) => {
      const rowData: Row = { id: uuid(), scopeToken: row.rowScopeToken };
      row.rowDataMap.forEach(([colId, cellModel]) => {
        rowData[colId] = {
          displayData: cellModel.displayData,
          editableData: cellModel.editableData,
          hidden: aggregatedTable?.columnsList.find((c) => c.columnId === colId)?.hidden,
        };
      });
      result.push(rowData);
    });
    return result;
  }, [aggregatedTable?.rowsList, aggregatedTable?.columnsList]);

  const actions: CardButton[] = useMemo(() => (
    isEditting ? [
      { label: 'Cancel', onClick: () => setIsEditting(false), variant: 'tertiary' },
      { isLoading: savingWidgetIds.includes(props.id), label: aggregatedTable?.saveButtonLabel || 'Save', onClick: handleSave, variant: 'primary' },
    ]
      : [{ label: aggregatedTable?.editButtonLabel || 'Edit', onClick: () => handleEditMode(), type: 'button', variant: 'primary' }]),
  [aggregatedTable?.editButtonLabel, aggregatedTable?.saveButtonLabel, handleEditMode, handleSave, isEditting, props.id, savingWidgetIds]);

  if (isMobile) {
    return (
      <Card
        disableAccordion
        headerProps={{
          actions: aggregatedTable?.editButtonVisible ? actions : undefined,
          open: true,
          title: aggregatedTable?.title || '',
        }}
        variant="border"
      >
        <div className="w-full overflow-x-auto">
          <table
            cellPadding={0}
            cellSpacing={0}
            className="w-full border-collapse !border-spacing-0"
          >
            <thead className="border-b border-b-on-surface-stroke">
              <tr role="row">
                {aggregatedTable?.columnsList.map((col) => col.title && !col.hidden && (
                <th
                  key={`col-${col.columnId}-${col.title}`}
                  align="left"
                  className="px-4 py-3"
                >
                  <Typography variant="overline">{col.title}</Typography>
                </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
              rows?.map((row) => (
                <tr
                  key={row.id}
                  className="relative"
                >
                  {aggregatedTable?.columnsList.map((col) => {
                    if (row[col.columnId].hidden) return null;

                    return (
                      <td
                        key={`row-${row[col.columnId]}-${col.title}`}
                        className="p-4"
                        id={col.columnId}
                      >
                        {col.editable && isEditting && col.panelElement ? (
                          <div className="w-[58px]">
                            <PanelElementRenderer
                              {...col.panelElement}
                              headerText=""
                              id={`editable@edit@${props.id}@${row.scopeToken}@${col.columnId}`}
                              variant="dense-variant"
                            />
                          </div>
                        ) : (
                          <Typography variant="body2">
                            {renderValue(row[col.columnId].displayData, undefined, col.panelElement)}
                          </Typography>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </Card>
    );
  }

  // Desktop
  return (
    <Card
      disableAccordion
      headerProps={{
        actions: aggregatedTable?.editButtonVisible ? actions : undefined,
        open: true,
        title: aggregatedTable?.title || '',
      }}
      variant="border"
    >
      <table
        cellPadding={0}
        cellSpacing={0}
        className="w-full border-collapse !border-spacing-0"
      >
        <thead className="border-b border-b-on-surface-stroke">
          <tr role="row">
            {aggregatedTable?.columnsList.map((col) => col.title && !col.hidden && (
            <th
              key={`col-${col.columnId}-${col.title}`}
              align="left"
              className="px-4 py-3"
            >
              <Typography variant="overline">{col.title}</Typography>
            </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
          rows?.map((row) => (
            <tr
              key={row.id}
              className="relative"
            >
              {aggregatedTable?.columnsList.map((col) => {
                if (row[col.columnId].hidden) return null;
                return (
                  <td
                    key={`row-${row[col.columnId]}-${col.title}`}
                    className="p-4"
                    id={col.columnId}
                  >
                    {col.editable && isEditting && col.panelElement ? (
                      <div className="w-[58px]">
                        <PanelElementRenderer
                          {...col.panelElement}
                          headerText=""
                          id={`${row.scopeToken}@${col.columnId}`}
                          variant="dense-variant"
                        />
                      </div>
                    ) : (
                      <Typography variant="body2">
                        {renderValue(row[col.columnId].displayData, undefined, col.panelElement)}
                      </Typography>
                    )}
                  </td>
                );
              })}
            </tr>
          ))
        }
        </tbody>
      </table>
    </Card>
  );
};
