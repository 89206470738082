import { FullPageSaveItem, SaveFieldItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicActivity } from '@/API/Queries/mortgageDynamicData/useDynamicActivity';
import { useSubmitDynamicActivity } from '@/API/Queries/mortgageDynamicData/useSubmitDynamicActivity';
import { Button } from '@/Components/Atoms/Button';
import { DialogRoot as Dialog } from '@/Components/Atoms/RadixDialog';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useWidgetContextValues, WidgetProvider } from '@/Components/Widgets/content/WidgetContext';
import { WidgetRenderer } from '@/Components/Widgets/WidgetRenderer';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  id: string;
}

export const DynamicActivity = (props: Props) => {
  const { id = '', onClose, open } = props;
  const { data } = useDynamicActivity(id);
  const widgetValues = useWidgetContextValues();

  const { isLoading, mutate: saveActivity } = useSubmitDynamicActivity();
  const { showSnackBar } = useShared();

  const handleSubmit = (values: DynamicDataElementValues) => {
    saveActivity(
      {
        activityId: id,
        pageSaveItems: data?.item?.widgetsList?.map((widget) => {
          const pageSave = new FullPageSaveItem();
          pageSave.setWidgetId(widget.id);
          const widgetScope = data.item?.scopeToken || '';
          pageSave.setWidgetScopeToken(widgetScope);
          const items = widget.cardListItem?.fieldsList.reduce((items: Array<SaveFieldItem>, field) => {
            const fieldValue = values[field.id];
            if (fieldValue && fieldValue.dynamic && fieldValue.dynamic.getDataType() !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
              const widgetItem = new SaveFieldItem();
              widgetItem.setFieldId(field.id);
              widgetItem.setValue(values[field.id].dynamic);
              return items.concat(widgetItem);
            }
            return items;
          }, []);
          pageSave.setItemsList(items || []);
          return pageSave;
        }) || [],
      },
      {
        onError: (error) => {
          showSnackBar(error.message || 'Something went wrong');
        },
        onSuccess: () => {
          showSnackBar('Loan Updated');
          onClose();
        },
      },
    );
  };

  return (
    <Dialog.Root onOpenChange={onClose} open={open}>
      <DynamicFormProvider className="w-full" onSubmit={handleSubmit}>
        <Dialog.Layout>
          <Dialog.Header
            actions={<Button isLoading={isLoading} label="Save" type="submit" />}
            title={data?.item?.title}
          />
          <Dialog.Content className="p-4 tablet:p-8 desktop:p-12">
            <WidgetProvider
              context="dialog"
              pageId=""
              {...widgetValues}
              editAllWidgets
            >
              <WidgetRenderer className="gap-4" widgetList={data?.item?.widgetsList || []} />
            </WidgetProvider>
          </Dialog.Content>
        </Dialog.Layout>
      </DynamicFormProvider>
    </Dialog.Root>
  );
};
