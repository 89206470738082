import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { AddSkillsetToActivityCommandRequest, AddSkillsetToActivityCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.AddSkillsetToActivityCommandRequest';

interface UpdateObject {
  activityId: string;
  skillsetId: string;
}

const useAddSkillsetToActivity = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const AddSkillsetToActivityCommand = async (updateObject: UpdateObject) => {
    const { activityId, skillsetId } = updateObject;
    const request = new AddSkillsetToActivityCommandRequest();
    request.setActivityDesignId(activityId);
    request.setSkillsetId(skillsetId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new AddSkillsetToActivityCommandResponse();
    AddSkillsetToActivityCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(AddSkillsetToActivityCommand);
};

export { useAddSkillsetToActivity };
