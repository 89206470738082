// source: Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb = require('../Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('../Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var Wilqo_Shared_Models_Timestamp_pb = require('../Wilqo.Shared.Models/Timestamp_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Timestamp_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('../Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ActivityConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LenderCondition', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LenderConditionMapping', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeTemplate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanPhaseEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LenderCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.LenderCondition.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LenderCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LenderCondition.displayName = 'proto.wilqo.api.mortgage_config.LenderCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LenderConditionMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LenderConditionMapping.displayName = 'proto.wilqo.api.mortgage_config.LenderConditionMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.ActivityConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.ActivityConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.ActivityConfig.displayName = 'proto.wilqo.api.mortgage_config.ActivityConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.displayName = 'proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.LoanFeeTemplate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeTemplate.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeSummary.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.displayName = 'proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.LenderCondition.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LenderCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LenderCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LenderCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliedEventCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    conditionDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activityConfigsList: jspb.Message.toObjectList(msg.getActivityConfigsList(),
    proto.wilqo.api.mortgage_config.ActivityConfig.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    code: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastPublishedDate: (f = msg.getLastPublishedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    conditionSatisfactionResponsiblePartyType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    conditionSatisfactionTimeframeType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    internalName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    lastPublishedBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    lastPublishedByDisplayName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition}
 */
proto.wilqo.api.mortgage_config.LenderCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LenderCondition;
  return proto.wilqo.api.mortgage_config.LenderCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LenderCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition}
 */
proto.wilqo.api.mortgage_config.LenderCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppliedEventCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_config.ActivityConfig;
      reader.readMessage(value,proto.wilqo.api.mortgage_config.ActivityConfig.deserializeBinaryFromReader);
      msg.addActivityConfigs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPublishedDate(value);
      break;
    case 10:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionResponsiblePartyType(value);
      break;
    case 13:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionTimeframeType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedBy(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedByDisplayName(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LenderCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LenderCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LenderCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliedEventCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getConditionDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActivityConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_config.ActivityConfig.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastPublishedDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getConditionSatisfactionResponsiblePartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getConditionSatisfactionTimeframeType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLastPublishedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLastPublishedByDisplayName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 applied_event_count = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getAppliedEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setAppliedEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string condition_description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getConditionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setConditionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ConditionTemplateCategoryEnum category = 4;
 * @return {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated ActivityConfig activity_configs = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_config.ActivityConfig>}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getActivityConfigsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.ActivityConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_config.ActivityConfig, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.ActivityConfig>} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
*/
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setActivityConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ActivityConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.addActivityConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_config.ActivityConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.clearActivityConfigsList = function() {
  return this.setActivityConfigsList([]);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string code = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp last_published_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getLastPublishedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
*/
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setLastPublishedDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.clearLastPublishedDate = function() {
  return this.setLastPublishedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.hasLastPublishedDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 10;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 10));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
*/
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string business_process_template_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ConditionSatisfactionResponsiblePartyEnum condition_satisfaction_responsible_party_type = 12;
 * @return {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getConditionSatisfactionResponsiblePartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setConditionSatisfactionResponsiblePartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional ConditionSatisfactionTimeframeEnum condition_satisfaction_timeframe_type = 13;
 * @return {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getConditionSatisfactionTimeframeType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setConditionSatisfactionTimeframeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string internal_name = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string last_published_by = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getLastPublishedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setLastPublishedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string last_published_by_display_name = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getLastPublishedByDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setLastPublishedByDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool archived = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LenderCondition} returns this
 */
proto.wilqo.api.mortgage_config.LenderCondition.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LenderConditionMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LenderConditionMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliedEventCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lenderConditionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ausConditionSource: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ausConditionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ausConditionDescription: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LenderConditionMapping;
  return proto.wilqo.api.mortgage_config.LenderConditionMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LenderConditionMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppliedEventCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderConditionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LenderConditionMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LenderConditionMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliedEventCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLenderConditionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAusConditionSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAusConditionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAusConditionDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 applied_event_count = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getAppliedEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setAppliedEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string lender_condition_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getLenderConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setLenderConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string aus_condition_source = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getAusConditionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setAusConditionSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string aus_condition_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getAusConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setAusConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string aus_condition_description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.getAusConditionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage_config.LenderConditionMapping.prototype.setAusConditionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.ActivityConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.ActivityConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ActivityConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activityInstanceTag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.ActivityConfig;
  return proto.wilqo.api.mortgage_config.ActivityConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.ActivityConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityTemplateVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityInstanceTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.ActivityConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.ActivityConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ActivityConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActivityInstanceTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_template_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.getActivityTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.setActivityTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string activity_instance_tag = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.getActivityInstanceTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage_config.ActivityConfig.prototype.setActivityInstanceTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerInquiryStateLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig;
  return proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationLicenseFailureMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserLicenseFailureMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerInquiryStateLicenseFailureMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerInquiryStateLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string organization_license_failure_message = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.getOrganizationLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} returns this
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.setOrganizationLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_license_failure_message = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.getUserLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} returns this
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.setUserLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_inquiry_state_license_failure_message = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.getConsumerInquiryStateLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} returns this
 */
proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig.prototype.setConsumerInquiryStateLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.repeatedFields_ = [31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    feeEstimatedTotalAmount: (f = msg.getFeeEstimatedTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaidToType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    feeProcessType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    feeSpecifiedHud1LineNumberValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    feeSpecifiedLineNumberValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gfeAggregationType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 15, ""),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    integratedDisclosureSubsectionType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    optionalCostIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    requiredProviderOfServiceIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    sectionClassificationType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    defaultFeeIncludedInAprIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    defaultFeePaidToType: jspb.Message.getFieldWithDefault(msg, 23, 0),
    defaultFeePaidToPartyType: jspb.Message.getFieldWithDefault(msg, 24, 0),
    defaultFeePaymentPaidByTypeThirdPartyName: jspb.Message.getFieldWithDefault(msg, 25, ""),
    defaultFeePaymentPaidByType: jspb.Message.getFieldWithDefault(msg, 26, 0),
    defaultFeePaymentTimingType: jspb.Message.getFieldWithDefault(msg, 27, 0),
    hud1SectionClassificationType: jspb.Message.getFieldWithDefault(msg, 28, 0),
    feeParametersList: jspb.Message.toObjectList(msg.getFeeParametersList(),
    Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.toObject, includeInstance),
    defaultFeePaidToPartyRoleMapId: jspb.Message.getFieldWithDefault(msg, 32, ""),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 33, ""),
    defaultFeeToleranceCategoryType: jspb.Message.getFieldWithDefault(msg, 34, 0),
    defaultFeePaidToWilqoPartyType: jspb.Message.getFieldWithDefault(msg, 35, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeTemplate;
  return proto.wilqo.api.mortgage_config.LoanFeeTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeEstimatedTotalAmount(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (reader.readEnum());
      msg.setFeePaidToType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (reader.readEnum());
      msg.setFeeProcessType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedHud1LineNumberValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedLineNumberValue(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (reader.readEnum());
      msg.setGfeAggregationType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSubsectionType(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptionalCostIndicator(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredProviderOfServiceIndicator(value);
      break;
    case 21:
      var value = /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (reader.readEnum());
      msg.setSectionClassificationType(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultFeeIncludedInAprIndicator(value);
      break;
    case 23:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToType(value);
      break;
    case 24:
      var value = /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToPartyType(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaymentPaidByTypeThirdPartyName(value);
      break;
    case 26:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentPaidByType(value);
      break;
    case 27:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentTimingType(value);
      break;
    case 28:
      var value = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (reader.readEnum());
      msg.setHud1SectionClassificationType(value);
      break;
    case 31:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader);
      msg.addFeeParameters(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaidToPartyRoleMapId(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 34:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setDefaultFeeToleranceCategoryType(value);
      break;
    case 35:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setDefaultFeePaidToWilqoPartyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeeEstimatedTotalAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getFeeProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getFeeSpecifiedHud1LineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFeeSpecifiedLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getOptionalCostIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getRequiredProviderOfServiceIndicator();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSectionClassificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getDefaultFeeIncludedInAprIndicator();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getDefaultFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      23,
      f
    );
  }
  f = message.getDefaultFeePaidToPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByTypeThirdPartyName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByType();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getDefaultFeePaymentTimingType();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getFeeParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.serializeBinaryToWriter
    );
  }
  f = message.getDefaultFeePaidToPartyRoleMapId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getDefaultFeeToleranceCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getDefaultFeePaidToWilqoPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FeeEnum type = 4;
 * @return {!proto.wilqo.api.mortgage_config.FeeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_estimated_total_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeeEstimatedTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeeEstimatedTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.clearFeeEstimatedTotalAmount = function() {
  return this.setFeeEstimatedTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.hasFeeEstimatedTotalAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FeePaidToEnum fee_paid_to_type = 7;
 * @return {!proto.wilqo.api.mortgage_config.FeePaidToEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeePaidToEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional FeeProcessEnum fee_process_type = 9;
 * @return {!proto.wilqo.api.mortgage_config.FeeProcessEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeeProcessType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeProcessEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeeProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string fee_specified_hud_1_line_number_value = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeeSpecifiedHud1LineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeeSpecifiedHud1LineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fee_specified_line_number_value = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeeSpecifiedLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeeSpecifiedLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional GFEAggregationEnum gfe_aggregation_type = 14;
 * @return {!proto.wilqo.api.mortgage_config.GFEAggregationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getGfeAggregationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setGfeAggregationType = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.clearGfeAggregationType = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.hasGfeAggregationType = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string integrated_disclosure_line_number_value = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 16;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional IntegratedDisclosureSubsectionEnum integrated_disclosure_subsection_type = 17;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getIntegratedDisclosureSubsectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setIntegratedDisclosureSubsectionType = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.clearIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.hasIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool optional_cost_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getOptionalCostIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setOptionalCostIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 19;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool required_provider_of_service_indicator = 20;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getRequiredProviderOfServiceIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setRequiredProviderOfServiceIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional SectionClassificationEnum section_classification_type = 21;
 * @return {!proto.wilqo.api.mortgage_config.SectionClassificationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getSectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setSectionClassificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional bool default_fee_included_in_apr_indicator = 22;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeeIncludedInAprIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeeIncludedInAprIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional DefaultFeePaidToTypeEnum default_fee_paid_to_type = 23;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 23, value);
};


/**
 * optional PartyRoleTypeEnum default_fee_paid_to_party_type = 24;
 * @return {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaidToPartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaidToPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional string default_fee_payment_paid_by_type_third_party_name = 25;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaymentPaidByTypeThirdPartyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaymentPaidByTypeThirdPartyName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional DefaultFeePaymentPaidByTypeEnum default_fee_payment_paid_by_type = 26;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaymentPaidByType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaymentPaidByType = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional DefaultFeePaymentTimingTypeEnum default_fee_payment_timing_type = 27;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaymentTimingType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaymentTimingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional Hud1SectionClassificationTypeEnum hud_1_section_classification_type = 28;
 * @return {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getHud1SectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setHud1SectionClassificationType = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.clearHud1SectionClassificationType = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.hasHud1SectionClassificationType = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * repeated FeeTemplateRuleGroupParameter fee_parameters = 31;
 * @return {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getFeeParametersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter, 31));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setFeeParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.addFeeParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.clearFeeParametersList = function() {
  return this.setFeeParametersList([]);
};


/**
 * optional string default_fee_paid_to_party_role_map_id = 32;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaidToPartyRoleMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaidToPartyRoleMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string business_process_template_id = 33;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional FeeToleranceCategoryType default_fee_tolerance_category_type = 34;
 * @return {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeeToleranceCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeeToleranceCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType default_fee_paid_to_wilqo_party_type = 35;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.getDefaultFeePaidToWilqoPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplate.prototype.setDefaultFeePaidToWilqoPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    section: jspb.Message.getFieldWithDefault(msg, 4, 0),
    borrowerPaid: (f = msg.getBorrowerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    sellerPaid: (f = msg.getSellerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lenderPaid: (f = msg.getLenderPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    otherPaid: (f = msg.getOtherPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidTo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isAPR: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    total: (f = msg.getTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalPercent: (f = msg.getTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lastPublishedDate: (f = msg.getLastPublishedDate()) && Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.toObject(includeInstance, f),
    percentBasisType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    paidBy: jspb.Message.getFieldWithDefault(msg, 16, 0),
    defaultFeePaidToWilqoPartyType: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeSummary;
  return proto.wilqo.api.mortgage_config.LoanFeeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setSection(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerPaid(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSellerPaid(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLenderPaid(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOtherPaid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAPR(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalPercent(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp;
      reader.readMessage(value,Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.deserializeBinaryFromReader);
      msg.setLastPublishedDate(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} */ (reader.readEnum());
      msg.setPercentBasisType(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (reader.readEnum());
      msg.setPaidBy(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setDefaultFeePaidToWilqoPartyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSection();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBorrowerPaid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSellerPaid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLenderPaid();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOtherPaid();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsAPR();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalPercent();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLastPublishedDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getPercentBasisType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPaidBy();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getDefaultFeePaidToWilqoPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeeEnum type = 3;
 * @return {!proto.wilqo.api.mortgage_config.FeeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional IntegratedDisclosureSectionEnum section = 4;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getSection = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setSection = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_paid = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getBorrowerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setBorrowerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearBorrowerPaid = function() {
  return this.setBorrowerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasBorrowerPaid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal seller_paid = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getSellerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setSellerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearSellerPaid = function() {
  return this.setSellerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasSellerPaid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal lender_paid = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getLenderPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setLenderPaid = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearLenderPaid = function() {
  return this.setLenderPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasLenderPaid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal other_paid = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getOtherPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setOtherPaid = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearOtherPaid = function() {
  return this.setOtherPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasOtherPaid = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string paid_to = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_a_p_r = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getIsAPR = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setIsAPR = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_percent = 12;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 12));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearTotalPercent = function() {
  return this.setTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasTotalPercent = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.WilqoTimestamp last_published_date = 13;
 * @return {?proto.wilqo.shared.models.WilqoTimestamp}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getLastPublishedDate = function() {
  return /** @type{?proto.wilqo.shared.models.WilqoTimestamp} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp, 13));
};


/**
 * @param {?proto.wilqo.shared.models.WilqoTimestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setLastPublishedDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.clearLastPublishedDate = function() {
  return this.setLastPublishedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.hasLastPublishedDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional FeePercentBasisEnum percent_basis_type = 14;
 * @return {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getPercentBasisType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeePercentBasisEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setPercentBasisType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool archived = 15;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional DefaultFeePaymentPaidByTypeEnum paid_by = 16;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getPaidBy = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setPaidBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType default_fee_paid_to_wilqo_party_type = 17;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.getDefaultFeePaidToWilqoPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeSummary} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeSummary.prototype.setDefaultFeePaidToWilqoPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleSummary.toObject, includeInstance),
    applyAndBetweenRules: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    feeParameters: (f = msg.getFeeParameters()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary;
  return proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 3:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleSummary.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenRules(value);
      break;
    case 5:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader);
      msg.setFeeParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleSummary.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenRules();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFeeParameters();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool read_only = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated wilqo.shared.rulesengine.RuleSummary rules = 3;
 * @return {!Array<!proto.wilqo.shared.rulesengine.RuleSummary>}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.RuleSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.RuleSummary>} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
*/
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.rulesengine.RuleSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional bool apply_and_between_rules = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.getApplyAndBetweenRules = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.setApplyAndBetweenRules = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional FeeTemplateRuleGroupParameter fee_parameters = 5;
 * @return {?proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.getFeeParameters = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
*/
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.setFeeParameters = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.clearFeeParameters = function() {
  return this.setFeeParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary.prototype.hasFeeParameters = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum = {
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_UNKNOWN: 0,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ATTORNEY: 1,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_BROKER: 2,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_CLOSER: 3,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_CLOSING_AGENT: 4,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ESCROW_COMPANY: 5,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_LOAN_OFFICER: 6,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ORIGINATOR: 7,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_OTHER: 8,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_PROCESSOR: 9,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_SETTLEMENT_AGENT: 10,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_TITLE_COMPANY: 11
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum = {
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_UNKNOWN: 0,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_OTHER: 1,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_APPROVAL: 2,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_DOCUMENTS: 3,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_FUNDING: 4,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_SIGNING: 5,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_UNDERWRITER_TO_REVIEW: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.LoanPhaseEnum = {
  LOAN_PHASE_ENUM_UNKNOWN: 0,
  LOAN_PHASE_ENUM_PRE_QUALIFICATION: 1,
  LOAN_PHASE_ENUM_APPLICATION: 2,
  LOAN_PHASE_ENUM_CLOSED: 4,
  LOAN_PHASE_ENUM_DECISIONED: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum = {
  CONDITION_TEMPLATE_CATEGORY_ENUM_UNKNOWN: 0,
  CONDITION_TEMPLATE_CATEGORY_ENUM_ASSETS: 1,
  CONDITION_TEMPLATE_CATEGORY_ENUM_PROPERTY: 2,
  CONDITION_TEMPLATE_CATEGORY_ENUM_INCOME: 3,
  CONDITION_TEMPLATE_CATEGORY_ENUM_CREDIT: 4,
  CONDITION_TEMPLATE_CATEGORY_ENUM_PROGRAM: 5,
  CONDITION_TEMPLATE_CATEGORY_ENUM_LEGAL: 6
};

goog.object.extend(exports, proto.wilqo.api.mortgage_config);
