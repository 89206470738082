import { BinaryReader } from 'google-protobuf';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SubscribeTridSummaryCommandRequest, SubscribeTridSummaryCommandResponse, UnsubscribeTridSummaryCommandRequest } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import { TridSummary } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import type { WilqoMessage } from '../useBackend';
import { useSubscriptionContext } from '../useSubscription';
import { useWebSocket } from '../useWebSocket';

const useTRIDSubscription = (loanId: string) => {
  const { commandResponse, isConnected } = useWebSocket();
  const { subscribe: subscribeContext } = useSubscriptionContext();

  const subscriptionId = useRef<string>('');
  const subscribed = useRef<boolean>(false);
  const [trid, setTrid] = useState<TridSummary.AsObject | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleNewMessage = useCallback((protoMessage: any) => {
    setIsLoading(false);
    const message = TridSummary.deserializeBinary(protoMessage.getValue_asU8()).toObject();
    setTrid(message);
  }, []);

  const unsubscribe = useCallback(async () => {
    const deleteRequest = new UnsubscribeTridSummaryCommandRequest();
    deleteRequest.setLoanId(loanId);
    deleteRequest.setSubscriptionId(subscriptionId.current);
    const unsubscribeMessage: WilqoMessage = {
      msg: deleteRequest,
      msgName: 'wilqo.api.mortgage.UnsubscribeTridSummaryCommandRequest',
    };
    return commandResponse(unsubscribeMessage);
  }, [loanId, commandResponse]);

  const subscribe = useCallback(async () => {
    const request = new SubscribeTridSummaryCommandRequest();
    request.setDealId(loanId);
    const subscriptionMessage: WilqoMessage = {
      msg: request,
      msgName: 'wilqo.api.mortgage.SubscribeTridSummaryCommandRequest',
    };
    const message = await commandResponse(subscriptionMessage);
    const response = new SubscribeTridSummaryCommandResponse();
    SubscribeTridSummaryCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(message.getValue()));
    const responseObj = response.toObject();
    if (!responseObj.error) {
      subscriptionId.current = responseObj.subscriptionId;
      subscribeContext(responseObj.subscriptionId, handleNewMessage, subscribe);
    } else {
      setIsLoading(false);
      setError(responseObj.error.errorMessage);
    }
  }, [loanId, commandResponse, handleNewMessage, subscribeContext]);

  const refetch = useCallback(() => {
    setError(undefined);
    setIsLoading(true);
    subscribe();
  }, [subscribe]);

  useEffect(() => {
    if (!subscribed.current && isConnected) {
      subscribed.current = true;
      subscribe();
    }

    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe, isConnected]);

  return {
    error,
    isLoading,
    refetch,
    trid,
  };
};

export { useTRIDSubscription };
