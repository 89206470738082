import { fromString } from './dynamicDataElementHelper';
import { getPanelElementOption } from './getPanelElement';

export function ConvertEnum(e: any, value: number): string {
  const objectKeys = Object.keys(e);

  // eslint-disable-next-line no-restricted-syntax
  for (const element of objectKeys) {
    const key = element;
    const v: any = e[key];
    if (v === value) {
      return CleanEnum(objectKeys, key);
    }
  }

  return '';
}

export function getEnumFromParsedValue(e: any, value: string, withoutSpace: boolean | undefined = false): number | undefined {
  const foundVal = Object.values(e).find((key) => {
    let val = ConvertEnum(e, key as number);
    if (withoutSpace) {
      val = val.replaceAll(' ', '');
    }

    return val === value;
  });

  return foundVal as number ?? undefined;
}

export function CleanEnum(keys: string[], value: string): string {
  let updated = value.toLowerCase().replace(/.*_enum_/, '');

  // Temporary until we can find a better solution for enums that don't include _ENUM_ in the name.
  // If _ENUM_ isn't in the name then we need to scan the keys to find the enum prefix that we can strip off.
  if (updated === value.toLowerCase()) {
    const prefix = findLongestPrefix(keys);
    updated = updated.replace(prefix.toLowerCase(), '');
  }

  return updated
    .split('_')
    .map((x) => x.substring(0, 1).toUpperCase() + x.substring(1))
    .join(' ');
}

function findLongestPrefix(list: string[]) {
  const prefix = list[0];
  // Track the length of the longest prefix
  let prefixLen = prefix.length;
  // Loop over each of the items in the list and compare to the current prefix.
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < list.length && prefixLen > 0; i++) {
    const item = list[i];
    let matchLen = 0;
    const maxMatchLen = Math.min(item.length, prefixLen);
    // Once we find a difference we'll break and update the prefix length with the max prefix we've seen.
    // eslint-disable-next-line no-plusplus
    while (++matchLen < maxMatchLen) {
      if (item.charAt(matchLen) !== prefix.charAt(matchLen)) {
        break;
      }
    }
    prefixLen = matchLen;
  }
  return prefix.substring(0, prefixLen);
}

export function getRawEnum(e: any, value: number): string {
  const objectKeys = Object.keys(e);
  // eslint-disable-next-line no-restricted-syntax
  for (const element of objectKeys) {
    const key = element;
    const v: any = e[key];
    if (v === value) {
      return key;
    }
  }

  return '';
}

export const getEnumPanelElementOptionList = (e: any, filterUnknown = false, filterOptions?: Array<number>) => {
  let items = filterUnknown ? Object.values(e).filter((value) => value !== 0) : Object.values(e);
  
  // This map will reorder the original items based on the desired array
  if(filterOptions && filterOptions.length > 0 ){
    items = filterOptions.map((index: number) => items[index - 1])
  }
  
  return items.map((value) => getPanelElementOption({
    headerText: ConvertEnum(e, Number(value)),
    id: String(value),
    value: fromString(e[value as number]).toObject(),
  }));
};
