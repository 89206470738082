import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';

import { Typography } from './Typography';

export type TooltipPosition = 'bottom' | 'right';

export interface ITooltipProps {
  message: string;
  children: ReactNode;
  className?: string;
  position?: TooltipPosition;
}

const Tooltip = (props: ITooltipProps) => {
  const { children, className, message, position = 'bottom' } = props;

  const [visible, setVisible] = useState(false);
  const [childrenBounds, setChildrenBounds] = useState<DOMRect>();

  const childrenRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipPosition = useMemo(() => {
    if (childrenBounds) {
      if (position === 'bottom') {
        const tooltipRect = tooltipRef.current?.getBoundingClientRect();
        if (tooltipRect) {
          const windowWidth = Math.min(document.documentElement.clientWidth, window.innerWidth);
          const isOutOfBounds = (childrenBounds.right + tooltipRect.width) > windowWidth;
          if (isOutOfBounds) {
            return { left: childrenBounds.left - tooltipRect.width - 4 };
          }
          return { top: childrenBounds.bottom + 4 };
        }
      }

      if (position === 'right') {
        const tooltipRect = tooltipRef.current?.getBoundingClientRect();
        if (tooltipRect) {
          const windowWidth = Math.min(document.documentElement.clientWidth, window.innerWidth);
          const isOutOfBounds = (childrenBounds.right + tooltipRect.width) > windowWidth;
          if (isOutOfBounds) {
            return { left: childrenBounds.left - tooltipRect.width - 4 };
          }
          return { left: childrenBounds.right - 30, top: childrenBounds.bottom + 4 };
        }
      }
    }
    return { left: 0 };
  }, [childrenBounds, position]);

  return (
    <div
      className={clsx(className, 'w-full items-center flex justify-center')}
      data-testid="tooltip"
      onFocus={() => { }}
      onMouseLeave={() => setVisible(false)}
      onMouseOver={() => {
        setVisible(true);
        setChildrenBounds(childrenRef.current?.getBoundingClientRect());
      }}
      tabIndex={-1}
    >
      <div ref={childrenRef} className="cursor-pointer w-full">{children}</div>
      <div
        ref={tooltipRef}
        className={clsx(
          'fixed bg-on-unknown-black-inactive rounded py-1 px-2 pointer-events-none w-max max-w-[256px] z-50 ease-linear',
          {
            'opacity-0': !visible,
            'opacity-100': visible,
          },
        )}
        style={{
          ...tooltipPosition,
        }}
      >
        <Typography className="!text-on-unknown-white-solid select-none" variant="caption">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export { Tooltip };
