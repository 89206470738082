import { useEffect, useMemo, useState } from 'react';

import { AddressAutoComplete } from '@/Components/Atoms/AddressAutoComplete';
import type { SelectedValue as AutocompleteValue } from '@/Components/Atoms/AddressAutoComplete/AddressAutoComplete.component';
import { AutoComplete } from '@/Components/Atoms/AutoComplete';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { ListItem } from '@/Components/Atoms/ListItem';
import states from '@/Components/Atoms/StateSelect/states';
import { TextField } from '@/Components/Atoms/TextField';
import type { IGeoCodeData } from '@/Utils/Helpers/geoCodeConversion';

export interface AddressCardListItemProps {
  onSubmit: (geocode: IGeoCodeData, close: () => void) => void;
  isLoading?: boolean;
  title: string;
  progress?: IGeoCodeData;
}

const emptyProgress: IGeoCodeData = {
  additionalLineText: '',
  city: '',
  country: '',
  county: '',
  state: '',
  state_abbr: '',
  street: '',
  zip: '',
};

export const AddressCardListItem = (props: AddressCardListItemProps) => {
  const { isLoading, onSubmit, progress, title } = props;

  const [values, setValues] = useState<IGeoCodeData>(progress || emptyProgress);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (progress) setValues(progress);
  }, [progress]);

  const cardActions = useMemo((): Array<CardButton> => {
    if (isEditing) {
      return [
        { label: 'Cancel', onClick: () => setIsEditing(false), variant: 'tertiary' },
        { label: 'Save', onClick: () => onSubmit(values, () => setIsEditing(false)) },
      ];
    }
    return [{ label: 'Edit', onClick: () => setIsEditing(true), variant: 'tertiary' }];
  }, [isEditing, onSubmit, values]);

  const renderItems = () => (
    <>
      <ListItem caption={values?.street} isLoadingCaption={isLoading} label="Address" />
      <ListItem caption={values?.additionalLineText} isLoadingCaption={isLoading} label="Additional Line" />
      <ListItem caption={values?.city} isLoadingCaption={isLoading} label="City" />
      <ListItem caption={values.state} isLoadingCaption={isLoading} label="State" />
      <ListItem caption={values.zip} isLoadingCaption={isLoading} label="Zip" />
      <ListItem caption={values.plusFourZip} isLoadingCaption={isLoading} label="Plus Four Zipcode" />
    </>
  );

  const handleAddressSelect = (selectedValue: AutocompleteValue | null) => {
    if (selectedValue && values) {
      setValues({
        ...values,
        ...selectedValue.geocode,
      });
    }
  };

  const renderForm = () => (
    <>
      <AddressAutoComplete
        displayOnly
        onSelect={handleAddressSelect}
        placeholder="Address"
        value={values?.street}
      />
      <TextField
        onChange={(event) => setValues({ ...values, additionalLineText: event.target.value })}
        placeholder="Additional Line Text"
        value={values?.additionalLineText}
      />
      <TextField
        onChange={(event) => setValues({ ...values, city: event.target.value })}
        placeholder="City"
        value={values?.city}
      />
      <AutoComplete
        displayOnly
        onSelect={(selectedState) => setValues({ ...values, state: selectedState.name, state_abbr: selectedState.abbreviation })}
        optionKey="name"
        options={states}
        placeholder="State"
        value={values?.state}
      />
      <TextField
        onChange={(event) => setValues({ ...values, zip: event.target.value })}
        placeholder="Zipcode"
        value={values.zip}
      />
      <TextField
        onChange={(event) => setValues({ ...values, plusFourZip: event.target.value })}
        placeholder="Plus Four Zipcode"
        value={values.plusFourZip}
      />
    </>
  );

  return (
    <Card
      defaultOpen
      headerProps={{ actions: cardActions, title }}
      variant="borderless"
    >
      <div className="grid grid-cols-1 gap-6 tablet:grid-cols-2 desktop:grid-cols-3">
        {isEditing ? renderForm() : renderItems()}
      </div>
    </Card>
  );
};
