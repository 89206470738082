import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';

import { DynamicDataElementMap, toTimestamp } from './dynamicDataElementHelper';

const getDateFromTimestamp = (timestamp?: Timestamp.AsObject) => {
  if (timestamp?.seconds) {
    const date = new Date(1970, 0, 1);
    date.setSeconds(timestamp.seconds);
    return date;
  }
  return null;
};

const getTimestampFromDate = (date: string) => {
  if (date) {
    const timeMS = +new Date(date);
    const timestamp = new Timestamp();
    timestamp.setSeconds(timeMS / 1000);
    timestamp.setNanos((timeMS % 1000) * 1e6);
    return timestamp;
  } else {
    const timestamp = new Timestamp();
    timestamp.setNanos(0);
    timestamp.setSeconds(0);
    return timestamp;
  }
};

const getDateFormatFromTimestamp = (timestamp: Timestamp.AsObject | undefined) => {
  const date = getDateFromTimestamp(timestamp);
  if (date) {
    const yyyy = date.getFullYear();
    const monthNumber = date.getMonth() + 1;
    const dayNumber = date.getDate();

    let mm = `${monthNumber}`;
    let dd = `${dayNumber}`;
    if (dayNumber < 10) dd = `0${dd}`;
    if (monthNumber < 10) mm = `0${mm}`;

    return `${mm}/${dd}/${yyyy}`;
  }
  return '';
};

const getDateFromWilqoTimestamp = (timestamp?: DynamicDataElement.AsObject) => (
  timestamp
    ? getDateFormatFromTimestamp(toTimestamp(DynamicDataElementMap(timestamp)).getStorage()?.toObject())
    : undefined
);

const getInstaceFromTimestamp = (timestamp?: Timestamp.AsObject): Timestamp => {
  const instance = new Timestamp();
  instance.setNanos(timestamp?.nanos || 0);
  instance.setSeconds(timestamp?.seconds || 0);
  return instance;
};

const formatDateAndTime = (date: Date | null): string | undefined => {
  if (date) {
    const formattedDate = new Intl.DateTimeFormat('en-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);

    let formattedTime = new Intl.DateTimeFormat('en-GB', {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
    formattedTime = formattedTime.toUpperCase();
    return `${formattedDate} ${formattedTime}`;
  }
  return undefined;
};
const getCSTTimeFromTimestamp = (timestamp?: Timestamp.AsObject | undefined) => {
  const date = getDateFromTimestamp(timestamp);
  const formattedTime = date?.toLocaleTimeString(navigator.language, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });

  if (formattedTime) return formattedTime;
  return '';
};

export {
  formatDateAndTime,
  getCSTTimeFromTimestamp,
  getDateFormatFromTimestamp,
  getDateFromTimestamp,
  getDateFromWilqoTimestamp,
  getInstaceFromTimestamp,
  getTimestampFromDate,
};
