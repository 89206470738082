import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import type { FileItemProps } from './FileUploader.component';
import Component from './FileUploader.component';

export interface FileUploaderProps {
  panelElement: PanelElement.AsObject;
  onSelect?: (items: FileItemProps[]) => void;
}

interface IFile {
  fileId: string;
  fileName: string;
  mimeType: string;
}

const FileUploader = (props: FileUploaderProps) => {
  const { onSelect, panelElement: { disabled, id, path, requirement } } = props;

  const [value, setValue, { disabled: conditionallyDisabled, hidden }] = useDynamicFormRegister<IFile[]>(
    id,
    {
      fromDynamic: (dynamic) => JSON.parse(toString(dynamic)),
      toDynamic: (file) => fromString(JSON.stringify(file)),
    },
    { requirement },
  );

  const handleOnSelect = (items: FileItemProps[]) => {
    setValue(items, '', path);
    if (onSelect) onSelect(items);
  };

  const handleOnRemove = (removeId: string) => {
    setValue(value?.filter((v) => v.fileId !== removeId) || [], '', '');
  };

  if (hidden) return null;
  return (
    <Component
      disabled={disabled || conditionallyDisabled}
      files={value?.map((f) => ({ ...f, name: f.fileName }))}
      onRemoveFile={handleOnRemove}
      onSelect={handleOnSelect}
    />
  );
};

export default FileUploader;
