import { useMemo } from 'react';

import { Button } from '@/Components/Atoms/Button';

import { ExpandStatusTypeEnum, useWidgetContext } from '../content/WidgetContext';

export const ExpanderAction = () => {
  const { expandStatus, setExpandStatus } = useWidgetContext();

  const shouldExpandAll = useMemo(() => expandStatus === ExpandStatusTypeEnum.COLLAPSE_ALL
    || expandStatus === ExpandStatusTypeEnum.DEFAULT,
  [expandStatus]);

  const handleButtonClick = () => {
    if (shouldExpandAll) setExpandStatus(ExpandStatusTypeEnum.OPEN_ALL);
    else setExpandStatus(ExpandStatusTypeEnum.COLLAPSE_ALL);
  };

  return (
    <Button
      label={shouldExpandAll ? 'Expand all' : 'Collapse All'}
      leftIcon={shouldExpandAll ? 'UnfoldMore' : 'UnfoldLess'}
      onClick={handleButtonClick}
      variant="tertiary"
    />
  );
};
