import { useState } from 'react';

import type { EditableTableWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/EditableTable_pb';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { DeleteItemCollection } from '../DeleteItemCollection';
import { useWidgetContext } from '../WidgetContext';
import { DesktopEditableTable } from './DesktopEditableTable';
import { MobileEditableTable } from './MobileEditableTable';

export interface ExternalProps extends EditableTableWidgetSettings.AsObject {
  id: string;
}

export interface Props extends ExternalProps {
  handleDelete: (scope: string) => void;
}

export const EditableTable = (props: ExternalProps) => {
  const { isMobile } = useWindow();
  const { loanId } = useDynamicLoanInfo();
  const { setVersion } = useDynamicContext();
  const { reloadMainPage } = useWidgetContext();
  const { showSnackBar } = useShared();

  const [deleteItemScope, setDeleteItemScope] = useState<string | undefined>(undefined);

  const handleDeleteSuccess = (version: number) => {
    setVersion(version);
    reloadMainPage();
    setDeleteItemScope(undefined);
  };

  const handleDeleteError = () => {
    showSnackBar('Something went wrong');
    setDeleteItemScope(undefined);
  };
  return (
    <>
      {
        isMobile
          ? <MobileEditableTable {...props} handleDelete={setDeleteItemScope} />
          : <DesktopEditableTable {...props} handleDelete={setDeleteItemScope} />
      }
      {deleteItemScope && (
        <DeleteItemCollection
          close={() => setDeleteItemScope(undefined)}
          dealId={loanId}
          handleDeleteError={handleDeleteError}
          handleDeleteSuccess={handleDeleteSuccess}
          scopeToken={deleteItemScope}
        />
      )}
    </>
  );
};
