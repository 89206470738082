/* eslint-disable sonarjs/no-nested-template-literals */
import clsx from 'clsx';
import type { ReactNode } from 'react';

import type { IBadgeProps } from '@/Components/Atoms/Badge';
import { Badge } from '@/Components/Atoms/Badge';
import { Button } from '@/Components/Atoms/Button';
import { Icon } from '@/Components/Atoms/Icon';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';

const CLASS_BASE = 'flex flex-row items-center py-4 pl-3 w-full max-w-[300px] min-h-[72px]';

type TableTextCellFunction<T extends Record<string, unknown>> = (row: T) => string;
type TableTextCellKey<T extends Record<string, unknown>> = keyof T;

type TableText<T extends Record<string, unknown>> = TableTextCellFunction<T> | TableTextCellKey<T>;

export interface TableTextCell<T extends Record<string, unknown>> {
  text: TableText<T> | string;
  type: 'text';
  caption?: TableText<T>;
  badge?: IBadgeProps;
  onClick?: (originalItem: T) => void;
}

export interface TableBadgeCell {
  type: 'badge';
  badge: string;
}

export interface TableItemCell<T> {
  value: string | ((originalItem: T) => string);
  type: 'item';
  selected?: boolean;
  showOnHover?: boolean;
  onClick: (originalItem: T) => void;
}

export interface TableGenericCell {
  type: 'generic';
  children: string;
}

export interface AnyCell {
  type: 'any';
  value: (original: any) => JSX.Element | null;
}

export type TableCellProps<T extends Record<string, unknown>> = AnyCell | TableBadgeCell | TableGenericCell | TableItemCell<T> | TableTextCell<T>;

export interface Props<T extends Record<string, unknown>> {
  original: T;
  data: TableCellProps<T>;
  firstColumn: boolean;
  id: string;
}

export const TableCell = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { data, firstColumn, id, original } = props;
  const hasIcon = !!original[`${id}-icon`];
  const hasIconTooltip = !!original[`${id}-iconTooltip`];

  const renderTextCell = (data: TableTextCell<T>): JSX.Element => {
    const { badge, caption, onClick, text } = data;

    const renderText = (item: TableText<T>): string => {
      if (typeof item === 'string') {
        if (item.includes('.')) {
          const [key1, key2] = item.split('.');
          const arrayValue = original[key1];
          if (Array.isArray(arrayValue) && arrayValue.length > 0) {
            return `${arrayValue[0][key2]}${arrayValue.length > 1 ? ` +${arrayValue.length - 1}` : ''}`;
          }
          return '--';
        }
        const itemValue = original[item];
        return itemValue ? String(itemValue) : '--';
      }
      if (typeof item === 'function') {
        return item(original);
      }
      return '';
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (onClick) {
        e.stopPropagation();
        onClick(original);
      }
    };

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        onClick={handleClick}
        role="button"
        tabIndex={0}
      >
        <span
          className={
          clsx(
            CLASS_BASE,
          )
        }
        >
          <div
            className={
            clsx(
              'flex flex-col break-words', {
                'mr-0': !badge,
                'mr-3': Boolean(badge),
              },
            )
          }
          >
            <Typography
              className={
              clsx(
                {
                  'cursor-pointer text-primary-on-surface': onClick,
                  'inline-block mb-1': Boolean(caption),
                  'text-on-surface-active': !onClick && firstColumn,
                  'text-on-surface-inactive': !firstColumn && !onClick,
                },
              )
            }
              variant={badge ? 'body2Bold' : 'body2'}
            >
              {renderText(text)}
            </Typography>
            {caption && <Typography variant="caption">{renderText(caption)}</Typography>}
          </div>
          {badge && <Badge {...badge} />}
        </span>
      </div>
    );
  };

  const renderItemCell = (data: TableItemCell<T>): JSX.Element => {
    const { onClick, selected = false, showOnHover, value } = data;
    const label = typeof value === 'function' ? value(original) : value;

    return (
      <div
        className={
          clsx(
            CLASS_BASE,
            {
              '[&_span]:text-on-surface-inactive': firstColumn,
              'bg-on-surface-states-selected': selected,
              'item-hide': showOnHover,
            },
          )
        }
      >
        <Button label={label} onClick={() => onClick(original)} variant="tertiary" />
      </div>
    );
  };

  const renderBadgeCell = (data: TableBadgeCell): JSX.Element | null => {
    const { badge } = data;

    if (!original[badge]) {
      return null;
    }

    const properties = original[badge] as IBadgeProps;

    return (
      <div
        className={
          clsx(
            CLASS_BASE,
            {
              '[&_span]:text-on-surface-inactive': firstColumn,
            },
          )
        }
      >
        <Badge {...properties} />
      </div>
    );
  };

  const renderGenericCell = (data: TableGenericCell): JSX.Element => {
    const { children } = data;
    const renderChildren = original[children] as ReactNode;

    return (
      <div
        className={
          clsx(
            CLASS_BASE,
            {
              '[&_span]:text-on-surface-inactive': firstColumn,
            },
          )
        }
      >
        {renderChildren}
      </div>
    );
  };

  const renderAnyCell = (data: AnyCell): JSX.Element | null => (
    <div className={CLASS_BASE}>
      {data.value(original)}
    </div>
  );

  const renderIconCell = (): JSX.Element | null => {
    if (hasIconTooltip) {
      return (
        <div className={`${CLASS_BASE} relative`}>
          <Tooltip message={String(original[`${id}-iconTooltip`])} position="right">
            <Icon className="text-primary-on-surface" icon={String(original[`${id}-icon`])} />
          </Tooltip>
        </div>
      );
    }
    return (
      <div className={CLASS_BASE}>
        <Icon className="text-primary-on-surface" icon={String(original[`${id}-icon`])} />
      </div>
    );
  };

  if (data.type === 'item') return renderItemCell(data);
  if (data.type === 'badge') return renderBadgeCell(data);
  if (data.type === 'generic') return renderGenericCell(data);
  if (data.type === 'any') return renderAnyCell(data);
  if (hasIcon) return renderIconCell();
  return renderTextCell(data);
};
