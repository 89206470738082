import { Outlet, useParams } from 'react-router';

import { useUser } from '@/API/Queries/user/useUser';
import { ContextualAppBar } from '@/Components/Atoms/ContextualAppBar';
import { Tabs } from '@/Components/Atoms/Tab';
import { useNavigate } from '@/Routes/NavigationContext';

const UpdateUser = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { data: user, isLoading } = useUser(id);

  return (
    <div className="w-full h-full flex flex-col">
      <ContextualAppBar
        icon="ArrowBack"
        loading={isLoading}
        onClickIcon={() => navigate(-1)}
        title={user?.displayName}
      />
      <div className="flex-1 w-full">
        <Tabs
          tabsData={[
            { label: 'Overview', linkTo: `/admin/user/${id}/overview` },
            { label: 'Organizations', linkTo: `/admin/user/${id}/organizations` },
            { label: 'Skillset Groups', linkTo: `/admin/user/${id}/skillsetGroups` },
            { label: 'Party Roles', linkTo: `/admin/user/${id}/partyRoles` },
            { label: 'Licensing', linkTo: `/admin/user/${id}/licensing` },
            { label: 'Lead Sources', linkTo: `/admin/user/${id}/leadSources` },
            { label: 'Loan Access', linkTo: `/admin/user/${id}/loanAccess` },
          ]}
        />
        <div className="p-12">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export { UpdateUser };
