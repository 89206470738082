import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateUserCommandRequest, UserCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.UpdateUserCommandRequest';

interface UpdateObject {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profileImageUrl: string;
  nationalLicensingId: string;
  skillsetGroupIds: Array<string>;
}

const useUpdateUser = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const UpdateUserCommand = async (updateObject: UpdateObject) => {
    const { email,
      firstName,
      lastName,
      nationalLicensingId,
      phoneNumber,
      profileImageUrl,
      userId,
    } = updateObject;
    const request = new UpdateUserCommandRequest();
    request.setId(userId);
    request.setFirstName(firstName);
    request.setLastName(lastName);
    request.setEmail(email);
    request.setNationalLicensingId(nationalLicensingId);
    request.setBusinessProcessDomainId(bpdId);
    request.setPhoneNumber(phoneNumber);
    request.setProfileImageUrl(profileImageUrl);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new UserCommandResponse();
    UserCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.user);
  };

  return useMutation(UpdateUserCommand);
};

export { useUpdateUser };
