import { PanelElement } from "@/API/Models/Wilqo.Shared.Models/ActivityModels_pb";
import { ExtendedConverter } from "@/Components/Features/dynamicForm/useDynamicFormRegister";
import { DynamicDataElementMap, fromNull, fromNumber, fromString, fromTimestamp, toNumber, toString } from "./dynamicDataElementHelper";
import { WilqoTimestamp } from "@/API/Models/Wilqo.Shared.Models/Timestamp_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { getDateFromWilqoTimestamp } from "./getDateFromTimestamp";
import { DynamicDataElement, DynamicDataElementDataTypeEnum } from "@/API/Models/Wilqo.Shared.Models/DynamicData_pb";
import { PanelElementExtended } from "./getPanelElement";

export const useDynamicValueConverters = (panelElement: PanelElementExtended) => {
  const getConverters = () => {
    const converters = {
      textInputConverters: {
        fromDynamic: (dynamic: DynamicDataElement) => {
          if (dynamic.getDataType() === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) return '';
          if (panelElement.type === 'number') {
            const value = dynamic.getValue();
            if (value) {
              return toNumber(dynamic).toString();
            }
            return '';
          }
  
          if (panelElement.type === 'date') {
            return getDateFromWilqoTimestamp(dynamic.toObject()) || '';
          }
  
          return toString(dynamic);
        },
        toDynamic: (value?: string) => {
          if (panelElement.type === 'number') {
            const digitOnly = value?.replace(/[^-\d.]/g, '');
            if (digitOnly === '.' || digitOnly === '--' || !value) return fromNull();
            const n = Number(digitOnly);
            return fromNumber(n);
          }
          // todo remove once date picker component is ready
          if (panelElement.type === 'date') {
            if (value && !value.includes('Y') && value !== '--') {
              const wilqoTimestamp = new WilqoTimestamp();
              wilqoTimestamp.setStorage(Timestamp.fromDate(new Date(value)));
              return fromTimestamp(wilqoTimestamp);
            }
            return fromNull();
          }
          return fromString(value);
        },
      },
      addressAutoCompleteConverters: {
        fromDynamic: (v:DynamicDataElement) => {
          if (v.toArray().length > 1 && v.toArray()[1] !== null) {
            return toString(v);
          }
          return '';
        },
        toDynamic: (v?: string) => {
          if (v) {
            return fromString(v);
          }
          return fromString('');
        },
      },
      hiddenElementConverters: {
        fromDynamic: (dynamic: DynamicDataElement) => dynamic.toObject(),
        toDynamic: (value?: DynamicDataElement.AsObject) => {
          if (value) {
            return DynamicDataElementMap(value);
          }
          return DynamicDataElementMap({ dataType: DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL, value: '' });
        },
      }
    }
  
    switch (panelElement.type) {
      case 'text':
      case 'date':
      case 'number':
      case 'text-area':
        return converters.textInputConverters as ExtendedConverter<string>
  
      case 'auto-complete-postal-code':
      case 'auto-complete-address':
      case 'auto-complete-address-international':
        return converters.addressAutoCompleteConverters as ExtendedConverter<string>
  
      default:
        return converters.hiddenElementConverters as ExtendedConverter<DynamicDataElement.AsObject>
    }
  }

  const fromDynamicObject = (value: DynamicDataElement.AsObject) => {
    switch (panelElement.type) {
      case 'text':
      case 'date':
      case 'number':
      case 'text-area':
      //adress auto complete
      case 'auto-complete-postal-code':
      case 'auto-complete-address':
      case 'auto-complete-address-international':
        return toString(DynamicDataElementMap(value))
      case 'multi-select':
      case 'select':
      case 'checkbox':
        return value;
      default:
        return undefined
    }
  }

  return {
    converters: getConverters() as ExtendedConverter<any>,
    fromDynamicObject,
  }
}