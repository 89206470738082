// source: Wilqo_API_Mortgage_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('./Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb = require('./Wilqo.Shared.Models/WilqoPartyTypeMetadataConfig_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb);
var Wilqo_API_Mortgage_Models_pb = require('./Wilqo_API_Mortgage_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Models_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb = require('./Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb);
var Wilqo_API_Mortgage_DocGen_Models_pb = require('./Wilqo.API.Mortgage.DocGen/Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DocGen_Models_pb);
var Wilqo_Shared_Models_PaginationModels_pb = require('./Wilqo.Shared.Models/PaginationModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_PaginationModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceReportContentPanel', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentTypeOption', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ExportConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ExportConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeePermissions', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FraudOrderingLogPanel', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllPartiesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllPartiesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.StateCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealCommentsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealCommentsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealJsonQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealJsonQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealXmlQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDealXmlQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanConditionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanConditionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansCountQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansCountQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetPartyQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetPartyQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanConditionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanConditionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanConditionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanConditionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllPartiesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAllPartiesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllPartiesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAllPartiesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.displayName = 'proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansCountQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansCountQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoansCountQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoansCountQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoansCountQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoansCountQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetPartyQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetPartyQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetPartyQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetPartyQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetPartyQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetPartyQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealJsonQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealJsonQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDealJsonQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealJsonQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealJsonQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDealJsonQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.displayName = 'proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.displayName = 'proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeePermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeePermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeePermissions.displayName = 'proto.wilqo.api.mortgage.FeePermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceReportContentPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceReportContentPanel.displayName = 'proto.wilqo.api.mortgage.ComplianceReportContentPanel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FraudOrderingLogPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FraudOrderingLogPanel.displayName = 'proto.wilqo.api.mortgage.FraudOrderingLogPanel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ExportConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.displayName = 'proto.wilqo.api.mortgage.ExportConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ExportConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.displayName = 'proto.wilqo.api.mortgage.ExportConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealXmlQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealXmlQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDealXmlQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealXmlQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealXmlQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDealXmlQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentTypeOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentTypeOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentTypeOption.displayName = 'proto.wilqo.api.mortgage.DocumentTypeOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealCommentsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetDealCommentsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetDealCommentsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetDealCommentsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.displayName = 'proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.displayName = 'proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.displayName = 'proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest;
  return proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanLender: (f = msg.getLoanLender()) && Wilqo_API_Mortgage_Models_pb.LoanLender.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse;
  return proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanLender;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanLender.deserializeBinaryFromReader);
      msg.setLoanLender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanLender();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanLender.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LoanLender loan_lender = 2;
 * @return {?proto.wilqo.api.mortgage.LoanLender}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.getLoanLender = function() {
  return /** @type{?proto.wilqo.api.mortgage.LoanLender} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanLender, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.LoanLender|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.setLoanLender = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.clearLoanLender = function() {
  return this.setLoanLender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLenderLoanByIdQueryResponse.prototype.hasLoanLender = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest;
  return proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanSummariesList: jspb.Message.toObjectList(msg.getLoanSummariesList(),
    Wilqo_API_Mortgage_Models_pb.LoanSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse;
  return proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanSummary.deserializeBinaryFromReader);
      msg.addLoanSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanSummary loan_summaries = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanSummary>}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.getLoanSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanSummary>} value
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.setLoanSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.addLoanSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.LoanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansForInstitutionQueryResponse.prototype.clearLoanSummariesList = function() {
  return this.setLoanSummariesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    conditionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanConditionQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string condition_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    condition: (f = msg.getCondition()) && Wilqo_API_Mortgage_Models_pb.ConditionSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanConditionQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ConditionSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ConditionSummary.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ConditionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ConditionSummary condition = 2;
 * @return {?proto.wilqo.api.mortgage.ConditionSummary}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.getCondition = function() {
  return /** @type{?proto.wilqo.api.mortgage.ConditionSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.ConditionSummary, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.ConditionSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanConditionQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanConditionQueryResponse.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest;
  return proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCreditBorrowersByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    creditBorrowersList: jspb.Message.toObjectList(msg.getCreditBorrowersList(),
    Wilqo_API_Mortgage_Models_pb.CreditBorrower.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse;
  return proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.CreditBorrower;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CreditBorrower.deserializeBinaryFromReader);
      msg.addCreditBorrowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCreditBorrowersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.CreditBorrower.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CreditBorrower credit_borrowers = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.CreditBorrower>}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.getCreditBorrowersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CreditBorrower>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CreditBorrower, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CreditBorrower>} value
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.setCreditBorrowersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CreditBorrower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CreditBorrower}
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.addCreditBorrowers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.CreditBorrower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCreditBorrowersQueryResponse.prototype.clearCreditBorrowersList = function() {
  return this.setCreditBorrowersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyAddressLineText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    propertyAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertyCity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    propertyStateCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    propertyZip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    borrowerSocialSecurityNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    borrowerDateOfBirth: jspb.Message.getFieldWithDefault(msg, 7, ""),
    borrowerFirstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    borrowerLastName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    propertyFullAddress: jspb.Message.getFieldWithDefault(msg, 10, ""),
    loanNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest;
  return proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyAddressLineText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyAdditionalLineText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyStateCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyZip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerSocialSecurityNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerDateOfBirth(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerFirstName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerLastName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyFullAddress(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPropertyAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertyCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPropertyStateCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPropertyZip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBorrowerSocialSecurityNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBorrowerDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBorrowerFirstName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBorrowerLastName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPropertyFullAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string property_address_line_text = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string property_additional_line_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string property_city = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string property_state_code = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string property_zip = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyZip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string borrower_social_security_no = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getBorrowerSocialSecurityNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setBorrowerSocialSecurityNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string borrower_date_of_birth = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getBorrowerDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setBorrowerDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string borrower_first_name = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getBorrowerFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setBorrowerFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string borrower_last_name = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getBorrowerLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setBorrowerLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string property_full_address = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getPropertyFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setPropertyFullAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string loan_number = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string business_process_domain_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loansList: jspb.Message.toObjectList(msg.getLoansList(),
    Wilqo_API_Mortgage_Models_pb.LoanSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse;
  return proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanSummary.deserializeBinaryFromReader);
      msg.addLoans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanSummary loans = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanSummary>}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.getLoansList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanSummary>} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.setLoansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.addLoans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySocialAndAddressQueryResponse.prototype.clearLoansList = function() {
  return this.setLoansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest;
  return proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealMortgageTenancy: (f = msg.getDealMortgageTenancy()) && Wilqo_API_Mortgage_Models_pb.DealMortgageTenancy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse;
  return proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DealMortgageTenancy;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DealMortgageTenancy.deserializeBinaryFromReader);
      msg.setDealMortgageTenancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealMortgageTenancy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DealMortgageTenancy.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DealMortgageTenancy deal_mortgage_tenancy = 2;
 * @return {?proto.wilqo.api.mortgage.DealMortgageTenancy}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.getDealMortgageTenancy = function() {
  return /** @type{?proto.wilqo.api.mortgage.DealMortgageTenancy} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DealMortgageTenancy, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DealMortgageTenancy|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.setDealMortgageTenancy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.clearDealMortgageTenancy = function() {
  return this.setDealMortgageTenancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealTenancyInformationQueryResponse.prototype.hasDealMortgageTenancy = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealEntityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllPartiesQueryRequest;
  return proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.getDealEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.setDealEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    partiesList: jspb.Message.toObjectList(msg.getPartiesList(),
    Wilqo_API_Mortgage_Models_pb.PartySummary.toObject, includeInstance),
    loanOriginator: (f = msg.getLoanOriginator()) && Wilqo_API_Mortgage_Models_pb.LoanOriginator.toObject(includeInstance, f),
    lender: (f = msg.getLender()) && Wilqo_API_Mortgage_Models_pb.Lender.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllPartiesQueryResponse;
  return proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.PartySummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.PartySummary.deserializeBinaryFromReader);
      msg.addParties(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanOriginator;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanOriginator.deserializeBinaryFromReader);
      msg.setLoanOriginator(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_Models_pb.Lender;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.Lender.deserializeBinaryFromReader);
      msg.setLender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPartiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.PartySummary.serializeBinaryToWriter
    );
  }
  f = message.getLoanOriginator();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanOriginator.serializeBinaryToWriter
    );
  }
  f = message.getLender();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_Models_pb.Lender.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PartySummary parties = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.PartySummary>}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.getPartiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.PartySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.PartySummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.PartySummary>} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.setPartiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.PartySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.addParties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.PartySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.clearPartiesList = function() {
  return this.setPartiesList([]);
};


/**
 * optional LoanOriginator loan_originator = 3;
 * @return {?proto.wilqo.api.mortgage.LoanOriginator}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.getLoanOriginator = function() {
  return /** @type{?proto.wilqo.api.mortgage.LoanOriginator} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanOriginator, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.LoanOriginator|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.setLoanOriginator = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.clearLoanOriginator = function() {
  return this.setLoanOriginator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.hasLoanOriginator = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Lender lender = 4;
 * @return {?proto.wilqo.api.mortgage.Lender}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.getLender = function() {
  return /** @type{?proto.wilqo.api.mortgage.Lender} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.Lender, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.Lender|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.setLender = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.clearLender = function() {
  return this.setLender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllPartiesQueryResponse.prototype.hasLender = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest;
  return proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    progressSummary: (f = msg.getProgressSummary()) && Wilqo_API_Mortgage_Models_pb.ProgressSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse;
  return proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ProgressSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ProgressSummary.deserializeBinaryFromReader);
      msg.setProgressSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProgressSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ProgressSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProgressSummary progress_summary = 2;
 * @return {?proto.wilqo.api.mortgage.ProgressSummary}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.getProgressSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.ProgressSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.ProgressSummary, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.ProgressSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.setProgressSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.clearProgressSummary = function() {
  return this.setProgressSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetProgressSummaryQueryResponse.prototype.hasProgressSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageConfig: (f = msg.getPageConfig()) && Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.toObject(includeInstance, f),
    filterConfig: (f = msg.getFilterConfig()) && proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest;
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentSourceType} */ (reader.readEnum());
      msg.setSourceType(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig;
      reader.readMessage(value,Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.deserializeBinaryFromReader);
      msg.setPageConfig(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig;
      reader.readMessage(value,proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.deserializeBinaryFromReader);
      msg.setFilterConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig.serializeBinaryToWriter
    );
  }
  f = message.getFilterConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.repeatedFields_ = [1,2,3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    documentTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    statusesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    searchTerm: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentCategoriesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig;
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDocumentTypes(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentStatusEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDocumentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * repeated string borrower_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};


/**
 * repeated DocumentEnum document_types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentEnum>}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getDocumentTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setDocumentTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.addDocumentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearDocumentTypesList = function() {
  return this.setDocumentTypesList([]);
};


/**
 * repeated DocumentStatusEnum statuses = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentStatusEnum>}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentStatusEnum>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentStatusEnum>} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional google.protobuf.Timestamp start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string search_term = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string document_categories = 7;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.getDocumentCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.setDocumentCategoriesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.addDocumentCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig.prototype.clearDocumentCategoriesList = function() {
  return this.setDocumentCategoriesList([]);
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocumentSourceType source_type = 3;
 * @return {!proto.wilqo.api.mortgage.DocumentSourceType}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.getSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentSourceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentSourceType} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.setSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional wilqo.shared.models.PaginationConfig page_config = 4;
 * @return {?proto.wilqo.shared.models.PaginationConfig}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.getPageConfig = function() {
  return /** @type{?proto.wilqo.shared.models.PaginationConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_PaginationModels_pb.PaginationConfig, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PaginationConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.setPageConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.clearPageConfig = function() {
  return this.setPageConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.hasPageConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DocumentsFilterConfig filter_config = 5;
 * @return {?proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.getFilterConfig = function() {
  return /** @type{?proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.DocumentsFilterConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.setFilterConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.clearFilterConfig = function() {
  return this.setFilterConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryRequest.prototype.hasFilterConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    Wilqo_API_Mortgage_Models_pb.DocumentSummary.toObject, includeInstance),
    pageDetails: (f = msg.getPageDetails()) && Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse;
  return proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentSummary.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails;
      reader.readMessage(value,Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.deserializeBinaryFromReader);
      msg.setPageDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentSummary.serializeBinaryToWriter
    );
  }
  f = message.getPageDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DocumentSummary documents = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentSummary>}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentSummary>} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentSummary}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.DocumentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional wilqo.shared.models.PaginationDetails page_details = 3;
 * @return {?proto.wilqo.shared.models.PaginationDetails}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.getPageDetails = function() {
  return /** @type{?proto.wilqo.shared.models.PaginationDetails} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_PaginationModels_pb.PaginationDetails, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PaginationDetails|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.setPageDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.clearPageDetails = function() {
  return this.setPageDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllLoanDocumentsQueryResponse.prototype.hasPageDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    document: (f = msg.getDocument()) && Wilqo_API_Mortgage_Models_pb.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.Document;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Document document = 2;
 * @return {?proto.wilqo.api.mortgage.Document}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.getDocument = function() {
  return /** @type{?proto.wilqo.api.mortgage.Document} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.Document, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.Document|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanDocumentQueryResponse.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest;
  return proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse;
  return proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ComplianceTestListItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem}
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.ComplianceTestListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFraudChecksForSidesheetQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest;
  return proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse;
  return proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ChangeHistoryItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.ChangeHistoryItem>}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ChangeHistoryItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ChangeHistoryItem>} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ChangeHistoryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem}
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.ChangeHistoryItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryForSidesheetQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest;
  return proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    item: (f = msg.getItem()) && Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse;
  return proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ChangeHistoryItem item = 2;
 * @return {?proto.wilqo.api.mortgage.ChangeHistoryItem}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.getItem = function() {
  return /** @type{?proto.wilqo.api.mortgage.ChangeHistoryItem} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.ChangeHistoryItem, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.ChangeHistoryItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetChangeHistoryByIdQueryResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest;
  return proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse;
  return proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ComplianceTestListItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ComplianceTestListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem}
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.ComplianceTestListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetComplianceChecksForSidesheetQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest;
  return proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder = {
  SORT_ORDER_UNKNOWN: 0,
  SORT_ORDER_NEWEST_FIRST: 1,
  SORT_ORDER_OLDEST_FIRST: 2
};

/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SortOrder sort_order = 3;
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.getSortOrder = function() {
  return /** @type {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.SortOrder} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryRequest.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    integrationHistoryList: jspb.Message.toObjectList(msg.getIntegrationHistoryList(),
    Wilqo_API_Mortgage_Models_pb.IntegrationHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse;
  return proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.IntegrationHistory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.IntegrationHistory.deserializeBinaryFromReader);
      msg.addIntegrationHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.IntegrationHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated IntegrationHistory integration_history = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.IntegrationHistory>}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.getIntegrationHistoryList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.IntegrationHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.IntegrationHistory, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.IntegrationHistory>} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.setIntegrationHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegrationHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory}
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.addIntegrationHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.IntegrationHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationHistoryQueryResponse.prototype.clearIntegrationHistoryList = function() {
  return this.setIntegrationHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest;
  return proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanPagesList: jspb.Message.toObjectList(msg.getLoanPagesList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse;
  return proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.addLoanPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.mortgage_dynamic_data.LoanPage loan_pages = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.getLoanPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} value
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.setLoanPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.addLoanPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntegrationResponseStatusQueryResponse.prototype.clearLoanPagesList = function() {
  return this.setLoanPagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchphrase: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest;
  return proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchphrase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchphrase();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string searchPhrase = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.getSearchphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.setSearchphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanSummariesList: jspb.Message.toObjectList(msg.getLoanSummariesList(),
    Wilqo_API_Mortgage_Models_pb.LoanSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse;
  return proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanSummary.deserializeBinaryFromReader);
      msg.addLoanSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanSummary loan_summaries = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanSummary>}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.getLoanSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanSummary>} value
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.setLoanSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.addLoanSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.LoanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansBySearchTermsQueryResponse.prototype.clearLoanSummariesList = function() {
  return this.setLoanSummariesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansCountQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansCountQueryRequest;
  return proto.wilqo.api.mortgage.GetLoansCountQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansCountQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoansCountQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoansCountQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    accessibleLoansCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoansCountQueryResponse;
  return proto.wilqo.api.mortgage.GetLoansCountQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccessibleLoansCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoansCountQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAccessibleLoansCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 accessible_loans_count = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.getAccessibleLoansCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.GetLoansCountQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoansCountQueryResponse.prototype.setAccessibleLoansCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchphrase: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchphrase(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchphrase();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string searchPhrase = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.getSearchphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.setSearchphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanSummariesList: jspb.Message.toObjectList(msg.getLoanSummariesList(),
    Wilqo_API_Mortgage_Models_pb.LoanSummary.toObject, includeInstance),
    showaddbutton: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanSummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanSummary.deserializeBinaryFromReader);
      msg.addLoanSummaries(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowaddbutton(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanSummary.serializeBinaryToWriter
    );
  }
  f = message.getShowaddbutton();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanSummary loan_summaries = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanSummary>}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.getLoanSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanSummary>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.setLoanSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.addLoanSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.LoanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.clearLoanSummariesList = function() {
  return this.setLoanSummariesList([]);
};


/**
 * optional bool showAddButton = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.getShowaddbutton = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanListsViewModelQueryResponse.prototype.setShowaddbutton = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetPartyQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryRequest}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetPartyQueryRequest;
  return proto.wilqo.api.mortgage.GetPartyQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryRequest}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetPartyQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string party_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetPartyQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    individual: (f = msg.getIndividual()) && Wilqo_API_Mortgage_Models_pb.Individual.toObject(includeInstance, f),
    wilqoUserIdentifier: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetPartyQueryResponse;
  return proto.wilqo.api.mortgage.GetPartyQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.Individual;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.Individual.deserializeBinaryFromReader);
      msg.setIndividual(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWilqoUserIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetPartyQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetPartyQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndividual();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.Individual.serializeBinaryToWriter
    );
  }
  f = message.getWilqoUserIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Individual individual = 3;
 * @return {?proto.wilqo.api.mortgage.Individual}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.getIndividual = function() {
  return /** @type{?proto.wilqo.api.mortgage.Individual} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.Individual, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.Individual|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.setIndividual = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.clearIndividual = function() {
  return this.setIndividual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.hasIndividual = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string wilqo_user_identifier = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.getWilqoUserIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetPartyQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetPartyQueryResponse.prototype.setWilqoUserIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealJsonQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealJsonQueryRequest;
  return proto.wilqo.api.mortgage.GetDealJsonQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealJsonQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealJsonQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealJsonQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealJson: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealJsonQueryResponse;
  return proto.wilqo.api.mortgage.GetDealJsonQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealJsonQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_json = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.getDealJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealJsonQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealJsonQueryResponse.prototype.setDealJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest;
  return proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentCategoriesList: jspb.Message.toObjectList(msg.getDocumentCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.DocumentCategory.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse;
  return proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentCategory.deserializeBinaryFromReader);
      msg.addDocumentCategories(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentCategory.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DocumentCategory document_categories = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentCategory>}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.getDocumentCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentCategory, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentCategory>} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.setDocumentCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.addDocumentCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.DocumentCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.clearDocumentCategoriesList = function() {
  return this.setDocumentCategoriesList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDocumentCategoriesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    providerOptionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loanProspectorTrackId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    desktopUnderwriterTrackId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    desktopOriginatorTrackId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    submissionType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lenderId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lenderName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    loanProcessingStage: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest;
  return proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderOptions(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProspectorTrackId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopUnderwriterTrackId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopOriginatorTrackId(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} */ (reader.readEnum());
      msg.setSubmissionType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} */ (reader.readEnum());
      msg.setLoanProcessingStage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProviderOptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getLoanProspectorTrackId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDesktopUnderwriterTrackId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDesktopOriginatorTrackId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubmissionType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLenderId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLenderName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLoanProcessingStage();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AusProvider provider_options = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.AusProvider>}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getProviderOptionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusProvider>} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setProviderOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusProvider} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.addProviderOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.clearProviderOptionsList = function() {
  return this.setProviderOptionsList([]);
};


/**
 * optional string loan_prospector_track_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getLoanProspectorTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setLoanProspectorTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string desktop_underwriter_track_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getDesktopUnderwriterTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setDesktopUnderwriterTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string desktop_originator_track_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getDesktopOriginatorTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setDesktopOriginatorTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DesktopOriginatorSubmissionType submission_type = 8;
 * @return {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getSubmissionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setSubmissionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string lender_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getLenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setLenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lender_name = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getLenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setLenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string business_process_domain_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional LoanProductAdvisorLoanProcessingStageEnum loan_processing_stage = 12;
 * @return {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.getLoanProcessingStage = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryRequest.prototype.setLoanProcessingStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse;
  return proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RunAusIntegrationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest;
  return proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentCategory: (f = msg.getDocumentCategory()) && Wilqo_API_Mortgage_Models_pb.DocumentCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse;
  return proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentCategory.deserializeBinaryFromReader);
      msg.setDocumentCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocumentCategory document_category = 2;
 * @return {?proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.getDocumentCategory = function() {
  return /** @type{?proto.wilqo.api.mortgage.DocumentCategory} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentCategory, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DocumentCategory|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.clearDocumentCategory = function() {
  return this.setDocumentCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDocumentCategoryByIdQueryResponse.prototype.hasDocumentCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest;
  return proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    intentToProceedReceived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    intentToProceedReceivedDate: (f = msg.getIntentToProceedReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    borrowersIntentToProceedStatusList: jspb.Message.toObjectList(msg.getBorrowersIntentToProceedStatusList(),
    Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceedStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse;
  return proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIntentToProceedReceived(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIntentToProceedReceivedDate(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceedStatus;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceedStatus.deserializeBinaryFromReader);
      msg.addBorrowersIntentToProceedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIntentToProceedReceived();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIntentToProceedReceivedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBorrowersIntentToProceedStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceedStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool intent_to_proceed_received = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.getIntentToProceedReceived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.setIntentToProceedReceived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp intent_to_proceed_received_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.getIntentToProceedReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.setIntentToProceedReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.clearIntentToProceedReceivedDate = function() {
  return this.setIntentToProceedReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.hasIntentToProceedReceivedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated BorrowerIntentToProceedStatus borrowers_intent_to_proceed_status = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus>}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.getBorrowersIntentToProceedStatusList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.BorrowerIntentToProceedStatus, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus>} value
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.setBorrowersIntentToProceedStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus}
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.addBorrowersIntentToProceedStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetIntentToProceedStatusQueryResponse.prototype.clearBorrowersIntentToProceedStatusList = function() {
  return this.setBorrowersIntentToProceedStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    Wilqo_API_Mortgage_Models_pb.CardListItem.toObject, includeInstance),
    calculationListList: jspb.Message.toObjectList(msg.getCalculationListList(),
    Wilqo_API_Mortgage_Models_pb.CalculationListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.CardListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.CalculationListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CalculationListItem.deserializeBinaryFromReader);
      msg.addCalculationList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.CardListItem.serializeBinaryToWriter
    );
  }
  f = message.getCalculationListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.CalculationListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CardListItem card_list = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CardListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};


/**
 * repeated CalculationListItem calculation_list = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.CalculationListItem>}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.getCalculationListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CalculationListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CalculationListItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CalculationListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.setCalculationListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CalculationListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CalculationListItem}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.addCalculationList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.CalculationListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageHeaderQueryResponse.prototype.clearCalculationListList = function() {
  return this.setCalculationListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sectionName: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest;
  return proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setSectionName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSectionName();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IntegratedDisclosureSectionEnum section_name = 3;
 * @return {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.getSectionName = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.setSectionName = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    loanAvailableFeesInfoList: jspb.Message.toObjectList(msg.getLoanAvailableFeesInfoList(),
    Wilqo_API_Mortgage_Models_pb.LoanAvailableFeeInfo.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse;
  return proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanAvailableFeeInfo;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanAvailableFeeInfo.deserializeBinaryFromReader);
      msg.addLoanAvailableFeesInfo(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoanAvailableFeesInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanAvailableFeeInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoanAvailableFeeInfo loan_available_fees_info = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanAvailableFeeInfo>}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.getLoanAvailableFeesInfoList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanAvailableFeeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanAvailableFeeInfo, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanAvailableFeeInfo>} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.setLoanAvailableFeesInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.addLoanAvailableFeesInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.LoanAvailableFeeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.clearLoanAvailableFeesInfoList = function() {
  return this.setLoanAvailableFeesInfoList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAvailableFeeQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    queryFromSandbox: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueryFromSandbox(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryFromSandbox();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool query_from_sandbox = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.getQueryFromSandbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.setQueryFromSandbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    Wilqo_API_Mortgage_Models_pb.CardListItem.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.CardListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.CardListItem.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardListItem card_list = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CardListItem, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFeeHeaderQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    queryFromSandbox: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueryFromSandbox(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQueryFromSandbox();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool query_from_sandbox = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.getQueryFromSandbox = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.setQueryFromSandbox = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.repeatedFields_ = [1,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeCategoriesList: jspb.Message.toObjectList(msg.getFeeCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    disabledColumnsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    escrowsList: jspb.Message.toObjectList(msg.getEscrowsList(),
    Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.toObject, includeInstance),
    prepaidsList: jspb.Message.toObjectList(msg.getPrepaidsList(),
    Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.toObject, includeInstance),
    feePermissions: (f = msg.getFeePermissions()) && proto.wilqo.api.mortgage.FeePermissions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.deserializeBinaryFromReader);
      msg.addFeeCategories(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisabledColumns(value);
      break;
    case 6:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.deserializeBinaryFromReader);
      msg.addEscrows(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.deserializeBinaryFromReader);
      msg.addPrepaids(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.FeePermissions;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeePermissions.deserializeBinaryFromReader);
      msg.setFeePermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDisabledColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEscrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getFeePermissions();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.FeePermissions.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoanFeeCategory fee_categories = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getFeeCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanFeeCategory, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setFeeCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.addFeeCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.LoanFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearFeeCategoriesList = function() {
  return this.setFeeCategoriesList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string disabled_columns = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getDisabledColumnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setDisabledColumnsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.addDisabledColumns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearDisabledColumnsList = function() {
  return this.setDisabledColumnsList([]);
};


/**
 * repeated LoanEscrowFeeCategory escrows = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getEscrowsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanEscrowFeeCategory, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanEscrowFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setEscrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.addEscrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.LoanEscrowFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearEscrowsList = function() {
  return this.setEscrowsList([]);
};


/**
 * repeated LoanPrepaidFeeCategory prepaids = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getPrepaidsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanPrepaidFeeCategory, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setPrepaidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.addPrepaids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.LoanPrepaidFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearPrepaidsList = function() {
  return this.setPrepaidsList([]);
};


/**
 * optional FeePermissions fee_permissions = 8;
 * @return {?proto.wilqo.api.mortgage.FeePermissions}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.getFeePermissions = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeePermissions} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.FeePermissions, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeePermissions|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.setFeePermissions = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.clearFeePermissions = function() {
  return this.setFeePermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFeeDetailQueryResponse.prototype.hasFeePermissions = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeePermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeePermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeePermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    manageFees: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    runFees: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    compareApplyToleranceCure: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    compareEditLastDisclosed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    editFeeDetailValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeePermissions}
 */
proto.wilqo.api.mortgage.FeePermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeePermissions;
  return proto.wilqo.api.mortgage.FeePermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeePermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeePermissions}
 */
proto.wilqo.api.mortgage.FeePermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManageFees(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunFees(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompareApplyToleranceCure(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompareEditLastDisclosed(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditFeeDetailValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeePermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeePermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeePermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManageFees();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRunFees();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCompareApplyToleranceCure();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCompareEditLastDisclosed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEditFeeDetailValue();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool manage_fees = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.getManageFees = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeePermissions} returns this
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.setManageFees = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool run_fees = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.getRunFees = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeePermissions} returns this
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.setRunFees = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool compare_apply_tolerance_cure = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.getCompareApplyToleranceCure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeePermissions} returns this
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.setCompareApplyToleranceCure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool compare_edit_last_disclosed = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.getCompareEditLastDisclosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeePermissions} returns this
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.setCompareEditLastDisclosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool edit_fee_detail_value = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.getEditFeeDetailValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeePermissions} returns this
 */
proto.wilqo.api.mortgage.FeePermissions.prototype.setEditFeeDetailValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    Wilqo_API_Mortgage_Models_pb.CardListItem.toObject, includeInstance),
    expanderListList: jspb.Message.toObjectList(msg.getExpanderListList(),
    Wilqo_API_Mortgage_Models_pb.ExpanderDetail.toObject, includeInstance),
    fraudControlPanel: (f = msg.getFraudControlPanel()) && Wilqo_API_Mortgage_Models_pb.FraudContentPanel.toObject(includeInstance, f),
    fraudOrderingLogPanel: (f = msg.getFraudOrderingLogPanel()) && proto.wilqo.api.mortgage.FraudOrderingLogPanel.toObject(includeInstance, f),
    loanEntityId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.CardListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.ExpanderDetail;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ExpanderDetail.deserializeBinaryFromReader);
      msg.addExpanderList(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_Models_pb.FraudContentPanel;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FraudContentPanel.deserializeBinaryFromReader);
      msg.setFraudControlPanel(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.FraudOrderingLogPanel;
      reader.readMessage(value,proto.wilqo.api.mortgage.FraudOrderingLogPanel.deserializeBinaryFromReader);
      msg.setFraudOrderingLogPanel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.CardListItem.serializeBinaryToWriter
    );
  }
  f = message.getExpanderListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.ExpanderDetail.serializeBinaryToWriter
    );
  }
  f = message.getFraudControlPanel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_Models_pb.FraudContentPanel.serializeBinaryToWriter
    );
  }
  f = message.getFraudOrderingLogPanel();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.FraudOrderingLogPanel.serializeBinaryToWriter
    );
  }
  f = message.getLoanEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CardListItem card_list = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CardListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};


/**
 * repeated ExpanderDetail expander_list = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.ExpanderDetail>}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getExpanderListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ExpanderDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ExpanderDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ExpanderDetail>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setExpanderListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ExpanderDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.addExpanderList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.ExpanderDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.clearExpanderListList = function() {
  return this.setExpanderListList([]);
};


/**
 * optional FraudContentPanel fraud_control_panel = 4;
 * @return {?proto.wilqo.api.mortgage.FraudContentPanel}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getFraudControlPanel = function() {
  return /** @type{?proto.wilqo.api.mortgage.FraudContentPanel} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.FraudContentPanel, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.FraudContentPanel|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setFraudControlPanel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.clearFraudControlPanel = function() {
  return this.setFraudControlPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.hasFraudControlPanel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FraudOrderingLogPanel fraud_ordering_log_panel = 5;
 * @return {?proto.wilqo.api.mortgage.FraudOrderingLogPanel}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getFraudOrderingLogPanel = function() {
  return /** @type{?proto.wilqo.api.mortgage.FraudOrderingLogPanel} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.FraudOrderingLogPanel, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.FraudOrderingLogPanel|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setFraudOrderingLogPanel = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.clearFraudOrderingLogPanel = function() {
  return this.setFraudOrderingLogPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.hasFraudOrderingLogPanel = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string loan_entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.getLoanEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanFraudPageQueryResponse.prototype.setLoanEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    Wilqo_API_Mortgage_Models_pb.CardListItem.toObject, includeInstance),
    expanderListList: jspb.Message.toObjectList(msg.getExpanderListList(),
    Wilqo_API_Mortgage_Models_pb.ExpanderDetail.toObject, includeInstance),
    complianceReportContentPanelList: jspb.Message.toObjectList(msg.getComplianceReportContentPanelList(),
    proto.wilqo.api.mortgage.ComplianceReportContentPanel.toObject, includeInstance),
    loanEntityId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.CardListItem;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.ExpanderDetail;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.ExpanderDetail.deserializeBinaryFromReader);
      msg.addExpanderList(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.ComplianceReportContentPanel;
      reader.readMessage(value,proto.wilqo.api.mortgage.ComplianceReportContentPanel.deserializeBinaryFromReader);
      msg.addComplianceReportContentPanel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.CardListItem.serializeBinaryToWriter
    );
  }
  f = message.getExpanderListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.ExpanderDetail.serializeBinaryToWriter
    );
  }
  f = message.getComplianceReportContentPanelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.ComplianceReportContentPanel.serializeBinaryToWriter
    );
  }
  f = message.getLoanEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated CardListItem card_list = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.CardListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};


/**
 * repeated ExpanderDetail expander_list = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.ExpanderDetail>}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.getExpanderListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ExpanderDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.ExpanderDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ExpanderDetail>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.setExpanderListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ExpanderDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.addExpanderList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.ExpanderDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.clearExpanderListList = function() {
  return this.setExpanderListList([]);
};


/**
 * repeated ComplianceReportContentPanel compliance_report_content_panel = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceReportContentPanel>}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.getComplianceReportContentPanelList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceReportContentPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ComplianceReportContentPanel, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceReportContentPanel>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.setComplianceReportContentPanelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceReportContentPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.addComplianceReportContentPanel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.ComplianceReportContentPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.clearComplianceReportContentPanelList = function() {
  return this.setComplianceReportContentPanelList([]);
};


/**
 * optional string loan_entity_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.getLoanEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCompliancePageQueryResponse.prototype.setLoanEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceReportContentPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstOrderedDate: (f = msg.getFirstOrderedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    lastOrderedDate: (f = msg.getLastOrderedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    firstReceivedDate: (f = msg.getFirstReceivedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    lastReceivedDate: (f = msg.getLastReceivedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    panelId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceReportContentPanel;
  return proto.wilqo.api.mortgage.ComplianceReportContentPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setFirstOrderedDate(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLastOrderedDate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setFirstReceivedDate(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLastReceivedDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceReportContentPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstOrderedDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLastOrderedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getFirstReceivedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLastReceivedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.PanelElement first_ordered_date = 1;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.getFirstOrderedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
*/
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.setFirstOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.clearFirstOrderedDate = function() {
  return this.setFirstOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.hasFirstOrderedDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.PanelElement last_ordered_date = 2;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.getLastOrderedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
*/
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.setLastOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.clearLastOrderedDate = function() {
  return this.setLastOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.hasLastOrderedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.PanelElement first_received_date = 3;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.getFirstReceivedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
*/
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.setFirstReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.clearFirstReceivedDate = function() {
  return this.setFirstReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.hasFirstReceivedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.PanelElement last_received_date = 4;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.getLastReceivedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
*/
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.setLastReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.clearLastReceivedDate = function() {
  return this.setLastReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.hasLastReceivedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string panel_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceReportContentPanel} returns this
 */
proto.wilqo.api.mortgage.ComplianceReportContentPanel.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FraudOrderingLogPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstOrderedDate: (f = msg.getFirstOrderedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    lastOrderedDate: (f = msg.getLastOrderedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    firstReceivedDate: (f = msg.getFirstReceivedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    lastReceivedDate: (f = msg.getLastReceivedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FraudOrderingLogPanel;
  return proto.wilqo.api.mortgage.FraudOrderingLogPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setFirstOrderedDate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLastOrderedDate(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setFirstReceivedDate(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLastReceivedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FraudOrderingLogPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstOrderedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLastOrderedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getFirstReceivedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLastReceivedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string panel_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.PanelElement first_ordered_date = 2;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.getFirstOrderedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
*/
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.setFirstOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.clearFirstOrderedDate = function() {
  return this.setFirstOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.hasFirstOrderedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.PanelElement last_ordered_date = 3;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.getLastOrderedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
*/
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.setLastOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.clearLastOrderedDate = function() {
  return this.setLastOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.hasLastOrderedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.PanelElement first_received_date = 4;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.getFirstReceivedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
*/
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.setFirstReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.clearFirstReceivedDate = function() {
  return this.setFirstReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.hasFirstReceivedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.PanelElement last_received_date = 5;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.getLastReceivedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
*/
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.setLastReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FraudOrderingLogPanel} returns this
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.clearLastReceivedDate = function() {
  return this.setLastReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FraudOrderingLogPanel.prototype.hasLastReceivedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest;
  return proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsListList: jspb.Message.toObjectList(msg.getItemsListList(),
    Wilqo_API_Mortgage_Models_pb.LoanLiabilitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse;
  return proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LoanLiabilitySummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LoanLiabilitySummary.deserializeBinaryFromReader);
      msg.addItemsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LoanLiabilitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanLiabilitySummary items_list = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanLiabilitySummary>}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.getItemsListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanLiabilitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LoanLiabilitySummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanLiabilitySummary>} value
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.setItemsListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanLiabilitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary}
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.addItemsList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.LoanLiabilitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLoanCreditPageDetailQueryResponse.prototype.clearItemsListList = function() {
  return this.setItemsListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest;
  return proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse;
  return proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated wilqo.shared.models.PanelElement panel_elements = 1;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAppraisalOrderOptionParametersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeSharedInformation: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    residencesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest;
  return proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSharedInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addResidences(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeSharedInformation();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getResidencesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_shared_information = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.getIncludeSharedInformation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.setIncludeSharedInformation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated string residences = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.getResidencesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.setResidencesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.addResidences = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.clearResidencesList = function() {
  return this.setResidencesList([]);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detailsPanelsList: jspb.Message.toObjectList(msg.getDetailsPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance),
    sharedInfoPanelsList: jspb.Message.toObjectList(msg.getSharedInfoPanelsList(),
    Wilqo_API_Mortgage_Models_pb.PartySharedInfoCard.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse;
  return proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addDetailsPanels(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.PartySharedInfoCard;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.PartySharedInfoCard.deserializeBinaryFromReader);
      msg.addSharedInfoPanels(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetailsPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getSharedInfoPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.PartySharedInfoCard.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated wilqo.shared.models.PanelElement details_panels = 1;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.getDetailsPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.setDetailsPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.addDetailsPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.clearDetailsPanelsList = function() {
  return this.setDetailsPanelsList([]);
};


/**
 * repeated PartySharedInfoCard shared_info_panels = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.PartySharedInfoCard>}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.getSharedInfoPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.PartySharedInfoCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.PartySharedInfoCard, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.PartySharedInfoCard>} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.setSharedInfoPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.PartySharedInfoCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.addSharedInfoPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.PartySharedInfoCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.clearSharedInfoPanelsList = function() {
  return this.setSharedInfoPanelsList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 3;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 3));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAddBorrowerPartyConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanOriginatorMersOrgId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    investorMersOrgId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    servicerMersOrgId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest;
  return proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOriginatorMersOrgId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorMersOrgId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicerMersOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanOriginatorMersOrgId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvestorMersOrgId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServicerMersOrgId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loan_originator_mers_org_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.getLoanOriginatorMersOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.setLoanOriginatorMersOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string investor_mers_org_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.getInvestorMersOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.setInvestorMersOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string servicer_mers_org_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.getServicerMersOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryRequest.prototype.setServicerMersOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse;
  return proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.Panel panels = 2;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetMersOptionParametersQueryResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest;
  return proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse;
  return proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.Panel panels = 2;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeeOptionParametersQueryResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest;
  return proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eligibleLoansList: jspb.Message.toObjectList(msg.getEligibleLoansList(),
    Wilqo_API_Mortgage_Models_pb.DealScope.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse;
  return proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_Models_pb.DealScope;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DealScope.deserializeBinaryFromReader);
      msg.addEligibleLoans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEligibleLoansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_Models_pb.DealScope.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DealScope eligible_loans = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.DealScope>}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.getEligibleLoansList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DealScope>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DealScope, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DealScope>} value
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.setEligibleLoansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DealScope=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DealScope}
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.addEligibleLoans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.DealScope, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetURLAEligibleLoansQueryResponse.prototype.clearEligibleLoansList = function() {
  return this.setEligibleLoansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ExportConfigurationQueryRequest;
  return proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    zipFile: msg.getZipFile_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ExportConfigurationQueryResponse;
  return proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZipFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getZipFile_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes zip_file = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.getZipFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes zip_file = 2;
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {string}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.getZipFile_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZipFile()));
};


/**
 * optional bytes zip_file = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZipFile()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.getZipFile_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZipFile()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.mortgage.ExportConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.ExportConfigurationQueryResponse.prototype.setZipFile = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    includeArchived: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest;
  return proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} */ (reader.readEnum());
      msg.setFilter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIncludeArchived();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConditionTemplateCategoryEnum filter = 2;
 * @return {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.getFilter = function() {
  return /** @type {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool include_archived = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.getIncludeArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest.prototype.setIncludeArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    lenderConditionsList: jspb.Message.toObjectList(msg.getLenderConditionsList(),
    Wilqo_API_Mortgage_Models_pb.LenderCondition.toObject, includeInstance),
    lenderConditionMappingsList: jspb.Message.toObjectList(msg.getLenderConditionMappingsList(),
    Wilqo_API_Mortgage_Models_pb.LenderConditionMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse;
  return proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.LenderCondition;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LenderCondition.deserializeBinaryFromReader);
      msg.addLenderConditions(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.LenderConditionMapping;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.LenderConditionMapping.deserializeBinaryFromReader);
      msg.addLenderConditionMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getLenderConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.LenderCondition.serializeBinaryToWriter
    );
  }
  f = message.getLenderConditionMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.LenderConditionMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LenderCondition lender_conditions = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.LenderCondition>}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.getLenderConditionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LenderCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LenderCondition, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LenderCondition>} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.setLenderConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LenderCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LenderCondition}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.addLenderConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.LenderCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.clearLenderConditionsList = function() {
  return this.setLenderConditionsList([]);
};


/**
 * repeated LenderConditionMapping lender_condition_mappings = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LenderConditionMapping>}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.getLenderConditionMappingsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LenderConditionMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.LenderConditionMapping, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LenderConditionMapping>} value
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.setLenderConditionMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LenderConditionMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping}
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.addLenderConditionMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LenderConditionMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetLenderConditionsByBPIdQueryResponse.prototype.clearLenderConditionMappingsList = function() {
  return this.setLenderConditionMappingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealXmlQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealXmlQueryRequest;
  return proto.wilqo.api.mortgage.GetDealXmlQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealXmlQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealXmlQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealXmlQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    xmlUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealXmlQueryResponse;
  return proto.wilqo.api.mortgage.GetDealXmlQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setXmlUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealXmlQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getXmlUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string xml_url = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.getXmlUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealXmlQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealXmlQueryResponse.prototype.setXmlUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest;
  return proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentFormSectionsList: jspb.Message.toObjectList(msg.getDocumentFormSectionsList(),
    Wilqo_API_Mortgage_Models_pb.DocumentFormSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse;
  return proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.DocumentFormSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DocumentFormSection.deserializeBinaryFromReader);
      msg.addDocumentFormSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentFormSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.DocumentFormSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DocumentFormSection document_form_sections = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentFormSection>}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.getDocumentFormSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentFormSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.DocumentFormSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentFormSection>} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.setDocumentFormSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentFormSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection}
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.addDocumentFormSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.DocumentFormSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentInfoConfigurationQueryResponse.prototype.clearDocumentFormSectionsList = function() {
  return this.setDocumentFormSectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest;
  return proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.repeatedFields_ = [1,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    Wilqo_API_Mortgage_Models_pb.AusUxOption.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    desktopOriginatorOptionsList: jspb.Message.toObjectList(msg.getDesktopOriginatorOptionsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance),
    loanProductAdvisorOptionsList: jspb.Message.toObjectList(msg.getLoanProductAdvisorOptionsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse;
  return proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.AusUxOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.AusUxOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addDesktopOriginatorOptions(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addLoanProductAdvisorOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.AusUxOption.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDesktopOriginatorOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLoanProductAdvisorOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AusUxOption options = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.AusUxOption>}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AusUxOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.AusUxOption, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusUxOption>} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusUxOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AusUxOption}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.AusUxOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated wilqo.shared.models.PanelElement desktop_originator_options = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.getDesktopOriginatorOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.setDesktopOriginatorOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.addDesktopOriginatorOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.clearDesktopOriginatorOptionsList = function() {
  return this.setDesktopOriginatorOptionsList([]);
};


/**
 * repeated wilqo.shared.models.PanelElement loan_product_advisor_options = 4;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.getLoanProductAdvisorOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.setLoanProductAdvisorOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.addLoanProductAdvisorOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAusUxOptionsByTenantQueryResponse.prototype.clearLoanProductAdvisorOptionsList = function() {
  return this.setLoanProductAdvisorOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest;
  return proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    requiredFieldsPage: (f = msg.getRequiredFieldsPage()) && Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse;
  return proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.setRequiredFieldsPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getRequiredFieldsPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.api.mortgage_dynamic_data.LoanPage required_fields_page = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.getRequiredFieldsPage = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPage|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.setRequiredFieldsPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.clearRequiredFieldsPage = function() {
  return this.setRequiredFieldsPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetValidationsResultsQueryResponse.prototype.hasRequiredFieldsPage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest;
  return proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    deal: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse;
  return proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDeal();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.getDeal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealAsBase64QueryResponse.prototype.setDeal = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    withData: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest;
  return proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWithData();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string address_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.getAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool with_data = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.getWithData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.setWithData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    addressInfo: (f = msg.getAddressInfo()) && Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse;
  return proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.deserializeBinaryFromReader);
      msg.setAddressInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAddressInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CentralizedAddressLoanPageData address_info = 2;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.getAddressInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.CentralizedAddressLoanPageData, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.setAddressInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.clearAddressInfo = function() {
  return this.setAddressInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCentralizedAddressLoanPageDataQueryResponse.prototype.hasAddressInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest;
  return proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    documentTypesList: jspb.Message.toObjectList(msg.getDocumentTypesList(),
    Wilqo_API_Mortgage_Models_pb.SimpleOption.toObject, includeInstance),
    documentTypeOptionsList: jspb.Message.toObjectList(msg.getDocumentTypeOptionsList(),
    proto.wilqo.api.mortgage.DocumentTypeOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse;
  return proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.SimpleOption;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.SimpleOption.deserializeBinaryFromReader);
      msg.addDocumentTypes(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.DocumentTypeOption;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocumentTypeOption.deserializeBinaryFromReader);
      msg.addDocumentTypeOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDocumentTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.SimpleOption.serializeBinaryToWriter
    );
  }
  f = message.getDocumentTypeOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.DocumentTypeOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimpleOption document_types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.SimpleOption>}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.getDocumentTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.SimpleOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.SimpleOption, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.SimpleOption>} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.setDocumentTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.SimpleOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.SimpleOption}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.addDocumentTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.SimpleOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.clearDocumentTypesList = function() {
  return this.setDocumentTypesList([]);
};


/**
 * repeated DocumentTypeOption document_type_options = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentTypeOption>}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.getDocumentTypeOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentTypeOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocumentTypeOption, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentTypeOption>} value
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.setDocumentTypeOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentTypeOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption}
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.addDocumentTypeOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.DocumentTypeOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDocumentGenerationDocumentTypesQueryResponse.prototype.clearDocumentTypeOptionsList = function() {
  return this.setDocumentTypeOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentTypeOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentTypeOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentTypeOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentTypeOption;
  return proto.wilqo.api.mortgage.DocumentTypeOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentTypeOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentType} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentTypeOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentTypeOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentTypeOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption} returns this
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption} returns this
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.api.mortgage_docgen.DocumentType document_type = 3;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentType}
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentType} value
 * @return {!proto.wilqo.api.mortgage.DocumentTypeOption} returns this
 */
proto.wilqo.api.mortgage.DocumentTypeOption.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest;
  return proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    feesComparisonSectionsList: jspb.Message.toObjectList(msg.getFeesComparisonSectionsList(),
    Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.toObject, includeInstance),
    totalRequiredAndLenderCure: (f = msg.getTotalRequiredAndLenderCure()) && Wilqo_API_Mortgage_Models_pb.TotalRequiredAndLenderCure.toObject(includeInstance, f),
    hasFirstLoanEstimateSentDate: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse;
  return proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesComparisonSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.deserializeBinaryFromReader);
      msg.addFeesComparisonSections(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.TotalRequiredAndLenderCure;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.TotalRequiredAndLenderCure.deserializeBinaryFromReader);
      msg.setTotalRequiredAndLenderCure(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFirstLoanEstimateSentDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getFeesComparisonSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesComparisonSection.serializeBinaryToWriter
    );
  }
  f = message.getTotalRequiredAndLenderCure();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.TotalRequiredAndLenderCure.serializeBinaryToWriter
    );
  }
  f = message.getHasFirstLoanEstimateSentDate();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FeesComparisonSection fees_comparison_sections = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.FeesComparisonSection>}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.getFeesComparisonSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeesComparisonSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesComparisonSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeesComparisonSection>} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.setFeesComparisonSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesComparisonSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.addFeesComparisonSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.FeesComparisonSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.clearFeesComparisonSectionsList = function() {
  return this.setFeesComparisonSectionsList([]);
};


/**
 * optional TotalRequiredAndLenderCure total_required_and_lender_cure = 3;
 * @return {?proto.wilqo.api.mortgage.TotalRequiredAndLenderCure}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.getTotalRequiredAndLenderCure = function() {
  return /** @type{?proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.TotalRequiredAndLenderCure, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.TotalRequiredAndLenderCure|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.setTotalRequiredAndLenderCure = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.clearTotalRequiredAndLenderCure = function() {
  return this.setTotalRequiredAndLenderCure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.hasTotalRequiredAndLenderCure = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool has_first_loan_estimate_sent_date = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.getHasFirstLoanEstimateSentDate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesComparisonsQueryResponse.prototype.setHasFirstLoanEstimateSentDate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealCommentsQueryRequest;
  return proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comments: (f = msg.getComments()) && Wilqo_API_Mortgage_Models_pb.DealComments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetDealCommentsQueryResponse;
  return proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_Models_pb.DealComments;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.DealComments.deserializeBinaryFromReader);
      msg.setComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_Models_pb.DealComments.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DealComments comments = 3;
 * @return {?proto.wilqo.api.mortgage.DealComments}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.getComments = function() {
  return /** @type{?proto.wilqo.api.mortgage.DealComments} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_Models_pb.DealComments, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.DealComments|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.setComments = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetDealCommentsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.clearComments = function() {
  return this.setComments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetDealCommentsQueryResponse.prototype.hasComments = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest;
  return proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse;
  return proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FeesAdjustmentsCreditsSection sections = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection>}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesAdjustmentsCreditsSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection>} value
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection}
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesAdjustmentsCreditsQueryResponse.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest;
  return proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    partiesList: jspb.Message.toObjectList(msg.getPartiesList(),
    Wilqo_API_Mortgage_Models_pb.PartySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse;
  return proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.PartySummary;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.PartySummary.deserializeBinaryFromReader);
      msg.addParties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPartiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.PartySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PartySummary parties = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.PartySummary>}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.getPartiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.PartySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.PartySummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.PartySummary>} value
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.setPartiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.PartySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.addParties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.PartySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesPaidToPartiesQueryResponse.prototype.clearPartiesList = function() {
  return this.setPartiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest;
  return proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse;
  return proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FeesOriginationDiscountSection sections = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.FeesOriginationDiscountSection>}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeesOriginationDiscountSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.FeesOriginationDiscountSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeesOriginationDiscountSection>} value
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection}
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.FeesOriginationDiscountSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetFeesOriginationDiscountQueryResponse.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest;
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    metadatasList: jspb.Message.toObjectList(msg.getMetadatasList(),
    proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse;
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata;
      reader.readMessage(value,proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.deserializeBinaryFromReader);
      msg.addMetadatas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getMetadatasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    metadata: (f = msg.getMetadata()) && Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb.WilqoPartyTypeMetadataConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata;
  return proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb.WilqoPartyTypeMetadataConfig;
      reader.readMessage(value,Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb.WilqoPartyTypeMetadataConfig.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb.WilqoPartyTypeMetadataConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.WilqoPartyType type = 1;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} returns this
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyTypeMetadataConfig metadata = 2;
 * @return {?proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.getMetadata = function() {
  return /** @type{?proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_WilqoPartyTypeMetadataConfig_pb.WilqoPartyTypeMetadataConfig, 2));
};


/**
 * @param {?proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} returns this
*/
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata} returns this
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated WilqoPartyTypeWithMetadata metadatas = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata>}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.getMetadatasList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata>} value
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.setMetadatasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata}
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.addMetadatas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.WilqoPartyTypeWithMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetWilqoPartyTypeMetadataQueryResponse.prototype.clearMetadatasList = function() {
  return this.setMetadatasList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.StateCase = {
  STATE_NOT_SET: 0,
  STATE_CODE: 2,
  STATE_NAME: 3
};

/**
 * @return {proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.StateCase}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.getStateCase = function() {
  return /** @type {proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.StateCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stateCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest;
  return proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state_code = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.setStateCode = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.clearStateCode = function() {
  return jspb.Message.setOneofField(this, 2, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.hasStateCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string state_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.setStateName = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.clearStateName = function() {
  return jspb.Message.setOneofField(this, 3, proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryRequest.prototype.hasStateName = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    countiesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse;
  return proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCounties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getCountiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string counties = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.getCountiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.setCountiesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.addCounties = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetCountiesByStateQueryResponse.prototype.clearCountiesList = function() {
  return this.setCountiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest;
  return proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest} returns this
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.toObject, includeInstance),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse;
  return proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory;
      reader.readMessage(value,Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WilqoPartyCategory categories = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_Models_pb.WilqoPartyCategory, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>} value
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.WilqoPartyCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} returns this
*/
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse} returns this
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.GetAllWilqoPartyCategoriesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage);
