/* eslint-disable no-console */
import { useAuth0 } from '@auth0/auth0-react';
import { BinaryReader } from 'google-protobuf';
import { Any } from 'google-protobuf/google/protobuf/any_pb';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { RequestMessage, ResponseMessage } from '@/API/Models/Wilqo_API_Consumer_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import type { WilqoMessage } from './useBackend';
import { useSubscriptionContext } from './useSubscription';

interface WebSocketTicket {
  url: string;
  nonce: string;
  expires: string;
}

interface InternalPromise {
  reject: (reason: any) => void;
  resolve: (value: any) => void;
}

interface WebSocketContextState {
  commandResponse: (message: WilqoMessage) => Promise<any>;
  isConnected: boolean;
}

const INITIAL_STATE: WebSocketContextState = {
  commandResponse: () => new Promise(() => true),
  isConnected: false,
};

const WebSocketContext = createContext(INITIAL_STATE);
WebSocketContext.displayName = 'WebSocketContext';

interface WebsocketProviderProps {
  children: React.ReactNode;
}

const WebsocketProvider = (props: WebsocketProviderProps) => {
  const { children } = props;

  const wsRef = useRef<WebSocket | undefined>();
  const [isConnected, setIsConnected] = useState(false);
  const connectingRef = useRef(false);

  const { configureSubscriptions, handleSubscriptionMessage } = useSubscriptionContext();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const messageCounterRef = useRef<number>(0);
  const promisesRef = useRef<Record<string, InternalPromise> | undefined>(undefined);
  const bpdId = useBPDId();

  const sendInternal = useCallback((messageId: string, msg?: WilqoMessage, isPong?: true): boolean => {
    if (!wsRef.current || wsRef.current.readyState !== wsRef.current.OPEN) {
      return false;
    }

    const busMessage = new RequestMessage();
    if (isPong) {
      busMessage.setPong(true);
    }
    busMessage.setMessageId(messageId);
    busMessage.getHeadersMap().set('caller-url', window?.location?.href);

    if (msg) {
      const msgAny = new Any();

      if (msg.msg) {
        const existingBpdIdInRequest = msg.msg.getBusinessProcessDomainId();
        if (existingBpdIdInRequest === '' || existingBpdIdInRequest === null || existingBpdIdInRequest === undefined) {
          msg.msg.setBusinessProcessDomainId(bpdId);
        }
      }
      msgAny.pack(msg.msg.serializeBinary(), msg.msgName);
      busMessage.setMessageContents(msgAny);
    }
    wsRef.current.send(busMessage.serializeBinary());

    return true;
  }, [bpdId]);

  const onMessage = useCallback(async (event: MessageEvent) => {
    const arrayBuffer = await event.data.arrayBuffer();
    const reader = new BinaryReader(arrayBuffer);

    const busMessage = new ResponseMessage();
    const msg = ResponseMessage.deserializeBinaryFromReader(busMessage, reader);

    if (busMessage.getPing()) {
      sendInternal('', undefined, true);
      return;
    }

    const responseTo = msg.getResponseToId();

    if (msg.getSubscriptionId() !== '') {
      handleSubscriptionMessage(msg);
      return;
    }

    const resolveForRequest = promisesRef.current ? promisesRef.current[responseTo] : undefined;
    if (!resolveForRequest) {
      return;
    }

    if (msg.getSystemError()) {
      resolveForRequest.reject('system error');
    }

    const responseMsg = msg.getMessageContents();
    if (!responseMsg) {
      return;
    }

    resolveForRequest.resolve(responseMsg);
  }, [sendInternal, handleSubscriptionMessage]);

  const getServerURL = () => {
    // Stopped using URL from WebSocketTicket to be able to use the tenant-specific host
    let server = `wss://${window.location.host}`;
    if (window.location.host.startsWith('localhost') || window.location.host.indexOf('local.wilqo-app.com') >= 0) {
      if (window.location.protocol === 'https:') {
        server = `wss://${window.location.host}`;
      } else {
        server = `wsRef.current://${window.location.host}`;
      }
    }

    return server;
  };

  const errorCb = useCallback(() => {
    console.log('websocket status: crashed');
    setIsConnected(false);
    connectingRef.current = false;
  }, []);

  const closeCb = useCallback(() => {
    console.log('websocket status: closed');
    setIsConnected(false);
    connectingRef.current = false;
  }, []);

  const successCb = useCallback(() => {
    console.log('websocket connected');
    setIsConnected(true);
    configureSubscriptions();
  }, [configureSubscriptions]);

  const connectWebsocket = useCallback(async (onConnect?: any) => {
    connectingRef.current = true;
    try {
      const token = await getAccessTokenSilently();
      if (!token) return;
      const ticketResponse = await fetch('/mortgage/api/WebSocket', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const ticket: WebSocketTicket = await ticketResponse.json();
      const server = getServerURL();
      const socket = new WebSocket(`${server}/mortgage/api/wsRef.current?nonce=${encodeURIComponent(ticket.nonce)}`);
      wsRef.current = socket;

      socket.onmessage = onMessage;
      socket.onopen = () => {
        successCb();
        if (onConnect) onConnect();
      };
      socket.onerror = errorCb;
      socket.onclose = closeCb;
    } catch (error) {
      setIsConnected(false);
    }
  }, [getAccessTokenSilently, onMessage, errorCb, closeCb, successCb]);

  const commandResponse = useCallback((msg: WilqoMessage) => new Promise<any>((resolve, reject) => {
    const messageId = messageCounterRef.current.toFixed(0);
    const didSend = sendInternal(messageId, msg);
    if (!didSend) {
      // reject(new Error('Failed to send message'));
    }
    promisesRef.current = { ...promisesRef.current, [messageId]: { reject, resolve } };
    messageCounterRef.current += 1;
  }), [messageCounterRef, sendInternal]);

  useEffect(() => {
    if (isAuthenticated && !connectingRef.current) {
      connectWebsocket();
    }
  }, [connectWebsocket, isAuthenticated]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !isConnected && !connectingRef.current) {
        console.log('reconnecting...');
        connectWebsocket();
      }
    });
  }, [isConnected, connectWebsocket]);

  return (
    <WebSocketContext.Provider value={{ commandResponse, isConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = () => useContext(WebSocketContext);
export { useWebSocket, WebsocketProvider };
