import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useFieldGrouping } from '@/Components/Features/dynamicForm/useFieldGrouping';
import { useDynamicValueConverters } from '@/Utils/Helpers/dynamicDataConverters';
import type { PanelElementExtended } from '@/Utils/Helpers/getPanelElement';

import type { SelectedValue } from './AddressAutoComplete.component';
import Component from './AddressAutoComplete.component';

export interface AddressAutoCompleteProps {
  panelElement: PanelElementExtended;
}

const AddressAutoComplete = (props: AddressAutoCompleteProps) => {
  const {
    panelElement: {
      disabled,
      headerText,
      id,
      path,
      requirement,
      validatorsList,
      variant,
    },
  } = props;

  const { converters } = useDynamicValueConverters(props.panelElement);

  const [value, setValue, { disabled: conditionallyDisabled, hidden, ref }] = useDynamicFormRegister(
    id,
    converters,
    { requirement: props.panelElement.requirement },
  );
  const { onValueChange } = useFieldGrouping();

  const validateState = Boolean(validatorsList.find((validator) => validator.type === 'validate_state'));

  const handleSelect = (value: SelectedValue | null) => {
    if (value) {
      onValueChange(value, 'googleAddress');
      setValue(value.geocode.street, value.geocode.street, path);
    }
  };

  const handleValueUpdate = (textValue: string) => {
    setValue(textValue, textValue);
  };

  if (hidden) return null;
  return (
    <Component
      ref={ref}
      disabled={disabled || conditionallyDisabled}
      disableManual={variant === 'disable-manual'}
      filterInternational={props.panelElement.type !== 'auto-complete-address-international'}
      onSelect={handleSelect}
      onValueUpdate={handleValueUpdate}
      placeholder={headerText}
      required={requirement?.required}
      validateState={validateState}
      value={value}
    />
  );
};

export default AddressAutoComplete;
