import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { CreateUserCommandRequest, UserCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import type { AdminLoanPartyRoleTypesEnum } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { BusinessProcessDomainEntityId } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.CreateUserCommandRequest';

interface UpdateObject {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  skillsetGroupIds: string[];
  licenseList?: License[];
  loanPartyRoleTypesList?: AdminLoanPartyRoleTypesEnum[];
}

const useCreateUser = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const CreateUserCommand = async (updateObject: UpdateObject) => {
    const { email, firstName, skillsetGroupIds, lastName, phoneNumber, licenseList = [], loanPartyRoleTypesList = [] } = updateObject;
    const request = new CreateUserCommandRequest();
    request.setFirstName(firstName);
    request.setLastName(lastName);
    request.setEmail(email);
    request.setPhoneNumber(phoneNumber);
    const requestSkillsetGroups = skillsetGroupIds.map((id) => {
      const bpdId = new BusinessProcessDomainEntityId();
      bpdId.setId(id);
      return bpdId;
    });
    request.setSkillsetGroupIdsList(requestSkillsetGroups);
    request.setLicensesList(licenseList);
    request.setLoanPartyRoleTypesList(loanPartyRoleTypesList);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new UserCommandResponse();
    UserCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(CreateUserCommand);
};

export { useCreateUser };
