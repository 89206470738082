import { useEffect } from 'react';

import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { Typography } from '@/Components/Atoms/Typography';
import type { FormProgress } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { mapFormFieldToPanelElement } from '@/Utils/Helpers/widgetHelpers';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import type { SaveConfig } from './useSaveWidget';
import { useWidgetContext } from './WidgetContext';

export const FormFlowWidget = (props: LoanPageWidget.AsObject) => {
  const { fieldGrouping, hiddenFieldIds, resetProgress } = useDynamicForm();
  const { generateDialogKeywordLinks } = useShared();

  const { loanId } = useDynamicLoanInfo();
  const { addEditingWidget, pageId = '', scopeToken = '' } = useWidgetContext();

  useEffect(() => addEditingWidget(props.id), [addEditingWidget, props.id]);

  // setup default values
  useEffect(() => {
    if (props.form && props.form.fieldsList) {
      const progress = props.form.fieldsList.reduce((acc, currentField): FormProgress => ({
        ...acc,
        values: {
          ...acc.values,
          [currentField.id]: {
            dynamic: currentField.value?.value ? DynamicDataElementMap(currentField.value?.value) : undefined,
            fieldConfig: currentField,
          },
        },
      }), { values: {} } as FormProgress);
      resetProgress(progress);
    }
  }, [props.form, resetProgress]);

  const additionalInfo: SaveConfig = {
    hiddenFieldIds,
    loanId,
    pageId,
    scopeToken,
    type: 'form',
    widgetId: props.id,
    widgets: [props],
  };

  return (
    <div className="mx-auto max-w-[508px] w-full">
      <div className="mb-6">
        <Typography className="mb-2" variant="display5">{props.form?.label}</Typography>
        <Typography className="text-on-surface-inactive" variant="body2">{props.form?.helpText}</Typography>
      </div>

      <div className="flex flex-col gap-6 w-full">
        {
            props.form?.fieldsList.map((field) => {
              const panelElement = mapFormFieldToPanelElement(
                field,
                undefined,
                fieldGrouping?.googleAddress?.addressLineFieldId === field.id,
              );
              if (panelElement && !field.hidden) {
                return (
                  <PanelElementRenderer
                    variant="default"
                    {...panelElement}
                    enrichedSummaryText={
                      field?.hyperLinksList.length && panelElement?.summaryText
                        ? generateDialogKeywordLinks(panelElement?.summaryText, field?.hyperLinksList)
                        : undefined
                    }
                  />
                );
              }
              return null;
            }).filter((panel) => panel !== null)
            }
      </div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button data-additionalinfo={JSON.stringify(additionalInfo)} hidden id="submit-FormFlow-widget" type="submit" />
    </div>
  );
};
