import { Button } from '@/Components/Atoms/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';

export interface ChickenTestProps {
  open: boolean;
  onClose: VoidFunction;
  onSave?: VoidFunction;
  onDiscard?: VoidFunction;
  isSaving?: boolean;
}

export const ChickenTest = (props: ChickenTestProps) => (
  <Dialog onOpenChange={props.onClose} open={props.open} variant="content">
    <DialogLayout>
      <DialogHeader title="Unsaved Changes" />
      <DialogContent className="px-4 py-6 w-[500px]">
        <Typography variant="body1">
          You have unsaved changes.
          Do you want to discard or save them, before continuing?
        </Typography>
      </DialogContent>
      <DialogFooter borderTop>
        <Button label="Discard" onClick={props.onDiscard} variant="tertiary" />
        <Button isLoading={props.isSaving} label="Save" onClick={props.onSave} />
      </DialogFooter>
    </DialogLayout>
  </Dialog>
);
