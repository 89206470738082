import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { IntegrationTypeLocalEnum } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useRunFees } from '@/API/Queries/mortgageIntegrations/fees/useRunFees';
import { Dialog } from '@/Components/Atoms/RadixDialog';
import { RequiredFields } from '@/Components/Features/DynamicLoanPage/RequiredFields';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import TitleCompany from './TitleCompany';

enum Steps {
  requiredFields,
  titleCompany,
}

interface FeesManualModalProps {
  onClose: () => void;
  open: boolean;
}

const RunFeesModal = ({ onClose, open }: FeesManualModalProps) => {
  const [step, setStep] = useState<Steps>(Steps.requiredFields);
  const queryClient = useQueryClient();
  const { showSnackBar } = useShared();

  const { data: loan } = useLoan();
  const { isLoading, mutate: runFees } = useRunFees();

  const handleOnIsValid = () => {
    setStep(Steps.titleCompany);
  };

  const handleNextStep = useCallback((agentId?: string) => {
    runFees({
      agentId: agentId || '',
      dealId: loan?.dealId || '',
    }, {
      onError() {
        showSnackBar({ message: 'Something went wrong', open: true });
      },
      onSuccess() {
        showSnackBar({ message: 'Order fees sent.', open: true });
        queryClient.invalidateQueries(['wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest', loan?.dealId || '']);
        onClose();
      },
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [runFees, loan?.dealId]);

  const requiredFields = () => (
    <RequiredFields
      dealId={loan?.dealId || ''}
      integrationType={IntegrationTypeLocalEnum.INTEGRATION_TYPE_LOCAL_ENUM_FEES}
      isRunningIntegration={isLoading}
      onIsValid={handleOnIsValid}
    />
  );

  const titleCompany = () => (
    <TitleCompany
      closeModal={onClose}
      dealId={loan?.dealId}
      isSaving={isLoading}
      nextStep={handleNextStep}
    />
  );

  const render = () => {
    if (step === Steps.requiredFields) {
      return requiredFields();
    }

    return titleCompany();
  };

  return (
    <Dialog onOpenChange={onClose} open={open}>
      {render()}
    </Dialog>
  );
};

export default RunFeesModal;
