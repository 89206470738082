import clsx from 'clsx';

import type { IAvatarProps } from './Avatar';
import { Avatar } from './Avatar';
import { Icon } from './Icon';
import { Surface } from './Surface';
import { Typography } from './Typography';

export interface IChipProps {
  id?: string;
  className?: string;
  label: string;
  image?: string;
  icon?: string;
  selected?: boolean;
  rightIcon?: string;
  variant?: string;
  avatarProps?: IAvatarProps;
  onClickClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
}

const Chip = (props: IChipProps) => {
  const { avatarProps, className, icon = '', image = '', label, onClick, onClickClose, rightIcon, selected = false, variant } = props;

  const renderLeftGraphic = () => {
    if (image !== '') {
      return <img alt="avatar" className="w-6 h-6 border rounded-full" src={image} />;
    }

    if (icon !== '') {
      return <Icon icon={icon} size="sm" />;
    }

    if (avatarProps != null) {
      return (<Avatar {...avatarProps} size="xs" />);
    }

    return null;
  };

  return (
    <Surface
      className={clsx(
        'hover:bg-on-surface-states-dragged focus:bg-on-surface-states-focus active:bg-on-surface-states-pressed border border-on-surface-stroke appearance-none flex flex-row items-center py-1.5 px-2 cursor-pointer rounded-full h-8 w-fit max-w-full min-w-max truncate user-drag:shadow-three',
        {
          'bg-surface-variant ': !selected,
          // Nestle the avatar circle nicely enside of the chip
          'pl-[2px]': !image && !icon && !!avatarProps,
        },
        className,
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      selected={selected}
      tabIndex={0}
    >
      {renderLeftGraphic()}
      <Typography
        className={clsx(
          'flex-1 overflow-hidden text-ellipsis whitespace-nowrap py-0 px-2',
          {
            'body1 text-left': variant === 'select',
            'text-on-surface-active': !selected,
            'text-primary-on-surface': selected,
            'text-sm': variant !== 'select',
          },
        )}
        variant="body2"
      >
        {label}
      </Typography>
      {onClickClose
        && (
          <Icon
            data-testid="chip-close-icon"
            icon="Clear"
            onClick={onClickClose}
            selected={selected}
            size="sm"
          />
        )}
      {rightIcon && (
        <Icon
          data-testid="chip-right-icon"
          icon={rightIcon}
          onClick={onClickClose}
          selected={selected}
          size="sm"
        />
      )}
    </Surface>
  );
};

export { Chip };
