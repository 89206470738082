import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { PanelSequenceDesignCommandResponse, ReorderPanelElementCommandRequest } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.ReorderPanelElementCommandRequest';

interface UpdateObject {
  panelId: string;
  panelElementId: string;
  newPanelId: string;
  newIndex: number;
}

const useReorderPanelElement = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();
  const {
    designerPanel: { entityId, panelSequenceDesignId },
  } = useActivityConfiguratorContext();

  const ReorderPanelElementCommand = async (updateObject: UpdateObject) => {
    const { newIndex, newPanelId, panelElementId, panelId } = updateObject;
    const request = new ReorderPanelElementCommandRequest();
    request.setPanelSequenceDesignId(panelSequenceDesignId);
    request.setPanelId(panelId);
    request.setPanelElementId(panelElementId);
    request.setNewPanel(newPanelId);
    request.setNewIndex(newIndex);
    request.setActivityDesignId(entityId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PanelSequenceDesignCommandResponse();
    PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(ReorderPanelElementCommand);
};

export { useReorderPanelElement };
