import { useEffect, useMemo } from 'react';

import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { ddeAreEqual, DynamicDataElementMap, fromList, toList } from '@/Utils/Helpers/dynamicDataElementHelper';

import type { ChipGroupItem } from './ChipGroup';
import { ChipGroup } from './ChipGroup';

interface ChipGroupProps {
  panelElement: PanelElement.AsObject;
}

const ChipGroupDDE = (props: ChipGroupProps) => {
  const { panelElement } = props;

  const [selectedValues = [], setValue, { hidden }] = useDynamicFormRegister<Array<DynamicDataElement.AsObject>>(
    panelElement.id,
    {
      fromDynamic: (dynamic) => {
        if (dynamic.getDataType() !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
          const ddeList = fromList(dynamic);
          return ddeList.map((dde) => {
            const option = panelElement.optionsList.find((option) => (option.value ? ddeAreEqual(DynamicDataElementMap(option.value), dde) : false));
            return option?.value as DynamicDataElement.AsObject;
          });
        }
        return [];
      },
      toDynamic: (selectedOptionsValue) => {
        if (selectedOptionsValue) {
          return toList(selectedOptionsValue.map((value) => DynamicDataElementMap(value)));
        }
        return toList([]);
      },
    },
    { panelElementType: 'chip-list', requirement: panelElement.requirement },
  );

  useEffect(() => {
    if (panelElement.optionsList.length === 1 && selectedValues?.length === 0 && panelElement.optionsList[0].value) {
      setValue([panelElement.optionsList[0]?.value], '', '', undefined, true);
    }
  }, [panelElement, setValue, selectedValues]);

  const chips = useMemo((): Array<ChipGroupItem> => panelElement.optionsList.map((option) => ({
    id: option.id,
    label: option.headerText,
    value: option.value,
  })), [panelElement]);

  const handleOnSelectItem = (selectedItem: ChipGroupItem, isSelected: boolean) => {
    let newValues = selectedValues || [];
    const selectedOption = panelElement.optionsList.find((option) => (option?.value ? ddeAreEqual(DynamicDataElementMap(option.value), DynamicDataElementMap(selectedItem.value)) : false));
    if (selectedOption) {
      if (isSelected) {
        newValues = newValues?.filter((item) => !ddeAreEqual(DynamicDataElementMap(item), DynamicDataElementMap(selectedOption.value as DynamicDataElement.AsObject)));
      } else if (selectedOption.value) {
        newValues = newValues?.concat(selectedOption.value);
      }
      setValue(newValues, '');
    }
  };

  const selectedIndexes = useMemo(() => selectedValues
    ?.map((selectedValue) => chips
      .findIndex((chip) => ddeAreEqual(DynamicDataElementMap(selectedValue), DynamicDataElementMap(chip.value)))),
  [selectedValues, chips]);

  if (hidden) return null;
  return (
    <ChipGroup
      chips={chips}
      onSelectItem={handleOnSelectItem}
      selected={selectedIndexes || []}
    />
  );
};

export { ChipGroupDDE };
