import { useQuery } from '@tanstack/react-query';

import { SearchOrganizationsQueryRequest, SearchOrganizationsQueryResponse } from '@/API/Models/Wilqo_API_Users_InstitutionQueries_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.SearchOrganizationsQueryRequest';

export const useOrganizationSearch = (search: string, page: number, pageSize: number, userId: string, enabled = false) => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();

  return useQuery([MESSAGE_NAME, search, page, pageSize, userId], async () => {
    const request = new SearchOrganizationsQueryRequest();
    request.setInstitutionId(institutionId);
    request.setPage(page);
    request.setPageSize(pageSize);
    request.setParentUserId(userId);
    request.setSearchTerms(search);
    return sendMessage<SearchOrganizationsQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      SearchOrganizationsQueryResponse,
    );
  }, { enabled });
};
