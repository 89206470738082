import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { useBorrowerInvite } from '@/API/Queries/user/useBorrowerInvite';
import { useUserDealAssociation } from '@/API/Queries/user/useUserDealAssociation';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Loader } from '@/Components/Atoms/Loader';
import { useNavigate } from '@/Routes/NavigationContext';

interface ConsumerAuthCallbackState {
  runId?: string;
  invitationId?: string;
}

const ConsumerAuthCallback = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [dealId, setDealId] = useState('');
  const [type, setType] = useState<'borrowerInvite' | 'dealAssociation' | undefined>();
  const { data: dealAssociation, error: dealAssociationError } = useUserDealAssociation(dealId, type === 'dealAssociation');
  const { data: borrowerInvite, error: borrowerInviteError } = useBorrowerInvite(dealId, type === 'borrowerInvite');

  const hasCalledBackend = useRef(false);
  useEffect(() => {
    if (!hasCalledBackend.current) {
      const { invitationId = '', runId = '' } = state as ConsumerAuthCallbackState;
      if (runId) {
        setDealId(runId);
        setType('dealAssociation');
      }
      if (invitationId) {
        setDealId(invitationId);
        setType('borrowerInvite');
      }
      hasCalledBackend.current = true;
    }
  }, [state]);

  useEffect(() => {
    if (type && dealId && (dealAssociation || borrowerInvite)) {
      if (type === 'borrowerInvite') {
        navigate('/home');
      }
      if (type === 'dealAssociation' && dealAssociation?.success) {
        navigate('/home');
        sessionStorage.setItem('loanId', dealId);
      }
    }
  }, [type, dealId, borrowerInvite, dealAssociation, navigate]);

  const error = useMemo(() => borrowerInviteError || dealAssociationError, [borrowerInviteError, dealAssociationError]);

  if (error) {
    return (
      <ContingencyMessage
        icon="Error"
        subtitle={dealAssociationError?.message || borrowerInviteError?.message || 'Try again later'}
        title="Something went wrong"
      />
    );
  }
  return <Loader />;
};

export { ConsumerAuthCallback };
