import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { SkillSetSummary } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useAddSkillsetToActivity } from '@/API/Queries/activities/useAddSkillsetToActivity';
import { useRemoveSkillsetFromActivity } from '@/API/Queries/activities/useRemoveSkillsetFromActivity';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { normalizeString } from '@/Utils/Helpers/normalizeString';

import { useActivityConfiguratorContext } from './activityConfiguratorContext';

type SkillSetTableData = {
  id: string;
  displayName: string;
  skillsetGroupsList: string;
  usersCount: string;
  isAssigned?: IBadgeProps;
};

const asssignedBadge: IBadgeProps = {
  label: 'Assigned', variant: UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE,
};

const toTableData = (skillset: SkillSetSummary.AsObject, isAssigned: boolean): SkillSetTableData => ({
  displayName: skillset.displayName,
  id: skillset.id,
  isAssigned: isAssigned ? asssignedBadge : undefined,
  skillsetGroupsList: skillset.skillsetGroupsList.map((i) => i.displayName).toString(),
  usersCount: String(skillset.usersCount),
});

const ActivityAssignment = () => {
  const { activityId = '' } = useParams();
  const {
    assignedSkillsets,
    availableSkillsets,
    isLoadingDesign,
    setAssignedSkillsets,
    setAvailableSkillsets,
    setSidesheetType,
    setUsers,
  } = useActivityConfiguratorContext();
  const [preSelectedList, setPreSelectedList] = useState<SkillSetTableData[]>([]);
  const [searchText, setSearchText] = useState('');
  useEffect(() => setSidesheetType('none'), [setSidesheetType]);

  const { mutate: removeSkillsetFromActivity } = useRemoveSkillsetFromActivity();
  const { mutate: addSkillsetToActivity } = useAddSkillsetToActivity();

  const handleCallback = useCallback((response: any) => {
    setAssignedSkillsets(response.assignedSkillSetsList);
    setAvailableSkillsets(response.availableSkillSetsList);
    setUsers(response.assignedUsersList);
  }, [setAvailableSkillsets, setAssignedSkillsets, setUsers]);

  const handleProcessSkillSet = useCallback((item: SkillSetTableData) => {
    if (item.isAssigned) {
      removeSkillsetFromActivity({ activityId, skillsetId: item.id }, { onSuccess: (response) => handleCallback(response) });
    } else {
      addSkillsetToActivity({ activityId, skillsetId: item.id }, { onSuccess: (response) => handleCallback(response) });
    }
  }, [removeSkillsetFromActivity, addSkillsetToActivity, activityId, handleCallback]);

  const handleAssignList = (items: SkillSetTableData[]) => {
    const item = items.shift();
    if (item) {
      addSkillsetToActivity({ activityId, skillsetId: item.id }, {
        onSuccess: (response) => {
          if (items.length > 0) {
            handleAssignList(items);
          } else {
            handleCallback(response);
          }
        },
      });
    }
  };
  const handleUnassignList = (items: SkillSetTableData[]) => {
    const item = items.shift();
    if (item) {
      removeSkillsetFromActivity({ activityId, skillsetId: item.id }, {
        onSuccess: (response) => {
          if (items.length > 0) {
            handleUnassignList(items);
          } else {
            handleCallback(response);
          }
        },
      });
    }
  };
  const handleSearchText = (value: string) => setSearchText(value);

  const columns = useMemo((): Array<Column<SkillSetTableData>> => [
    {
      header: 'Skillset Name',
      id: 'displayName',
      key: { text: 'displayName', type: 'text' },
    },
    {
      header: 'Associated Skillset Groups',
      id: 'skillsetGroupsList',
      key: { text: 'skillsetGroupsList', type: 'text' },
    },
    {
      header: '# Of Users',
      id: 'usersCount',
      key: { text: 'usersCount', type: 'text' },
    },
    {
      header: '',
      id: 'isAssigned',
      key: { badge: 'isAssigned', type: 'badge' },
    },
  ], []);

  const data = useMemo((): SkillSetTableData[] => {
    let items = [
      ...assignedSkillsets.map((item) => toTableData(item, true)),
      ...availableSkillsets.map((item) => toTableData(item, false)),
    ];
    if (searchText) {
      items = items.filter((item) => normalizeString(item.displayName).includes(normalizeString(searchText)));
    }

    return items;
  }, [assignedSkillsets, availableSkillsets, searchText]);

  return (
    <div className="p-12 h-full">
      <Table
        cardProps={{
          headerProps: {
            contextualHeaderProps: {
              buttons: [{
                label: 'assign',
                onClick: () => handleAssignList(preSelectedList),
              }, {
                label: 'unassign',
                onClick: () => handleUnassignList(preSelectedList),
              }],
              caption: `${preSelectedList.length} selected skillset${preSelectedList.length !== 1 ? 's' : ''}`,
            },
            searchProps: {
              onChange: handleSearchText,
              value: searchText,
            },
            showContextual: preSelectedList.length > 0,
            title: 'Assign activity to skillsets',
          },

        }}
        columns={columns}
        data={data}
        hoverActions={[{
          label: (item) => (item.isAssigned ? 'Unassign' : 'Assign'),
          onClick: handleProcessSkillSet,
        }]}
        isLoading={isLoadingDesign}
        setRowSelection={setPreSelectedList}
      />
    </div>
  );
};

export { ActivityAssignment };
