// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/RelatedNestingContent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb);
var Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb = require('../../../Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.ContentCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    nestedContentList: jspb.Message.toObjectList(msg.getNestedContentList(),
    proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.deserializeBinaryFromReader);
      msg.addNestedContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNestedContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RelatedNestingContentSettings nested_content = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.getNestedContentList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.setNestedContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.addNestedContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings.prototype.clearNestedContentList = function() {
  return this.setNestedContentList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    expander: (f = msg.getExpander()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.toObject(includeInstance, f),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.toObject, includeInstance),
    help: (f = msg.getHelp()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.deserializeBinaryFromReader);
      msg.setExpander(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.deserializeBinaryFromReader);
      msg.setHelp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getExpander();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.serializeBinaryToWriter
    );
  }
  f = message.getHelp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue label = 1;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.getLabel = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RelatedNestingContentExpanderSettings expander = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.getExpander = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.setExpander = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.clearExpander = function() {
  return this.setExpander(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.hasExpander = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated RelatedNestingContentGroupingSettings groups = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional RelatedNestingContentHelpSettings help = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.getHelp = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.setHelp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.clearHelp = function() {
  return this.setHelp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSettings.prototype.hasHelp = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    summary: (f = msg.getSummary()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.toObject(includeInstance, f),
    collectionOperations: (f = msg.getCollectionOperations()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} */ (reader.readEnum());
      msg.setInitialState(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.deserializeBinaryFromReader);
      msg.setCollectionOperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.serializeBinaryToWriter
    );
  }
  f = message.getCollectionOperations();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelatedNestingContentExpanderState initial_state = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.getInitialState = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.setInitialState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RelatedNestingContentSummarySettings summary = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.getSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RelatedNestingContentCollectionSettings collection_operations = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.getCollectionOperations = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.setCollectionOperations = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.clearCollectionOperations = function() {
  return this.setCollectionOperations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderSettings.prototype.hasCollectionOperations = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    add: (f = msg.getAdd()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.deserializeBinaryFromReader);
      msg.setAdd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelatedNestingContentAddItemSettings add = 1;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.getAdd = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.setAdd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.clearAdd = function() {
  return this.setAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentCollectionSettings.prototype.hasAdd = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.ContentCase = {
  CONTENT_NOT_SET: 0,
  TABLE_FORM: 4,
  CONDITION_ACTIVITIES_TABLE: 5
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.ContentCase}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getContentCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.ContentCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = msg.getLabel()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    expander: (f = msg.getExpander()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.toObject(includeInstance, f),
    help: (f = msg.getHelp()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.toObject(includeInstance, f),
    tableForm: (f = msg.getTableForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.toObject(includeInstance, f),
    conditionActivitiesTable: (f = msg.getConditionActivitiesTable()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.toObject(includeInstance, f),
    item: (f = msg.getItem()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.deserializeBinaryFromReader);
      msg.setExpander(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.deserializeBinaryFromReader);
      msg.setHelp(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.setTableForm(value);
      break;
    case 5:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.setConditionActivitiesTable(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getExpander();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.serializeBinaryToWriter
    );
  }
  f = message.getHelp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.serializeBinaryToWriter
    );
  }
  f = message.getTableForm();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getConditionActivitiesTable();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue label = 1;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getLabel = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RelatedNestingContentGroupingExpanderSettings expander = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getExpander = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setExpander = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearExpander = function() {
  return this.setExpander(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasExpander = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RelatedNestingContentHelpSettings help = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getHelp = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setHelp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearHelp = function() {
  return this.setHelp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasHelp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TableFormWidgetSettings table_form = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getTableForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setTableForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearTableForm = function() {
  return this.setTableForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasTableForm = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TableFormWidgetSettings condition_activities_table = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getConditionActivitiesTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setConditionActivitiesTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearConditionActivitiesTable = function() {
  return this.setConditionActivitiesTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasConditionActivitiesTable = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue item = 6;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getItem = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 6));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.hasItem = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string virtual_field_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSettings.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialState: jspb.Message.getFieldWithDefault(msg, 1, 0),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.toObject(includeInstance, f),
    kebab: (f = msg.getKebab()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.toObject(includeInstance, f),
    collapsedSummary: (f = msg.getCollapsedSummary()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.toObject(includeInstance, f),
    expandedSummary: (f = msg.getExpandedSummary()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} */ (reader.readEnum());
      msg.setInitialState(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.deserializeBinaryFromReader);
      msg.setKebab(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.deserializeBinaryFromReader);
      msg.setCollapsedSummary(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.deserializeBinaryFromReader);
      msg.setExpandedSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.serializeBinaryToWriter
    );
  }
  f = message.getKebab();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.serializeBinaryToWriter
    );
  }
  f = message.getCollapsedSummary();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.serializeBinaryToWriter
    );
  }
  f = message.getExpandedSummary();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional RelatedNestingContentExpanderState initial_state = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.getInitialState = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.setInitialState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional RelatedNestingContentGroupingBadgeSettings badge = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RelatedNestingContentGroupingKebabSettings kebab = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.getKebab = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.setKebab = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.clearKebab = function() {
  return this.setKebab(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.hasKebab = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RelatedNestingContentGroupingSummarySettings collapsed_summary = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.getCollapsedSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.setCollapsedSummary = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.clearCollapsedSummary = function() {
  return this.setCollapsedSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.hasCollapsedSummary = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RelatedNestingContentGroupingSummarySettings expanded_summary = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.getExpandedSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.setExpandedSummary = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.clearExpandedSummary = function() {
  return this.setExpandedSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingExpanderSettings.prototype.hasExpandedSummary = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    delimiter: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelimiter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated wilqo.shared.models.RenderableValue items = 1;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.getDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingSummarySettings.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    variant: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue value = 4;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 4));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.UxDisplayStatusEnum variant = 5;
 * @return {!proto.wilqo.shared.models.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.getVariant = function() {
  return /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.models.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingBadgeSettings.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    add: (f = msg.getAdd()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.toObject(includeInstance, f),
    edit: (f = msg.getEdit()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.toObject(includeInstance, f),
    pb_delete: (f = msg.getDelete()) && proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.toObject(includeInstance, f),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.toObject, includeInstance),
    filterParentScopeForDelete: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.deserializeBinaryFromReader);
      msg.setAdd(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.deserializeBinaryFromReader);
      msg.setEdit(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.deserializeBinaryFromReader);
      msg.setDelete(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilterParentScopeForDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdd();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.serializeBinaryToWriter
    );
  }
  f = message.getEdit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.serializeBinaryToWriter
    );
  }
  f = message.getDelete();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.serializeBinaryToWriter
    );
  }
  f = message.getFilterParentScopeForDelete();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional RelatedNestingContentAddItemSettings add = 1;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.getAdd = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.setAdd = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.clearAdd = function() {
  return this.setAdd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.hasAdd = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RelatedNestingContentEditItemSettings edit = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.getEdit = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.setEdit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.clearEdit = function() {
  return this.setEdit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.hasEdit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RelatedNestingContentDeleteItemSettings delete = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.getDelete = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.setDelete = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.clearDelete = function() {
  return this.setDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.hasDelete = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated LoanPageAction actions = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional bool filter_parent_scope_for_delete = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.getFilterParentScopeForDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentGroupingKebabSettings.prototype.setFilterParentScopeForDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentSummarySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentAddItemSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentEditItemSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentDeleteItemSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings;
  return proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentHelpSettings.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentExpanderState = {
  RELATED_NESTING_CONTENT_EXPANDER_STATE_UNKNOWN: 0,
  RELATED_NESTING_CONTENT_EXPANDER_STATE_EXPANDED: 1,
  RELATED_NESTING_CONTENT_EXPANDER_STATE_COLLAPSED: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
