import { useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useMemo, useState } from 'react';

import type { ActionListItem, LoanPageAction, LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { DYNAMIC_PAGE_MESSAGE_NAME } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { useSearchParams } from '@/Routes/NavigationContext';

interface DynamicContextState {
  dealId: string;
  version: number;
  setVersion: (version: number) => void;
  currentAction?: Partial<LoanPageAction.AsObject>;
  currentSubAction?: ActionListItem.AsObject;
  toggleAction: (action: LoanPageActionType | Partial<LoanPageAction.AsObject> | undefined, subAction?: ActionListItem.AsObject) => void;
  closeAction: () => void;
}

const initialState: DynamicContextState = {
  closeAction: () => null,
  dealId: '',
  setVersion: () => null,
  toggleAction: () => null,
  version: 0,
};

const DynamicContext = createContext(initialState);
DynamicContext.displayName = 'DynamicContext';

interface DynamicContextProviderProps {
  children: React.ReactNode;
  dealId: string;
}

const DynamicContextProvider = (props: DynamicContextProviderProps) => {
  const { children, dealId } = props;

  const [version, setVersion] = useState(0);
  const [currentAction, setCurrentAction] = useState<Partial<LoanPageAction.AsObject>>();
  const [currentSubAction, setCurrentSubAction] = useState<ActionListItem.AsObject>();

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { pageId, scopeToken } = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const toggleAction = (action?: LoanPageActionType | Partial<LoanPageAction.AsObject>, subAction?: ActionListItem.AsObject | undefined) => {
    if (subAction) {
      setCurrentAction({ type: subAction.actionType });
      setCurrentSubAction(subAction);
    } else if (typeof action === 'number') {
      setCurrentAction({ type: action });
    } else {
      setCurrentAction(action);
    }
  };

  const closeAction = () => {
    queryClient.invalidateQueries([DYNAMIC_PAGE_MESSAGE_NAME, dealId, pageId, scopeToken]);
    toggleAction(undefined, undefined);
  };

  return (
    <DynamicContext.Provider value={{ closeAction, currentAction, currentSubAction, dealId, setVersion, toggleAction, version }}>
      {children}
    </DynamicContext.Provider>
  );
};

const useDynamicContext = () => useContext(DynamicContext);
export { DynamicContextProvider, useDynamicContext };
