import { useMutation } from '@tanstack/react-query';

import { DealWriteCommandResponse, TogglableWidgetPageSaveCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest';

interface UpdateObject {
  dealId: string;
  dealVersion: number;
  itemsList: TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem[];
  pageId: string;
}

export const useTogglableWidgetSave = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (updateObject: UpdateObject) => {
    const request = new TogglableWidgetPageSaveCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setDealId(updateObject.dealId);
    request.setDealVersion(updateObject.dealVersion);
    request.setItemsList(updateObject.itemsList);
    request.setPageId(updateObject.pageId);
    return sendMessage<DealWriteCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      DealWriteCommandResponse,
    );
  });
};
