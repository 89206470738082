import type { HTMLProps } from 'react';

import { Icon } from '../Icon';
import { Typography } from '../Typography';

export interface IDrawerHeader extends HTMLProps<HTMLDivElement> {
  title: string;
  onClickIcon?: VoidFunction;
}

export const DrawerHeader = ({ onClickIcon, title }: IDrawerHeader) => (
  <div className="flex items-center justify-between w-full tablet:h-[72px] h-[56px] px-4 tablet:py-6 py-4 border-b bg-surface" data-drawer-header>
    <Typography variant="display6">{title}</Typography>
    <Icon icon="Clear" onClick={onClickIcon} variant="interactive" />
  </div>
);
