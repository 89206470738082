import { useQueryClient } from '@tanstack/react-query';

import { InfoCardTypeEnum } from '@/API/Models/Wilqo.Shared.Models/BrandAdminModels_pb';
import { IntentToProceedCommunicationMethodEnum, UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { GET_INTENT_TO_PROCEED_MESSAGE, useGetIntentToProceedStatus } from '@/API/Queries/mortgage/useGetIntentToProceedStatus';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useUpsertBorrowerIntentToProceed } from '@/API/Queries/mortgage/useUpsertBorrowerIntentToProceed';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Typography } from '@/Components/Atoms/Typography';
import { DynamicDataElementMap, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getEnumFromParsedValue } from '@/Utils/Helpers/enumHelpers';
import { getDateFormatFromTimestamp, getDateFromWilqoTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { convertStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const getDynamicString = (v?: any) => (v ? toString(DynamicDataElementMap(v)) : '');
const getParamFromObject = (o: any, str: string): any => {
  const data = Object.entries(o).find(([key]) => !!key.includes(str));
  return (data || [])[1];
};

const IntentToProceedDetails = () => {
  const { data: loan, isLoading: isLoadingLoan } = useLoan();
  const { isMobile } = useWindow();
  const { isLoading: isSaving, mutate } = useUpsertBorrowerIntentToProceed();
  const { showSnackBar } = useShared();
  const queryClient = useQueryClient();

  const { data: intentToProceedStatus, isLoading } = useGetIntentToProceedStatus(loan?.dealId || '');

  if (isLoadingLoan || isLoading) {
    return <Skeleton height="70px" margin="20px 0" rows={6} variant="rect" width="100%" />;
  }

  const handleSaveBorrower = (partyId: string) => (data: any, close: () => void) => {
    mutate({
      borrowerIntentToProceed: {
        intentToProceedDate: getParamFromObject(data, 'intent-to-proceed-date')?.value,
        intentToProceedMethod: getEnumFromParsedValue(
          IntentToProceedCommunicationMethodEnum,
          getDynamicString(getParamFromObject(data, 'intent-to-proceed-method').value.value),
        ) || 0,
      },
      dealId: loan?.dealId || '',
      partyId,
    }, {
      onError: () => {
        showSnackBar({ message: 'There was an error updating the borrower', open: true });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTENT_TO_PROCEED_MESSAGE, loan?.dealId]);
        showSnackBar({ message: 'Borrower updated', open: true });
        close();
      },
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between p-4 tablet:p-8 desktop:pt-12 desktop:pb-0 desktop:px-6">
        <Typography variant="display4">
          Intent to Proceed
        </Typography>
      </div>

      <div className="flex flex-nowrap justify-start gap-6 overflow-x-auto p-4 tablet:p-8 desktop:p-6 desktop:py-4">
        <InfoCard
          caption="Received"
          isMobile={isMobile}
          value={intentToProceedStatus?.intentToProceedReceived ? 'Yes' : 'No'}
          variant={convertStatusEnum(InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_SUCCESS)}
        />
        <InfoCard
          caption="Date received"
          isMobile={isMobile}
          value={getDateFormatFromTimestamp(intentToProceedStatus?.intentToProceedReceivedDate) || '--'}
          variant={convertStatusEnum(InfoCardTypeEnum.INFO_CARD_TYPE_ENUM_NEUTRAL)}
        />
      </div>

      <div className="p-4 tablet:p-8 desktop:p-6 desktop:py-4">
        {intentToProceedStatus?.borrowersIntentToProceedStatusList?.map((borrowerStatus) => (
          <CardListItem
            key={borrowerStatus.partyId}
            headerProps={{
              badge: {
                label: borrowerStatus.intentToProceedSet ? 'INTENT GIVEN' : 'NOT GIVEN YET',
                variant: borrowerStatus.intentToProceedSet
                  ? UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_NEW
                  : UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_RESTING,
              },
              title: borrowerStatus.borrowerName,
            }}
            isEditable
            isSaving={isSaving}
            listItems={[{
              caption: !borrowerStatus.intentToProceedSet ? '--' : getDynamicString(borrowerStatus.intentToProceedMethod?.value) || '--',
              label: 'ITP Method',
              panelElement: borrowerStatus.intentToProceedMethod,
              value: borrowerStatus.intentToProceedMethod?.optionsList.find((option) => option.id === getDynamicString(borrowerStatus.intentToProceedMethod?.value)),
            }, {
              caption: !borrowerStatus.intentToProceedSet ? '--' : getDateFromWilqoTimestamp(borrowerStatus.intentToProceedDate?.value) || '--',
              label: 'ITP Given Date',
              panelElement: borrowerStatus.intentToProceedDate,
              value: { value: getDateFromWilqoTimestamp(borrowerStatus.intentToProceedDate?.value) },
            }]}
            onSubmit={handleSaveBorrower(borrowerStatus.partyId)}
            variant="border"
          />
        ))}
      </div>
    </div>
  );
};

export { IntentToProceedDetails };
