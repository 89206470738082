// source: Wilqo.Shared.Models/Configuration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.wilqo.shared.models.ExpirationDateConfiguration', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ExpirationDateTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ExpirationInformation', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Flexlist', null, global);
goog.exportSymbol('proto.wilqo.shared.models.FlexlistSection', null, global);
goog.exportSymbol('proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.FlexlistSubSection', null, global);
goog.exportSymbol('proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Flexlist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Flexlist.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Flexlist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Flexlist.displayName = 'proto.wilqo.shared.models.Flexlist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.FlexlistSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.FlexlistSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.FlexlistSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.FlexlistSection.displayName = 'proto.wilqo.shared.models.FlexlistSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.FlexlistSubSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.FlexlistSubSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.FlexlistSubSection.displayName = 'proto.wilqo.shared.models.FlexlistSubSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ExpirationDateConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.ExpirationDateConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ExpirationDateConfiguration.displayName = 'proto.wilqo.shared.models.ExpirationDateConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ExpirationInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.ExpirationInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ExpirationInformation.displayName = 'proto.wilqo.shared.models.ExpirationInformation';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Flexlist.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Flexlist.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Flexlist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Flexlist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Flexlist.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.wilqo.shared.models.FlexlistSection.toObject, includeInstance),
    completed: (f = msg.getCompleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    submitted: (f = msg.getSubmitted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Flexlist}
 */
proto.wilqo.shared.models.Flexlist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Flexlist;
  return proto.wilqo.shared.models.Flexlist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Flexlist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Flexlist}
 */
proto.wilqo.shared.models.Flexlist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.FlexlistSection;
      reader.readMessage(value,proto.wilqo.shared.models.FlexlistSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompleted(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubmitted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Flexlist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Flexlist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Flexlist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Flexlist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.FlexlistSection.serializeBinaryToWriter
    );
  }
  f = message.getCompleted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubmitted();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Flexlist.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
 */
proto.wilqo.shared.models.Flexlist.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Flexlist.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
 */
proto.wilqo.shared.models.Flexlist.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FlexlistSection sections = 3;
 * @return {!Array<!proto.wilqo.shared.models.FlexlistSection>}
 */
proto.wilqo.shared.models.Flexlist.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.FlexlistSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.FlexlistSection, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.FlexlistSection>} value
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
*/
proto.wilqo.shared.models.Flexlist.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.FlexlistSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.FlexlistSection}
 */
proto.wilqo.shared.models.Flexlist.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.FlexlistSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
 */
proto.wilqo.shared.models.Flexlist.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};


/**
 * optional google.protobuf.Timestamp completed = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.Flexlist.prototype.getCompleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
*/
proto.wilqo.shared.models.Flexlist.prototype.setCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
 */
proto.wilqo.shared.models.Flexlist.prototype.clearCompleted = function() {
  return this.setCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.Flexlist.prototype.hasCompleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp submitted = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.Flexlist.prototype.getSubmitted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
*/
proto.wilqo.shared.models.Flexlist.prototype.setSubmitted = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.Flexlist} returns this
 */
proto.wilqo.shared.models.Flexlist.prototype.clearSubmitted = function() {
  return this.setSubmitted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.Flexlist.prototype.hasSubmitted = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.FlexlistSection.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.FlexlistSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.FlexlistSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.FlexlistSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sectionStatusType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.FlexlistSubSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.FlexlistSection}
 */
proto.wilqo.shared.models.FlexlistSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.FlexlistSection;
  return proto.wilqo.shared.models.FlexlistSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.FlexlistSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.FlexlistSection}
 */
proto.wilqo.shared.models.FlexlistSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum} */ (reader.readEnum());
      msg.setSectionStatusType(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.FlexlistSubSection;
      reader.readMessage(value,proto.wilqo.shared.models.FlexlistSubSection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.FlexlistSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.FlexlistSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.FlexlistSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSectionStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.shared.models.FlexlistSubSection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
 */
proto.wilqo.shared.models.FlexlistSection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
 */
proto.wilqo.shared.models.FlexlistSection.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
 */
proto.wilqo.shared.models.FlexlistSection.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FlexlistSectionStatusTypesEnum section_status_type = 4;
 * @return {!proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.getSectionStatusType = function() {
  return /** @type {!proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum} value
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
 */
proto.wilqo.shared.models.FlexlistSection.prototype.setSectionStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated FlexlistSubSection items = 5;
 * @return {!Array<!proto.wilqo.shared.models.FlexlistSubSection>}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.FlexlistSubSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.FlexlistSubSection, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.FlexlistSubSection>} value
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
*/
proto.wilqo.shared.models.FlexlistSection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.FlexlistSubSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.FlexlistSubSection}
 */
proto.wilqo.shared.models.FlexlistSection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.FlexlistSubSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.FlexlistSection} returns this
 */
proto.wilqo.shared.models.FlexlistSection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.FlexlistSubSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.FlexlistSubSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.FlexlistSubSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    flexlistSubSectionDetailType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contentTemplateId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contentTemplateVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    flexlistSubSectionStatusType: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.FlexlistSubSection}
 */
proto.wilqo.shared.models.FlexlistSubSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.FlexlistSubSection;
  return proto.wilqo.shared.models.FlexlistSubSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.FlexlistSubSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.FlexlistSubSection}
 */
proto.wilqo.shared.models.FlexlistSubSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum} */ (reader.readEnum());
      msg.setFlexlistSubSectionDetailType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentTemplateId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentTemplateVersion(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum} */ (reader.readEnum());
      msg.setFlexlistSubSectionStatusType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.FlexlistSubSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.FlexlistSubSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.FlexlistSubSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFlexlistSubSectionDetailType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getContentTemplateId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContentTemplateVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFlexlistSubSectionStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FlexlistSubSectionDetailTypesEnum flexlist_sub_section_detail_type = 4;
 * @return {!proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getFlexlistSubSectionDetailType = function() {
  return /** @type {!proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setFlexlistSubSectionDetailType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string content_template_id = 5;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getContentTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setContentTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string content_template_version = 6;
 * @return {string}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getContentTemplateVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setContentTemplateVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional FlexlistSubSectionStatusTypesEnum flexlist_sub_section_status_type = 7;
 * @return {!proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum}
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.getFlexlistSubSectionStatusType = function() {
  return /** @type {!proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum} value
 * @return {!proto.wilqo.shared.models.FlexlistSubSection} returns this
 */
proto.wilqo.shared.models.FlexlistSubSection.prototype.setFlexlistSubSectionStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ExpirationDateConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ExpirationDateConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    expirationDateType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    daysParameter: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ExpirationDateConfiguration;
  return proto.wilqo.shared.models.ExpirationDateConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ExpirationDateConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.ExpirationDateTypeEnum} */ (reader.readEnum());
      msg.setExpirationDateType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDaysParameter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ExpirationDateConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ExpirationDateConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpirationDateType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDaysParameter();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional ExpirationDateTypeEnum expiration_date_type = 1;
 * @return {!proto.wilqo.shared.models.ExpirationDateTypeEnum}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.getExpirationDateType = function() {
  return /** @type {!proto.wilqo.shared.models.ExpirationDateTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationDateTypeEnum} value
 * @return {!proto.wilqo.shared.models.ExpirationDateConfiguration} returns this
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.setExpirationDateType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 days_parameter = 2;
 * @return {number}
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.getDaysParameter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ExpirationDateConfiguration} returns this
 */
proto.wilqo.shared.models.ExpirationDateConfiguration.prototype.setDaysParameter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ExpirationInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ExpirationInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ExpirationInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    originationDate: (f = msg.getOriginationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    originatorType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originatorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    originatorDisplayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    expirationDateConfiguration: (f = msg.getExpirationDateConfiguration()) && proto.wilqo.shared.models.ExpirationDateConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.shared.models.ExpirationInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ExpirationInformation;
  return proto.wilqo.shared.models.ExpirationInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ExpirationInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.shared.models.ExpirationInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOriginationDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginatorDisplayName(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.ExpirationDateConfiguration;
      reader.readMessage(value,proto.wilqo.shared.models.ExpirationDateConfiguration.deserializeBinaryFromReader);
      msg.setExpirationDateConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ExpirationInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ExpirationInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ExpirationInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOriginationDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOriginatorType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginatorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOriginatorDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExpirationDateConfiguration();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.shared.models.ExpirationDateConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp expiration_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
*/
proto.wilqo.shared.models.ExpirationInformation.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp origination_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getOriginationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
*/
proto.wilqo.shared.models.ExpirationInformation.prototype.setOriginationDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.clearOriginationDate = function() {
  return this.setOriginationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.hasOriginationDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string originator_type = 3;
 * @return {string}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getOriginatorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.setOriginatorType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string originator_id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getOriginatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.setOriginatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string originator_display_name = 5;
 * @return {string}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getOriginatorDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.setOriginatorDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ExpirationDateConfiguration expiration_date_configuration = 6;
 * @return {?proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.getExpirationDateConfiguration = function() {
  return /** @type{?proto.wilqo.shared.models.ExpirationDateConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.ExpirationDateConfiguration, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ExpirationDateConfiguration|undefined} value
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
*/
proto.wilqo.shared.models.ExpirationInformation.prototype.setExpirationDateConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ExpirationInformation} returns this
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.clearExpirationDateConfiguration = function() {
  return this.setExpirationDateConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ExpirationInformation.prototype.hasExpirationDateConfiguration = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.FlexlistSectionStatusTypesEnum = {
  FLEXLIST_SECTION_STATUS_TYPES_ENUM_UNKNOWN: 0,
  FLEXLIST_SECTION_STATUS_TYPES_ENUM_NOT_STARTED: 1,
  FLEXLIST_SECTION_STATUS_TYPES_ENUM_STARTED: 2,
  FLEXLIST_SECTION_STATUS_TYPES_ENUM_COMPLETE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.FlexlistSubSectionStatusTypesEnum = {
  FLEXLIST_SUB_SECTION_STATUS_TYPES_ENUM_UNKNOWN: 0,
  FLEXLIST_SUB_SECTION_STATUS_TYPES_ENUM_NOT_STARTED: 1,
  FLEXLIST_SUB_SECTION_STATUS_TYPES_ENUM_STARTED: 2,
  FLEXLIST_SUB_SECTION_STATUS_TYPES_ENUM_COMPLETE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.FlexlistSubSectionDetailTypesEnum = {
  FLEXLIST_SUB_SECTION_DETAIL_TYPES_ENUM_UNKNOWN: 0
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.ExpirationDateTypeEnum = {
  EXPIRATION_DATE_TYPE_ENUM_UNKNOWN: 0,
  EXPIRATION_DATE_TYPE_ENUM_USE_CALENDAR_DAYS: 1,
  EXPIRATION_DATE_TYPE_ENUM_USE_BUSINESS_DAYS: 2
};

goog.object.extend(exports, proto.wilqo.shared.models);
