import type { PanelElementListItem } from '@/Components/Atoms/Card/CardListItem';
import { RadioGroupOrientationEnum } from '@/Components/Atoms/RadioGroup/RadioGroup.component';
import type { CardListItemField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem_pb';
import type { FormField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { RenderableValue } from '@/API/Models/Wilqo.Shared.Models/RenderableValue_pb';
import { DisplayTypeEnum } from '@/API/Models/Wilqo.Shared.Models/RenderableValue_pb';

import { DynamicDataElementMap } from './dynamicDataElementHelper';
import type { PanelElementExtended } from './getPanelElement';
import { renderValue } from './renderableHelper';
import { calculateScreenSize, WindowTypeEnum } from './useWindow';

export const getFieldPanelElementType = (panelElementType: string, value?: RenderableValue.AsObject) => {
  if (value?.enumeration?.fieldTypeOverride || value?.pb_boolean?.fieldTypeOverride) {
    return value.enumeration?.fieldTypeOverride || value.pb_boolean?.fieldTypeOverride || '';
  }
  return panelElementType;
};

export const mapCardListItemFieldToPanelElement = (field: CardListItemField.AsObject, hiddenFieldIds: Array<string>, fieldGroupingSource?: boolean): PanelElementListItem & { id: string } => {
  const dde = field?.value?.value ? DynamicDataElementMap(field.value.value) : undefined;
  const caption = field?.displayOverrideValue ? renderValue(field.displayOverrideValue, field.id, field.panelElement) : renderValue(field.value, field.id, field.panelElement);
  const isConditionallyHidden = hiddenFieldIds.includes(field.id);

  
  const out: PanelElementListItem & { id: string; } = {
    caption: field.helpText,
    hidden: field.hidden || isConditionallyHidden,
    id: field.id,
    label: !field.value && !field.displayOverrideValue ? '--' : caption,
    overline: field.leadingText || '--',
    panelElement: field.panelElement,
    value: { dynamic: dde, fieldConfig: field },
  };
  if (out.panelElement) {
    out.panelElement.type = fieldGroupingSource ? 'auto-complete-address' : getFieldPanelElementType(out.panelElement.type, field.value);
    (out.panelElement as PanelElementExtended).variant = fieldGroupingSource ? 'disable-manual' : undefined
    if (field.value?.related?.itemAddDetails?.pageId) {
      out.panelElement.type = out.panelElement.type !== 'multi-select' ? 'dynamic-select' : 'multi-select';
      const config = {
        pageId: field.value.related.itemAddDetails.pageId,
        // prevPageId: pageId,
        // prevScopeToken: scopeToken,
      };
      out.panelElement.path = JSON.stringify(config);
    }
    out.panelElement.disabled = field.readOnly;
    out.panelElement.summaryText = field.panelElement?.summaryText || field.helpText;
    out.panelElement.value = field.value?.value;
    out.panelElement.headerText = field.leadingText; // Override w display text from dynamic page config
    out.panelElement.id = field.id; // Override panel element id with config item's id for save handling
  }

  // TEMPORARY override of mask type until BE can consistenly provide
  if (field.value && field.value.displayType && out.panelElement) {
    switch (field.value.displayType) {
      case DisplayTypeEnum.DISPLAY_TYPE_ENUM_CURRENCY:
        out.panelElement.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'currency' };
        break;
      case DisplayTypeEnum.DISPLAY_TYPE_ENUM_DATE:
        out.panelElement.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'date' };
        break;
      default:
        break;
    }
    if (field.id === 'UwT2DG15Io3JMRa7ZV1Wk') {
      out.panelElement.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'ssn9' };
    }
  }

  return out;
};

export type FormPanelElement = PanelElementExtended & { hidden?: boolean; orientation?: RadioGroupOrientationEnum };

export const mapFormFieldToPanelElement = (field: FormField.AsObject, isURLAForm?: boolean, fieldGroupingSource?: boolean): FormPanelElement | undefined => {
  const { helpText, id, leadingText, panelElement, value } = field;
  if (panelElement) {
    const pe: FormPanelElement = panelElement;
    const isDesktop = calculateScreenSize(window.innerWidth) === WindowTypeEnum.desktop;
    if (pe) {
      pe.id = id;
      pe.headerText = leadingText;
      pe.summaryText = pe.summaryText || helpText;
      pe.hidden = field.hidden;
      pe.disabled = field.readOnly;
      pe.type = fieldGroupingSource ? 'auto-complete-address' : getFieldPanelElementType(pe.type, value);
      pe.variant = fieldGroupingSource ? 'disable-manual' : undefined
      if (isURLAForm && ['radio', 'radioGroup'].includes(pe.type)) {
        pe.orientation = isDesktop && pe.optionsList.some((option) => option.headerText.toLowerCase() === 'yes')
          ? RadioGroupOrientationEnum.ROW
          : RadioGroupOrientationEnum.MIX;
      }
    }

    // TEMPORARY override of mask type until BE can consistently provide
    if (value && value.displayType && pe) {
      switch (value.displayType) {
        case DisplayTypeEnum.DISPLAY_TYPE_ENUM_CURRENCY:
          pe.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'currency' };
          break;
        case DisplayTypeEnum.DISPLAY_TYPE_ENUM_DATE:
          pe.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'date' };
          break;
        default:
          break;
      }
      if (id === 'UwT2DG15Io3JMRa7ZV1Wk') {
        pe.mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'ssn9' };
      }
    }
    if (pe) {
      pe.value = value?.value;
    }
    return pe;
  }
  return undefined;
};
