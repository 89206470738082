// source: Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
goog.exportSymbol('proto.wilqo.shared.rulesengine.Operator', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.OperatorEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.Rule', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleAggregator', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleGroup', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleGroupDefinition', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleTypeEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.Operator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.Operator.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.Operator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.Operator.displayName = 'proto.wilqo.shared.rulesengine.Operator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.Rule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.Rule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.Rule.displayName = 'proto.wilqo.shared.rulesengine.Rule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.RuleGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleGroup.displayName = 'proto.wilqo.shared.rulesengine.RuleGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleGroupDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleGroupDefinition.displayName = 'proto.wilqo.shared.rulesengine.RuleGroupDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleAggregator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.RuleAggregator.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleAggregator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleAggregator.displayName = 'proto.wilqo.shared.rulesengine.RuleAggregator';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.Operator.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.Operator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.Operator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Operator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stringRepresentation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    comparableValueRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    panelElementTypeOverride: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.Operator}
 */
proto.wilqo.shared.rulesengine.Operator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.Operator;
  return proto.wilqo.shared.rulesengine.Operator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.Operator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.Operator}
 */
proto.wilqo.shared.rulesengine.Operator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringRepresentation(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.shared.models.DataTypeEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDataTypes(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComparableValueRequired(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementTypeOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.Operator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.Operator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Operator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStringRepresentation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getComparableValueRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPanelElementTypeOverride();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional OperatorEnum id = 1;
 * @return {!proto.wilqo.shared.rulesengine.OperatorEnum}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getId = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorEnum} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string string_representation = 3;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getStringRepresentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setStringRepresentation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated wilqo.shared.models.DataTypeEnum data_types = 4;
 * @return {!Array<!proto.wilqo.shared.models.DataTypeEnum>}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getDataTypesList = function() {
  return /** @type {!Array<!proto.wilqo.shared.models.DataTypeEnum>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataTypeEnum>} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setDataTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.addDataTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.clearDataTypesList = function() {
  return this.setDataTypesList([]);
};


/**
 * optional bool comparable_value_required = 5;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getComparableValueRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setComparableValueRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string panel_element_type_override = 6;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Operator.prototype.getPanelElementTypeOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Operator} returns this
 */
proto.wilqo.shared.rulesengine.Operator.prototype.setPanelElementTypeOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.Rule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.Rule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Rule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldLibraryIdentifier: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fieldLibraryOptionId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fieldLibraryExpression: jspb.Message.getFieldWithDefault(msg, 9, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comparableValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    clrTypeName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.Rule}
 */
proto.wilqo.shared.rulesengine.Rule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.Rule;
  return proto.wilqo.shared.rulesengine.Rule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.Rule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.Rule}
 */
proto.wilqo.shared.rulesengine.Rule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryIdentifier(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryOptionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryExpression(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComparableValue(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClrTypeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.Rule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.Rule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.Rule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldLibraryIdentifier();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFieldLibraryOptionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFieldLibraryExpression();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getComparableValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getClrTypeName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message_type = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field_library_identifier = 8;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getFieldLibraryIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setFieldLibraryIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string field_library_option_id = 11;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getFieldLibraryOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setFieldLibraryOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string field_library_expression = 9;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getFieldLibraryExpression = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setFieldLibraryExpression = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional OperatorEnum operator = 4;
 * @return {!proto.wilqo.shared.rulesengine.OperatorEnum}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getOperator = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorEnum} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string comparable_value = 5;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getComparableValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setComparableValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 6;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string clr_type_name = 10;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.Rule.prototype.getClrTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.Rule} returns this
 */
proto.wilqo.shared.rulesengine.Rule.prototype.setClrTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.RuleGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.wilqo.shared.rulesengine.Rule.toObject, includeInstance),
    applyAndBetweenRules: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    ruleGroupDefinitionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    externalDescription: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup}
 */
proto.wilqo.shared.rulesengine.RuleGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleGroup;
  return proto.wilqo.shared.rulesengine.RuleGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup}
 */
proto.wilqo.shared.rulesengine.RuleGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.Rule;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.Rule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenRules(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRuleGroupDefinitionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.Rule.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenRules();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRuleGroupDefinitionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExternalDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool read_only = 2;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Rule rules = 3;
 * @return {!Array<!proto.wilqo.shared.rulesengine.Rule>}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.Rule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.Rule, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.Rule>} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
*/
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.Rule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.Rule}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.rulesengine.Rule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional bool apply_and_between_rules = 4;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getApplyAndBetweenRules = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setApplyAndBetweenRules = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string rule_group_definition_id = 5;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getRuleGroupDefinitionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setRuleGroupDefinitionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string external_description = 6;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.getExternalDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroup.prototype.setExternalDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleGroupDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupDefinition}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleGroupDefinition;
  return proto.wilqo.shared.rulesengine.RuleGroupDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupDefinition}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleGroupDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_description = 3;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupDefinition} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupDefinition.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.RuleAggregator.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleAggregator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleAggregator.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.shared.rulesengine.RuleGroup.toObject, includeInstance),
    applyAndBetweenGroups: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregator}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleAggregator;
  return proto.wilqo.shared.rulesengine.RuleAggregator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregator}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.shared.rulesengine.RuleGroup;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.RuleGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleAggregator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleAggregator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.shared.rulesengine.RuleGroup.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenGroups();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated RuleGroup groups = 1;
 * @return {!Array<!proto.wilqo.shared.rulesengine.RuleGroup>}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.RuleGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.RuleGroup, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.RuleGroup>} value
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregator} returns this
*/
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.RuleGroup}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.rulesengine.RuleGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional bool apply_and_between_groups = 2;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.getApplyAndBetweenGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.RuleAggregator.prototype.setApplyAndBetweenGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.OperatorEnum = {
  OPERATOR_ENUM_UNKNOWN: 0,
  OPERATOR_ENUM_CONTAINS_VALUE: 1,
  OPERATOR_ENUM_DOES_NOT_CONTAINS_VALUE: 2,
  OPERATOR_ENUM_EQUALS: 3,
  OPERATOR_ENUM_DOES_NOT_EQUALS: 4,
  OPERATOR_ENUM_CONTAINS: 5,
  OPERATOR_ENUM_DOES_NOT_CONTAINS: 6,
  OPERATOR_ENUM_GREATER_THAN: 7,
  OPERATOR_ENUM_GREATER_THAN_OR_EQUAL: 8,
  OPERATOR_ENUM_LESS_THAN: 9,
  OPERATOR_ENUM_LESS_THAN_OR_EQUAL: 10,
  OPERATOR_ENUM_ONE_OF: 11,
  OPERATOR_ENUM_NONE_OF: 12,
  OPERATOR_ENUM_IS_TRUE: 13,
  OPERATOR_ENUM_IS_FALSE: 14,
  OPERATOR_ENUM_HAS_CONDITION: 15,
  OPERATOR_ENUM_PROGRESS_ITEM_IS_NOT_STARTED: 16,
  OPERATOR_ENUM_PROGRESS_ITEM_IS_STARTED: 17,
  OPERATOR_ENUM_PROGRESS_ITEM_IS_COMPLETED: 18,
  OPERATOR_ENUM_COMPLIANCE_TEST_CHECK: 19,
  OPERATOR_ENUM_FRAUD_TEST_CHECK: 20,
  OPERATOR_ENUM_DOCUMENT_STATUS_CHECK: 21,
  OPERATOR_ENUM_PROGRESS_ITEM_NEEDS_REVIEW: 22,
  OPERATOR_ENUM_PROGRESS_ITEM_VALIDATED: 23,
  OPERATOR_ENUM_PROGRESS_ITEM_EXPIRED: 24,
  OPERATOR_ENUM_PROGRESS_ITEM_CANCELED: 25,
  OPERATOR_ENUM_DOCUMENT_CATEGORY_CHECK: 26,
  OPERATOR_ENUM_PROGRESS_ITEM_NOT_APPLICABLE: 27,
  OPERATOR_ENUM_PROGRESS_ITEM_LO_COMPLETED: 28,
  OPERATOR_ENUM_PROGRESS_ITEM_READY_FOR_UW: 29,
  OPERATOR_ENUM_PROGRESS_ITEM_UW_APPROVED: 30
};

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.RuleTypeEnum = {
  RULE_TYPE_ENUM_UNKNOWN: 0,
  RULE_TYPE_ENUM_FIELD_LIBRARY: 1,
  RULE_TYPE_ENUM_PROGRESS_ITEM: 2
};

goog.object.extend(exports, proto.wilqo.shared.rulesengine);
