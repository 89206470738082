// source: Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('../Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb = require('../Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb = require('../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb);
var Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb = require('../Wilqo.API.Mortgage.DynamicData/Circuit/CircuitSideEffect_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Circuit_CircuitSideEffect_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ConsentConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ConsentField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RequiredField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ConsentConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ConsentConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ConsentField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ConsentField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ConsentField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.RequiredField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.RequiredField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.RequiredField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryUpdate.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryUpdate;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryUpdate.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryUpdate.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated FieldLibraryUpdate values = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryUpdate, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryWriteCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElementDataTypeEnum data_type = 2;
 * @return {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    serializedValue: (f = msg.getSerializedValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setSerializedValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSerializedValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement serialized_value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.getSerializedValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.setSerializedValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.clearSerializedValue = function() {
  return this.setSerializedValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SerializeDynamicDataElementCommandResponse.prototype.hasSerializedValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_Shared_Models_DynamicData_pb.DynamicDataScopedWriteItem.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pageTitle: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataScopedWriteItem;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataScopedWriteItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataScopedWriteItem.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPageTitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.DynamicDataScopedWriteItem items = 3;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataScopedWriteItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataScopedWriteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataScopedWriteItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataScopedWriteItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataScopedWriteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.DynamicDataScopedWriteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string page_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string widget_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string page_title = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.getPageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWriteCommandRequest.prototype.setPageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated SaveFieldItem items = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated SaveFieldItem items = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFormWidgetDataCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createNew: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateNew(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreateNew();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool create_new = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getCreateNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setCreateNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated SaveFieldItem items = 8;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveCollectionFormWidgetDataCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult;
  return proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string virtual_field_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.toObject, includeInstance),
    childrenScopeList: jspb.Message.toObjectList(msg.getChildrenScopeList(),
    Wilqo_Shared_Models_DynamicData_pb.ScopeValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_DynamicData_pb.ScopeValue;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.ScopeValue.deserializeBinaryFromReader);
      msg.addChildrenScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.serializeBinaryToWriter
    );
  }
  f = message.getChildrenScopeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Wilqo_Shared_Models_DynamicData_pb.ScopeValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated SectionFieldResult items = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated wilqo.shared.models.ScopeValue children_scope = 8;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.getChildrenScopeList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.ScopeValue, 8));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.setChildrenScopeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.addChildrenScope = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveVirtualFieldValuesCommandRequest.prototype.clearChildrenScopeList = function() {
  return this.setChildrenScopeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    consents: (f = msg.getConsents()) && proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    toggleConsentSource: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ConsentConfig;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.deserializeBinaryFromReader);
      msg.setConsents(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum} */ (reader.readEnum());
      msg.setToggleConsentSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConsents();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getToggleConsentSource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum = {
  TOGGLE_CONSENT_SOURCE_LOAN_PAGE: 0,
  TOGGLE_CONSENT_SOURCE_CIRCUIT_PAGE: 1
};

/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ConsentConfig consents = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ConsentConfig}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getConsents = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ConsentConfig, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ConsentConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setConsents = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.clearConsents = function() {
  return this.setConsents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.hasConsents = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string page_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ToggleConsentSourceEnum toggle_consent_source = 7;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.getToggleConsentSource = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.ToggleConsentSourceEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveToggleConsentCommandRequest.prototype.setToggleConsentSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    consentFieldList: jspb.Message.toObjectList(msg.getConsentFieldList(),
    proto.wilqo.api.mortgage_dynamic_data.ConsentField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ConsentConfig;
  return proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ConsentField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ConsentField.deserializeBinaryFromReader);
      msg.addConsentField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConsentFieldList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ConsentField.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConsentField consent_field = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ConsentField>}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.getConsentFieldList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ConsentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ConsentField, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ConsentField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.setConsentFieldList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.addConsentField = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.ConsentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentConfig} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentConfig.prototype.clearConsentFieldList = function() {
  return this.setConsentFieldList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ConsentField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasDynamicValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dynamicValue: (f = msg.getDynamicValue()) && proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject(includeInstance, f),
    consentFieldType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ConsentField;
  return proto.wilqo.api.mortgage_dynamic_data.ConsentField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDynamicValue(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.setDynamicValue(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType} */ (reader.readEnum());
      msg.setConsentFieldType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ConsentField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasDynamicValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDynamicValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
  f = message.getConsentFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool has_dynamic_value = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.getHasDynamicValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.setHasDynamicValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional SaveFieldItem dynamic_value = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.getDynamicValue = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.setDynamicValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.clearDynamicValue = function() {
  return this.setDynamicValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.hasDynamicValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ConsentFieldType consent_field_type = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.getConsentFieldType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConsentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentField.prototype.setConsentFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField;
  return proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated TableNewItemField items = 8;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.TableNewItemField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveTableNewItemCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.toObject, includeInstance),
    createNew: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    dynamicActivityConfigId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateNew(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDynamicActivityConfigId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.serializeBinaryToWriter
    );
  }
  f = message.getCreateNew();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDynamicActivityConfigId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated FullPageSaveItem items = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool create_new = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getCreateNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setCreateNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string dynamic_activity_config_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.getDynamicActivityConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest.prototype.setDynamicActivityConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    widgetScopeToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem;
  return proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetScopeToken(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWidgetScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string widget_scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.getWidgetScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.setWidgetScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SaveFieldItem items = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    widgetScopeToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance),
    createNew: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem;
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetScopeToken(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateNew(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWidgetScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
  f = message.getCreateNew();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string widget_scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.getWidgetScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.setWidgetScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SaveFieldItem items = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool create_new = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.getCreateNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem.prototype.setCreateNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated TogglableWidgetPageSaveItem items = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.TogglableWidgetPageSaveItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetPageSaveCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    borrowerToBeDeletedScopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alternatePrimaryBorrowerSelectionScopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deletePageType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerToBeDeletedScopeToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternatePrimaryBorrowerSelectionScopeToken(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} */ (reader.readEnum());
      msg.setDeletePageType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBorrowerToBeDeletedScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlternatePrimaryBorrowerSelectionScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeletePageType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string borrower_to_be_deleted_scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getBorrowerToBeDeletedScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setBorrowerToBeDeletedScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string alternate_primary_borrower_selection_scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getAlternatePrimaryBorrowerSelectionScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setAlternatePrimaryBorrowerSelectionScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DeleteBorrowerPageType delete_page_type = 5;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getDeletePageType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setDeletePageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextPageTypeToBeRequested: jspb.Message.getFieldWithDefault(msg, 3, 0),
    alternatePrimaryBorrowerSelectionOptionsList: jspb.Message.toObjectList(msg.getAlternatePrimaryBorrowerSelectionOptionsList(),
    proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.toObject, includeInstance),
    associatedItemsData: (f = msg.getAssociatedItemsData()) && proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.toObject(includeInstance, f),
    alternatePrimaryBorrowerSelectionScopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    borrowerAndAssociatedDataDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} */ (reader.readEnum());
      msg.setNextPageTypeToBeRequested(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.deserializeBinaryFromReader);
      msg.addAlternatePrimaryBorrowerSelectionOptions(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.deserializeBinaryFromReader);
      msg.setAssociatedItemsData(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlternatePrimaryBorrowerSelectionScopeToken(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerAndAssociatedDataDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNextPageTypeToBeRequested();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAlternatePrimaryBorrowerSelectionOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedItemsData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.serializeBinaryToWriter
    );
  }
  f = message.getAlternatePrimaryBorrowerSelectionScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBorrowerAndAssociatedDataDeleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerItemScopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerItemScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerItemScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string borrower_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string borrower_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_item_scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.getBorrowerItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem.prototype.setBorrowerItemScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    associatedItemsDataToBeDeletedList: jspb.Message.toObjectList(msg.getAssociatedItemsDataToBeDeletedList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.toObject, includeInstance),
    associatedItemsDataToBePreservedList: jspb.Message.toObjectList(msg.getAssociatedItemsDataToBePreservedList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.addAssociatedItemsDataToBeDeleted(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.addAssociatedItemsDataToBePreserved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssociatedItemsDataToBeDeletedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedItemsDataToBePreservedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TableFormWidgetSettings associated_items_data_to_be_deleted = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.getAssociatedItemsDataToBeDeletedList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.setAssociatedItemsDataToBeDeletedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.addAssociatedItemsDataToBeDeleted = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.clearAssociatedItemsDataToBeDeletedList = function() {
  return this.setAssociatedItemsDataToBeDeletedList([]);
};


/**
 * repeated TableFormWidgetSettings associated_items_data_to_be_preserved = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.getAssociatedItemsDataToBePreservedList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.setAssociatedItemsDataToBePreservedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.addAssociatedItemsDataToBePreserved = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo.prototype.clearAssociatedItemsDataToBePreservedList = function() {
  return this.setAssociatedItemsDataToBePreservedList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DeleteBorrowerPageType next_page_type_to_be_requested = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getNextPageTypeToBeRequested = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setNextPageTypeToBeRequested = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated BorrowerItem alternate_primary_borrower_selection_options = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getAlternatePrimaryBorrowerSelectionOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setAlternatePrimaryBorrowerSelectionOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.addAlternatePrimaryBorrowerSelectionOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.BorrowerItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.clearAlternatePrimaryBorrowerSelectionOptionsList = function() {
  return this.setAlternatePrimaryBorrowerSelectionOptionsList([]);
};


/**
 * optional AssociationInfo associated_items_data = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getAssociatedItemsData = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.AssociationInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setAssociatedItemsData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.clearAssociatedItemsData = function() {
  return this.setAssociatedItemsData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.hasAssociatedItemsData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string alternate_primary_borrower_selection_scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getAlternatePrimaryBorrowerSelectionScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setAlternatePrimaryBorrowerSelectionScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool borrower_and_associated_data_deleted = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.getBorrowerAndAssociatedDataDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerCommandResponse.prototype.setBorrowerAndAssociatedDataDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deleteConfirmation: (f = msg.getDeleteConfirmation()) && proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.deserializeBinaryFromReader);
      msg.setDeleteConfirmation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeleteConfirmation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType = {
  DELETE_TYPE_UNKNOWN: 0,
  DELETE_TYPE_ITEM_ONLY: 1,
  DELETE_TYPE_ITEM_AND_ASSOCIATIONS: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    associatedItemsList: jspb.Message.toObjectList(msg.getAssociatedItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType} */ (reader.readEnum());
      msg.setDeleteType(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.deserializeBinaryFromReader);
      msg.addAssociatedItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAssociatedItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeleteType delete_type = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.getDeleteType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.setDeleteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated AssociatedItemRequest associated_items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.getAssociatedItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.setAssociatedItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.addAssociatedItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.AssociatedItemRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation.prototype.clearAssociatedItemsList = function() {
  return this.setAssociatedItemsList([]);
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DeleteConfirmation delete_confirmation = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.getDeleteConfirmation = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.DeleteConfirmation|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.setDeleteConfirmation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.clearDeleteConfirmation = function() {
  return this.setDeleteConfirmation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.hasDeleteConfirmation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    displayPrompt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    associatedItemsData: (f = msg.getAssociatedItemsData()) && proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.toObject(includeInstance, f),
    addAssociatedItemConfirmationOption: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayPrompt(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.deserializeBinaryFromReader);
      msg.setAssociatedItemsData(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddAssociatedItemConfirmationOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDisplayPrompt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssociatedItemsData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.serializeBinaryToWriter
    );
  }
  f = message.getAddAssociatedItemConfirmationOption();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus = {
  DELETE_STATUS_UNKNOWN: 0,
  DELETE_STATUS_ERROR: 1,
  DELETE_STATUS_ITEM_DELETED: 2,
  DELETE_STATUS_ITEM_AND_ASSOCIATIONS_DELETED: 3,
  DELETE_STATUS_HAS_ASSOCIATIONS: 4,
  DELETE_STATUS_NO_ASSOCIATIONS: 5,
  DELETE_STATUS_ITEM_CANNOT_BE_DELETED: 6
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namePartsList: jspb.Message.toObjectList(msg.getNamePartsList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addNameParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamePartsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.RenderableValue name_parts = 3;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.getNamePartsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.setNamePartsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.addNameParts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.prototype.clearNamePartsList = function() {
  return this.setNamePartsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData;
  return proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssociatedItemResponse items = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse>}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.AssociatedItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DeleteStatus status = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteStatus} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string display_prompt = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getDisplayPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setDisplayPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DeleteItemCommandAssociatedItemData associated_items_data = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getAssociatedItemsData = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.DeleteItemCommandAssociatedItemData|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setAssociatedItemsData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.clearAssociatedItemsData = function() {
  return this.setAssociatedItemsData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.hasAssociatedItemsData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool add_associated_item_confirmation_option = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.getAddAssociatedItemConfirmationOption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteItemCommandResponse.prototype.setAddAssociatedItemConfirmationOption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    consolidatedItemScopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
  return proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsolidatedItemScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getConsolidatedItemScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string consolidated_item_scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.getConsolidatedItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.prototype.setConsolidatedItemScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    retry: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRetry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRetry();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool retry = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.getRetry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealWriteCommandResponse.prototype.setRetry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated RequiredFieldGroup groups = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.RequiredField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup;
  return proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.RequiredField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.RequiredField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.RequiredField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scope_token = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated RequiredField fields = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredField>}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.RequiredField, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.RequiredField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.RequiredField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredFieldGroup.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.RequiredField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.RequiredField;
  return proto.wilqo.api.mortgage_dynamic_data.RequiredField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.RequiredField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.RequiredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.RequiredField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance),
    pageId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated SaveFieldItem items = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string page_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string widget_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    iteratorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    uniqueIdentifierVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.toObject, includeInstance),
    childrenScopeList: jspb.Message.toObjectList(msg.getChildrenScopeList(),
    Wilqo_Shared_Models_DynamicData_pb.ScopeValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIteratorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueIdentifierVirtualFieldId(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_DynamicData_pb.ScopeValue;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.ScopeValue.deserializeBinaryFromReader);
      msg.addChildrenScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIteratorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUniqueIdentifierVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.serializeBinaryToWriter
    );
  }
  f = message.getChildrenScopeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      Wilqo_Shared_Models_DynamicData_pb.ScopeValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string iterator_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string unique_identifier_virtual_field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getUniqueIdentifierVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setUniqueIdentifierVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated SectionFieldResult items = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated wilqo.shared.models.ScopeValue children_scope = 8;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.getChildrenScopeList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.ScopeValue, 8));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.setChildrenScopeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.addChildrenScope = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandRequest.prototype.clearChildrenScopeList = function() {
  return this.setChildrenScopeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    retry: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRetry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRetry();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool retry = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.getRetry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.setRetry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeCollectionMemberCommandResponse.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fieldValue: (f = msg.getFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    withoutData: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setFieldValue(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithoutData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFieldValue();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getWithoutData();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement field_value = 7;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 7));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.clearFieldValue = function() {
  return this.setFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.hasFieldValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool without_data = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.getWithoutData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandRequest.prototype.setWithoutData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    defaultFieldValue: (f = msg.getDefaultFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField;
  return proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setDefaultFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getDefaultFieldValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 3;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement default_field_value = 4;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.getDefaultFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.setDefaultFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.clearDefaultFieldValue = function() {
  return this.setDefaultFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField.prototype.hasDefaultFieldValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FilteredField fields = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField>}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.FilteredField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleFieldFilterCommandResponse.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.InitializeManagedSettingsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopedItemsList: jspb.Message.toObjectList(msg.getScopedItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.deserializeBinaryFromReader);
      msg.addScopedItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopedItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.toObject, includeInstance),
    isnew: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult;
  return proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnew(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string scope_token = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SectionFieldResult items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.SectionFieldResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool isNew = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.getIsnew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.setIsnew = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.clearIsnew = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult.prototype.hasIsnew = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ScopedSectionFieldResult scoped_items = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult>}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.getScopedItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.setScopedItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult}
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.addScopedItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.ScopedSectionFieldResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SaveScopedVirtualFieldValuesCommandRequest.prototype.clearScopedItemsList = function() {
  return this.setScopedItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 3;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated FullPageSaveItem items = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.FullPageSaveItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 deal_version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.AddWilqoPartyMemberCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 3;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated SaveFieldItem items = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.SaveFieldItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 deal_version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.UpdateWilqoPartyMemberCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sideEffectTypesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest;
  return proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSideEffectTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSideEffectTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated CircuitSideEffectRequestType side_effect_types = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType>}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.getSideEffectTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.setSideEffectTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.addSideEffectTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandRequest.prototype.clearSideEffectTypesList = function() {
  return this.setSideEffectTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse;
  return proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 deal_version = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.HandleCircuitSideEffectsCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ConsentFieldType = {
  CONSENT_FIELD_TYPE_UNKNOWN: 0,
  CONSENT_FIELD_TYPE_CONSENT: 1,
  CONSENT_FIELD_TYPE_IP: 2,
  CONSENT_FIELD_TYPE_TIMESTAMP: 3,
  CONSENT_FIELD_TYPE_METHOD: 4
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
