import { useEffect } from 'react';

import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicValueConverters } from '@/Utils/Helpers/dynamicDataConverters';

import { useDynamicFormRegister } from '../dynamicForm/useDynamicFormRegister';

export const HiddenElement = (props: PanelElement.AsObject) => {
  const { id } = props;

  const { converters, fromDynamicObject } = useDynamicValueConverters(props);

  const [value, setValue] = useDynamicFormRegister(id, converters);

  useEffect(() => {
    if (props.value && !value) {
      setValue(fromDynamicObject(props.value), '');
    }
  }, [fromDynamicObject, props.type, props.value, setValue, value]);

  return null;
};
