import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateLoanFeesCommandRequest, UpdateLoanFeesCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { LoanEscrowFeeCategory, LoanFeeCategory, LoanPrepaidFeeCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toLoanEscrowFeeCategoriesFromObjectArray, toLoanFeeCategoriesFromObjectArray, toLoanPrepaidFeeCategoriesFromObjectArray } from '../helpers/feesHelper';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface UseUpsertFeesDTO {
  dealId: string;
  loanId: string;
  feeCategories: LoanFeeCategory.AsObject[];
  escrowCategory: LoanEscrowFeeCategory.AsObject[];
  prepaidCategory: LoanPrepaidFeeCategory.AsObject[];
}

const MESSAGE_NAME = 'wilqo.api.mortgage.UpdateLoanFeesCommandRequest';

const useUpdateFees = () => {
  const { commandResponse } = useBackend();

  const updateFees = async (updateObject: UseUpsertFeesDTO) => {
    const request = new UpdateLoanFeesCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setLoanId(updateObject.loanId);

    request.setFeeCategoriesList(toLoanFeeCategoriesFromObjectArray(updateObject.feeCategories));
    request.setEscrowCategoriesList(toLoanEscrowFeeCategoriesFromObjectArray(updateObject.escrowCategory));
    request.setPrepaidCategoriesList(toLoanPrepaidFeeCategoriesFromObjectArray(updateObject.prepaidCategory));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateLoanFeesCommandResponse();
    UpdateLoanFeesCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(updateFees);
};

export { useUpdateFees };
