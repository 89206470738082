import { FeeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { fromString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

// FEE_ENUM_UNKNOWN = 0
// FEE_ENUM_LOAN_DISCOUNT_POINTS = 68
export const getFeeEnumPanelElementOptionList = () => {
  const items = Object.values(FeeEnum).filter((value) => value !== 0 && value !== 68);
  return items.map((value) => getPanelElementOption({
    headerText: ConvertEnum(FeeEnum, Number(value)),
    id: String(value),
    value: fromString(String(value)).toObject(),
  }));
};

export const defaultFeePaymentTimingTypeOptionList = [
  getPanelElementOption({ headerText: 'At Closing', id: '1' }),
  getPanelElementOption({ headerText: 'Prior to Closing', id: '2' }),
  getPanelElementOption({ headerText: 'Financed', id: '3' }),
];
