import React from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { AutoComplete } from '@/Components/Atoms/AutoComplete';
import { Card } from '@/Components/Atoms/Card/Card';

import type { CredentialFormData } from './CredentialManager';

interface CredentialFormProps {
  cardOptions: CardOption[];
  handleChange: (field: keyof CredentialFormData) => (value: string) => void;
}

export interface CardOption {
  title: string;
  subtitle: string;
  field: string;
  options: Array<PanelElementOption.AsObject>;
}

const CredentialForm: React.FC<CredentialFormProps> = ({ cardOptions, handleChange }) => {
  const renderCard = (cardOption: CardOption) => {
    if (cardOption.options.length === 0) return null;

    return (
      <div className="mb-4">
        <Card
          defaultOpen
          disableAccordion={false}
          headerProps={{
            open: true,
            subtitle: cardOption.subtitle,
            title: cardOption.title,
          }}
          variant="border"
        >
          <div className="p-4">
            <AutoComplete
              displayOnly
              onSelect={handleChange(cardOption.field as keyof CredentialFormData)}
              options={cardOption.options}
              placeholder={`Select ${cardOption.title.toLowerCase()}`}
            />
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      {cardOptions.map((cardOption) => (
        <React.Fragment key={cardOption.title}>
          {renderCard(cardOption)}
        </React.Fragment>
      ))}
    </>
  );
};

export { CredentialForm };
