import { useMutation } from '@tanstack/react-query';

import { DealWriteCommandResponse, RequiredField, RequiredFieldGroup, SaveRequiredFieldsCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useWilqoMessage } from '../useWilqoMessage';

interface SaveRequiredFieldsDTO {
  entityId: string;
  version: number;
  groupsList: RequiredFieldGroup.AsObject[];
}

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.SaveRequiredFieldsCommandRequest';

export const useSaveRequiredFields = () => {
  const sendMessage = useWilqoMessage();

  async function saveRequiredFields(dto: SaveRequiredFieldsDTO) {
    const request = new SaveRequiredFieldsCommandRequest();
    request.setEntityId(dto.entityId);
    request.setVersion(dto.version);

    const groupsList = dto.groupsList.map((group) => {
      const groupInstance = new RequiredFieldGroup();
      const fieldsList = group.fieldsList.map((field) => {
        const fieldInstance = new RequiredField();
        fieldInstance.setValue(field.value ? DynamicDataElementMap(field.value) : undefined);
        fieldInstance.setVirtualFieldId(field.virtualFieldId);
        return fieldInstance;
      });
      groupInstance.setFieldsList(fieldsList);
      groupInstance.setScopeToken(group.scopeToken);
      return groupInstance;
    });

    request.setGroupsList(groupsList);

    return sendMessage<DealWriteCommandResponse.AsObject>({
      msg: request,
      msgName: MESSAGE_NAME,
    }, DealWriteCommandResponse);
  }

  return useMutation(saveRequiredFields);
};
