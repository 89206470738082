import { BinaryReader } from 'google-protobuf';
import type { Any } from 'google-protobuf/google/protobuf/any_pb';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import type { AsyncValidation } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { AsyncValidationPanelElementWrapper } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { GetAsyncValidationResultQueryRequest, GetAsyncValidationResultQueryResponse } from '@/API/Models/Wilqo_API_Activity_Queries_pb';
import { GetLoansBySocialAndAddressQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import type { AsyncError, DynamicDataElementValues, FormError } from '@/Components/Features/dynamicForm/DynamicFormContext';

import { useBackend } from '../useBackend';

const MESSAGE_NAME = 'wilqo.api.activity.GetAsyncValidationResultQueryRequest';

interface RequestData {
  validationType: number;
  values: Array<{ id: string; value: DynamicDataElement }>;
}

const useAsyncValidation = (
  values: DynamicDataElementValues,
  errors: Record<string, FormError>,
  setAsyncError: (error: AsyncError) => void,
  clearAsyncError: (type: number) => void,
  validations?: AsyncValidation.AsObject[],
) => {
  const { commandResponse } = useBackend();

  const desearilizeResponse = useCallback(async (response: Any): Promise<any> => {
    const typeUrl = response.getTypeUrl();
    return response.unpack((packed) => GetLoansBySocialAndAddressQueryResponse.deserializeBinary(packed), typeUrl);
  }, []);

  const asyncValidate = useCallback(async (requestData: RequestData) => {
    const { validationType, values } = requestData;
    const request = new GetAsyncValidationResultQueryRequest();
    const responseList = values.map((item) => {
      const responseItem = new AsyncValidationPanelElementWrapper();
      responseItem.setPanelElementId(item.id);
      responseItem.setValue(item.value);
      return responseItem;
    });
    request.setResponsesList(responseList);
    request.setType(validationType);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAsyncValidationResultQueryResponse();
    GetAsyncValidationResultQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const result = response.getResult();
    const isValid = response.getIsSuccess();
    if (!isValid) {
      if (result) {
        const data = desearilizeResponse(result);
        setAsyncError({ data, type: validationType });
      } else {
        setAsyncError({ type: validationType });
      }
    } else {
      clearAsyncError(validationType);
    }
  }, [commandResponse, desearilizeResponse, setAsyncError, clearAsyncError]);

  const debouncedAsyncValidate = useRef(debounce(asyncValidate, 500));

  useEffect(() => {
    validations?.forEach(
      (validation: AsyncValidation.AsObject) => {
        const validationValues = Object.keys(values)
          .filter((panelElementId) => validation.itemsList.some((item) => item.panelElementId === panelElementId))
          .map((id) => ({ id, value: values[id].dynamic }));
        const requiredValidationItems = validation.itemsList.filter((item) => item.required);
        const isAllItemsValid = requiredValidationItems.every((item) => (errors[item.panelElementId] ? !errors[item.panelElementId].errorMessage : false));
        const isAllItemsPresent = requiredValidationItems.length === validationValues.length;
        if (isAllItemsValid && isAllItemsPresent) {
          debouncedAsyncValidate.current({ validationType: validation.type, values: validationValues });
        }
      },
    );
  }, [values, validations, errors]);
};

export { useAsyncValidation };
