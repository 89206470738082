// source: Wilqo.Shared.Models/DynamicData.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.models.DataTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DynamicDataElement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DynamicDataElementDataTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DynamicDataScopedWriteItem', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DynamicDataWriteItem', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DynamicListTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ScopeValue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DynamicDataElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DynamicDataElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DynamicDataElement.displayName = 'proto.wilqo.shared.models.DynamicDataElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ScopeValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.ScopeValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ScopeValue.displayName = 'proto.wilqo.shared.models.ScopeValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DynamicDataWriteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DynamicDataWriteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DynamicDataWriteItem.displayName = 'proto.wilqo.shared.models.DynamicDataWriteItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DynamicDataScopedWriteItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DynamicDataScopedWriteItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DynamicDataScopedWriteItem.displayName = 'proto.wilqo.shared.models.DynamicDataScopedWriteItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DynamicDataElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DynamicDataElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DynamicDataElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DynamicDataElement;
  return proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DynamicDataElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DynamicDataElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional DynamicDataElementDataTypeEnum data_type = 1;
 * @return {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataElementDataTypeEnum} value
 * @return {!proto.wilqo.shared.models.DynamicDataElement} returns this
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bytes value = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes value = 2;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.shared.models.DynamicDataElement} returns this
 */
proto.wilqo.shared.models.DynamicDataElement.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ScopeValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ScopeValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ScopeValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ScopeValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    logicalScopeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.wilqo.shared.models.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.ScopeValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ScopeValue;
  return proto.wilqo.shared.models.ScopeValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ScopeValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.ScopeValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalScopeId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.DynamicDataElement;
      reader.readMessage(value,proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ScopeValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ScopeValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ScopeValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ScopeValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogicalScopeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string logical_scope_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.ScopeValue.prototype.getLogicalScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ScopeValue} returns this
 */
proto.wilqo.shared.models.ScopeValue.prototype.setLogicalScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.ScopeValue.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.ScopeValue} returns this
*/
proto.wilqo.shared.models.ScopeValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ScopeValue} returns this
 */
proto.wilqo.shared.models.ScopeValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ScopeValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DynamicDataWriteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataWriteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.wilqo.shared.models.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DynamicDataWriteItem;
  return proto.wilqo.shared.models.DynamicDataWriteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.DynamicDataElement;
      reader.readMessage(value,proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DynamicDataWriteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataWriteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem} returns this
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem} returns this
*/
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem} returns this
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DynamicDataWriteItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DynamicDataScopedWriteItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNew: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scopesList: jspb.Message.toObjectList(msg.getScopesList(),
    proto.wilqo.shared.models.ScopeValue.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.DynamicDataWriteItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DynamicDataScopedWriteItem;
  return proto.wilqo.shared.models.DynamicDataScopedWriteItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNew(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.ScopeValue;
      reader.readMessage(value,proto.wilqo.shared.models.ScopeValue.deserializeBinaryFromReader);
      msg.addScopes(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.DynamicDataWriteItem;
      reader.readMessage(value,proto.wilqo.shared.models.DynamicDataWriteItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DynamicDataScopedWriteItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNew();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.shared.models.ScopeValue.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.DynamicDataWriteItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_new = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.getIsNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} returns this
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.setIsNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated ScopeValue scopes = 2;
 * @return {!Array<!proto.wilqo.shared.models.ScopeValue>}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.getScopesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ScopeValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.ScopeValue, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ScopeValue>} value
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} returns this
*/
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.setScopesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.ScopeValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ScopeValue}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.addScopes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.ScopeValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} returns this
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * repeated DynamicDataWriteItem items = 3;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DynamicDataWriteItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataWriteItem>} value
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} returns this
*/
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataWriteItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.DynamicDataWriteItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DynamicDataScopedWriteItem} returns this
 */
proto.wilqo.shared.models.DynamicDataScopedWriteItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.DynamicDataElementDataTypeEnum = {
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_UNKNOWN: 0,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_STRING: 1,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DATE: 2,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_DURATION: 3,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NUMBER: 4,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_BOOLEAN: 5,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL: 6,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_ENUMERATION: 7,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_COMPLEX_TYPE: 8,
  DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_LIST: 16
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DataTypeEnum = {
  DATA_TYPE_ENUM_UNKNOWN: 0,
  DATA_TYPE_ENUM_STRING: 1,
  DATA_TYPE_ENUM_DATE: 2,
  DATA_TYPE_ENUM_BOOL: 3,
  DATA_TYPE_ENUM_NUMBER: 4,
  DATA_TYPE_ENUM_ENUM: 5,
  DATA_TYPE_ENUM_COMPLEX_TYPE: 6,
  DATA_TYPE_ENUM_DYNAMIC_LIST: 7,
  DATA_TYPE_ENUM_PROGRESS_ITEM: 8
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DynamicListTypeEnum = {
  DYNAMIC_LIST_TYPE_ENUM_UNKNOWN: 0,
  DYNAMIC_LIST_TYPE_ENUM_ORGANIZATION: 1
};

goog.object.extend(exports, proto.wilqo.shared.models);
