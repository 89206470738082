// source: Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_DynamicModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRulePeriodSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRulePeriodType', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRuleSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRuleSettings.DateRuleSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.CurrentDateThresholdCase', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DateRuleType', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRule', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRule.RightFieldSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRule.RuleSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRuleAggregator', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRuleGroup', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DynamicRuleResult', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.EnumRuleSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.NumberRuleSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.NumberRuleSettings.NumberRuleSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.NumberRuleSumSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.NumberRuleType', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.TableIteratorEvaluationType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DynamicRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.rulesengine.DynamicRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DynamicRule.displayName = 'proto.wilqo.shared.rulesengine.DynamicRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.DynamicRuleGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.DynamicRuleGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DynamicRuleGroup.displayName = 'proto.wilqo.shared.rulesengine.DynamicRuleGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.DynamicRuleAggregator.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.DynamicRuleAggregator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DynamicRuleAggregator.displayName = 'proto.wilqo.shared.rulesengine.DynamicRuleAggregator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.DynamicRuleResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DynamicRuleResult.displayName = 'proto.wilqo.shared.rulesengine.DynamicRuleResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DateRuleSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.shared.rulesengine.DateRuleSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.rulesengine.DateRuleSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DateRuleSettings.displayName = 'proto.wilqo.shared.rulesengine.DateRuleSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.displayName = 'proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.DateRulePeriodSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DateRulePeriodSettings.displayName = 'proto.wilqo.shared.rulesengine.DateRulePeriodSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.EnumRuleSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.EnumRuleSettings.displayName = 'proto.wilqo.shared.rulesengine.EnumRuleSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.shared.rulesengine.NumberRuleSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.rulesengine.NumberRuleSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.NumberRuleSettings.displayName = 'proto.wilqo.shared.rulesengine.NumberRuleSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.NumberRuleSumSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.NumberRuleSumSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.NumberRuleSumSettings.displayName = 'proto.wilqo.shared.rulesengine.NumberRuleSumSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.displayName = 'proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_ = [[7,5],[8,10,11]];

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DynamicRule.RightFieldSettingsCase = {
  RIGHT_FIELD_SETTINGS_NOT_SET: 0,
  RIGHT_VIRTUAL_FIELD_ID: 7,
  COMPARABLE_VALUE: 5
};

/**
 * @return {proto.wilqo.shared.rulesengine.DynamicRule.RightFieldSettingsCase}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getRightFieldSettingsCase = function() {
  return /** @type {proto.wilqo.shared.rulesengine.DynamicRule.RightFieldSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DynamicRule.RuleSettingsCase = {
  RULE_SETTINGS_NOT_SET: 0,
  DATE_RULE_SETTINGS: 8,
  ENUM_RULE_SETTINGS: 10,
  NUMBER_RULE_SETTINGS: 11
};

/**
 * @return {proto.wilqo.shared.rulesengine.DynamicRule.RuleSettingsCase}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getRuleSettingsCase = function() {
  return /** @type {proto.wilqo.shared.rulesengine.DynamicRule.RuleSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DynamicRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DynamicRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operator: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dataType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    rightVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    comparableValue: (f = msg.getComparableValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    tableIteratorId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tableIteratorEvaluationType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dateRuleSettings: (f = msg.getDateRuleSettings()) && proto.wilqo.shared.rulesengine.DateRuleSettings.toObject(includeInstance, f),
    enumRuleSettings: (f = msg.getEnumRuleSettings()) && proto.wilqo.shared.rulesengine.EnumRuleSettings.toObject(includeInstance, f),
    numberRuleSettings: (f = msg.getNumberRuleSettings()) && proto.wilqo.shared.rulesengine.NumberRuleSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule}
 */
proto.wilqo.shared.rulesengine.DynamicRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DynamicRule;
  return proto.wilqo.shared.rulesengine.DynamicRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule}
 */
proto.wilqo.shared.rulesengine.DynamicRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (reader.readEnum());
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightVirtualFieldId(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setComparableValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableIteratorId(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (reader.readEnum());
      msg.setTableIteratorEvaluationType(value);
      break;
    case 8:
      var value = new proto.wilqo.shared.rulesengine.DateRuleSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DateRuleSettings.deserializeBinaryFromReader);
      msg.setDateRuleSettings(value);
      break;
    case 10:
      var value = new proto.wilqo.shared.rulesengine.EnumRuleSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.EnumRuleSettings.deserializeBinaryFromReader);
      msg.setEnumRuleSettings(value);
      break;
    case 11:
      var value = new proto.wilqo.shared.rulesengine.NumberRuleSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.NumberRuleSettings.deserializeBinaryFromReader);
      msg.setNumberRuleSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DynamicRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperator();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getComparableValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getTableIteratorId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTableIteratorEvaluationType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDateRuleSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.shared.rulesengine.DateRuleSettings.serializeBinaryToWriter
    );
  }
  f = message.getEnumRuleSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.wilqo.shared.rulesengine.EnumRuleSettings.serializeBinaryToWriter
    );
  }
  f = message.getNumberRuleSettings();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.wilqo.shared.rulesengine.NumberRuleSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string virtual_field_id = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OperatorEnum operator = 3;
 * @return {!proto.wilqo.shared.rulesengine.OperatorEnum}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getOperator = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorEnum} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setOperator = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 4;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string right_virtual_field_id = 7;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getRightVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setRightVirtualFieldId = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.clearRightVirtualFieldId = function() {
  return jspb.Message.setOneofField(this, 7, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.hasRightVirtualFieldId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement comparable_value = 5;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getComparableValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setComparableValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.clearComparableValue = function() {
  return this.setComparableValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.hasComparableValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string table_iterator_id = 6;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getTableIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setTableIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TableIteratorEvaluationType table_iterator_evaluation_type = 9;
 * @return {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getTableIteratorEvaluationType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setTableIteratorEvaluationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional DateRuleSettings date_rule_settings = 8;
 * @return {?proto.wilqo.shared.rulesengine.DateRuleSettings}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getDateRuleSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DateRuleSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.DateRuleSettings, 8));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DateRuleSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setDateRuleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.clearDateRuleSettings = function() {
  return this.setDateRuleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.hasDateRuleSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EnumRuleSettings enum_rule_settings = 10;
 * @return {?proto.wilqo.shared.rulesengine.EnumRuleSettings}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getEnumRuleSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.EnumRuleSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.EnumRuleSettings, 10));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.EnumRuleSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setEnumRuleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.clearEnumRuleSettings = function() {
  return this.setEnumRuleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.hasEnumRuleSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional NumberRuleSettings number_rule_settings = 11;
 * @return {?proto.wilqo.shared.rulesengine.NumberRuleSettings}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.getNumberRuleSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.NumberRuleSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.NumberRuleSettings, 11));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.NumberRuleSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRule.prototype.setNumberRuleSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.wilqo.shared.rulesengine.DynamicRule.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.clearNumberRuleSettings = function() {
  return this.setNumberRuleSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRule.prototype.hasNumberRuleSettings = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DynamicRuleGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.wilqo.shared.rulesengine.DynamicRule.toObject, includeInstance),
    applyAndBetweenRules: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    tableIteratorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tableIteratorEvaluationType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DynamicRuleGroup;
  return proto.wilqo.shared.rulesengine.DynamicRuleGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.DynamicRule;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DynamicRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenRules(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableIteratorId(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (reader.readEnum());
      msg.setTableIteratorEvaluationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DynamicRuleGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.DynamicRule.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenRules();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTableIteratorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTableIteratorEvaluationType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DynamicRule rules = 2;
 * @return {!Array<!proto.wilqo.shared.rulesengine.DynamicRule>}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.DynamicRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.DynamicRule, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.DynamicRule>} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DynamicRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.DynamicRule}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.rulesengine.DynamicRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional bool apply_and_between_rules = 3;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.getApplyAndBetweenRules = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.setApplyAndBetweenRules = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string table_iterator_id = 4;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.getTableIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.setTableIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TableIteratorEvaluationType table_iterator_evaluation_type = 5;
 * @return {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType}
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.getTableIteratorEvaluationType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleGroup.prototype.setTableIteratorEvaluationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DynamicRuleAggregator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.shared.rulesengine.DynamicRuleGroup.toObject, includeInstance),
    applyAndBetweenGroups: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    tableIteratorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tableIteratorEvaluationType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DynamicRuleAggregator;
  return proto.wilqo.shared.rulesengine.DynamicRuleAggregator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.DynamicRuleGroup;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DynamicRuleGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenGroups(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableIteratorId(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (reader.readEnum());
      msg.setTableIteratorEvaluationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DynamicRuleAggregator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.DynamicRuleGroup.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenGroups();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getTableIteratorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTableIteratorEvaluationType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DynamicRuleGroup groups = 2;
 * @return {!Array<!proto.wilqo.shared.rulesengine.DynamicRuleGroup>}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.DynamicRuleGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.DynamicRuleGroup, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.DynamicRuleGroup>} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleGroup}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.rulesengine.DynamicRuleGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional bool apply_and_between_groups = 3;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.getApplyAndBetweenGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.setApplyAndBetweenGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string table_iterator_id = 4;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.getTableIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.setTableIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional TableIteratorEvaluationType table_iterator_evaluation_type = 5;
 * @return {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType}
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.getTableIteratorEvaluationType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.TableIteratorEvaluationType} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleAggregator} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleAggregator.prototype.setTableIteratorEvaluationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DynamicRuleResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    firstRuleToPass: (f = msg.getFirstRuleToPass()) && proto.wilqo.shared.rulesengine.DynamicRule.toObject(includeInstance, f),
    firstGroupToPass: (f = msg.getFirstGroupToPass()) && proto.wilqo.shared.rulesengine.DynamicRuleGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DynamicRuleResult;
  return proto.wilqo.shared.rulesengine.DynamicRuleResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.DynamicRule;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DynamicRule.deserializeBinaryFromReader);
      msg.setFirstRuleToPass(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.DynamicRuleGroup;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DynamicRuleGroup.deserializeBinaryFromReader);
      msg.setFirstGroupToPass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DynamicRuleResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DynamicRuleResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFirstRuleToPass();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.DynamicRule.serializeBinaryToWriter
    );
  }
  f = message.getFirstGroupToPass();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.DynamicRuleGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool result = 1;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional DynamicRule first_rule_to_pass = 2;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRule}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.getFirstRuleToPass = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRule} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.DynamicRule, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRule|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.setFirstRuleToPass = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.clearFirstRuleToPass = function() {
  return this.setFirstRuleToPass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.hasFirstRuleToPass = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DynamicRuleGroup first_group_to_pass = 3;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRuleGroup}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.getFirstGroupToPass = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRuleGroup} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.DynamicRuleGroup, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRuleGroup|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult} returns this
*/
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.setFirstGroupToPass = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DynamicRuleResult} returns this
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.clearFirstGroupToPass = function() {
  return this.setFirstGroupToPass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DynamicRuleResult.prototype.hasFirstGroupToPass = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.DateRuleSettingsCase = {
  DATE_RULE_SETTINGS_NOT_SET: 0,
  PERIOD_SETTINGS: 2,
  SINGLE_FIELD_SETTINGS: 3
};

/**
 * @return {proto.wilqo.shared.rulesengine.DateRuleSettings.DateRuleSettingsCase}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.getDateRuleSettingsCase = function() {
  return /** @type {proto.wilqo.shared.rulesengine.DateRuleSettings.DateRuleSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.rulesengine.DateRuleSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DateRuleSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    periodSettings: (f = msg.getPeriodSettings()) && proto.wilqo.shared.rulesengine.DateRulePeriodSettings.toObject(includeInstance, f),
    singleFieldSettings: (f = msg.getSingleFieldSettings()) && proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DateRuleSettings;
  return proto.wilqo.shared.rulesengine.DateRuleSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.DateRuleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.DateRulePeriodSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DateRulePeriodSettings.deserializeBinaryFromReader);
      msg.setPeriodSettings(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.deserializeBinaryFromReader);
      msg.setSingleFieldSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DateRuleSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPeriodSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.DateRulePeriodSettings.serializeBinaryToWriter
    );
  }
  f = message.getSingleFieldSettings();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional DateRuleType type = 1;
 * @return {!proto.wilqo.shared.rulesengine.DateRuleType}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.DateRuleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DateRuleType} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DateRulePeriodSettings period_settings = 2;
 * @return {?proto.wilqo.shared.rulesengine.DateRulePeriodSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.getPeriodSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DateRulePeriodSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.DateRulePeriodSettings, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DateRulePeriodSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings} returns this
*/
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.setPeriodSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.shared.rulesengine.DateRuleSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.clearPeriodSettings = function() {
  return this.setPeriodSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.hasPeriodSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DateRuleSingleFieldSettings single_field_settings = 3;
 * @return {?proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.getSingleFieldSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings} returns this
*/
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.setSingleFieldSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.shared.rulesengine.DateRuleSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.clearSingleFieldSettings = function() {
  return this.setSingleFieldSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSettings.prototype.hasSingleFieldSettings = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.CurrentDateThresholdCase = {
  CURRENT_DATE_THRESHOLD_NOT_SET: 0,
  CURRENT_DATE_YEARS_THRESHOLD: 3,
  CURRENT_DATE_MONTHS_THRESHOLD: 4,
  CURRENT_DATE_DAYS_THRESHOLD: 5
};

/**
 * @return {proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.CurrentDateThresholdCase}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getCurrentDateThresholdCase = function() {
  return /** @type {proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.CurrentDateThresholdCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    compareToCurrentDateIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeTimeIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    currentDateYearsThreshold: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentDateMonthsThreshold: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currentDateDaysThreshold: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings;
  return proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompareToCurrentDateIndicator(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeTimeIndicator(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentDateYearsThreshold(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentDateMonthsThreshold(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentDateDaysThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompareToCurrentDateIndicator();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeTimeIndicator();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional bool compare_to_current_date_indicator = 1;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getCompareToCurrentDateIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.setCompareToCurrentDateIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_time_indicator = 2;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getIncludeTimeIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.setIncludeTimeIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 current_date_years_threshold = 3;
 * @return {number}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getCurrentDateYearsThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.setCurrentDateYearsThreshold = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.clearCurrentDateYearsThreshold = function() {
  return jspb.Message.setOneofField(this, 3, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.hasCurrentDateYearsThreshold = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 current_date_months_threshold = 4;
 * @return {number}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getCurrentDateMonthsThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.setCurrentDateMonthsThreshold = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.clearCurrentDateMonthsThreshold = function() {
  return jspb.Message.setOneofField(this, 4, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.hasCurrentDateMonthsThreshold = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 current_date_days_threshold = 5;
 * @return {number}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.getCurrentDateDaysThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.setCurrentDateDaysThreshold = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.clearCurrentDateDaysThreshold = function() {
  return jspb.Message.setOneofField(this, 5, proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.DateRuleSingleFieldSettings.prototype.hasCurrentDateDaysThreshold = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DateRulePeriodSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalToCompare: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startDefaultAction: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endDefaultAction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DateRulePeriodSettings;
  return proto.wilqo.shared.rulesengine.DateRulePeriodSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalToCompare(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} */ (reader.readEnum());
      msg.setStartDefaultAction(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} */ (reader.readEnum());
      msg.setEndDefaultAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DateRulePeriodSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTotalToCompare();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStartDefaultAction();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEndDefaultAction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType = {
  DEFAULT_VALUE_ACTION_TYPE_UNKNOWN: 0,
  DEFAULT_VALUE_ACTION_TYPE_IGNORE: 1,
  DEFAULT_VALUE_ACTION_TYPE_USE_CURRENT: 2
};

/**
 * optional DateRulePeriodType type = 1;
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodType}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodType} value
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 total_to_compare = 2;
 * @return {number}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.getTotalToCompare = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.setTotalToCompare = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DefaultValueActionType start_default_action = 3;
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.getStartDefaultAction = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} value
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.setStartDefaultAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DefaultValueActionType end_default_action = 4;
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.getEndDefaultAction = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings.DefaultValueActionType} value
 * @return {!proto.wilqo.shared.rulesengine.DateRulePeriodSettings} returns this
 */
proto.wilqo.shared.rulesengine.DateRulePeriodSettings.prototype.setEndDefaultAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.EnumRuleSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.EnumRuleSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowDefaultOption: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.EnumRuleSettings}
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.EnumRuleSettings;
  return proto.wilqo.shared.rulesengine.EnumRuleSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.EnumRuleSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.EnumRuleSettings}
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowDefaultOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.EnumRuleSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.EnumRuleSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowDefaultOption();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool allow_default_option = 1;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.prototype.getAllowDefaultOption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.EnumRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.EnumRuleSettings.prototype.setAllowDefaultOption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.oneofGroups_ = [[2]];

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.NumberRuleSettingsCase = {
  NUMBER_RULE_SETTINGS_NOT_SET: 0,
  SUM_SETTINGS: 2
};

/**
 * @return {proto.wilqo.shared.rulesengine.NumberRuleSettings.NumberRuleSettingsCase}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.getNumberRuleSettingsCase = function() {
  return /** @type {proto.wilqo.shared.rulesengine.NumberRuleSettings.NumberRuleSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.rulesengine.NumberRuleSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.NumberRuleSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sumSettings: (f = msg.getSumSettings()) && proto.wilqo.shared.rulesengine.NumberRuleSumSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.NumberRuleSettings;
  return proto.wilqo.shared.rulesengine.NumberRuleSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.NumberRuleType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.NumberRuleSumSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.NumberRuleSumSettings.deserializeBinaryFromReader);
      msg.setSumSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.NumberRuleSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSumSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.NumberRuleSumSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional NumberRuleType type = 1;
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleType}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.NumberRuleType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleType} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional NumberRuleSumSettings sum_settings = 2;
 * @return {?proto.wilqo.shared.rulesengine.NumberRuleSumSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.getSumSettings = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.NumberRuleSumSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.NumberRuleSumSettings, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.NumberRuleSumSettings|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSettings} returns this
*/
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.setSumSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.shared.rulesengine.NumberRuleSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.clearSumSettings = function() {
  return this.setSumSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.NumberRuleSettings.prototype.hasSumSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.NumberRuleSumSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalToCompare: (f = msg.getTotalToCompare()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    conversionFactorValue: (f = msg.getConversionFactorValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.NumberRuleSumSettings;
  return proto.wilqo.shared.rulesengine.NumberRuleSumSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setTotalToCompare(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setConversionFactorValue(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.NumberRuleSumSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalToCompare();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getConversionFactorValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.DynamicDataElement total_to_compare = 1;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.getTotalToCompare = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
*/
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.setTotalToCompare = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.clearTotalToCompare = function() {
  return this.setTotalToCompare(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.hasTotalToCompare = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement conversion_factor_value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.getConversionFactorValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
*/
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.setConversionFactorValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.clearConversionFactorValue = function() {
  return this.setConversionFactorValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.hasConversionFactorValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NumberRuleSumFieldSettings additional_fields = 3;
 * @return {!Array<!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings>}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings>} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
*/
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSumSettings.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conversionFactorValue: (f = msg.getConversionFactorValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings;
  return proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setConversionFactorValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConversionFactorValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement conversion_factor_value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.getConversionFactorValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} returns this
*/
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.setConversionFactorValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings} returns this
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.clearConversionFactorValue = function() {
  return this.setConversionFactorValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.NumberRuleSumFieldSettings.prototype.hasConversionFactorValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.TableIteratorEvaluationType = {
  TABLE_ITERATOR_EVALUATION_TYPE_UNKNOWN: 0,
  TABLE_ITERATOR_EVALUATION_TYPE_ALL: 1,
  TABLE_ITERATOR_EVALUATION_TYPE_ANY: 2,
  TABLE_ITERATOR_EVALUATION_TYPE_NONE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DateRuleType = {
  DATE_RULE_TYPE_UNKNOWN: 0,
  DATE_RULE_TYPE_PERIOD: 1,
  DATE_RULE_TYPE_TOTAL_PERIOD: 2,
  DATE_RULE_TYPE_SINGLE_FIELD: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.DateRulePeriodType = {
  DATE_RULE_PERIOD_TYPE_UNKNOWN: 0,
  DATE_RULE_PERIOD_TYPE_DAYS: 1,
  DATE_RULE_PERIOD_TYPE_MONTHS: 2,
  DATE_RULE_PERIOD_TYPE_YEARS: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.rulesengine.NumberRuleType = {
  NUMBER_RULE_TYPE_UNKNOWN: 0,
  NUMBER_RULE_TYPE_SUM: 1
};

goog.object.extend(exports, proto.wilqo.shared.rulesengine);
