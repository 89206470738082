/* eslint-disable max-len */

interface IGoogleResponseProps {
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: Array<string>;
  }>;
  types: Array<string>;
  formatted_address: string;
}

export interface IGeoCodeData {
  country: string;
  city: string;
  county: string;
  state: string;
  state_abbr: string;
  street: string;
  zip: string;
  additionalLineText: string;
  plusFourZip?: string;
}

export type GeocodeError = 'error' | 'zero_results';
interface ConvertToGeocodeResponse {
  geocode?: IGeoCodeData;
  error?: GeocodeError;
}

const convertToGeoCode = (address: string, cb: (response: ConvertToGeocodeResponse) => void) => {
  let autocompleteGeo = <any>null;

  if (!autocompleteGeo && window.google) {
    autocompleteGeo = new window.google.maps.Geocoder();
  }

  autocompleteGeo.geocode({ address }, (responseData: Array<IGoogleResponseProps>, status: string) => {
    switch (status) {
      case google.maps.GeocoderStatus.OK: {
        const streetNumber = responseData[0].address_components.filter((response) => response.types.includes('street_number'))[0];
        const streetName = responseData[0].address_components.filter((response) => response.types.includes('route'))[0];
        const city = responseData[0].address_components.filter((response) => response.types.includes('locality'))[0];
        const postalCode = responseData[0].address_components.filter((response) => response.types.includes('postal_code'))[0];
        const country = responseData[0].address_components.filter((response) => response.types.includes('country'))[0];
        const county = responseData[0].address_components.filter((response) => response.types.includes('administrative_area_level_2'))[0];
        const state = responseData[0].address_components.filter((response) => response.types.includes('administrative_area_level_1'))[0];
        const additionalLineText = responseData[0].address_components.filter((response) => response.types.includes('subpremise'))[0];

        const geoCodedAddress: IGeoCodeData = {
          additionalLineText: additionalLineText?.long_name || '',
          city: city?.long_name || '',
          country: country?.long_name || '',
          county: county?.long_name || '',
           state: state?.long_name || '',
          state_abbr: state?.short_name || '',
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          street: `${streetNumber ? `${streetNumber?.long_name} ` : ''}${streetName ? `${streetName?.long_name}` : ''}`,
          zip: postalCode?.long_name || '',
        };
        cb({
          geocode: geoCodedAddress,
        });
        break;
      }
      case google.maps.GeocoderStatus.ZERO_RESULTS: {
        cb({ error: 'zero_results' });
        break;
      }
      default: {
        cb({ error: 'error' });
        throw new Error(`Google Geocode error: ${status}`);
      }
    }
  });
};

export { convertToGeoCode };
