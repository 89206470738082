import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';

const $by = <T>(v: T) => String(v);

export const useLookup = <T>(data: T[] | undefined, by: (v: T) => number | string = $by) => {
  const table = useMemo(() => keyBy(data || [], by), [data, by]);

  const exists = useCallback((v: T | number | string) => {
    const key = typeof v === 'string' || typeof v === 'number' ? v : by(v);
    return Object.prototype.hasOwnProperty.call(table, key);
  }, [table, by]);

  return { exists, table };
};
