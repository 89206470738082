import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetPageConfigurationQueryRequest, GetPageConfigurationQueryResponse } from '@/API/Models/Wilqo_API_Brand_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const GET_PAGE_CONFIG_MESSAGE_NAME = 'wilqo.api.brand.GetPageConfigurationQueryRequest';

const usePageConfiguration = (enabled = true, questionnaire = false, dealId?: string) => {
  const { commandResponse } = useBackend(questionnaire);
  const bpdId = useBPDId();

  return useQuery([GET_PAGE_CONFIG_MESSAGE_NAME, dealId], async () => {
    const request = new GetPageConfigurationQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    if (dealId) {
      request.setDealId(dealId);
    }
    const responseMsg = await commandResponse({
      msg: request,
      msgName: GET_PAGE_CONFIG_MESSAGE_NAME,
    });
    const response = new GetPageConfigurationQueryResponse();
    GetPageConfigurationQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(GET_PAGE_CONFIG_MESSAGE_NAME, responseObj.error, responseObj.pageConfig);
  }, { enabled });
};
export { usePageConfiguration };
