import Markdown from 'react-markdown';

import type { DynamicTitle, LoanPageHeader } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Header_pb';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Typography } from '@/Components/Atoms/Typography';
import { convertUxEnum } from '@/Utils/Helpers/getUxStatus';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { DynamicActions } from '../actions/DynamicActions';

interface HeaderProps {
  header: LoanPageHeader.AsObject;
  dialogPageId?: string;
  onCloseDialogAction?: VoidFunction;
}

const renderTitle = (title: string, dynamicTitle?: DynamicTitle.AsObject) => {
  if (dynamicTitle) {
    return `${title} ${dynamicTitle.staticTitleAndItemsDelimiter} ${dynamicTitle.itemsList.map((item) => renderValue(item, undefined, undefined, '')).join(dynamicTitle.itemsDelimiter)}`;
  }

  return title;
};

export const Header = (props: HeaderProps) => {
  const { header, onCloseDialogAction } = props;

  const { isMobile } = useWindow();

  return (
    <div className="flex flex-col gap-y-4 tablet:gap-y-6">
      <div className="flex flex-wrap tablet:flex-row flex-col gap-4 tablet:items-center tablet:justify-between">
        <div className="flex-1 grid gap-6">
          {(header.title || header.dynamicTitle) && (
            <>
              <Typography className="text-on-surface-solid text-wrap" variant={isMobile ? 'display5' : 'display4'}>
                {renderTitle(header.title, header.dynamicTitle)}
              </Typography>
              {header.helpText && (
                <Typography variant="body1">
                  <Markdown className="text-on-surface-inactive">{header.helpText}</Markdown>
                </Typography>
              )}
            </>
          )}
        </div>
        <div className="w-full tablet:w-auto">
          <DynamicActions
            actions={header.actionsList}
            onCloseDialog={onCloseDialogAction}
          />
        </div>
      </div>
      {header.infoCardsList && header.infoCardsList.length > 0 && (
        <div className="flex flex-wrap tablet:gap-6 gap-2 items-stretch justify-center sm:justify-start">
          {
            header.infoCardsList?.map((card) => (
              <InfoCard
                key={card.caption}
                {...card}
                isMobile={isMobile}
                variant={convertUxEnum(card.variant)}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};
