import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { useInvitationVerification } from '@/API/Queries/user/useInvitationVerification';
import { Typography } from '@/Components/Atoms/Typography';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

const Invite = () => {
  const { id = '' } = useParams();
  const { signup } = useAuth();
  const { data, isLoading } = useInvitationVerification(id);

  useEffect(() => {
    if (data && data.isValid) {
      const uri = `${window.location.protocol}//${window.location.host}/mortgage/login`;
      signup(uri, { invitationId: id }, data.email);
    }
  }, [data, signup, id]);

  const message = useMemo(() => {
    if (isLoading) return 'Verifying your link, you will be redirected within seconds';
    if (data && !data.isValid) return data.validationMessage;
    return '';
  }, [isLoading, data]);

  return (
    <div className="h-full w-full flex justify-center items-center">
      <Typography variant="display2">{message}</Typography>
    </div>
  );
};

export { Invite };
