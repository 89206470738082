import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { RemoveSkillsetFromActivityCommandRequest, RemoveSkillsetFromActivityCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest';

interface UpdateObject {
  activityId: string;
  skillsetId: string;
}

const useRemoveSkillsetFromActivity = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const RemoveSkillsetFromActivityCommand = async (updateObject: UpdateObject) => {
    const { activityId, skillsetId } = updateObject;
    const request = new RemoveSkillsetFromActivityCommandRequest();
    request.setActivityDesignId(activityId);
    request.setSkillsetId(skillsetId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new RemoveSkillsetFromActivityCommandResponse();
    RemoveSkillsetFromActivityCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(RemoveSkillsetFromActivityCommand);
};

export { useRemoveSkillsetFromActivity };
