import type { AssociationSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { Button } from '@/Components/Atoms/Button';
import { DropdownMenu } from '@/Components/Atoms/DropdownMenu';
import { renderValue } from '@/Utils/Helpers/renderableHelper';

import { useWidgetContext } from './WidgetContext';

interface Props {
  label: string;
  settings?: AssociationSettings.AsObject;
  onSelectItem: (scopeToken: string) => void;
  newItemVisible: boolean;
  newItemScopeToken?: string;
}

export const ScopeSelector = (props: Props) => {
  const { context } = useWidgetContext();

  if (props.newItemVisible) {
    if (props.settings && props.settings.itemsList.length > 1) {
      return (
        <DropdownMenu.Root modal={context === 'dialog'}>
          <DropdownMenu.Trigger>
            <Button label={props.label || 'Add New'} rightIcon="ArrowDropDown" variant="tertiary" />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {
                props.settings.itemsList.map(({ label, scopeToken }) => (
                  <DropdownMenu.Item
                    key={scopeToken}
                    label={renderValue(label)}
                    onClick={() => props.onSelectItem(scopeToken)}
                  />
                ))
              }
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      );
    }
    return (
      <Button
        label={props.label || 'Add New'}
        onClick={() => props.onSelectItem(props.settings?.itemsList[0].scopeToken || props.newItemScopeToken || '')}
        variant="tertiary"
      />
    );
  }

  return null;
};
