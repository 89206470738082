import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { OrganizationSummary } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import type { LoanPermission, UserSummary } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useLoanCount } from '@/API/Queries/mortgage/useLoanCount';
import { useOrganizationSearch } from '@/API/Queries/user/useOrganizationSearch';
import { useUpdateUserPermissions } from '@/API/Queries/user/useUpdateUserPermissions';
import { useUserLoanPermissions } from '@/API/Queries/user/useUserLoanPermissions';
import { useUserSearch } from '@/API/Queries/user/useUsersSearch';
import { CardHeader } from '@/Components/Atoms/Card/CardHeader';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Tabs } from '@/Components/Atoms/Tab';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { ExtendedStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useDebounce } from '@/Utils/Hooks/useDebounce';
import { usePoll } from '@/Utils/Hooks/usePoll';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

enum CurrentTabEnum {
  ORGS,
  USERS,
}

export const UserLoanAccess = () => {
  // LIBRARY HOOKS
  const { id = '' } = useParams();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const { showSnackBar } = useShared();
  const { isMobile } = useWindow();

  // STATE
  const [currentTab, setCurrentTab] = useState<CurrentTabEnum>(CurrentTabEnum.ORGS);
  const [loanPermissions, setLoanPermissions] = useState<LoanPermission.AsObject>({ allowedOrgIdsList: [], allowedPartyRoleTypesList: [], allowedUserIdsList: [] });

  // DATA HOOKS
  const { data: userLoanPermissions } = useUserLoanPermissions(id);
  const { data: userLoanCount, startPolling: startLoanCountPolling } = usePoll<number | undefined>((options) => useLoanCount.bind(undefined, options, id));
  const { data: organizationData, isLoading: isLoadingOrgs } = useOrganizationSearch(debouncedSearch, 0, 10, id, currentTab === CurrentTabEnum.ORGS);
  const { data: userData, isLoading: isLoadingUsers } = useUserSearch(debouncedSearch, 0, 10, id, currentTab === CurrentTabEnum.USERS);
  const { mutate: updatePermissions } = useUpdateUserPermissions();

  // MEMOIZED VALUES
  const orgColumns = useMemo((): Array<Column<OrganizationSummary.AsObject>> => [
    {
      header: 'Organization',
      id: 'org',
      key: { text: 'displayName', type: 'text' },
    },
    {
      header: 'Status',
      id: 'status',
      key: { badge: 'status', type: 'badge' },
    },
  ], []);
  const orgsData = useMemo(() => organizationData?.organizationsList
    .reduce((acc: Array<any>, org): Array<any> => {
      const hasPermission = loanPermissions?.allowedOrgIdsList.includes(org.id);
      const newOrg = {
        ...org,
        status: loanPermissions?.allowedOrgIdsList.includes(org.id) ? {
          label: 'Granted',
          variant: UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS,
        } : undefined,
      };
      if (hasPermission) return [newOrg, ...acc];
      return [...acc, newOrg];
    }, []),
    [loanPermissions, organizationData]);
  const userTableData = useMemo(() => userData?.usersList.reduce((acc: Array<any>, user) => {
    const hasPermission = loanPermissions?.allowedUserIdsList.includes(user.id);
    const newUser = {
      ...user,
      status: hasPermission ? {
        label: 'Granted',
        variant: UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS,
      } : undefined,
    };
    if (hasPermission) return [newUser, ...acc];
    return [...acc, newUser];
  }, []), [loanPermissions?.allowedUserIdsList, userData]);
  const userColumns = useMemo((): Array<Column<UserSummary.AsObject>> => [
    {
      header: 'User name',
      id: 'name',
      key: { text: 'displayName', type: 'text' },
    },
    {
      header: 'Organizations',
      id: 'org',
      key: { text: 'organizationsList', type: 'text' },
    },
    {
      header: 'Job Functions',
      id: 'jf',
      key: { text: 'skillsetGroupsList', type: 'text' },
    },
    {
      header: 'Party Role',
      id: 'partyRole',
      key: { text: 'partyRolesList.name', type: 'text' },
    },
    {
      header: 'Status',
      id: 'status',
      key: { badge: 'status', type: 'badge' },
    },
  ], []);

  // EVENT HANDLERS
  const handleUpdateSuccess = () => {
    startLoanCountPolling('count');
    showSnackBar({ message: 'Permssions updated', open: true });
  };
  const handleUpdateError = (error: any) => showSnackBar({ message: error.message, open: true });
  const handleUpdateUsers = (grant: boolean, selectedUserId: string) => {
    let newUserIdsList = loanPermissions?.allowedUserIdsList || [];
    if (grant) newUserIdsList?.push(selectedUserId);
    else newUserIdsList = newUserIdsList?.filter((userId) => userId !== selectedUserId);
    const newPermissions: LoanPermission.AsObject = { ...loanPermissions, allowedUserIdsList: newUserIdsList };
    setLoanPermissions(newPermissions);
    updatePermissions({
      orgIds: loanPermissions?.allowedOrgIdsList || [],
      userId: id,
      userIds: newUserIdsList,
    }, {
      onError: handleUpdateError,
      onSuccess: handleUpdateSuccess,
    });
  };
  const handleUpdateOrgs = (grant: boolean, selectedOrgId: string) => {
    let newOrgIdsList = loanPermissions?.allowedOrgIdsList || [];
    if (grant) newOrgIdsList?.push(selectedOrgId);
    else newOrgIdsList = newOrgIdsList?.filter((userId) => userId !== selectedOrgId);
    const newPermissions: LoanPermission.AsObject = { ...loanPermissions, allowedOrgIdsList: newOrgIdsList };
    setLoanPermissions(newPermissions);
    updatePermissions({
      orgIds: newOrgIdsList,
      userId: id,
      userIds: loanPermissions?.allowedUserIdsList || [],
    }, {
      onError: handleUpdateError,
      onSuccess: handleUpdateSuccess,
    });
  };
  const handleTabClick = (tab: CurrentTabEnum) => {
    setCurrentTab(tab);
    setSearch('');
  };
  const handleActionClick = (item: any) => {
    if (currentTab === CurrentTabEnum.ORGS) {
      handleUpdateOrgs(!loanPermissions?.allowedOrgIdsList.includes(item.id) || false, item.id);
    } else {
      handleUpdateUsers(!loanPermissions?.allowedUserIdsList.includes(item.id) || false, item.id);
    }
  };

  // EFFECTS
  useEffect(() => {
    if (userLoanPermissions) {
      setLoanPermissions(userLoanPermissions);
    }
  }, [userLoanPermissions]);

  // RENDERERS
  const getHoverActionLabel = (item: any): string => {
    if ((currentTab === CurrentTabEnum.ORGS && loanPermissions?.allowedOrgIdsList.includes(item.id))
      || (currentTab === CurrentTabEnum.USERS && loanPermissions?.allowedUserIdsList.includes(item.id))) {
      return 'Revoke';
    }
    return 'Grant';
  };
  const renderOrgs = () => (
    <Table
      cardProps={{}}
      columns={orgColumns}
      data={orgsData || []}
      hoverActions={[{ label: getHoverActionLabel, onClick: handleActionClick }]}
      isLoading={isLoadingOrgs}
      width="stretch"
    />
  );
  const renderUsers = () => (
    <Table
      cardProps={{}}
      columns={userColumns}
      data={userTableData || []}
      hoverActions={[{ label: getHoverActionLabel, onClick: handleActionClick }]}
      isLoading={isLoadingUsers}
      width="stretch"
    />
  );

  return (
    <div>
      <Typography className="text-on-surface-inactive" variant="body1">Grant access to other users&apos; loans</Typography>
      <div className="flex flex-row my-10">
        <InfoCard
          caption="LOANS ACCESSIBLE"
          isMobile={isMobile}
          value={String(userLoanCount || 0)}
          variant={ExtendedStatusEnum.DEFAULT}
        />
        <InfoCard
          caption="ORGANIZATIONS"
          isMobile={isMobile}
          value={String(loanPermissions?.allowedOrgIdsList.length || 0)}
          variant={ExtendedStatusEnum.NEUTRAL}
        />
        <InfoCard
          caption="USERS"
          isMobile={isMobile}
          value={String(loanPermissions?.allowedUserIdsList.length || 0)}
          variant={ExtendedStatusEnum.NEUTRAL}
        />
      </div>
      <CardHeader
        close={() => { }}
        disableAccordion
        open
        searchProps={{ onChange: setSearch, value: search }}
        title="Loan Access"
      />
      <Tabs
        stretch
        tabsData={[
          { active: currentTab === CurrentTabEnum.ORGS, label: 'ORGS', onClick: () => handleTabClick(CurrentTabEnum.ORGS) },
          { active: currentTab === CurrentTabEnum.USERS, label: 'USERS', onClick: () => handleTabClick(CurrentTabEnum.USERS) },
        ]}
      />
      {currentTab === CurrentTabEnum.ORGS ? renderOrgs() : renderUsers()}

    </div>
  );
};
