import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { PanelElementResponse } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { PutQuestionnairePanelCommandRequest, PutQuestionnairePanelCommandResponse } from '@/API/Models/Wilqo_API_Questionnaire_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { DynamicDataElementMap, fromString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest';

interface UpdateObject {
  panelId: string;
  elements: Array<PanelElementResponse.AsObject>;
  questionnaireRunId: string;
  accordionId?: string;
}

const usePutQuestionnaire = () => {
  const { commandResponse } = useBackend(true);
  const { showSnackBar } = useShared();
  const bpdId = useBPDId();

  const PutQuestionnaireCommand = async (updateObject: UpdateObject) => {
    const { accordionId, elements, panelId, questionnaireRunId } = updateObject;
    const request = new PutQuestionnairePanelCommandRequest();
    request.setPanelId(panelId);
    request.setRunId(questionnaireRunId);
    request.setBusinessProcessDomainId(bpdId);

    if (accordionId !== undefined) {
      const elementContainer = new PanelElementResponse();
      elementContainer.setId('container');
      if (accordionId) {
        elementContainer.setValue(fromString(accordionId));
      }
      elementContainer.setPanelId(panelId);
      request.addPanelElements(elementContainer);
    }

    elements.map((el) => {
      const element = new PanelElementResponse();
      element.setId(el.id);
      if (el.value) {
        element.setValue(DynamicDataElementMap(el.value));
      }
      element.setPanelId(panelId);
      return element;
    }).forEach((x) => request.addPanelElements(x));
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PutQuestionnairePanelCommandResponse();
    PutQuestionnairePanelCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(PutQuestionnaireCommand, { onError: () => showSnackBar({ message: 'Something went wrong, try again later', open: true }) });
};

export { usePutQuestionnaire };
