import MasonryContainer from 'react-masonry-css';

export interface Props {
  maxNumberOfColumns: number;
  children: any;
}

export const Masonry = (props: Props) => (
  <MasonryContainer
    breakpointCols={{ 600: 1, 840: 2, default: props.maxNumberOfColumns }}
    className="my-masonry-grid"
    columnClassName="my-masonry-grid_column"
  >
    {props.children}
  </MasonryContainer>
);
