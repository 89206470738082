import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { OrganizationCommandResponse, OrganizationInitCommandRequest } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import type { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.OrganizationInitCommandRequest';

const useCreateOrganization = () => {
  const { commandResponse } = useBackend();

  const CreateOrganizationCommand = async (organization: OrganizationInitCommandRequest.AsObject) => {
    const request = new OrganizationInitCommandRequest();
    request.setAddressAdditionalLineText(organization.addressAdditionalLineText);
    request.setAddressLineText(organization.addressLineText);
    request.setCityName(organization.cityName);
    request.setCountryCode(organization.countryCode);
    request.setCountryName(organization.countryName);
    request.setDisplayName(organization.displayName);
    request.setFullName(organization.fullName);
    request.setNationalLicensingDescription(organization.nationalLicensingDescription);
    request.setNationalLicensingId(organization.nationalLicensingId);
    request.setOwnedByBusinessProcessDomainId(organization.ownedByBusinessProcessDomainId);
    request.setPlusFourZipCode(organization.plusFourZipCode);
    request.setPostalCode(organization.postalCode);
    request.setStateCode(organization.stateCode);
    request.setStateName(organization.stateName);
    request.setExternalId(organization.externalId);
    request.setLegalEntityIndicator(organization.legalEntityIndicator);
    const licenses = organization.licensesList as unknown[];
    request.setLicensesList(licenses as License[]);
    request.setBusinessProcessDomainId(organization.businessProcessDomainId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new OrganizationCommandResponse();
    OrganizationCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(CreateOrganizationCommand);
};

export { useCreateOrganization };
