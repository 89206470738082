interface IconProps {
  className?: string;
}

const FormatItalic = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M200-200v-100h160l120-360H320v-100h400v100H580L460-300h140v100H200Z" />
  </svg>
);

export default FormatItalic;
