import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpsertOrgLicenseCommandResponse, UpsertUserLicenseCommandRequest, UpsertUserLicenseCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { License } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { getInstaceFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.UpsertUserLicenseCommandRequest';

interface UpdateObject {
  license: License.AsObject;
  userId: string;
}

const useUpsertUserLicense = () => {
  const { commandResponse } = useBackend();
  const UpsertOrgLicenseCommand = async (updateObject: UpdateObject) => {
    const { license, userId } = updateObject;
    const request = new UpsertUserLicenseCommandRequest();
    const userLicense = new License();
    userLicense.setId(license.id);
    userLicense.setEnabled(license.enabled);
    userLicense.setExpirationDate(getInstaceFromTimestamp(license.expirationDate));
    userLicense.setIssueDate(getInstaceFromTimestamp(license.issueDate));
    userLicense.setLicenseAuthorityType(license.licenseAuthorityType);
    userLicense.setLicenseDescription(license.licenseDescription);
    userLicense.setLicenseId(license.licenseId);
    userLicense.setLicenseLegalName(license.licenseLegalName);
    userLicense.setLicenseScope(license.licenseScope);
    userLicense.setLicenseScopeType(license.licenseScopeType);
    userLicense.setLicenseStatusType(license.licenseStatusType);
    userLicense.setStartDate(getInstaceFromTimestamp(license.startDate));
    userLicense.setEnabled(license.enabled);
    request.setLicense(userLicense);

    request.setUserId(userId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertUserLicenseCommandResponse();
    UpsertOrgLicenseCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpsertOrgLicenseCommand);
};

export { useUpsertUserLicense };
