import { Disclosure } from '@headlessui/react';

import { cn } from '@/Utils/Helpers/uiBuilders';

import { Icon } from './Icon';

interface StepBannerProps {
  title: string;
  items: Array<string>;
  completedItems: Array<string>;
  selectedItem?: string;
  defaultOpen?: boolean;
  displayVertical?: boolean;
}

const StepBanner = (props: StepBannerProps) => {
  const { completedItems, defaultOpen, displayVertical, items, selectedItem, title } = props;

  return (
    <Disclosure as="div" className="bg-surface step-banner border-b-[1px] border-b-on-surface-stroke" defaultOpen={defaultOpen}>
      {({ open }: { open: boolean }) => (
        <>
          <Disclosure.Button
            as="div"
            className="w-full flex flex-row justify-between items-center p-4 px-2 sm:px-6"
          >
            <div className="flex flex-row items-center">
              <Icon
                className="mr-4 bg-surface-variant text-danger-on-surface"
                icon="Warning"
                variant="fixed-background"
              />
              <span className="text-on-surface-active">{title}</span>
            </div>
            <Icon
              icon={open ? 'ExpandLess' : 'ExpandMore'}
            />
          </Disclosure.Button>
          <Disclosure.Panel className={cn('pl-16 tablet:pr-8 tablet:pl-12 sm:pl-20 w-full', { '!pl-6': displayVertical })}>
            <div
              className={cn(
                'w-full overflow-x-auto flex flex-col tablet:flex-row tablet:items-center mt-2 gap-x-6 gap-y-3 pb-2',
                {
                  '!flex-col !items-start': displayVertical,
                },
              )}
            >
              {
                items.map((item, index) => {
                  const isComplete = completedItems.some((completedItem) => completedItem === item);
                  const isSelected = item === selectedItem;
                  return (
                    <div
                      key={item}
                      className="flex-row flex p-[2px] items-center mr-2"
                    >
                      {
                        isComplete ? (
                          <Icon
                            className="text-surface bg-primary-on-surface h-6 w-6 rounded-full mr-2"
                            icon="Done"
                          />
                        ) : (
                          <Icon
                            className="mr-2"
                            icon={`Number${index + 1}`}
                            selected={isSelected}
                          />
                        )
                      }
                      <span className="whitespace-nowrap text-on-surface-inactive">{item}</span>
                    </div>
                  );
                })
              }
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export { StepBanner };
