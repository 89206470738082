// source: Wilqo_API_Activity_AdminCommands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Activity_Admin_pb = require('./Wilqo_API_Activity_Admin_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Admin_pb);
var Wilqo_API_Activity_Models_pb = require('./Wilqo_API_Activity_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Models_pb);
var Wilqo_API_Users_Models_pb = require('./Wilqo_API_Users_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Users_Models_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('./Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var Wilqo_Shared_Models_Configuration_pb = require('./Wilqo.Shared.Models/Configuration_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Configuration_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
goog.exportSymbol('proto.wilqo.api.activity.ActivityDesignCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AddDesignPanelCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AddPanelElementCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BatchReorderPanelCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CreateActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CreateActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.DeletePanelCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.DeletePanelElementCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.EditPanelDetailsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitializeDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitializeDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PanelSequenceDesignCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ReorderPanelCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ReorderPanelElementCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AddDesignPanelCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddDesignPanelCommandRequest.displayName = 'proto.wilqo.api.activity.AddDesignPanelCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AddPanelElementCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddPanelElementCommandRequest.displayName = 'proto.wilqo.api.activity.AddPanelElementCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.displayName = 'proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.EditPanelDetailsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.EditPanelDetailsCommandRequest.displayName = 'proto.wilqo.api.activity.EditPanelDetailsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ReorderPanelCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ReorderPanelCommandRequest.displayName = 'proto.wilqo.api.activity.ReorderPanelCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.BatchReorderPanelCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.BatchReorderPanelCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BatchReorderPanelCommandRequest.displayName = 'proto.wilqo.api.activity.BatchReorderPanelCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.displayName = 'proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ReorderPanelElementCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ReorderPanelElementCommandRequest.displayName = 'proto.wilqo.api.activity.ReorderPanelElementCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.displayName = 'proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.displayName = 'proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.DeletePanelElementCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.DeletePanelElementCommandRequest.displayName = 'proto.wilqo.api.activity.DeletePanelElementCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.DeletePanelCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.DeletePanelCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.DeletePanelCommandRequest.displayName = 'proto.wilqo.api.activity.DeletePanelCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PanelSequenceDesignCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.displayName = 'proto.wilqo.api.activity.PanelSequenceDesignCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.displayName = 'proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.displayName = 'proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityDesignCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityDesignCommandResponse.displayName = 'proto.wilqo.api.activity.ActivityDesignCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.displayName = 'proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.displayName = 'proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.displayName = 'proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.displayName = 'proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitializeDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitializeDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitializeDataCommandRequest.displayName = 'proto.wilqo.api.activity.InitializeDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitializeDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitializeDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitializeDataCommandResponse.displayName = 'proto.wilqo.api.activity.InitializeDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CreateActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CreateActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CreateActivityCommandRequest.displayName = 'proto.wilqo.api.activity.CreateActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CreateActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CreateActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CreateActivityCommandResponse.displayName = 'proto.wilqo.api.activity.CreateActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.displayName = 'proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.displayName = 'proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.displayName = 'proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.displayName = 'proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.displayName = 'proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.displayName = 'proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddDesignPanelCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddDesignPanelCommandRequest}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddDesignPanelCommandRequest;
  return proto.wilqo.api.activity.AddDesignPanelCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddDesignPanelCommandRequest}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddDesignPanelCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddDesignPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.AddDesignPanelCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddPanelElementCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddPanelElementCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    onNewPage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    elementTemplateReference: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addIndex: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddPanelElementCommandRequest;
  return proto.wilqo.api.activity.AddPanelElementCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddPanelElementCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnNewPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setElementTemplateReference(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAddIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddPanelElementCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddPanelElementCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOnNewPage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getElementTemplateReference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddIndex();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string activity_design_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool on_new_page = 3;
 * @return {boolean}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getOnNewPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setOnNewPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string element_template_reference = 4;
 * @return {string}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getElementTemplateReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setElementTemplateReference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 add_index = 5;
 * @return {number}
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.getAddIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AddPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.AddPanelElementCommandRequest.prototype.setAddIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest;
  return proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string activity_design_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.DesignerPanelElement panel_element = 3;
 * @return {?proto.wilqo.shared.models.DesignerPanelElement}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelElement|undefined} value
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
*/
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.EditPanelElementSettingsCommandRequest.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.EditPanelDetailsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    headerText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    showNextModalConfirmationText: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.EditPanelDetailsCommandRequest;
  return proto.wilqo.api.activity.EditPanelDetailsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShowNextModalConfirmationText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.EditPanelDetailsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShowNextModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string activity_design_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string header_text = 3;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary_text = 4;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string show_next_modal_confirmation_text = 7;
 * @return {string}
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.getShowNextModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditPanelDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.EditPanelDetailsCommandRequest.prototype.setShowNextModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ReorderPanelCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ReorderPanelCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newIndex: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ReorderPanelCommandRequest;
  return proto.wilqo.api.activity.ReorderPanelCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ReorderPanelCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ReorderPanelCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ReorderPanelCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewIndex();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string activity_design_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 new_index = 3;
 * @return {number}
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.getNewIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelCommandRequest.prototype.setNewIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BatchReorderPanelCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelMovesList: jspb.Message.toObjectList(msg.getPanelMovesList(),
    proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BatchReorderPanelCommandRequest;
  return proto.wilqo.api.activity.BatchReorderPanelCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail;
      reader.readMessage(value,proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.deserializeBinaryFromReader);
      msg.addPanelMoves(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BatchReorderPanelCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelMovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newIndex: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail;
  return proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string panel_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 new_index = 2;
 * @return {number}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.getNewIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail.prototype.setNewIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_sequence_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MoveDetail panel_moves = 3;
 * @return {!Array<!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail>}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.getPanelMovesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail>} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} returns this
*/
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.setPanelMovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.addPanelMoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.BatchReorderPanelCommandRequest.MoveDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelCommandRequest.prototype.clearPanelMovesList = function() {
  return this.setPanelMovesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ReorderPanelElementCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newPanel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    newIndex: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ReorderPanelElementCommandRequest;
  return proto.wilqo.api.activity.ReorderPanelElementCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPanel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ReorderPanelElementCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewPanel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNewIndex();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string activity_design_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_element_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string new_panel = 4;
 * @return {string}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getNewPanel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setNewPanel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 new_index = 5;
 * @return {number}
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.getNewIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.ReorderPanelElementCommandRequest.prototype.setNewIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    elementMovesList: jspb.Message.toObjectList(msg.getElementMovesList(),
    proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest;
  return proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail;
      reader.readMessage(value,proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.deserializeBinaryFromReader);
      msg.addElementMoves(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getElementMovesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldPanelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newPanelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newIndex: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail;
  return proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPanelId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewPanelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewIndex();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string old_panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.getOldPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.setOldPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_panel_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.getNewPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.setNewPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 new_index = 4;
 * @return {number}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.getNewIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail.prototype.setNewIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_sequence_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MoveDetail element_moves = 3;
 * @return {!Array<!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail>}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.getElementMovesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail>} value
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} returns this
*/
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.setElementMovesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail}
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.addElementMoves = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.MoveDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.BatchReorderPanelElementCommandRequest.prototype.clearElementMovesList = function() {
  return this.setElementMovesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.DeletePanelElementCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.DeletePanelElementCommandRequest;
  return proto.wilqo.api.activity.DeletePanelElementCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.DeletePanelElementCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_design_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_element_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelElementCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelElementCommandRequest.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.DeletePanelCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.DeletePanelCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.DeletePanelCommandRequest;
  return proto.wilqo.api.activity.DeletePanelCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.DeletePanelCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.DeletePanelCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.DeletePanelCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_design_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.DeletePanelCommandRequest} returns this
 */
proto.wilqo.api.activity.DeletePanelCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    design: (f = msg.getDesign()) && Wilqo_API_Activity_Admin_pb.PanelSequenceDesign.toObject(includeInstance, f),
    runtime: (f = msg.getRuntime()) && Wilqo_API_Activity_Models_pb.PanelSequence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PanelSequenceDesignCommandResponse;
  return proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Admin_pb.PanelSequenceDesign;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.PanelSequenceDesign.deserializeBinaryFromReader);
      msg.setDesign(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.PanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PanelSequence.deserializeBinaryFromReader);
      msg.setRuntime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDesign();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Admin_pb.PanelSequenceDesign.serializeBinaryToWriter
    );
  }
  f = message.getRuntime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.PanelSequence.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PanelSequenceDesign design = 3;
 * @return {?proto.wilqo.api.activity.PanelSequenceDesign}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.getDesign = function() {
  return /** @type{?proto.wilqo.api.activity.PanelSequenceDesign} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.PanelSequenceDesign, 3));
};


/**
 * @param {?proto.wilqo.api.activity.PanelSequenceDesign|undefined} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.setDesign = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.clearDesign = function() {
  return this.setDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.hasDesign = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PanelSequence runtime = 4;
 * @return {?proto.wilqo.api.activity.PanelSequence}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.getRuntime = function() {
  return /** @type{?proto.wilqo.api.activity.PanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PanelSequence, 4));
};


/**
 * @param {?proto.wilqo.api.activity.PanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.setRuntime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignCommandResponse} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.clearRuntime = function() {
  return this.setRuntime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PanelSequenceDesignCommandResponse.prototype.hasRuntime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest;
  return proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceDesignId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} returns this
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} returns this
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_sequence_design_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.getPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest} returns this
 */
proto.wilqo.api.activity.PublishPanelSequenceDesignCommandRequest.prototype.setPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    progressType: (f = msg.getProgressType()) && Wilqo_API_Activity_Models_pb.ProgressType.toObject(includeInstance, f),
    businessProcessConfig: (f = msg.getBusinessProcessConfig()) && Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest;
  return proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 5:
      var value = new Wilqo_API_Activity_Models_pb.ProgressType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ProgressType.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 6:
      var value = new Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.deserializeBinaryFromReader);
      msg.setBusinessProcessConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_API_Activity_Models_pb.ProgressType.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string internal_name = 4;
 * @return {string}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ProgressType progress_type = 5;
 * @return {?proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressType} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ProgressType, 5));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressType|undefined} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
*/
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BusinessProcessExtraConfig business_process_config = 6;
 * @return {?proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.getBusinessProcessConfig = function() {
  return /** @type{?proto.wilqo.api.activity.BusinessProcessExtraConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig, 6));
};


/**
 * @param {?proto.wilqo.api.activity.BusinessProcessExtraConfig|undefined} value
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
*/
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.setBusinessProcessConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest} returns this
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.clearBusinessProcessConfig = function() {
  return this.setBusinessProcessConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.EditActivityDesignNamesCommandRequest.prototype.hasBusinessProcessConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityDesignCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityDesignCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activityDesign: (f = msg.getActivityDesign()) && Wilqo_API_Activity_Admin_pb.AdminActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityDesignCommandResponse;
  return proto.wilqo.api.activity.ActivityDesignCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityDesignCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Admin_pb.AdminActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.AdminActivitySummary.deserializeBinaryFromReader);
      msg.setActivityDesign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityDesignCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityDesignCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivityDesign();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Admin_pb.AdminActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse} returns this
*/
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse} returns this
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdminActivitySummary activity_design = 3;
 * @return {?proto.wilqo.api.activity.AdminActivitySummary}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.getActivityDesign = function() {
  return /** @type{?proto.wilqo.api.activity.AdminActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.AdminActivitySummary, 3));
};


/**
 * @param {?proto.wilqo.api.activity.AdminActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse} returns this
*/
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.setActivityDesign = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityDesignCommandResponse} returns this
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.clearActivityDesign = function() {
  return this.setActivityDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityDesignCommandResponse.prototype.hasActivityDesign = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest;
  return proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandRequest.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedUsersList: jspb.Message.toObjectList(msg.getAssignedUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance),
    assignedSkillSetsList: jspb.Message.toObjectList(msg.getAssignedSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillSetsList: jspb.Message.toObjectList(msg.getAvailableSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse;
  return proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addAssignedUsers(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillSets(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.users.User assigned_users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.getAssignedUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.setAssignedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.addAssignedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.clearAssignedUsersList = function() {
  return this.setAssignedUsersList([]);
};


/**
 * repeated wilqo.api.users.SkillSetSummary assigned_skill_sets = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.getAssignedSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.setAssignedSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.addAssignedSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.clearAssignedSkillSetsList = function() {
  return this.setAssignedSkillSetsList([]);
};


/**
 * repeated wilqo.api.users.SkillSetSummary available_skill_sets = 5;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.getAvailableSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.setAvailableSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.addAvailableSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.AddSkillsetToActivityCommandResponse.prototype.clearAvailableSkillSetsList = function() {
  return this.setAvailableSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillsetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest;
  return proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityDesignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.getActivityDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.setActivityDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandRequest.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    assignedUsersList: jspb.Message.toObjectList(msg.getAssignedUsersList(),
    Wilqo_API_Users_Models_pb.User.toObject, includeInstance),
    assignedSkillSetsList: jspb.Message.toObjectList(msg.getAssignedSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance),
    availableSkillSetsList: jspb.Message.toObjectList(msg.getAvailableSkillSetsList(),
    Wilqo_API_Users_Models_pb.SkillSetSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse;
  return proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_Models_pb.User;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.User.deserializeBinaryFromReader);
      msg.addAssignedUsers(value);
      break;
    case 4:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAssignedSkillSets(value);
      break;
    case 5:
      var value = new Wilqo_API_Users_Models_pb.SkillSetSummary;
      reader.readMessage(value,Wilqo_API_Users_Models_pb.SkillSetSummary.deserializeBinaryFromReader);
      msg.addAvailableSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAssignedUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_Models_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getAssignedSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
  f = message.getAvailableSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Users_Models_pb.SkillSetSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.api.users.User assigned_users = 3;
 * @return {!Array<!proto.wilqo.api.users.User>}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.getAssignedUsersList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.User, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.User>} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.setAssignedUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.addAssignedUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.clearAssignedUsersList = function() {
  return this.setAssignedUsersList([]);
};


/**
 * repeated wilqo.api.users.SkillSetSummary assigned_skill_sets = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.getAssignedSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.setAssignedSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.addAssignedSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.clearAssignedSkillSetsList = function() {
  return this.setAssignedSkillSetsList([]);
};


/**
 * repeated wilqo.api.users.SkillSetSummary available_skill_sets = 5;
 * @return {!Array<!proto.wilqo.api.users.SkillSetSummary>}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.getAvailableSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSetSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_Models_pb.SkillSetSummary, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSetSummary>} value
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.setAvailableSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSetSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.addAvailableSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.users.SkillSetSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.RemoveSkillsetFromActivityCommandResponse.prototype.clearAvailableSkillSetsList = function() {
  return this.setAvailableSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitializeDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitializeDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitializeDataCommandRequest}
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitializeDataCommandRequest;
  return proto.wilqo.api.activity.InitializeDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitializeDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitializeDataCommandRequest}
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitializeDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitializeDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.activity.InitializeDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitializeDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitializeDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitializeDataCommandResponse}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitializeDataCommandResponse;
  return proto.wilqo.api.activity.InitializeDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitializeDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitializeDataCommandResponse}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitializeDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitializeDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitializeDataCommandResponse} returns this
*/
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitializeDataCommandResponse} returns this
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitializeDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CreateActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CreateActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    progressType: (f = msg.getProgressType()) && Wilqo_API_Activity_Models_pb.ProgressType.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    businessProcessConfig: (f = msg.getBusinessProcessConfig()) && Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CreateActivityCommandRequest;
  return proto.wilqo.api.activity.CreateActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CreateActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.activity.ActivityType} */ (reader.readEnum());
      msg.setActivityType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.ProgressType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ProgressType.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = new Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig;
      reader.readMessage(value,Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.deserializeBinaryFromReader);
      msg.setBusinessProcessConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CreateActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CreateActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.ProgressType.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBusinessProcessConfig();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActivityType activity_type = 1;
 * @return {!proto.wilqo.api.activity.ActivityType}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getActivityType = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityType} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string external_name = 2;
 * @return {string}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_name = 3;
 * @return {string}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProgressType progress_type = 4;
 * @return {?proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressType} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ProgressType, 4));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressType|undefined} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BusinessProcessExtraConfig business_process_config = 6;
 * @return {?proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.getBusinessProcessConfig = function() {
  return /** @type{?proto.wilqo.api.activity.BusinessProcessExtraConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Admin_pb.BusinessProcessExtraConfig, 6));
};


/**
 * @param {?proto.wilqo.api.activity.BusinessProcessExtraConfig|undefined} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.setBusinessProcessConfig = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.clearBusinessProcessConfig = function() {
  return this.setBusinessProcessConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CreateActivityCommandRequest.prototype.hasBusinessProcessConfig = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CreateActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CreateActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    adminPanelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    internalNameValidation: (f = msg.getInternalNameValidation()) && Wilqo_API_Activity_Models_pb.InternalNameValidation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CreateActivityCommandResponse;
  return proto.wilqo.api.activity.CreateActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CreateActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminPanelSequenceDesignId(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.InternalNameValidation;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.InternalNameValidation.deserializeBinaryFromReader);
      msg.setInternalNameValidation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CreateActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CreateActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getAdminPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalNameValidation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.InternalNameValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string admin_panel_sequence_design_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.getAdminPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.setAdminPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional InternalNameValidation internal_name_validation = 3;
 * @return {?proto.wilqo.api.activity.InternalNameValidation}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.getInternalNameValidation = function() {
  return /** @type{?proto.wilqo.api.activity.InternalNameValidation} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.InternalNameValidation, 3));
};


/**
 * @param {?proto.wilqo.api.activity.InternalNameValidation|undefined} value
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.setInternalNameValidation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CreateActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.clearInternalNameValidation = function() {
  return this.setInternalNameValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CreateActivityCommandResponse.prototype.hasInternalNameValidation = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminPanelSequenceDesignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rules: (f = msg.getRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest;
  return proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminPanelSequenceDesignId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminPanelSequenceDesignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string admin_panel_sequence_design_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.getAdminPanelSequenceDesignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.setAdminPanelSequenceDesignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary rules = 2;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.getRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} returns this
*/
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    rules: (f = msg.getRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse;
  return proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} returns this
*/
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} returns this
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary rules = 2;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.getRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} returns this
*/
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse} returns this
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityTriggersCommandResponse.prototype.hasRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expirationDateConfiguration: (f = msg.getExpirationDateConfiguration()) && Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest;
  return proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.deserializeBinaryFromReader);
      msg.setExpirationDateConfiguration(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpirationDateConfiguration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string activity_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} returns this
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ExpirationDateConfiguration expiration_date_configuration = 2;
 * @return {?proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.getExpirationDateConfiguration = function() {
  return /** @type{?proto.wilqo.shared.models.ExpirationDateConfiguration} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ExpirationDateConfiguration|undefined} value
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} returns this
*/
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.setExpirationDateConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} returns this
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.clearExpirationDateConfiguration = function() {
  return this.setExpirationDateConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.hasExpirationDateConfiguration = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest} returns this
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse;
  return proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse} returns this
*/
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse} returns this
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SetExpirationConfigurationToActivityTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest;
  return proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addActivityTemplateIds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string activity_template_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.getActivityTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.setActivityTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.addActivityTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.clearActivityTemplateIdsList = function() {
  return this.setActivityTemplateIdsList([]);
};


/**
 * optional bool archived = 4;
 * @return {boolean}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse;
  return proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse} returns this
*/
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse} returns this
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ArchiveAdminActivityTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.wilqo.api.activity);
