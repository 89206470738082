import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { UpdateActivityTriggersCommandRequest, UpdateActivityTriggersCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { convertTriggerSummaryToInstance } from '../mortgageConfig/helpers';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.UpdateActivityTriggersCommandRequest';

interface UpdateObject {
  designId: string;
  rules: RuleAggregatorSummary.AsObject;
}

const useUpdateActivityTriggers = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const UpdateActivityTriggersCommand = async (updateObject: UpdateObject) => {
    const { designId, rules } = updateObject;
    const request = new UpdateActivityTriggersCommandRequest();
    request.setAdminPanelSequenceDesignId(designId);
    request.setBusinessProcessDomainId(bpdId);
    request.setRules(convertTriggerSummaryToInstance(rules));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new UpdateActivityTriggersCommandResponse();
    UpdateActivityTriggersCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, { id: designId, rules: obj.rules });
  };

  return useMutation(UpdateActivityTriggersCommand);
};

export { useUpdateActivityTriggers };
