// source: Wilqo.Shared.Models/ActivityModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb = require('../Wilqo.Shared.Models/Wilqo_Shared_Models_UIComponents_Common_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb);
goog.exportSymbol('proto.wilqo.shared.models.Checklist', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ChecklistItem', null, global);
goog.exportSymbol('proto.wilqo.shared.models.Panel', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElementMask', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElementOption', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElementRequirement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElementResponse', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelElementValidation', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelStatus', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PanelStatus.PanelInteraction', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ValidationChecklist', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ValidationChecklistItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Panel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Panel.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Panel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Panel.displayName = 'proto.wilqo.shared.models.Panel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.PanelElement.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElement.displayName = 'proto.wilqo.shared.models.PanelElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElementRequirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElementRequirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElementRequirement.displayName = 'proto.wilqo.shared.models.PanelElementRequirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElementValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElementValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElementValidation.displayName = 'proto.wilqo.shared.models.PanelElementValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElementOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElementOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElementOption.displayName = 'proto.wilqo.shared.models.PanelElementOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElementMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElementMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElementMask.displayName = 'proto.wilqo.shared.models.PanelElementMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelStatus.displayName = 'proto.wilqo.shared.models.PanelStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.Checklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.Checklist.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.Checklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.Checklist.displayName = 'proto.wilqo.shared.models.Checklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ChecklistItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.ChecklistItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ChecklistItem.displayName = 'proto.wilqo.shared.models.ChecklistItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ValidationChecklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.ValidationChecklist.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.ValidationChecklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ValidationChecklist.displayName = 'proto.wilqo.shared.models.ValidationChecklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.ValidationChecklistItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.ValidationChecklistItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.ValidationChecklistItem.displayName = 'proto.wilqo.shared.models.ValidationChecklistItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.PanelElementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.PanelElementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.PanelElementResponse.displayName = 'proto.wilqo.shared.models.PanelElementResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Panel.repeatedFields_ = [5,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Panel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Panel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Panel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Panel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    headerText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    showNext: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    proto.wilqo.shared.models.PanelElement.toObject, includeInstance),
    showNextModalConfirmationText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    coverImage: jspb.Message.getFieldWithDefault(msg, 8, ""),
    footerText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hyperlinksList: jspb.Message.toObjectList(msg.getHyperlinksList(),
    Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.shared.models.Panel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Panel;
  return proto.wilqo.shared.models.Panel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Panel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.shared.models.Panel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowNext(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.PanelElement;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElement.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShowNextModalConfirmationText(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImage(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.deserializeBinaryFromReader);
      msg.addHyperlinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Panel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Panel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Panel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Panel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShowNext();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.shared.models.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getShowNextModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCoverImage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHyperlinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string header_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string summary_text = 3;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool show_next = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.Panel.prototype.getShowNext = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setShowNext = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated PanelElement panel_elements = 5;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.shared.models.Panel.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.PanelElement, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
*/
proto.wilqo.shared.models.Panel.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.shared.models.Panel.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};


/**
 * optional string show_next_modal_confirmation_text = 6;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getShowNextModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setShowNextModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue type = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.Panel.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
*/
proto.wilqo.shared.models.Panel.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.Panel.prototype.hasType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string cover_image = 8;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getCoverImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setCoverImage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string footer_text = 9;
 * @return {string}
 */
proto.wilqo.shared.models.Panel.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated PanelHyperlinkElement hyperlinks = 10;
 * @return {!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>}
 */
proto.wilqo.shared.models.Panel.prototype.getHyperlinksList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb.PanelHyperlinkElement, 10));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelHyperlinkElement>} value
 * @return {!proto.wilqo.shared.models.Panel} returns this
*/
proto.wilqo.shared.models.Panel.prototype.setHyperlinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelHyperlinkElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelHyperlinkElement}
 */
proto.wilqo.shared.models.Panel.prototype.addHyperlinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.shared.models.PanelHyperlinkElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Panel} returns this
 */
proto.wilqo.shared.models.Panel.prototype.clearHyperlinksList = function() {
  return this.setHyperlinksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.PanelElement.repeatedFields_ = [14,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    path: jspb.Message.getFieldWithDefault(msg, 7, ""),
    order: jspb.Message.getFieldWithDefault(msg, 9, 0),
    externalLink: (f = msg.getExternalLink()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    modalConfirmationText: jspb.Message.getFieldWithDefault(msg, 12, ""),
    requirement: (f = msg.getRequirement()) && proto.wilqo.shared.models.PanelElementRequirement.toObject(includeInstance, f),
    validatorsList: jspb.Message.toObjectList(msg.getValidatorsList(),
    proto.wilqo.shared.models.PanelElementValidation.toObject, includeInstance),
    privateInput: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.wilqo.shared.models.PanelElementOption.toObject, includeInstance),
    mask: (f = msg.getMask()) && proto.wilqo.shared.models.PanelElementMask.toObject(includeInstance, f),
    validationEvent: (f = msg.getValidationEvent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fieldLibraryIdentifier: (f = msg.getFieldLibraryIdentifier()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    virtualFieldIdentifier: (f = msg.getVirtualFieldIdentifier()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.shared.models.PanelElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElement;
  return proto.wilqo.shared.models.PanelElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.shared.models.PanelElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalLink(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setModalConfirmationText(value);
      break;
    case 13:
      var value = new proto.wilqo.shared.models.PanelElementRequirement;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElementRequirement.deserializeBinaryFromReader);
      msg.setRequirement(value);
      break;
    case 14:
      var value = new proto.wilqo.shared.models.PanelElementValidation;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElementValidation.deserializeBinaryFromReader);
      msg.addValidators(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateInput(value);
      break;
    case 16:
      var value = new proto.wilqo.shared.models.PanelElementOption;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElementOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 18:
      var value = new proto.wilqo.shared.models.PanelElementMask;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElementMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValidationEvent(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFieldLibraryIdentifier(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVirtualFieldIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getExternalLink();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRequirement();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.shared.models.PanelElementRequirement.serializeBinaryToWriter
    );
  }
  f = message.getValidatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.wilqo.shared.models.PanelElementValidation.serializeBinaryToWriter
    );
  }
  f = message.getPrivateInput();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.wilqo.shared.models.PanelElementOption.serializeBinaryToWriter
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.wilqo.shared.models.PanelElementMask.serializeBinaryToWriter
    );
  }
  f = message.getValidationEvent();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFieldLibraryIdentifier();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getVirtualFieldIdentifier();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string header_text = 1;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string summary_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string path = 7;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 order = 9;
 * @return {number}
 */
proto.wilqo.shared.models.PanelElement.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.StringValue external_link = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.PanelElement.prototype.getExternalLink = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setExternalLink = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearExternalLink = function() {
  return this.setExternalLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasExternalLink = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool disabled = 11;
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string modal_confirmation_text = 12;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional PanelElementRequirement requirement = 13;
 * @return {?proto.wilqo.shared.models.PanelElementRequirement}
 */
proto.wilqo.shared.models.PanelElement.prototype.getRequirement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElementRequirement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.PanelElementRequirement, 13));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElementRequirement|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setRequirement = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearRequirement = function() {
  return this.setRequirement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasRequirement = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated PanelElementValidation validators = 14;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementValidation>}
 */
proto.wilqo.shared.models.PanelElement.prototype.getValidatorsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.PanelElementValidation, 14));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementValidation>} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setValidatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementValidation}
 */
proto.wilqo.shared.models.PanelElement.prototype.addValidators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.shared.models.PanelElementValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearValidatorsList = function() {
  return this.setValidatorsList([]);
};


/**
 * optional bool private_input = 15;
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.getPrivateInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setPrivateInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated PanelElementOption options = 16;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementOption>}
 */
proto.wilqo.shared.models.PanelElement.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.PanelElementOption, 16));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementOption>} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementOption}
 */
proto.wilqo.shared.models.PanelElement.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.wilqo.shared.models.PanelElementOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional PanelElementMask mask = 18;
 * @return {?proto.wilqo.shared.models.PanelElementMask}
 */
proto.wilqo.shared.models.PanelElement.prototype.getMask = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElementMask} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.PanelElementMask, 18));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElementMask|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasMask = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue validation_event = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.PanelElement.prototype.getValidationEvent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setValidationEvent = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearValidationEvent = function() {
  return this.setValidationEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasValidationEvent = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string thumbnail = 20;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElement.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional google.protobuf.StringValue field_library_identifier = 21;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.PanelElement.prototype.getFieldLibraryIdentifier = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 21));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setFieldLibraryIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearFieldLibraryIdentifier = function() {
  return this.setFieldLibraryIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasFieldLibraryIdentifier = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional DynamicDataElement value = 23;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.PanelElement.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 23));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasValue = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.StringValue virtual_field_identifier = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.PanelElement.prototype.getVirtualFieldIdentifier = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
*/
proto.wilqo.shared.models.PanelElement.prototype.setVirtualFieldIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElement} returns this
 */
proto.wilqo.shared.models.PanelElement.prototype.clearVirtualFieldIdentifier = function() {
  return this.setVirtualFieldIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElement.prototype.hasVirtualFieldIdentifier = function() {
  return jspb.Message.getField(this, 24) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElementRequirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElementRequirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementRequirement.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElementRequirement}
 */
proto.wilqo.shared.models.PanelElementRequirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElementRequirement;
  return proto.wilqo.shared.models.PanelElementRequirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElementRequirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElementRequirement}
 */
proto.wilqo.shared.models.PanelElementRequirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElementRequirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElementRequirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementRequirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.PanelElementRequirement} returns this
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementRequirement} returns this
 */
proto.wilqo.shared.models.PanelElementRequirement.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElementValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElementValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElementValidation}
 */
proto.wilqo.shared.models.PanelElementValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElementValidation;
  return proto.wilqo.shared.models.PanelElementValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElementValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElementValidation}
 */
proto.wilqo.shared.models.PanelElementValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElementValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElementValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementValidation} returns this
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementValidation} returns this
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementValidation} returns this
 */
proto.wilqo.shared.models.PanelElementValidation.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElementOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElementOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    order: jspb.Message.getFieldWithDefault(msg, 9, 0),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    modalConfirmationText: jspb.Message.getFieldWithDefault(msg, 12, ""),
    path: jspb.Message.getFieldWithDefault(msg, 13, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    childPanelElement: (f = msg.getChildPanelElement()) && proto.wilqo.shared.models.PanelElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElementOption}
 */
proto.wilqo.shared.models.PanelElementOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElementOption;
  return proto.wilqo.shared.models.PanelElementOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElementOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElementOption}
 */
proto.wilqo.shared.models.PanelElementOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setModalConfirmationText(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 25:
      var value = new proto.wilqo.shared.models.PanelElement;
      reader.readMessage(value,proto.wilqo.shared.models.PanelElement.deserializeBinaryFromReader);
      msg.setChildPanelElement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElementOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElementOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getChildPanelElement();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.wilqo.shared.models.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string header_text = 1;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string summary_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 order = 9;
 * @return {number}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool disabled = 11;
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string modal_confirmation_text = 12;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string path = 13;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional DynamicDataElement value = 23;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 23));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
*/
proto.wilqo.shared.models.PanelElementOption.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.hasValue = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PanelElement child_panel_element = 25;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.getChildPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.PanelElement, 25));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
*/
proto.wilqo.shared.models.PanelElementOption.prototype.setChildPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElementOption} returns this
 */
proto.wilqo.shared.models.PanelElementOption.prototype.clearChildPanelElement = function() {
  return this.setChildPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementOption.prototype.hasChildPanelElement = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElementMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElementMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customFormat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customMask: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allowEmptyFormatting: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElementMask}
 */
proto.wilqo.shared.models.PanelElementMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElementMask;
  return proto.wilqo.shared.models.PanelElementMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElementMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElementMask}
 */
proto.wilqo.shared.models.PanelElementMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMask(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowEmptyFormatting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElementMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElementMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomMask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllowEmptyFormatting();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementMask} returns this
 */
proto.wilqo.shared.models.PanelElementMask.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string custom_format = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.getCustomFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementMask} returns this
 */
proto.wilqo.shared.models.PanelElementMask.prototype.setCustomFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string custom_mask = 3;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.getCustomMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementMask} returns this
 */
proto.wilqo.shared.models.PanelElementMask.prototype.setCustomMask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool allow_empty_formatting = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementMask.prototype.getAllowEmptyFormatting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.PanelElementMask} returns this
 */
proto.wilqo.shared.models.PanelElementMask.prototype.setAllowEmptyFormatting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    interaction: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelStatus}
 */
proto.wilqo.shared.models.PanelStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelStatus;
  return proto.wilqo.shared.models.PanelStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelStatus}
 */
proto.wilqo.shared.models.PanelStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.PanelStatus.PanelInteraction} */ (reader.readEnum());
      msg.setInteraction(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInteraction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.PanelStatus.PanelInteraction = {
  UNKNOWN: 0,
  VIEWED: 1,
  PROGRESS_SUBMITTED: 2,
  SKIPPED: 3
};

/**
 * optional PanelInteraction interaction = 1;
 * @return {!proto.wilqo.shared.models.PanelStatus.PanelInteraction}
 */
proto.wilqo.shared.models.PanelStatus.prototype.getInteraction = function() {
  return /** @type {!proto.wilqo.shared.models.PanelStatus.PanelInteraction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.PanelStatus.PanelInteraction} value
 * @return {!proto.wilqo.shared.models.PanelStatus} returns this
 */
proto.wilqo.shared.models.PanelStatus.prototype.setInteraction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.PanelStatus.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.PanelStatus} returns this
*/
proto.wilqo.shared.models.PanelStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelStatus} returns this
 */
proto.wilqo.shared.models.PanelStatus.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelStatus.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.wilqo.shared.models.PanelStatus.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelStatus} returns this
 */
proto.wilqo.shared.models.PanelStatus.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.Checklist.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.Checklist.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.Checklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.Checklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Checklist.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedItems: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.ChecklistItem.toObject, includeInstance),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    checklistId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.shared.models.Checklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.Checklist;
  return proto.wilqo.shared.models.Checklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.Checklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.shared.models.Checklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedItems(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.ChecklistItem;
      reader.readMessage(value,proto.wilqo.shared.models.ChecklistItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.Checklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.Checklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.Checklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.Checklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompletedItems();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.ChecklistItem.serializeBinaryToWriter
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getChecklistId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.shared.models.Checklist.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_items = 2;
 * @return {number}
 */
proto.wilqo.shared.models.Checklist.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 completed_items = 3;
 * @return {number}
 */
proto.wilqo.shared.models.Checklist.prototype.getCompletedItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.setCompletedItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ChecklistItem items = 4;
 * @return {!Array<!proto.wilqo.shared.models.ChecklistItem>}
 */
proto.wilqo.shared.models.Checklist.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ChecklistItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.ChecklistItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ChecklistItem>} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
*/
proto.wilqo.shared.models.Checklist.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.ChecklistItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ChecklistItem}
 */
proto.wilqo.shared.models.Checklist.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.ChecklistItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 entity_version = 5;
 * @return {number}
 */
proto.wilqo.shared.models.Checklist.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string checklist_id = 6;
 * @return {string}
 */
proto.wilqo.shared.models.Checklist.prototype.getChecklistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.Checklist} returns this
 */
proto.wilqo.shared.models.Checklist.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ChecklistItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ChecklistItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ChecklistItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    panel: (f = msg.getPanel()) && proto.wilqo.shared.models.Panel.toObject(includeInstance, f),
    panelSequenceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    completed: (f = msg.getCompleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ChecklistItem}
 */
proto.wilqo.shared.models.ChecklistItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ChecklistItem;
  return proto.wilqo.shared.models.ChecklistItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ChecklistItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ChecklistItem}
 */
proto.wilqo.shared.models.ChecklistItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.Panel;
      reader.readMessage(value,proto.wilqo.shared.models.Panel.deserializeBinaryFromReader);
      msg.setPanel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ChecklistItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ChecklistItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ChecklistItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPanel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.Panel.serializeBinaryToWriter
    );
  }
  f = message.getPanelSequenceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCompleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Panel panel = 2;
 * @return {?proto.wilqo.shared.models.Panel}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getPanel = function() {
  return /** @type{?proto.wilqo.shared.models.Panel} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.Panel, 2));
};


/**
 * @param {?proto.wilqo.shared.models.Panel|undefined} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
*/
proto.wilqo.shared.models.ChecklistItem.prototype.setPanel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.clearPanel = function() {
  return this.setPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.hasPanel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string panel_sequence_id = 7;
 * @return {string}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getPanelSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.setPanelSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp completed = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getCompleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
*/
proto.wilqo.shared.models.ChecklistItem.prototype.setCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.clearCompleted = function() {
  return this.setCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.hasCompleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string summary_text = 4;
 * @return {string}
 */
proto.wilqo.shared.models.ChecklistItem.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ChecklistItem} returns this
 */
proto.wilqo.shared.models.ChecklistItem.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.ValidationChecklist.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ValidationChecklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ValidationChecklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ValidationChecklist.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedItems: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.ValidationChecklistItem.toObject, includeInstance),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    checklistId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ValidationChecklist}
 */
proto.wilqo.shared.models.ValidationChecklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ValidationChecklist;
  return proto.wilqo.shared.models.ValidationChecklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ValidationChecklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ValidationChecklist}
 */
proto.wilqo.shared.models.ValidationChecklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedItems(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.ValidationChecklistItem;
      reader.readMessage(value,proto.wilqo.shared.models.ValidationChecklistItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ValidationChecklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ValidationChecklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ValidationChecklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompletedItems();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.shared.models.ValidationChecklistItem.serializeBinaryToWriter
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getChecklistId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_items = 2;
 * @return {number}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 completed_items = 3;
 * @return {number}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getCompletedItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.setCompletedItems = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ValidationChecklistItem items = 4;
 * @return {!Array<!proto.wilqo.shared.models.ValidationChecklistItem>}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ValidationChecklistItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.ValidationChecklistItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ValidationChecklistItem>} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
*/
proto.wilqo.shared.models.ValidationChecklist.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.ValidationChecklistItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.ValidationChecklistItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 entity_version = 5;
 * @return {number}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string checklist_id = 6;
 * @return {string}
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.getChecklistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ValidationChecklist} returns this
 */
proto.wilqo.shared.models.ValidationChecklist.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.ValidationChecklistItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.ValidationChecklistItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ValidationChecklistItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    panel: (f = msg.getPanel()) && proto.wilqo.shared.models.Panel.toObject(includeInstance, f),
    completed: (f = msg.getCompleted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    followUpMap: (f = msg.getFollowUpMap()) ? f.toObject(includeInstance, proto.wilqo.shared.models.Panel.toObject) : [],
    completedByAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem}
 */
proto.wilqo.shared.models.ValidationChecklistItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.ValidationChecklistItem;
  return proto.wilqo.shared.models.ValidationChecklistItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.ValidationChecklistItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem}
 */
proto.wilqo.shared.models.ValidationChecklistItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.Panel;
      reader.readMessage(value,proto.wilqo.shared.models.Panel.deserializeBinaryFromReader);
      msg.setPanel(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCompleted(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 8:
      var value = msg.getFollowUpMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.shared.models.Panel.deserializeBinaryFromReader, "", new proto.wilqo.shared.models.Panel());
         });
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompletedByAutomation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.ValidationChecklistItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.ValidationChecklistItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.ValidationChecklistItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPanel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.Panel.serializeBinaryToWriter
    );
  }
  f = message.getCompleted();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFollowUpMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.shared.models.Panel.serializeBinaryToWriter);
  }
  f = message.getCompletedByAutomation();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Panel panel = 2;
 * @return {?proto.wilqo.shared.models.Panel}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getPanel = function() {
  return /** @type{?proto.wilqo.shared.models.Panel} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.Panel, 2));
};


/**
 * @param {?proto.wilqo.shared.models.Panel|undefined} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
*/
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setPanel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.clearPanel = function() {
  return this.setPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.hasPanel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp completed = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getCompleted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
*/
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.clearCompleted = function() {
  return this.setCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.hasCompleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string summary_text = 4;
 * @return {string}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, Panel> follow_up = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getFollowUpMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.shared.models.Panel>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.wilqo.shared.models.Panel));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.clearFollowUpMap = function() {
  this.getFollowUpMap().clear();
  return this;
};


/**
 * optional bool completed_by_automation = 9;
 * @return {boolean}
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.getCompletedByAutomation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.ValidationChecklistItem} returns this
 */
proto.wilqo.shared.models.ValidationChecklistItem.prototype.setCompletedByAutomation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.PanelElementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.PanelElementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.shared.models.PanelElementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.PanelElementResponse;
  return proto.wilqo.shared.models.PanelElementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.PanelElementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.shared.models.PanelElementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.PanelElementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.PanelElementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.PanelElementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementResponse} returns this
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.PanelElementResponse} returns this
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DynamicDataElement value = 4;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.PanelElementResponse} returns this
*/
proto.wilqo.shared.models.PanelElementResponse.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.PanelElementResponse} returns this
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.PanelElementResponse.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.wilqo.shared.models);
