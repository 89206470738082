import dayjs from 'dayjs';
import { padEnd } from 'lodash';

import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { RenderableValue } from '@/API/Models/Wilqo.Shared.Models/RenderableValue_pb';
import { DateFormatTypeEnum, DisplayTypeEnum, DisplayTypeStringMaskType, PeriodFormatType } from '@/API/Models/Wilqo.Shared.Models/RenderableValue_pb';

import { ddeAreEqual, DynamicDataElementMap, fromList, toBool, toNumber, toStringFromObject } from './dynamicDataElementHelper';
import { formatPhone } from './normalizeString';

export const renderCurrency = (num: number, maximumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits,
    style: 'currency',
  });
  return formatter.format(num);
}

const renderRenderableCurrency = (renderableValue: RenderableValue.AsObject) => {
  if (renderableValue.value) {
    const valueString = toStringFromObject(renderableValue.value);
    const valueNumber = Number(valueString);
    if (!Number.isNaN(valueNumber)) {
      return renderCurrency(valueNumber, renderableValue.currency?.decimalPlaces);
    }
    return '--';
  }
  return '--';
};

const renderDecimal = (renderableValue: RenderableValue.AsObject) => {
  if (renderableValue.value && renderableValue.value.value) {
    const valueString = toStringFromObject(renderableValue.value);
    if (valueString && valueString !== '--') {
      const valueNumber = Number(valueString);
      const decimalPlaceNumber = valueNumber.toFixed(renderableValue.decimal?.decimalPlaces || 0);
      return `${decimalPlaceNumber}${renderableValue.decimal?.formatAsPercent ? '%' : ''}`;
    }
  }
  return '--';
};

const renderPanelElementOption = (renderableValue: RenderableValue.AsObject, panelElement?: PanelElement.AsObject) => {
  if (renderableValue.value) {
    const selectedOption = panelElement?.optionsList.find((option) => (option.value && renderableValue.value ? ddeAreEqual(DynamicDataElementMap(option.value), DynamicDataElementMap(renderableValue.value)) : false));
    const value = toStringFromObject(renderableValue.value);
    if (value === 'Unknown' || (!selectedOption && !value)) return '--';
    const optionValue = selectedOption?.headerText || value;
    if (renderableValue.enumeration?.uppercase) return optionValue.toUpperCase();
    return optionValue;
  }
  return '--';
};

const renderBoolean = (renderableValue: RenderableValue.AsObject) => {
  if (renderableValue.value) {
    const value = toBool(DynamicDataElementMap(renderableValue.value));
    const label = value ? renderableValue.pb_boolean?.trueLabel : renderableValue.pb_boolean?.falseLabel;
    if (label) return label;
  }
  return '--';
};

const renderDate = (renderableValue: RenderableValue.AsObject) => {
  if (renderableValue.value) {
    const dateString = toStringFromObject(renderableValue.value);
    const date = new Date(dateString);
    if (!Number.isNaN(date) && dateString !== '--') {
      if (renderableValue.date?.formatType === DateFormatTypeEnum.DATE_FORMAT_TYPE_DATETIME) {
        return dayjs(date).format('MM/DD/YYYY HH:mm:ss');
      }
      if (renderableValue.date?.formatType === DateFormatTypeEnum.DATE_FORMAT_TYPE_TIME) {
        return dayjs(date).format('HH:mm:ss');
      }
      if (renderableValue.date?.formatType === DateFormatTypeEnum.DATE_FORMAT_TYPE_DATE) {
        return dayjs(date).format('MM/DD/YYYY');
      }
      return dateString;
    }
  }
  return '--';
};
const renderString = (renderableValue: RenderableValue.AsObject, fieldId?: string, noValue?: string, panelElement?: PanelElement.AsObject) => {
  if (renderableValue.value && renderableValue.value.value) {
    const value = toStringFromObject(renderableValue.value);
    // TEMPORARY: ADD MASK TO SSN FIELD
    if (renderableValue.string?.maskType === DisplayTypeStringMaskType.DISPLAY_TYPE_STRING_MASK_TYPE_SSN9) {
      return value.replace(/^(\d{1,3})(\d{1,2})(\d{0,4})$/, (group, p1, p2, p3) => `${padEnd(p1, 3, 'X')}-${padEnd(p2, 2, 'X')}-${padEnd(p3, 4, 'X')}`);
    }
    if (renderableValue.string?.maskType === DisplayTypeStringMaskType.DISPLAY_TYPE_STRING_MASK_TYPE_PHONE) {
      return formatPhone(value);
    }
    if (renderableValue.string?.maskType === DisplayTypeStringMaskType.DISPLAY_TYPE_STRING_MASK_TYPE_DATE || fieldId === 'UwT2DG15Io3JMRa7ZV1Wk') {
      return value.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
    }
    if (panelElement?.type === 'select') {
      const selectedOption = panelElement.optionsList.find((opt) => opt.id === value);
      if (selectedOption) {
        return selectedOption.headerText;
      }
    }
    
    return value;
  }

  return (noValue || noValue === '') ? noValue : '--';
};

const renderAssociationList = (renderableValue: RenderableValue.AsObject, panelElement?: PanelElement.AsObject) => {
  if (renderableValue.value && panelElement) {
    const ddeList = fromList(DynamicDataElementMap(renderableValue.value));
    return ddeList.map((ddeValue) => panelElement.optionsList
      .find((opt) => {
        if (opt.value) {
          return ddeAreEqual(DynamicDataElementMap(opt.value), ddeValue);
        }
        return false;
      })
      ?.headerText)
      .toString();
  }
  return '--';
};

const renderPeriod = (renderableValue: RenderableValue.AsObject): string => {
  if (renderableValue.value) {
    const value = toNumber(DynamicDataElementMap(renderableValue.value));
    if (value > 0) {
      const isPlural = value > 1;
      switch (renderableValue.period?.format) {
        case PeriodFormatType.PERIOD_FORMAT_TYPE_DAYS:
          return `${value} ${isPlural ? 'days' : 'day'}`;
        case PeriodFormatType.PERIOD_FORMAT_TYPE_HOURS:
          return `${value} ${isPlural ? 'hrs' : 'hr'}`;
        case PeriodFormatType.PERIOD_FORMAT_TYPE_MINUTES:
          return `${value} min`;
        case PeriodFormatType.PERIOD_FORMAT_TYPE_MONTHS:
          return `${value} mo`;
        case PeriodFormatType.PERIOD_FORMAT_TYPE_SECONDS:
          return `${value} ${isPlural ? 'secs' : 'sec'}`;
        case PeriodFormatType.PERIOD_FORMAT_TYPE_YEARS:
          return `${value} ${isPlural ? 'yrs' : 'yr'}`;
        default:
          return String(value);
      }
    }
  }

  return '--';
};

const renderValue = (renderableValue?: RenderableValue.AsObject, fieldId?: string, panelElement?: PanelElement.AsObject, noValue?: string): string => {
  if (!renderableValue) return (noValue || noValue === '') ? noValue : '--';
  if (renderableValue.displayType === DisplayTypeEnum.DISPLAY_TYPE_ENUM_UNKNOWN && renderableValue.value?.dataType as number >= 16) {
    return renderAssociationList(renderableValue, panelElement);
  }
  switch (renderableValue.displayType) {
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_CURRENCY:
      return renderRenderableCurrency(renderableValue);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_DECIMAL:
      return renderDecimal(renderableValue);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_ENUMERATION:
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_SELECT:
      return renderPanelElementOption(renderableValue, panelElement);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_BOOL:
      return renderBoolean(renderableValue);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_DATE:
      return renderDate(renderableValue);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_STRING:
      return renderString(renderableValue, fieldId, noValue, panelElement);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_ASSOCIATION:
      return renderAssociationList(renderableValue, panelElement);
    case DisplayTypeEnum.DISPLAY_TYPE_ENUM_PERIOD:
      return renderPeriod(renderableValue);
    default:
      return renderString(renderableValue, undefined, noValue);
  }
};

export { renderValue };
