import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { PublishAdminLoanFeeTemplateCommandRequest, PublishAdminLoanFeeTemplateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest';

const usePublishFeeTemplate = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const PublishFeeTemplateCommand = async (feeId: string) => {
    const request = new PublishAdminLoanFeeTemplateCommandRequest();
    request.setAdminLoanFeeTemplateId(feeId);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PublishAdminLoanFeeTemplateCommandResponse();
    PublishAdminLoanFeeTemplateCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.template);
  };

  return useMutation(PublishFeeTemplateCommand);
};

export { usePublishFeeTemplate };
