import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { ResetSandboxCommandRequest, ResetSandboxCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface IUseResetSandbox {
  loanId: string;
  dealId: string;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.ResetSandboxCommandRequest';

const useResetSandbox = () => {
  const { commandResponse } = useBackend();

  const resetSandbox = async (updateObject: IUseResetSandbox) => {
    const request = new ResetSandboxCommandRequest();
    request.setLoanId(updateObject.loanId);
    request.setDealId(updateObject.dealId);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new ResetSandboxCommandResponse();
    ResetSandboxCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(resetSandbox);
};

export { useResetSandbox };
