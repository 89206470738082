import { useCallback } from 'react';

import type { FieldPanelElementOverrides } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { PanelElementValidation } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementMap, fromList } from '@/Utils/Helpers/dynamicDataElementHelper';

import { isConditionOperationValid } from './conditionalValidation';
import type { ConditionalFieldOverride, DynamicDataElementValues, FieldOverrides } from './DynamicFormContext';

const convertToFieldOverrides = (overridesMap: [string, string][]) => {
  const overrides: FieldOverrides = {};

  const optionsFilterString = overridesMap.find((i) => i[0] === 'Options-Filter')?.[1];
  if (optionsFilterString) {
    const optionsFilterBytes = Uint8Array.from(atob(optionsFilterString), (c) => c.charCodeAt(0));
    overrides.optionsFilter = fromList(DynamicDataElement.deserializeBinary(optionsFilterBytes)).map((dde) => dde.toObject());
  }

  const validatorsString = overridesMap.find((i) => i[0] === 'Validators')?.[1];
  if (validatorsString) {
    overrides.validators = validatorsString
      .split(';')
      .map((validatorString) => PanelElementValidation.deserializeBinary(Uint8Array.from(atob(validatorString), (c) => c.charCodeAt(0))).toObject());
  }

  return overrides;
};

export const useConditionalFieldOverride = (
  conditionalOverideList?: FieldPanelElementOverrides.AsObject[],
) => useCallback((values?: DynamicDataElementValues) => {
  let fields: ConditionalFieldOverride = {};
  if (conditionalOverideList && values) {
    fields = conditionalOverideList.reduce((acc: ConditionalFieldOverride, conditionalOverride) => {
      try {
        if (!conditionalOverride.sourcePanelElement || !conditionalOverride.sourcePanelElement.value?.value) return acc;
        const targetValue = values[conditionalOverride.sourcePanelElement.panelElementId].dynamic;
        if (isConditionOperationValid(
          conditionalOverride.sourcePanelElement.validationOperator,
          targetValue,
          DynamicDataElementMap(conditionalOverride.sourcePanelElement.value?.value),
        )) {
          const fieldOverride: ConditionalFieldOverride = conditionalOverride.targetElementsList.reduce((acc, target) => {
            const overrides = convertToFieldOverrides(target.overridesMap);
            return {
              ...acc,
              ...target.panelElementsIdsList.reduce((prev, current) => ({ ...prev, [current]: overrides }), {}),
            };
          }, {});
          return { ...acc, ...fieldOverride };
        }
        return acc;
      } catch {
        return acc;
      }
    }, {});
  }

  return fields;
}, [conditionalOverideList]);
