import { Typography } from '@/Components/Atoms/Typography';

interface TriggerTitleProps {
  text: string[] | string;
}

const TriggerTitle = (props: TriggerTitleProps) => {
  const { text } = props;
  const sentences = [text].flat();

  return (
    <div className="items-start flex flex-col gap-5">
      {
        sentences.map((i) => <Typography variant="subtitle2Bold">{i}</Typography>)
      }
    </div>
  );
};

export { TriggerTitle };
