import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import {
  CreateDocumentCategoryCommandRequest,
  CreateDocumentCategoryCommandResponse,
} from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { DocType, DocumentCategoryEnum, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.CreateDocumentCategoryCommandRequest';

export interface CreateDocumentCategoryProps {
  docTypes: DocType[];
  name: string;
  types: DocumentEnum[];
  category: DocumentCategoryEnum;
}

const useCreateDocumentCategory = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useMutation(async ({ category, docTypes, name, types }: CreateDocumentCategoryProps) => {
    const request = new CreateDocumentCategoryCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setName(name);
    request.setDocTypesList(docTypes);
    request.setTypesList(types);
    request.setName(name);
    request.setCategoryType(category);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new CreateDocumentCategoryCommandResponse();
    CreateDocumentCategoryCommandResponse.deserializeBinaryFromReader(
      response,
      new BinaryReader(responseMsg.getValue()),
    );
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error);
  });
};
export { useCreateDocumentCategory };
