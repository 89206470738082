import clsx from 'clsx';

import type { IButtonProps } from './Button';
import { Button } from './Button';
import { Menu } from './Menu';

export type BottomNavAlignType = 'left' | 'right' | 'span';
export type BottomNavVariantType = 'default' | 'dense';

interface ExtendedButtonProps extends IButtonProps {
  asMenu?: boolean;
  dropDownList?: IButtonProps[];
}

export interface BottomNavProps {
  actions: ExtendedButtonProps[];
  align?: BottomNavAlignType;
  borderTop?: boolean;
  variant?: BottomNavVariantType;
}

const BottomNav = (props: BottomNavProps) => {
  const { actions, align = 'right', borderTop = false, variant = 'default' } = props;

  return (
    <div
      className={clsx('w-full min-h-[73px] flex flex-row bg-surface gap-2 items-center', {
        'border-t border-t-on-surface-stroke': borderTop,
        'justify-between': align === 'span' && actions.length > 1,
        'justify-end': align === 'right' || (actions.length === 1 && align !== 'left'),
        'justify-start': align === 'left',
        'p-2': variant === 'dense',
        'py-[18px] px-4': variant === 'default',
      })}
    >
      {
        actions.map((action) => (
          action.asMenu ? (
            <Menu
              key={action.label}
              closeAfterSelecting
              options={action.dropDownList?.map((action) => ({
                label: action.label,
                onClick: action.onClick || (() => null),
              })) || []}
              triggerItem={(
                <Button
                  {...action}
                  label={action.label}
                  variant={action.variant}
                />
            )}
            />
          ) : (
            <Button key={action.label} {...action} />
          )
        ))
      }
    </div>
  );
};

export { BottomNav };
