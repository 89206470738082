import { Outlet, useParams } from 'react-router';

import { useSkillset } from '@/API/Queries/user/useSkillset';
import { ContextualAppBar } from '@/Components/Atoms/ContextualAppBar';
import { Tabs } from '@/Components/Atoms/Tab';
import { useNavigate } from '@/Routes/NavigationContext';

const UpdateSkillset = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { data: skillsetGroup, isLoading } = useSkillset(id);

  return (
    <div>
      <ContextualAppBar
        icon="ArrowBack"
        loading={isLoading}
        onClickIcon={() => navigate(-1)}
        title={skillsetGroup?.displayName}
      />
      <Tabs
        tabsData={[
          { label: 'Overview', linkTo: `/admin/skillset/${id}/overview` },
          { label: 'Skillset Groups', linkTo: `/admin/skillset/${id}/skillsetGroups` },
          { label: 'Activities', linkTo: `/admin/skillset/${id}/activities` },

        ]}
      />
      <div className="p-12">
        <Outlet />
      </div>
    </div>
  );
};

export { UpdateSkillset };
