import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetDocumentCategoriesQueryRequest, GetDocumentCategoriesQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetDocumentCategoriesQueryRequest';

const useDocumentCategories = () => {
  const { commandResponse } = useBackend();

  const bpdId = useBPDId();
  return useQuery([bpdId], async () => {
    const request = new GetDocumentCategoriesQueryRequest();

    request.setBusinessProcessDomainId(bpdId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetDocumentCategoriesQueryResponse();
    GetDocumentCategoriesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.documentCategoriesList);
  });
};

export { useDocumentCategories };
