interface IconProps {
  className?: string;
}

const Number4 = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M15.1172 12.8125V14.1367H8.9707L8.88867 13.1113L12.4453 7.46875H13.7871L12.334 9.90039L10.5645 12.8125H15.1172ZM14.1504 7.46875V16H12.4629V7.46875H14.1504Z" />
  </svg>
);

export default Number4;
