import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { CreateSkillsetGroupCommandRequest, SkillsetGroupCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.CreateSkillsetGroupCommandRequest';

interface UpdateObject {
  displayName: string;
  longDescription: string;
  skillsetIds: string[];
  userIds: string[];
}

export const useCreateSkillsetGroup = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const CreateSkillsetGroupCommand = async (updateObject: UpdateObject) => {
    const { displayName, longDescription, skillsetIds, userIds } = updateObject;
    const request = new CreateSkillsetGroupCommandRequest();
    request.setDisplayName(displayName);
    request.setLongDescription(longDescription);
    request.setSkillSetIdsList(skillsetIds);
    request.setUserIdsList(userIds);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new SkillsetGroupCommandResponse();
    SkillsetGroupCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(CreateSkillsetGroupCommand);
};
