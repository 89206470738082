import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { FRAUD_PAGE_MESSAGE, useLoanFraudPage } from '@/API/Queries/mortgage/useLoanFraudPage';
import { useUpsertLoanComplianceTestSummary } from '@/API/Queries/mortgage/useUpsertLoanComplianceTestSummary';
import { Button } from '@/Components/Atoms/Button';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { InfoCard } from '@/Components/Atoms/InfoCard';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Tabs } from '@/Components/Atoms/Tab';
import { DynamicDataElementMap, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { getDateFromWilqoTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { convertStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { IntegrationHistory } from '../../IntegrationHistory';
import { FraudRequiredFieldsModal } from './FraudRequiredFieldsModal';

const FraudDetails = () => {
  const { loanId = '' } = useParams();
  const { data: loan, isLoading: isLoadingLoan } = useLoan();
  const [selectedTab, setSelectedTab] = useState('details');
  const [showModal, setShowModal] = useState(false);
  const { isLoading: isSaving, mutate } = useUpsertLoanComplianceTestSummary();
  const { data: fraudPageData, isLoading: fraudPageLoading } = useLoanFraudPage(loanId);
  const { isMobile } = useWindow();
  const renderIntegrationHistory = () => <IntegrationHistory hideBreadcrumbs sideSheetName="fraud" />;
  const queryClient = useQueryClient();
  const { showSnackBar } = useShared();
  const getDynamicString = (v?: any) => (v ? toString(DynamicDataElementMap(v)) : '');
  const getParamFromObject = (o: any, str: string): any => {
    const data = Object.entries(o).find(([key]) => !!key.includes(str));
    return (data || [])[1];
  };

  const handleSaveTestSummary = (loanEntityId: string) => (data: any, close: () => void) => {
    mutate({
      dealId: loan?.dealId || '',
      loanEntityId: loanEntityId || '',
      testSummary: {
        fraudReportFirstOrderedDate: getParamFromObject(data, fraudPageData?.fraudOrderingLogPanel?.firstOrderedDate?.id || '')?.value,
        fraudReportFirstReceivedDate: getParamFromObject(data, fraudPageData?.fraudOrderingLogPanel?.firstReceivedDate?.id || '')?.value,
        fraudReportLastOrderedDate: getParamFromObject(data, fraudPageData?.fraudOrderingLogPanel?.lastOrderedDate?.id || '')?.value,
        fraudReportLastReceivedDate: getParamFromObject(data, fraudPageData?.fraudOrderingLogPanel?.lastReceivedDate?.id || '')?.value,
      },
    }, {
      onError: () => {
        showSnackBar({ message: 'There was an error updating the compliance date', open: true });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([FRAUD_PAGE_MESSAGE, loan?.dealId]);
        showSnackBar({ message: 'Fraud date updated', open: true });
        close();
      },
    });
  };

  if (fraudPageLoading || isLoadingLoan) {
    return <Skeleton height="70px" margin="20px 0" rows={6} variant="rect" width="100%" />;
  }
  const fraudNavigate = () => {
    const url = fraudPageData?.fraudControlPanel?.providerReportUrl?.externalLink?.value;
    if (url === undefined || url === '') { return; }
    window.open(url, '_blank');
  };
  const renderDetails = () => (
    <ContingencyMessage
      icon="NoItems"
      subtitle="No fraud found"
      title="No results, yet"
      variant="image"
    />
  );

  return (
    <div>
      <div className="flex flex-row items-center justify-between px-4 py-6 tablet:px-8 desktop:px-6">
        <span className="font-four font-bold text-4xl">Fraud Details</span>
        <Button
          label="RUN TEST"
          onClick={() => setShowModal(true)}
          variant="secondary"
        />
      </div>
      <div className="flex flex-col justify-start gap-10">
        <div className="flex flex-wrap gap-6 px-4 py-6 tablet:px-8 desktop:px-6">
          {fraudPageData?.cardListList.map((infoCard) => (
            <InfoCard
              key={infoCard.id}
              caption={infoCard.caption}
              isMobile={isMobile}
              items={infoCard.verifiedListList}
              type={infoCard.type}
              value={infoCard.value}
              variant={convertStatusEnum(infoCard.infoCardType)}
            />
          ))}
        </div>
        <div className="flex flex-wrap justify-start gap-6 px-4 py-6 tablet:px-8 desktop:px-6">
          {fraudPageData?.fraudControlPanel && fraudPageData?.fraudControlPanel?.providerReportUrl?.externalLink && (
          <CardListItem
            key={fraudPageData.fraudControlPanel?.providerReportUrl?.id}
            headerProps={{
              title: fraudPageData.fraudControlPanel.title,
            }}
            isEditable={false}
            listItems={[{
              caption: 'Open provider link',
              label: fraudPageData?.fraudControlPanel?.providerReportUrl?.headerText || '',
              onClick: fraudNavigate,
              panelElement: fraudPageData.fraudControlPanel?.providerReportUrl,
              value: fraudPageData.fraudControlPanel?.providerReportUrl?.value,
            }]}
            variant="border"
          />
          )}
          <CardListItem
            key={fraudPageData?.fraudOrderingLogPanel?.panelId}
            headerProps={{
              title: 'Fraud Order Log',
            }}
            isEditable
            isSaving={isSaving}
            listItems={[{
              caption: !fraudPageData?.fraudOrderingLogPanel?.firstOrderedDate ? '--/--/--' : getDynamicString(fraudPageData?.fraudOrderingLogPanel.firstOrderedDate?.value) || '--',
              label: 'First Ordered Date',
              panelElement: fraudPageData?.fraudOrderingLogPanel?.firstOrderedDate,
              value: { value: getDateFromWilqoTimestamp(fraudPageData?.fraudOrderingLogPanel?.firstOrderedDate?.value) },
            }, {
              caption: !fraudPageData?.fraudOrderingLogPanel?.lastOrderedDate ? '--/--/--' : getDynamicString(fraudPageData?.fraudOrderingLogPanel?.lastOrderedDate?.value) || '--',
              label: 'Last Ordered Date',
              panelElement: fraudPageData?.fraudOrderingLogPanel?.lastOrderedDate,
              value: { value: getDateFromWilqoTimestamp(fraudPageData?.fraudOrderingLogPanel?.lastOrderedDate?.value) },
            },
            {
              caption: !fraudPageData?.fraudOrderingLogPanel?.firstReceivedDate ? '--/--/--' : getDynamicString(fraudPageData?.fraudOrderingLogPanel?.firstReceivedDate?.value) || '--',
              label: 'First Received Date',
              panelElement: fraudPageData?.fraudOrderingLogPanel?.firstReceivedDate,
              value: { value: getDateFromWilqoTimestamp(fraudPageData?.fraudOrderingLogPanel?.firstReceivedDate?.value) },
            }, {
              caption: !fraudPageData?.fraudOrderingLogPanel?.lastReceivedDate ? '--/--/--' : getDynamicString(fraudPageData?.fraudOrderingLogPanel?.lastReceivedDate?.value) || '--',
              label: 'Last Received Date',
              panelElement: fraudPageData?.fraudOrderingLogPanel?.lastReceivedDate,
              value: { value: getDateFromWilqoTimestamp(fraudPageData?.fraudOrderingLogPanel?.lastReceivedDate?.value) },
            }]}
            onSubmit={handleSaveTestSummary(fraudPageData?.loanEntityId || '')}
            variant="border"
          />
        </div>
      </div>
      <Tabs
        stretch
        tabsData={[
          { active: selectedTab === 'details', label: 'Details', onClick: () => setSelectedTab('details') },
          { active: selectedTab === 'integration', label: 'Integrations', onClick: () => setSelectedTab('integration') },
        ]}
      />

      {selectedTab === 'details'
        ? renderDetails()
        : renderIntegrationHistory()}

      <FraudRequiredFieldsModal
        onClose={() => setShowModal(false)}
        open={showModal}
      />
    </div>
  );
};

export { FraudDetails };
