import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetProgressItemTemplatesQueryRequest, GetProgressItemTemplatesQueryResponse } from '@/API/Models/Wilqo.API.MortgageConfig/ProgressItemTemplate/Wilqo_API_MortgageConfig_ProgressItemTemplateQueries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.GetProgressItemTemplatesQueryRequest';

const useProgressItemTemplates = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId], async () => {
    const request = new GetProgressItemTemplatesQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetProgressItemTemplatesQueryResponse();
    GetProgressItemTemplatesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.progressItemTemplatesList);
  });
};
export { useProgressItemTemplates };
