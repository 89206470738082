import { BinaryReader } from 'google-protobuf';
import { createContext, useCallback, useContext, useRef } from 'react';

import { PingSubscriptionCommandRequest, PingSubscriptionCommandResponse } from '@/API/Models/Wilqo_Subscriptions_Commands_pb';

import { useBackend } from './useBackend';
import { useConfigureSubscription } from './useConfigureSubscription';
import { pingLatencyFromTtl } from './util';

type MessageHandler = (message: any) => void;
type ReconnectHandler = () => void;

interface SubscriptionContextState {
  subscribe: (subscriptionId: string, handler: MessageHandler, reconnect: ReconnectHandler) => void;
  handleSubscriptionMessage: (msg: any) => void;
  configureSubscriptions: () => void;
}

const INITIAL_STATE: SubscriptionContextState = {
  configureSubscriptions: () => { },
  handleSubscriptionMessage: () => { },
  subscribe: () => { },
};

const SubscriptionContext = createContext(INITIAL_STATE);
SubscriptionContext.displayName = 'SubscriptionContext';

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

const SubscriptionProvider = (props: SubscriptionProviderProps) => {
  const { children } = props;
  const { commandResponse } = useBackend();
  const openSubscriptions = useRef<any>({});
  const unhandledMessages = useRef<any>({});
  const { mutateAsync: configureSubscription } = useConfigureSubscription();

  const handleSubscriptionMessage = useCallback((msg: any) => {
    const subId = msg.getSubscriptionId();
    const message = msg.getMessageContents();
    if (openSubscriptions.current[subId]) {
      const { handler } = openSubscriptions.current[subId];
      handler(message);
    } else {
      const currentIdMessages = unhandledMessages.current[subId];
      unhandledMessages.current = {
        ...unhandledMessages.current,
        [subId]: currentIdMessages ? currentIdMessages.concat(message) : [message],
      };
    }
  }, []);

  const subscribe = useCallback((subscriptionId: string, handler: MessageHandler, reconnect?: ReconnectHandler) => {
    openSubscriptions.current = {
      ...openSubscriptions.current,
      [subscriptionId]: { handler, reconnect },
    };
    const currentUnhandledMessages = unhandledMessages.current ? unhandledMessages.current[subscriptionId] : undefined;
    if (currentUnhandledMessages) {
      currentUnhandledMessages.forEach((msg: any) => {
        handler(msg);
      });
      delete unhandledMessages.current[subscriptionId];
    }
  }, []);

  const closeMissingSubscriptions = useCallback((response: PingSubscriptionCommandResponse) => {
    const activeSubscriptionIds = response.getSubscriptionIdsList();

    const deadSubscriptions = Object.keys(openSubscriptions.current)
      .filter((frontendOpenSubId) => activeSubscriptionIds.indexOf(frontendOpenSubId) === -1);

    deadSubscriptions.forEach((deadSubscriptionId) => {
      openSubscriptions.current[deadSubscriptionId].reconnect();
      delete openSubscriptions.current[deadSubscriptionId];
    });
  }, []);

  const pingSubscription = useCallback(async () => {
    const request = new PingSubscriptionCommandRequest();
    const responseMsg = await commandResponse({
      msg: request,
      msgName: 'wilqo.subscriptions.PingSubscriptionCommandRequest',
    });

    const response = new PingSubscriptionCommandResponse();
    PingSubscriptionCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    if (!responseObj.error || !responseObj.error.error) {
      closeMissingSubscriptions(response);
    }
  }, [commandResponse, closeMissingSubscriptions]);

  const configureSubscriptions = useCallback(async () => {
    const { ttl } = await configureSubscription();
    return setInterval(() => {
      pingSubscription();
    }, pingLatencyFromTtl(ttl));
  }, [pingSubscription, configureSubscription]);

  return (
    <SubscriptionContext.Provider value={{ configureSubscriptions, handleSubscriptionMessage, subscribe }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => useContext(SubscriptionContext);
export { SubscriptionProvider, useSubscriptionContext };
