import { useMemo } from 'react';

import type { LoanPageAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { useDynamicLoanPage } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { getPageAction } from '@/Utils/Helpers/getPanelElement';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import { DynamicDialog } from '../content/DynamicDialog';

interface RequiredArgs {
  mode: 'add' | 'edit' | 'read';
  withoutData: boolean;
  scopeToken: string;
  pageId: string;
}

export const getDynamicDialogAction = (args: RequiredArgs): LoanPageAction.AsObject => getPageAction({
  additionalArgsMap: [
    ['mode', args.mode],
    ['scopeToken', args.scopeToken],
    ['withoutData', String(args.withoutData)],
  ],
  dialogPageId: args.pageId,
  type: LoanPageActionType.LOAN_PAGE_ACTION_TYPE_DYNAMIC_DIALOG,
});

export const DynamicDialogAction = () => {
  const { closeAction, currentAction } = useDynamicContext();

  const { loanId, scopeToken: loanScope } = useDynamicLoanInfo();
  const { mode, scopeToken: argsScope, withoutData } = useMemo(() => ({
    mode: currentAction?.additionalArgsMap?.find(([i]) => i === 'mode')?.[1],
    scopeToken: currentAction?.additionalArgsMap?.find(([i]) => i === 'scopeToken')?.[1],
    withoutData: currentAction?.additionalArgsMap?.find(([i]) => i === 'withoutData')?.[1] === 'true',
  }), [currentAction]);
  const scopeToken = argsScope || loanScope;

  const { data, isLoading } = useDynamicLoanPage(loanId, currentAction?.dialogPageId, scopeToken, '', withoutData, { cacheTime: 0 });

  return (
    <DynamicDialog
      editMode={mode === 'edit' || mode === 'add'}
      isEditable
      isLoading={isLoading}
      isNew={mode === 'add'}
      isOpen={currentAction?.type === LoanPageActionType.LOAN_PAGE_ACTION_TYPE_DYNAMIC_DIALOG}
      onClose={closeAction}
      page={data?.page}
      scopeToken={scopeToken}
    />
  );
};
