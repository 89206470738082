// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../../../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CardListItemField.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.repeatedFields_ = [5,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.CardListItemField.toObject, includeInstance),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
    onPage: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    collapsedState: jspb.Message.getFieldWithDefault(msg, 11, 0),
    fieldGroupsList: jspb.Message.toObjectList(msg.getFieldGroupsList(),
    proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.toObject, includeInstance),
    numberOfColumns: jspb.Message.getFieldWithDefault(msg, 13, 0),
    renderType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    action: (f = msg.getAction()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.toObject(includeInstance, f),
    summary: (f = msg.getSummary()) && proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.toObject(includeInstance, f),
    displaySummaryOnRead: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CardListItemField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CardListItemField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnPage(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (reader.readEnum());
      msg.setCollapsedState(value);
      break;
    case 12:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.deserializeBinaryFromReader);
      msg.addFieldGroups(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfColumns(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (reader.readEnum());
      msg.setRenderType(value);
      break;
    case 15:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    case 16:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisplaySummaryOnRead(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CardListItemField.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOnPage();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCollapsedState();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getFieldGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.serializeBinaryToWriter
    );
  }
  f = message.getNumberOfColumns();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getRenderType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getDisplaySummaryOnRead();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SummaryLine lines = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.toObject, includeInstance),
    delimiter: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelimiter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.serializeBinaryToWriter
    );
  }
  f = message.getDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated SummaryField fields = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.getDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryLine.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string help_text = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool editable = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated CardListItemField fields = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemField>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CardListItemField, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.CardListItemField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string scope_token = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool on_page = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getOnPage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setOnPage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional WidgetCollapsedState collapsed_state = 11;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getCollapsedState = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setCollapsedState = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated CardListItemFieldGrouping field_groups = 12;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getFieldGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setFieldGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.addFieldGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.clearFieldGroupsList = function() {
  return this.setFieldGroupsList([]);
};


/**
 * optional int32 number_of_columns = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getNumberOfColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setNumberOfColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional CustomRenderType render_type = 14;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getRenderType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setRenderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional ButtonAction action = 15;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ButtonAction}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getAction = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ButtonAction} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction, 15));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ButtonAction|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.hasAction = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional SummaryCardWidgetSettings summary = 16;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings, 16));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.SummaryCardWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool display_summary_on_read = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.getDisplaySummaryOnRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings.prototype.setDisplaySummaryOnRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    helpText: jspb.Message.getFieldWithDefault(msg, 8, ""),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    displayOverrideValue: (f = msg.getDisplayOverrideValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    consolidatedItemScopeToken: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hyperLinksList: jspb.Message.toObjectList(msg.getHyperLinksList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.toObject, includeInstance),
    hasChild: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemField;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setDisplayOverrideValue(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsolidatedItemScopeToken(value);
      break;
    case 10:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.deserializeBinaryFromReader);
      msg.addHyperLinks(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOverrideValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getConsolidatedItemScopeToken();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHyperLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink.serializeBinaryToWriter
    );
  }
  f = message.getHasChild();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string leading_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string help_text = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 4;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue display_override_value = 5;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getDisplayOverrideValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 5));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setDisplayOverrideValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.clearDisplayOverrideValue = function() {
  return this.setDisplayOverrideValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.hasDisplayOverrideValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool read_only = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool hidden = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string consolidated_item_scope_token = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getConsolidatedItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setConsolidatedItemScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated HyperLink hyper_links = 10;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getHyperLinksList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.HyperLink, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.HyperLink>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setHyperLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.HyperLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.HyperLink}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.addHyperLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.mortgage_dynamic_data.HyperLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.clearHyperLinksList = function() {
  return this.setHyperLinksList([]);
};


/**
 * optional bool has_child = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.getHasChild = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemField.prototype.setHasChild = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping;
  return proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addItemIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string item_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.getItemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.setItemIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.addItemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CardListItemFieldGrouping.prototype.clearItemIdsList = function() {
  return this.setItemIdsList([]);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
