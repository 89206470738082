import { Draggable, Droppable } from 'react-beautiful-dnd';

import type { IChipProps } from './Chip';
import { ChipGroup } from './ChipGroup/ChipGroup';
import type { ListItemProps } from './ListItem';
import { ListItem } from './ListItem';
import type { NavDrawerItemProps } from './NavDrawerItem';
import { NavDrawerItem } from './NavDrawerItem';
import { SearchHeader } from './SearchHeader';

export interface MenuTab {
  id?: string;
  title: string;
  type: 'menu';
  menus: Array<NavDrawerItemProps & { id: string }>;
  onClickItem: (item: NavDrawerItemProps) => void;
}

export interface SearchTab {
  id?: string;
  title: string;
  type: 'search';
  list: Array<ListItemProps & { id: string }>;
  searchText: string;
  onChangeText: (text: string) => void;
}

export type NavDrawerTab = MenuTab | SearchTab;

export interface ContextualNavDrawerProps {
  tabs: Array<NavDrawerTab>;
  selectedTabIndex: number;
  onSelectTab: (chip: IChipProps, selected: boolean) => void;
}

const ContextualNavDrawer = (props: ContextualNavDrawerProps) => {
  const { onSelectTab, selectedTabIndex, tabs } = props;

  const renderMenu = (tab: MenuTab) => (
    <div>
      {
        tab.menus.map((menu) => (
          <NavDrawerItem key={menu.id} {...menu} />
        ))
      }
    </div>
  );

  const renderSearchTab = (tab: SearchTab) => (
    <>
      <SearchHeader
        onChange={tab.onChangeText}
        searchQuery={tab.searchText}
      />
      <div className="flex-1 overflow-y-auto">

        <Droppable droppableId="CONTROLS" isDropDisabled>
          {
          (provided) => (
            <div ref={provided.innerRef.bind(this)} {...provided.droppableProps}>
              {
                tab.list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {
                      (provided) => (
                        <div
                          ref={provided.innerRef.bind(this)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {
                          }
                          <ListItem {...item} />
                        </div>
                      )
                    }
                  </Draggable>
                ))
              }
            </div>
          )
        }
        </Droppable>
      </div>
    </>
  );

  const renderTab = (tab: NavDrawerTab) => {
    if (tab.type === 'menu') {
      return renderMenu(tab);
    }
    if (tab.type === 'search') {
      return renderSearchTab(tab);
    }
    return null;
  };

  return (
    <div className="border-l border-l-on-surface-stroke h-full overflow-y-auto w-[320px] flex flex-col p-4 max-h-[calc(100vh-122px)] bg-surface">
      <div className="m-2">
        <ChipGroup
          chips={tabs.map((tab) => ({
            id: tab.id,
            label: tab.title,
          }))}
          onSelectItem={onSelectTab}
          selected={[selectedTabIndex]}
        />
      </div>
      { renderTab(tabs[selectedTabIndex]) }
    </div>
  );
};

export { ContextualNavDrawer };
