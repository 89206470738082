// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Fusion.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_ActivityModels_pb = require('../../../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valueField: (f = msg.getValueField()) && proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.toObject(includeInstance, f),
    caption: jspb.Message.getFieldWithDefault(msg, 4, ""),
    switchType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    switchField: (f = msg.getSwitchField()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    switchOptionsList: jspb.Message.toObjectList(msg.getSwitchOptionsList(),
    proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.toObject, includeInstance),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.deserializeBinaryFromReader);
      msg.setValueField(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType} */ (reader.readEnum());
      msg.setSwitchType(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setSwitchField(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.deserializeBinaryFromReader);
      msg.addSwitchOptions(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValueField();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.serializeBinaryToWriter
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSwitchType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSwitchField();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getSwitchOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.serializeBinaryToWriter
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FusionValueFieldSettings value_field = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getValueField = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setValueField = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.clearValueField = function() {
  return this.setValueField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.hasValueField = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string caption = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional FusionOptionSwitchType switch_type = 5;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getSwitchType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setSwitchType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional wilqo.shared.models.PanelElement switch_field = 6;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getSwitchField = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setSwitchField = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.clearSwitchField = function() {
  return this.setSwitchField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.hasSwitchField = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated FusionOptionSettings switch_options = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getSwitchOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setSwitchOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.addSwitchOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.clearSwitchOptionsList = function() {
  return this.setSwitchOptionsList([]);
};


/**
 * optional bool read_only = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    valueField: (f = msg.getValueField()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValueField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getValueField();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool hidden = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value_field = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.getValueField = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.setValueField = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.clearValueField = function() {
  return this.setValueField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionValueFieldSettings.prototype.hasValueField = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionValue: (f = msg.getOptionValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    displayOverrideValue: (f = msg.getDisplayOverrideValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    calculationType: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings;
  return proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setOptionValue(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setDisplayOverrideValue(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType} */ (reader.readEnum());
      msg.setCalculationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getDisplayOverrideValue();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement option_value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getOptionValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setOptionValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.clearOptionValue = function() {
  return this.setOptionValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.hasOptionValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue value = 4;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 4));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool read_only = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 6;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue display_override_value = 7;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getDisplayOverrideValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 7));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setDisplayOverrideValue = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.clearDisplayOverrideValue = function() {
  return this.setDisplayOverrideValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.hasDisplayOverrideValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool hidden = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional FusionCalculationMethodType calculation_type = 9;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.getCalculationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSettings.prototype.setCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionOptionSwitchType = {
  FUSION_OPTION_SWITCH_TYPE_UNKNOWN: 0,
  FUSION_OPTION_SWITCH_TYPE_RADIO: 1,
  FUSION_OPTION_SWITCH_TYPE_CHIPS: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FusionCalculationMethodType = {
  FUSION_CALCULATION_METHOD_TYPE_UNKNOWN: 0,
  FUSION_CALCULATION_METHOD_TYPE_AMOUNT: 1,
  FUSION_CALCULATION_METHOD_TYPE_PERCENT: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
