// source: Wilqo.Shared.Models/WilqoPartyType.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.models.WilqoPartyType', null, global);
/**
 * @enum {number}
 */
proto.wilqo.shared.models.WilqoPartyType = {
  WILQO_PARTY_TYPE_UNKNOWN: 0,
  WILQO_PARTY_TYPE_BORROWER: 1,
  WILQO_PARTY_TYPE_NON_BORROWER: 2,
  WILQO_PARTY_TYPE_LENDER: 3,
  WILQO_PARTY_TYPE_LENDER_BRANCH: 4,
  WILQO_PARTY_TYPE_INVESTOR: 5,
  WILQO_PARTY_TYPE_LOAN_ORIGINATOR: 6,
  WILQO_PARTY_TYPE_PROCESSOR: 7,
  WILQO_PARTY_TYPE_UNDERWRITER: 8,
  WILQO_PARTY_TYPE_CLOSER: 9,
  WILQO_PARTY_TYPE_FUNDER: 10,
  WILQO_PARTY_TYPE_SHIPPER: 11,
  WILQO_PARTY_TYPE_SELLER: 12,
  WILQO_PARTY_TYPE_REAL_ESTATE_COMPANY: 13,
  WILQO_PARTY_TYPE_PROPERTY_ACCESS_CONTACT: 14,
  WILQO_PARTY_TYPE_HAZARD_INSURANCE_COMPANY: 15,
  WILQO_PARTY_TYPE_HOMEOWNERS_ASSOCIATION: 16,
  WILQO_PARTY_TYPE_APPRAISAL_MANAGEMENT_COMPANY: 17,
  WILQO_PARTY_TYPE_APPRAISER_COMPANY: 18,
  WILQO_PARTY_TYPE_FLOOD_CERTIFICATE_PROVIDER: 19,
  WILQO_PARTY_TYPE_BUILDER: 20,
  WILQO_PARTY_TYPE_TITLE_COMPANY: 21,
  WILQO_PARTY_TYPE_SETTLEMENT_COMPANY: 22,
  WILQO_PARTY_TYPE_ESCROW_COMPANY: 23,
  WILQO_PARTY_TYPE_ATTORNEY: 24,
  WILQO_PARTY_TYPE_NOTARY: 25,
  WILQO_PARTY_TYPE_WAREHOUSE_BANK: 26,
  WILQO_PARTY_TYPE_MI_COMPANY: 27,
  WILQO_PARTY_TYPE_SERVICER: 28,
  WILQO_PARTY_TYPE_SUBSERVICER: 29,
  WILQO_PARTY_TYPE_CREDIT_REPORT_PROVIDER: 30,
  WILQO_PARTY_TYPE_ATTORNEY_IN_FACT: 31
};

goog.object.extend(exports, proto.wilqo.shared.models);
