import type { ReactNode } from 'react';

import { Icon } from './Icon';
import { Typography } from './Typography';

export interface HelperTextProps {
  icon?: string;
  message?: ReactNode | string;
}

const HelperText = (props: HelperTextProps) => {
  const { icon, message = '' } = props;

  return (
    <>
      <Typography className="mt-1 mr-0 mb-0 ml-4 !text-on-surface-inactive" data-testid="form-error-message" variant="body2">{message}</Typography>
      {icon && <Icon className="absolute right-3 top-4 text-on-surface-active" icon={icon} />}
    </>
  );
};

export { HelperText };
