import { useQuery } from '@tanstack/react-query';

import { AddUserDealAssociationCommandRequest, AddUserDealAssociationCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.AddUserDealAssociationCommandRequest';

const useUserDealAssociation = (dealId = '', enabled = false) => {
  const sendMessage = useWilqoMessage();

  return useQuery<{ success: boolean }, { message: string } >([MESSAGE_NAME, dealId], async () => {
    const request = new AddUserDealAssociationCommandRequest();
    request.setDealId(dealId || '');
    await sendMessage<AddUserDealAssociationCommandResponse.AsObject>({
      msg: request,
      msgName: MESSAGE_NAME,
    }, AddUserDealAssociationCommandResponse);
    return { success: true };
  }, { enabled: Boolean(dealId) && enabled, retry: 3 });
};
export { useUserDealAssociation };
