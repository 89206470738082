import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { OrganizationCommandResponse, OrganizationUpdateCommandRequest } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import type { Organization } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';
import { MESSAGE_NAME as GET_MESSAGE_NAME } from './useOrganization';

const MESSAGE_NAME = 'wilqo.api.users.OrganizationUpdateCommandRequest';

const useUpdateOrganization = () => {
  const { commandResponse } = useBackend();
  const queryClient = useQueryClient();

  const UpdateOrganizationCommand = async (updateObject: Organization.AsObject) => {
    const request = new OrganizationUpdateCommandRequest();
    request.setAddressAdditionalLineText(updateObject.addressAdditionalLineText ?? '');
    request.setAddressLineText(updateObject.addressLineText ?? '');
    request.setCityName(updateObject.cityName ?? '');
    request.setCountryCode(updateObject.countryCode ?? '');
    request.setCountryName(updateObject.countryName ?? '');
    request.setDisplayName(updateObject.displayName ?? '');
    request.setEntityId(updateObject.id);
    request.setFullName(updateObject.fullName ?? '');
    request.setNationalLicensingDescription(updateObject.nationalLicensingDescription ?? '');
    request.setNationalLicensingId(updateObject.nationalLicensingId ?? '');
    request.setPlusFourZipCode(updateObject.plusFourZipCode ?? '');
    request.setPostalCode(updateObject.postalCode ?? '');
    request.setStateCode(updateObject.stateCode ?? '');
    request.setStateName(updateObject.stateName ?? '');
    request.setExternalId(updateObject.externalId ?? '');
    request.setLegalEntityIndicator(updateObject.legalEntityIndicator ?? '');
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new OrganizationCommandResponse();
    OrganizationCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.organization);
  };

  return useMutation(UpdateOrganizationCommand, {
    onSuccess: (organization) => {
      if (organization) {
        queryClient.invalidateQueries([GET_MESSAGE_NAME, organization.id]);
      }
    },
  });
};

export { useUpdateOrganization };
