interface IconProps {
  className?: string;
}

const NumberThree = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M10.8691 11.002H11.7715C12.0605 11.002 12.2988 10.9531 12.4863 10.8555C12.6738 10.7539 12.8125 10.6133 12.9023 10.4336C12.9961 10.25 13.043 10.0371 13.043 9.79492C13.043 9.57617 13 9.38281 12.9141 9.21484C12.832 9.04297 12.7051 8.91016 12.5332 8.81641C12.3613 8.71875 12.1445 8.66992 11.8828 8.66992C11.6758 8.66992 11.4844 8.71094 11.3086 8.79297C11.1328 8.875 10.9922 8.99023 10.8867 9.13867C10.7812 9.28711 10.7285 9.4668 10.7285 9.67773H9.03516C9.03516 9.20898 9.16016 8.80078 9.41016 8.45312C9.66406 8.10547 10.0039 7.83398 10.4297 7.63867C10.8555 7.44336 11.3242 7.3457 11.8359 7.3457C12.4141 7.3457 12.9199 7.43945 13.3535 7.62695C13.7871 7.81055 14.125 8.08203 14.3672 8.44141C14.6094 8.80078 14.7305 9.24609 14.7305 9.77734C14.7305 10.0469 14.668 10.3086 14.543 10.5625C14.418 10.8125 14.2383 11.0391 14.0039 11.2422C13.7734 11.4414 13.4922 11.6016 13.1602 11.7227C12.8281 11.8398 12.4551 11.8984 12.041 11.8984H10.8691V11.002ZM10.8691 12.2852V11.4121H12.041C12.5059 11.4121 12.9141 11.4648 13.2656 11.5703C13.6172 11.6758 13.9121 11.8281 14.1504 12.0273C14.3887 12.2227 14.5684 12.4551 14.6895 12.7246C14.8105 12.9902 14.8711 13.2852 14.8711 13.6094C14.8711 14.0078 14.7949 14.3633 14.6426 14.6758C14.4902 14.9844 14.2754 15.2461 13.998 15.4609C13.7246 15.6758 13.4043 15.8398 13.0371 15.9531C12.6699 16.0625 12.2695 16.1172 11.8359 16.1172C11.4766 16.1172 11.123 16.0684 10.7754 15.9707C10.4316 15.8691 10.1191 15.7188 9.83789 15.5195C9.56055 15.3164 9.33789 15.0625 9.16992 14.7578C9.00586 14.4492 8.92383 14.084 8.92383 13.6621H10.6172C10.6172 13.8809 10.6719 14.0762 10.7812 14.248C10.8906 14.4199 11.041 14.5547 11.2324 14.6523C11.4277 14.75 11.6445 14.7988 11.8828 14.7988C12.1523 14.7988 12.3828 14.75 12.5742 14.6523C12.7695 14.5508 12.918 14.4102 13.0195 14.2305C13.125 14.0469 13.1777 13.834 13.1777 13.5918C13.1777 13.2793 13.1211 13.0293 13.0078 12.8418C12.8945 12.6504 12.7324 12.5098 12.5215 12.4199C12.3105 12.3301 12.0605 12.2852 11.7715 12.2852H10.8691Z" />
  </svg>
);

export default NumberThree;
