import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { AddSkillsetGroupToUserCommandRequest, AddSkillsetGroupToUserCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.AddSkillsetGroupToUserCommandRequest';

interface UpdateObject {
  skillsetGroupId: string;
  userId: string;
}

export const useAddUserSkillsetGroup = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const AddUserSkillsetGroup = async (updateObject: UpdateObject) => {
    const { skillsetGroupId, userId } = updateObject;
    const request = new AddSkillsetGroupToUserCommandRequest();
    request.setSkillsetGroupId(skillsetGroupId);
    request.setBusinessProcessDomainId(bpdId);
    request.setUserId(userId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new AddSkillsetGroupToUserCommandResponse();
    AddSkillsetGroupToUserCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(AddUserSkillsetGroup);
};
