import clsx from 'clsx';
import { useMemo } from 'react';

import { Footer as FooterBrandProps, Link, LogoUrl } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { useGetFooterByUserType } from '@/API/Queries/brand/useGetFooterByUserType';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { convertRichText } from '@/Utils/Helpers/richTextConversion';

interface FooterProps {
  dataFooter?: FooterBrandProps.AsObject;
  className?: string;
}

const Footer = ({ className, dataFooter }: FooterProps) => {
  const { isConsumer } = useAuth();
  const { data } = useGetFooterByUserType(
    isConsumer ? FooterBrandProps.UserType.CONSUMER : FooterBrandProps.UserType.LENDER, !dataFooter,
  );
  const footer = useMemo(() => dataFooter || data, [data, dataFooter]);

  if (footer) {
    const { bodyRichText, linksList, logoUrlsList, mainText, sizetype } = footer;

    const isDrawer = sizetype === FooterBrandProps.SizeType.SMALL;

    const getLogoSrc = (logo: LogoUrl.AsObject): string => {
      if (logo.type === LogoUrl.LogoType.EXTERNAL) return logo.url;
      return `https://${window.location.host}/mortgage/api/configuration/image?name=${logo.url}`;
    };

    return (
      <div
        className={clsx(
          className, 'flex gap-3 py-4 sm:py-8 px-4 sm:px-12',
          {
            'items-start sm:items-center flex-col sm:flex-row border-t-2 border-t-on-surface-stroke': !isDrawer,
            'p-6 flex-col': isDrawer,
          },
        )}
      >
        <div className="flex flex-wrap">
          {logoUrlsList.map((logo: LogoUrl.AsObject) => (
            <img key={logo.url} alt="Logo" className="h-6 w-auto" src={getLogoSrc(logo)} />
          ))}
        </div>
        <span className="text-xs">
          {`Version: ${process.env.VERSION}`}
        </span>
        {mainText && <span className="text-xs">{mainText}</span>}
        {!isDrawer && (<span className="text-xs">{convertRichText(bodyRichText)}</span>)}
        <div className="flex flex-wrap gap-x-2">
          {
            linksList.map((link: Link.AsObject, index: number) => (
              <div key={`${link.link}-${link.label}`}>
                <a
                  className="no-underline font-semibold text-on-surface-active text-xs"
                  href={link.link}
                  rel="noreferrer"
                  target={link.linkType === Link.LinkType.NEW_TAB ? '_blank' : '_self'}
                >
                  {link.label}
                </a>
                {index !== linksList.length - 1 && (<span key={`${link.link}-${link.label}-s`}>|</span>)}
              </div>
            ))
          }
        </div>
      </div>
    );
  }

  return null;
};

export { Footer };
