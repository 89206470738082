import { useState } from 'react';
import ReactJson from 'react-json-view';

import { useProcessStepInformation } from '@/API/Queries/mortgageIntegrations/processStatus/useProcessStepInformation';
import { Button } from '@/Components/Atoms/Button';
import { Skeleton } from '@/Components/Atoms/Skeleton';

export interface ProcessStepInformationTableProps {
  processId: string;
  stepName: string;
}

export const ProcessStepInformation = (props: ProcessStepInformationTableProps) => {
  const [enabled, setEnabled] = useState(false);
  const { data, error, isLoading } = useProcessStepInformation({ enabled, processId: props.processId, stepName: props.stepName });

  const showTable = () => {
    setEnabled(true);
  };

  const hideTable = () => {
    setEnabled(false);
  };

  if (error) {
    return <div>An error occurred retrieving data</div>;
  }

  if (enabled) {
    if (isLoading) {
      return <Skeleton height="50px" rows={3} variant="rect" />;
    }

    const input = JSON.parse(data?.jsonInput ?? '{}');
    const output = JSON.parse(data?.jsonOutput ?? '{}');

    return (
      <div>
        <div>
          <Button
            className="m-0 mobile:mt-4"
            label="Hide"
            onClick={hideTable}
            type="button"
            variant="tertiary"
          />
        </div>
        <div>
          <ReactJson collapsed name="Input" src={input} />
          <ReactJson collapsed name="Output" src={output} />
        </div>
      </div>
    );
  }

  return (
    <Button
      className="m-0 mobile:mt-4"
      label="View"
      onClick={showTable}
      type="button"
      variant="tertiary"
    />
  );
};
