// source: Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('../Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Action', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ConfigurationType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DealPageElement', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DealReadData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Navigation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.PageConfiguration', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.PageData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Route', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Sidebar', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ValidationMessage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Widget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.WidgetContent', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DealPageElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DealPageElement.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DealPageElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DealReadData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DealReadData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DealReadData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.Navigation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Navigation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Navigation.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Navigation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.PageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.PageData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.PageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.PageData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.PageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Action.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.WidgetContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.WidgetContent.displayName = 'proto.wilqo.api.mortgage_dynamic_data.WidgetContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Widget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.Widget.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Widget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Widget.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Widget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Route = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Route, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Route.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Route';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.Sidebar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.Sidebar.displayName = 'proto.wilqo.api.mortgage_dynamic_data.Sidebar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.PageConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.displayName = 'proto.wilqo.api.mortgage_dynamic_data.PageConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ValidationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ValidationMessage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    controlType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeSelectorsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isNewItem: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fieldId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite;
  return proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopeSelectors(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNewItem(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getControlType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeSelectorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getIsNewItem();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 1;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string control_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getControlType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setControlType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string scope_selectors = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getScopeSelectorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setScopeSelectorsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.addScopeSelectors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.clearScopeSelectorsList = function() {
  return this.setScopeSelectorsList([]);
};


/**
 * optional bool is_new_item = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getIsNewItem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setIsNewItem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDataWrite.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DealPageElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldTemplate: (f = msg.getFieldTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f),
    fieldValue: (f = msg.getFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DealPageElement;
  return proto.wilqo.api.mortgage_dynamic_data.DealPageElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setFieldTemplate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DealPageElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
  f = message.getFieldValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_library_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplate field_template = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.getFieldTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.setFieldTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.clearFieldTemplate = function() {
  return this.setFieldTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.hasFieldTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement field_value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.getFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.setFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealPageElement} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.clearFieldValue = function() {
  return this.setFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealPageElement.prototype.hasFieldValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNew: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    scope: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNew(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNew();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_new = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.getIsNew = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.setIsNew = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string scope = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeList: jspb.Message.toObjectList(msg.getScopeList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.toObject, includeInstance),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.deserializeBinaryFromReader);
      msg.addScope(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope.serializeBinaryToWriter
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldLibraryScope scope = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.getScopeList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.setScopeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.addScope = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryScope, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.clearScopeList = function() {
  return this.setScopeList([]);
};


/**
 * repeated FieldLibraryUpdateValue values = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdate.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_library_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryUpdateValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    path: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string path = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated FieldLibraryParameter parameters = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    startPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    length: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter;
  return proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartPosition();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 start_position = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.getStartPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.setStartPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 length = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldLibraryParameter.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary;
  return proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated FieldTemplateSummary fields = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary>}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldGroupSummary.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scopeId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary;
  return proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getScopeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string panel_element_type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.getPanelElementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.setPanelElementType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 4;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string scope_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.getScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FieldTemplateSummary.prototype.setScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DealReadData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    template: (f = msg.getTemplate()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.toObject(includeInstance, f),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DealReadData;
  return proto.wilqo.api.mortgage_dynamic_data.DealReadData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DealReadData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplate template = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DataFieldTemplate, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DealReadData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DealReadData.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    subsection: (f = msg.getSubsection()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.toObject(includeInstance, f),
    dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, proto.wilqo.shared.models.DynamicDataElement.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData;
  return proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.deserializeBinaryFromReader);
      msg.setSubsection(value);
      break;
    case 2:
      var value = msg.getDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.shared.models.DynamicDataElement.deserializeBinaryFromReader, "", new proto.wilqo.shared.models.DynamicDataElement());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubsection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.shared.models.DynamicDataElement.serializeBinaryToWriter);
  }
};


/**
 * optional wilqo.shared.models.DesignerPanelSequence subsection = 1;
 * @return {?proto.wilqo.shared.models.DesignerPanelSequence}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.getSubsection = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelSequence|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.setSubsection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.clearSubsection = function() {
  return this.setSubsection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.hasSubsection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, wilqo.shared.models.DynamicDataElement> data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.shared.models.DynamicDataElement>}
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.getDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.shared.models.DynamicDataElement>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.wilqo.shared.models.DynamicDataElement));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.FlexlistSubsectionData.prototype.clearDataMap = function() {
  this.getDataMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem;
  return proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.URLAProgressItem.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Navigation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Navigation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    submenuListList: jspb.Message.toObjectList(msg.getSubmenuListList(),
    proto.wilqo.api.mortgage_dynamic_data.Navigation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Navigation;
  return proto.wilqo.api.mortgage_dynamic_data.Navigation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Navigation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Navigation;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Navigation.deserializeBinaryFromReader);
      msg.addSubmenuList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Navigation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Navigation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubmenuListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Navigation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link_to = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Navigation submenu_list = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.getSubmenuListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Navigation, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.setSubmenuListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Navigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation}
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.addSubmenuList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.Navigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Navigation.prototype.clearSubmenuListList = function() {
  return this.setSubmenuListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.repeatedFields_ = [2,3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.PageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    layoutsList: jspb.Message.toObjectList(msg.getLayoutsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    widgetsList: jspb.Message.toObjectList(msg.getWidgetsList(),
    proto.wilqo.api.mortgage_dynamic_data.Widget.toObject, includeInstance),
    layoutType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sidebarsList: jspb.Message.toObjectList(msg.getSidebarsList(),
    proto.wilqo.api.mortgage_dynamic_data.Sidebar.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.wilqo.api.mortgage_dynamic_data.Action.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.PageData;
  return proto.wilqo.api.mortgage_dynamic_data.PageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addLayouts(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Widget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Widget.deserializeBinaryFromReader);
      msg.addWidgets(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLayoutType(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Sidebar;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Sidebar.deserializeBinaryFromReader);
      msg.addSidebars(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Action;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.PageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLayoutsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Widget.serializeBinaryToWriter
    );
  }
  f = message.getLayoutType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSidebarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Sidebar.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Action.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated google.protobuf.Int32Value layouts = 2;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getLayoutsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setLayoutsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.addLayouts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.google.protobuf.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.clearLayoutsList = function() {
  return this.setLayoutsList([]);
};


/**
 * repeated Widget widgets = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Widget>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Widget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Widget, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Widget>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Widget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.addWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.Widget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.clearWidgetsList = function() {
  return this.setWidgetsList([]);
};


/**
 * optional string layout_type = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getLayoutType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setLayoutType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Sidebar sidebars = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Sidebar>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getSidebarsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Sidebar>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Sidebar, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Sidebar>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setSidebarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Sidebar=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.addSidebars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.Sidebar, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.clearSidebarsList = function() {
  return this.setSidebarsList([]);
};


/**
 * repeated Action actions = 6;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Action>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Action, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Action>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action}
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage_dynamic_data.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageData.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    leftIcon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rightIcon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Action;
  return proto.wilqo.api.mortgage_dynamic_data.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeftIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRightIcon(value);
      break;
    case 5:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLeftIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRightIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum = {
  UNKNOWN: 0,
  CREATE_LOAN: 1,
  CONTINUE_APPLICATION: 2
};

/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActionTypeEnum type = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Action.ActionTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string left_icon = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.getLeftIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.setLeftIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string right_icon = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.getRightIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.setRightIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, string> additional_settings = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Action} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Action.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    api: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings;
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.getApi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.setApi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: (f = msg.getSettings()) && proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, 0),
    subTitle: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.WidgetContent;
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} */ (reader.readEnum());
      msg.setSubTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.WidgetContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSubTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional WidgetContentSettings settings = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.getSettings = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.WidgetContentSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ConfigurationType title = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.getTitle = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ConfigurationType sub_title = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.getSubTitle = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ConfigurationType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContent.prototype.setSubTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Widget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Widget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.toObject = function(includeInstance, msg) {
  var f, obj = {
    area: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentList: jspb.Message.toObjectList(msg.getContentList(),
    proto.wilqo.api.mortgage_dynamic_data.WidgetContent.toObject, includeInstance),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Widget;
  return proto.wilqo.api.mortgage_dynamic_data.Widget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Widget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArea(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.WidgetContent;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.WidgetContent.deserializeBinaryFromReader);
      msg.addContent(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Widget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Widget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArea();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.WidgetContent.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum = {
  UNKNOWN: 0,
  DISPLAY_NAME: 1,
  CONSUMER_ACTIVITIES: 2,
  LENDER_ACTIVITIES: 3
};

/**
 * optional string area = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.getArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.setArea = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated WidgetContent content = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.WidgetContent>}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.getContentList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.WidgetContent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.WidgetContent, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.WidgetContent>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.setContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContent}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.addContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.WidgetContent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.clearContentList = function() {
  return this.setContentList([]);
};


/**
 * optional WidgetTypeEnum type = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Widget.WidgetTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * map<string, string> additional_settings = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Widget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Widget.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Route.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Route} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Route.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageData: (f = msg.getPageData()) && proto.wilqo.api.mortgage_dynamic_data.PageData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Route;
  return proto.wilqo.api.mortgage_dynamic_data.Route.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Route} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.PageData;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.PageData.deserializeBinaryFromReader);
      msg.setPageData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Route.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Route} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Route.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.PageData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PageData page_data = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.PageData}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.getPageData = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.PageData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.PageData, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.PageData|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.setPageData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.clearPageData = function() {
  return this.setPageData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.Route.prototype.hasPageData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.Sidebar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    additionalSettingsMap: (f = msg.getAdditionalSettingsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.Sidebar;
  return proto.wilqo.api.mortgage_dynamic_data.Sidebar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = msg.getAdditionalSettingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.Sidebar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdditionalSettingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum = {
  OVERVIEW: 0,
  LOAN_LIST: 1,
  ANALYTICS: 2,
  LOAN_PROGRESS: 3,
  LOAN_PARTIES: 4,
  LOAN_TRANSACTION: 5,
  LOAN_OVERVIEW: 6,
  LOAN_DOCUMENTS: 7,
  LOAN_COMPLIANCE: 8,
  LOAN_FRAUD_TESTS: 9,
  LOAN_COMMENTS: 10,
  LOAN_CHANGE_HISTORY: 11,
  HELP: 12
};

/**
 * optional SidebarTypeEnum type = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Sidebar.SidebarTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> additional_settings = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.getAdditionalSettingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Sidebar} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.Sidebar.prototype.clearAdditionalSettingsMap = function() {
  this.getAdditionalSettingsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    navigationList: jspb.Message.toObjectList(msg.getNavigationList(),
    proto.wilqo.api.mortgage_dynamic_data.Navigation.toObject, includeInstance),
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    proto.wilqo.api.mortgage_dynamic_data.Route.toObject, includeInstance),
    businessprocessdomainid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    roleMask: msg.getRoleMask_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.PageConfiguration;
  return proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Navigation;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Navigation.deserializeBinaryFromReader);
      msg.addNavigation(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.Route;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.Route.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessprocessdomainid(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRoleMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNavigationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Navigation.serializeBinaryToWriter
    );
  }
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.Route.serializeBinaryToWriter
    );
  }
  f = message.getBusinessprocessdomainid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoleMask_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Navigation navigation = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getNavigationList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Navigation, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Navigation>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setNavigationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Navigation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Navigation}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.addNavigation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.Navigation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.clearNavigationList = function() {
  return this.setNavigationList([]);
};


/**
 * repeated Route routes = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.Route>}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.Route>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.Route, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.Route>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.Route=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.Route}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.Route, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.clearRoutesList = function() {
  return this.setRoutesList([]);
};


/**
 * optional string businessProcessDomainId = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getBusinessprocessdomainid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setBusinessprocessdomainid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bytes role_mask = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getRoleMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes role_mask = 6;
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getRoleMask_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRoleMask()));
};


/**
 * optional bytes role_mask = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.getRoleMask_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRoleMask()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.PageConfiguration.prototype.setRoleMask = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.repeatedFields_ = [3,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanPhasesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    consumerDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lenderDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 6, 0),
    expression: jspb.Message.getFieldWithDefault(msg, 7, ""),
    url: jspb.Message.getFieldWithDefault(msg, 8, ""),
    showIfLender: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    showIfConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    pageId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    logicalScopeId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    screenOrderOverridesMap: (f = msg.getScreenOrderOverridesMap()) ? f.toObject(includeInstance, undefined) : [],
    subTextsList: jspb.Message.toObjectList(msg.getSubTextsList(),
    Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem;
  return proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoanPhases(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpression(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowIfLender(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowIfConsumer(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalScopeId(value);
      break;
    case 13:
      var value = msg.getScreenOrderOverridesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readUint32, null, "", 0);
         });
      break;
    case 14:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.deserializeBinaryFromReader);
      msg.addSubTexts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanPhasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getConsumerDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLenderDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getExpression();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getShowIfLender();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getShowIfConsumer();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLogicalScopeId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getScreenOrderOverridesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint32);
  }
  f = message.getSubTextsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string loan_phases = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getLoanPhasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setLoanPhasesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.addLoanPhases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.clearLoanPhasesList = function() {
  return this.setLoanPhasesList([]);
};


/**
 * optional string consumer_description = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getConsumerDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setConsumerDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lender_description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getLenderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setLenderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 screen_order = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string expression = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getExpression = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setExpression = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string url = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool show_if_lender = 9;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getShowIfLender = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setShowIfLender = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool show_if_consumer = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getShowIfConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setShowIfConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string page_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string logical_scope_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getLogicalScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setLogicalScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * map<string, uint32> screen_order_overrides = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getScreenOrderOverridesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.clearScreenOrderOverridesMap = function() {
  this.getScreenOrderOverridesMap().clear();
  return this;
};


/**
 * repeated wilqo.shared.models.DisplayRenderableValue sub_texts = 14;
 * @return {!Array<!proto.wilqo.shared.models.DisplayRenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.getSubTextsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DisplayRenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue, 14));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DisplayRenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.setSubTextsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.shared.models.DisplayRenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.addSubTexts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.shared.models.DisplayRenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem.prototype.clearSubTextsList = function() {
  return this.setSubTextsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageContent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ValidationMessage;
  return proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageContent(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType} */ (reader.readEnum());
      msg.setMessageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message_content = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.getMessageContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.setMessageContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ValidationMessageType message_type = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.getMessageType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessage.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ConfigurationType = {
  CONFIGURATION_TYPE_UNKNOWN: 0,
  BORROWER_NAME: 1,
  ENTITY_ID: 2,
  LOAN_NUMBER: 3,
  PROPERTY_ADDRESS: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum = {
  BORROWER_CLASSIFICATION_ENUM_UNKNOWN: 0,
  BORROWER_CLASSIFICATION_ENUM_PRIMARY: 1,
  BORROWER_CLASSIFICATION_ENUM_SECONDARY: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DeleteBorrowerPageType = {
  DELETE_BORROWER_PAGE_TYPE_UNKNOWN: 0,
  DELETE_BORROWER_PAGE_TYPE_DELETE_DISCLAIMER_WITH_ALTERNATE_PRIMARY_BORROWER_SELECTION: 1,
  DELETE_BORROWER_PAGE_TYPE_ASSOCIATION_INFO: 2,
  DELETE_BORROWER_PAGE_TYPE_DELETE_BORROWER_CONFIRMATION: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ValidationMessageType = {
  VALIDATION_MESSAGE_TYPE_UNKNWON: 0,
  VALIDATION_MESSAGE_TYPE_BANNER: 1,
  VALIDATION_MESSAGE_TYPE_TOASTER: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
