import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { Dialog } from '../Dialog';

interface UnlicensedStateDialogProps {
  open: boolean;
  onClickDismiss: () => void;
}

export const UnlicensedStateDialog = (props: UnlicensedStateDialogProps) => {
  const { onClickDismiss, open } = props;
  const { pathname } = useLocation();

  const { subtitle, title } = useMemo(() => ({
    subtitle: pathname.includes('questionnaire') ? 'We need more information for this state. Please call us at xxx-xxx-xxxx or email us at help@email.com to continue.' : 'Please validate the address or contact your Administrator if this is incorrect.',
    title: pathname.includes('questionnaire') ? 'More info required' : 'You do not appear to be licensed in the subject property state.',
  }), [pathname]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      actions={[{
        label: 'Got it',
        onClick: onClickDismiss,
        type: 'button',
      }]}
      onClickDismiss={onClickDismiss}
      size="small"
      title={title}
    >
      <span className="text-on-surface-active">
        {subtitle}
      </span>
    </Dialog>
  );
};
