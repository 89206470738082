import { useEffect } from 'react';

import type { RequiredField, RequiredFieldGroup } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import type { IntegrationTypeLocalEnum, IntegrationVendorEnum, ProcessType } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useSaveRequiredFields } from '@/API/Queries/mortgageDynamicData/useSaveRequiredFields';
import { useGetRequiredFieldsValidation } from '@/API/Queries/mortgageIntegrations/useGetRequiredFieldsValidation';
import { Button } from '@/Components/Atoms/Button';
import { Loader } from '@/Components/Atoms/Loader';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { WidgetProvider } from '@/Components/Widgets/content/WidgetContext';
import { WidgetRenderer } from '@/Components/Widgets/WidgetRenderer';

import type { DynamicDataElementValues } from '../dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '../dynamicForm/DynamicFormContext';
import { useDynamicContext } from './DynamicContext';

interface RequiredFieldProps {
  dealId: string;
  additionalInformation?: any;
  integrationType: IntegrationTypeLocalEnum;
  packageName?: string;
  onIsValid: () => void;
  isRunningIntegration: boolean;
  vendor?: IntegrationVendorEnum;
  progressMessage?: string;
  processType?: ProcessType;
}

export const RequiredFields = (props: RequiredFieldProps) => {
  const {
    additionalInformation,
    dealId,
    integrationType,
    isRunningIntegration,
    onIsValid,
    packageName,
    processType,
    progressMessage,
    vendor,
  } = props;

  const { data, isLoading, refetch } = useGetRequiredFieldsValidation({ additionalInformation, dealId, integrationType, packageName, processType, vendor });
  const { isLoading: isSaving, mutate: saveRequiredFields } = useSaveRequiredFields();

  const { version } = useDynamicContext();

  const widgetsList = data?.requiredFieldsPage?.widgetsList || [];

  useEffect(() => {
    if (data?.isValid) {
      onIsValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (values: DynamicDataElementValues) => {
    const groupsList = widgetsList.reduce<RequiredFieldGroup.AsObject[]>((prev, widget) => {
      if (widget.cardListItem) {
        const { fieldsList: fields, scopeToken } = widget.cardListItem;
        const fieldsList = fields.reduce<RequiredField.AsObject[]>((prev, curr) => {
          const value = (values[curr.panelElement?.id ?? 'id']?.dynamic as DynamicDataElement)?.toObject();
          const virtualFieldId = curr.panelElement?.virtualFieldIdentifier?.value || '';
          return value !== undefined ? [...prev, { value, virtualFieldId }] : prev;
        }, []);

        return fieldsList.length ? [...prev, { fieldsList, scopeToken }] : prev;
      }
      return [];
    }, []);

    saveRequiredFields({
      entityId: dealId,
      groupsList,
      version,
    }, { onSuccess: () => refetch() });
  };

  return (
    <DialogLayout asChild>
      <DynamicFormProvider onSubmit={handleSubmit}>
        <DialogHeader title="Required Fields" />

        <DialogContent>
          {isLoading || isRunningIntegration ? (
            <Loader progressMessage={progressMessage} />
          ) : (
            <div className="p-12 flex-1 flex flex-col gap-4">
              <WidgetProvider
                context="dialog"
                editAllWidgets={false}
                editingWidgetIds={widgetsList.map((w) => w.id) || []}
                pageId=""
                savingWidgetIds={[]}
                setEditAllWidgets={() => { }}
                setEditingWidgetIds={() => { }}
                setSavingWidgetIds={() => { }}
              >
                <WidgetRenderer widgetList={widgetsList || []} />
              </WidgetProvider>
            </div>
          )}
        </DialogContent>

        {
          !widgetsList.every((w) => w.messagePanel) && (
            <DialogFooter borderTop>
              <Button
                disabled={isLoading}
                isLoading={isSaving || isRunningIntegration}
                label="save"
                type="submit"
              />
            </DialogFooter>
          )
        }
      </DynamicFormProvider>
    </DialogLayout>
  );
};
