import { keyBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DefaultValueConfig } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/DefaultValueConfig_pb';
import type {
  RelatedNestingContentSettings,
  RelatedNestingContentWidgetSettings,
} from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/RelatedNestingContent_pb';
import { RelatedNestingContentExpanderState } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/RelatedNestingContent_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import { WidgetRenderer } from '../../WidgetRenderer';
import { DynamicDialog } from '../DynamicDialog';
import { useDialogData } from '../useDialogData';
import type { SaveConfig } from '../useSaveWidget';
import { useWidgetIds } from '../useWidgetSavingIds';
import { ExpandStatusTypeEnum, useWidgetContext } from '../WidgetContext';
import { NestedCollectionCollapsibleRow } from './NestedCollapsibleRow';

interface PageConfig {
  pageId: string;
  scopeToken: string;
  isNew: boolean;
  isNested: boolean;
  dynamicValue?: DynamicDataElement.AsObject;
  virtualId?: string;
}

export const NestedCollection = ({
  nestedContentList,
}: RelatedNestingContentWidgetSettings.AsObject) => {
  const {
    addEditingWidget,
    context,
    editingWidgetIds,
    expandStatus,
    hasSavedIds,
    removeSavingWidget,
    setHasSavedIds,
  } = useWidgetContext();
  const { values } = useDynamicForm();
  const { loanId } = useDynamicLoanInfo();

  const [currentRowBeingEdited, setCurrentRowBeingEdited] = useState<string | undefined>();
  const [quickAddEditInfo, setQuickAddEditInfo] = useState<PageConfig | undefined>();

  const { data: quickAddEditConfig, isLoading, refetch: reloadDialog } = useDialogData(
    loanId,
    quickAddEditInfo?.pageId || '',
    quickAddEditInfo?.scopeToken || '',
    '',
    quickAddEditInfo?.isNew,
    quickAddEditInfo?.virtualId,
    quickAddEditInfo?.dynamicValue,
    DefaultValueConfig.WidgetType.WIDGET_TYPE_RELATED_NESTING_CONTENT,
  );

  const handleClickNestedItemClick = useCallback((item: RelatedNestingContentSettings.AsObject) => {
    setQuickAddEditInfo({
      isNested: false,
      isNew: true,
      pageId: item.expander?.collectionOperations?.add?.pageId || '',
      scopeToken: item.expander?.collectionOperations?.add?.scopeToken || '',
    });
  }, []);

  // set quick edit info when no items
  useEffect(() => {
    if (!nestedContentList.some((item) => item.groupsList.length > 0) && context === 'dialog') {
      handleClickNestedItemClick(nestedContentList[0]);
    }
  }, [nestedContentList, handleClickNestedItemClick, context]);

  // enable the CardListItem editing state by default
  useEffect(() => {
    if (quickAddEditConfig?.page && context === 'dialog') {
      const newEditingWidgets = quickAddEditConfig.page.widgetsList.map((w) => w.id).filter((id) => !editingWidgetIds.includes(id));

      if (newEditingWidgets.length > 0) {
        addEditingWidget(newEditingWidgets);
      }
    }
  }, [addEditingWidget, quickAddEditConfig, context, editingWidgetIds]);

  // after adding new item should close the form
  useEffect(() => {
    const savedWidgetId = hasSavedIds?.find(
      (savedId) => quickAddEditConfig?.page?.widgetsList.some((w) => savedId === w.id),
    );

    if (hasSavedIds?.length && savedWidgetId) {
      if (setHasSavedIds) setHasSavedIds(hasSavedIds?.filter((id) => id !== savedWidgetId));
      setQuickAddEditInfo(undefined);
    }
  }, [hasSavedIds, quickAddEditConfig?.page?.widgetsList, removeSavingWidget, setHasSavedIds]);

  const { isSaving } = useWidgetIds(quickAddEditConfig?.page?.widgetsList);

  const renderContent = (item: RelatedNestingContentSettings.AsObject) => {
    if (quickAddEditInfo && !quickAddEditInfo.isNested && context !== 'page') {
      return (
        <div className="p-3">
          {isLoading ? (
            <Skeleton height={300} variant="rect" width="100%" />
          ) : (
            <WidgetRenderer widgetList={quickAddEditConfig?.page?.widgetsList || []} />
          )}
        </div>
      );
    }

    if (item.groupsList.length > 0) {
      return item.groupsList.map(
        (rowData) => (
          <NestedCollectionCollapsibleRow
            key={rowData.virtualFieldId}
            currentRowBeingEdited={currentRowBeingEdited}
            quickAddEditInfo={quickAddEditInfo}
            rowData={rowData}
            setCurrentRowBeingEdited={setCurrentRowBeingEdited}
            setQuickAddEditInfo={setQuickAddEditInfo}
          />
        ),
      );
    }

    return (
      <ContingencyMessage
        icon=""
        subtitle="No results founds for this query"
        title="No Results"
      />
    );
  };

  const required = useMemo(() => {
    const cards = quickAddEditConfig?.page?.widgetsList?.map((w) => w.cardListItem).filter((c) => !!c) || [];
    const required = cards
      .flatMap((c) => c?.fieldsList)
      .filter((f) => f?.panelElement?.requirement?.required)
      .map((f) => f?.id);
    return keyBy(required);
  }, [quickAddEditConfig]);

  const isSaveDisabled = useCallback(() => {
    const isRequired = (fieldId: string) => Object.prototype.hasOwnProperty.call(required, fieldId);
    const isValueMissing = (fieldId: string) => values[fieldId].value == null || values[fieldId].value === '' || values[fieldId].plainValue === '--';
    const isAnyRequiredFieldsMissing = () => Object.keys(values).some((fieldId) => isRequired(fieldId) && isValueMissing(fieldId));
    return isAnyRequiredFieldsMissing();
  }, [required, values]);

  const getActions = useCallback((item: RelatedNestingContentSettings.AsObject): CardButton[] => {
    if (quickAddEditInfo && context !== 'page') {
      const saveConfig: SaveConfig = {
        isNew: quickAddEditInfo.isNew,
        loanId,
        pageId: quickAddEditInfo.pageId,
        scopeToken: quickAddEditInfo.scopeToken,
        type: 'page',
        widgets: quickAddEditConfig?.page?.widgetsList,
      };
      const handleCancel = () => {
        setQuickAddEditInfo(undefined);
        setCurrentRowBeingEdited(undefined);
      };
      return [
        { label: 'Cancel', onClick: handleCancel, variant: 'tertiary' },
        { additionalData: JSON.stringify(saveConfig), disabled: isSaveDisabled(), isLoading: isSaving, label: 'Save', type: 'submit' },
      ];
    }
    return [
      {
        label: item.expander?.collectionOperations?.add?.label || 'Create new',
        onClick: () => handleClickNestedItemClick(item),
      },
    ];
  }, [context, quickAddEditInfo, quickAddEditConfig, loanId, isSaving, handleClickNestedItemClick, isSaveDisabled]);

  const handleDialogClose = () => {
    setQuickAddEditInfo(undefined);
    reloadDialog();
  };

  return (
    <>
      <div className="flex flex-col gap-y-10">
        {nestedContentList.map((item) => (
          <Card
            key={renderValue(item.label)}
            defaultOpen={
              item.expander?.initialState
                === RelatedNestingContentExpanderState.RELATED_NESTING_CONTENT_EXPANDER_STATE_EXPANDED
            }
            headerProps={{
              actions: getActions(item),
              open: expandStatus !== ExpandStatusTypeEnum.COLLAPSE_ALL || context === 'dialog',
              subtitle: item.help?.text,
              title: renderValue(item.label),
            }}
            variant="border"
          >
            {renderContent(item)}
          </Card>
        ))}
      </div>

      {quickAddEditInfo && context === 'page' && (
        <DynamicDialog
          editMode
          isEditable
          isLoading={isLoading}
          isNew={quickAddEditInfo.isNew}
          isOpen
          onClose={handleDialogClose}
          page={quickAddEditConfig?.page}
          scopeToken={quickAddEditInfo.scopeToken}
        />
      )}
    </>
  );
};
