import { useMutation } from '@tanstack/react-query';

import { UpdateUserLoanPermissionCommandRequest, UpdateUserLoanPermissionCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

interface UpdateObject {
  userIds: string[];
  orgIds: string[];
  userId: string;
}

const MESSAGE_NAME = 'wilqo.api.users.UpdateUserLoanPermissionCommandRequest';

export const useUpdateUserPermissions = () => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();

  return useMutation(async (updateObject: UpdateObject) => {
    const request = new UpdateUserLoanPermissionCommandRequest();
    request.setUserId(updateObject.userId);
    request.setInstitutionId(institutionId);
    request.setAllowedOrgIdsList(updateObject.orgIds);
    request.setAllowedUserIdsList(updateObject.userIds);
    return sendMessage<UpdateUserLoanPermissionCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      UpdateUserLoanPermissionCommandResponse,
    );
  });
};
