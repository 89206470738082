import { useParams } from 'react-router';

import { useSkillset } from '@/API/Queries/user/useSkillset';
import { useUpdateSkillset } from '@/API/Queries/user/useUpdateSkillset';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';

const namePanelElement = getPanelElement({ headerText: 'Skillset Name', id: 'displayName', requirement: getPanelElementRequiment(), type: 'text' });
const descriptionPanelElement = getPanelElement({ headerText: 'Description', id: 'description', requirement: getPanelElementRequiment(), type: 'text' });

const SkillsetOverview = () => {
  const { id = '' } = useParams();
  const { data: skillset, isLoading, refetch } = useSkillset(id);

  const { isLoading: isSaving, mutate: updateSkillset } = useUpdateSkillset();

  const handleSubmit = (formData: DynamicDataElementValues, close: () => void) => {
    updateSkillset({
      displayName: formData.displayName.value,
      longDescription: formData.description.value,
      skillsetGroupIds: skillset?.skillsetGroupsList.map((e) => e.id) || [],
      skillsetId: id,
    }, {
      onSuccess: () => {
        close();
        refetch();
      },
    });
  };

  return (
    <CardListItem
      headerProps={{ title: 'Skillset Overview' }}
      isEditable
      isLoading={isLoading}
      isSaving={isSaving}
      listItems={[
        {
          caption: skillset?.displayName,
          label: 'Skillset name',
          panelElement: namePanelElement,
          value: { value: skillset?.displayName },
        },
        {
          caption: skillset?.longDescription,
          label: 'Description',
          panelElement: descriptionPanelElement,
          value: { value: skillset?.longDescription },
        },
      ]}
      onSubmit={handleSubmit}
    />
  );
};

export { SkillsetOverview };
