import type { Dispatch, SetStateAction } from 'react';

import type { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';
import type { IndividualEscrowPayment, IndividualFeePayment, IndividualPrepaidPayment } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { FeePaymentPaidByEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { PanelElementListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { INDIVIDUAL_FEES_COLUMNS, PAYERS } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import { ConvertEnum, getEnumPanelElementOptionList, getRawEnum } from '@/Utils/Helpers/enumHelpers';
import { getPanelElement, getPanelElementOption } from '@/Utils/Helpers/getPanelElement';
import { cleanAndConvertCurrency, convertProtoToCurrency, convertProtoToPercentage } from '@/Utils/Helpers/numberFormatter';
import {
  ConvertProtoDecimalAsObjectToNumber,
} from '@/Utils/Helpers/protoDecimalConversion';

export interface TableContent {
  payer: string;
  financedAmount?: number;
  outsideOfClosingAmount?: number;
  atClosingTotalAmount?: number;
  totalAmount?: number;
  creditAmount?: number;
  displayOtherValue?: string;
}

export const PAYMENT_PORTIONS_OF_FEE: string[] = [
  'creditAmount',
  'financedAmount',
  'outsideOfClosingAmount',
];

export const createTableContent = (feePayment: any): TableContent[] | undefined => {
  const tableRows = PAYERS.map((payer: string) => {
    const newObj: TableContent = { payer };
    const regex = new RegExp(`paidBy${payer}`, 'i');
    const keys = Object.keys(feePayment).filter((key) => regex.test(key));

    if (payer === 'Other') {
      newObj.displayOtherValue = ConvertEnum(FeePaymentPaidByEnum, feePayment.feePaymentPaidByOtherType || 0);
    }
    keys.forEach((key) => {
      const value = feePayment[key as keyof typeof feePayment];
      if (value && typeof value === 'object' && 'v1' in value) {
        const keyWithoutPayer = key.replace(regex, '');
        const newKey = keyWithoutPayer.charAt(0).toLowerCase() + keyWithoutPayer.slice(1) as keyof Omit<TableContent, 'displayOtherValue' | 'payer'>;
        newObj[newKey] = ConvertProtoDecimalAsObjectToNumber(value);
      }
    });

    INDIVIDUAL_FEES_COLUMNS.forEach((column) => {
      if (newObj[column.id as keyof Omit<TableContent, 'displayOtherValue' | 'payer'>] === undefined) {
        newObj[column.id as keyof Omit<TableContent, 'displayOtherValue' | 'payer'>] = 0;
      }
    });

    newObj.atClosingTotalAmount = (newObj.totalAmount || 0) - (newObj.financedAmount || 0) - (newObj.outsideOfClosingAmount || 0);

    return newObj;
  });

  tableRows?.filter((row) => row.financedAmount || row.outsideOfClosingAmount || row.atClosingTotalAmount || row.totalAmount || row.creditAmount);
  return tableRows;
};

export const mapPayerToPayerPaid = (payer: string): string => `${payer.charAt(0).toLowerCase()}${payer.slice(1)}Paid`;

export function calculatePayerTotals(updatedFee: any, payer: string) {
  const { feePayment } = updatedFee;
  const totalAmount = cleanAndConvertCurrency(feePayment[`paidBy${payer}TotalAmount`]);
  const financedAmount = cleanAndConvertCurrency(feePayment[`paidBy${payer}FinancedAmount`]);
  const creditAmount = cleanAndConvertCurrency(feePayment[`paidBy${payer}CreditAmount`]);
  const outsideOfClosingAmount = cleanAndConvertCurrency(feePayment[`paidBy${payer}OutsideOfClosingAmount`]);
  const closingTotalAmount = cleanAndConvertCurrency(feePayment[`paidBy${payer}AtClosingTotalAmount`]);

  const totalColumnValue = totalAmount.add(financedAmount).add(creditAmount).add(outsideOfClosingAmount);

  return totalColumnValue.value > closingTotalAmount.value;
}

const isProtoDecimalAsObject = (obj: any): obj is ProtoDecimal.AsObject => typeof obj === 'object' && obj !== null && 'v1' in obj;

export const validatePaymentValues = (
  payer: string,
  paymentDetails: IndividualEscrowPayment.AsObject | IndividualFeePayment.AsObject | IndividualPrepaidPayment.AsObject | undefined,
  addValidationError: (key: string) => void,
  removeValidationError: (key: string) => void,
) => {
  if (!paymentDetails) return;

  const paymentKeysToValidate: Array<string> = [
    `paidBy${payer}FinancedAmount`,
    `paidBy${payer}CreditAmount`,
    `paidBy${payer}OutsideOfClosingAmount`,
  ];

  const totalPaymentKey = `paidBy${payer}TotalAmount` as keyof typeof paymentDetails;
  const totalPaymentAmount = paymentDetails[totalPaymentKey];
  const totalPaymentAmountNumber = isProtoDecimalAsObject(totalPaymentAmount)
    ? ConvertProtoDecimalAsObjectToNumber(totalPaymentAmount)
    : 0;

  paymentKeysToValidate.forEach((key) => {
    const specificPaymentKey = key as keyof typeof paymentDetails;
    const specificPaymentValue = paymentDetails[specificPaymentKey];
    if (specificPaymentValue && typeof specificPaymentValue === 'object' && !Array.isArray(specificPaymentValue)) {
      const specificPaymentValueNumber = isProtoDecimalAsObject(specificPaymentValue)
        ? ConvertProtoDecimalAsObjectToNumber(specificPaymentValue)
        : 0;
      if (specificPaymentValueNumber > totalPaymentAmountNumber) {
        addValidationError(key);
      } else {
        removeValidationError(key);
      }
    }
  });
};

export const createListItems = (
  paymentPortion: IndividualEscrowPayment.AsObject | IndividualFeePayment.AsObject | IndividualPrepaidPayment.AsObject | undefined,
  infoData: {
    field?: string;
    id: string;
    label: string;
    type?: string;
    enumType?: any;
  }[],
  enabledFields: string[],
): PanelElementListItem[] => infoData.map((item) => {
  let caption = '';

  const fieldMappings: { [key: string]: () => void } = {
    escrowTotalPercent: () => {
      caption = convertProtoToPercentage((paymentPortion as IndividualEscrowPayment.AsObject)?.escrowTotalPercent || 0);
    },
    feeSpecifiedFixedAmount: () => {
      caption = convertProtoToCurrency((paymentPortion as IndividualFeePayment.AsObject)?.feeSpecifiedFixedAmount || 0);
    },
    feeTotalPercent: () => {
      caption = convertProtoToPercentage((paymentPortion as IndividualFeePayment.AsObject)?.feeTotalPercent || 0);
    },
    regulationZPointsAndFees: () => {
      caption = paymentPortion?.regulationZPointsAndFeesIndicator ? 'Yes' : 'No';
    },
  };

  const handleDefault = () => {
    if (item.enumType && typeof paymentPortion?.[item.id as keyof typeof paymentPortion] === 'number') {
      caption = ConvertEnum(item.enumType, paymentPortion?.[item.id as keyof typeof paymentPortion] as number);
    }
  };

  const determineOptionsList = (item: { id: string; enumType?: any }) => {
    // all fields that are using hardcoded option lists
    const optionsByType = {
      integratedDisclosureSectionType: [
        { headerText: 'Origination Charges', id: '5' },
        { headerText: 'Services You Can Shop For', id: '14' },
        { headerText: 'Services You Cannot Shop For', id: '13' },
        { headerText: 'Taxes And Other Government Fees', id: '15' },
        { headerText: 'Other', id: '6' },
      ],
      regulationZPointsAndFees: [
        { headerText: 'Yes' },
        { headerText: 'No' },
      ],
    };
    const options = optionsByType[item.id as keyof typeof optionsByType]?.map(getPanelElementOption);
    return options ?? (item.enumType ? getEnumPanelElementOptionList(item.enumType) : undefined);
  };

  const handler = fieldMappings[item.id] || handleDefault;
  handler();

  const regulationZPointsAndFeesIndicator = paymentPortion?.regulationZPointsAndFeesIndicator ? 'Yes' : 'No';

  return {
    caption,
    disableInteractions: true,
    label: item.label,
    panelElement: getPanelElement({
      disabled: !enabledFields.includes(item.id),
      headerText: item.label,
      id: item.id,
      optionsList: determineOptionsList(item),
      type: item.type || 'text',
    }),
    value: item.type === 'select'
      ? {
        value: {
          headerText: caption,
          value: caption,
        },
      }
      : {
        value: item.id === 'regulationZPointsAndFees'
          ? regulationZPointsAndFeesIndicator
          : caption,
      },
  };
});

export const handleAdditionalInfoSubmit = (enabledFields: string[], additionalInfo: {
  field?: string;
  id: string;
  label: string;
  type?: string;
  enumType?: any;
}[], feePayment: IndividualEscrowPayment.AsObject |
IndividualFeePayment.AsObject |
IndividualPrepaidPayment.AsObject |
undefined,
setFeePayment: Dispatch<SetStateAction<any | undefined>>) => (data: DynamicDataElementValues, close: () => void) => {
  let updated = false;
  enabledFields.forEach((field) => {
    if (data[field]) {
      const { value } = data[field];

      if (typeof value === 'object' && feePayment) {
        const currentEnum = additionalInfo.find((item) => item.id === field);
        const feeTypeKey = getRawEnum(currentEnum?.enumType, Number(value.id));

        if (currentEnum?.enumType && feeTypeKey in currentEnum?.enumType) {
          (feePayment as any)[field] = currentEnum?.enumType[feeTypeKey as keyof typeof currentEnum.enumType];
          updated = true;
        }
      } else if (feePayment && field === 'regulationZPointsAndFees') {
        feePayment.regulationZPointsAndFeesIndicator = value === 'Yes';
        updated = true;
      }
    }
  });
  if (updated) {
    setFeePayment((prevState: IndividualEscrowPayment.AsObject | IndividualFeePayment.AsObject | IndividualPrepaidPayment.AsObject | undefined) => ({
      ...prevState,
    }));
  }
  close();
};
