import type { TextGenerateProps as ComponentProps } from './TextGenerate.component';
import { TextGenerate as Component } from './TextGenerate.component';
import type { TextGenerateProps as DDEProps } from './TextGenerate.dde';
import { TextGenerate as DDE } from './TextGenerate.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly?: false;
}

type TextGenerateProps = ExtendedComponentProps | ExtendedDDEProps;

export const TextGenerate = (props: TextGenerateProps) => {
  const { displayOnly } = props;
  return displayOnly ? <Component {...props} /> : <DDE {...props} />;
};
