/* eslint no-bitwise: 0 */
import { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';

// Please read comments under ConvertNumberToBytes first

// Set the left most bit (of a 32 bit integer) then interpret the result as an unsigned 32bit integer
const negativeMask = (0b1 << 31) >>> 0;
// The exponent is stored in bits 16-23
const exponentMask = Number(`0b${'1'.repeat(8)}`) << 16;

export function ConvertNumberToProtoDecimal(value: number): ProtoDecimal {
  const bytes = ConvertNumberToBytes(value);
  const protoDecimal = new ProtoDecimal();
  protoDecimal.setV1(Number(bytes[0]));
  protoDecimal.setV2(Number(bytes[1]));
  protoDecimal.setV3(Number(bytes[2]));
  protoDecimal.setV4(Number(bytes[3]));
  return protoDecimal;
}
export function ConvertNumberToDecimalParts(n: number): [exponent: number, scaledInteger: bigint, sign: number] {
  // The algorithm used below is similar to how it would be done by hand
  // A C# decimal is basically (sign * scaledInteger / (10 ** exponent))
  // An easy way to convert by hand is as follows (using 12.12 as an example):
  // Convert the number to a string "12.12"
  // Determine how many decimal places to the right are needed to make it into an integer, in this case 2 (this is the exponent)
  // Make it into an integer by removing the decimal point 1212 (this is the scaledInteger)
  // The sign is just as you would expect Math.Sign
  // The result (1 * 1212 / (10 ** 2)) = 12.12

  // The sign information is separate from the rest of the number in a C# Decimal
  const sign = Math.sign(n);

  // the scaledInteger is always positive
  n = Math.abs(n);

  // Convert the number to a string
  // This is the easiest way (I found) to get convert the number to a integer and get the resulting exponent
  // The alterative was to multiply repeatedly by 10 till we get an integer however this introduced floating point rounding errors (from the repeated multiplication)
  let numberAsString = n.toString();

  // This relies on the formatting of Number.toString() to be either exponential notation or decimal (".") separated.
  // Based on the ECMAScript specification this should always be true.
  // https://262.ecma-international.org/13.0/#sec-numeric-types-number-tostring

  // handle exponential notation
  if (numberAsString.includes('e')) {
    if (numberAsString.includes('e-')) {
      // mimic how a human would do this since Number.toFixed results in precision errors
      // Effectively this moves the decimal to the left by exponentOfExponentialNotation places
      // This only handles negative exponents (handled by the above if statement)

      const pieces = numberAsString.split('e-');

      // grab base
      const base = pieces[0];
      // grab exponent (as a positive number)
      const exponentOfExponentialNotation = Number(pieces[1]);

      // determine where the decimal is currently
      const indexOfDecimal = base.indexOf('.');

      // prefix with 0.
      // add in the correct number of zeros in front of the base (without the current decimal separator)
      // effectively moving the decimal separator left by exponentOfExponentialNotation places
      numberAsString = `0.${'0'.repeat(exponentOfExponentialNotation - indexOfDecimal)}${base.replace('.', '')}`;
    } else {
      // The number does not have a decimal part so it converts to BigInt without issues (BigInt can handle any JavaSCript integer without lose of precision)
      // In addition, we already checked for unsafe integers
      // BigInt.toString() gives us the correct string representation
      // https://262.ecma-international.org/#sec-numeric-types-bigint-tostring
      numberAsString = BigInt(n).toString();
    }
  }

  // Determine how many places to "descale" the scaledInteger by
  // 0.01 = 1 / (10 ^ 2)
  // 10 = 10 / (10 ^ 0)
  let exponent = 0;
  if (numberAsString.includes('.')) {
    exponent = numberAsString.length - numberAsString.indexOf('.') - 1;
  }

  const intAsString = numberAsString.replace('.', '');

  // create the scaledInteger from the number with the decimal point removed
  // Use a big int to avoid precision issues
  const scaledInteger = BigInt(intAsString);

  // The exponent of a C# Decimal must be in the range 0-28
  if (exponent < 0 || exponent > 28) {
    const errorMessage = 'out of range';
    throw new RangeError(errorMessage);
  }

  return [exponent, scaledInteger, sign];
}

function VerifyNumberIsValid(n: number) {
  // too big of integers in JavaScript will lose precision
  if (Number.isInteger(n) && !Number.isSafeInteger(n)) {
    const errorMessage = 'unsafe int attempted to be used';
    throw new RangeError(errorMessage);
  }

  // covers infinity and NaN
  if (!Number.isFinite(n)) {
    const errorMessage = 'number is not valid';
    throw new RangeError(errorMessage);
  }
}

function ConvertNumberPartsToDecimalParts(exponent: number, scaledInteger: bigint, sign: number): [bigint, bigint, bigint, bigint] {
  // If the sign is zero the decimal parts are all zeros
  // According the the C# Decimal specification it can also be represented by -0 but that is treated as equal anyways
  if (sign === 0) {
    return [BigInt(0), BigInt(0), BigInt(0), BigInt(0)];
  }

  // break the scaledInteger into high, middle, and low bits (it is an unsigned 96 bit integer to C#)

  // grab the low 32 bit from scaledInteger (treat it as a signed integer)
  // For clarity the below code should have been used instead but Jest kept getting stuck in a (probably) infinite loop
  //  var lowPart = scaledInteger & BigInt(0xFFFFFFFF);
  const lowPart = BigInt.asIntN(32, scaledInteger);

  // grab the middle 32 bits (treat it as a signed integer)
  const midPart = (scaledInteger >> BigInt(32)) & BigInt(0xFFFFFFFF);

  // grab the high 32 bits (treat it as a signed integer)
  const highPart = (scaledInteger >> BigInt(64)) & BigInt(0xFFFFFFFF);

  let finalPart = 0;

  // If the number is negative apply the negative bit mask
  // positive is 0 so no changes are needed
  if (sign === -1) {
    finalPart |= negativeMask;
  }

  // The exponent starts at bit 16 so shift to there to mask in the correct bits
  // We have already checked that the number is in range
  const exponentMask = exponent << 16;

  finalPart |= exponentMask;
  return [lowPart, midPart, highPart, BigInt(finalPart)];
}

export function ConvertNumberToBytes(n: number): [bigint, bigint, bigint, bigint] {
  // This function converts a JavaScript Number (double internally) into the bit representation used by Decimal.GetBits

  /*
  Due to the range and precision being different between the types not all decimals can be numbers and vice versa
  Any invalid Number should throw

  Here is the decimal format documentation from Microsoft
  https://learn.microsoft.com/en-us/dotnet/api/system.decimal.getbits?view=net-7.0#system-decimal-getbits(system-decimal)
  This internal representation seems unlikely to change. We are using a public method (Decimal.GetBits) And multiple versions of the .NET Framework and .NET Core use the same representation.
  Please note that the same Decimal value may have a different (valid) internal representations due to trailing zeros. Trailing zeros are preserved in C# decimals but not in JavaScript.

  Here is the documentation on JavaScript Numbers
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number

  Here are a couple of important pieces of JavaScript information that is relevant:

  Bitwise operations in JavScript convert their operands to 32-bit integers then applies the operator then converts it back to a Number (double)
    See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND

  The (eslint) linter should catch this most of the time but JavScript literal Numbers may lose precision at runtime
    I.E. 1894.54987949848948948949848989 -> 1894.5498794984894
       -10.574876876981586181648181685 -> -10.574876876981586
    The easiest way to test this is to paste the number into the JavaScript console

  To avoid issues with precision, we use BigInts as much as possible (we only cast to Number once we have to)

  n >>> 0 causes the Number to be interpreted as an unsigned 32bit integer

  Any math on a JavaScript Number can result in rounding errors due to the floating point math

  We can't lose precision (but can lose range) when going from a Number to a C# Decimal
  Decimal's use 96 bits for the fraction portion which is greater than the 53 bits used for a JS Number

  In certain places it would be good to use BigInts instead of Numbers however we are limited by our TypeScript target version (es6)

  Debugging notes

  1. If needed run this code in the JS console of developer tools, it makes it easier to see results
  2. run the linter by hand if needed to check for loss of precision
  */

  // confirm the number is valid (as much as possible) before preceding
  VerifyNumberIsValid(n);

  // grab the parts of the number
  const [exponent, scaledInteger, sign] = ConvertNumberToDecimalParts(n);

  // convert the parts into the representation used by Decimal.GetBits
  return ConvertNumberPartsToDecimalParts(exponent, scaledInteger, sign);
}

export function ConvertProtoDecimalToInstance(value?: ProtoDecimal.AsObject): ProtoDecimal {
  const protoDecimal = new ProtoDecimal();
  protoDecimal.setV1(value?.v1 || 0);
  protoDecimal.setV2(value?.v2 || 0);
  protoDecimal.setV3(value?.v3 || 0);
  protoDecimal.setV4(value?.v4 || 0);
  return protoDecimal;
}

export function ConvertProtoDecimalToNumber(value: ProtoDecimal): number {
  return ConvertProtoDecimalAsObjectToNumber({
    v1: value.getV1(),
    v2: value.getV2(),
    v3: value.getV3(),
    v4: value.getV4(),
  });
}

export function ConvertProtoDecimalAsObjectToNumber(value?: ProtoDecimal.AsObject): number {
  // see ConvertNumberToBytes comments for full documentation (that has more general information)
  if (!value) return 0;

  const lowPart = value.v1;
  const midPart = value.v2;
  const highPart = value.v3;

  // the low, middle and high parts together are an unsigned 96bit integer
  // The result is a BigInt to avoid the precision issues of a Number
  // The middle and high parts are scaled to the correct decimal place with left bit shifts (the inverse of the other process)
  const integerPart = BigInt(lowPart >>> 0) + (BigInt(midPart) << BigInt(32)) + (BigInt(highPart) << BigInt(64));

  // the forth part is a bit masked mix of the sign and the scale
  const signAndScale = value.v4 >>> 0;

  // bit mask out the high bit
  // then right bit shift (as an unsigned integer) and check if it is set (meaning negative)
  const negative = (signAndScale & negativeMask) >>> 31 === 1;

  // grab the exponent component and shift to the correct position (so it can be read as an unsigned integer)
  const scale = (signAndScale & exponentMask) >> 16;

  // compute the unsigned number by scaling the integerPart
  let finalResult = Number(integerPart);
  if (scale !== 0) {
    finalResult /= 10 ** Number(scale);
  }

  // apply the sign
  finalResult *= negative ? -1 : 1;

  // confirm that the result is safe (if it's an integer)
  if (Number.isInteger(finalResult) && !Number.isSafeInteger(finalResult)) {
    const errorMessage = 'An unsafe int would have been generated';
    throw new RangeError(errorMessage);
  }

  return finalResult;
}
