import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { Typography } from '../Typography';

export interface SwitchProps {
  className?: string;
  optionsLabels: Array<{
    name: string;
    disabled?: boolean;
  }>;
  selectedItem: string;
  onSelect: (selected: string) => void;
  elevation?: boolean;
  label?: string;
  disabled?: boolean;
}

const Switch = (props: SwitchProps) => {
  const { elevation = false, label, onSelect, optionsLabels, selectedItem } = props;

  const loadItem = useRef(false);
  useEffect(() => {
    if (!selectedItem && !loadItem.current) {
      loadItem.current = true;
      onSelect(optionsLabels[0].name);
    }
  }, [optionsLabels, onSelect, selectedItem]);

  return (
    <div className={twMerge('w-full flex flex-row justify-between items-center', props.className)}>
      {label && <Typography variant="body2">{label}</Typography>}
      <div className={clsx('flex flex-row items-center rounded-[2rem] bg-surface-background p-1 border border-on-surface-stroke', {
        'shadow-one': elevation,
      })}
      >
        {
          optionsLabels.map((label) => {
            const isSelected = label.name === selectedItem;
            return (
              <div
                key={label.name}
                className={clsx('px-3 py-[6px] rounded-[2rem] cursor-pointer', {
                  '!cursor-not-allowed': label.disabled,
                  'bg-primary-on-surface-states-activated': isSelected,
                  'bg-transparent': !isSelected,
                  'hover:bg-on-surface-states-hover': !label.disabled,
                })}
                id={label.name}
                onClick={() => {
                  if (!label.disabled) onSelect(label.name);
                }}
                onKeyDown={() => {
                  if (!label.disabled) onSelect(label.name);
                }}
                role="button"
                tabIndex={0}
              >
                <Typography
                  className={clsx({
                    'text-on-primary-states-disabled': label.disabled,
                    'text-primary-on-surface': isSelected,
                  })}
                  variant={isSelected ? 'body2Bold' : 'body2'}
                >
                  {label.name}
                </Typography>
              </div>
            );
          })
      }
      </div>
    </div>

  );
};

export default Switch;
