import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DocumentCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { DocType, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useCreateDocumentCategory } from '@/API/Queries/mortgage/useCreateDocumentCategory';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import { Button } from '@/Components/Atoms/Button';
import { Input } from '@/Components/Atoms/Input';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getAssignedBadgeProps } from '@/Utils/Helpers/getAssignedBadgeProps';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import type { DocumentCategoryModalView } from './DocumentCategoryModal';

type TableItem = {
  consumerFacing: string;
  id: string;
  label: string;
  documentTypeName: string;
  lastEdited: string;
  badge?: IBadgeProps;
  isAssigned: ReactNode;
};

interface CreateDocumentCategoryProps {
  refetch: () => void;
  onBack: () => void;
  docType?: DocType.AsObject;
  docTypes: DocType.AsObject[];
  assign?: boolean;
  category?: PanelElementOption.AsObject;
  setView: (view: DocumentCategoryModalView) => void;
  documentCategory: Partial<DocumentCategory.AsObject>;
  setDocTypes: (docTypes: DocType.AsObject[]) => void;
  setDocType: (docType?: DocType.AsObject) => void;
  setDocumentCategory: (documentCategory: Partial<DocumentCategory.AsObject>) => void;
}

const CreateDocumentCategory = ({
  assign,
  category,
  docType,
  docTypes,
  documentCategory,
  onBack,
  refetch,
  setDocType,
  setDocTypes,
  setDocumentCategory,
  setView,
}: CreateDocumentCategoryProps) => {
  const { isLoading: isSaving, mutate: createDocumentCategory } = useCreateDocumentCategory();
  const { showSnackBar } = useShared();
  const [selectedTypes, setSelectedTypes] = useState<TableItem[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (docType && !docTypes.some((doc) => doc.docType === docType.docType)) {
      setDocTypes([...docTypes, { ...docType }]);
      if (assign) {
        setDocumentCategory({
          ...documentCategory,
          categoryType: category ? Number(category.id) : documentCategory.categoryType,
          typesList: [...documentCategory?.typesList || [], docType.docType],
        });
      }
    }
    setDocType(undefined);
  }, [docType, assign, setDocTypes, docTypes, category, setDocumentCategory, documentCategory, setDocType]);

  const columns = useMemo((): Array<Column<any>> => [{
    header: 'Document Type',
    id: 'documentTypeName',
    key: {
      text: 'documentTypeName',
      type: 'text',
    },
  }, {
    header: 'Mismo Mapping',
    id: 'label',
    key: {
      children: 'label',
      type: 'generic',
    },
  }, {
    header: 'Last Edited',
    id: 'lastEdited',
    key: {
      text: 'lastEdited',
      type: 'text',
    },
  }, {
    header: 'Assign',
    id: 'badge',
    key: {
      badge: 'badge',
      type: 'badge',
    },
  }, {
    header: 'Consumer facing',
    id: 'consumerFacing',
    key: {
      text: 'consumerFacing',
      type: 'text',
    },
  },
  ], []);

  const getTableItem = useCallback((docType: DocType.AsObject, isAssigned?: boolean): TableItem => {
    const lastEditedDate = getDateFromTimestamp(documentCategory?.lastEditedDate);
    return {
      badge: isAssigned ? getAssignedBadgeProps() : undefined,
      consumerFacing: docType.isConsumerFacing ? 'Yes' : 'No',
      documentTypeName: docType?.documentTypeName || '--',
      id: `${String(docType.docType)}-${docType.documentTypeName}`,
      isAssigned,
      label: ConvertEnum(DocumentEnum, docType.docType),
      lastEdited: lastEditedDate !== null ? lastEditedDate.toLocaleString() : '',
    };
  }, [documentCategory?.lastEditedDate]);

  const data = useMemo(() => {
    let tableData = docTypes.map((docType) => getTableItem(docType, true)) || [];

    if (search) {
      tableData = tableData.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
    }

    return tableData.sort((a, b) => {
      if (a.isAssigned === b.isAssigned) {
        return 0;
      }
      return a.isAssigned ? -1 : 1;
    });
  }, [docTypes, getTableItem, search]);

  const handleUnassign = useCallback((selectedTypes: TableItem[]) => {
    const listTypes = selectedTypes.map((type) => type.id);
    if (documentCategory?.typesList) {
      const types: DocumentEnum[] = documentCategory.typesList
        .filter((type) => !selectedTypes
          .some((selectedType) => Number(selectedType.id.split('-')[0]) === type));

      const dt = docTypes?.filter((docType) => !listTypes.includes(`${String(docType.docType)}-${docType.documentTypeName}`)).map((docType) => {
        const newDocType = new DocType();
        newDocType.setDocType(docType.docType);
        newDocType.setDocumentTypeName(docType.documentTypeName);
        newDocType.setIsConsumerFacing(docType.isConsumerFacing);
        return newDocType.toObject();
      });

      setDocTypes(dt);
      setDocumentCategory({
        ...documentCategory,
        docTypesList: dt,
        typesList: types,
      });
    }
  }, [docTypes, documentCategory, setDocTypes, setDocumentCategory]);

  const handleSingleItemClick = useCallback((item: TableItem) => {
    handleUnassign([item]);
  }, [handleUnassign]);

  const handleCreateCategory = () => {
    createDocumentCategory({
      category: documentCategory.categoryType || 0,
      docTypes: docTypes.map((docType) => {
        const newDocType = new DocType();
        newDocType.setDocType(docType.docType);
        newDocType.setDocumentTypeName(docType.documentTypeName);
        newDocType.setIsConsumerFacing(docType.isConsumerFacing);
        return newDocType;
      }) || [],
      name: documentCategory.name || '',
      types: docTypes.map((docType) => docType.docType) || [],
    }, {
      onSuccess() {
        showSnackBar({ message: 'Document category created', open: true });
        refetch();
        onBack();
      },
    });
  };

  const handleUpdateDocumentName = (value: string) => {
    setDocumentCategory({
      ...documentCategory,
      name: value,
    });
  };

  const handleCreateType = () => {
    setView('newType');
  };

  return (
    <DialogLayout>
      <DialogHeader title="Create Document Category" />

      <DialogContent>
        <div className="w-full h-full p-4 sm:p-12 flex flex-col gap-y-10 overflow-auto">
          <Typography
            className="text-on-surface-inactive"
            variant="body1"
          >
            Edit category details, assign or unassign types to the category.
          </Typography>
          <div className="flex gap-x-4">
            <Input
              displayOnly
              label="Category Name"
              onChange={handleUpdateDocumentName}
              placeholder="Category Name"
              required
              value={documentCategory.name}
            />
          </div>

          <div className="border border-on-surface-stroke p-4 w-full rounded-sm">
            <Table
              cardProps={{
                headerProps: {
                  actions: [{ label: 'Create new', onClick: handleCreateType }],
                  contextualHeaderProps: {
                    buttons: [
                      { label: 'Unassign', onClick: () => handleUnassign(selectedTypes) },
                    ],
                    caption: `${selectedTypes.length} selected`,
                  },
                  searchProps: { onChange: setSearch, value: search },
                  showContextual: selectedTypes.length > 0,
                  title: 'Assigned Document Types',
                },
              }}
              columns={columns}
              data={data}
              hoverActions={[
                { label: (item) => (item.isAssigned ? 'Unassign' : 'Assign'), onClick: (item) => handleSingleItemClick(item) },
              ]}
              setRowSelection={setSelectedTypes}
              width="stretch"
            />
          </div>
        </div>
      </DialogContent>

      <DialogFooter borderTop>
        <Button
          disabled={!documentCategory.name}
          isLoading={isSaving}
          label="Create Category"
          onClick={handleCreateCategory}
          type="button"
        />
      </DialogFooter>
    </DialogLayout>
  );
};

export { CreateDocumentCategory };
